import React, { useContext, useEffect } from "react";
import { Switch, Route } from "react-router-dom";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";
//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected, AccessRoute } from "./AuthProtected";
import { LoginContex } from "../context/loginContext/LoginContext";

const Index = () => {
  const {
    menus,
    fetchMenus,
    isLoading,
    User,
    sidebarMenus,
    fetchSidebarMenus, fetchSingleRoleReport,repRoles
  } = useContext(LoginContex);
  let userId = User?.data?.user?._id || User?.user?._id;
  useEffect(() => {
    fetchMenus();

    fetchSidebarMenus(userId);
    fetchSingleRoleReport(userId);
  }, []);

  // //console.log(menus);

  const itemsSelect = [];
  for (let i = 0; i < sidebarMenus.length; i++) {
    // //console.log(sidebarMenus[i]._id);
    sidebarMenus[i].subItems.forEach((link) => {
      itemsSelect.push(link.link.substring(1));
    });
  }
  const reporLinks = [];
  for (let i = 0; i < repRoles.length; i++) {
    // //console.log(repRoles[i]._id);
    repRoles[i].subItems.forEach((link) => {
      reporLinks.push(link.link.substring(1));
    });
  }
// //console.log(reporLinks)
  const arr = [
    "NotFound",
    "dashboard",
    "AddNewMenus",
    "SendFinger",
    "PaymentsReports",
    "ConnectDevice",
    "Shifts",
    "dailyAttendance",
    "attendance",
    "monthly",
    "AvailableCards",
    "SendSmsNotifications",
    "ResolvedTickets",
    "RegisterSms",
    "CancelCards",
    "TransferReceiver",
    "CompleteTicket",
    "ExpiredAnalog",
    "TicketSupervisorReport",
    "Active",
    "OrganizationReport",
    "download",
    "category",
    "leave",
    "NewRedline",
    "OpenClosedRedlineCard",
    "CancelOrActivate",
    "MultiShifts",
    
  ];
  

  itemsSelect.push(...arr, ...reporLinks);
  // //console.log(itemsSelect);
  const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
  const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);
  return (
    <React.Fragment>
      <Switch>
        <Route path={availablePublicRoutesPaths}>
          <NonAuthLayout>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  component={route.component}
                  key={idx}
                  exact={true}
                />
              ))}
            </Switch>
          </NonAuthLayout>
        </Route>

        <Route path={availableAuthRoutesPath}>
          <AuthProtected>
            <VerticalLayout>
              <Switch>
                {authProtectedRoutes.map((route, idx) => (
                  <AccessRoute
                    path={route.path}
                    component={route.component}
                    key={idx}
                    exact={true}
                    allowedPages={itemsSelect}
                  />
                ))}
              </Switch>
            </VerticalLayout>
          </AuthProtected>
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default Index;
