import React, { useState, useEffect, useContext, useMemo } from "react";
import Select from "react-select";
import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";

const CancelCards = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardPackage, setCardPackage] = useState("");
  const [customer, setCustomer] = useState();
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [openDate, setOpenDate] = useState("");
  const [closeDate, setCloseDate] = useState("");
  const [usingDays, setUsingDays] = useState("");
  const [amount, setAmount] = useState(8);
  const [Discription, setdescription] = useState("");
  const { openAnalogCardRegister, customers } = useContext(LoginContex);
  const [type, setTyp] = useState("text");
  const [customerInfo, setCustomerInfo] = useState();
  const [receiverId, setreceiverId] = useState();
  const [Custom, setCustom] = useState("text");
  const [channelId, setChanell] = useState();
  const [cycle, setCycle] = useState("");
  const [numberOfDays, setNumberOfDays] = useState();
  const [numberOfMonths, setNumberOfMonths] = useState();
  
  
  const [billAmount, setBillAmount] = useState();

  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [modal_delete, setmodal_delete] = useState(false);

  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  document.title = "Cancel Card ";

  const {
    fetchOpenCards,
    OpenCards,
  
    cardList,
    channelPackage,
    fetchChanelPackages,
    closeAnalogCard,
    fetchSoldCards,
    cancelCards,
    soldReceivers,
    isLoading,User
  } = useContext(LoginContex);

  useEffect(() => {
    fetchSoldCards()
  }, []);

 

  let getCustcardNumber = (id) => {
     setCustomerInfo(id);
  
 
    setCardNumber(id.cardNumber);

    //console.log(id.cardNumber)

  };


  let userId = User?.data?.user?._id || User?.user?._id;
  const columns = useMemo(
    () => [
      {
        Header: "Receiver",
        accessor: "cardNumber",
        filterable: false,
      },
      {
        Header: "Cartoon Number",
        accessor: "cartonNumber",
        filterable: false,
      }
    

      
    ]
    // [handleCustomerClick]
  );
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handlerSubmitOpen = (e) => {
    //console.log(e);
    e.preventDefault();
    if (
      !customerInfo?.cardNumber 
    ) {
      showToastMessage("please provide required values");
      return;
    }

   
    let  cardNumber1 = customerInfo?.cardNumber;
    
    e.target.reset();
    cancelCards(cardNumber1);
    //console.log(cardNumber1);
    setmodal_list(false);

    // showToastMessage("succefully opened card");
  };

  
  const cardNumbers = [];

  for (let i = 0; i < soldReceivers.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: soldReceivers?.[i]?._id,
      cardNumber: soldReceivers?.[i]?.cardNumber,
      label: soldReceivers?.[i]?.cardNumber,
    };

    cardNumbers.push(dropdownList);
  }




  //console.log(info);
  const closeCard = () => {
    // const data = {
    //   id: info._id,
    //   description: status,
    // };
    // //console.log(data);
    // closeAnalogCard(data);
    // setStatus("");
    setmodal_delete(false);
  };
  const load = () => {
    if (isLoading) {
      return (
        <>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ToastContainer />
                        <img
                          src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif"
                          style={{ backgroundColor: "white !", width: "700px" }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
    
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Cancel Card " pageTitle="Cancel Card" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">Cancel Card</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={tog_list}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Cancel Card
                          </Button>
                         
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                {load()}
                <CardBody>
                  <div id="Purchasestable">
                    <Row className="g-4 mb-3"></Row>

                 

                    {soldReceivers?.length > 0 && (
                      <TableContainer
                        columns={columns}
                        data={soldReceivers || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        loading={true}
                        
                        // handleCustomerClick={handleCustomerClicks}
                        // isCustomerFilter={true}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Open Closed Card Distribution From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Cancel Cards</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={handlerSubmitOpen}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Receivers</label>
                  <Select
                    // className="bg-white"
                    options={cardNumbers}

                    // onChange={(e) => getCustcardNumber(e.target)}
                    onChange={(choice) =>
                      // getCustPhone(e.target.value, e.target)
                      getCustcardNumber(choice)
                    }
                    value={receiverId}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                  />
                </div>
              </Col>
            <Col>
            <div className="mt-4">
            <Button type="submit" className="btn btn-success">
                Save
              </Button>
            </div>
            </Col>
            </Row>
          </ModalBody>

        </form>
      </Modal>

 
    </React.Fragment>
  );
};

export default CancelCards;
