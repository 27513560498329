import React, { useState, useEffect, useContext, useMemo } from "react";
import { Grid, _ } from "gridjs-react";
import DataTable from "react-data-table-component";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import TableContainer from "../../Components/Common/TableContainer";
import "react-toastify/dist/ReactToastify.css";
const Stores = () => {
  const [storeName, setstoreName] = useState("");
  const [storeLocation, srtstoreLocation] = useState("");
  const [storeDescription, setstoreDescription] = useState("");
  const [isPrimaryStore, setisPrimaryStore] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [yes, setYes] = useState("false");
  const [id, setId] = useState("");
  // const [no, setNo] = useState(false);
  const {
    registerStore,
    fetchStores,
    stores,
    deleteStores,
    upadteStore,
    isLoading,User
  } = useContext(LoginContex);
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  // //console.log(yes, true);
  let yess = yes === "true";
  //console.log(yess);
  useEffect(() => {
    fetchStores();
  }, []);
  // Customers Column
  let userId = User?.data?.user?._id || User?.user?._id;
  //console.log(userId)
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "storeName",
        filterable: false,
      },
      {
        Header: "Address",
        accessor: "storeLocation",
        filterable: false,
      },
      {
        Header: "Description",
        accessor: "storeDescription",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    updateForm(customerData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              {/* <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    deleteStore(customerData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li> */}
            </ul>
          );
        },
      },
    ]
    // [handleCustomerClick]
  );

  document.title = "Store Registration ";

  const handleSubmit = (e) => {
    //console.log("ddddddddddddd");
    e.preventDefault();
    if (!isEditing) {
      const data = {
        storeDescription,
        storeName,
        storeLocation,
        isPrimaryStore: yes === "true",
        userId
      };
      if (!storeName || !storeLocation) {
        return;
      }
      registerStore(data);
      setmodal_list(false);
      setstoreName("");
      srtstoreLocation("");
      setstoreDescription("");
    }
    if (isEditing) {
      const data = {
        storeDescription,
        storeName,
        storeLocation,
        isPrimaryStore: yes === "true",
        id: id,
      };
      if (!storeName || !storeLocation) {
        return;
      }
      upadteStore(data);
      setmodal_list(false);
      setstoreName("");
      srtstoreLocation("");
      setstoreDescription("");
    }
  };

  const deleteStore = (data) => {
    setmodal_delete(true);
    setId(data._id);
  };

  const delStore = () => {
    deleteStores(id);
    setmodal_delete(false);
  };

  //updateees
  // //console.log(isEditing);
  const updateForm = (row) => {
    //console.log(row);
    setmodal_list(true);
    setstoreName(row.storeName);
    srtstoreLocation(row.storeLocation);
    setstoreDescription(row.storeDescription);
    setYes(row.isPrimaryStore);
    setEditing(true);
    setId(row._id);
  };

  //console.log(yes);
  const addModal = () => {
    tog_list();
    setEditing(false);
    setstoreName("");
    srtstoreLocation("");
    setstoreDescription("");
  };
  const sortStores = [];
  for (let i = 0; i < stores?.length; i++) {
    let singleArr = [
      stores[i].storeName,
      stores[i].storeLocation,
      stores[i].storeDescription,
      stores[i]._id,
    ];

    sortStores.push(singleArr);
  }

  const load = () => {
    if (isLoading) {
      return (
        <>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif"
                          style={{ backgroundColor: "white !", width: "700px" }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Store Registration" pageTitle="Registration" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">Add Store</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  {load()}
                  <div id="StoreList">
                    <Row className="g-4 mb-3"></Row>

                    {stores.length > 0 && (
                      <TableContainer
                        columns={columns}
                        data={stores || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        // handleCustomerClick={handleCustomerClicks}
                        // isCustomerFilter={true}
                      />
                    )}
                  </div>
                  {/* </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Add New Store</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="store_name-field" className="form-label">
                    Store <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="store_name-field"
                    className="form-control"
                    placeholder="Enter Store Name"
                    required
                    onChange={(e) => setstoreName(e.target.value)}
                    value={storeName}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="location-field"
                    className="form-control"
                    placeholder="Enter Location"
                    required
                    onChange={(e) => srtstoreLocation(e.target.value)}
                    value={storeLocation}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div>
                  <label className="form-label">Is Primary?</label>
                  <div className="mb-3">
                    <Label className="form-check-label" htmlFor="is_yes">
                      <Input
                        className="form-control-input"
                        type="radio"
                        name="prim"
                        id="is_yes"
                        value={true}
                        onChange={(e) => setYes(e.target.value)}
                      />
                      &nbsp;Yes
                    </Label>
                  </div>
                </div>
                <div className="mb-3">
                  <Label className="form-check-label" htmlFor="is_no">
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="prim"
                      defaultChecked
                      id="is_no"
                      value={false}
                      onChange={(e) => setYes(e.target.value)}
                    />
                    &nbsp; No
                  </Label>
                </div>
              </Col>
              <Col md={10}>
                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <textarea
                    className="form-control"
                    id="Description-field"
                    rows="1"
                    onChange={(e) => setstoreDescription(e.target.value)}
                    value={storeDescription}
                  ></textarea>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isEditing ? "update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => delStore()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Stores;
