import React, { useState, useEffect, useContext, useMemo } from "react";
import * as XLSX from "xlsx";
import customerFormat from "../../customerFormat.xlsx";
import latestFormat from "../../latestFormatt.xlsx";
import moment from "moment"

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import Select from "react-select";
// import { Grid } from "gridjs";
import TableContainer from "../../Components/Common/TableContainer";
import { Grid, _ } from "gridjs-react";

const UploadIptvCustomers = () => {
  const [serviceList, setServiceList] = useState([
    {
      itemId: "",
      quantity: "",
      unitPrice: "",
      amount: "",
      description: "",
      QtyAvailable: "",
    },
  ]);

  const [itemData, setitemData] = useState();
  const [itemss, setItems] = useState([]);
  const [toggleTable, setToggleTable] = useState(false);

  ///local states

  const [todaydata, setTodayDate] = useState("");
  const [date, setDate] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [itemInfo, setItemInfo] = useState();

  const [ClearFile, setsetClearFile] = useState();

  const [Qty, setQty] = useState("");
  const [modal_list, setmodal_list] = useState(false);
  const [yes, setYes] = useState(true);

  const [referenceNo, setReferenceNo] = useState("");

  const [description, setdescription] = useState("");
  const [districtId, setDistrictId] = useState("");

  const {
    suppliers,
    items,
    fetchItems,
    stores,
    fetchStores,
    sites,
    fetchSites,
    readProducts,
    fetchPayments,

    payments,
    purchases_with_associated_receiversBYids,
    addReceiverDetails,
    products,
    registerPurchaseOrder,
    fetchQuantityAvailable,
    quantityAvailable,
    purchaseOrdersReceivers,
    purchaseOrdersRec,
    singlePurchaseOrder,
    FetchSinglePurchase,
    FetchSinglePurchaseReceiver,
    singlePurchaseOrderReceiver,
    registerReciever,
    uploadedErrors,
    isLoading,
    customersUpload,
    fetchDistricts,
    districts,
    User,iptvCustomers,uploadIptvCustomers
  } = useContext(LoginContex);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  let yess = yes === "true";
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    

    fetchItems();
    fetchStores();
    fetchPayments();
    purchaseOrdersReceivers();
    // fetchSites();
    // readProducts();
    fetchDistricts();
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;
    setTodayDate(today);
    FetchSinglePurchaseReceiver(supplierId.value);
  }, [supplierId.value]);

  //console.log(singlePurchaseOrderReceiver);

  ///---------
  // const showToastMessage = () => {
  //   toast.success("succefully registered !", {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  // };
  ///date format
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  let userId = User?.data?.user?._id || User?.user?._id;
  //console.log(userId)
  const columns = useMemo(
    () => [
      {
        Header: "subscriptionID",
        accessor: "subscriptionID",
        filterable: false,
      },
      {
        Header: "Mc",
        accessor: "Mc",
        filterable: false,
      },
      {
        Header: "Sn",
        accessor: "SN",
        filterable: false,
      },
      {
        Header: "customerName",
        accessor: "customerName",
        filterable: false,
      },
      {
        Header: "customerPhone",
        accessor: "customerPhone",
        filterable: false,
      },
      
      {
        Header: "customerTell",
        accessor: "customerTell",
        filterable: false,
      },
      
       
       {
        Header: "status",
        accessor: "status",
        filterable: false,
        // Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
       
    ]
    // [handleCustomerClick]
  );

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;

  //console.log(purchaseOrdersRec);
  //console.log(supplierId.test);
  document.title = "Upload Iptv Customers";

  // const amounts = serviceList[0].Qty;
  // //console.log(amounts);

  serviceList.forEach((ca) => {
    for (const item in ca) {
      //console.log("h");
    }
  });

  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,
      {
        itemId: "",
        quantity: "",
        unitPrice: "",
        amount: "",
        description: "",
        QtyAvailable: "",
      },
    ]);
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };
  const purchaseAmount = serviceList.reduce((accu, curr) => {
    return accu + curr["amount"];
  }, 0);

  const handleServiceChange = (e, index) => {
    fetchQuantityAvailable(serviceList[index]["itemId"]);

    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    list[index]["QtyAvailable"] = quantityAvailable.item_blance;
    fetchQuantityAvailable(serviceList[index]["itemId"]);
    list[index]["amount"] =
      serviceList[index]["quantity"] * serviceList[index]["unitPrice"];
    list[index]["purchaseAmount"] = purchaseAmount;
    if (e.target.name === "itemId") {
      fetchQuantityAvailable(serviceList[index]["itemId"]);
    }
  };

  // //console.log(serviceList);
  //console.log(singlePurchaseOrder[0]?.purchaseOrderDetails);
  // setServiceList([...serviceList,])

  // //console.log(purchaseAmount);



  // let currentUserId=getCurrentUserId(); 

  // //console.log(currentUserId);
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const data = {
    purchaseOrderDate: date,
    supplierId: supplierId.value,
    referenceNo: referenceNo,
    description: description,
    purchaseOrderAmount: purchaseAmount,
    purchaseOrderDetails: serviceList,
  };
  // //console.log(
  //   Number(singlePurchaseOrderReceiver[0]?.quantity) -
  //     Number(singlePurchaseOrderReceiver[0]?.reciversUploaded)
  // );

  
  const handleSubmit = (e) => {
    e.preventDefault();
    // registerPurchaseOrder(data);
    
    if (itemss.length < 1) {
      showToastMessage("upload the customers");
      return;
    }
    // if (!districtId?.value) {
    //   showToastMessage("Please provide the district of the customers");
    //   return;
    // }
    //  let currentUserId=getCurrentUserId(); 

    //  //console.log(currentUserId);
   
     const data = {
      customerupload: itemss,
    
      userId:userId,
    };

    //console.log(data)

    uploadIptvCustomers(data);
    setItemInfo("");
    setItems([]);
    setDistrictId("");
    e.target.reset();
  };
  const suppliersArr = [];

  for (let i = 0; i < singlePurchaseOrderReceiver.length; i++) {
    //console.log(singlePurchaseOrderReceiver[i]._id);

    var dropdownList = {
      value: singlePurchaseOrderReceiver[i].itemId._id,
      label: singlePurchaseOrderReceiver[i].itemId.itemName,
    };

    suppliersArr.push(dropdownList);
  }
  // soloving dropdowns item info
  const itemArr = [];

  for (let i = 0; i < purchaseOrdersRec.length; i++) {
    //console.log(purchaseOrdersRec[i]._id);

    var dropdownList = {
      value: purchaseOrdersRec[i]?._id,
      label: ` ${purchaseOrdersRec[i]?.supplierId?.supplierName}--${purchaseOrdersRec[i]?.purchaseOrderId} `,
      test: purchaseOrdersRec[i]?.supplierId?._id,
    };

    itemArr.push(dropdownList);
  }
  const sortedPaymet = [];
  for (let i = 0; i < itemss?.length; i++) {
    let singleArr = [itemss[i]?.cardNumber, itemss[i]?.cartonNumber];
    sortedPaymet.push(singleArr);
  }
  const sortedSkipped = [];
  for (let i = 0; i < uploadedErrors?.length; i++) {
    let singleArr = [
      uploadedErrors[i]?.cardNumber,
      uploadedErrors[i]?.cartonNumber,
    ];
    sortedSkipped.push(singleArr);
  }

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      const custUpload = [];

      // for (let i = 0; i < d.length; i++) {
    
      //   var dropdownList = {
      //     cardNumber: d?.[i]?.cardNumber,
      //     customerName: d?.[i]?.customerName,
      //     customerAddress: d?.[i]?.customerAddress,
      //     customerPhone: d?.[i]?.customerPhone,
      //     customerTell: d?.[i]?.customerTell,
      //     customerUser: d?.[i]?.customerUser,
      //     endDate:  moment(d?.[i]?.endDate).format("DD-MM-YYYY"),
      //     startDate:  moment(d?.[i]?.startDate).format("DD-MM-YYYY"),
        
      //   };
    
      //   custUpload.push(dropdownList);
      // }
      setItems(d);
    });

    file = null;
  };

  //console.log(itemss);
  //console.log(sortedPaymet);

  const preview = () => {
    setToggleTable(!toggleTable);
  };

  //console.log(uploadedErrors);

  const districtArr = [];

  for (let i = 0; i < districts.length; i++) {
    // //console.log(items[i]._id);

    var dropdownList = {
      value: districts[i]._id,
      label: districts[i].districtName,
    };

    districtArr.push(dropdownList);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Upload Iptv Customers " pageTitle="Purchase" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">Upload Iptv Customers</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          {/* <Link
                            to="/ManagePurchaseOrder"
                            className="text-white"
                          >
                            <Button
                              color="success"
                              className="add-btn me-1"
                              // onClick={() => tog_list()}
                              //  id="create-btn"
                            >
                              <i className=" ri-add-line align-bottom me-1"></i>{" "}
                              Manage Purchase Order
                            </Button>
                          </Link> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchases Ordertable">
                    <form>
                      <ModalBody>
                        <div
                          className="mb-3"
                          id="modal-id"
                          style={{ display: "none" }}
                        >
                          <label htmlFor="id-field" className="form-label">
                            ID
                          </label>
                          <input
                            type="text"
                            id="id-field"
                            className="form-control"
                            placeholder="ID"
                            readOnly
                          />
                        </div>
                        <Row>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formFile" className="form-label">
                                Upload File
                              </Label>
                              <Input
                                className="form-control"
                                type="file"
                                id="formFile"
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  // setsetClearFile( e.target.files[0])
                                  readExcel(file);
                                  e.target.value = null;
                                }}
                                // value={itemss}
                              />
                            </div>
                          </Col>
                          {/* <Col md={4}>
                            <div className="mb-3">
                              <label>
                                District <span className="text-danger">*</span>
                              </label>
                              <Select
                                options={districtArr}
                                onChange={(choice) => setDistrictId(choice)}
                                required
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    // text: "orangered",
                                    // primary25: "hotpink",
                                    // primary: "black",
                                  },
                                })}
                              />
                            </div>
                          </Col> */}
                          <Col md={4} style={{display:"flex", justifyContent:"flex-end" , alignItems:'flex-end'}}>
                            <div className="hstack gap-2 justify-content-end">
                              <div className="mb-3 pull-right">
                                {/* <button
                                  className="btn btn-success mx-5"
                                  onClick={preview}
                                  type="button"
                                >
                                  <a
                                    href={latestFormat}
                                    download="latestFormatt.xlsx"
                                    className="text-white"
                                  >
                                    Download the format{" "}
                                    <i
                                      className="fa fa-download mx-3 fx-3"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </button> */}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {/* <div className="hstack gap-2 justify-content-end">
                            <div className="mb-3 pull-right">
                              <button
                                className="btn btn-success mx-5"
                           
                                onClick={preview}
                                type="button"
                              >
                                <a
                                  href={customerFormat}
                                  download="format.xlsx"
                                  className="text-white"
                                >
                                  Download the format{" "}
                                  <i
                                    className="fa fa-download mx-3 fx-3"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </button>
                             
                            </div>
                          </div> */}

                          {/* <Col md={3}></Col> */}
                        </Row>
                      </ModalBody>
                      <ModalFooter></ModalFooter>
                    </form>
                    <div className="table-card ">
                      {/* {toggleTable && (
                        <Grid
                          data={sortedPaymet}
                          columns={[
                            // "ID",

                            "Card Number",
                            "Cartone Number",
                          ]}
                          search={true}
                          pagination={{ enabled: true, limit: 10 }}
                        />
                      )} */}
                      {itemss.length > 0 && (
                        <TableContainer
                          columns={columns}
                          data={itemss || []}
                          isGlobalFilter={true}
                          isAddUserList={false}
                          customPageSize={10}
                          className="custom-header-css"
                          // handleCustomerClick={handleCustomerClicks}
                          // isCustomerFilter={true}
                        />
                      )}
                    </div>
                  </div>

                  <div className="hstack gap-2 justify-content-end">
                    <button
                      type="button"
                      className="btn btn-success mt-5"
                      // id="add-btn"
                      onClick={handleSubmit}
                      disabled={isLoading}
                    >
                      {isLoading ? "please wait..." : "save"}
                    </button>
                  </div>
                </CardBody>
              </Card>

              {sortedSkipped.length > 0 && (
                <Card>
                  <CardBody>
                    <div>
                      <h5 className="text-danger">
                        These receiver cards already exist in the database , and
                        you can not save duplicate cardNumbers or serials !!
                      </h5>
                      <Row className="mt-5">
                        <Grid
                          data={sortedSkipped}
                          columns={[
                            // "ID",

                            "Card Number",
                            "Cartone Number",
                          ]}
                          search={true}
                          pagination={{ enabled: true, limit: 10 }}
                        />
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Purchase Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        {/* =================================== */}
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UploadIptvCustomers;
