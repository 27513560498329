import React, { useState, useEffect, useContext, useMemo } from "react";
import TableContainer from "../../Components/Common/TableContainer";
import { Grid, _ } from "gridjs-react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link, useHistory } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { date } from "yup";

const ManagePurchase = () => {
  const history = useHistory();
  const [storeName, setstoreName] = useState("");
  const [storeLocation, srtstoreLocation] = useState("");
  const [storeDescription, setstoreDescription] = useState("");
  const [isPrimaryStore, setisPrimaryStore] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [yes, setYes] = useState(true);
  const [id, setId] = useState("");
  // const [no, setNo] = useState(false);
  const [allData, setAllData] = useState("");

  // local states

  const [purchaseDate, setpurchaseDate] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [storeId, setstoreId] = useState("");
  const [siteId, setsiteId] = useState("");
  const [referenceNo, setreferenceNo] = useState("");
  const [description, setdescription] = useState("");
  const [purchaseAmount, setpurchaseAmount] = useState("");
  const [additionalCost, setadditionalCost] = useState("");
  const [discount, setdiscount] = useState();
  const [totalAmount, settotalAmount] = useState();
  const {
    registerStore,
    stores,
    deleteStores,
    upadteStore,
    fetchItems,
    fetchStores,
    fetchSites,
    sites,
    items,
    allPurchases,
    fetchAllPurchases,
    fetchPurchase,
    purchases,
    removePurchase,
    deletePurchase,
    managePurchaseFilterByDate,
    manageFilterByDate,isLoading
  } = useContext(LoginContex);
  const [modal_list, setmodal_list] = useState(false);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [helper, setHelper] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  // //console.log(yes);
  useEffect(() => {
    fetchStores();
    fetchItems();
    fetchSites();
    fetchPurchase();
    fetchAllPurchases();
  }, []);

  //console.log(allPurchases);

  document.title = "Manage Purchase";
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "supplierId.supplierName",
        filterable: false,
      },
      {
        Header: "Reference No",
        accessor: "referenceNo",
        filterable: false,
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
      },
      {
        Header: "Total Amount",
        accessor: "totalAmount",
        filterable: false,
      },
      {
        Header: "Total Items",
        accessor: "purchaseDetails.length",
        filterable: false,
      },
      {
        Header: "Purchase Date",
        accessor: "purchaseDate",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      {
        Header: "Status",
        accessor: "purchaseType",
        Cell: (cell) => {
          //console.log(cell.value);
          switch (cell.value) {
            case "purchase receive":
              return (
                <span className="badge text-uppercase badge-soft-success">
                  {" "}
                  {cell.value}
                </span>
              );
            case "	purchase order":
              return (
                <span className="badge text-uppercase badge-soft-danger">
                  {" "}
                  {cell.value}
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase badge-soft-info">
                  {" "}
                  {cell.value}
                </span>
              );
          }
        },
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to={
                    cellProps.row.original.purchaseType == "purchase receive"
                      ? `/purchaseReceiveUpdate/edit/${cellProps.row.original._id}/${cellProps.row.original.purchaseType}`
                      : `#`
                  }
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    //console.log(customerData);
                    // updateForm(customerData);
                  }}
                >
                  {/* <i className="ri-pencil-fill fs-16"></i> */}
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={(row) => {
                    const purchaseDate = cellProps.row.original;
                    deletePuchase(purchaseDate);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ]
    // [handleCustomerClick]
  );

  ////console.log(allPurchases);

  const handleSubmit = (e) => {
    // //console.log("ddddddddddddd");
    e.preventDefault();
    if (!isEditing) {
      const data = {
        storeDescription,
        storeName,
        storeLocation,
        isPrimaryStore: yes,
      };
      if (!storeName || !storeLocation || !storeDescription) {
        return;
      }
      registerStore(data);
      setmodal_list(false);
    }
    if (isEditing) {
      const data = {
        storeDescription,
        storeName,
        storeLocation,
        isPrimaryStore: yes,
        id: id,
      };
      if (!storeName || !storeLocation || !storeDescription) {
        return;
      }
      upadteStore(data);
    }
  };

  const deletePuchase = (data) => {
    //console.log(data);
    setmodal_delete(true);

    // //console.log(row._cells[7].data);
    const type = data?.purchaseType;
    const id = data._id;
    const the_data = { type, id };
    setAllData(the_data);
    setId(data._id);
  };

  const delPurchase = () => {
    deletePurchase(allData);
    setmodal_delete(false);
  };

  //updateees
  // //console.log(isEditing);
  const updateForm = (data, row) => {
    //console.log(data, row);
    // setmodal_list(true);
    setstoreName(data.storeName);
    srtstoreLocation(data.storeLocation);
    setstoreDescription(data.storeDescription);
    setYes(yes);
    setEditing(true);
    setId(data._id);
  };

  const dateFromDB = "2005-12-03T18:30:00.000Z";
  const formattedDate = moment(dateFromDB).utc().format("DD/MM/YYYY");

  // //console.log("Date From DB:", dateFromDB);
  //=> 2005-12-03T18:30:00.000Z

  //  //console.log("Formatted Date:", formattedDate);

  // soloving dropdowns
  const suppliersArr = [];

  for (let i = 0; i < items.length; i++) {
    // //console.log(items[i]._id);

    var dropdownList = {
      value: items[i]?._id,
      label: items[i]?.supplierName,
    };

    suppliersArr.push(dropdownList);
  }

  const sortedPurchase = [];
  for (let i = 0; i < allPurchases?.length; i++) {
    let date = allPurchases[i].purchaseDate.split("T");
    const formattedDate = moment(date[0]).format("DD-MM-YYYY");
    // //console.log("from: " + formattedDate);
    let singleArr = [
      allPurchases[i].allPurchaseId,
      allPurchases[i].supplierId?.supplierName,
      formattedDate,
      allPurchases[i].referenceNo,
      allPurchases[i].description,
      allPurchases[i].totalAmount,
      allPurchases[i].purchaseDetails.length,
      allPurchases[i].purchaseType,
      allPurchases[i]._id,
    ];

    sortedPurchase.push(singleArr);
  }

  //console.log(manageFilterByDate);
  const sortedManagePurchase = [];
  for (let i = 0; i < manageFilterByDate?.data?.length; i++) {
    let date = manageFilterByDate?.data[i].purchaseDate.split("T");
    const formattedDate = moment(date[0]).format("DD-MM-YYYY");
    // //console.log("from: " + formattedDate);
    let singleArr = [
      manageFilterByDate?.data[i].allPurchaseId,
      manageFilterByDate?.data[i].supplierId?.supplierName,
      formattedDate,
      manageFilterByDate?.data[i].referenceNo,
      manageFilterByDate?.data[i].description,
      manageFilterByDate?.data[i].totalAmount,
      manageFilterByDate?.data[i].purchaseDetails.length,
      manageFilterByDate?.data[i].purchaseType,
      manageFilterByDate?.data[i]._id,
    ];

    sortedManagePurchase.push(singleArr);
  }

  // //console.log(allPurchases);
  const filterThroughDate = () => {
    setHelper(true);
    managePurchaseFilterByDate(start, end);
  };

  const clearFilter = () => {
    setHelper(false);
  };

  //console.log(manageFilterByDate);

  function handleClick(params) {
    history.push(params);
  }
  const load = () => {
    if (isLoading) {
      return (
        <>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ToastContainer />
                        <img
                          src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif"
                          style={{ backgroundColor: "white !", width: "700px" }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Manage Purchase" pageTitle="Purchase" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">Manage Purchase</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Link to="/Payment" className="text-white">
                            <Button
                              color="success"
                              className=" add-btn me-1"
                              // onClick={() => tog_list()}
                              //  id="create-btn"
                            >
                              <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                              Show Payment{" "}
                            </Button>
                          </Link>
                        </div>
                        <div></div>
                        <div></div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="StoreList">
                    <Row>
                      <Col md={3}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            From
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStart(date)}
                            value={start}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            To
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEnd(date)}
                            value={end}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={filterThroughDate}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Filter By Date{" "}
                          </Button>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={clearFilter}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Clear filter{" "}
                          </Button>
                        </div>
                      </Col>
                      {/* <Col md={3}>
                        <div className="mb-3">
                          <label>Type</label>
                          <select className="form-select " id="itemtype">
                            <option selected>Choose...</option>
                            <option value="All Purchases">All Purchases</option>
                            <option value="Purchase Receive">
                              Purchase Receive
                            </option>
                            <option value="Purchase Return">
                              Purchase Return
                            </option>
                          </select>
                        </div>
                      </Col> */}
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      {/* {allPurchases.length > 0 && (
                        <Grid
                          data={
                            helper === true
                              ? sortedManagePurchase
                              : sortedPurchase
                          }
                          columns={[
                            "ID",
                            "Supplier",
                            "Date",
                            "Ref",
                            "Description",
                            "Total Amount",
                            "Total Items",
                            "Type",
                            {
                              name: "Actions",
                              width: "100px",
                              formatter: (cell, row) => {
                                return _(
                                  <>
                                    <UncontrolledDropdown className="dropdown d-inline-block">
                                      <DropdownToggle
                                        className="btn btn-soft-secondary btn-sm"
                                        tag="button"
                                      >
                                        <i className="ri-more-fill align-middle"></i>
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-end">
                                        <Row>
                                          <DropdownItem
                                            className="edit-item-btn"
                                            
                                          >
                                            <span
                                            
                                              onClick={() =>
                                                handleClick(
                                                  row._cells[7].data ==
                                                    "purchase receive"
                                                    ? `/purchaseReceiveUpdate/edit/${cell}/${row._cells[7].data}`
                                                    : `#`
                                                )
                                              }
                                            >
                                              {" "}
                                              <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                                              Edit{" "}
                                            </span>
                                           
                                          </DropdownItem>

                                          <DropdownItem
                                            className="remove-item-btn"
                                            onClick={() =>
                                              deletePuchase(cell, row)
                                            }
                                          >
                                            <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                            Delete
                                          </DropdownItem>
                                        </Row>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </>
                                );
                              },
                            },
                          ]}
                          search={true}
                          pagination={{ enabled: true, limit: 10 }}
                        />
                      )} */}
                    </div>
                    {load()}

                    <TableContainer
                      columns={columns}
                      data={
                        helper === true
                          ? manageFilterByDate
                          : allPurchases || []
                      }
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      
                      // handleCustomerClick={handleCustomerClicks}
                      // isCustomerFilter={true}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => delPurchase()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ManagePurchase;
