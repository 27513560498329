import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import { LoginContex } from "../../context/loginContext/LoginContext";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import images   ../ParticlesAuth
// import logoLight from "../../../assets/images/logo-light.png";

const BasicTwosVerify = () => {
  document.title =
    "Two Step Verification | Velzon - React Admin & Dashboard Template";
  const {
    getTheOtpNumber,
    User,
    confirmOTPP,
    Otp,
    OTPNumber,
    isLoading,
    fetchSidebarMenus,
    removeFromLocalStorage,
    removeIDFromLocalStorage,
    cleaState,
    theUser,
    resetOTPnum,
  } = useContext(LoginContex);

  const [firstDigit, setDigit] = useState("");
  const [secondDigit, setSecondDigit] = useState("");
  const [thirdDigit, setthirdDigit] = useState("");
  const [fourthDigit, setfourthDigit] = useState("");
  const [compo, setCompo] = useState(
    firstDigit + secondDigit + thirdDigit + fourthDigit
  );

  const history = useHistory();

  //   useEffect(() => {
  //     if (User) {
  //       history.push("/dashboard");

  //     }
  //   }, []);
  // //console.log(firstDigit,secondDigit,thirdDigit,fourthDigit)
  //console.log(OTPNumber);
  useEffect(() => {}, []);

  // const user = localStorage.getItem("user");
  // const idd= JSON.parse(user)
  // //console.log(idd)
  //console.log(User);
  useEffect(() => {
    fetchSidebarMenus(User?.user?._id);
  }, [User]);
  const id = localStorage.getItem("_id");
  const userId = id && JSON.parse(id);
  const the_otp_number = firstDigit + secondDigit + thirdDigit + fourthDigit;

  //console.log(userId);
  //console.log(Otp);
  //console.log(OTPNumber);
  //console.log(the_otp_number);

  //console.log(Otp);
  //console.log(OTPNumber, the_otp_number);

  const gett = () => {
    localStorage.getItem("user");
  };
  const removeData = () => {
    removeFromLocalStorage();
    removeIDFromLocalStorage();
    cleaState();
    resetOTPnum();

    history.push("/login");
  };

  const getInputElement = (index) => {
    return document.getElementById("digit" + index + "-input");
  };

  const moveToNext = (index) => {
    if (getInputElement(index).value.length === 1) {
      if (index !== 4) {
        getInputElement(index + 1).focus();
      } else {
        getInputElement(index).blur();
        // Submit code
        //console.log("submit code");
      }
    }
  };
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const showToastSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const confirmOTP = () => {
    //console.log(the_otp_number);
    //console.log(Otp);
    //console.log(OTPNumber);
    if (!firstDigit || !secondDigit || !thirdDigit || !fourthDigit) {
      showToastMessage("Please complete the four digit of the OTP number");
      return;
    }
    const data = {
      userid: userId,
      OTP: the_otp_number,
    };
      confirmOTPP(data);
    fetchSidebarMenus(User?.user?._id);
    // history.push("/dashboard");
    // <Redirect to={{ pathname: "/dashboard"}} />

    // if (OTPNumber == the_otp_number) {
    //   // showToastSuccess("Navigating to the dashboard ...")
    //   //console.log(the_otp_number, OTPNumber);
    //   //console.log("MATCHINGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG");
    

    //   //console.log(User?.user?._id);
    //   fetchSidebarMenus(User?.user?._id);

    //   history.push("/dashboard");
    //   //console.log(the_otp_number, OTPNumber);
    // } else {
    //   //console.log("dont match");
    //   //console.log(User?.OTP);
    //   // showToastMessage("Wrong credentials")
    // }


  };

  return (
    <React.Fragment>
      <div className="auth-page-wrapper">
        <ParticlesAuth>
          <div className="auth-page-content">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center mt-sm-5 mb-4 text-white-50">
                    <div>
                      <ToastContainer />
                      <Link
                        to="/dashboard"
                        className="d-inline-block auth-logo"
                      >
                        {/* <img src={logoLight} alt="" height="20" /> */}
                      </Link>
                    </div>
                    <p className="mt-3 fs-15 fw-medium">Asal </p>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="mt-4">
                    <CardBody className="p-4  text-white" style={{backgroundColor:"#404258"}}>
                      <div className="mb-4">
                        <div className="avatar-lg mx-auto">
                          <div className="avatar-title bg-light text-primary display-5 rounded-circle">
                            <i className="ri-mail-line"></i>
                          </div>
                        </div>
                      </div>

                      <div className="p-2 mt-4 ">
                        <div className="text-white text-center mb-4 mx-lg-3">
                          <h4 className="text-white">Verify Your Number</h4>
                          <p>
                            Please enter the 4 digit code sent to{" "}
                            <span className="fw-semibold">your phone</span>
                          </p>
                        </div>

                        <form>
                          <Row>
                            <Col className="col-3">
                              <div className="mb-3">
                                <label
                                  htmlFor="digit1-input"
                                  className="visually-hidden"
                                >
                                  Digit 1
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg bg-light border-light text-center text-black"
                                  maxLength="1"
                                  onChange={(e) => setDigit(e.target.value)}
                                  id="digit1-input"
                                  onKeyUp={() => moveToNext(1)}
                                />
                              </div>
                            </Col>

                            <Col className="col-3">
                              <div className="mb-3">
                                <label
                                  htmlFor="digit2-input"
                                  className="visually-hidden"
                                >
                                  Digit 2
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg bg-light border-light text-center"
                                  maxLength="1"
                                  id="digit2-input"
                                  onChange={(e) =>
                                    setSecondDigit(e.target.value)
                                  }
                                  onKeyUp={() => moveToNext(2)}
                                />
                              </div>
                            </Col>

                            <Col className="col-3">
                              <div className="mb-3">
                                <label
                                  htmlFor="digit3-input"
                                  className="visually-hidden"
                                >
                                  Digit 3
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg bg-light border-light text-center"
                                  maxLength="1"
                                  id="digit3-input"
                                  onChange={(e) =>
                                    setthirdDigit(e.target.value)
                                  }
                                  onKeyUp={() => moveToNext(3)}
                                />
                              </div>
                            </Col>

                            <Col className="col-3">
                              <div className="mb-3">
                                <label
                                  htmlFor="digit4-input"
                                  className="visually-hidden"
                                >
                                  Digit 4
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg bg-light border-light text-center"
                                  maxLength="1"
                                  id="digit4-input"
                                  onChange={(e) =>
                                    setfourthDigit(e.target.value)
                                  }
                                  onKeyUp={() => moveToNext(4)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </form>
                        <div className="mt-3">
                          {/* <Button type="submit" color="success" className="w-100" disabled={isLoading} onClick={()=>confirmOTP()}>Confirm</Button> */}
                          {isLoading ? <button
                            disabled
                            type="button"
                            className="btn btn-success btn-load w-100"
                          >
                            <span className="d-flex align-items-center">
                              <span
                                className="spinner-border flex-shrink-0"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </span>
                              <span className="flex-grow-1 ms-2 text-center">
                                Confirming...
                              </span>
                            </span>
                          </button> :  <Button
                            type="submit"
                            color="success"
                            className="w-100"
                            onClick={() => confirmOTP()}
                          >
                            Confirm
                          </Button>}
                          
                        
                        </div>
                      </div>

                      <p
                        className="text-center text-white"
                        style={{ cursor: "pointer" , color:"white"}}
                        onClick={() => removeData()}
                      >
                        {" "}
                        Login{" "}
                      </p>
                    </CardBody>
                  </Card>
                  <div className="mt-4 text-center"></div>
                </Col>
              </Row>
            </Container>
          </div>
        </ParticlesAuth>
      </div>
    </React.Fragment>
  );
};

export default BasicTwosVerify;
