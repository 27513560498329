import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ChannelPackage = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [channelPackageName, setcardPackageName] = useState("");
  const [channelPackageDesc, setcardPackageDesc] = useState("");
  const [numberOfChannels, setnumberOfChannels] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [_id, setId] = useState("");
  const {
    registerPackage,
    deletePackage,
    fetchChanelPackages,
    channelPackage,
    updateChannelPackage,
    isLoading,User
  } = useContext(LoginContex);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = channelPackage.filter(
    (item) =>
      item.channelPackageName &&
      item.channelPackageName.toLowerCase().includes(filterText.toLowerCase())
  );
  //console.log(filteredItems);
  //console.log(filterText);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    fetchChanelPackages();
  }, []);

  document.title = "Channel Package ";
  let userId = User?.data?.user?._id || User?.user?._id;
  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> Packages</h6>,
      selector: (row) => row.channelPackageName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Number Of Channel</h6>,
      selector: (row) => row.numberOfChannels,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Discription</h6>,
      selector: (row) => row.channelPackageDesc,
    },

   
  ];

  const handlerSubmit = (e) => {
    //console.log("first");
    e.preventDefault();
    if (!isEditing) {
      const data = { numberOfChannels, channelPackageDesc, channelPackageName, userId };
      if (!numberOfChannels || !channelPackageName) {
        return;
      }
      //console.log(data);
      registerPackage(data);
      setmodal_list(false);
    }
    if (isEditing) {
      const data = {
        numberOfChannels,
        channelPackageDesc,
        channelPackageName,
        id: _id,
      };
      if (!numberOfChannels || !channelPackageName) {
        return;
      }

      updateChannelPackage(data);
      setmodal_list(false);
    }
  };

  const deleteChanel = (data) => {
    setId(data._id);
    setmodal_delete(true);
  };

  const deleteChannels = () => {
    deletePackage(_id);
    setmodal_delete(false);
  };
  //console.log(isEditing);
  const updateChanellForm = (data) => {
    setcardPackageName(data.channelPackageName);
    setcardPackageDesc(data.channelPackageDesc);
    setnumberOfChannels(data.numberOfChannels);
    setId(data._id);
    setmodal_list(true);
    setEditing(true);
  };
  //console.log(channelPackage);
  const addModal = () => {
    tog_list();
    setEditing(false);
    setcardPackageName("");
    setcardPackageDesc("");
    setnumberOfChannels("");
  };

  const load = () => {
    if (isLoading) {
      return (
        <>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <Card>
                    <ToastContainer />
                    <CardBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif"
                          style={{ backgroundColor: "white !", width: "700px" }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Channel Package " pageTitle="Registration" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">Channel Package</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add Package
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  {load()}
                  <div id="Purchasestable">
                    <Row className="g-4 mb-3"></Row>
                    {channelPackage.length > 0 && (
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        selectableRows
                        persistTableHead
                      />
                    )}
                    ;
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Channel Package Distribution From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Add Channel Packages</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <div className="mb-3">
                <label className="form-label">
                  Package <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="distribution_date-field"
                  className="form-control"
                  placeholder="Enter Package Name"
                  required
                  onChange={(e) => setcardPackageName(e.target.value)}
                  value={channelPackageName}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">
                  Number of Channels <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  id="number_of_channels-field"
                  className="form-control"
                  placeholder="Enter  Number of Channels"
                  required
                  onChange={(e) => setnumberOfChannels(e.target.value)}
                  value={numberOfChannels}
                />
              </div>

              <div className="mb-3">
                <label className="form-label"> Discription</label>
                <textarea
                  type="text"
                  id="Description-field"
                  className="form-control"
                  rows="1"
                  onChange={(e) => setcardPackageDesc(e.target.value)}
                  value={channelPackageDesc}
                ></textarea>
              </div>
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>

              <button type="submit" className="btn btn-success" id="add-btn">
                {isEditing ? "Update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => deleteChannels()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ChannelPackage;
