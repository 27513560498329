import React, { useState, useEffect, useContext, useMemo } from "react";
import { Grid, _ } from "gridjs-react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import TableContainer from "../../Components/Common/TableContainer";
import "react-toastify/dist/ReactToastify.css";
const AddNewMenus = () => {
  const {
    fetchMenus,
    menus,
    addNewAdmin
  } = useContext(LoginContex);


  const [main_menu_id, set_main_menu_id] = useState('')
  const [main_menu_name, set_main_menu_name] = useState('')
  const [sub_menu_name, set_sub_menu_name] = useState('')
  const [status, setStatus] = useState(false)
  const [id, setId] = useState('')
  const [label, setlabel] = useState('')
  const [link, setlink] = useState('')
  const [parentId, setparentId] = useState('')

  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const addModal = () => {
    setmodal_list(!modal_list)
  }

  useEffect(() => {
    fetchMenus()

  }, [])
  const handleSubmit = (e) => {
    e.preventDefault()
    const testing = {
      mainMenuId: main_menu_id?.value,
      mainMenuName: main_menu_name.value,
      submenuName: sub_menu_name,
      status: status,
      id: id,
      label: label,
      link: link,
      parentId: parentId,
      sqn: Math.floor(Math.random() * 1000)
    }

    addNewAdmin(testing)

  }

  const itemArr = [];

  for (let i = 0; i < menus.length; i++) {
    // //console.log(menus[i]);

    var dropdownList = {
      value: menus[i]?._id,
      label: ` ${menus[i]?.title} `,
    
    };
    // //console.log( menus[i]?._id);

    itemArr.push(dropdownList);
  }
  const itemArr2 = []
  for (let i = 0; i < menus.length; i++) {
    // //console.log(menus[i]);

    var dropdownList = {
      value: menus[i]?.title,
      label: ` ${menus[i]?.title} `,

    };

    itemArr2.push(dropdownList);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Menus Page" pageTitle="Menus" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">Add New Menu</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>

                  <div id="StoreList">
                    <Row className="g-4 mb-3"></Row>
                  </div>
                  {/* </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Add New Menus</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <form
          onSubmit={handleSubmit}

        >
          <ModalBody>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label>
                    Main Menu ID  <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={itemArr}
                    onChange={(choice) => set_main_menu_id(choice)}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        text: "orangered",
                        primary25: "hotpink",
                        primary: "black",
                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>
                    Main Menu Name  <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={itemArr2}
                    onChange={(choice) => set_main_menu_name(choice)}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        text: "orangered",
                        primary25: "hotpink",
                        primary: "black",
                      },
                    })}
                  />
                </div>
              </Col>
            </Row>
            <Row>


              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    Sub Menu   <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="location-field"
                    className="form-control"
                    placeholder="Enter Sub Menu "
                    required
                    onChange={(e) => set_sub_menu_name(e.target.value)}
                    value={sub_menu_name}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="store_name-field" className="form-label">
                    Status <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="store_name-field"
                    className="form-control"
                    placeholder="Enter Status"
                    required
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                    readOnly
                  />
                </div>
              </Col>
            </Row>
            <Row>

              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    ID <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="location-field"
                    className="form-control"
                    placeholder="Enter ID"
                    required
                    onChange={(e) => setId(e.target.value)}
                    value={id}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="store_name-field" className="form-label">
                    label <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="store_name-field"
                    className="form-control"
                    placeholder="Enter label"
                    required
                    onChange={(e) => setlabel(e.target.value)}
                    value={label}
                  />
                </div>
              </Col>
            </Row>
            <Row>

              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    Link <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="location-field"
                    className="form-control"
                    placeholder="Enter Link"
                    required
                    onChange={(e) => setlink(e.target.value)}
                    value={link}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="store_name-field" className="form-label">
                    Parent Id <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="store_name-field"
                    className="form-control"
                    placeholder="Enter Parent Id"
                    required
                    onChange={(e) => setparentId(e.target.value)}
                    value={parentId}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {/* {isEditing ? "update" : "Save"} */}
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>


      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            //   onClick={() => delStore()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddNewMenus;