import React, { useState, useEffect, useContext, useMemo } from "react";
import { Grid, _ } from "gridjs-react";
import DataTable from "react-data-table-component";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Input,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import  moment from 'moment';

const NasiyeReport = () => {
  //LOCAL states

  const [salesAgentName, setsalesAgentName] = useState("");
  const [salesAgentPhone, setsalesAgentPhone] = useState("");
  const [salesAgentAddress, setsalesAgentAddress] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [_id, set_ID] = useState("");
  const {
    fetchCustomers,
    registerAgent,
    fetchAgents,
    agents,
    deleteAgents,
    updateAgents,
    isLoading,User,fetchNasiyeReport, nasiyeReport
  } = useContext(LoginContex);

  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    fetchCustomers();
    fetchAgents();
    fetchNasiyeReport()
  }, []);
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  document.title = "Nasiye Report ";
  let userId = User?.data?.user?._id || User?.user?._id;
  const columns = useMemo(
    () => [
      {
        Header: "Mobile",
        accessor: "phoneNumer",
        filterable: false,
      },
      {
        Header: "subscriptionId",
        accessor: "subscriptionId",
        filterable: false,
      },
      {
        Header: "Amount",
        accessor: "amount",
        filterable: false,
      },
      {
        Header: "subscriptionType",
        accessor: "substcriptionType",
        filterable: false,
      },
      {
        Header: "TransDate",
        accessor: "createdAt",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      
    ]
    // [handleCustomerClick]
  );
  //register customer
  //console.log(isUpdating);
  const handlerSubmit = (e) => {
    e.preventDefault();

    if (!isUpdating) {
      const data = {
        salesAgentAddress,
        salesAgentPhone,
        salesAgentName,
        userId
      };

      if (!salesAgentName || !salesAgentPhone) {
        return;
      }
      registerAgent(data);
      setmodal_list(false);
      setsalesAgentName("");
      setsalesAgentPhone("");
      setsalesAgentAddress("");
      //console.log(data);
    }
    if (isUpdating) {
      //console.log("updd");
      const data = {
        salesAgentAddress,
        salesAgentPhone,
        salesAgentName,
        id: _id,
      };

      if (!salesAgentName || !salesAgentPhone) {
        return;
      }
      // updateCustomer(data);
      updateAgents(data);
      setmodal_list(false);
      setsalesAgentName("");
      setsalesAgentPhone("");
      setsalesAgentAddress("");
    }
  };

  //delet customer

  const deleteCus = (data) => {
    setmodal_delete(true);
    set_ID(data._id);
  };
  const deleteAgentss = () => {
    deleteAgents(_id);
    setmodal_delete(false);
  };

  // update form

  const updateForm = (data) => {
    setmodal_list(true);
    setsalesAgentName(data.salesAgentName);
    setsalesAgentPhone(data.salesAgentPhone);
    setsalesAgentAddress(data.salesAgentAddress);
    setIsUpdating(true);
    set_ID(data._id);
  };

  const addModal = () => {
    tog_list();
    setIsUpdating(false);
    setsalesAgentName("");
    setsalesAgentPhone("");
    setsalesAgentAddress("");
  };
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handleChangePhone = (e) => {
    const limit = 10;

    // Here we are checking if the length is equal to 9
    if (e.target.value.length === 10) {
      showToastMessage("Phone number should not exceed 10 digits!");
      setsalesAgentPhone(e.target.value.slice(0, limit));
    }
    setsalesAgentPhone(e.target.value.slice(0, limit));
  };
  const sortAgents = [];
  for (let i = 0; i < agents?.length; i++) {
    let singleArr = [
      agents[i].salesAgentName,
      agents[i].salesAgentPhone,
      agents[i].salesAgentAddress,
      agents[i]._id,
    ];

    sortAgents.push(singleArr);
  }


  const CvsData = [];
for (let i = 0; i < nasiyeReport?.length; i++) {
  // //console.log(nasiyeReport[i]._id);

 
    var dropdownList = {
      phoneNumer: nasiyeReport[i]?.phoneNumer,
      subscriptionId: ` ${nasiyeReport[i]?.subscriptionId}`,
      amount: ` ${nasiyeReport?.[i]?.amount}`,
      substcriptionType: ` ${nasiyeReport?.[i]?.substcriptionType}`,
     
     
    
      Date: ` ${moment(nasiyeReport[i]?.createAt).utc().format("DD-MM-YYYY")}`,
     

    };

 

  CvsData.push(dropdownList);
}

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(CvsData?.[0] || []);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

// ** Downloads CSV
function downloadCSV(array) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv === null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}
  
  const load = () => {
    if (isLoading) {
      return (
        <>
          <div className="page-content">
            <Container fluid>
              <BreadCrumb title="Manage Cables" pageTitle="Billing" />
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif"
                          style={{ backgroundColor: "white !", width: "700px" }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Nasiye Report" pageTitle="Report" />
          <Row>
            <Col lg={12}>
              <Card>
              <div className="form-label mx-4" style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end"}}>
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={()=>{
                              downloadCSV(CvsData)
                            }}
                            type="button"
                            //  id="create-btn"
                          > 
                            <i className="fa fa-print mx-2" aria-hidden="true"> </i>  
                            Download Excel
                          </Button>
                        </div>

                <CardBody>
                  {load()}
                  <div id="customerList">
                    <Row className="g-4 mb-3"></Row>

                     
                      <TableContainer
                        columns={columns}
                        data={nasiyeReport || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        // handleCustomerClick={handleCustomerClicks}
                        // isCustomerFilter={true}
                      />
                    
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* customer Registration From */}

    

   
    </React.Fragment>
  );
};

export default NasiyeReport;
