import React, { useState, useEffect, useContext, useMemo } from "react";
import Select from "react-select";
import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import DataTable from "react-data-table-component";

const OpenIptv = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [modal_list2, setmodal_list2] = useState(false);
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardPackage, setCardPackage] = useState("");
  const [customer, setCustomer] = useState();
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [openDate, setOpenDate] = useState("");
  const [closeDate, setCloseDate] = useState("");
  const [usingDays, setUsingDays] = useState("");
  const [amount, setAmount] = useState(8);
  const [Discription, setdescription] = useState("");
  const { openAnalogCardRegister, customers } = useContext(LoginContex);
  const [type, setTyp] = useState("text");
  const [customerInfo, setCustomerInfo] = useState();
  const [customerId, setCustomerId] = useState();
  const [Custom, setCustom] = useState("text");
  const [channelId, setChanell] = useState();
  const [cycle, setCycle] = useState("");
  const [numberOfDays, setNumberOfDays] = useState();
  const [numberOfMonths, setNumberOfMonths] = useState();
  const [billAmount, setBillAmount] = useState();
  const [IptvAmount, setIptvAmount] = useState();
  const [niceObject, setniceObject] = useState("");
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const tog_list2 = () => {
    setmodal_list2(!modal_list2);
  };

  document.title = "Open IPTV ";

  const {
    fetchOpenCards,
    OpenCards,

    cardList,
    channelPackage,
    fetchChanelPackages,
    closeAnalogCard,
    isLoading,
    User,
    openIptvs,
    openIptv,
    closeIptv,
  } = useContext(LoginContex);

  useEffect(() => {
    fetchOpenCards();
    // ();
    fetchChanelPackages();
    openIptvs();
    openIptvs(true);
  }, []);
  let The_data = openIptv.filter((iptv) => iptv.status != null);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = The_data.filter(
    (item) =>
    (item?.customerName && item?.customerName?.toLowerCase().includes(filterText.toLowerCase())) &&
      item.customerName?.toLowerCase().includes(filterText.toLowerCase()) && item.customerPhone ||
      item.customerPhone?.toString().toLowerCase().includes(filterText.toLowerCase())   && item.customerTell ||
      item.customerTell?.toString().toLowerCase().includes(filterText.toLowerCase())
  );
  //console.log(filteredItems);
  //console.log(filterText);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    fetchChanelPackages();
  }, []);

  document.title = "IPTV MANAGE ";

  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> No.</h6>,
      selector: (row,index) => index +1,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> customerName</h6>,
      selector: (row) => row.customerName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Phone Number</h6>,
      selector: (row) => row.customerPhone,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Address</h6>,
      selector: (row) => row.customerAddress,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>customerTell</h6>,
      selector: (row) => row.customerTell,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> subscriptionId</h6>,
      selector: (row) => row.subscriptionId,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> registerDate</h6>,
      selector: (row) => moment(row.registerDate).utc().format("DD-MM-YYYY"), 
    },
    // {
    //   name: <h6 style={{ fontWeight: "bold" }}> status</h6>,
    //   selector: (row) => row.status == true ?"Opened" :"Not  Opened",
    // },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Actions</h6>,
      cell: (row) => (
        <>
       
           
     
            <ul className="list-inline hstack gap-2 mb-0">
              {row.status ==false ?
              <li className="list-inline-item edit" title="Edit">
                <button

                  className="text-primary d-inline-block edit-item-btn btn btn-danger text-white"
                  onClick={() => {
                    const customerData = row;
                    someFn(customerData);
                  }}
                >
                 Disable
                </button>
              </li>:

              <li className="list-inline-item edit" title="Edit">
                <button

                  className="text-primary d-inline-block edit-item-btn btn btn-success text-white"
                  onClick={() => {
                    const customerData =row;
                    //console.log(row)
                    opeen(customerData);
                  }}
                >
                Open
                </button>
              </li>
                }
            </ul>
       
        
          
         
        </>
      ),
    },
  ];

  //console.log(cardList);

  //console.log(openIptv);

  //console.log(OpenCards);
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  let userId = User?.data?.user?._id || User?.user?._id;
  // const columns = useMemo(
  //   () => [
  //     {
  //       Header: "Name",
  //       accessor: "customerName",
  //       filterable: false,
  //     },
  //     {
  //       Header: "Phone Number",
  //       accessor: "customerPhone",
  //       filterable: false,
  //     },
  //     {
  //       Header: "Home Number",
  //       accessor: "customerTell",
  //       filterable: false,
  //     },
  //     {
  //       Header: "Address",
  //       accessor: "customerAddress",
  //       filterable: false,
  //     },
  //     {
  //       Header: "Subscription",
  //       accessor: "subscriptionId",
  //       filterable: false,
  //     },

  //     {
  //       Header: "Issue Date",
  //       accessor: "registerDate",
  //       filterable: false,
  //       Cell: (cell) => <>{handleValidDate(cell.value)}</>,
  //     },

      // {
      //   Header: "Action",
      //   Cell: (cellProps) => {
      //     return (
      //       <ul className="list-inline hstack gap-2 mb-0">
      //         {cellProps.row.original.status ==true ?
      //         <li className="list-inline-item edit" title="Edit">
      //           <button

      //             className="text-primary d-inline-block edit-item-btn btn btn-danger text-white"
      //             onClick={(row) => {
      //               const customerData = cellProps.row.original;
      //               someFn(customerData);
      //             }}
      //           >
      //            Disable
      //           </button>
      //         </li>:

      //         <li className="list-inline-item edit" title="Edit">
      //           <button

      //             className="text-primary d-inline-block edit-item-btn btn btn-success text-white"
      //             onClick={(row) => {
      //               const customerData = cellProps.row.original;
      //               someFn(customerData);
      //             }}
      //           >
      //           Open
      //           </button>
      //         </li>
      //           }
      //       </ul>
      //     );
      //   },
      // },
  //   ]
  //   // [handleCustomerClick]
  // );

  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handlerSubmitOpen = (e) => {
    //console.log(e);
    e.preventDefault();
    // if (
    //   !customerInfo?.cardNumber ||
    //   !customerInfo?.receiverType ||
    //   !customerInfo?.value ||
    //   !customerInfo?.value ||
   
    //   !cycle ||
    //   !openDate
    // ) {
    //   showToastMessage("please provide required values");
    //   return;
    // }

    //console.log(numberOfMonths);
    //console.log(closeDate);
    //console.log();
    //console.log("first");
    const data = {
      cardNumber: customerInfo?.cardNumber,
      receivertype: customerInfo?.receiverType,
      customerId: customerInfo?.value,
      noOfMonthDay: cycle == "month" ? numberOfMonths : numberOfDays,
      endDate:
        cycle === "month"
          ? addMonths(Number(numberOfMonths), dateee)
          : addDays(Number(numberOfDays)),
      startDate: openDate,

      openDate: openDate,
      billingCycle: cycle,
      billAmount:
        numberOfMonths > 0
          ? (numberOfMonths * 6).toFixed(2) * 1
          : (numberOfDays * 0.2).toFixed(2) * 1,
      channelPackageId: channelId,
      isManual: true,
      userId: userId,
    };
    e.target.reset();
    // openAnalogCardRegister(data);
    // setmodal_list(false);
    //console.log(data);

    // showToastMessage("succefully opened card");
  };

  const editPop = (data) => {
    //console.log(data);
    setmodal_list(true);
    setCardNumber(data.cardNumber);
    setBillingCycle(data.billingCycle);
    setCardType(data.receiverType);
    setOpenDate(data.open_date);
    // setCloseDate(data.closeDate);
    setCustomer(data.customerId.customerName);
    setCardPackage(data.channel_package);
  };
  var CvsData = [];
  const OptimizeCvcFn = () => {
    for (let i = 0; i < OpenCards?.length; i++) {
      //console.log(OpenCards[i]._id);

      var dropdownList = {
        customer: OpenCards[i]?.customerId?.customerName,
        phone: ` ${OpenCards[i]?.customerId?.customerPhone}`,
        address: ` ${OpenCards[i]?.customerId?.customerAddress}`,
        cardNumber: ` ${OpenCards[i]?.cardNumber}`,
        createdAt: ` ${OpenCards[i]?.createdAt}`,
      };

      CvsData.push(dropdownList);
    }
    return CvsData;
  };

  const calculation = useMemo(() => OptimizeCvcFn(), [OpenCards]);
  //console.log(CvsData);
  //console.log(calculation);
  //console.log(OpenCards);
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // ** Downloads CSV
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  const setbilling = (e) => {
    setBillingCycle(e);

    //console.log(e);
    let type = e;

    if (type === "Custom") {
      // document.getElementById("customfield").style.display = "block";
      // document.getElementById("billamount").setAttribute("md={4}");
    } else {
      // document.getElementById("customfield").style.display = "none";
      // document.getElementById("billamount").setAttribute("md={8}");
    }
  };

  let neeew = "11-11-2022";
  if (billingCycle === "monthly") {
    //console.log(openDate);
    const test = moment(openDate).utc().format("MM/DD/YYYY");
    //console.log(test);
    neeew = new Date(test);
    neeew.setDate(neeew.getDate() + 30);
    // neeew.setMonth(neeew.getMonth() + 1);
    // setCloseDate(setDate(openDate.getDate() + 5));
    // //console.log(closeDate);
    //console.log(neeew);
    // neeew.toLocaleDateString("en-US");
    // setCloseDate(neeew);
    // //console.log(closeDate);
  }

  // const test = moment(openDate).utc().format("MM/DD/YYYY");
  // const yep = new Date(test);
  // const day = yep.getDate() + 1;
  // const moonth = yep.getMonth() + 1;
  // const year = yep.getFullYear();
  // //console.log(yep);
  // //console.log(day);
  // //console.log(moonth);
  // //console.log(year);

  const customizeDate = (month = 0, dayy = 0) => {
    //console.log(month);
    const test = moment(openDate).utc().format("MM/DD/YYYY");
    const yep = new Date(test);
    const day = yep.getDate() + 1 + Number(dayy);
    const moonth = `${yep.setMonth(yep.getMonth() + Number(month))}`;
    const year = yep.getFullYear();

    //console.log(day, moonth, year);

    const foor = day + "-" + moonth + "-" + year;
    const f = moment(foor).format("D/MM/YYYY");

    setCloseDate(f);
    // //console.log(yep.getMonth());
  };

  //console.log(openDate);

  function dateWithMonthsDelay(months) {
    //console.log(months);
    const date = new Date(openDate);
    date.setMonth(date.getMonth() + months);
    // setCloseDate(date);

    return date;
  }

  //console.log(closeDate);
  // //console.log();
  // //console.log(neeew);
  //console.log("type is" + typeof neeew);

  const chan = () => {
    setTyp("date");
  };
  const CustomersArr = [];

  for (let i = 0; i < customers.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: customers[i]._id,
      label: ` ${customers[i].customerName}`,
    };

    CustomersArr.push(dropdownList);
  }
  // const sortCard = [];
  // for (let i = 0; i < OpenCards?.length; i++) {
  //   //console.log(OpenCards[i]?._id);
  //   let openDate = OpenCards[i].openDate.split("T");
  //   const formattedDate = moment(openDate[0]).format("DD-MM-YYYY");
  //   let endDate = OpenCards[i].endDate.split("T");
  //   const formattedDate2 = moment(endDate[0]).format("DD-MM-YYYY");
  //   let singleArr = [
  //     i + 1,
  //     OpenCards?.[i]?.customerId?.customerName,
  //     OpenCards[i]?.customerId?.customerPhone,
  //     OpenCards[i]?.customerId?.customerAddress,

  //     OpenCards[i]?.cardNumber,
  //     OpenCards[i]?.receivertype,
  //     formattedDate,
  //     formattedDate2,
  //     OpenCards[i]?._id,
  //   ];

  //   sortCard.push(singleArr);
  // }

  // //console.log(sortCard);

  const customerCards = [];

  for (let i = 0; i < cardList.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: cardList?.[i]?.dataCustomers?._id,
      cardNumber: cardList?.[i]?.cardNumber,
      receiverType: cardList?.[i]?.datareceivers?.itemType,
      label: `${cardList?.[i]?.dataCustomers?.customerName} -- ${cardList?.[i]?.dataCustomers?.cardNumber}`,
    };

    customerCards.push(dropdownList);
  }

  //console.log(cardList);
  //console.log(customerCards);

  function addMonths(numOfMonths, date = new Date()) {
    //console.log(numOfMonths);
    date.setMonth(date.getMonth() + numOfMonths);
    setCloseDate(date);

    return date;
  }

  function addDays(days) {
    var result = new Date(openDate);
    result.setDate(result.getDate() + days);
    setCloseDate(result);
    return result;
  }

  // //console.log(closeDate);
  //console.log(closeDate);
  // 👇️ Add 2 months to current Date
  // const result = addMonths(2);

  // 👇️ Add months to another date
  const dateee = new Date(openDate);
  const someFn = (row) => {
    //console.log(row);
    setmodal_delete(true);
    const selectedRow = {
      name: row.customerName,
      address: row.customerAddress,
      subscription: row.subscriptionId,

      _id: row._id,

    };
    setInfo(selectedRow);
  };

  //console.log(info);
  const closeCard = () => {
    const data = {
      status: true,
      subscriptionId: info.subscription,
    };
    //console.log(data);
    closeIptv(data);

    setStatus("");
    setmodal_delete(false);
  };

  const opeen = (row)=>{
    //console.log(row)
    setniceObject(row)
    setmodal_list(true)
  }
  const openingCardss = () => {
    const data = {
      status: false,
      subscriptionId: niceObject.subscriptionId,
      duration: cycle ? cycle : "27",
      Tell:niceObject.customerTell
    };
    //console.log(data);
    // //console.log(cycle)
    closeIptv(data);

    setStatus("");
    setmodal_list(false)
  };

  //console.log(niceObject)
  const load = () => {
    if (isLoading) {
      return (
        <>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ToastContainer />
                        <img
                          src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif"
                          style={{ backgroundColor: "white !", width: "700px" }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="IPTV Manage " pageTitle="IPTV Manage" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">IPTV Manage</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          {/* <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={tog_list2}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Open IPTV
                          </Button> */}
                          {/* <Button
                            color="success"
                            className="add-btn me-1 mx-2"
                            onClick={()=>downloadCSV(openIptv)}
                            id="create-btn"
                          >
                           <i className="fa fa-print mx-2" aria-hidden="true"></i>
                            Download Excell
                          </Button> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
     
                <CardBody>
                  <div id="Purchasestable">
                    <Row className="g-4 mb-3"></Row>

                    {/* <TableContainer
                        columns={columns}
                        data={The_data || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        loading={true}
                        
                        // handleCustomerClick={handleCustomerClicks}
                        // isCustomerFilter={true}
                      /> */}

                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                      progressPending={isLoading  }
                      progressComponent={<SpinnerBorder />}
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                    
                      persistTableHead
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Open Closed Card Distribution From */}

      <Modal
        isOpen={modal_list2}
        toggle={() => {
          tog_list2();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Open IPTV</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list2(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">Customer</label>
                  <Select
                    // className="bg-white"
                    options={customerCards}
                    onChange={(choice) =>
                      // getCustPhone(e.target.value, e.target)
                      setCustomerInfo(choice)
                    }
                    value={customerId}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">Card Number</label>
                  <input
                    type="text"
                    id="card_number-field"
                    className="form-control"
                    placeholder="Enter Card Number"
                    required
                    disabled
                    value={customerInfo?.cardNumber}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">Reciever Type</label>
                  <input
                    type="text"
                    id="card_number-field"
                    className="form-control"
                    placeholder="Enter Card Number"
                    required
                    disabled
                    value={customerInfo?.receiverType}
                  />
                </div>
              </Col>
              {/* <Col md={4}>
                <div className="mb-3">
                  <label>
                    Channel package<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select "
                    id="itemtype"
                    // value={billingCycle}
                    onChange={(e) => {
                      setChanell(e.target.value);
                      customizeDate(numberOfMonths, numberOfDays);
                    }}
                    required
                  >
                    <option>---choose option ---</option>
                    {channelPackage.map((chanel, i) => {
                      return (
                        <option key={i} value={chanel._id}>
                          {chanel.channelPackageName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </Col> */}

              <Col md={4}>
                <div className="mb-3">
                  <label>
                    Duration <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select "
                    id="itemtype"
                    // value={billingCycle}
                    required
                    onChange={(e) => setCycle(e.target.value)}
                  >
                    <option>---choose option ---</option>
                    <option value="1 month">1 month</option>
                    <option value="2 month">2 month</option>
                    <option value="3 month">3 month</option>
                    <option value="6 month">6 month</option>
                    <option value="1 year"> 1 year</option>
                  </select>
                </div>
              </Col>

              {/* {type === "Custom" && (
                 <Col md={4} style={{display: "none"}}>
                 <div className="mb-3">
                   <label className="form-label">
                     Custom <span className="text-danger">*</span>
                   </label>
 
                   <input
                     id="customfield"
                     className="form-control"
                     type="text"
                     name="custom"                   
                     onChange={(e) => setOpenDate(e.target.value)}
                     required
                   />
                 </div>
               </Col>
              )} */}

              {/* <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">
                    Open Date <span className="text-danger">*</span>
                  </label>

                  <Flatpickr
                    className="form-control"
                    required
                    placeholder="DD/MM/YYYY"
                    options={{
                      dateFormat: "d/m/Y",
                      defaultDate: "today",
                    }}
                    onChange={([date]) => {
                      setOpenDate(date);
                    }}
                    value={openDate}
                  />
                </div>
              </Col> */}
              {/* <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">
                    Close Date <span className="text-danger">*</span>
                  </label>

                  <Flatpickr
                    className="form-control"
                    required
                    placeholder="DD/MM/YYYY"
                    options={{
                      dateFormat: "d/m/Y",
                      defaultDate: "today",
                    }}
                    onChange={(e) => setCloseDate(e.target.value)}
                    value={moment(neeew).utc().format("MM/DD/YYYY")}
                  // onClick={chan}
                  />
                </div>
              </Col> */}
              {/* {cycle === "month" && (
                <Col id="billamount">
                  <div className="mb-3">
                    <label className="form-label">
                      month <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      id="amount-field"
                      className="form-control"
                      placeholder="Enter numbers of months"
                      required
                      // value={amount}\
                      onChange={(e) => {
                        setNumberOfDays("")
                        setNumberOfMonths(e.target.value);
                        if (cycle === "month") {
                          addMonths(Number(numberOfMonths), dateee);
                        }
                      }}
                    ></input>
                  </div>
                </Col>
              )} */}
              {/* {cycle === "day" && (
                <Col id="billamount">
                  <div className="mb-3">
                    <label className="form-label">
                      days <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      id="amount-field"
                      className="form-control"
                      placeholder="Enter number of days"
                      required
                      // value={amount}
                      onChange={(e) =>  {
                        setNumberOfMonths("")
                        setNumberOfDays(e.target.value)
                      }}
                    ></input>
                  </div>
                </Col>
              )} */}

              <Col id="billamount">
                <div className="mb-3">
                  <label className="form-label">
                    Bill amount <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="amount-field"
                    className="form-control"
                    placeholder="Enter Amount"
                    required
                    readOnly
                    // value={ numberOfMonths > 0 ? (numberOfMonths *6).toFixed(2) :(numberOfDays *0.2).toFixed(2)}
                    // onChange={(e) => setBillAmount(e.target.value)}
                  ></input>
                </div>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list2(false)}
              >
                Close
              </button>

              <Button type="submit" className="btn btn-success" onClick={handlerSubmitOpen}>
                Save
              </Button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
        style={{ width: "800px" }}
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className=" mx-4 mb-0">
                Are you Sure You want to Close{" "}
                {`  ${info?.name}'s subcription Id is ${info?.subscription}  who lives in ${info?.address}`}{" "}
                ?
              </p>
            </div>
            {/* <Col id="billamount" className="mt-4">
              <div className="mb-3">
                <label className="form-label">
                  Subscription Id <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="amount-field"
                  className="form-control"
                  placeholder="Enter description"
                  required
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                ></input>
              </div>
            </Col> */}
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              No
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => closeCard()}
            >
              Yes, Close it
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> OPEN IPTV</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={handlerSubmitOpen}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
              />
            </div>
            <Row>
              <Col md={4}>
                
              <div className="mb-3">
                  <label className="form-label">Customer</label>
                  <input
                    type="text"
                    id="phone_number-field"
                    className="form-control"
                    placeholder="Customer Name"
                    required
                    disabled
                    value={niceObject?.customerName}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">Phone Number</label>
                  <input
                    type="text"
                    id="phone_number-field"
                    className="form-control"
                    placeholder="Phone Number"
                    required
                    disabled
                    value={niceObject?.customerPhone}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">Lindline</label>
                  <input
                    type="text"
                    id="card_number-field"
                    className="form-control"
                    placeholder="Enter Customer Tel"
                    required
                    disabled
                    value={niceObject?.customerTell}
                  />
                </div>
              </Col>
              <Col id="billamount">
                <div className="mb-3">
                  <label className="form-label">
                    Subscription ID <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="amount-field"
                    className="form-control"
                    placeholder="Subscription ID"
                    value={niceObject?.subscriptionId}
                    disabled
                    required
                    // value={ numberOfMonths > 0 ? (numberOfMonths *6).toFixed(2) :(numberOfDays *0.2).toFixed(2)}
                    // onChange={(e) => setBillAmount(e.target.value)}
                  ></input>
                </div>
              </Col>

              <Col md={4}>
                <div className="mb-3">
                  <label>
                    Duration <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select "
                    id="itemtype"
                    // value={billingCycle}
                    required
                    onChange={(e) => {
                      setCycle(e.target.value);
                      const selectedOption =
                        e.target.options[e.target.selectedIndex];
                      const customAttribute =
                        selectedOption.dataset.customAttribute;
                      //console.log(customAttribute);
                      setIptvAmount(Number(customAttribute))
                    }}
                  >
                    <option>---choose option ---</option>
                    <option value="27" data-custom-attribute="1">
                      1 month
                    </option>
                    <option value="28" data-custom-attribute="3">
                      3 month
                    </option>
                    <option value="29" data-custom-attribute="6">
                      6 month
                    </option>
                    <option value="30" data-custom-attribute="12">
                      1 year
                    </option>
                  </select>
                </div>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>

              <Button
                type="submit"
                className="btn btn-success"
                onClick={() => openingCardss()}
              >
                Save
              </Button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default OpenIptv;

const SpinnerBorder = () => {
  return <Spinner className="my-2 text-center" />;
};
