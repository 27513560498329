import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link, useParams } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import Select from "react-select";

const PurchasesOrderUpdate = () => {
  const [tabales, setTabales] = useState();
  const [serviceList, setServiceList] = useState([
    {
      itemId: "",
      quantity: "",
      unitPrice: "",
      amount: "",
      description: "",
      QtyAvailable: "",
    },
  ]);

  const [itemData, setitemData] = useState();
  const { the_id } = useParams();

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: "white",

        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",

        // backgroundColor: isDisabled ? "red" : null,
      };
    },
  };

  ///local states

  const [todaydata, setTodayDate] = useState("");
  const [date, setDate] = useState("");

  const [amount, setAmount] = useState();
  const [additianalCost, setAdditianalCost] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [QtyAvailable, setQtyAvailable] = useState("");
  const [Qty, setQty] = useState("");
  const [modal_list, setmodal_list] = useState(false);
  const [yes, setYes] = useState(true);
  const [supplierId, setSupplierId] = useState("");
  const [storeId, setStoreId] = useState("");
  const [siteId, setSiteId] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [description, setdescription] = useState("");
  const [description2, setdescription2] = useState("");
  const [switchh, setSwitch] = useState("");

  const {
    suppliers,
    items,
    fetchItems,
    stores,
    fetchStores,
    sites,
    fetchSites,
    readProducts,
    products,
    registerPurchaseOrder,
    fetchQuantityAvailable,
    quantityAvailable,
    purchaseReceiveEdit,
    fetchPurchaseReceiveUpdateById,
  } = useContext(LoginContex);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  let yess = yes === "true";
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const focusedArr = purchaseReceiveEdit;

  useEffect(() => {
    fetchItems();
    fetchStores();
    fetchSites();
    readProducts();
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;
    setTodayDate(today);
    fetchPurchaseReceiveUpdateById(the_id);
    // setServiceList(focusedArr);
    setServiceList((pre) => [...serviceList, serviceList]);
  }, [the_id]);

  //console.log(serviceList);
  //   //console.log(purchaseReceiveEdit?.purchaseDetails);
  //console.log(focusedArr);

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;

  //console.log(date);

  document.title = "Purchase  ";

  // const amounts = serviceList[0].Qty;
  // //console.log(amounts);

  //

  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,
      {
        itemId: "",
        quantity: "",
        unitPrice: "",
        amount: "",
        description: "",
        QtyAvailable: "",
      },
    ]);
  };

  const handleServiceRemove = (index) => {
    const list = [...filteredArr];
    // list.splice(index, 1);
    filteredArr.splice(index, 1);
  };
  //   const purchaseAmount = serviceList?.reduce((accu, curr) => {
  //     return accu + curr["amount"];
  //   }, 0);
  const purchaseAmount = 5;
  const handleServiceChange = (e, index) => {
    fetchQuantityAvailable(serviceList[index]["itemId"]);

    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    list[index]["QtyAvailable"] = quantityAvailable.item_blance;
    fetchQuantityAvailable(serviceList[index]["itemId"]);
    list[index]["amount"] =
      serviceList[index]["quantity"] * serviceList[index]["unitPrice"];
    list[index]["purchaseAmount"] = purchaseAmount;
    if (e.target.name === "itemId") {
      fetchQuantityAvailable(serviceList[index]["itemId"]);
    }
  };

  //console.log(serviceList);
  //console.log(quantityAvailable.item_blance);
  // setServiceList([...serviceList,])

  // //console.log(purchaseAmount);

  const data = {
    purchaseOrderDate: date,
    supplierId: supplierId.value,
    referenceNo: referenceNo,
    description: description,
    purchaseOrderAmount: purchaseAmount,
    purchaseOrderDetails: serviceList,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    registerPurchaseOrder(data);
    setDate("");
    setSupplierId("...Choose supplier...");
    setReferenceNo("");
    setdescription("");
    // setServiceList("");
    setServiceList([
      {
        itemId: "",
        quantity: 0,
        unitPrice: 0,
        amount: 0,
        description: "",
        QtyAvailable: "",
      },
    ]);
    e.target.reset();
    setTimeout(() => {}, 2000);

    serviceList.quantity = "";
    serviceList.unitPrice = "";
  };
  // soloving dropdowns
  // const suppliersArr = [{ label: "Choose item", value: "choose item" }];
  const suppliersArr = [];

  for (let i = 0; i < items.length; i++) {
    //console.log(items[i]._id);

    var dropdownList = {
      value: items[i]._id,
      label: items[i].supplierName,
    };

    suppliersArr.push(dropdownList);
  }

  /////////////////////////////////////////////
  //console.log(focusedArr);
  const focus = focusedArr?.purchaseDetails;
  let filteredArr = [];

  //console.log(focus);

  for (let i = 0; i < focus?.length; i++) {
    //console.log(focus[i]);
    //console.log("kkkkkkkkkkkkkkkkkkkkkk");
    const theOb = {
      itemId: focus[i]?.itemId?._id,
      itemName: focus[i]?.itemId?.itemName,
      rate: focus[i]?.itemId?.sellingPrice,
      quantity: focus[i].quantity,
      description: focus[i].description,
      referenceNo: focus[i].referenceNo,
    };

    filteredArr.push(theOb);
  }

  //console.log(filteredArr);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Purchases Order " pageTitle="Purchase" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">
                            Add Purchases Order
                          </h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Link
                            to="/ManagePurchaseOrder"
                            className="text-white"
                          >
                            <Button
                              color="success"
                              className="add-btn me-1"
                              // onClick={() => tog_list()}
                              //  id="create-btn"
                            >
                              <i className=" ri-pencil-line align-bottom me-1"></i>{" "}
                              Manage Pending Orders
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchases Ordertable">
                    <form onSubmit={handleSubmit}>
                      <ModalBody>
                        <div
                          className="mb-3"
                          id="modal-id"
                          style={{ display: "none" }}
                        >
                          <label htmlFor="id-field" className="form-label">
                            ID
                          </label>
                          <input
                            type="text"
                            id="id-field"
                            className="form-control"
                            placeholder="ID"
                            readOnly
                          />
                        </div>
                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>
                                Supplier <span className="text-danger">*</span>
                              </label>
                              <Select
                                className="bg-white"
                                options={suppliersArr}
                                onChange={(choice) => setSupplierId(choice)}
                                defaultValue={
                                  focusedArr?.supplierId?.supplierName
                                }
                                required
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    // text: "orangered",
                                    // primary25: "hotpink",
                                    // primary: "black",
                                  },
                                })}
                              />

                              {/* <option value="choose supplier">
                                  -----choose supplier------
                                </option> */}

                              {/* <option value="0">
                                  &hellip; Choose supplier &hellip;
                                </option>
                                {items.map((item, index) => {
                                  return (
                                    <option key={index} value={item._id}>
                                      {item.supplierName}
                                    </option>
                                  );
                                })}
                              </select> */}
                            </div>
                          </Col>
                          <Col md={2}>
                            <div>
                              <Label
                                htmlFor="Purchases OrderDate"
                                className="form-label"
                              >
                                Purchase Date{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Flatpickr
                                className="form-control"
                                placeholder="DD/MM/YYYY"
                                options={{
                                  dateFormat: "d/m/Y",
                                }}
                                onChange={([date]) => setDate(date)}
                                required
                                value={date}
                              />
                            </div>
                          </Col>

                          <Col md={2}>
                            <div className="mb-3">
                              <label className="form-label">Reference No</label>
                              <input
                                type="text"
                                id="referenceno-field"
                                className="form-control"
                                placeholder="Enter Reference No"
                                required
                                defaultValue={focusedArr.referenceNo}
                                onChange={(e) => setReferenceNo(e.target.value)}
                                value={referenceNo}
                              />
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="mb-3">
                              <label className="form-label">Description</label>
                              <textarea
                                className="form-control"
                                id="Description-field"
                                rows="1"
                                required
                                onChange={(e) => setdescription(e.target.value)}
                                value={description}
                                defaultValue={focusedArr.description}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Row></Row>
                          <table
                            className="table align-middle table-nowrap"
                            id="purchase"
                          >
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col" style={{ width: "50px" }}></th> */}
                                <th className="sort" data-sort="item">
                                  Item Name
                                </th>
                                {/* <th className="sort" data-sort="qty">
                                  Qty Avaliable
                                </th> */}
                                <th className="sort" data-sort="phone">
                                  Quantity
                                </th>
                                <th className="sort" data-sort="Date">
                                  Rate
                                </th>
                                <th className="sort" data-sort="amount">
                                  Amount
                                </th>
                                {/* <th className="sort" data-sort="amount">
                                  Discription
                                </th> */}
                                <th className="sort" data-sort="action">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {filteredArr?.map((service, index) => {
                                //console.log(service);
                                return (
                                  <tr key={index} className="">
                                    <td className="item">
                                      <div className="mb-3">
                                        {/* <select
                                          className="form-select "
                                          id="itemtype"
                                          onChange={(e) =>
                                            handleServiceChange(e, index)
                                          }
                                          name="itemId"
                                          required
                                          value={"muscab"}
                                        >
                                          <option>
                                            &hellip; Choose item &hellip;
                                          </option>
                                          {products.map((pr, index) => {
                                            let t = pr._id == service.itemId;
                                            //console.log(t);
                                            //console.log(pr);
                                            return (
                                              <option
                                                key={index}
                                                value={pr._id}
                                                selected={
                                                  pr._id == service.itemId &&
                                                  pr.itemName
                                                }
                                              >
                                                {pr.itemName}
                                              </option>
                                            );
                                          })}
                                        </select> */}
                                        <div className="mb-3">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="disabledInput"
                                            defaultValue={service.QtyAvailable}
                                            disabled
                                            value={service.itemName}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    {/* <td className="qty">
                                      <div className="mb-3">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="disabledInput"
                                          defaultValue={service.QtyAvailable}
                                          disabled
                                          value={service.QtyAvailable}
                                        />
                                      </div>
                                    </td> */}
                                    <td className="phone">
                                      <div className="mb-3">
                                        <input
                                          type="number"
                                          id="CashAccount-field"
                                          className="form-control"
                                          placeholder="Enter quantity"
                                          required
                                          // onChange={(e) =>
                                          //   handleServiceChange(e, index)
                                          // }
                                          name="quantity"
                                          disabled
                                          value={service.quantity}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="mb-3">
                                        <input
                                          type="number"
                                          id="CashAccount-field"
                                          className="form-control"
                                          placeholder="Enter Rate"
                                          required
                                          // onChange={(e) =>
                                          //   handleServiceChange(e, index)
                                          // }
                                          name="unitPrice"
                                          disabled
                                          value={service.rate}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="mb-3">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="disabledInput"
                                          defaultValue={service.amount}
                                          value={
                                            Number(service.quantity) *
                                            Number(service.rate)
                                          }
                                          disabled
                                        />
                                      </div>
                                    </td>
                                    {/* <td className="qty">
                                      <div className="mb-3">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          onChange={(e) =>
                                            handleServiceChange(e, index)
                                          }
                                        />
                                      </div>
                                    </td> */}
                                    <td>
                                      {/* {serviceList.length !== 1 && ( */}
                                      <button
                                        type="button"
                                        className="btn btn-danger mb-3"
                                        id="add-btn"
                                        onClick={() =>
                                          handleServiceRemove(index)
                                        }
                                      >
                                        Remove
                                      </button>
                                      {/* )} */}

                                      {/* {serviceList.length - 1 === index && (
                                        <button
                                          type="submit"
                                          className="btn btn-success mb-3 mx-3"
                                          id="add-btn"
                                          onClick={handleServiceAdd}
                                        >
                                          Add
                                        </button>
                                      )}
                                       */}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <Col md={8}></Col>
                          <Col md={2}>
                            <div className="mb-3 pull-right">
                              <label className="form-label">
                                Purchase Amount
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="disabledInput"
                                defaultValue={purchaseAmount}
                                // onChange={(e) =>
                                //   setpurchaseAmount(e.target.value)
                                // }
                                value={purchaseAmount}
                                disabled
                              />
                            </div>
                          </Col>

                          <Col md={3}></Col>
                          <Row className="mt-5"></Row>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-success"
                            id="add-btn"
                          >
                            Save
                          </button>
                        </div>
                      </ModalFooter>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Purchase Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        {/* =================================== */}
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default PurchasesOrderUpdate;
