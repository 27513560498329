import React, { useState, useEffect, useContext, useMemo } from "react";
import Tabs from "./Tabs";
import Panel from "./Panel";
import "./styles.css"
import Select from "react-select";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Grid, _ } from "gridjs-react";

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";


const MasterFile = () => {
    const [serviceList, setServiceList] = useState([
        { item: "", QtyAvailable: "", Qty: "", rate: "", Amount: "" },
    ]);

    const {
        AdvanceRegister,
        fetchEmployees,
        getEmployee,
        getAdvance,
        sites,
        fetchDepartment,
        departments,
        EmpTitle,
        getAllTitles,
        fetchSites,
        fetchEmpTitle,
        DepartmentRegister,
        deleteDepartment,
        DepartmentUpdate,
        TitleRegsiter,
        EmpTitleUpdate,
        deleteTitle,
        Groups,
        getGroups,
        ZoneList,
        GroupRegsiter,
        fetchGroups,
        registerZones,
        getZones,
        fetchZones,
        fetchAllZones,
        getByIdGroupCable,
        getByIdGroupsCable,
        GroupUpdateAPI,
        deleteGroup,
        deleteZoneAPI,
        ZonesUpdate,
        deleteBank,
        BankUpdate,
        fetchBank,
        BankRegister,
        Banks, User
    } =
        useContext(LoginContex);


    const [QtyAvailable, setQtyAvailable] = useState("");
    const [departmentName, setdepartmentName] = useState("");
    const [Remarks, setRemarks] = useState("");
    const [Title, SetTitleName] = useState("");
    const [Group, SetGroup] = useState("");
    const [GroupUpdate, SetGroupUpdate] = useState();
    const [Zonename, setZonename] = useState("");
    const [siteId, setsiteId] = useState("");
    const [siteIdUpdate, setsiteIdUpdate] = useState("");
    const [groupIdZone, setgroupIdZone] = useState("");
    const [siteIdZone, setsiteIdZone] = useState("");
    const [btnText, setbtnText] = useState("Add");
    const [isEditing, setIsEditing] = useState(false);
    const [deptId, setdeptId] = useState();
    const [EmpId, setEmpId] = useState();
    const [GroupId, setGroupId] = useState();
    const [ZoneId, setZoneId] = useState();
    const [bankId, setbankId] = useState();

    const [Qty, setQty] = useState("");
    const [DeptUpdate, SetDeptUpdate] = useState("");
    const [TitleUpdate, SetTitleUpdate] = useState("");
    const [ZoneUpdate, SetZoneUpdate] = useState("");
    const [groupIdZoneUpdt, setgroupIdZoneUpdt] = useState("");
    const [modalGroup_list, setmodalGroup_list] = useState(false);
    const [modalDept_list, setmodalDept_list] = useState(false);
    const [modalTitle_list, setmodalTitle_list] = useState(false);
    const [modalZone_list, setmodalZone_list] = useState(false);
    const [modal_list, setmodal_list] = useState(false);
    const [modalDept_delete, setmodalDept_delete] = useState(false);
    const [modalTitle_delete, setmodalTitle_delete] = useState(false);
    const [modalGroup_delete, setmodalGroup_delete] = useState(false);
    const [modalZone_delete, setmodalZone_delete] = useState(false);


    const [objectDept_id, setObjectDept] = useState();
    const [objectGroup_id, setObjectGroup] = useState();
    const [objectTitle_id, setObjectTitle] = useState();
    const [objectZone_id, setObjectZone] = useState();
    const [objectBank_id, setObjectBank] = useState();

    const [bankName, setbankName] = useState();
    const [bankNameUpd, setbankNameUpd] = useState();
    let userId = User?.data?.user?._id || User?.user?._id;
    const tog_list = () => {
        setmodalGroup_list(true);
    };

    const togDept_list = () => {
        setmodalDept_list(true);
    };

    const togZone_list = () => {
        setmodalZone_list(true);
    };

    const togTitle_list = () => {
        setmodalTitle_list(true);
    };

    const togBank_list = () => {
        setmodal_list(true);
    };


    const togDept_delete = () => {
        // //console.log('hiii');
        setmodalDept_delete(!modalDept_delete);
    };

    const togGroup_delete = () => {
        // //console.log('hiii');
        setmodalGroup_delete(!modalGroup_delete);
    };


    const togTitle_delete = () => {
        // //console.log('hiii');
        setmodalTitle_delete(!modalTitle_delete);
    };


    const togZone_delete = () => {
        // //console.log('hiii');
        setmodalZone_delete(!modalZone_delete);
    };
    const [modal_delete, setmodal_delete] = useState(false);

    const tog_delete = () => {
        setmodal_delete(!modal_delete);
    };

    useEffect(() => {
        fetchEmpTitle();
        fetchGroups();
        fetchDepartment();
        // //console.log(departments);
        fetchSites();
        fetchZones();
        fetchAllZones();
        fetchBank();
    }, []);

    // //console.log(getZones.result);
    useEffect(() => {
        const attroptions = {
            valueNames: [
                "name",
                "born",
                {
                    data: ["id"],
                },
                {
                    attr: "src",
                    name: "image",
                },
                {
                    attr: "href",
                    name: "link",
                },
                {
                    attr: "data-timestamp",
                    name: "timestamp",
                },
            ],
        };

        // pagination list

        new List("pagination-list", {
            valueNames: ["pagi-list"],
            page: 3,
            pagination: true,
        });
    });

    document.title = "Master File  ";

    const handleValidDate = (date) => {
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };

    const columnsDept = useMemo(
        () => [
            {
                Header: "#",
                accessor: "departmentId",
                filterable: false,
            },
            {
                Header: "Department",
                accessor: "departmentName",
                filterable: false,
            },

            {
                Header: "Action Date ",
                accessor: "createdAt",
                filterable: false,
                Cell: (cell) => <>{handleValidDate(cell.value)}</>,
            },

            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">
                            <li className="list-inline-item edit" title="Edit">
                                <Link
                                    to="#"
                                    className="text-primary d-inline-block edit-item-btn"
                                    onClick={(row) => {
                                        const customerData = cellProps.row.original;
                                        editPop_Department(customerData);
                                    }}
                                >
                                    <i className="ri-pencil-fill fs-16"></i>
                                </Link>
                            </li>
                            <li className="list-inline-item" title="Remove">
                                <Link
                                    to="#"
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={(row) => {
                                        const customerData = cellProps.row.original;
                                        deletDeptPop(customerData);
                                    }}
                                >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                </Link>
                            </li>
                        </ul>
                    );
                },
            },
        ]
        // [handleCustomerClick]
    );


    const columnsTitle = useMemo(
        () => [
            {
                Header: "#",
                accessor: "titleId",
                filterable: false,
            },
            {
                Header: "Title",
                accessor: "title",
                filterable: false,
            },

            {
                Header: "Action Date ",
                accessor: "createdAt",
                filterable: false,
                Cell: (cell) => <>{handleValidDate(cell.value)}</>,
            },

            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">
                            <li className="list-inline-item edit" title="Edit">
                                <Link
                                    to="#"
                                    className="text-primary d-inline-block edit-item-btn"
                                    onClick={(row) => {
                                        const customerData = cellProps.row.original;
                                        editPop_Title(customerData);
                                    }}
                                >
                                    <i className="ri-pencil-fill fs-16"></i>
                                </Link>
                            </li>
                            <li className="list-inline-item" title="Remove">
                                <Link
                                    to="#"
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={(row) => {
                                        const customerData = cellProps.row.original;
                                        deletTitlePop(customerData);
                                    }}
                                >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                </Link>
                            </li>
                        </ul>
                    );
                },
            },
        ]
        // [handleCustomerClick]
    );


    const columnsGroups = useMemo(
        () => [
            {
                Header: "#",
                accessor: "groupId",
                filterable: false,
            },
            {
                Header: "Group",
                accessor: "groupName",
                filterable: false,
            },
            {
                Header: "Site",
                accessor: "siteName",
                filterable: false,
            },


            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">
                            <li className="list-inline-item edit" title="Edit">
                                <Link
                                    to="#"
                                    className="text-primary d-inline-block edit-item-btn"
                                    onClick={(row) => {
                                        const customerData = cellProps.row.original;
                                        editPop_Group(customerData);
                                    }}
                                >
                                    <i className="ri-pencil-fill fs-16"></i>
                                </Link>
                            </li>
                            <li className="list-inline-item" title="Remove">
                                <Link
                                    to="#"
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={(row) => {
                                        const customerData = cellProps.row.original;
                                        deletGroupPop(customerData);
                                    }}
                                >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                </Link>
                            </li>
                        </ul>
                    );
                },
            },
        ]
        // [handleCustomerClick]
    );


    const columnsZones = useMemo(
        () => [
            {
                Header: "Zone",
                accessor: "zoonName",
                filterable: false,
            },
            {
                Header: "Group",
                accessor: "groupName",
                filterable: false,
            },
            {
                Header: "Site",
                accessor: "siteName",
                filterable: false,
            },


            {
                Header: "Action Date ",
                accessor: "actionDate",
                filterable: false,
                Cell: (cell) => <>{handleValidDate(cell.value)}</>,
            },

            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">
                            <li className="list-inline-item edit" title="Edit">
                                <Link
                                    to="#"
                                    className="text-primary d-inline-block edit-item-btn"
                                    onClick={(row) => {
                                        const customerData = cellProps.row.original;
                                        editPop_Zone(customerData);
                                    }}
                                >
                                    <i className="ri-pencil-fill fs-16"></i>
                                </Link>
                            </li>
                            <li className="list-inline-item" title="Remove">
                                <Link
                                    to="#"
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={(row) => {
                                        const customerData = cellProps.row.original;
                                        deletZonesPop(customerData);
                                    }}
                                >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                </Link>
                            </li>
                        </ul>
                    );
                },
            },
        ]
        // [handleCustomerClick]
    );

    const handleServiceAdd = () => {
        setServiceList([
            ...serviceList,
            { item: "", QtyAvailable: "", Qty: "", rate: "", Amount: "" },
        ]);
    };

    const handleServiceRemove = (index) => {
        const list = [...serviceList];
        list.splice(index, 1);
        setServiceList(list);
    };

    const handleServiceChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...serviceList];
        list[index][name] = value;
        setServiceList(list);
        // handleServiceAdd();
    };

    const test = (e, index) => {
        handleServiceChange(e, index);
        if (e.target.name === "item") {
            handleServiceAdd();
        }
    };

    let getGroup = (id) => {
        // //console.log(id)
        setsiteIdZone(id);
        getByIdGroupCable(id);
    };

    let getGroupUpd = (id) => {
        // //console.log(id)
        setsiteIdUpdate(id);
        getByIdGroupCable(id);
    };


    const showToastMessage = (message) => {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    };

    //DEPARTMENTS
    const handlerSubmitDept = (e) => {

        e.preventDefault();

        if (!departmentName) {
            showToastMessage('Please fill department name!');
            return
        } else {
            const data = {
                departmentName: departmentName,
                userId
            }
            DepartmentRegister(data);
            setdepartmentName("");
        }

        // }
    };

    const handlerSubmitDeptUpd = (e) => {

        e.preventDefault();

        if (!DeptUpdate) {
            showToastMessage('Please fill department name!');
            return
        } else {
            const data = {
                departmentName: DeptUpdate,
                id: objectDept_id
            }
            DepartmentUpdate(data);
            setmodalDept_list(false);
        }
        // }
    };

    //console.log(departments);

    const sortDept = [];
    for (let i = 0; i < departments?.length; i++) {
        let singleArr = [
            departments[i].departmentId,
            departments[i].departmentName,
            moment(departments[i].createdAt).utc()
                .format("YYYY-MM-DD"),
            departments[i]._id,


        ];

        sortDept.push(singleArr);
    }

    //TITLE
    const handlerSubmitTitle = (e) => {
        // if (!isUpdating) {
        e.preventDefault();

        if (!Title) {
            showToastMessage('Please fill Title name!');
            return
        } else {
            const data = {
                title: Title,
                userId
            }
            TitleRegsiter(data);
            setTimeout(() => fetchEmpTitle(), 1500);
            SetTitleName("");
        }

        // }
    };

    const sortTitle = [];
    for (let i = 0; i < getAllTitles?.length; i++) {
        let singleArr = [
            getAllTitles[i].titleId,
            getAllTitles[i].title,
            moment(getAllTitles[i].createdAt).utc()
                .format("YYYY-MM-DD"),
            getAllTitles[i]._id,


        ];

        sortTitle.push(singleArr);
    }
    //Banks
    const handlerSubmitBank = (e) => {
        e.preventDefault();
        const data = {
            bankName,
            userId
        }
        BankRegister(data);
        setbankName("");
    };

    const handlerSubmitBankUp = (e) => {
        e.preventDefault();
        const data = {
            bankName: bankNameUpd,
            id: objectBank_id
        }
        BankUpdate(data);
        setbankNameUpd("");
        setmodal_list(false);
    };



    const handlerSubmitTitleUpd = (e) => {

        e.preventDefault();
        if (!TitleUpdate) {
            showToastMessage('Please fill Title name!');
            return
        } else {

            const data = {
                title: TitleUpdate,
                id: objectTitle_id,
                
            }
            EmpTitleUpdate(data);
            SetTitleUpdate("");
            setmodalTitle_list(false);
            fetchEmpTitle();
            // }
        }

    };

    //GROUPS
    const handlerSubmitGroup = (e) => {

        e.preventDefault();
        if (!Group || !siteId) {
            showToastMessage('Please fill Required Fields!');
            return
        }
        const data = {
            groupName: Group,
            siteId: siteId,
            userId
        }
        GroupRegsiter(data);
        setTimeout(() => fetchGroups(), 1500);
        SetGroup("");
        setsiteId("Select site");
        // }
    };

    const sortGroups = [];
    for (let i = 0; i < getGroups?.length; i++) {
        let singleArr = [
            getGroups[i].groupId,
            getGroups[i].groupName,
            getGroups[i].siteName,
            getGroups[i]._id,
            getGroups[i].siteId,
        ];

        sortGroups.push(singleArr);
    }

    const handlerSubmitGroupUpd = (e) => {

        e.preventDefault();
        if (!GroupUpdate || !siteIdUpdate) {
            showToastMessage('Please fill Required Fields!');
            return
        }
        const data = {
            groupName: GroupUpdate,
            siteId: siteIdUpdate,
            id: objectGroup_id
        }
        GroupUpdateAPI(data);
        SetGroupUpdate("");
        setsiteIdUpdate("");
        setmodalGroup_list(false);
        // }
    };
    //ZOONS
    const handlerSubmitZones = (e) => {
        e.preventDefault();

        if (!Zonename || !groupIdZone || !siteIdZone) {
            showToastMessage('Please fill Required Fields!');
            return;
        }

        const data = {
            zoonName: Zonename,
            groupId: groupIdZone,
            siteId: siteIdZone,
            userId

        }
        // alert('hey');
        //console.log(data);
        registerZones(data);
        setsiteIdZone("Select Group");
        setgroupIdZone("Select Site");
        setZonename("");
        // }
    };

    const sortZone = [];
    for (let i = 0; i < ZoneList?.length; i++) {
        let singleArr = [
            ZoneList[i].zoonName,
            ZoneList[i].groupName,
            ZoneList[i].siteName,
            moment(ZoneList[i].actionDate).utc()
                .format("YYYY-MM-DD"),
            ZoneList[i]._id,
            ZoneList[i].siteID,
            ZoneList[i].groupId,
        ];

        sortZone.push(singleArr);
    }


    const handlerSubmitZonesUpd = (e) => {
        e.preventDefault();

        if (!ZoneUpdate || !groupIdZoneUpdt || !siteIdUpdate) {
            showToastMessage('Please fill Required Fields!');
            return;
        }

        const data = {
            zoonName: ZoneUpdate,
            groupId: groupIdZoneUpdt,
            siteId: siteIdUpdate,
            id: objectZone_id
        }
        ZonesUpdate(data);
        setmodalZone_list(false);
        SetZoneUpdate("");
        setgroupIdZoneUpdt("");
        setsiteIdUpdate("");
        // }
    };

    const editPop_Department = (data) => {
        SetDeptUpdate(data.departmentName);
        //console.log(data);
        togDept_list();
        setmodalDept_list(true);
        setIsEditing(true);
        setObjectDept(data._id);
    };


    const editPop_Group = (data) => {
        SetGroupUpdate(data.groupName);
        setObjectGroup(data._id);
        setsiteIdUpdate(data.siteId);
        tog_list();
        setmodalGroup_list(true);
        setIsEditing(true);
    };

    const editPop_Zone = (data) => {
        SetZoneUpdate(data.zoonName);
        getByIdGroupCable(data.siteID);
        setgroupIdZoneUpdt(data.groupId);
        setsiteIdUpdate(data.siteID);
        setObjectZone(data._id);
        // //console.log(data);
        togZone_list();
        setmodalZone_list(true);
        setIsEditing(true);
    };

    const editPop_Title = (data) => {
        SetTitleUpdate(data.title);
        setObjectTitle(data._id);
        //console.log(data);
        togTitle_list();
        setmodalTitle_list(true);
        setIsEditing(true);
    };

    const editPop_Bank = (data) => {
        setbankNameUpd(data.bankName);
        setObjectBank(data._id);
        //console.log(data);
        togBank_list();
        setmodal_list(true);
        setIsEditing(true);
    };


    const deletDeptPop = (data) => {
        setmodalDept_delete(true);
        setdeptId(data._id);
    };


    const deleteDep = () => {
        deleteDepartment(deptId);
        setmodalDept_delete(false);
    };

    const deleteEmp = () => {
        deleteTitle(EmpId);
        setmodalTitle_delete(false);
    };

    const deleteGroupFunc = () => {
        deleteGroup(GroupId);
        setmodalGroup_delete(false);
    };

    const deleteZone = () => {
        deleteZoneAPI(ZoneId);
        setmodalZone_delete(false);
    };


    const deletTitlePop = (data) => {
        setmodalTitle_delete(true);
        setEmpId(data._id);
    };

    const deleteBankPop = (data) => {
        setmodal_delete(true);
        setbankId(data._id);
    };

    const deleteBankfun = () => {
        deleteBank(bankId);
        setmodal_delete(false);
    };


    const deletGroupPop = (data) => {
        setmodalGroup_delete(true);
        setGroupId(data._id);
    };

    const deletZonesPop = (data) => {
        setmodalZone_delete(true);
        setZoneId(data._id);
    };

    const deletPop = (data) => {
        setmodalDept_delete(true);
        // setEmployeeId(data._id);
    };


    const SitesArr = [];
    for (let i = 0; i < sites.length; i++) {
        //console.log(sites[i]._id);

        var dropdownList = {
            value: sites[i]._id,
            label: ` ${sites[i].siteName}`,
        };

        SitesArr.push(dropdownList);
    }


    const handleTypeSelectSite = e => {
        getGroup(e.value);
    };

    const handleTypeSelectSiteUpd = e => {
        getGroupUpd(e.value);

    };

    const handleTypeSelectGroup = e => {
        setsiteId(e.value);
    };

    const handleTypeSelectGroupUpd = e => {
        setsiteIdUpdate(e.value);
    };

    const GroupsArr = [];
    for (let i = 0; i < getByIdGroupsCable.length; i++) {
        var dropdownList = {
            value: getByIdGroupsCable[i]._id,
            label: ` ${getByIdGroupsCable[i].groupName}`,
        };

        GroupsArr.push(dropdownList);
    }

    const handleTypeSelectGroupZone = e => {
        setgroupIdZone(e.value);
    };

    const handleTypeSelectGroupUpdt = e => {
        setgroupIdZoneUpdt(e.value);
    };



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        title="Master File "
                        pageTitle="Master File"
                    />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <ToastContainer />
                                </CardHeader>
                                <CardBody>
                                    <div id="Employeestable">

                                        <form>
                                            <ModalBody>
                                                <div
                                                    className="mb-3"
                                                    id="modal-id"
                                                    style={{ display: "none" }}>

                                                    <label htmlFor="id-field" className="form-label">
                                                        ID
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="id-field"
                                                        className="form-control"
                                                        placeholder="ID"
                                                        readOnly
                                                    />
                                                </div>
                                                <Row>


                                                    <Col md={12}>
                                                        <Tabs >
                                                            <Panel title="Department">
                                                                <Row style={{ marginLeft: "25px" }}>
                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <label>Department Name</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>
                                                                            <div className="mb-3">
                                                                                <input
                                                                                    type="text"
                                                                                    id="Employee-field"
                                                                                    className="form-control"
                                                                                    placeholder="Enter Department Name"
                                                                                    required
                                                                                    onChange={(e) => setdepartmentName(e.target.value)}
                                                                                    value={departmentName}
                                                                                ></input>
                                                                            </div>
                                                                        </div>
                                                                    </Col>


                                                                    <Row>
                                                                        <div style={{ marginLeft: "25px", marginTop: "10px" }} className="hstack gap-2 justify-content-end">

                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-success"
                                                                                id="add-btn"
                                                                                onClick={
                                                                                    handlerSubmitDept
                                                                                }
                                                                            >
                                                                                Add
                                                                            </button>

                                                                        </div>
                                                                    </Row>

                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <Card>
                                                                            <CardHeader>
                                                                            </CardHeader>
                                                                            <Card>
                                                                                <CardBody>
                                                                                    <div id="Purchasestable">
                                                                                        <Row className="g-4 mb-3"></Row>
                                                                                        <TableContainer
                                                                                            columns={columnsDept}
                                                                                            data={departments || []}
                                                                                            isGlobalFilter={true}
                                                                                            isAddUserList={false}
                                                                                            customPageSize={10}
                                                                                            className="custom-header-css"

                                                                                        />
                                                                                        {/* end of container */}

                                                                                    </div>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </Panel>
                                                            <Panel title="Titles">
                                                                <Row style={{ marginLeft: "25px" }}>
                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <label>Title Name</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>
                                                                            <div className="mb-3">
                                                                                <input
                                                                                    type="text"
                                                                                    id="Employee-field"
                                                                                    className="form-control"
                                                                                    placeholder="Enter Title"
                                                                                    required
                                                                                    onChange={(e) => SetTitleName(e.target.value)}
                                                                                    value={Title}
                                                                                ></input>
                                                                            </div>
                                                                        </div>
                                                                    </Col>


                                                                    <Row>
                                                                        <div style={{ marginLeft: "25px", marginTop: "10px" }} className="hstack gap-2 justify-content-end">

                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-success"
                                                                                id="add-btn"
                                                                                onClick={
                                                                                    handlerSubmitTitle
                                                                                }
                                                                            >
                                                                                Add
                                                                            </button>

                                                                        </div>
                                                                    </Row>

                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <Card>
                                                                            <CardHeader>
                                                                            </CardHeader>
                                                                            <Card>
                                                                                <CardBody>
                                                                                    <div id="Purchasestable">
                                                                                        <Row className="g-4 mb-3"></Row>
                                                                                        <TableContainer
                                                                                            columns={columnsTitle}
                                                                                            data={getAllTitles || []}
                                                                                            isGlobalFilter={true}
                                                                                            isAddUserList={false}
                                                                                            customPageSize={10}
                                                                                            className="custom-header-css"
                                                                                        />

                                                                                        {/* end of container */}

                                                                                    </div>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </Panel>

                                                            <Panel title="Groups">
                                                                <Row style={{ marginLeft: "25px" }}>
                                                                    <Col md={6}>
                                                                        <div className="mb-3">
                                                                            <label>Group Name</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>
                                                                            <div className="mb-3">
                                                                                <input
                                                                                    type="text"
                                                                                    id="Employee-field"
                                                                                    className="form-control"
                                                                                    placeholder="Enter Group Name"
                                                                                    required
                                                                                    onChange={(e) => SetGroup(e.target.value)}
                                                                                    value={Group}
                                                                                ></input>
                                                                            </div>
                                                                        </div>
                                                                    </Col>

                                                                    <Col md={6}>
                                                                        <label>Site</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>
                                                                        <Select
                                                                            // className="bg-white"
                                                                            options={SitesArr}
                                                                            onChange={handleTypeSelectGroup}
                                                                            value={SitesArr.filter(function (option) {
                                                                                return option.value === siteId;
                                                                            })}

                                                                            defaultValue={{ label: "Select Site", value: siteId }}
                                                                            required
                                                                            theme={(theme) => ({
                                                                                ...theme,
                                                                                borderRadius: 0,
                                                                                colors: {
                                                                                    ...theme.colors,
                                                                                    // text: "orangered",
                                                                                    // primary25: "hotpink",
                                                                                    // primary: "black",
                                                                                },
                                                                            })}
                                                                        />
                                                                    </Col>

                                                                    <Row>
                                                                        <div style={{ marginLeft: "25px", marginTop: "10px" }} className="hstack gap-2 justify-content-end">

                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-success"
                                                                                id="add-btn"
                                                                                onClick={
                                                                                    handlerSubmitGroup
                                                                                }
                                                                            >

                                                                                Add
                                                                            </button>

                                                                        </div>
                                                                    </Row>

                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <Card>
                                                                            <CardHeader>
                                                                            </CardHeader>
                                                                            <Card>
                                                                                <CardBody>
                                                                                    <div id="Purchasestable">
                                                                                        <Row className="g-4 mb-3"></Row>
                                                                                        <TableContainer
                                                                                            columns={columnsGroups}
                                                                                            data={getGroups || []}
                                                                                            isGlobalFilter={true}
                                                                                            isAddUserList={false}
                                                                                            customPageSize={10}
                                                                                            className="custom-header-css"

                                                                                        />


                                                                                    </div>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </Panel>
                                                            <Panel title="Zones">

                                                                <Row style={{ marginLeft: "25px" }}>
                                                                    <Col md={6}>
                                                                        <div className="mb-3">
                                                                            <label>Zone Name</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>
                                                                            <div className="mb-3">
                                                                                <input
                                                                                    type="text"
                                                                                    id="Employee-field"
                                                                                    className="form-control"
                                                                                    placeholder="Enter Zone Name"
                                                                                    required
                                                                                    onChange={(e) => setZonename(e.target.value)}
                                                                                    value={Zonename}
                                                                                ></input>
                                                                            </div>
                                                                        </div>
                                                                    </Col>

                                                                    <Col md={6}>
                                                                        <label>Site</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>

                                                                        <Select
                                                                            // className="bg-white"
                                                                            options={SitesArr}
                                                                            onChange={handleTypeSelectSite}
                                                                            value={SitesArr.filter(function (option) {
                                                                                return option.value === siteIdZone;
                                                                            })}

                                                                            defaultValue={{ label: "Select Site", value: siteIdZone }}
                                                                            required
                                                                            theme={(theme) => ({
                                                                                ...theme,
                                                                                borderRadius: 0,
                                                                                colors: {
                                                                                    ...theme.colors,
                                                                                    // text: "orangered",
                                                                                    // primary25: "hotpink",
                                                                                    // primary: "black",
                                                                                },
                                                                            })}
                                                                        />
                                                                    </Col>

                                                                    <Col md={6}>
                                                                        <label>Group</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>
                                                                        <Select
                                                                            options={GroupsArr}
                                                                            onChange={handleTypeSelectGroupZone}
                                                                            value={GroupsArr.filter(function (option) {
                                                                                return option.value === groupIdZone;
                                                                            })}

                                                                            defaultValue={{ label: "Select Group", value: groupIdZone }}
                                                                            required
                                                                            theme={(theme) => ({
                                                                                ...theme,
                                                                                borderRadius: 0,
                                                                                colors: {
                                                                                    ...theme.colors,
                                                                                    // text: "orangered",
                                                                                    // primary25: "hotpink",
                                                                                    // primary: "black",
                                                                                },
                                                                            })}
                                                                        />
                                                                    </Col>
                                                                    <Row>
                                                                        <div style={{ marginLeft: "25px", marginTop: "10px" }} className="hstack gap-2 justify-content-end">

                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-success"
                                                                                id="add-btn"
                                                                                onClick={
                                                                                    handlerSubmitZones
                                                                                }
                                                                            >
                                                                                Add
                                                                            </button>

                                                                        </div>
                                                                    </Row>

                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <Card>
                                                                            <CardHeader>
                                                                            </CardHeader>
                                                                            <Card>
                                                                                <CardBody>
                                                                                    <div id="Purchasestable">
                                                                                        <Row className="g-4 mb-3"></Row>
                                                                                        <TableContainer
                                                                                            columns={columnsZones}
                                                                                            data={ZoneList || []}
                                                                                            isGlobalFilter={true}
                                                                                            isAddUserList={false}
                                                                                            customPageSize={10}
                                                                                            className="custom-header-css"

                                                                                        />


                                                                                    </div>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </Panel>
                                                        </Tabs>
                                                    </Col>
                                                </Row>

                                            </ModalBody>
                                            <ModalFooter >

                                            </ModalFooter>
                                        </form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* end of container */}
                </Container>
            </div>

            {/* Add Modal */}
            {/* Purchase Registration From */}

            {/* Department Update Model  */}
            <Modal
                isOpen={modalDept_list}
                toggle={() => {
                    togDept_list();
                }}
                centered
                size="lg"
            >
                {/* <ModalHeader className="bg-light p-3">
                    Update Department
                    <Button
                        type="button"
                        onClick={() => {
                            setmodalDept_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </ModalHeader> */}

                <div className="bg-light p-3 modal-header"><h5 className="modal-title">Update Department</h5>

                    <Button
                        type="button"
                        onClick={() => {
                            setmodalDept_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>

                </div>
                <form onSubmit={handlerSubmitDeptUpd} >
                    <ModalBody>
                        <div className="mb-3" id="modal-id" style={{ display: "none" }}>
                            <label htmlFor="id-field" className="form-label">
                                ID
                            </label>
                            <input
                                type="text"
                                id="id-field"
                                className="form-control"
                                placeholder="ID"
                                readOnly
                            />
                        </div>


                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <label className="form-label">Department Name</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>
                                    <input
                                        type="text"
                                        id="Employee-field"
                                        className="form-control"
                                        placeholder="Enter Department Name"
                                        required
                                        onChange={(e) => SetDeptUpdate(e.target.value)}
                                        value={DeptUpdate}
                                    ></input>
                                </div>
                            </Col>

                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => setmodalDept_list(false)}
                            >
                                Close
                            </button>

                            <button type="button" className="btn btn-success" id="edit-btn"
                                onClick={handlerSubmitDeptUpd}
                            >
                                Update
                            </button>
                        </div>
                    </ModalFooter>
                </form>

                {/* =================================== */}
            </Modal>

            {/* Department Remove Modal */}
            <Modal
                isOpen={modalDept_delete}
                toggle={() => {
                    togDept_delete();
                }}
                className="modal"
                id="deleteRecordModal"
                centered
            >
                <div className="modal-header">
                    <Button
                        type="button"
                        onClick={() => setmodalDept_delete(false)}
                        className="btn-close"
                        aria-label="Close"
                    >
                        {" "}
                    </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon
                            src="https://cdn.lordicon.com/gsqxdxog.json"
                            trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548"
                            style={{ width: "100px", height: "100px" }}
                        ></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">
                                Are you Sure You want to Remove this Record ?
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            onClick={() => setmodalDept_delete(false)}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={deleteDep}
                        >
                            Yes, Delete It!
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            {/* Group Update Model  */}
            <Modal
                isOpen={modalGroup_list}
                toggle={() => {
                    tog_list();
                }}
                centered
                size="lg"
            >
                {/* <ModalHeader className="bg-light p-3">
                    Group Update
                    <Button
                        type="button"
                        onClick={() => {
                            setmodalGroup_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </ModalHeader> */}
                <div className="bg-light p-3 modal-header"><h5 className="modal-title">Update Group</h5>

                    <Button
                        type="button"
                        onClick={() => {
                            setmodalGroup_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </div>
                <form onSubmit={handlerSubmitGroupUpd}>
                    <ModalBody>
                        <div className="mb-3" id="modal-id" style={{ display: "none" }}>
                            <label htmlFor="id-field" className="form-label">
                                ID
                            </label>
                            <input
                                type="text"
                                id="id-field"
                                className="form-control"
                                placeholder="ID"
                                readOnly
                            />
                        </div>


                        <Row>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label className="form-label">Group Name</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>
                                    <input
                                        type="text"
                                        id="Employee-field"
                                        className="form-control"
                                        placeholder="Enter Group Name"
                                        required
                                        onChange={(e) => SetGroupUpdate(e.target.value)}
                                        value={GroupUpdate}
                                    ></input>
                                </div>
                            </Col>
                            <Col md={6}>
                                <label>Site</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>

                                <Select
                                    // className="bg-white"
                                    options={SitesArr}
                                    onChange={handleTypeSelectGroupUpd}
                                    value={SitesArr.filter(function (option) {
                                        return option.value === siteIdUpdate;
                                    })}

                                    defaultValue={{ label: "Select Site", value: siteIdUpdate }}
                                    required
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            // text: "orangered",
                                            // primary25: "hotpink",
                                            // primary: "black",
                                        },
                                    })}
                                />


                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => setmodalGroup_list(false)}
                            >
                                Close
                            </button>

                            <button type="button" className="btn btn-success" id="edit-btn"
                                onClick={handlerSubmitGroupUpd}
                            >
                                Update
                            </button>
                        </div>
                    </ModalFooter>
                </form>

                {/* =================================== */}
            </Modal>
            {/* Group Remove Modal */}
            <Modal
                isOpen={modalGroup_delete}
                toggle={() => {
                    togGroup_delete();
                }}
                className="modal"
                id="deleteRecordModal"
                centered
            >
                <div className="modal-header">
                    <Button
                        type="button"
                        onClick={() => setmodalGroup_delete(false)}
                        className="btn-close"
                        aria-label="Close"
                    >
                        {" "}
                    </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon
                            src="https://cdn.lordicon.com/gsqxdxog.json"
                            trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548"
                            style={{ width: "100px", height: "100px" }}
                        ></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">
                                Are you Sure You want to Remove this Record ?
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            onClick={() => setmodalGroup_delete(false)}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={deleteGroupFunc}
                        >
                            Yes, Delete It!
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            {/* Title Update Model  */}
            <Modal
                isOpen={modalTitle_list}
                toggle={() => {
                    togTitle_list();
                }}
                centered
                size="lg"
            >
                {/* <ModalHeader className="bg-light p-3">
                    Update 
                    <Button
                        type="button"
                        onClick={() => {
                            setmodalTitle_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </ModalHeader> */}
                <div className="bg-light p-3 modal-header"><h5 className="modal-title">Update Title</h5>

                    <Button
                        type="button"
                        onClick={() => {
                            setmodalTitle_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </div>
                <form>
                    <ModalBody>
                        <div className="mb-3" id="modal-id" style={{ display: "none" }}>
                            <label htmlFor="id-field" className="form-label">
                                ID
                            </label>
                            <input
                                type="text"
                                id="id-field"
                                className="form-control"
                                placeholder="ID"
                                readOnly
                            />
                        </div>


                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <label className="form-label">Title Name</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>
                                    <input
                                        type="text"
                                        id="Employee-field"
                                        className="form-control"
                                        placeholder="Enter Title Name"
                                        required
                                        onChange={(e) => SetTitleUpdate(e.target.value)}
                                        value={TitleUpdate}
                                    ></input>
                                </div>
                            </Col>

                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => setmodalTitle_list(false)}
                            >
                                Close
                            </button>

                            <button type="button" className="btn btn-success" id="edit-btn"
                                onClick={handlerSubmitTitleUpd}
                            >
                                Update
                            </button>
                        </div>
                    </ModalFooter>
                </form>

                {/* =================================== */}
            </Modal>

            {/* Title Remove Modal */}
            <Modal
                isOpen={modalTitle_delete}
                toggle={() => {
                    togTitle_delete();
                }}
                className="modal"
                id="deleteRecordModal"
                centered
            >
                <div className="modal-header">
                    <Button
                        type="button"
                        onClick={() => setmodalTitle_delete(false)}
                        className="btn-close"
                        aria-label="Close"
                    >
                        {" "}
                    </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon
                            src="https://cdn.lordicon.com/gsqxdxog.json"
                            trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548"
                            style={{ width: "100px", height: "100px" }}
                        ></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">
                                Are you Sure You want to Remove this Record ?
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            onClick={() => setmodalTitle_delete(false)}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={deleteEmp}
                        >
                            Yes, Delete It!
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            {/* Zones Update Model  */}
            <Modal
                isOpen={modalZone_list}
                toggle={() => {
                    togZone_list();
                }}
                centered
                size="lg"
            >
                {/* <ModalHeader className="bg-light p-3">
                    Zones Update
                    <Button
                        type="button"
                        onClick={() => {
                            setmodalZone_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </ModalHeader> */}

                <div className="bg-light p-3 modal-header"><h5 className="modal-title">Update Zone</h5>

                    <Button
                        type="button"
                        onClick={() => {
                            setmodalZone_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </div>
                <form onSubmit={handlerSubmitZonesUpd}>
                    <ModalBody>
                        <div className="mb-3" id="modal-id" style={{ display: "none" }}>
                            <label htmlFor="id-field" className="form-label">
                                ID
                            </label>
                            <input
                                type="text"
                                id="id-field"
                                className="form-control"
                                placeholder="ID"
                                readOnly
                            />
                        </div>


                        <Row>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label className="form-label">Zone Name</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>
                                    <input
                                        type="text"
                                        id="Employee-field"
                                        className="form-control"
                                        placeholder="Enter Zone Name"
                                        required
                                        onChange={(e) => SetZoneUpdate(e.target.value)}
                                        value={ZoneUpdate}
                                    ></input>
                                </div>
                            </Col>


                            <Col md={6}>
                                <label>Site</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>


                                <Select
                                    // className="bg-white"
                                    options={SitesArr}
                                    onChange={handleTypeSelectSiteUpd}
                                    value={SitesArr.filter(function (option) {
                                        return option.value === siteIdUpdate;
                                    })}

                                    defaultValue={{ label: "Select Site", value: siteIdUpdate }}
                                    required
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            // text: "orangered",
                                            // primary25: "hotpink",
                                            // primary: "black",
                                        },
                                    })}
                                />
                            </Col>

                            <Col md={6}>
                                <label>Group</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>
                                <Select
                                    options={GroupsArr}
                                    onChange={handleTypeSelectGroupUpdt}
                                    value={GroupsArr.filter(function (option) {
                                        return option.value === groupIdZoneUpdt;
                                    })}

                                    defaultValue={{ label: "Select Group", value: groupIdZoneUpdt }}
                                    required
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            // text: "orangered",
                                            // primary25: "hotpink",
                                            // primary: "black",
                                        },
                                    })}
                                />
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => setmodalZone_list(false)}
                            >
                                Close
                            </button>

                            <button type="button" className="btn btn-success" id="edit-btn"
                                onClick={handlerSubmitZonesUpd}
                            >
                                Update
                            </button>
                        </div>
                    </ModalFooter>
                </form>

                {/* =================================== */}
            </Modal>

            {/* Zones Remove Modal */}
            <Modal
                isOpen={modalZone_delete}
                toggle={() => {
                    togZone_delete();
                }}
                className="modal"
                id="deleteRecordModal"
                centered
            >
                <div className="modal-header">
                    <Button
                        type="button"
                        onClick={() => setmodalZone_delete(false)}
                        className="btn-close"
                        aria-label="Close"
                    >
                        {" "}
                    </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon
                            src="https://cdn.lordicon.com/gsqxdxog.json"
                            trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548"
                            style={{ width: "100px", height: "100px" }}
                        ></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">
                                Are you Sure You want to Remove this Record ?
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            onClick={() => setmodalZone_delete(false)}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={deleteZone}
                        >
                            Yes, Delete It!
                        </button>
                    </div>
                </ModalBody>
            </Modal>

            {/* Banks Update Model  */}
            <Modal
                isOpen={modal_list}
                toggle={() => {
                    togBank_list();
                }}
                centered
                size="lg"
            >
                {/* <ModalHeader className="bg-light p-3">
                    Update 
                    <Button
                        type="button"
                        onClick={() => {
                            setmodalTitle_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </ModalHeader> */}
                <div className="bg-light p-3 modal-header"><h5 className="modal-title">Update Bank</h5>

                    <Button
                        type="button"
                        onClick={() => {
                            setmodal_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </div>
                <form>
                    <ModalBody>
                        <div className="mb-3" id="modal-id" style={{ display: "none" }}>
                            <label htmlFor="id-field" className="form-label">
                                ID
                            </label>
                            <input
                                type="text"
                                id="id-field"
                                className="form-control"
                                placeholder="ID"
                                readOnly
                            />
                        </div>


                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <label className="form-label">Bank Name</label>
                                    <input
                                        type="text"
                                        id="Employee-field"
                                        className="form-control"
                                        placeholder="Enter Bank Name"
                                        required
                                        onChange={(e) => setbankNameUpd(e.target.value)}
                                        value={bankNameUpd}
                                    ></input>
                                </div>
                            </Col>

                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => setmodal_list(false)}
                            >
                                Close
                            </button>

                            <button type="button" className="btn btn-success" id="edit-btn"
                                onClick={handlerSubmitBankUp}
                            >
                                Update
                            </button>
                        </div>
                    </ModalFooter>
                </form>

                {/* =================================== */}
            </Modal>

            {/* Banks Remove Modal */}

            {/* Banks Modal */}
            <Modal
                isOpen={modal_delete}
                toggle={() => {
                    tog_delete();
                }}
                className="modal"
                id="deleteRecordModal"
                centered
            >
                <div className="modal-header">
                    <Button
                        type="button"
                        onClick={() => setmodal_delete(false)}
                        className="btn-close"
                        aria-label="Close"
                    >
                        {" "}
                    </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon
                            src="https://cdn.lordicon.com/gsqxdxog.json"
                            trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548"
                            style={{ width: "100px", height: "100px" }}
                        ></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">
                                Are you Sure You want to Remove this Record ?
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            onClick={() => setmodal_delete(false)}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={deleteBankfun}
                        >
                            Yes, Delete It!
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default MasterFile;
