import React, { useState, useEffect, useContext, useMemo } from "react";

import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import moment from "moment";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import DataTable from "react-data-table-component";
const GeneratePayrollSummaryReport = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardPackage, setCardPackage] = useState("");
  const [customer, setCustomer] = useState();
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [openDate, setOpenDate] = useState("");
  const [closeDate, setCloseDate] = useState("");
  const [usingDays, setUsingDays] = useState("");
  const [amount, setAmount] = useState(8);
  const [Discription, setdescription] = useState("");
  const { openAnalogCardRegister } = useContext(LoginContex);
  const [type, setTyp] = useState("text");
  const [customerInfo, setCustomerInfo] = useState();
  const [customerId, setCustomerId] = useState();
  const [Custom, setCustom] = useState("text");
  const [channelId, setChanell] = useState();
  const [cycle, setCycle] = useState("");
  const [numberOfDays, setNumberOfDays] = useState();
  const [numberOfMonths, setNumberOfMonths] = useState();
  const [billAmount, setBillAmount] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [helper, setHelper] = useState(false);
  const [modal_delete, setmodal_delete] = useState(false);

  document.title = "GeneratePayrollSummary Report";

  const {
    fetchOpenCards,
    OpenCards,
    cardList,
    channelPackage,
    fetchChanelPackages,
    closeAnalogCard,
    getEmployee,
    fetchEmployees,
    FetchSummaryCloseReport,
    summaryCloseReport,
    fetchCustomers,
    customers,fetchTicketRegesterationReport,ticketRegReport,
    fetchAdvanceReport,
    advancesReport

  } = useContext(LoginContex);

  const {
    fetchPayrollSummaryReport,
    payrollSummaryReport,
    getAllYears,
    allYears
  } = useContext(LoginContex);


  const [yearInfo, setyear] = useState();
  useEffect(() => {
    getAllYears();
    fetchPayrollSummaryReport();
  }, []);
  //console.log(yearInfo)
  // //console.log(fetchPayrollSummaryReport(year))
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  //console.log(allYears)
  
   
      const filteredItems = payrollSummaryReport?.filter(
        (item,i) =>
       item.month.toLowerCase().includes(filterText.toLowerCase()) && item.month ||
        item.year.toString().toLowerCase().includes(filterText.toLowerCase())  && item.year
      );
  
    document.title = "Payroll Summary Report";

  // //console.log(filteredItems);
  // //console.log(ticketRegReport);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        setyear("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  document.title = "GeneratePayrollSummary Report ";

  const columnss = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> No:</h6>,
      selector: (row,i) =>i +1,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Year</h6>,
      selector: (row) => row?.year,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Month</h6>,
      selector: (row) => row?.month ,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Total Amount</h6>,
      selector: (row) => row?.totalNetAmount,
    },
    
   

   
  ];

  const filterThroughDate = () => {
    // //console.log(yearInfo.value)
    // setyear('') 

    fetchPayrollSummaryReport(yearInfo?.value)
    // //console.log(years);
    // //console.log(unique);
  };

  const clearFilter = () => {
    setHelper(false);
 
    setyear("")
  };



  // //console.log(customer);
 
  const years = []
  
  for (let i = 0; i < allYears.length; i++) {
    // //console.log(setEmployeeId[i]._id);

    var dropdownList = {
      value: allYears[i],
      label: ` ${allYears[i]}`,
    };
    // var unique = [...new Set(dropdownList)]
  
    years.push(dropdownList);
  }


  const filterYears = [...new Map(years.map(item =>
    [item['value'], item])).values()];
//  //console.log(filterYears)



const CvsData = [];
for (let i = 0; i < filteredItems?.length; i++) {
  // //console.log(filteredItems[i]._id);

 
    var dropdownList = {
      year: filteredItems[i]?.year,
      month: ` ${filteredItems[i]?.month}`,
      totalNetAmount: ` ${filteredItems[i]?.totalNetAmount}`,
     
     

    };

 

  CvsData.push(dropdownList);
}
//console.log(CvsData)
// //console.log(ticketSupervisorReport)

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(CvsData?.[0] || []);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

// ** Downloads CSV
function downloadCSV(array) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv === null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Generate Payroll Summary Report" pageTitle="Report" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-asuto">
                      <div>
                        <ToastContainer />
                       
                        <div>
                        <h4 className="card-title mb-0">Generate Payroll Summary Report</h4>
                          <div className="form-label" style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end"}}>
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={()=>{
                              downloadCSV(CvsData)
                            }}
                            type="button"
                            //  id="create-btn"
                          > 
                            <i className="fa fa-print mx-2" aria-hidden="true"> </i>  
                            Download Excel
                          </Button>
                        </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="mb-5">
                    <Col md={3}>
                    <div className="mb-3">
                      <label className="form-label">
                        Years <span className="text-danger">*</span>
                      </label>
                        <Select
                          // className="bg-white"
                          options={filterYears}
                          onChange={(choice) => setyear(choice)}
                          
                          value={filterYears.filter(function (option) {
                            return option.value === yearInfo?.value;
                          })}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                            },
                          })}
                        />
                    </div>
                      </Col>
              
                      
                      
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={()=>filterThroughDate()}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Filter By year{" "}
                          </Button>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={clearFilter}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Clear filter{" "}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                   

{
                      <DataTable
                        columns={columnss}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        // selectableRows
                        persistTableHead
                      // sortFunction={customSort}
                      />
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Open Closed Card Distribution From */}

      {/* Remove Modal */}
    </React.Fragment>
  );
};

export default GeneratePayrollSummaryReport;
