import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Container,
  Row,
  Label,
  Table,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Grid } from "gridjs-react";
import Flatpickr from "react-flatpickr";
import moment from "moment";

const SalesReport = () => {
  //LOCAL states
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [helper, setHelper] = useState(false);
  const {
    inventoryReport,
    inventoryReports,
    purchReport,
    purchaseReport,
    salesReport,
    getsalesReport,
    purchaseSalesByDate,
    salesRepoDate,
  } = useContext(LoginContex);

  useEffect(() => {
    inventoryReport();
    purchaseReport();
    getsalesReport();
  }, []);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = helper
    ? salesRepoDate.length > 0 && salesRepoDate?.filter(
        (item) =>
          item.item?.itemId?.itemName &&
          item.item?.itemId?.itemName
            .toLowerCase()
            .includes(filterText.toLowerCase())
      )
    : salesReport.length > 0 && salesReport?.filter(
        (item) =>
          item.item?.itemId?.itemName &&
          item.item?.itemId?.itemName
            .toLowerCase()
            .includes(filterText.toLowerCase())
      );
  //console.log(filteredItems);
  //console.log(filterText);
  //console.log(salesReport);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  //console.log(inventoryReports);
  //console.log(purchReport);

  document.title = "Sales Report";

  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> Item</h6>,
      selector: (row) => row.item?.itemId?.itemName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Quantity</h6>,
      selector: (row) => row.item?.quantity,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Rate</h6>,
      selector: (row) => row.item?.unitPrice,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Total</h6>,
      selector: (row) => row.item?.reciversUploaded,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Date</h6>,
      selector: (row) => moment(row.date).utc().format("DD-MM-YYYY"),
    },
  ];

  const sortedInventories = [];
  for (let i = 0; i < inventoryReports?.length; i++) {
    let singleArr = [
      inventoryReports[i]?.itemId?.itemName,
      inventoryReports[i]?.availableQuantity,
      inventoryReports[i]?.storeId?.storeName,

      // inventoryReports[i]._id,
    ];

    sortedInventories.push(singleArr);
  }
  const sortedPurchases = [];
  for (let i = 0; i < purchReport?.length; i++) {
    //console.log(purchReport[i]?.date);
    let date = purchReport[i]?.date.split("T");
    const formattedDate = moment(date[0]).format("DD-MM-YYYY");
    let singleArr = [
      purchReport[i]?.item?.itemId?.itemName,
      purchReport[i]?.item?.quantity,
      purchReport[i]?.item?.reciversUploaded,
      purchReport[i]?.supplier?.supplierName,
      formattedDate,

      // inventoryReports[i]._id,
    ];

    sortedPurchases.push(singleArr);
  }
  const sortedSales = [];
  for (let i = 0; i < salesReport?.length; i++) {
    //console.log(salesReport[i]?.date);
    let date = salesReport[i]?.date.split("T");
    const formattedDate = moment(date[0]).format("DD-MM-YYYY");
    let singleArr = [
      salesReport[i].item?.itemId?.itemName || "not available",
      salesReport[i].item?.quantity,
      salesReport[i]?.item?.unitPrice,
      Number(salesReport[i]?.item?.quantity) *
        Number(salesReport[i]?.item?.unitPrice),
      formattedDate,

      // inventoryReports[i]._id,
    ];

    sortedSales.push(singleArr);
  }

  //console.log(salesRepoDate);
  const sortedSalesByDate = [];
  for (let i = 0; i < salesRepoDate?.length; i++) {
    //console.log(salesRepoDate[i]?.date);
    let date = salesRepoDate[i]?.date.split("T");
    const formattedDate = moment(date[0]).format("DD-MM-YYYY");
    let singleArr = [
      salesRepoDate[i].item?.itemId?.itemName || "not available",
      salesRepoDate[i]?.item?.quantity,
      salesRepoDate[i]?.item?.unitPrice,
      Number(salesRepoDate[i]?.item?.quantity) *
        Number(salesRepoDate[i]?.item.unitPrice),
      formattedDate,

      // inventoryReports[i]._id,
    ];

    sortedSalesByDate.push(singleArr);
  }
  //console.log(salesReport);

  const filterThroughDate = () => {
    setHelper(true);
    purchaseSalesByDate(start, end);
  };

  const clearFilter = () => {
    setHelper(false);
    setEnd("");
    setStart("");
  };

  // function convertArrayToPDF(data) {
  //   //console.log(data)
  //   var doc = new jsPDF();
  
  //   data.forEach(function(item) {
  //     Object.keys(item).forEach(function(key) {
  //       doc.text(key + ': ' + item[key], 10, 10);
  //     });
  //   });
  
  //   doc.save('array-data.pdf');
  // }
//   function downloadPDF(data) {
//     //console.log(data)
//     const doc = new jsPDF();
//     data.forEach((item, index) => {
//         doc.text(`${index + 1}. ${JSON.stringify(item)}`, 10, 10 * (index + 1));
//     });
//     doc.save('data.pdf');
// }

const CvsData = [];
for (let i = 0; i < filteredItems?.length; i++) {
  // //console.log(filteredItems[i]._id);

  if(filteredItems[i]?.item?.itemId?.itemName){
    var dropdownList = {
      itemName: filteredItems[i]?.item?.itemId?.itemName,
      price: ` ${filteredItems[i]?.item?.unitPrice}`,
      quantity: ` ${filteredItems?.[i]?.item?.quantity}`,
     
     
    
      Date: ` ${moment(filteredItems[i]?.date).utc().format("DD-MM-YYYY")}`,
     

    };
  }
 

  CvsData.push(dropdownList);
}

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(CvsData?.[0] || []);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

// ** Downloads CSV
function downloadCSV(array) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv === null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}
  
  const names = ["muscab","usama","suheyb","salmaan","khaalid"]
  //console.log(names.length)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Report Center" pageTitle="Sales Report" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">Sales Reports </h4>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end">
                    <div className="hstack gap-2 justify-content-end">
                      <button type="button" className="btn btn-info" onClick={()=> downloadCSV(CvsData)}>
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        Download
                      </button>
                    </div>{" "}
                  </div>
                </CardHeader>

                <CardBody>
                  <div id="customerList">
                    <Row className="mb-5">
                      {/* <Col md={3}>
                        <div className="mb-3">
                          <label>Supplier</label>
                          <Select
                            options={suppliersArr}
                            onChange={(choice) => setSupplierId(choice)}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                // text: "orangered",
                                // primary25: "hotpink",
                                // primary: "black",
                              },
                            })}
                          />
                        </div>
                      </Col> */}
                      <Col md={3}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Start Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStart(date)}
                            value={start}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            End Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEnd(date)}
                            value={end}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={filterThroughDate}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Filter By Date{" "}
                          </Button>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={clearFilter}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Clear filter{" "}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    {salesReport.length > 0 && (
                      <DataTable
                        columns={columns}
                        data={filteredItems || []}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        // selectableRows
                        persistTableHead
                        // sortFunction={customSort}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* customer Registration From */}

      {/* Remove Modal */}
    </React.Fragment>
  );
};

export default SalesReport;
