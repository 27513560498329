import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Container,
  Row,
  Table,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Grid } from "gridjs-react";
import moment from "moment";
const InventriesReport = () => {
  //LOCAL states
  const {
    inventoryReport,
    inventoryReports,
    purchReport,
    purchaseReport,
    salesReport,
    getsalesReport,
  } = useContext(LoginContex);

  useEffect(() => {
    inventoryReport();
    purchaseReport();
    getsalesReport();
  }, []);

  //console.log(inventoryReports);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = inventoryReports.filter(
    (item) =>
      item.itemId?.itemName &&
      item.itemId?.itemName.toLowerCase().includes(filterText.toLowerCase())
  );
  //console.log(filteredItems);
  //console.log(filterText);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  document.title = " Inventories Report ";

  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> Item</h6>,
      selector: (row) => row.itemId?.itemName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Available Quantity</h6>,
      selector: (row) => row.availableQuantity,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Store</h6>,
      selector: (row) => row.storeId?.storeName,
    },
  ];

  const sortedInventories = [];
  for (let i = 0; i < inventoryReports?.length; i++) {
    let singleArr = [
      inventoryReports[i]?.itemId?.itemName,
      inventoryReports[i]?.availableQuantity,
      inventoryReports[i]?.storeId?.storeName,

      // inventoryReports[i]._id,
    ];

    sortedInventories.push(singleArr);
  }
  const sortedPurchases = [];
  for (let i = 0; i < purchReport?.length; i++) {
    //console.log(purchReport[i]?.date);
    let date = purchReport[i]?.date.split("T");
    const formattedDate = moment(date[0]).format("DD-MM-YYYY");
    let singleArr = [
      purchReport[i]?.item?.itemId?.itemName,
      purchReport[i]?.item?.quantity,
      purchReport[i]?.item?.reciversUploaded,
      purchReport[i]?.supplier?.supplierName,
      formattedDate,

      // inventoryReports[i]._id,
    ];

    sortedPurchases.push(singleArr);
  }
  const sortedSales = [];
  for (let i = 0; i < salesReport?.length; i++) {
    //console.log(salesReport[i].date);
    let date = salesReport[i].date.split("T");
    const formattedDate = moment(date[0]).format("DD-MM-YYYY");
    let singleArr = [
      salesReport[i].item?.itemId?.itemName || "not available",
      salesReport[i].item.quantity,
      salesReport[i].item.unitPrice,
      Number(salesReport[i].item.quantity) *
        Number(salesReport[i].item.unitPrice),
      formattedDate,

      // inventoryReports[i]._id,
    ];

    sortedSales.push(singleArr);
  }
  //console.log(salesReport);

  const CvsData = [];
  for (let i = 0; i < filteredItems?.length; i++) {
    // //console.log(filteredItems[i]._id);
  
    if(filteredItems?.[i]?.itemId?.itemName){
      var dropdownList = {
        itemName: filteredItems?.[i]?.itemId?.itemName,
        availableQuantity: ` ${filteredItems?.[i]?.availableQuantity}`,
        store: ` ${filteredItems?.[i]?.storeId?.storeName}`,
       
       
  
      };
    }
   
  
    CvsData.push(dropdownList);
  }

  function convertArrayOfObjectsToCSV(array) {
    let result;
  
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData?.[0] || []);
  
    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
  
        result += item[key];
  
        ctr++;
      });
      result += lineDelimiter;
    });
  
    return result;
  }
  
  // ** Downloads CSV
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;
  
    const filename = "export.csv";
  
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
  
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Inventries Report" pageTitle="Report Center" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-autdo">
                      <ToastContainer />
                      <div>
                        <div
                          className="d-flex justify-content-space-between"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h4 className="card-title mb-0">
                            Inventories Report
                          </h4>

                          <div className="form-label" style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end"}}>
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={()=>{
                              downloadCSV(CvsData)
                            }}
                            type="button"
                            //  id="create-btn"
                          > 
                            <i className="fa fa-print mx-2" aria-hidden="true"> </i>  
                            Download Excel
                          </Button>
                        </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end">
                    <div className="hstack gap-2 justify-content-end">
                      {/* <button type="button" className="btn btn-info">
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        Export
                      </button> */}
                    </div>{" "}
                  </div>
                </CardHeader>

                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3"></Row>

                    {inventoryReports.length > 0 && (
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        // onChangePage={5}
                        // onChangePage={}
                        // selectableRows
                        persistTableHead
                        // sortFunction={customSort}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* customer Registration From */}

      {/* Remove Modal */}
    </React.Fragment>
  );
};

export default InventriesReport;
