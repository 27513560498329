import React, { useContext, useEffect } from "react";
import {
  CardBody,
  Row,
  Col,
  Card,
  Table,
  CardHeader,
  Container,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link, useParams } from "react-router-dom";

import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import { LoginContex } from "../../context/loginContext/LoginContext";

const InvoiceDetails = () => {
  const { the_id } = useParams();
  const { FetchSinglePurchase, singlePurchaseOrder } = useContext(LoginContex);
  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };

  useEffect(() => {
    FetchSinglePurchase(the_id);
  }, [the_id]);

  //console.log(singlePurchaseOrder?.[0]?.purchaseOrderDetails);
  document.title = "Invoice Details ";

  const totalAmount = singlePurchaseOrder?.[0]?.purchaseOrderDetails?.reduce(
    (accu, curr) => {
      return accu + curr["amount"];
    },
    0
  );

  //console.log(totalAmount);
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Invoice Details" pageTitle="Invoices" />

        <Row className="justify-content-center">
          <Col xxl={12}>
            <Card id="demo">
              <Row>
                {/* <Col lg={12}>
                  <CardHeader className="border-bottom-dashed p-4">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <img
                          src={logoDark}
                          className="card-logo card-logo-dark"
                          alt="logo dark"
                          height="17"
                        />
                        <img
                          src={logoLight}
                          className="card-logo card-logo-light"
                          alt="logo light"
                          height="17"
                        />
                      </div>
                      <div className="flex-shrink-0 mt-sm-0 mt-3"></div>
                    </div>
                  </CardHeader>
                </Col> */}
                <Col lg={12}>
                  <CardBody className="p-4">
                    <Row className="g-3">
                      {/* <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                          Invoice No
                        </p>
                        <h5 className="fs-14 mb-0">
                          #VL<span id="invoice-no">25000355</span>
                        </h5>
                      </Col> */}
                      {/* <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                          Date
                        </p>
                        <h5 className="fs-14 mb-0">
                          <span id="invoice-date">23 Nov, 2021</span>{" "}
                          <small className="text-muted" id="invoice-time">
                            02:36PM
                          </small>
                        </h5>
                      </Col> */}
                      {/* <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                          Payment Status
                        </p>
                        <span
                          className="badge badge-soft-success fs-11"
                          id="payment-status"
                        >
                          Paid
                        </span>
                      </Col> */}
                      {/* <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                          Total Amount
                        </p>
                        <h5 className="fs-14 mb-0">
                          $<span id="total-amount">755.96</span>
                        </h5>
                      </Col> */}
                    </Row>
                  </CardBody>
                </Col>
                {/* <Col lg={12}>
                  <CardBody className="p-4 border-top border-top-dashed">
                    <Row className="g-3">
                      <Col sm={6}>
                        <h6 className="text-muted text-uppercase fw-semibold mb-3">
                          Billing Address
                        </h6>
                        <p className="fw-medium mb-2" id="billing-name">
                          David Nichols
                        </p>
                        <p
                          className="text-muted mb-1"
                          id="billing-address-line-1"
                        >
                          305 S San Gabriel Blvd
                        </p>
                        <p className="text-muted mb-1">
                          <span>Phone: +</span>
                          <span id="billing-phone-no">(123) 456-7890</span>
                        </p>
                        <p className="text-muted mb-0">
                          <span>Tax: </span>
                          <span id="billing-tax-no">12-3456789</span>
                        </p>
                      </Col>
                      <Col sm={6}>
                        <h6 className="text-muted text-uppercase fw-semibold mb-3">
                          Shipping Address
                        </h6>
                        <p className="fw-medium mb-2" id="shipping-name">
                          David Nichols
                        </p>
                        <p
                          className="text-muted mb-1"
                          id="shipping-address-line-1"
                        >
                          305 S San Gabriel Blvd
                        </p>
                        <p className="text-muted mb-1">
                          <span>Phone: +</span>
                          <span id="shipping-phone-no">(123) 456-7890</span>
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Col> */}
                <Col lg={12}>
                  <CardBody className="p-4">
                    <div className="table-responsive">
                      <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                        <thead>
                          <tr className="table-active">
                            <th scope="col" style={{ width: "50px" }}>
                              #
                            </th>
                            <th scope="col">Purchase Order Details</th>
                            <th scope="col">Rate</th>
                            <th scope="col">Quantity</th>
                            <th scope="col" className="text-end">
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody id="products-list">
                          {singlePurchaseOrder?.[0]?.purchaseOrderDetails?.map(
                            (invoice, index) => {
                              //console.log(invoice);
                              return (
                                <>
                                  {" "}
                                  <tr>
                                    <th scope="row"> {index + 1}</th>
                                    <td className="text-start">
                                      <span className="fw-medium">
                                        {invoice?.itemId?.itemName}
                                      </span>
                                      <p className="text-muted mb-0">
                                        {invoice.description}
                                      </p>
                                    </td>
                                    <td>$ {invoice.unitPrice}</td>
                                    <td> {invoice.quantity}</td>
                                    <td className="text-end">
                                      ${" "}
                                      {Number(invoice.unitPrice) *
                                        Number(invoice.quantity)}{" "}
                                    </td>
                                  </tr>
                                </>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </div>
                    <div className="border-top border-top-dashed mt-2">
                      <Table
                        className="table table-borderless table-nowrap align-middle mb-0 ms-auto"
                        style={{ width: "250px" }}
                      >
                        <tbody>
                          <tr>
                            <td>Sub Total</td>
                            <td className="text-end">$ {totalAmount} </td>
                          </tr>
                          {/* 
                          <tr>
                            <td>
                              Discount <small className="text-muted"></small>
                            </td>
                            <td className="text-end"> $53.99</td>
                          </tr> */}

                          <tr className="border-top border-top-dashed fs-15">
                            <th scope="row">Total Amount</th>
                            <th className="text-end">${totalAmount} </th>
                          </tr>
                        </tbody>
                      </Table>
                    </div>

                    {/* <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                      <Link
                        to="#"
                        onClick={printInvoice}
                        className="btn btn-success"
                      >
                        <i className="ri-printer-line align-bottom me-1"></i>{" "}
                        Print
                      </Link>
                      <Link to="#" className="btn btn-primary">
                        <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                        Download
                      </Link>
                    </div> */}
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InvoiceDetails;
