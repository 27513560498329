import React, { useState, useEffect, useContext, useMemo } from "react";

import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import moment from "moment";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import DataTable from "react-data-table-component";
const TicketRegistrationReport = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardPackage, setCardPackage] = useState("");
  const [customer, setCustomer] = useState();
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [openDate, setOpenDate] = useState("");
  const [closeDate, setCloseDate] = useState("");
  const [usingDays, setUsingDays] = useState("");
  const [amount, setAmount] = useState(8);
  const [Discription, setdescription] = useState("");
  const { openAnalogCardRegister } = useContext(LoginContex);
  const [type, setTyp] = useState("text");
  const [customerInfo, setCustomerInfo] = useState();
  const [customerId, setCustomerId] = useState();
  const [Custom, setCustom] = useState("text");
  const [channelId, setChanell] = useState();
  const [cycle, setCycle] = useState("");
  const [numberOfDays, setNumberOfDays] = useState();
  const [numberOfMonths, setNumberOfMonths] = useState();
  const [billAmount, setBillAmount] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [helper, setHelper] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [modal_delete, setmodal_delete] = useState(false);

  document.title = "Ticket Registration Report";

  const {
    fetchOpenCards,
    OpenCards,
  
    cardList,
    channelPackage,
    fetchChanelPackages,
    closeAnalogCard,
    getEmployee,
    fetchEmployees,
    FetchSummaryCloseReport,
    summaryCloseReport,
    fetchCustomers,
    customers,fetchTicketRegesterationReport,ticketRegReport
  } = useContext(LoginContex);

  useEffect(() => {
    // FetchAllOpenCardsReport();
    // FetchSummaryCableReport();
    fetchCustomers();
    fetchEmployees();
    FetchSummaryCloseReport();
  }, []);

  //console.log(customers);

  //console.log(openDate);
  //console.log(start);
  //console.log(end);
  //console.log(OpenCards);

 

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = ticketRegReport.filter(
    (item) =>
      item.customers[0]?.customerName &&
      item.customers[0]?.customerName?.toLowerCase().includes(filterText.toLowerCase()) && item.customers[0]?.customerPhone ||
      item.customers[0]?.customerPhone?.toString().toLowerCase().includes(filterText.toLowerCase()) 
  );
  //console.log(filteredItems);
  //console.log(ticketRegReport);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  document.title = "Ticket ";

  const columnss = [
    {
      name: <h6 style={{ fontWeight: "" }}> No</h6>,
      selector: (row, index) => index+1,
      width:"50px"
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Customer</h6>,
      selector: (row) => row?.customers?.[0]?.customerName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}>  Phone </h6>,
      selector: (row) => row?.customers?.[0]?.customerPhone,
      width:"100px"
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Address </h6>,
      selector: (row) => row?.customers?.[0]?.customerAddress,
      
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Employee </h6>,
      selector: (row) => row?.employees?.[0]?.empName } ,
    {
      name: <h6 style={{ fontWeight: "bold" }}> Phone  </h6>,
      selector: (row) => row?.employees?.[0]?.mobile
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Ticket Title  </h6>,
      selector: (row) => row?.title
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>User </h6>,
      selector: (row) => row.users_info?.[0]?.username,
    
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Date</h6>,
      // selector: (row) => row.createdAt,
      selector: (row) =>  moment(row?.createdAt).utc().format("YYYY-MM-DD HH:mm"),
      width:"140px"
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Status</h6>,
      selector: (row) => row?.customers?.[0]?.isStatus ? "Before" : "After",

    },
   

   
  ];


  const CvsData = [];
  for (let i = 0; i < filteredItems?.length; i++) {
    //console.log(filteredItems[i]._id);

    if(filteredItems[i]?.customers?.[0]?.customerName){
      var dropdownList = {
        Customer: filteredItems[i]?.customers?.[0]?.customerName,
        customerPhone: ` ${filteredItems[i]?.customers?.[0]?.customerPhone}`,
        Address: ` ${filteredItems[i]?.customers?.[0]?.customerAddress}`,
        Employee: ` ${filteredItems[i]?.employees?.[0]?.empName}`,
        EmployeePhone: ` ${filteredItems[i]?.employees?.[0]?.mobile}`,
        TicketTitle: ` ${filteredItems[i]?.title}`,
        Username: ` ${filteredItems[i]?.users_info?.[0]?.username}`,
        Date: ` ${moment(filteredItems[i]?.createdAt).utc().format("YYYY-MM-DD HH:mm")}`,
        Status: ` ${filteredItems[i]?.customers?.[0]?.isStatus ? "Before" : "After"}`,
  
      };
    }
   

    CvsData.push(dropdownList);
  }
//console.log(CvsData)
//console.log(ticketRegReport)

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData?.[0] || []);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // ** Downloads CSV
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }


  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  //console.log(ticketRegReport)
  const columns = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "customers[0].customerName",
        filterable: false,
      },
      {
        Header: "Customer Phone Number",
        accessor: "customers[0].customerPhone",
        filterable: false,
      },
      {
        Header: "Customer Address",
        accessor: "customers[0].customerAddress",
        filterable: false,
      },
      {
        Header: "Employee Name",
        accessor: "employees[0].empName",
        filterable: false,
      },
      {
        Header: "Employee Phone Number",
        accessor: "employees[0].mobile",
        filterable: false,
      },
      {
        Header: "Date",
        accessor: `{customers[0].isStatus == true ? "Yes" : "No"}`,

        filterable: false,
      },
      {
        Header: " Status",
        accessor: "customers[0].isStatus == true ? 'Yes' :'No' ",
        filterable: false,
      },
    ]
    // [handleCustomerClick]
  );
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handlerSubmitOpen = (e) => {
    //console.log(e);
    e.preventDefault();
    if (
      !customerInfo?.cardNumber ||
      !customerInfo?.receiverType ||
      !customerInfo?.value ||
      !customerInfo?.value ||
      !channelId ||
      !cycle ||
      !openDate ||
      !billAmount
    ) {
      showToastMessage("please provide required values");
      return;
    }
    //console.log(numberOfMonths);
    //console.log(closeDate);
    //console.log();
    //console.log("first");
    const data = {
      cardNumber: customerInfo?.cardNumber,
      receivertype: customerInfo?.receiverType,
      customerId: customerInfo?.value,
      noOfMonthDay: cycle == "month" ? numberOfMonths : numberOfDays,
      endDate:
        cycle === "month"
          ? addMonths(Number(numberOfMonths), dateee)
          : addDays(Number(numberOfDays)),
      startDate: openDate,

      openDate: openDate,
      billingCycle: cycle,
      billAmount: billAmount,
      channelPackageId: channelId,
    };
    e.target.reset();
    openAnalogCardRegister(data);
    setmodal_list(false);
    //console.log(data);

    // showToastMessage("succefully opened card");
  };

  const editPop = (data) => {
    //console.log(data);
    setmodal_list(true);
    setCardNumber(data.cardNumber);
    setBillingCycle(data.billingCycle);
    setCardType(data.receiverType);
    setOpenDate(data.open_date);
    // setCloseDate(data.closeDate);
    setCustomer(data.customerId.customerName);
    setCardPackage(data.channel_package);
  };

  const setbilling = (e) => {
    setBillingCycle(e);

    //console.log(e);
    let type = e;

    if (type === "Custom") {
      // document.getElementById("customfield").style.display = "block";
      // document.getElementById("billamount").setAttribute("md={4}");
    } else {
      // document.getElementById("customfield").style.display = "none";
      // document.getElementById("billamount").setAttribute("md={8}");
    }
  };

  let neeew = "11-11-2022";
  if (billingCycle === "monthly") {
    //console.log(openDate);
    const test = moment(openDate).utc().format("MM/DD/YYYY");
    //console.log(test);
    neeew = new Date(test);
    neeew.setDate(neeew.getDate() + 30);

    //console.log(neeew);
  }

  const customizeDate = (month = 0, dayy = 0) => {
    //console.log(month);
    const test = moment(openDate).utc().format("MM/DD/YYYY");
    const yep = new Date(test);
    const day = yep.getDate() + 1 + Number(dayy);
    const moonth = `${yep.setMonth(yep.getMonth() + Number(month))}`;
    const year = yep.getFullYear();

    //console.log(day, moonth, year);

    const foor = day + "-" + moonth + "-" + year;
    const f = moment(foor).format("D/MM/YYYY");

    setCloseDate(f);
    // //console.log(yep.getMonth());
  };

  //console.log(openDate);

  function dateWithMonthsDelay(months) {
    //console.log(months);
    const date = new Date(openDate);
    date.setMonth(date.getMonth() + months);
    // setCloseDate(date);

    return date;
  }

  const filterThroughDate = () => {
    setHelper(true);
    const startt = moment(start).format("YYYY-MM-DD");
    const enddd = moment(end).format("YYYY-MM-DD");
    fetchTicketRegesterationReport(startt, enddd, employeeId?.value);
  };

  const clearFilter = () => {
    setHelper(false);
    setStart("")
    setEnd("")
    setEmployeeId("")
  };
  //console.log(closeDate);

  // //console.log();
  // //console.log(neeew);
  //console.log("type is" + typeof neeew);

  const chan = () => {
    setTyp("date");
  };

  //console.log(customer);
  let Emp = [];
  Emp.push({ label: "all", value: "all" });
  for (let i = 0; i < getEmployee.length; i++) {
    // //console.log(setEmployeeId[i]._id);

    var dropdownList = {
      value: getEmployee[i]._id,
      label: ` ${getEmployee[i].empName}`,
    };

    Emp.push(dropdownList);
  }

  

  const customerCards = [];

  for (let i = 0; i < cardList.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: cardList?.[i]?.dataCustomers?._id,
      cardNumber: cardList?.[i]?.cardNumber,
      receiverType: cardList?.[i]?.datareceivers?.itemType,
      label: cardList?.[i]?.dataCustomers?.customerName,
    };

    customerCards.push(dropdownList);
  }

  //console.log(cardList);

  function addMonths(numOfMonths, date = new Date()) {
    //console.log(numOfMonths);
    date.setMonth(date.getMonth() + numOfMonths);
    setCloseDate(date);

    return date;
  }

  function addDays(days) {
    var result = new Date(openDate);
    result.setDate(result.getDate() + days);
    setCloseDate(result);
    return result;
  }

  // //console.log(closeDate);
  //console.log(closeDate);
  // 👇️ Add 2 months to current Date
  // const result = addMonths(2);

  // 👇️ Add months to another date
  const dateee = new Date(openDate);
  const someFn = (row) => {
    //console.log(row);
    setmodal_delete(true);
    const selectedRow = {
      name: row.customerName,
      address: row.customerAddress,
      cardNumber: row.cardNumber,
      receiverType: row.receiverType,
      _id: row._id,
      openDate: row.openDate,
      closeDate: row.endDate,
    };
    setInfo(selectedRow);
  };

  //console.log(info);
  const closeCard = () => {
    const data = {
      id: info._id,
      description: status,
    };
    //console.log(data);
    closeAnalogCard(data);
    setStatus("");
    setmodal_delete(false);
  };
  //   const districtArr = [];

  //   for (let i = 0; i < districts.length; i++) {
  //     // //console.log(items[i]._id);

  //     var dropdownList = {
  //       value: districts[i]._id,
  //       label: districts[i].districtName,
  //     };

  //     districtArr.push(dropdownList);
  //   }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Ticket Registration Report" pageTitle="Report" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div className="">
                          <h4 className="card-title mb-0">Ticket Registration Report</h4>
                          {/* <Button>Go Back</Button> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="mb-5">
                    <Col md={2}>
                    <div className="mb-3">
                      <label className="form-label">
                        Employee <span className="text-danger">*</span>
                      </label>
                        <Select
                          // className="bg-white"
                          options={Emp}
                          onChange={(choice) => setEmployeeId(choice)}
                          value={Emp.filter(function (option) {
                            return option.value === employeeId?.value;
                          })}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                            },
                          })}
                        />
                    </div>
                      </Col>
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Start Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStart(date)}
                            value={start}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            End Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEnd(date)}
                            value={end}
                          />
                        </div>
                      </Col>
                      
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={filterThroughDate}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Filter By Date{" "}
                          </Button>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={clearFilter}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Clear filter{" "}
                          </Button>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={()=>{
                              downloadCSV(CvsData)
                            }}
                            type="button"
                            //  id="create-btn"
                          > 
                            <i className="fa fa-print mx-2" aria-hidden="true"> </i>  
                            Download Excel
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    {/* <TableContainer
                      columns={columns}
                      data={ticketRegReport}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      // handleCustomerClick={handleCustomerClicks}
                      // isCustomerFilter={true}
                    /> */}

{
                      <DataTable
                        columns={columnss}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        // selectableRows
                        persistTableHead
                      // sortFunction={customSort}
                      />
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Open Closed Card Distribution From */}

      {/* Remove Modal */}
    </React.Fragment>
  );
};

export default TicketRegistrationReport;
