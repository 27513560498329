import React, {
  useState,
  useEffect,
  useContext,
  componentDidMount,
  useRef,
  useMemo,
} from "react";
import Select from "react-select";
import ReactDOM from "react-dom";
// import $ from 'jquery';
import { Helmet } from "react-helmet";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";
import { GrDocumentPdf } from "react-icons/gr";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { BsPrinter } from "react-icons/bs";
import { Grid, _ } from "gridjs-react";
import * as XLSX from "xlsx";
import "./styleEmp.css";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Alert,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";

const EmployeeRegistration = () => {
  const [serviceList, setServiceList] = useState([
    { item: "", QtyAvailable: "", Qty: "", rate: "", Amount: "" },
  ]);

  const {
    departments,
    getAllTitles,
    EmployeeRegister,
    fetchEmpTitle,
    fetchDepartment,
    fetchEmployees,
    fetchSites,
    getEmployee,
    deleteEmployee,
    updateEmployee,
    fetchGroups,
    sites,
    empsGroups,
    Zones,
    getGroups,
    fetchzones,User, fetchShifts,shifts
  } = useContext(LoginContex);

  // Employees
  const [EmployeeId, setEmployeeId] = useState();
  const [employeeName, setEmployeeName] = useState("");
  const [titleId, setTitle] = useState("");
  const [employeePhone, setEmployeePhone] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState();
  const [BaseSalary, setBaseSalary] = useState();
  const [bankName, setBankName] = useState();
  const [bankAccount, setBankAccount] = useState();
  const [empAddress, setempAddress] = useState();

  const [EmployeeDepartment, setEmployeeDepartment] = useState("");
  const [employeeShift, setEmployeeShift] = useState("");
  const [Branch, setBranch] = useState();
  const [groupId, setGroup] = useState();
  const [zoonId, setZone] = useState();

  const [SiteId, setSiteId] = useState();
  const [UserId, setUserId] = useState();
  const [hiredate, setHiredate] = useState();
  const [checked, setChecked] = useState(true);
  const [checked1, setChecked1] = useState(false);

  const [Disable, setDisable] = useState(true);
  const [HasSite, setHasSite] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [object_id, setObject] = useState();
  const [modal_list, setmodal_list] = useState(false);
  const [isError, setIsError] = useState(false);

  const tog_list = () => {
    setChecked(false);
    setChecked1(false);
    setmodal_list(!modal_list);
    setIsEditing(false);
    setDisable(false);
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const handleChange1 = () => {
    setChecked1(!checked1);
  }
  const handleChange = () => {
    setChecked(!checked);
   
    setDisable(!Disable);
    setHasSite(!HasSite);
    setSiteId("Select Site");
  };
  let userId = User?.data?.user?._id || User?.user?._id;
  useEffect(() => {
    fetchEmployees();
    fetchEmpTitle();
    fetchDepartment();
    fetchGroups();
    getYear();
    fetchSites();
    fetchShifts()
  }, []);
  //   //console.log(getGroups)
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  document.title = "Employee Registration  ";

  const columns = useMemo(
    () => [
      {
        Header: "EMP-NO",
        accessor: "empNo",
        filterable: true,
      },
      {
        Header: "Name",
        accessor: "empName",
        filterable: false,
      },
      {
        Header: "Phone Number",
        accessor: "mobile",
        filterable: false,
      },
      {
        Header: "Department",
        accessor: "departmentName",
        filterable: false,
      },
      {
        Header: "Shift",
        accessor: "shiftName",
        filterable: false,
      },
      {
        Header: "Title",
        accessor: "titleName",
        filterable: false,
      },
      {
        Header: "Hired Date",
        accessor: "hireDate",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    editPop(customerData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    deletPop(customerData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ]
    // [handleCustomerClick]
  );

  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,
      { item: "", QtyAvailable: "", Qty: "", rate: "", Amount: "" },
    ]);
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
    // handleServiceAdd();
  };

  const test = (e, index) => {
    handleServiceChange(e, index);
    if (e.target.name === "item") {
      handleServiceAdd();
    }
  };

  const clear = () => {
    setEmployeeId("");
    setEmployeeName("");
    setempAddress("");
    setEmployeePhone("");
    setEmployeeEmail("");
    setEmployeeDepartment("");
    setEmployeeShift("");
    setSiteId("");
    setBankAccount("");
    setBankName("");
    setBaseSalary("");
  };

  const [date, setDate] = useState();

  const getYear = () => setDate(new Date().toLocaleString() + "");

  const closing = () => {
    // setIsEditing(false);
    setmodal_list(false);
    clear();
  };

  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const addModal = () => {
    tog_list();
    setEmployeeId("");
    setEmployeeName("");
    setEmployeePhone("");
    setEmployeeEmail("");
    setEmployeeDepartment("");
    setEmployeeShift("");
    setTitle("");
    setBaseSalary("");
    setBankName("");
    setBankAccount("");
    setGroup("");
    setZone("");
    setBranch("");
    setSiteId("");
    setUserId("");
    setIsEditing(false);
  };

  // const editPop = (data) => {
  //   //console.log(data)
  //   setmodal_list(true);
  //   setEmployeeId(data._id);
  //   setEmployeeName(data.empName);
  //   setempAddress(data.empAddress);
  //   setEmployeePhone(data.mobile);
  //   setEmployeeEmail(data.empEmail);
  //   setEmployeeDepartment(data.departmentId);
  //   setEmployeeShift(data.shiftId);
  //   setTitle(data.titleId);
  //   setSiteId(data.siteId);
  //   setBankAccount(data.bankAccount);
  //   setBankName(data.bankName);
  //   setChecked(data.HasSite);
  //   setChecked1(data.isResigned);
  //   setObject(data._id);
  //   setBaseSalary(data.baseSalary); 

  //   if (data.siteId == null || data.siteId == ""){
  //     setChecked(false);
  //     setDisable(false);
  //   }else{
  //     setChecked(true);
  //     setDisable(true);
  //   }
  //   setIsEditing(true);
  // };
  const editPop = (data) => {
    setmodal_list(true);
    setEmployeeId(data._id);
    setEmployeeName(data.empName);
    setempAddress(data.empAddress);
    setEmployeePhone(data.mobile);
    setEmployeeEmail(data.empEmail);
    setEmployeeDepartment(data.departmentId);
  
    if (data.employeeShifts) {
      // Handle multiple shifts
      const shiftIdsArray = data.employeeShifts.split(','); // Convert the string to an array of shift IDs
      setEmployeeShift(shiftIdsArray);
    } else {
      // Handle single shift
      setEmployeeShift([data.shiftId]); // Store as an array to handle multi-select
    }
  
    setTitle(data.titleId);
    setSiteId(data.siteId);
    setBankAccount(data.bankAccount);
    setBankName(data.bankName);
    setChecked(data.HasSite);
    setChecked1(data.isResigned);
    setObject(data._id);
    setBaseSalary(data.baseSalary); 
  
    if (data.siteId == null || data.siteId == "") {
      setChecked(false);
      setDisable(false);
    } else {
      setChecked(true);
      setDisable(true);
    }
    setIsEditing(true);
  };
  const handleChangePhone = (e) => {
    const limit = 10;

    // Here we are checking if the length is equal to 9
    if (e.target.value.length === 10) {
      showToastMessage("Phone number should not exceed 10 digits!");
      setEmployeePhone(e.target.value.slice(0, limit));
    }
    setEmployeePhone(e.target.value.slice(0, limit));
  };

  // //console.log(employeePhone.value.length);
  const handlerSubmit = (e) => {
    if (isEditing == false) {
      e.preventDefault();

      if (isError == true) {
        showToastMessage("Please Provide Valid Phone number !");
        return;
      }
      if (checked == false) {
        if (
          !employeeName ||
          !employeePhone ||
          !EmployeeDepartment ||
          !employeeShift ||
          !titleId ||
          !bankAccount ||
          !bankName ||
          !BaseSalary
        ) {
          showToastMessage("Please Fill Required Fields !");
          return;
        }

        const data = {
          empName: employeeName,
          empAddress: empAddress,
          empEmail: employeeEmail,
          mobile: employeePhone,
          baseSalary: BaseSalary,
          bankName: bankName,
          bankAccount: bankAccount,
          departmentId: EmployeeDepartment,
          shiftId: employeeShift,
          siteId: null,
          titleId: titleId,
          isResigned: false,
          hireDate: date,
          userId: userId,
          hasSite: HasSite,

        };

        // console.log(data)
        EmployeeRegister(data);
        setmodal_list(false);

        setEmployeeId("");
        setEmployeeName("");
        setEmployeePhone("");
        setEmployeeEmail("");
        setEmployeeDepartment("");
        setEmployeeShift("");
        setempAddress("");
        setTitle("");
        setBaseSalary("");
        setBankName("");
        setBankAccount("");
        setGroup("");
        setZone("");
        setBranch("");
        setSiteId("");
        setUserId("");

        //console.log(data);
      } else {
        if (
          !employeeName ||
          !employeePhone ||
          !EmployeeDepartment ||
          !employeeShift ||
          !titleId ||
          !bankAccount ||
          !bankName ||
          !BaseSalary
        ) {
          showToastMessage("Please Fill Required Fields !");
          return;
        }

        const data = {
          empName: employeeName,
          empAddress: empAddress,
          empEmail: employeeEmail,
          mobile: employeePhone,
          baseSalary: BaseSalary,
          bankName: bankName,
          bankAccount: bankAccount,
          departmentId: EmployeeDepartment,
          shiftId:employeeShift,
          siteId: SiteId,
          titleId: titleId,
          isResigned: false,
          hireDate: date,
          userId: userId,
          hasSite: HasSite,

        };
        //console.log(data)
        EmployeeRegister(data);
        setmodal_list(false);

        setEmployeeId("");
        setEmployeeName("");
        setEmployeePhone("");
        setEmployeeEmail("");
        setEmployeeDepartment("");
        setEmployeeShift("");
        setTitle("");
        setBaseSalary("");
        setBankName("");
        setBankAccount("");
        setGroup("");
        setZone("");
        setBranch("");
        setSiteId("");
        setUserId("");
      }
    }

    if (isEditing == true) {
      e.preventDefault();

      if (checked == false) {
        const data = {
          empName: employeeName,
          empAddress: empAddress,
          empEmail: employeeEmail,
          mobile: employeePhone,
          baseSalary: BaseSalary,
          bankName: bankName,
          bankAccount: bankAccount,
          departmentId: EmployeeDepartment,
          shiftId: employeeShift,
          titleId: titleId,
          isResigned: checked1,
          hireDate: date,
          siteId: null,
          hasSite: HasSite,
          id: object_id,
        };
        //console.log(data);
        updateEmployee(data);
        setIsEditing(false);
        setmodal_list(false);
        setEmployeeId("");
        setEmployeeName("");
        setEmployeePhone("");
        setEmployeeEmail("");
        setEmployeeDepartment("");
        setEmployeeShift("");
        setTitle("");
        setempAddress("");
        setBaseSalary("");
        setBankName("");
        setBankAccount("");
        setGroup("");
        setZone("");
        setBranch("");
        setSiteId("");
        setUserId("");
      } else {
        const data = {
          empName: employeeName,
          empAddress: empAddress,
          empEmail: employeeEmail,
          mobile: employeePhone,
          baseSalary: BaseSalary,
          bankName: bankName,
          bankAccount: bankAccount,
          departmentId: EmployeeDepartment,
          shiftId: employeeShift,
          titleId: titleId,
          isResigned: checked1,
          hireDate: date,
          siteId: SiteId,
          hasSite: HasSite,
          id: object_id,
        };
        //console.log(data);
        updateEmployee(data);
        setIsEditing(false);
        setmodal_list(false);
        setEmployeeId("");
        setEmployeeName("");
        setEmployeePhone("");
        setEmployeeEmail("");
        setEmployeeDepartment("");
        setEmployeeShift("");
        setTitle("");
        setempAddress("");
        setBaseSalary("");
        setBankName("");
        setBankAccount("");
        setGroup("");
        setZone("");
        setBranch("");
        setSiteId("");
        setUserId("");
      }
    }
  };

  ///delete
  const deletPop = (data) => {
    setmodal_delete(true);
    setEmployeeId(data);
    
  };
  const deleteEmp = () => {
    deleteEmployee(EmployeeId._id);
    setmodal_delete(false);
  };

  const sortEmployees = [];
  for (let i = 0; i < getEmployee?.length; i++) {
    let singleArr = [
      getEmployee[i].empName,
      getEmployee[i].mobile,
      getEmployee[i].empEmail,
      getEmployee[i].departmentName,
      getEmployee[i].titleName,
      moment(getEmployee[i].hireDate).utc().format("YYYY-MM-DD"),
      getEmployee[i]._id,
      getEmployee[i].departmentId,
      getEmployee[i].titleId,
      getEmployee[i].siteId,
      getEmployee[i].hasSite,
      getEmployee[i].empAddress,
      getEmployee[i].baseSalary,
      getEmployee[i].bankName,
      getEmployee[i].bankAccount,
    ];

    sortEmployees.push(singleArr);
  }

  const DepartmentsArr = [];

  for (let i = 0; i < departments.length; i++) {
    // //console.log(departments[i]._id);

    var dropdownList = {
      value: departments[i]._id,
      label: ` ${departments[i].departmentName}`,
    };

    DepartmentsArr.push(dropdownList);
  }
  const shiftArr = [];

  for (let i = 0; i < shifts?.length; i++) {
    // //console.log(shifts[i]?._id);

    var list = {
      value: shifts[i]._id,
      label: ` ${shifts[i].shiftName}`,
    };
    // //console.log(list)
    shiftArr.push(list);
  }

  const handleTypeSelect = (e) => {
    setEmployeeDepartment(e.value);
  };
  // const handleShiftSelect = (selectedOptions) => {

  //   const selectedValues = selectedOptions ? selectedOptions.map(option => option.value).join(',') : "";
  //   setEmployeeShift(selectedValues);
 
  // };
  const handleShiftSelect = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setEmployeeShift(selectedValues);
  };
  const TitlesArr = [];
  for (let i = 0; i < getAllTitles.length; i++) {
    // //console.log(getAllTitles[i]._id);

    var dropdownList = {
      value: getAllTitles[i]._id,
      label: ` ${getAllTitles[i].title}`,
    };
    //console.log(dropdownList)
    TitlesArr.push(dropdownList);
  }

  const handleTypeSelectTitle = (e) => {
    setTitle(e.value);
  };
// //console.log(employeeShift)
  const SitesArr = [];
  for (let i = 0; i < sites.length; i++) {
    // //console.log(sites[i]._id);

    var dropdownList = {
      value: sites[i]._id,
      label: ` ${sites[i].siteName}`,
    };

    SitesArr.push(dropdownList);
  }

  const handleTypeSelectSite = (e) => {
    setSiteId(e.value);
  };

  //Print
  const printer = () => {
    let myStyle = '<link rel="stylesheet" href="./demo" />';
    let printContents = document.getElementById("divcontents").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = myStyle + printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.close();
    // window.onfocus=function(){ window.close();}
  };

  // var tab = document.getElementById('employeesExportCSV'); // id of table
  // //console.log(tab)
  // function fnExcelReport()
  // {
  //     var tab_text="<table border='2px'><tr bgcolor='#87AFC6'>";
  //     var textRange; var j=0;
  //     var tab = document.getElementById('employeesExportCSV'); // id of table

  //     for(j = 0 ; j < tab.rows.length ; j++)
  //     {
  //         tab_text=tab_text+tab.rows[j].innerHTML+"</tr>";
  //         //tab_text=tab_text+"</tr>";
  //     }

  //     tab_text=tab_text+"</table>";
  //     tab_text= tab_text.replace(/<A[^>]*>|<\/A>/g, "");//remove if u want links in your table
  //     tab_text= tab_text.replace(/<img[^>]*>/gi,""); // remove if u want images in your table
  //     tab_text= tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

  //     var ua = window.navigator.userAgent;
  //     var msie = ua.indexOf("MSIE ");

  //     // if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))      // If Internet Explorer
  //     // {
  //     //     txtArea1.document.open("txt/html","replace");
  //     //     txtArea1.document.write(tab_text);
  //     //     txtArea1.document.close();
  //     //     txtArea1.focus();
  //     //     sa=txtArea1.document.execCommand("SaveAs",true,"Say Thanks to Sumit.xls");
  //     // }
  //     // else                 //other browser not tested on IE 11
  //         let sa = window.open('data:application/vnd.ms-excel,' + encodeURIComponent(tab_text));

  //     return (sa);
  // }

  // const printFunction = (documentId) => {

  //     var doc = document.getElementById(documentId);

  //     //Wait until PDF is ready to print
  //     if (typeof doc.print === 'undefined') {
  //         setTimeout(function () { printFunction(documentId); }, 1000);
  //     } else {
  //         doc.print();
  //     }
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Helmet>
            <script src="html2pdf.bundle.min.js"></script>
          </Helmet>
          <BreadCrumb
            title="Employee Registration "
            pageTitle="Employee Registration"
          />
          <embed
            style={{
              display: "none",
            }}
            type="application/pdf"
            src="path_to_pdf_document.pdf"
            id="pdfDocument"
            width="100%"
            height="100%"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">Add Employee</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="g-4 mb-3"></Row>
                    {/* <Row style={{ display: "none" }}>
                        <button
                          className="btn btn-sm btn-success edit-item-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#showModal"
                          // onClick={exportTableToExcel('employeesExportCSV', 'EmployeesCSV')}
                          style={{
                            backgroundColor: "#405189",
                            border: "none",
                            marginLeft: "1.5%",
                          }}
                        >
                          <SiMicrosoftexcel size={"20"} />
                        </button>
                        <button
                          className="btn btn-sm btn-success edit-item-btn"
                          id="printpdf"
                          data-bs-toggle="modal"
                          data-bs-target="#showModal"
                          // onClick={fnExcelReport()}
                          style={{
                            backgroundColor: "#405189",
                            border: "none",
                            marginLeft: "1%",
                          }}
                        >
                          <BsFileEarmarkPdf size={"20"} />
                        </button>
                        <button
                          className="btn btn-sm btn-success edit-item-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#showModal"
                          onClick={printer}
                          style={{
                            backgroundColor: "#405189",
                            border: "none",
                            marginLeft: "1%",
                          }}
                        >
                          <BsPrinter size={"20"} />
                        </button>
                      </Row> */}
                    <TableContainer
                      columns={columns}
                      data={getEmployee || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                    // handleCustomerClick={handleCustomerClicks}
                    // isCustomerFilter={true}
                    />
                    {/* <Grid
                        data={sortEmployees}
                        columns={[
                          "Full Name.",
                          "Phone",
                          "Email",
                          "Department",
                          "Title",
                          "HireDate",
                          {
                            name: "ID",
                            hidden: true,
                          },

                          {
                            name: "DeptID",
                            hidden: true,
                          },
                          {
                            name: "TitleID",
                            hidden: true,
                          },
                          {
                            name: "SiteID",
                            hidden: true,
                          },
                          {
                            name: "HasSite",
                            hidden: true,
                          },
                          {
                            name: "Address",
                            hidden: true,
                          },
                          {
                            name: "Salary",
                            hidden: true,
                          },
                          {
                            name: "BankName",
                            hidden: true,
                          },
                          {
                            name: "BankAccount",
                            hidden: true,
                          },
                          {
                            name: "Actions",
                            width: "100px",
                            formatter: (cell, row) => {
                              return _(
                                <>
                                  <Row>
                                    <Col md={3}>
                                      <DropdownItem
                                        className="edit-item-btn"
                                        onClick={() => editPop(cell, row)}
                                      >
                                        <FaEdit size={"15"} />
                                      </DropdownItem>
                                    </Col>
                                    <Col md={3}>
                                      <DropdownItem
                                        onClick={() => deletPop(cell, row)}
                                      >
                                        {" "}
                                        <MdDelete size={"15"} />
                                      </DropdownItem>
                                    </Col>
                                  </Row>
                                </>
                              );
                            },
                          },
                        ]}
                        search={true}
                        pagination={{ enabled: true, limit: 10 }}
                      /> */}
                  </div>

                  {/* end of container */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <div
              id="divcontents"
              style={{ marginLeft: "60px", display: "none" }}
            >
              <div className="maincontainer">
                <div className="itemstable" style={{ marginTop: "50px" }}>
                  <table
                    id="employeesExportCSV"
                    className="table table-striped table-bordered table-hover"
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      fontSize: "15px",
                      position: "relative",
                    }}
                  >
                    <thead
                      className="list form-check-all"
                      id="THeader"
                      style={{ backgroundColor: "black" }}
                    >
                      <tr id="Testt2">
                        <th>Full Name</th>
                        <th>Phone</th>
                        {/* <th>Email</th> */}
                        <th>Department</th>
                        <th>Title</th>
                        <th>Salary</th>
                        <th>Bank Account</th>
                        <th>Hire Date</th>
                      </tr>
                    </thead>
                    <tbody className="list form-check-all">
                      {getEmployee.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td className="Customer" id="mytable">
                              {data.empName}
                            </td>
                            <td
                              style={{ fontSize: "15px" }}
                              className="Customer"
                            >
                              {data.mobile}
                            </td>
                            {/* <td className="Customer">{data.empEmail}</td> */}
                            <td className="Customer">{data.departmentName}</td>
                            <td className="Customer">{data.titleName}</td>
                            <td className="Customer">{data.baseSalary}</td>
                            <td className="Customer">{data.bankAccount}</td>
                            <td className="Customer">
                              {""}{" "}
                              {moment(data.hireDate).utc().format("DD-MM-YYYY")}{" "}
                              { }{" "}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Row>
          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Employee Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        {/* <ModalHeader className="bg-light p-3">
                    Add New Employee
                    <Button
                        type="button"
                        onClick={() => {
                            setmodal_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </ModalHeader> */}
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">Add New Employee</h5>

          <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Employee Name</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <input
                    type="text"
                    id="Purchasesid-field"
                    className="form-control"
                    placeholder="Enter Employee Name"
                    required
                    onChange={(e) => setEmployeeName(e.target.value)}
                    value={employeeName}
                  />
                </div>
              </Col>
              <Col md={6}>
                <label>Phone</label>
                <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                <div className="mb-3">
                  <input
                    type="number"
                    id="empPhone"
                    className="form-control"
                    placeholder="Enter Phone"
                    required
                    // onChange={(e) =>
                    //     setEmployeePhone(e.target.value)}

                    // onChange={(e) => {
                    //     setEmployeePhone(e.target.value);
                    //     // if (e.target.value.length > 9) {
                    //     //     setIsError(true);
                    //     // }
                    // }}
                    onChange={handleChangePhone}
                    value={employeePhone}
                  ></input>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>Email</label>
                  
                  <div className="mb-3">
                    <input
                      type="text"
                      id="Employee-field"
                      className="form-control"
                      placeholder="Enter Email"
                      onChange={(e) => setEmployeeEmail(e.target.value)}
                      value={employeeEmail}
                    ></input>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>Addresss</label>
                  <div className="mb-3">
                    <input
                      type="text"
                      id="Employee-field"
                      className="form-control"
                      placeholder="Enter Address"
                      onChange={(e) => setempAddress(e.target.value)}
                      value={empAddress}
                    ></input>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label>Department</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <Select
                    // className="bg-white"
                    options={DepartmentsArr}
                    onChange={handleTypeSelect}
                    value={DepartmentsArr.filter(function (option) {
                      return option.value === EmployeeDepartment;
                    })}
                    defaultValue={{
                      label: "Select Department",
                      value: EmployeeDepartment,
                    }}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        // text: "orangered",
                        // primary25: "hotpink",
                        // primary: "black",
                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>Shift</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  {/* <Select
                      options={shiftArr}
                      onChange={handleShiftSelect}
                      value={shiftArr?.filter(option => employeeShift?.split(',')?.includes(option.value))}
                      isMulti // Enable multi-selection
                      required
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                        },
                      })}
                    /> */}
              <Select
                  options={shiftArr}
                  onChange={handleShiftSelect}
                  value={shiftArr.filter(option => employeeShift.includes(option.value))}
                  isMulti
                  required
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                    },
                  })}
                />
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <label>Employee Title</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>

                  <Select
                    // className="bg-white"
                    options={TitlesArr}
                    onChange={handleTypeSelectTitle}
                    value={TitlesArr.filter(function (option) {
                      return option.value === titleId;
                    })}
                    defaultValue={{ label: "Select Title", value: titleId }}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        // text: "orangered",
                        // primary25: "hotpink",
                        // primary: "black",
                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <div className="form-check form-switch form-switch-lg mb-3">
                    <Input
                      style={{ marginTop: "9%" }}
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="ISCash"
                      onChange={handleChange1}
                      // value={checked}
                      checked={checked1}
                    />
                    <span style={{ marginTop: "7%", marginLeft: "-18%" }}>
                      Is Resigned
                    </span>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <div>
                  <div className="form-check form-switch form-switch-lg mb-3">
                    <Input
                      style={{ marginTop: "9%" }}
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="ISCash"
                      onChange={handleChange}
                      // value={checked}
                      checked={checked}
                    />
                    <span style={{ marginTop: "7%", marginLeft: "-18%" }}>
                      Has Site
                    </span>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <label>Site</label>
                  {Disable ? (
                    <Select
                      // className="bg-white"
                      options={SitesArr}
                      disabled={Disable}
                      onChange={handleTypeSelectSite}
                      value={SitesArr.filter(function (option) {
                        return option.value === SiteId;
                      })}
                      defaultValue={{ label: "Select Site", value: SiteId }}
                      required
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          // text: "orangered",
                          // primary25: "hotpink",
                          // primary: "black",
                        },
                      })}
                    />
                  ) : (
                    <Select
                    
                      // className="bg-white"
                      options={SitesArr}
                      disabled={Disable}
                      onChange={handleTypeSelectSite}
                      value={SitesArr.filter(function (option) {
                        return option.value === SiteId;
                      })}
                      components={{
                        Menu: () => null,
                        MenuList: () => null,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      defaultValue={{ label: "Select Site", value: SiteId }}
                      required
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          // text: "orangered",
                          // primary25: "hotpink",
                          // primary: "black",
                        },
                      })}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label>BaseSalary</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <input
                    type="number"
                    id="Employee-field"
                    className="form-control"
                    placeholder="Enter Salary"
                    required
                    onChange={(e) => setBaseSalary(e.target.value)}
                    value={BaseSalary}
                  ></input>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>Bank Name</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <div className="mb-3">
                    <input
                      type="text"
                      id="Employee-field"
                      className="form-control"
                      placeholder="Enter Bank Name"
                      required
                      onChange={(e) => setBankName(e.target.value)}
                      value={bankName}
                    ></input>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <label>Bank Account</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <div className="mb-3">
                    <input
                      type="number"
                      id="Employee-field"
                      className="form-control"
                      placeholder="Enter Bank Account"
                      required
                      onChange={(e) => setBankAccount(e.target.value)}
                      value={bankAccount}
                    ></input>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => closing()}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isEditing ? "Update" : "Add"}
              </button>
            </div>
          </ModalFooter>
        </form>

        {/* =================================== */}
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={deleteEmp}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default EmployeeRegistration;
