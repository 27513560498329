import React, { useState, useEffect, useContext, useMemo } from "react";
import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
  Row,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
//import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";

const ManagePurchaseOrder = () => {
  const [yes, setYes] = useState(true);
  const [id, setId] = useState("");
  // const [no, setNo] = useState(false);

  // local states

  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const {
    fetchPurchase,
    purchases,
    fetchPurchaseOrder,

    purchaseOrders,
    removePurchase,
  } = useContext(LoginContex);
  useEffect(() => {
    fetchPurchase();
    fetchPurchaseOrder();
  }, []);
  //console.log(purchaseOrders[0]?.purchaseOrderDetails.length);

  document.title = "Manage Pending Orders";
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  //console.log(purchaseOrders)
  const columns = useMemo(
    () => [
      {
        Header: "supplierName",
        accessor: "supplierId.supplierName",
        filterable: false,
      },
      {
        Header: "referenceNo",
        accessor: "referenceNo",
        filterable: false,
      },
      {
        Header: "description",
        accessor: "description",
        filterable: false,
      },
      {
        Header: "total amount",
        accessor: "purchaseOrderAmount",
        filterable: false,
      },
      {
        Header: "total amount",
        accessor: "purchaseOrderDetails.length",
        filterable: false,
      },
      {
        Header: "date",
        accessor: "purchaseOrderDate",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      // {
      //   Header: "Type",
      //   // accessor: "purchase oder",
      //   default: "ll",
      //   filterable: false,
      // },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li
                className="list-inline-item edit btn btn-success"
                //title="Receive"
              >
                <Link
                  to={`/purchaseReceive/${cellProps.row.original._id}`}
                  className="text-white d-inline-block edit-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                  }}
                >
                  Receive
                </Link>
              </li>
              <li
                className="list-inline-item btn btn-primary text-white"
                //title="Remove"
              >
                <Link
                  to={`/view/purchaseOrder/${cellProps.row.original._id}`}
                  className="text-white d-inline-block remove-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                  }}
                >
                  View
                </Link>
              </li>
            </ul>
          );
        },
      },
    ]
    // [handleCustomerClick]
  );

  const deletePuchase = (data) => {
    setmodal_delete(true);
    setId(data._id);
  };

  const delPurchase = () => {
    removePurchase(id);
    setmodal_delete(false);
  };

  //updateees

  const dateFromDB = "2005-12-03T18:30:00.000Z";
  const formattedDate = moment(dateFromDB).utc().format("DD/MM/YYYY");

  //console.log("Date From DB:", dateFromDB);
  //=> 2005-12-03T18:30:00.000Z

  //console.log("Formatted Date:", formattedDate);

  const sortedOrders = [];
  for (let i = 0; i < purchaseOrders?.length; i++) {
    let singleArr = [
      purchaseOrders[i].purchaseDate,
      purchaseOrders[i].supplierId?.supplierName,
      purchaseOrders[i].referenceNo,
      purchaseOrders[i].description,
      purchaseOrders[i].purchaseOrderAmount,
      purchaseOrders[i].purchaseOrderDetails.length,
      purchaseOrders[i].type,
    ];

    sortedOrders.push(singleArr);
  }

  // //console.log(data?.purchaseOrderDetails);

  //console.log(purchaseOrders);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Manage Pending Orders" pageTitle="Purchase" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">
                            Manage Pending Orders
                          </h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Link to="/PurchasesOrder" className="text-white">
                            <Button
                              color="success"
                              className="add-btn me-1"
                              // onClick={() => tog_list()}
                              //  id="create-btn"
                            >
                              <i className=" ri-add-line align-bottom me-1"></i>{" "}
                              Purchases Order{" "}
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="StoreList">
                    <Row className="g-4 mb-3"></Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      {/* {purchaseOrders.length > 0 && (
                        <Grid
                          data={sortedOrders}
                          columns={[
                           

                            "Date",
                            "SupplierName",
                            "Ref",
                            "Description",
                            "Total Amount",
                            "Total Items",
                            "Type",
                            {
                              name: "Actions",
                              width: "100px",
                              formatter: (cell, row) => {
                                return _(
                                  <>
                                  
                                    <UncontrolledDropdown className="dropdown d-inline-block">
                                      <DropdownToggle
                                        className="btn btn-soft-secondary btn-sm"
                                        tag="button"
                                      >
                                        <i className="ri-more-fill align-middle"></i>
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-end">
                                        <Row>
                                          <DropdownItem
                                            className="edit-item-btn"
                                           
                                          >
                                            View
                                          </DropdownItem>

                                          <DropdownItem
                                            className="remove-item-btn"
                                          >
                                            Receive
                                          </DropdownItem>
                                        </Row>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </>
                                );
                              },
                            },
                           
                          ]}
                          search={true}
                          pagination={{ enabled: true, limit: 10 }}
                        />
                      )} */}
                      {/* <table
                        className="table align-middle table-nowrap"
                        id="StoreTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "5px" }}></th>

                            <th className="sort" data-sort="date">
                              Date
                            </th>
                            <th className="sort" data-sort="supplier">
                              Supplier
                            </th>
                            <th className="sort" data-sort="order">
                              Ref
                            </th>
                            <th className="sort" data-sort="description">
                              Description
                            </th>

                            <th className="sort" data-sort="TotalAmount">
                              Total Amount
                            </th>
                            <th className="sort" data-sort="description">
                              Total items
                            </th>
                            <th className="sort" data-sort="Type">
                              Type
                            </th>

                            <th className="sort" data-sort="Type">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {purchaseOrders?.map((data, index) => {
                            //console.log(data);
                            return (
                              <tr key={index}>
                                <th scope="row"></th>
                                <td className="id" style={{ display: "none" }}>
                                  <Link
                                    to="#"
                                    className="fw-medium link-primary"
                                  >
                                    #VZ2104
                                  </Link>
                                </td>
                                <td className="date">
                                  {" "}
                                  {moment(data.purchaseDate)
                                    .utc()
                                    .format("DD-MM-YYYY")}{" "}
                                </td>
                                <td className="supplier">
                                  {" "}
                                  {data.supplierId?.supplierName}
                                </td>
                                <td className="order"> {data.referenceNo}</td>
                                <td className="descriptions">
                                  {" "}
                                  {data.description}{" "}
                                </td>
                                <td className="TotalAmount">
                                  {" "}
                                  {data.purchaseOrderAmount}{" "}
                                </td>
                                <td className="TotalAmount">
                                  {data?.purchaseOrderDetails.length}{" "}
                                </td>

                                <td className="type"> purchase Order</td>
                                <td className="type">
                                  <div className="d-flex gap-2">
                                    <div className="edit">
                                      {" "}
                                      <Link
                                        style={{ color: "white" }}
                                        to={`/purchaseReceive/${data._id}`}
                                      >
                                        <Button size="sm"> Receive</Button>{" "}
                                      </Link>{" "}
                                      <Link
                                        style={{ color: "white" }}
                                        to={`/view/purchaseOrder/${data._id}`}
                                      >
                                        <Button
                                          className="btn btn-success"
                                          size="sm"
                                        >
                                          {" "}
                                          View
                                        </Button>{" "}
                                      </Link>{" "}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table> */}
                    </div>
                  </div>

                  {purchaseOrders.length > 0 && (
                    <TableContainer
                      columns={columns}
                      data={purchaseOrders || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      // handleCustomerClick={handleCustomerClicks}
                      // isCustomerFilter={true}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        <ModalHeader className="bg-light p-3 pull right">
          Add New Store
          <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close float-right"
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <form>
          <ModalBody></ModalBody>
          <ModalFooter></ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => delPurchase()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ManagePurchaseOrder;
