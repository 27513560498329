import { Grid, _ } from "gridjs-react";
import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";
import DataTable from "react-data-table-component";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer } from "react-toastify";

const CloseOpenedCard = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardPackage, setCardPackage] = useState("");
  const [customer, setCustomer] = useState();
  const [billingCycle, setBillingCycle] = useState("");
  const [openDate, setOpenDate] = useState("");
  const [closeDate, setCloseDate] = useState("");
  const [usingDays, setUsingDays] = useState("");
  const [amount, setAmount] = useState("");
  const [Discription, setdescription] = useState("");
  const [recievertype, setrecievertype] = useState("");
  const [channelpackage, setchannelpackage] = useState("");
  const { fetchClosedCards, closedCards } = useContext(LoginContex);

  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = closedCards.filter(
    (item) =>
      item.cardNumber &&
      item.cardNumber.toLowerCase().includes(filterText.toLowerCase())
  );
  //console.log(filteredItems);
  //console.log(filterText);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    fetchClosedCards();
  }, []);
  //console.log(closedCards);

  document.title = "Close Card ";

  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> Name</h6>,
      selector: (row) => row.customerId?.customerName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Card Number</h6>,
      selector: (row) => row.cardNumber,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>User Code</h6>,
      selector: (row) => row.customerId?.customerUser,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Username</h6>,
      selector: (row) => row?.userId?.username
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Description</h6>,
      selector: (row) => row.description,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>No Of Months/Days</h6>,
      selector: (row) => `${row.noOfMonthDay}`
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Open Date</h6>,
      selector: (row) => moment(row.startDate).utc().format("DD-MM-YYYY"),
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Close Date</h6>,
      selector: (row) => moment(row.endDate).utc().format("DD-MM-YYYY"),
    },

    {
      /*end*/
    },
  ];
  //console.log(closedCards);

  const CvsData = [];
  for (let i = 0; i < closedCards?.length; i++) {
    //console.log(closedCards[i]._id);
if(closedCards[i]?.customerId?.customerName){
    var dropdownList = {
      customer: closedCards[i]?.customerId?.customerName,
      phone: ` ${closedCards[i]?.customerId?.customerPhone}`,
      address: ` ${closedCards[i]?.customerId?.customerAddress}`,
      cardNumber: ` ${closedCards[i]?.cardNumber}`,
      noOfMonthDay: ` ${closedCards[i]?.noOfMonthDay}`,

      openDate: ` ${closedCards[i]?.startDate}`,
      closeDate: ` ${closedCards[i]?.endDate}`,
      createdAt: ` ${closedCards[i]?.createdAt}`,
    };
  }
    CvsData.push(dropdownList);
  }

  //console.log(CvsData);
  function convertArrayOfObjectsToCSV(array) {
    let result;
  
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData?.[0] || []);
  
    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
  
        result += item[key];
  
        ctr++;
      });
      result += lineDelimiter;
    });
  
    return result;
  }
  
  // ** Downloads CSV
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;
  
    const filename = "export.csv";
  
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
  
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const editPop = (data) => {
    //console.log(data);
    setmodal_list(true);
    setCardNumber(data.card_no);
    setBillingCycle(data.billingCycle);
    setCardType(data.card_type);
    setOpenDate(data.open_date);
    setCloseDate(data.closeDate);
    setAmount(data.amount);
    setUsingDays(data.using_days);
    setCustomer(data.customer_name);
    setCardPackage(data.card_package);
  };

  const handlerSubmit = (e) => {
    const data = {
      cardNumber,
      recievertype,
      channelpackage,
      customer,
      billingCycle,
      openDate,
      closeDate,
      usingDays,
      amount,
      Discription,
    };
    //console.log(data);
  };

  const date = new Date();
  const futureDate = date.getDate() + 3;
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  //console.log(defaultValue);

  const casee = moment(openDate).format("DD/MM/YYYY");
  //console.log(casee);
  const con = closedCards;

  // const sortCard = [];
  // for (let i = 0; i < closedCards?.length; i++) {
  //   let openDate = closedCards[i].openDate.split("T");
  //   const openDatee = moment(openDate[0]).format("DD-MM-YYYY");
  //   let closeDate = closedCards[i].endDate.split("T");
  //   const closeDateee = moment(closeDate[0]).format("DD-MM-YYYY");
  //   let singleArr = [
  //     closedCards[i].customerId?.customerName,
  //     closedCards[i].cardNumber,
  //     closedCards[i].receivertype,
  //     closedCards[i].description,
  //     closedCards[i].billingCycle == "month"
  //       ? `${
  //           closedCards[i].noOfMonthDay > 1
  //             ? closedCards[i].noOfMonthDay + " months"
  //             : closedCards[i].noOfMonthDay + " month"
  //         }  `
  //       : `${
  //           closedCards[i].noOfMonthDay > 1
  //             ? closedCards[i].noOfMonthDay + " days"
  //             : closedCards[i].noOfMonthDay + " day"
  //         } `,
  //     openDatee,
  //     closeDateee,
  //     closedCards[i].billAmount,
  //   ];

  //   sortCard.push(singleArr);
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Close Card " pageTitle="Billing" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <Row className="g-4 mb-3">
                            <Col className="col-sm-auto">
                              <div>
                                <div>
                                  <h4 className="card-title mb-0">Open Card</h4>
                                </div>
                              </div>
                            </Col>
                            <Col className="col-sm">
                              <div className="d-flex justify-content-sm-end">
                                <div>
                                  <Button
                                    color="success"
                                    className="add-btn me-1 mx-2"
                                    onClick={() => downloadCSV(CvsData)}
                                    id="create-btn"
                                  >
                                    <i
                                      className="fa fa-print mx-2"
                                      aria-hidden="true"
                                    ></i>
                                    Download Excell
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm"></Col>
                  </Row> */}

                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">Close Card</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          
                          <Button
                            color="success"
                            className="add-btn me-1 mx-2"
                            onClick={() => downloadCSV(CvsData)}
                            id="create-btn"
                          >
                            <i
                              className="fa fa-print mx-2"
                              aria-hidden="true"
                            ></i>
                            Download Excell
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="g-4 mb-3"></Row>
                    {/* {closedCards.length > 0 && ( */}
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        persistTableHead
                        // sortFunction={customSort}
                      />
                    {/* )} */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Open Closed Card Distribution From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Close Card</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Card Number </label>
                  <input
                    type="number"
                    id="card_number-field"
                    className="form-control"
                    placeholder="Enter Card Number"
                    required
                    disabled
                    value={cardNumber}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Reciever Type</label>
                  <input
                    type="text"
                    id="card_number-field"
                    className="form-control"
                    placeholder="Enter Card Number"
                    required
                    disabled
                    value={cardType}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Channel Package</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Card Number"
                    disabled
                    value={cardPackage}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Customer</label>
                  <input
                    type="text"
                    id="card_number-field"
                    className="form-control"
                    placeholder="Enter Card Number"
                    required
                    disabled
                    value={customer}
                  />
                </div>
              </Col>

              <Col md={4}>
                <div className="mb-3">
                  <label>Billing Cycle</label>
                  <select
                    className="form-select "
                    id="itemtype"
                    // onChange={(e) => setItemType(e.target.value)}
                    value={billingCycle}
                    disabled
                  >
                    {/* <option selected>Choose...</option> */}
                    <option value={billingCycle}>{billingCycle}</option>
                    {/* <option value="3 Month">3 Mothly</option>
                    <option value="6 Month">6 Mounth</option> */}
                  </select>
                </div>
              </Col>

              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">Open Date</label>
                  {/* <input
                    type="date"
                    // id="number_of_channels-field"
                    className="form-control"
                    // disabled
                    defaultValue={"11/11/2011"}
                  /> */}
                  <Flatpickr
                    className="form-control"
                    disabled
                    placeholder="DD/MM/YYYY"
                    options={{
                      dateFormat: "d/m/Y",
                    }}
                    defaultValue={date.toLocaleDateString("en-US")}
                    value={date.toLocaleDateString("en-US")}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">Close Date</label>
                  <Flatpickr
                    className="form-control"
                    disabled
                    placeholder="DD/MM/YYYY"
                    options={{
                      dateFormat: "d/m/Y",
                    }}
                    value={closeDate}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Using Days</label>
                  <input
                    type="text"
                    id="amount-field"
                    className="form-control"
                    placeholder="Enter  Using Days"
                    required
                    disabled
                    value={usingDays}
                  ></input>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Amount</label>
                  <input
                    type="number"
                    id="amount-field"
                    className="form-control"
                    placeholder="Enter Amount"
                    required
                    disabled
                    value={amount}
                  ></input>
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <label className="form-label">
                    Reason <span className="text-danger">*</span>
                  </label>
                  {/* <input
                    type="text"
                    id="amount-field"
                    className="form-control"
                    placeholder="Enter reason"
                    required
                    onChange={(e) => setdescription(e.target.value)}
                  ></input> */}
                  <textarea
                    className="form-control"
                    id="Description-field"
                    rows="3"
                    required
                    placeholder="Enter reason"
                    onChange={(e) => setdescription(e.target.value)}
                    // onChange={(e) => setDesc(e.target.value)}
                    // value={description}
                  ></textarea>
                </div>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-success"
                id="edit-btn"
                onClick={handlerSubmit}
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CloseOpenedCard;
