import React, { useState, useEffect, useContext, useMemo } from "react";

import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import moment from "moment";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
const CloseReport = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardPackage, setCardPackage] = useState("");
  const [customer, setCustomer] = useState();
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [openDate, setOpenDate] = useState("");
  const [closeDate, setCloseDate] = useState("");
  const [usingDays, setUsingDays] = useState("");
  const [amount, setAmount] = useState(8);
  const [Discription, setdescription] = useState("");
  const { openAnalogCardRegister, customers } = useContext(LoginContex);
  const [type, setTyp] = useState("text");
  const [customerInfo, setCustomerInfo] = useState();
  const [customerId, setCustomerId] = useState();
  const [Custom, setCustom] = useState("text");
  const [channelId, setChanell] = useState();
  const [cycle, setCycle] = useState("");
  const [numberOfDays, setNumberOfDays] = useState();
  const [numberOfMonths, setNumberOfMonths] = useState();
  const [billAmount, setBillAmount] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [helper, setHelper] = useState(false);

  const [modal_delete, setmodal_delete] = useState(false);

  document.title = "Close Card Report";

  const {
    fetchOpenCards,
    OpenCards,
    cardList,
    channelPackage,
    fetchChanelPackages,
    closeAnalogCard,
    FetchOpenCardsReport,
    openCardsReport,
    FetchAllOpenCardsReport,
    allopenCardReport,
    FetchSummaryCableReport,
    summaryCableReport,
    FetchSummaryCloseReport,
    summaryCloseReport,
  } = useContext(LoginContex);

  useEffect(() => {
    // FetchAllOpenCardsReport();
    // FetchSummaryCableReport();
    FetchSummaryCloseReport();
  }, []);

  //console.log(summaryCloseReport);

  //console.log(openDate);
  //console.log(start);
  //console.log(end);
  //console.log(OpenCards);
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).utc().format("DD-MM-YYYY");
    return date1;
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "customerId.customerName",
        filterable: false,
      },
      {
        Header: "Card Number",
        accessor: "cardNumber",
        filterable: false,
      },
      {
        Header: "Receiver Type",
        accessor: "receivertype",
        filterable: false,
      },
      {
        Header: "Billing Cycle",
        accessor: "billingCycle",
        filterable: false,
      },
      {
        Header: " No Of Months/Days",
        accessor: "noOfMonthDay",
        filterable: false,
      },
      {
        Header: "Open Date",
        accessor: "openDate",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },

      {
        Header: "Close Date",
        accessor: "endDate",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
    ]
    // [handleCustomerClick]
  );
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handlerSubmitOpen = (e) => {
    //console.log(e);
    e.preventDefault();
    if (
      !customerInfo?.cardNumber ||
      !customerInfo?.receiverType ||
      !customerInfo?.value ||
      !customerInfo?.value ||
      !channelId ||
      !cycle ||
      !openDate ||
      !billAmount
    ) {
      showToastMessage("please provide required values");
      return;
    }
    //console.log(numberOfMonths);
    //console.log(closeDate);
    //console.log();
    //console.log("first");
    const data = {
      cardNumber: customerInfo?.cardNumber,
      receivertype: customerInfo?.receiverType,
      customerId: customerInfo?.value,
      noOfMonthDay: cycle == "month" ? numberOfMonths : numberOfDays,
      endDate:
        cycle === "month"
          ? addMonths(Number(numberOfMonths), dateee)
          : addDays(Number(numberOfDays)),
      startDate: openDate,

      openDate: openDate,
      billingCycle: cycle,
      billAmount: billAmount,
      channelPackageId: channelId,
    };
    e.target.reset();
    openAnalogCardRegister(data);
    setmodal_list(false);
    //console.log(data);

    // showToastMessage("succefully opened card");
  };

  const editPop = (data) => {
    //console.log(data);
    setmodal_list(true);
    setCardNumber(data.cardNumber);
    setBillingCycle(data.billingCycle);
    setCardType(data.receiverType);
    setOpenDate(data.open_date);
    // setCloseDate(data.closeDate);
    setCustomer(data.customerId.customerName);
    setCardPackage(data.channel_package);
  };

  const setbilling = (e) => {
    setBillingCycle(e);

    //console.log(e);
    let type = e;

    if (type === "Custom") {
      // document.getElementById("customfield").style.display = "block";
      // document.getElementById("billamount").setAttribute("md={4}");
    } else {
      // document.getElementById("customfield").style.display = "none";
      // document.getElementById("billamount").setAttribute("md={8}");
    }
  };

  let neeew = "11-11-2022";
  if (billingCycle === "monthly") {
    //console.log(openDate);
    const test = moment(openDate).utc().format("MM/DD/YYYY");
    //console.log(test);
    neeew = new Date(test);
    neeew.setDate(neeew.getDate() + 30);

    //console.log(neeew);
  }

  const customizeDate = (month = 0, dayy = 0) => {
    //console.log(month);
    const test = moment(openDate).utc().format("MM/DD/YYYY");
    const yep = new Date(test);
    const day = yep.getDate() + 1 + Number(dayy);
    const moonth = `${yep.setMonth(yep.getMonth() + Number(month))}`;
    const year = yep.getFullYear();

    //console.log(day, moonth, year);

    const foor = day + "-" + moonth + "-" + year;
    const f = moment(foor).format("D/MM/YYYY");

    setCloseDate(f);
    // //console.log(yep.getMonth());
  };

  //console.log(openDate);

  function dateWithMonthsDelay(months) {
    //console.log(months);
    const date = new Date(openDate);
    date.setMonth(date.getMonth() + months);
    // setCloseDate(date);

    return date;
  }

  const filterThroughDate = () => {
    setHelper(true);
    const startt = moment(start).format("YYYY-MM-DD");
    const enddd = moment(end).format("YYYY-MM-DD");
    FetchSummaryCloseReport(startt, enddd);
  };

  const clearFilter = () => {
    setHelper(false);
  };
  //console.log(closeDate);
  const districtArr = [];

  // //console.log();
  // //console.log(neeew);
  //console.log("type is" + typeof neeew);

  const chan = () => {
    setTyp("date");
  };
  const CustomersArr = [];

  for (let i = 0; i < customers.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: customers[i]._id,
      label: ` ${customers[i].customerName}`,
    };

    CustomersArr.push(dropdownList);
  }


  const customerCards = [];

  for (let i = 0; i < cardList.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: cardList?.[i]?.dataCustomers?._id,
      cardNumber: cardList?.[i]?.cardNumber,
      receiverType: cardList?.[i]?.datareceivers?.itemType,
      label: cardList?.[i]?.dataCustomers?.customerName,
    };

    customerCards.push(dropdownList);
  }

  //console.log(cardList);

  function addMonths(numOfMonths, date = new Date()) {
    //console.log(numOfMonths);
    date.setMonth(date.getMonth() + numOfMonths);
    setCloseDate(date);

    return date;
  }

  function addDays(days) {
    var result = new Date(openDate);
    result.setDate(result.getDate() + days);
    setCloseDate(result);
    return result;
  }

  // //console.log(closeDate);
  //console.log(closeDate);
  // 👇️ Add 2 months to current Date
  // const result = addMonths(2);

  // 👇️ Add months to another date
  const dateee = new Date(openDate);

  //console.log(info);
  const CvsData = [];
  for (let i = 0; i < summaryCloseReport?.length; i++) {
    // //console.log(summaryCloseReport[i]._id);
  
    // if(summaryCloseReport?.[i]?.customerId?.customerName){
      var dropdownList = {
        Name: summaryCloseReport?.[i]?.customerId?.customerName,
        cardNumber: ` ${summaryCloseReport?.[i]?.cardNumber}`,
        receivertype: ` ${summaryCloseReport?.[i]?.receivertype}`,
        billingCycle: ` ${summaryCloseReport?.[i]?.billingCycle}`,
        noOfMonthDay: ` ${summaryCloseReport?.[i]?.noOfMonthDay}`,
        openDate: ` ${moment(summaryCloseReport?.[i]?.openDate).utc().format("DD-MM-YYYY")}`,
        endDate: ` ${moment(summaryCloseReport?.[i]?.endDate).utc().format("DD-MM-YYYY")}`,
       
  
      };
    // }
   
  
    CvsData.push(dropdownList);
  }

  function convertArrayOfObjectsToCSV(array) {
    let result;
  
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData?.[0] || []);
  
    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
  
        result += item[key];
  
        ctr++;
      });
      result += lineDelimiter;
    });
  
    return result;
  }
  
  // ** Downloads CSV
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;
  
    const filename = "export.csv";
  
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
  
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Close Card Report " pageTitle="Report" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-autoo">
                      <div>
                        <ToastContainer />
                        <div>
                        <h4 className="card-title mb-0">Close card Report</h4>
                          <div className="form-label" style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end"}}>
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={()=>{
                              downloadCSV(CvsData)
                            }}
                            type="button"
                            //  id="create-btn"
                          > 
                            <i className="fa fa-print mx-2" aria-hidden="true"> </i>  
                            Download Excel
                          </Button>
                        </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="mb-5">
                      {/* <Col md={3}>
                        <div className="mb-3">
                          <label>Supplier</label>
                          <Select
                            options={suppliersArr}
                            onChange={(choice) => setSupplierId(choice)}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                // text: "orangered",
                                // primary25: "hotpink",
                                // primary: "black",
                              },
                            })}
                          />
                        </div>
                      </Col> */}
                      <Col md={3}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Start Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStart(date)}
                            value={start}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            End Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEnd(date)}
                            value={end}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={filterThroughDate}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Filter By Date{" "}
                          </Button>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={clearFilter}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Clear filter{" "}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <TableContainer
                      columns={columns}
                      data={summaryCloseReport}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      // handleCustomerClick={handleCustomerClicks}
                      // isCustomerFilter={true}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Open Closed Card Distribution From */}

      {/* Remove Modal */}
    </React.Fragment>
  );
};

export default CloseReport;
