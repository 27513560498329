import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link, useParams } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";

const UpdateManage = () => {
  ///local states

  const [todaydata, setTodayDate] = useState("");
  const [date, setDate] = useState("");

  const [amount, setAmount] = useState();
  const [additianalCost, setAdditianalCost] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [QtyAvailable, setQtyAvailable] = useState("");
  const [Qty, setQty] = useState("");
  const [modal_list, setmodal_list] = useState(false);
  const [yes, setYes] = useState(true);
  const [supplierId, setSupplierId] = useState("");
  const [storeId, setStoreId] = useState("");
  const [siteId, setSiteId] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [description, setdescription] = useState("");
  const [description2, setdescription2] = useState("");
  const params = useParams();
  const {
    suppliers,
    items,
    fetchItems,
    stores,
    fetchStores,
    sites,
    fetchSites,
    readProducts,
    products,
    registerPurchase,
    fetchId,
    manageSingle_id,
  } = useContext(LoginContex);

  const [serviceList, setServiceList] = useState([
    {
      itemId: "",
      quantity: "",
      unitPrice: "",
      amount: "",
      description: "",
    },
  ]);

  // const ti = manageSingle_id.
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  //   const testu = Object.assign(manageSingle_id.purchaseDetails);

  let yess = yes === "true";
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    const attroptions = {
      valueNames: [
        "name",
        "born",
        {
          data: ["id"],
        },
        {
          attr: "src",
          name: "image",
        },
        {
          attr: "href",
          name: "link",
        },
        {
          attr: "data-timestamp",
          name: "timestamp",
        },
      ],
    };

    // pagination list

    new List("pagination-list", {
      valueNames: ["pagi-list"],
      page: 3,
      pagination: true,
    });
  });
  useEffect(() => {
    fetchItems();
    fetchStores();
    fetchSites();
    readProducts();
    fetchId(params.id);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;
    setTodayDate(today);
  }, []);

  //console.log(items);

  ///date format
  //console.log(params.id);
  //console.log(manageSingle_id);
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;

  //console.log(date);

  document.title = "Purchase  ";

  const amounts = serviceList[0].Qty;
  // //console.log(amounts);

  serviceList.forEach((ca) => {
    for (const item in ca) {
      //console.log("h");
    }
  });
  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,

      {
        itemId: "",
        quantity: "",
        unitPrice: " ",
        amount: " ",
        description: "",
      },
    ]);
  };

  const test = manageSingle_id.purchaseDetails;
  //console.log(test);
  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };
  const purchaseAmount = serviceList.reduce((accu, curr) => {
    return accu + curr["amount"];
  }, 0);
  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    e.target.value = "";
    // //console.log(value);
    // list[index]["quantity"] = "";
    const list = [...serviceList];
    list[index][name] = value;
    list[index]["amount"] =
      serviceList[index]["quantity"] * serviceList[index]["unitPrice"];
    list[index]["purchaseAmount"] = purchaseAmount;
    setServiceList(list);
    // handleServiceAdd();
    if (e.target.name == "itemId") {
      handleServiceAdd();
    }
  };

  //console.log(serviceList);
  // setServiceList([...serviceList,])

  // //console.log(purchaseAmount);

  const totalAmount =
    purchaseAmount + Number(additianalCost) - Number(discount);

  //the big object

  const data = {
    purchaseDate: date,
    supplierId: supplierId,
    description: description,
    referenceNo: referenceNo,
    siteId: siteId,
    purchaseAmount: purchaseAmount,
    totalAmount: totalAmount,
    isCash: yess,
    additionalCostAcc: additianalCost,
    discount: discount,
    purchaseDetails: serviceList,
  };

  const handleSubmit = (e) => {
    registerPurchase(data);
    e.preventDefault();
    //console.log(data);
  };

  const PreSelected = items.filter(
    (item) => item._id == manageSingle_id.supplierId
  );
  //console.log(PreSelected);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Update purchase "
            pageTitle="Purchases Registration"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <h4 className="card-title mb-0">Add Purchases</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            // onClick={() => tog_list()}
                            //  id="create-btn"
                          >
                            <i className=" ri-pencil-line align-bottom me-1"></i>{" "}
                            <Link to="/ManagePurchase" className="text-white">
                              {" "}
                              Manage Purchase{" "}
                            </Link>
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <form onSubmit={handleSubmit}>
                      <ModalBody>
                        <div
                          className="mb-3"
                          id="modal-id"
                          style={{ display: "none" }}
                        >
                          <label htmlFor="id-field" className="form-label">
                            ID
                          </label>
                          <input
                            type="text"
                            id="id-field"
                            className="form-control"
                            placeholder="ID"
                            readOnly
                          />
                        </div>
                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Supplier</label>
                              <select
                                className="form-select "
                                id="itemtype"
                                onChange={(e) => setSupplierId(e.target.value)}
                                value={manageSingle_id.supplierId}
                              >
                                {items.map((item, index) => {
                                  return (
                                    <option key={index} value={item._id}>
                                      {item.supplierName}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div>
                              <Label
                                htmlFor="PurchasesDate"
                                className="form-label"
                              >
                                Purchase Date
                              </Label>
                              <Input
                                type="date"
                                class="form-control"
                                id="PurchasesDate"
                                onChange={(e) => setDate(e.target.value)}
                                value={manageSingle_id.purchaseDate}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Store</label>
                              <select
                                className="form-select "
                                id="itemtype"
                                value={manageSingle_id.supplierId}
                                onChange={(e) => setStoreId(e.target.value)}
                              >
                                {stores?.map((store, index) => {
                                  return (
                                    <option
                                      key={index}
                                      name="service"
                                      value={store._id}
                                    >
                                      {store.storeName}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label className="form-label">Site</label>
                              <select
                                className="form-select "
                                id="itemtype"
                                value={manageSingle_id.siteId}
                                onChange={(e) => setSiteId(e.target.value)}
                              >
                                {sites?.map((site, index) => {
                                  return (
                                    <option
                                      key={index}
                                      name="service"
                                      value={site._id}
                                    >
                                      {site.siteName}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <label className="form-label">Reference No</label>
                              <input
                                type="number"
                                id="referenceno-field"
                                className="form-control"
                                placeholder="Enter Reference No"
                                required
                                onChange={(e) => setReferenceNo(e.target.value)}
                                value={manageSingle_id.referenceNo}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label className="form-label">Description</label>
                              <textarea
                                className="form-control"
                                id="Description-field"
                                rows="1"
                                required
                                onChange={(e) => setdescription(e.target.value)}
                                value={manageSingle_id.description}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label className="form-label">
                                purchase Amount
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="disabledInput"
                                defaultValue={purchaseAmount}
                                value={manageSingle_id.purchaseAmount}
                                disabled
                              />
                            </div>
                          </Col>
                          <Row></Row>
                          <table
                            className="table align-middle table-nowrap"
                            id="purchase"
                          >
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col" style={{ width: "50px" }}></th> */}
                                <th className="sort" data-sort="item">
                                  Item Name
                                </th>
                                <th className="sort" data-sort="qty">
                                  Qty Avaliable
                                </th>
                                <th className="sort" data-sort="phone">
                                  Quantity
                                </th>
                                <th className="sort" data-sort="Date">
                                  Rate
                                </th>
                                <th className="sort" data-sort="amount">
                                  Amount
                                </th>
                                <th className="sort" data-sort="amount">
                                  discription
                                </th>
                                <th className="sort" data-sort="action">
                                  Remove
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {manageSingle_id.purchaseDetails?.map(
                                (service, index) => {
                                  //console.log(manageSingle_id.purchaseDetails);
                                  return (
                                    <tr key={index} className="">
                                      <td className="item">
                                        <div className="mb-3">
                                          <select
                                            className="form-select "
                                            id="itemtype"
                                            onChange={(e) =>
                                              handleServiceChange(e, index)
                                            }
                                            value={service.itemId}
                                            name="itemId"
                                          >
                                            {products.map((pr, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={pr._id}
                                                  selected
                                                >
                                                  {pr.itemName}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                      </td>
                                      <td className="qty">
                                        <div className="mb-3">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="disabledInput"
                                            defaultValue="5"
                                            disabled
                                            value={5}
                                          />
                                        </div>
                                      </td>
                                      <td className="phone">
                                        <div className="mb-3">
                                          <input
                                            type="number"
                                            id="CashAccount-field"
                                            className="form-control"
                                            placeholder="Enter quantity"
                                            required
                                            onChange={(e) =>
                                              handleServiceChange(e, index)
                                            }
                                            name="quantity"
                                            value={service.quantity}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="mb-3">
                                          <input
                                            type="number"
                                            id="CashAccount-field"
                                            className="form-control"
                                            placeholder="Enter Rate"
                                            required
                                            onChange={(e) =>
                                              handleServiceChange(e, index)
                                            }
                                            name="unitPrice"
                                            value={service.unitPrice}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="mb-3">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="disabledInput"
                                            defaultValue={service.Amount}
                                            value={service.amount}
                                            disabled
                                          />
                                        </div>
                                      </td>
                                      <td className="qty">
                                        <div className="mb-3">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            onChange={(e) =>
                                              handleServiceChange(e, index)
                                            }
                                            value={service.description}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        {/* {manageSingle_id.purchaseDetails.length >
                                        1 && ( */}
                                        <button
                                          type="submit"
                                          className="btn btn-danger mb-3"
                                          id="add-btn"
                                          onClick={() =>
                                            handleServiceRemove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                        {/* )} */}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                              <tr className="">
                                <td className="item">
                                  <div className="mb-3">
                                    <select
                                      className="form-select "
                                      id="itemtype"
                                      onChange={(e) => handleServiceChange(e)}
                                      //   value={service.itemId}
                                      name="itemId"
                                    >
                                      {products.map((pr, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={pr._id}
                                            selected
                                          >
                                            {pr.itemName}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </td>
                                <td className="qty">
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="disabledInput"
                                      defaultValue="5"
                                      disabled
                                      value={5}
                                    />
                                  </div>
                                </td>
                                <td className="phone">
                                  <div className="mb-3">
                                    <input
                                      type="number"
                                      id="CashAccount-field"
                                      className="form-control"
                                      placeholder="Enter quantity"
                                      required
                                      onChange={(e) => handleServiceChange(e)}
                                      name="quantity"
                                      //   value={service.quantity}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="mb-3">
                                    <input
                                      type="number"
                                      id="CashAccount-field"
                                      className="form-control"
                                      placeholder="Enter Rate"
                                      required
                                      onChange={(e) => handleServiceChange(e)}
                                      name="unitPrice"
                                      //   value={service.unitPrice}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="disabledInput"
                                      //   defaultValue={service.Amount}
                                      //   value={service.amount}
                                      disabled
                                    />
                                  </div>
                                </td>
                                <td className="qty">
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) => handleServiceChange(e)}
                                      //   value={service.description}
                                    />
                                  </div>
                                </td>
                                <td>
                                  {/* {manageSingle_id.purchaseDetails.length >
                                        1 && ( */}
                                  <button
                                    type="submit"
                                    className="btn btn-danger mb-3"
                                    id="add-btn"
                                    onClick={() => handleServiceRemove("")}
                                  >
                                    Remove
                                  </button>
                                  {/* )} */}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <Col md={2}>
                            {/* <div className="mb-3">
                              <Label
                                className="form-check-label"
                                for="Yes_is_Cash"
                              >
                                Is Cash
                              </Label>
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="Yes_is_Cash"
                                  id="Yes_is_Cash"
                                />
                              </div>
                            </div> */}

                            <div>
                              <label className="form-label">Is Cash</label>
                              <div className="mb-3">
                                <Input
                                  className="form-control-input"
                                  type="radio"
                                  name="prim"
                                  id="is_yes"
                                  value={true}
                                  defaultChecked
                                  onChange={(e) => setYes(e.target.value)}
                                />
                                <Label className="form-check-label">Yes</Label>
                              </div>
                            </div>

                            <div className="mb-3">
                              <Input
                                className="form-check-input"
                                type="radio"
                                name="prim"
                                id="is_no"
                                value={false}
                                onChange={(e) => setYes(e.target.value)}
                              />
                              <Label className="form-check-label">No</Label>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="mb-3">
                              <label className="form-label">
                                Additional Cost
                              </label>
                              <input
                                type="number"
                                id="AdditionalCostAcc-field"
                                className="form-control"
                                placeholder="Enter Additional Cost Acc"
                                required
                                onChange={(e) =>
                                  setAdditianalCost(e.target.value)
                                }
                              />
                            </div>
                          </Col>

                          <Col md={2}>
                            <div className="mb-3">
                              <label className="form-label">Discount</label>
                              <input
                                type="number"
                                id="totalamount-field"
                                className="form-control"
                                placeholder="Enter Discount"
                                required
                                onChange={(e) => setDiscount(e.target.value)}
                              />
                            </div>
                          </Col>

                          <Col md={2}>
                            <div className="mb-3">
                              <label className="form-label">Total amount</label>
                              <input
                                type="number"
                                className="form-control"
                                id="disabledInput"
                                defaultValue={totalAmount}
                                value={totalAmount}
                                disabled
                              />
                            </div>
                          </Col>
                          <Row className="mt-5"></Row>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-success"
                            id="add-btn"
                          >
                            Save
                          </button>
                        </div>
                      </ModalFooter>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Purchase Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        {/* =================================== */}
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateManage;
