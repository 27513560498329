import React, { useState, useEffect, useContext, useMemo } from "react";
import Select from "react-select";
import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import DataTable from "react-data-table-component";

const SendSmsNotifications = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [modal_list2, setmodal_lis2] = useState(false);
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState("");





  const [contents, setContent] = useState("");
  const [checked, setChecked] = useState(false);
  const [custType, setType] = useState("");
  const tog_list = () => {
    setmodal_list(!modal_list)
  };
  const tog_list2 = () => {
    setmodal_lis2(!modal_list2)
  };

  const [modal_delete, setmodal_delete] = useState(false);

  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const handleChange = () => {
    setChecked(!checked);
  };
  document.title = "SEND   SMS";
  //console.log(contents);
  const {
    closeAnalogCard,

    FetchExpireCards,
    expireCards,
    isNewCableLoading,
    User,
    sendSmsTocustomers,
    isSending,
    FetchCurrentExpireCards,
    currentExpiredCards,fetchAllAnalogExpired,analogAllExpired,fetchAllAnalogThreeDaysExpired,analogThreeRemaingDays,sendSmsTo3daysRemaingcustomers,customersNotification,getBothCustomersByType,sendSmsNotificantionCustomers,resendSmsToCustomers
  } = useContext(LoginContex);

  useEffect(() => {
 
    // fetchAllAnalogExpired()
    // fetchAllAnalogThreeDaysExpired()
  
    // getBothCustomersByType(custType)
  }, [custType]);

  //console.log(custType);
  const handleTypeChange = (e)=>{
    setType(e.target.value)
    getBothCustomersByType(e.target.value)
  }
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems =customersNotification &&   customersNotification
  ?.filter(
    (item) =>
      (item?.customerName &&
        item?.customerName?.toLowerCase().includes(filterText.toLowerCase()) &&
        item.customerName?.toLowerCase().includes(filterText.toLowerCase()) &&
        item.customerPhone) ||
      item.customerPhone
        ?.toString()
        .toLowerCase()
        .includes(filterText.toLowerCase()) ||
      item.cardNumber
        ?.toString()
        .toLowerCase()
        .includes(filterText.toLowerCase())
  )
  //console.log(filteredItems);
  // //console.log(iptvCustomerRechargeData);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);
  let userId = User?.data?.user?._id || User?.user?._id;
  const columns = [
    // {
    //   name: <h6 style={{ fontWeight: "bold" }}>No</h6>,
    //   selector: (row, index) => index + 1,
    // },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Customer Name</h6>,
      selector: (row) =>checked ==false ? row?.customerName :row?.customer_info?.customerName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Phone</h6>,
      selector: (row) => checked ==false ? row?.customerPhone :row?.customer_info?.customerPhone,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> {custType == "Analog"? "cardNumber" : "SubscriptionId"}</h6>,
      selector: (row) => custType == "Analog"? row?.cardNumber : row?.subscriptionID,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> endDate</h6>,

      selector: (row) => moment(row.endDate).utc().format("DD-MM-YYYY"),
    },

    ,
  ];

  //   const columns = useMemo(
  //     () => [
  //       {
  //         Header: "Name",
  //         accessor: "customerName",
  //         filterable: false,
  //       },
  //       {
  //         Header: "Phone Number",
  //         accessor: "customerPhone",
  //         filterable: false,
  //       },

  //       {
  //         Header: " card Number",
  //         accessor: "cardNumber",
  //         filterable: false,
  //       },
  //       {
  //         Header: " status",
  //         accessor: "status",
  //         filterable: false,
  //       },

  //       {
  //         Header: "endDate",
  //         accessor: "endDate",
  //         filterable: false,
  //         Cell: (cell) => <>{handleValidDate(cell.value)}</>,
  //       },

  //     ]
  //     // [handleCustomerClick]
  //   );
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const closeCard = () => {
    const data = {
      id: info._id,
      description: status,
    };
    //console.log(data);
    closeAnalogCard(data);
    setStatus("");
    setmodal_delete(false);
  };

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };
  const handleSms = (e) => {
    e.preventDefault();
    if(custType == "" || custType == undefined){
      toast.error("please select customer type");
      return;
    }
    const data = {
          type:custType,
          content: contents,
          userId: userId,
          allCustomers: customersNotification,
        }
 //console.log(data)
 sendSmsNotificantionCustomers(data)
    // if(checked==false){
    //   const data = {
    //     message: contents,
    //     userId: userId,
    //     expiredAnalogCustomers: analogAllExpired,
    //   };
    //   //console.log(data);
    //   sendSmsTocustomers(data);
    //   setContent("");
    // }

    // if(checked== true){
    //   const data = {
    //     message: contents,
    //     userId: userId,
    //     expiringAnalogCustomers: analogThreeRemaingDays,
    //   };
    //   //console.log(data);
    //   sendSmsTo3daysRemaingcustomers(data);
    //   setContent("");
    // }
 
  };
  const handleRensend = (e) => {
    e.preventDefault();
    if(custType == "" || custType == undefined){
      toast.error("please select customer type");
      return;
    }
    const data = {
          type:custType,
          content: contents,
        }
 //console.log(data)
 resendSmsToCustomers(data)
    // if(checked==false){
    //   const data = {
    //     message: contents,
    //     userId: userId,
    //     expiredAnalogCustomers: analogAllExpired,
    //   };
    //   //console.log(data);
    //   sendSmsTocustomers(data);
    //   setContent("");
    // }

    // if(checked== true){
    //   const data = {
    //     message: contents,
    //     userId: userId,
    //     expiringAnalogCustomers: analogThreeRemaingDays,
    //   };
    //   //console.log(data);
    //   sendSmsTo3daysRemaingcustomers(data);
    //   setContent("");
    // }
 
  };

  const handleTagClick = (tag) => {
    setContent((prevContents) => prevContents + tag);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Send SMS To Analog and IPTV customers "
            pageTitle="Send SMS To Analog and IPTV Customers"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                   
                      </div>
                    </Col>
                    <Col md={2}>
                       
                       <Label htmlFor="PurchasesDate" className="form-label">
                         Type <span className="text-danger">*</span>
                       </Label>

                       <select
                         className="form-select "
                         id="itemtype"
                         onChange={handleTypeChange}
                       >
                           <option value=''>Select Option</option>
                         <option value='Analog'>Analog</option>
                         <option value='IPTV'>IPTV</option>
                       
                       </select>
                    
                     </Col>
                    <Col md={3}>
                       
                    <Button
                            color="success"
                            className="add-btn me-1 mx-2 mt-4"
                            id="create-btn"
                            onClick={() => tog_list()}
                          >
                          <i className="fas fa-sms fx-3 px-3"></i>  

                              Send SMS To ALL Customers
                          </Button>
                    
                     </Col>
                    <Col md={3}>
                       
                    <Button
                            color="success"
                            className="add-btn me-1 mx-2 mt-4"
                            id="create-btn"
                            onClick={() => tog_list2()}
                          >
                          <i className="fas fa-sms fx-3 px-3"></i>  

                             Re-Send SMS To Customers
                          </Button>
                    
                     </Col>
                  
                  </Row>
                </CardHeader>
                {/* {load()} */}
                <CardBody>
                  <div id="Purchasestable">
                    <Row className="g-4 mb-3"></Row>

                    {/* <TableContainer
                        columns={columns}
                        data={expireCards || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        loading={true} */}

                    {/* // handleCustomerClick={handleCustomerClicks}
                        // isCustomerFilter={true}
                      /> */}

                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      progressPending={isNewCableLoading}
                      progressComponent={<SpinnerBorder />}
                      persistTableHead
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Open Closed Card Distribution From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">
            {" "}
            SEND SMS NOTIFICATIONS TO ALL CUSTOMERS :{" "}
            <span className="text-danger">
              (* You are sending SMS to{" "}
              <span className="text-primary">
                {" "}
                 {customersNotification?.length} 
              </span>{" "}
              Customers
            </span>{" "}
            *)
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={handleSms}>
          <ModalBody>
           
            <Row>
              <Col>
              <div className="form-group">
                <label>Message</label>
                <Input
                disabled={isSending}
                  type="textarea"
                  style={{ backgroundColor: "white", color: "#333" }}
                  onChange={handleContentChange}
                  value={contents}
                />
              </div>
              <b>Available Tags:</b>
              <br/>
              {/* {(custType ==="Analog") &&
              <> <li className="btn btn-light" onClick={() => handleTagClick('[cardNumber]')}>
              [cardNumber]
            </li>
         
            </>
            } */}
              <li className="btn btn-light m-2" onClick={() => handleTagClick('[fullname]')}>
                 [fullname]
               </li>
              
               {/* <li className="btn btn-light" onClick={() => handleTagClick('[phoneNumber]')}>
                 [phoneNumber]
               </li>
          
                <li className="btn btn-light m-2" onClick={() => handleTagClick('[startDate]')}>
              [startDate]
            </li>
            <li className="btn btn-light" onClick={() => handleTagClick('[endDate]')}>
            [endDate]
          </li> */}
              </Col>
              {/* <Col>
              <div className="form-group">
                <label>Message</label>
                
              </div>
              </Col> */}
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>

              {isSending == true ? (
                <div
                  className="hstack gap-2 justify-content-end"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <button
                    disabled
                    type="button"
                    className="btn btn-success btn-load"
                  >
                    <span className="d-flex align-items-center">
                      <span
                        className="spinner-border flex-shrink-0"
                        role="status"
                      >
                        <span className="visually-hidden">Sending...</span>
                      </span>
                      <span className="flex-grow-1 ms-2 text-center">
                        Sending...
                      </span>
                    </span>
                  </button>
                </div>
              ) : (
                <div className="hstack gap-2 justify-content-end">
                  <Button type="submit" className="btn btn-success">
                    Save
                  </Button>
                </div>
              )}
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <Modal
        isOpen={modal_list2}
        toggle={() => {
          tog_list2();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">
           Re-send SMS to Customers
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_lis2(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={handleRensend}>
          <ModalBody>
           
            <Row>
              <Col>
              <div className="form-group">
                <label>Message</label>
                <Input
                disabled={isSending}
                  type="textarea"
                  style={{ backgroundColor: "white", color: "#333" }}
                  onChange={handleContentChange}
                  value={contents}
                />
              </div>
              <b>Available Tags:</b>
              <br/>
              {/* {(custType ==="Analog") &&
              <> <li className="btn btn-light" onClick={() => handleTagClick('[cardNumber]')}>
              [cardNumber]
            </li>
         
            </>
            } */}
              <li className="btn btn-light m-2" onClick={() => handleTagClick('[fullname]')}>
                 [fullname]
               </li>
              
               {/* <li className="btn btn-light" onClick={() => handleTagClick('[phoneNumber]')}>
                 [phoneNumber]
               </li>
          
                <li className="btn btn-light m-2" onClick={() => handleTagClick('[startDate]')}>
              [startDate]
            </li>
            <li className="btn btn-light" onClick={() => handleTagClick('[endDate]')}>
            [endDate]
          </li> */}
              </Col>
              {/* <Col>
              <div className="form-group">
                <label>Message</label>
                
              </div>
              </Col> */}
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_lis2(false)}
              >
                Close
              </button>

              {isSending == true ? (
                <div
                  className="hstack gap-2 justify-content-end"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <button
                    disabled
                    type="button"
                    className="btn btn-success btn-load"
                  >
                    <span className="d-flex align-items-center">
                      <span
                        className="spinner-border flex-shrink-0"
                        role="status"
                      >
                        <span className="visually-hidden">Sending...</span>
                      </span>
                      <span className="flex-grow-1 ms-2 text-center">
                        Sending...
                      </span>
                    </span>
                  </button>
                </div>
              ) : (
                <div className="hstack gap-2 justify-content-end">
                  <Button type="submit" className="btn btn-success">
                    Save
                  </Button>
                </div>
              )}
            </div>
          </ModalFooter>
        </form>
      </Modal>

    
    </React.Fragment>
  );
};

export default SendSmsNotifications;
const SpinnerBorder = () => {
  return <Spinner className="my-2 text-center" />;
};
