import React, { useContext, useState } from "react";
import logo from "./LogoTabaarak.png";
import moment from "moment";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { LoginContex } from "../../context/loginContext/LoginContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "3px",
  boxShadow: 24,
  p: 4,
  padding: "12px",
  border: "none",
  margin: "2rem",
};
let cartItems = [];
const Bill = ({
  items,
  receivers,
  paidbalance,
  totalAmount,
  discount,
  grandTotal,
  today,customerInfo
}) => {
  const [waiter, setWaiter] = useState("");
  const [table, setTable] = useState("");
  const [src, setSrc] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const { handlePrint, componentRef, getSingleCustomer, singleCustomer } =
    useContext(LoginContex);

  const handleOpen = () => setOpen(true);
  const handleClosee = () => setOpen(false);
  const tables = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  // const submitBill = (e) => {
  //     e.preventDefault()
  //     const billInfo = {
  //         waiter, table, totalPrice, cartItems, numberOfRes , user
  //     }
  //     // //console.log(billInfo)
  //     createBill(billInfo)

  //     handleClose()
  //     readBill()
  //     handleOpen()

  //     // navigate('/dashboard/bills')

  // }
  const handlePrintt = (e) => {
    e.preventDefault();
    // handlePrint()
    // setWaiter('')
    // setTable('')
    // removeFromCartitems()
    // handleClosee()
  };

  const mockData = [
    {
      name: "muscab",
      phone: 56465,
      salary: 200,
      date: "2023-3-2",
    },
    {
      name: "salmaan",
      phone: 56465,
      salary: 200,
      date: "2023-3-2",
    },
    {
      name: "usama",
      phone: 56465,
      salary: 200,
      date: "2023-3-2",
    },
  ];

  const date = new Date();
  //console.log(items);
  //console.log(receivers);
//console.log(customerInfo)
  return (
    <>
      <div className="page-contentc" ref={componentRef}>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <div id="divcontents mt-2">
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ marginLeft: "35%", size: "40px" }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p>Customer : {customerInfo?.customers}</p>
                    <p>Phone :  {customerInfo?.customerPhone}</p>
                    <p>Addres :  {customerInfo?.districtName}</p>
                  </div>
                  <div>Sales Date: {today}</div>
                </div>

                <CardBody>
                  <h5 className="my-1">Item Information</h5>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <table
                      id="items"
                      className="table table-striped table-bordered table-hover"
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        fontSize: "15px",
                        position: "relative",
                      }}
                    >
                      <thead className="list form-check-all">
                        <tr style={{ marginRight: "" }}>
                          {/* <th></th> */}
                          <th>Item Name</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Amount</th>

                          {/* <th></th> */}
                          {/* <Col md={8} style={{ marginRight: "5%" }}></Col> */}
                          {/* <th></th> */}
                        </tr>
                      </thead>
                      <tbody className="list form-check-all">
                        {items?.map((data, index) => {
                          return (
                            <tr key={index}>
                              {/* <td></td> */}

                              <td className="Customer">{data.itemLabel} </td>
                              <td className="Customer">{data?.quantity}</td>
                              <td className="Customer">{data?.quantity}</td>

                              <td className="Customer">{data?.amount}</td>

                              {/* <td></td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <h5 className="mt-2">Receiver Information</h5>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <table
                      id="items"
                      className="table table-striped table-bordered table-hover"
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        fontSize: "15px",
                        position: "relative",
                      }}
                    >
                      <thead className="list form-check-all">
                        <tr style={{ marginRight: "" }}>
                          {/* <th></th> */}
                          <th>Item Name</th>
                          <th>Receiver Number</th>
                          <th>CardNumber</th>
                          <th>Amount</th>

                          {/* <th></th> */}
                          {/* <Col md={8} style={{ marginRight: "5%" }}></Col> */}
                          {/* <th></th> */}
                        </tr>
                      </thead>
                      <tbody className="list form-check-all">
                        {receivers?.map((data, index) => {
                          return (
                            <tr key={index}>
                              {/* <td></td> */}

                              <td className="Customer">{data.recTyp} </td>
                              <td className="Customer">
                                {data?.receiverNumber}
                              </td>
                              <td className="Customer">
                                {data?.receiverCardNumber}
                              </td>

                              <td className="Customer">{data?.amount}</td>

                              {/* <td></td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="my-5">
                    <h5 className="font-weight-bold py-1">Reciept Details :</h5>
                    <h6 className="font-weight-bold py-1">
                      Grand Total : $ {grandTotal}
                    </h6>
                    {/* <h6 className="font-weight-bold py-1">
                      Discount : $ {discount}
                    </h6> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Bill;
