import React, { useState, useEffect, useContext, useMemo } from "react";
import Select from "react-select";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import TableContainer from "../../Components/Common/TableContainer";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Advance = () => {
  const [modal_Advance_list, setAdvance_list] = useState(false);
  // const [modal_Advance_list, setAdvance_list] = useState([
  //     {  },
  // ]);

  const tog_list = () => {
    setloanPeriod("");
    setmodal_list(!modal_list);
  };

  // const tog_list = () => {
  //     setmodal_list(!modal_list);
  //   };

  const {
    advanceRegister,
    advanceUpdate,
    advanceDelete,
    fetchEmployees,
    getEmployee,
    getAdvance,
    fetchAdvance,User
  } = useContext(LoginContex);

  // Employees
  const [empId, setEmployeeId] = useState();
  const [year, setYear] = useState(5);
  const [month, setMonth] = useState(5);
  const [loanAmount, setLoanAmount] = useState(5);
  const [perMonthDedection, setPerMonthDedection] = useState(5);
  const [loanPeriod, setloanPeriod] = useState(5);
  const [date, setDate] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [remarks, setRemarks] = useState();
  const [object_id, setObject] = useState();

  const [isUpdating, setIsUpdating] = useState(false);

  const [advanceData, setAdvanceData] = useState("");

  const [modal_list, setmodal_list] = useState(false);
  // const tog_list = () => {
  //     setmodal_list(!modal_list);
  // };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    fetchEmployees();
    fetchAdvance();
  }, []);

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  //   //console.log(getAdvance)

  document.title = "Advance  ";
  let userId = User?.data?.user?._id || User?.user?._id;
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "empName",
        filterable: false,
      },
      {
        Header: "Loan Amount",
        accessor: "loanAmount",
        filterable: false,
      },
      {
        Header: "Per Month dedaction",
        accessor: "perMonthDedection",
        filterable: false,
      },
      {
        Header: "Loan Period",
        accessor: "loanPeriod",
        filterable: false,
      },
      {
        Header: "Start Date ",
        accessor: "startDate",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      {
        Header: "End Date ",
        accessor: "endDate",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    updateAdvance(customerData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    deletPop(customerData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ]
    // [handleCustomerClick]
  );

  const handleServiceAdd = () => {
    setAdvance_list([...modal_Advance_list, {}]);
  };

  const handleServiceRemove = (index) => {
    const list = [...modal_Advance_list];
    list.splice(index, 1);
    setAdvance_list(list);
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...modal_Advance_list];
    list[index][name] = value;
    setAdvance_list(list);
    // handleServiceAdd();
  };

  const calculateLoanPeriod = () => {
    //console.log("Waaa Tijaabo From ahmed");
  };
  const test = (e, index) => {
    handleServiceChange(e, index);
    if (e.target.name === "item") {
      handleServiceAdd();
    }
  };

  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handlerSubmit = (e) => {
    if (!isUpdating) {
      e.preventDefault();

      if (!empId || !year || !month || !loanPeriod || !startDate || !endDate) {
        showToastMessage("Please fill the required Fields");
        return;
      }

      const data = {
        empId,
        year,
        month,
        loanAmount,
        perMonthDedection,
        loanPeriod,
        startDate,
        endDate,
        description: remarks,
      };

      advanceRegister(data);
      setEmployeeId("");
      setYear("");
      setMonth("");
      setLoanAmount("");
      setPerMonthDedection("");
      setloanPeriod("");
      setStartDate("");
      setEndDate("");
      setRemarks("");
      setmodal_list(false);
    } else {
      e.preventDefault();
      const data = {
        empId,
        year,
        month,
        loanAmount,
        perMonthDedection,
        loanPeriod,
        startDate,
        endDate,
        description: remarks,
        id: object_id,
      };

      if (isUpdating) {
        advanceUpdate(data);
        setEmployeeId("");
        setYear("");
        setMonth("");
        setLoanAmount("");
        setPerMonthDedection("");
        setloanPeriod("");
        setStartDate("");
        setEndDate("");
        setRemarks("");
        setIsUpdating(false);
        setmodal_list(false);
      }
    }
  };

  const calc = (value, index) => {
    let lnAmount = 0;
    let lnPeriod = 0;

    if (index == 2) {
      if (!loanAmount) {
        lnAmount = lnAmount;
      } else {
        lnAmount = loanAmount / value;
      }
      //console.log(lnPeriod);
      setPerMonthDedection(lnAmount);
    }

    if (index == 1) {
      if (!loanPeriod) {
        lnPeriod = lnPeriod;
      } else {
        lnPeriod = loanAmount / loanPeriod;
      }
      setPerMonthDedection(lnPeriod.toFixed(3));
    }
  };

  const deletPop = (data) => {
    setmodal_delete(true);
    setObject(data._id);
  };
  const deleteAdvance = () => {
    advanceDelete(object_id);
    setmodal_delete(false);
    // alert('Employee Removed Successfully');
  };
  const addModal = () => {
    tog_list();
    setEmployeeId("");
    setYear("");
    setMonth("");
    setLoanAmount("");
    setPerMonthDedection("");
    setStartDate("");
    setEndDate("");
    setRemarks("");
    setIsUpdating(false);
  };

  const CustomersArr = [];

  for (let i = 0; i < getEmployee.length; i++) {
    //console.log(getEmployee[i]._id);

    var dropdownList = {
      value: getEmployee[i]._id,
      label: ` ${getEmployee[i].empName}`,
    };

    CustomersArr.push(dropdownList);
  }

  const handleTypeSelect = (e) => {
    setEmployeeId(e.value);
  };

  const updateAdvance = (data, row) => {
    setIsUpdating(true);
    tog_list();
    setEmployeeId(data.empId);
    setYear(data.year);
    setMonth(data.month);
    setLoanAmount(data.loanAmount);
    setPerMonthDedection(data.perMonthDedection);
    setloanPeriod(data.loanPeriod);
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    setRemarks(data.description);
    setObject(data._id);
  };

  //console.log(getAdvance);
  const sortAdvance = [];
  for (let i = 0; i < getAdvance?.length; i++) {
    let singleArr = [
      getAdvance[i].empName,
      getAdvance[i].loanAmount,
      getAdvance[i].perMonthDedection,
      getAdvance[i].loanPeriod,
      moment(getAdvance[i].startDate).utc().format("YYYY-MM-DD"),
      moment(getAdvance[i].endDate).utc().format("YYYY-MM-DD"),
      getAdvance[i]._id,
      getAdvance[i].empId,
      getAdvance[i].description,
      getAdvance[i].year,
      getAdvance[i].month,
    ];

    sortAdvance.push(singleArr);
  }

  // //console.log(modal_Advance_list);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Advance " pageTitle="Advance" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h4 className="card-title mb-0">Add Item, Edit & Remove</h4> */}
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">Add Advance</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            // onClick={() => tog_list()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row className="g-4 mb-3"></Row>
                  {/* <div className="table-responsive table-card mt-3 mb-1">
                      <Grid
                        data={sortAdvance}
                        columns={[
                          "Full Name.",
                          "loanAmount",
                          "perMonthDedection",
                          "loanPeriod",
                          "startDate",
                          "endDate",
                          { 
                            name: 'EmpID',
                            hidden: true
                          },
                          { 
                            name: 'Year',
                            hidden: true
                          },
                          { 
                            name: 'Month',
                            hidden: true
                          },
                          { 
                            name: 'Desc',
                            hidden: true
                          },
                          {
                            name: "Actions",
                            width: "100px",
                            formatter: (cell, row) => {
                              return _(
                                <>

                                  <Row>
                                    <Col md={3}>
                                      <DropdownItem
                                        className="edit-item-btn"
                                        onClick={() =>
                                          updateAdvance(cell, row)
                                        }
                                      >
                                        <FaEdit size={"15"} />
                                      </DropdownItem>
                                    </Col>
                                    <Col md={3}>
                                      <DropdownItem
                                        onClick={() => deletPop(cell, row)}
                                      >
                                        {" "}
                                        <MdDelete size={"15"} />
                                      </DropdownItem>
                                    </Col>
                                  </Row>

                                </>
                              );
                            },
                          },
                        ]}

                        search={true}
                        pagination={{ enabled: true, limit: 10 }}
                      />
                    </div> */}
                  <TableContainer
                    columns={columns}
                    data={getAdvance || []}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    customPageSize={10}
                    className="custom-header-css"
                    // handleCustomerClick={handleCustomerClicks}
                    // isCustomerFilter={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Advance Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        {/* <ModalHeader className="bg-light p-3">
                    Add Advance
                    <Button
                        type="button"
                        onClick={() => {
                            setmodal_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </ModalHeader> */}

        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">Add Advance</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label>Employee Name</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <Select
                    // className="bg-white"
                    options={CustomersArr}
                    // onChange={(choice) =>
                    //   setEmployeeId(choice.value)
                    // }

                    onChange={handleTypeSelect}
                    value={CustomersArr.filter(function (option) {
                      return option.value === empId;
                    })}
                    defaultValue={{ label: "Select Employee", value: empId }}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        // text: "orangered",
                        // primary25: "hotpink",
                        // primary: "black",
                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <label>Select Year</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <select
                    className="form-select "
                    id="itemtype"
                    onChange={(e) => setYear(e.target.value)}
                    value={year}
                  >
                    <option selected>Choose...</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label>Select Month</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <select
                    className="form-select "
                    id="itemtype"
                    onChange={(e) => setMonth(e.target.value)}
                    value={month}
                  >
                    <option selected>Choose...</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>Loan Amount</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <div className="mb-3">
                    <input
                      type="number"
                      id="Employee-field"
                      className="form-control"
                      placeholder="Enter Loan Amount"
                      required
                      onChange={(e) => setLoanAmount(e.target.value)}
                      value={loanAmount}
                      onKeyUp={(e) => calc(e.target.value, 1)}
                    ></input>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label>Loan Period(Months)</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <div className="mb-3">
                    <input
                      type="number"
                      id="Employee-field"
                      className="form-control"
                      placeholder="Enter loanPeriod"
                      required
                      onChange={(e) => setloanPeriod(e.target.value)}
                      value={loanPeriod}
                      onKeyUp={(e) => calc(e.target.value, 2)}
                    ></input>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <label>Per Month Dedection</label>
                  <div className="mb-3">
                    <input
                      type="number"
                      id="TxtPerMonthDedection"
                      className="form-control"
                      disabled="disabled"
                      placeholder="Enter Per Month Dedection"
                      required
                      onChange={(e) => setPerMonthDedection(e.target.value)}
                      value={perMonthDedection}

                      // onKeyUp="calc(this.value, 1)"
                      // onKeyUp={calc(this.value, 1)}
                      // onKeyUp={calculateLoanPeriod.toggleCP}
                    ></input>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label>Start Date</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  {/* 
                    <Flatpickr
                        className="form-control"
                        placeholder="DD/MM/YYYY"
                        options={{
                          dateFormat: "d/m/Y",
                        }}
                           onChange={(e) => setStartDate(e.target.value)}
                    value={startDate}
                      /> */}

                  <Flatpickr
                    className="form-control"
                    placeholder="DD/MM/YYYY"
                    options={{
                      dateFormat: "d/m/Y",
                      defaultDate: "today",
                    }}
                    onChange={([date]) => setStartDate(date)}
                    value={startDate}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>End Date</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <Flatpickr
                    className="form-control"
                    placeholder="DD/MM/YYYY"
                    // disabled="disabled"
                    options={{
                      dateFormat: "d/m/Y",
                      defaultDate: "today",
                    }}
                    onChange={([date]) => setEndDate(date)}
                    value={endDate}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <label>Remarks</label>
                  <div className="mb-3">
                    <textarea
                      type="text"
                      id="Employee-field"
                      className="form-control"
                      placeholder="Enter Remarks"
                      onChange={(e) => setRemarks(e.target.value)}
                      value={remarks}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isUpdating ? "Update" : "save"}
                {/* Save */}
              </button>
            </div>
          </ModalFooter>
        </form>

        {/* =================================== */}
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={deleteAdvance}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Advance;
