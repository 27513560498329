import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Grid } from "gridjs";
import { _ } from "gridjs-react";

const ManageInventory = () => {
  const [modal_list, setmodal_list] = useState(false);

  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    const attroptions = {
      valueNames: [
        "name",
        "born",
        {
          data: ["id"],
        },
        {
          attr: "src",
          name: "image",
        },
        {
          attr: "href",
          name: "link",
        },
        {
          attr: "data-timestamp",
          name: "timestamp",
        },
      ],
    };

    // pagination list

    new List("pagination-list", {
      valueNames: ["pagi-list"],
      page: 3,
      pagination: true,
    });
  });

  document.title = "Manage Inventory";

  const mockData = [
    {
      card_no: "102140",
      card_type: "hool",
      customer_name: "gesey",
      customer_phone: "061245874",
      card_package: "Diini",
      open_date: "10-09-2022",
      using_days: "30 days",
    },
    {
      card_no: "102140",
      card_type: "hool",
      customer_name: "gesey",
      customer_phone: "061245874",
      card_package: "Diini",
      open_date: "10-09-2022",
      using_days: "30 days",
    },
  ];
  const editPop = (data) => {
    setmodal_list(true);
  };
  const sortInventroies = [];
  for (let i = 0; i < mockData?.length; i++) {
    // let date = mockData[i].createdAt.split("T");
    // const formattedDate = moment(date[0]).utc().format("DD-MM-YYYY");
    let singleArr = [
      mockData[i].card_no,
      mockData[i].card_type,
      mockData[i].customer_name,
      mockData[i].customer_phone,
      mockData[i].card_package,
      mockData[i].open_date,
      mockData[i].using_days,
    ];

    sortInventroies.push(singleArr);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Manage Inventory " pageTitle="Inventory" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <h4 className="card-title mb-0">Manage Inventory</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Link to="/ItemDistripution" className="text-white">
                            <Button
                              color="success"
                              className="add-btn me-1"
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Add New Transfer
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="g-4 mb-3"></Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      {/* {mockData.length > 0 && (
                        <Grid
                          data={sortInventroies}
                          columns={[
                            "Tranfer ID",
                            "Date",
                            "transfer Type",
                            "From",
                            "To",
                            "Quantity",
                            "Remark",
                            {
                              name: "Actions",
                              width: "100px",
                              formatter: (cell, row) => {
                                return _(
                                  <>
                                    <UncontrolledDropdown className="dropdown d-inline-block">
                                      <DropdownToggle
                                        className="btn btn-soft-secondary btn-sm"
                                        tag="button"
                                      >
                                        <i className="ri-more-fill align-middle"></i>
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-end">
                                        <Row>
                                          <Col md={3}>
                                            <DropdownItem className="edit-item-btn">
                                              <i className="ri-edit-2-line link-success fs-15"></i>
                                            </DropdownItem>
                                          </Col>
                                          <Col md={3}>
                                            <DropdownItem>
                                              {" "}
                                              <i className="ri-delete-bin-line link-danger fs-15"></i>
                                            </DropdownItem>
                                          </Col>
                                        </Row>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </>
                                );
                              },
                            },
                          ]}
                          search={true}
                          pagination={{ enabled: true, limit: 10 }}
                        />
                      )} */}
                    </div>

                    <table
                      className="table align-middle table-nowrap"
                      id="cardTable"
                    >
                      <thead className="table-light">
                        <tr>
                          <th scope="col" style={{ width: "5px" }}></th>
                          <th className="sort" data-sort="card_no">
                            Transfer ID
                          </th>
                          <th className="sort" data-sort="card_type">
                            Date
                          </th>
                          <th className="sort" data-sort="customer_name">
                            Transfer Type
                          </th>
                          <th className="sort" data-sort="customer_phone">
                            From
                          </th>
                          <th className="sort" data-sort="card_package">
                            To
                          </th>
                          <th className="sort" data-sort="open_date">
                            Quantity
                          </th>
                          <th className="sort" data-sort="using_days">
                            Remark
                          </th>
                          <th className="sort" data-sort="action">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="list form-check-all">
                        {mockData.map((data, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row"></th>
                              <td className="id" style={{ display: "none" }}>
                                <Link to="#" className="fw-medium link-primary">
                                  #VZ2104
                                </Link>
                              </td>
                              <td className="card_no">{data.card_no}</td>
                              <td className="card_type">{data.card_type}</td>
                              <td className="customer_name">
                                {" "}
                                {data.customer_name}{" "}
                              </td>
                              <td className="customer_phone">
                                {" "}
                                {data.customer_phone}{" "}
                              </td>
                              <td className="card_package">
                                {" "}
                                {data.card_package}{" "}
                              </td>
                              <td className="open_date"> {data.open_date} </td>
                              <td className="using_days">
                                {" "}
                                {data.using_days}{" "}
                              </td>
                              <td>
                                <div className="d-flex gap-2">
                                  <div className="edit">
                                    <button
                                      className="btn btn-sm btn-success edit-item-btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#showModal"
                                      onClick={() => editPop(data)}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                  <div className="remove">
                                    <button
                                      className="btn btn-sm btn-danger remove-item-btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteRecordModal"
                                      //  onClick={() => deletPop(data)}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Open Closed Card Distribution From */}

      {/* <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        <ModalHeader className="bg-light p-3">
          Close Card
          <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <form>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Card Number</label>
                  <input
                    type="number"
                    id="card_number-field"
                    className="form-control"
                    placeholder="Enter Card Number"
                    required
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Card Type</label>
                  <input
                    type="number"
                    id="card_type-field"
                    className="form-control"
                    placeholder="Enter Card Type"
                    required
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Card Package</label>
                  <input
                    type="text"
                    id="card_package-field"
                    className="form-control"
                    placeholder="Enter Card Package"
                    required
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Customer Name</label>
                  <input
                    type="text"
                    id="customer_name-field"
                    className="form-control"
                    placeholder="Enter Customer Name"
                    required
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>Billing Cycle</label>
                  <select
                    className="form-select "
                    id="itemtype"
                    // onChange={(e) => setItemType(e.target.value)}
                    // value={itemType}
                  >
                    <option selected>Choose...</option>
                    <option value="Monthly">Monthly</option>
                    <option value="3 Month">3 Mothly</option>
                    <option value="6 Month">6 Mounth</option>
                  </select>
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Open Date</label>
                  <input
                    type="date"
                    id="number_of_channels-field"
                    className="form-control"
                    placeholder="Enter  Number of Channels"
                    required
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Close Date</label>
                  <input
                    type="date"
                    id="number_of_channels-field"
                    className="form-control"
                    placeholder="Enter  Number of Channels"
                    required
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Using Days</label>
                  <input
                    type="number"
                    id="amount-field"
                    className="form-control"
                    placeholder="Enter  Using Days"
                    required
                  ></input>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Amount</label>
                  <input
                    type="number"
                    id="amount-field"
                    className="form-control"
                    placeholder="Enter Amount"
                    required
                  ></input>
                </div>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>

              <button type="button" className="btn btn-success" id="edit-btn">
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal> */}

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ManageInventory;
