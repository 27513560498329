import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Select from "react-select";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Autocomplete, TextField } from "@mui/material";

const Ticket = () => {
  const [storeName, setstoreName] = useState("");
  const [storeLocation, srtstoreLocation] = useState("");
  const [storeDescription, setstoreDescription] = useState("");
  const [isPrimaryStore, setisPrimaryStore] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [siteId, setSiteId] = useState("");
  const [zoneId, setZone] = useState("");
  const [groupId, setGroupId] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [customerInfo, setCustomerInfo] = useState();
  const [employeeId, setEmployeeId] = useState("");
  const [yes, setYes] = useState(true);
  const [id, setId] = useState("");
  // const [no, setNo] = useState(false);

  const [supplierId, setsupplierId] = useState("");
  const [amount, setamount] = useState(5);
  const [reference, setreference] = useState("");
  const [issueDate, setIsseDate] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [Date, setDate] = useState();
  const [custAddress, setCustAddress] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [empPhone, setempPhone] = useState("");
  const [supervisorInfo, setSupervisorInfo] = useState("");
  const [CustometINFO, setCustometINFO] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const {
    // registerCustomer,
    fetchCustomers,
    customers,
    fetchSites,
    sites,
    fetchGroups,
    getByIdGroup,
    getByIdGroups,
    fetchByIdZones,
    getByIdZones,
    fetchByIEmployee,
    GetByIdEmployee,
    registertickets,
    fetchTickets,
    GetTickets,
    ticketsUpdate,
    fetchByIdZonesCable,
    getByIdZonesCables,
    fetchEmployeesToAssign,
    EmployeesFromZone,
    getSingleEmployee,
    singleEmployee,
    getSingleCustomer,
    getByIdCustomers,
    singleCustomer,
    getSupervisors,
    supervisors,
    ticketsSupervisorAdd,
    User,
    isLoading,
    fetchAutoCustomers,
    isNewCableLoading,
    updatedCustomers,fetchBothCustomers,bothcustomers
  } = useContext(LoginContex);
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  //console.log(yes);
  useEffect(() => {
    fetchCustomers();
    fetchSites();
    fetchGroups();
    fetchTickets();
    // getByIdCustomers(customerInfo?.value);
    getSupervisors();
  }, []);

  //console.log(supervisors);
  let getGroup = (id) => {
    // //console.log(id)
    setSiteId(id);

    getByIdGroup(id);
    setGroupId("");
    setZone("");
    setEmployeeId("");
  };

  let getZones = (id) => {
    setGroupId(id);
    fetchByIdZonesCable(id);
    // //console.log(getZoneById)
  };

  // const deleteAdvance = (data) => {
  //   setObject(data._id);
  //   advanceDelete(data._id);
  // };

  // let getZones = (id) => {
  //   setGroupId(id);
  //   fetchByIdZones(id);
  //   //console.log(getZoneById);
  // };

  // groupId
  // :
  // "63451153f2f6d05e80ead191"
  // siteId
  // :
  // "634bacedd3df7321eb703ef4"
  // zoneId
  // :
  // "634d5423df758e11a0502440"

  let getEmployee = (id) => {
    setZone(id);
    let Data = [
      {
        siteId: siteId,
        groupId,
        zoneId: id,
      },
    ];

    fetchByIEmployee(siteId, groupId, id);
    // //console.log(GetByIdEmployee)
  };

  // let getEmployee = (id) => {
  //   setZone(id);
  //   let Data = [
  //     {
  //       siteId: id,
  //       groupId,
  //       zoneId,
  //     },
  //   ];

  //   fetchByIEmployee(Data);
  //   // //console.log(GetByIdEmployee)
  // };

  // setEmployeeId(id);

  // //console.log(paymentAmount?.supply_blance);
  document.title = "Ticket ";
  const mockData = [
    {
      Ticket_Id: "1",
      Date: "23/10/2022",
      Customer_Name: "Axmed Xuseen Xanshi",
      Ticket_Title: "ABCD",
      Site_Sent_To: "Bakaaro Site",
      IS_Resolved: "No",
    },
  ];

  const getEmployeePhone = (id) => {
    setEmployeeId(id);
    getSingleEmployee(id);
    //console.log(singleEmployee.mobile);
    // //console.log(supervisorInfo?.label);
    setempPhone(singleEmployee.mobile);
  };

  const showToastMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastMessageError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  async function sendMessage(content, phone) {
    //console.log("description", description.length);

    if (description.trim().length < 10) {
      setTimeout(
        () => showToastMessageError("Error sending Message to Employee!"),
        2500
      );
    } else {
      //console.log(content, phone);
      //console.log(phone);
      let config = {
        "Content-Type": "text/plain",
        Accept: "*/*",
      };
      axios
        .get(
          `https://smsa.tabaarak.com/SendSMS.aspx?user=Asal&pass=TV@ccess2016&cont=${content}&rec=${phone}`,
          {
            headers: config,
          }
        )
        .then((response) => {
          //console.log(response.data);
          this.setState({ posts: response.data });
        })
        .catch((err) => {
          //console.log("API call error:", err.Message);
          setTimeout(
            () => showToastMessage("Message has been sent successfully"),
            2500
          );
        });
    }
  }

  // const [albums, setAlbums] = useState("");

  // const sendMessage = async () => {
  //   try {
  //     const { data } = await axios.get("https://smsa.tabaarak.com/SendSMS.aspx?user=TabaarakTest&pass=TabaarakTest@@&cont=Testfromtbrksmstesting&rec=0615765331");
  //     setAlbums(data);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

  // //console.log("origin" , empPhone);
  let FirstNum = String(empPhone)[0];
  // //console.log("FirstNum" , FirstNum);

  let getCustAddress = (id) => {
    setCustomerInfo(id);

    //console.log("ID: ", id);
    setCustomerId(id);
    getSingleCustomer(id);
    setCustAddress(id.customerAddress);

    //console.log("Address", singleCustomer.customerAddress);
  };

  //console.log("singleEmployee", singleEmployee);

  // let Ename = supervisorInfo?.label == undefined ? "": supervisorInfo?.label;
  // let Cname = singleCustomer.customerName == undefined ? "" : singleCustomer.customerName;
  // let Cphone = singleCustomer.customerPhone == undefined ? "": singleCustomer.customerPhone;
  // let Caddress = singleCustomer.customerAddress == undefined ? "" : singleCustomer.customerAddress;
  // let desc;

  //console.log(supervisorInfo?.value);
  //console.log(supervisorInfo?.label);
  let userId = User?.data?.user?._id || User?.user?._id;
  let userIds = User?.user?._id;
  //console.log(userId);
  //console.log(userIds);
  //console.log(selectedOption)
  const handleSubmit = (e) => {
    //console.log(isEditing);
    e.preventDefault();
//console.log(selectedOption?.customers)
    var desc;
    if (supervisorInfo?.label == "" || supervisorInfo?.phoneNumber == "") {
      desc = `${supervisorInfo?.empname} : ${description} -> Waxaa u Adeegtaa Macaaamilka ah '${selectedOption?.customers}' : Wata telephone number '${selectedOption?.customerPhone}' : Degaanka ${selectedOption?.customerAddress}`;
    } else {
      desc = `${supervisorInfo?.empname} : ${description} -> Waxaa u Adeegtaa Macaaamilka ah '${selectedOption?.customers} ' : Wata telephone number '${selectedOption?.customerPhone}' : Degaanka ${selectedOption?.customerAddress}`;
    }

    var PhoneNum = supervisorInfo?.phoneNumber;

    if (!isEditing) {
      const data = {
        issueDate,
        empId: supervisorInfo?.value,
        customerId: selectedOption?._id,
        custAddress,
        siteId: null,
        groupId: null,
        zoneId: null,
        title,
        description,
        content: desc,
        userId: userId,
        phoneNumber: PhoneNum.trim(),
      };
      // sendMessage(description, empPhone);
      // registertickets(data);

      if (description == "" || description == undefined) {
        showToastMessageError("Please make sure to fill description field");
      } else {
        ticketsSupervisorAdd(data);
        //console.log("mydata", data);
      }
      setSupervisorInfo("");
      setCustomerInfo("0");
      setCustAddress("");
      setSiteId("0");
      setGroupId("0");
      setZone("0");
      setTitle("");
      setDescription("");
      setSelectedOption("")
    }
    if (isEditing) {
      const data = {
        id: updateId,
        issueDate,
        empId: employeeId,
        customerId: selectedOption?._id,
        custAddress,
        siteId,
        groupId,
        zoneId,
        title,
        description,
      };
      ticketsUpdate(data);

      setSupervisorInfo("0");
      setCustomerInfo("0");
      setCustAddress("");
      setSiteId("0");
      setGroupId("0");
      setZone("0");
      setTitle("");
      setDescription("");
    }
  };

  const deleteStore = (data) => {
    setmodal_delete(true);
    setId(data._id);
  };

  const getEmployeeFromZone = (id) => {
    setZone(id);
    const data = {
      zoneId: id,
    };
    fetchEmployeesToAssign(data);
    //console.log(data);
  };

  const delStore = () => {
    setmodal_delete(false);
  };

  //updateees
  const updatTickets = (data) => {
    setSiteId(data.siteId);

    setGroupId(data.groupId);

    setZone(data.zoneId);

    setmodal_list(true);
    setEmployeeId(data.empId);
    setCustomerId(data.customerId);
    setCustAddress(data.custAddress);
    setSiteId(data.siteId);
    setGroupId(data.groupId);
    setZone(data.zoneId);
    setTitle(data.title);
    setDescription(data.description);
    setEditing(true);
    setUpdateId(data._id);
  };

  const addModal = () => {
    tog_list();
    setEditing(false);
    setamount("");
    setreference("");
    setsupplierId("");
    setIsseDate("");
  };

  const CustomersArr = [];

  for (let i = 0; i < customers.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: customers[i]._id,
      label: ` ${customers[i].customerName} -- ${customers[i].customerPhone}`,
      customername: ` ${customers[i].customerName}`,
      customerPhone: ` ${customers[i].customerPhone}`,
      customerAddress: ` ${customers[i].customerAddress}`,
    };

    CustomersArr.push(dropdownList);
  }
  const supervisorsArr = [];

  for (let i = 0; i < supervisors.length; i++) {
    // //console.log(supervisors[i]._id);

    var dropdownList = {
      value: supervisors[i]._id,
      label: ` ${supervisors[i].empName} -- ${supervisors[i].mobile}`,
      empname: ` ${supervisors[i].empName}`,
      phoneNumber: ` ${supervisors[i].mobile}`,
    };

    supervisorsArr.push(dropdownList);
  }

  //console.log(supervisors);

  const getOptionSelected = (option, value) => {
    //console.log(option);
    //console.log(value);
    if (option && value) {
      return option.id === value.id;
    }
    return false;
  };
  //console.log(getOptionSelected());
  //console.log(selectedOption);
  const onInputChange2 = (event, value, reason) => {
    //console.log(value);
    //console.log(event);
    setCustometINFO(value);
    fetchBothCustomers(value);

    const matchingOption = makeItArray2.find(
      (option) =>
        option.customers.toLowerCase() ==
        value.substring(0, value.indexOf("-")).toLowerCase()
    );

    setSelectedOption(matchingOption);
  };

  const makeItArray2 = [];

  for (let i = 0; i < bothcustomers?.length; i++) {
    // //console.log("from: " + formattedDate);
    let singleArr = {
      customers: bothcustomers[i]?.customerName,
      customerPhone: bothcustomers[i]?.customerPhone,
      _id: bothcustomers[i]?._id,
      customerAddress: bothcustomers[i]?.customerAddress,
      districtName: bothcustomers[i]?.districtName,
    };

    makeItArray2.push(singleArr);
  }
  //console.log(makeItArray2);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Create Ticket" pageTitle="Ticket" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          {/* <h4 className="card-title mb-0">Add Ticket</h4> */}
                        </div>
                      </div>
                    </Col>

                    {/* <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={() => tog_list()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            Create Ticket
                          </Button>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="StoreList">
                    <Row className="g-4 mb-3"></Row>

                    <form onSubmit={handleSubmit}>
                      <ModalBody>
                        <div
                          className="mb-3"
                          id="modal-id"
                          style={{ display: "none" }}
                        >
                          <label htmlFor="id-field" className="form-label">
                            ID
                          </label>
                          <input
                            type="text"
                            id="id-field"
                            className="form-control"
                            placeholder="ID"
                            readOnly
                          />
                        </div>

                        <Row>
                          <Col md={4} className="d-none">
                            <div className="mb-3">
                              <label>
                                Ticket date{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Flatpickr
                              disabled={isLoading}
                                className="form-control"
                                required
                                placeholder="DD/MM/YYYY"
                                options={{
                                  dateFormat: "d/m/Y",
                                  defaultDate: "today",
                                }}
                                onChange={([date]) => setDate(date)}
                                // value={Date}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                             
                              <Label className="form-label">
                                Customer <span className="text-danger">*</span>
                              </Label>
                              <Autocomplete
                                disabled={isLoading}
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                options={makeItArray2}
                                getOptionSelected={getOptionSelected}
                                getOptionLabel={(option) => {
                                  return `${option.customers?.toString()}-${option.customerPhone?.toString()}`;
                                }}
                                value={selectedOption}
                                onInputChange={onInputChange2}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Search Customer..."
                                  />
                                )}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <label className="form-label">Address</label>
                              <input
                              //  disabled={isLoading}
                                type="text"
                                id="address"
                                className="form-control"
                                placeholder="Enter Address"
                                onChange={(e) =>
                                  setCustAddress(e.target.value, e.target)
                                }
                                value={selectedOption?.customerAddress}
                                disabled
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row></Row>
                        <Row>
                          <Col md={4}>
                            <div className="mb-3">
                              <label className="form-label">Supervisor</label>
                              <Select
                              isDisabled={isLoading}
                                // className="bg-white"
                                options={supervisorsArr}
                                onChange={(choice) =>
                                  // getCustPhone(e.target.value, e.target)
                                  setSupervisorInfo(choice)
                                }
                                value={supervisorInfo}
                                required
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                  },
                                })}
                              />
                            </div>
                          </Col>
                          <Col md={8}>
                            <div className="mb-33">
                              <label className="form-label">Ticket title</label>
                              <input
                               disabled={isLoading}
                                type="text"
                                id="address"
                                className="form-control"
                                placeholder="Enter Ticket Title"
                                onChange={(e) =>
                                  setTitle(e.target.value, e.target)
                                }
                                value={title}
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <label className="form-label">Description</label>
                              <textarea
                               disabled={isLoading}
                                className="form-control"
                                id="Description-field"
                                rows="4"
                                onChange={(e) =>
                                  setDescription(e.target.value, e.target)
                                }
                                value={description}
                              />
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => setmodal_list(false)}
                          >
                            Close
                          </button>
                          {/* <button
                            type="submit"
                            className="btn btn-success"
                            id="add-btn"
                          >
                            {isEditing ? "update" : "Save"}
                          </button> */}

                          {isLoading == true ? (
                            <button
                              disabled
                              type="button"
                              className="btn btn-success btn-load w-100"
                            >
                              <span className="d-flex align-items-center">
                                <span
                                  className="spinner-border flex-shrink-0"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </span>
                                <span className="flex-grow-1 ms-2 text-center">
                                  Loading...
                                </span>
                              </span>
                            </button>
                          ) : (
                            <Button
                              color="success"
                              className="btn btn-success w-100"
                              type="submit"
                            >
                              Save
                            </Button>
                          )}
                        </div>
                      </ModalFooter>
                    </form>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#121331,secondary:#08a88a"
                            style={{ width: "75px", height: "75px" }}
                          ></lord-icon>
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">
                            We've searched more than 150+ Orders We did not find
                            any orders for you search.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Create New Ticket</h5>
          <button
            type="button"
            className="btn-close"
            onClick={addModal}
            aria-label="Close"
          ></button>
        </div>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => delStore()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Ticket;
