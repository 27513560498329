import React, { useState, useEffect, useContext, useMemo } from "react";
import { Grid, _ } from "gridjs-react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";

//Import Flatepicker
import Flatpickr from "react-flatpickr";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Cell } from "gridjs";
import Select from "react-select";
import TableContainer from "../../Components/Common/TableContainer";

const Payment = () => {
  const [isEditing, setEditing] = useState(false);
  const [yes, setYes] = useState(true);
  const [id, setId] = useState("");
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const [yaab, setyaab] = useState();
  const [helper, setHelper] = useState("");

  const [supplierId, setsupplierId] = useState("");
  const [amount, setamount] = useState();
  const [reference, setreference] = useState("");
  const [date, setDate] = useState("");
  const {
    fetchItems,
    items,
    registerPayment,
    fetchPayments,
    payments,
    deletePayment,
    updatePayment,
    tttt,
    fetchCustomerBalance,
    paymentAmount,
    fetchPaymentsById,
    paymentByIDS,
    paymentFilterByDate,
    paymentByFilter,User
  } = useContext(LoginContex);
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  // //console.log(yes);
  useEffect(() => {
    fetchItems();
    fetchPayments();
    fetchCustomerBalance(supplierId?.value);
    fetchPaymentsById(id);
  }, [supplierId?.value, id]);

  // //console.log(paymentAmount?.supply_blance);
  document.title = "Payment ";

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  let userId = User?.data?.user?._id || User?.user?._id;

  //console.log(userId);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "supplierName",
        filterable: false,
      },
      {
        Header: "Purchase Amount",
        accessor: "purchaseAmount",
        filterable: false,
      },
      // {
      //   Header: "description",
      //   accessor: "description",
      //   filterable: false,
      // },
      {
        Header: "Date",
        accessor: "referenceNo",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      {
        Header: "Referance No",
        accessor: "description",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={(row) => {
                    const paymentData = cellProps.row.original;
                    updateForm(paymentData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={(row) => {
                    const paymentData = cellProps.row.original;
                    deleteStore(paymentData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ]
    // [handleCustomerClick]
  );
  // //console.log(paymentByIDS);

  const handleSubmit = (e) => {
    // //console.log(isEditing);
    e.preventDefault();
    if (!isEditing) {
      const data = {
        paymentDate: date,
        reference,
        amount,
        supplierId: supplierId.value,
        balance: paymentAmount?.supply_blance,
        userId
      };
      if (!supplierId.value || !amount) {
        return;
      }
      registerPayment(data);
      setmodal_list(false);
      setamount("");
      setreference("");
      setsupplierId("");
      setDate("");
      e.target.reset();

      // //console.log(data);
    }
    if (isEditing) {
      const data = {
        paymentDate: date,
        reference,
        amount,
        id: yaab,
        supplierId: supplierId.value,
        balance: paymentAmount?.supply_blance,
      };
      // if (!reference || !amount) {
      //   return;
      // }
      updatePayment(data);
      setmodal_list(false);
    }
  };

  const deleteStore = (data) => {
    // //console.log(data);
    setmodal_delete(true);
    setId(data._id);
  };

  const delStore = () => {
    deletePayment(id);
    setmodal_delete(false);
  };

  //updateees
  ////console.log(isEditing);
  const updateForm = (row) => {
    // //console.log(`${data}------------------------------------`);
    // //console.log(row._cells);
    //console.log(row);
    // const the_id = data;
    setmodal_list(true);
    setamount(row.purchaseAmount);
    setreference(row.description);
    // setsupplierId(data.supplierId);
    setDate(row.referenceNo);
    setyaab(row._id);
    setYes(yes);
    setEditing(true);
    setId(row._id);
    // setsupplierId({ label: row._cells[1].data, value: row._cells[1].data });
    setsupplierId({ label: row.supplierName, value: row.supplierId });
    //console.log(row._cells[1].data);
  };
  const addModal = () => {
    tog_list();
    setEditing(false);
    setamount("");
    setreference("");
    setsupplierId("");
    setDate("");
  };
  // //console.log(payments);

  // soloving dropdowns
  const suppliersArr = [];

  for (let i = 0; i < items.length; i++) {
    // //console.log(items[i]._id);

    var dropdownList = {
      value: items[i]._id,
      label: items[i].supplierName,
    };

    suppliersArr.push(dropdownList);
  }
  // //console.log(payments);
  // //console.log(paymentByFilter);

  const sortedPaymet = [];
  for (let i = 0; i < payments?.length; i++) {
    let date = payments[i].referenceNo.split("T");
    const formattedDate = moment(date[0]).format("DD-MM-YYYY");
    let singleArr = [
      payments[i].purchaseDate,
      payments[i].supplierName,
      formattedDate,
      payments[i].purchaseAmount,
      payments[i].description,
      payments[i]._id,
      payments[i].supplierId,
    ];

    sortedPaymet.push(singleArr);
  }
  const sortedPaymetByDate = [];
  for (let i = 0; i < paymentByFilter?.length; i++) {
    let date = paymentByFilter[i].referenceNo.split("T");
    const formattedDate = moment(date[0]).format("DD-MM-YYYY");
    ////console.log(formattedDate);
    let singleArr = [
      paymentByFilter[i].purchaseDate,
      paymentByFilter[i].supplierName,
      formattedDate,
      paymentByFilter[i].purchaseAmount,
      paymentByFilter[i].description,
      paymentByFilter[i]._id,
      paymentByFilter[i].supplierId,
    ];

    sortedPaymetByDate.push(singleArr);
  }

  //console.log(sortedPaymetByDate);
  // //console.log(payments);
  // //console.log(yaab);
  //console.log(items);

  const itemArr = [];

  for (let i = 0; i < items.length; i++) {
    // //console.log(items[i]._id);

    var dropdownList = {
      value: items[i]._id,
      label: ` ${items[i].supplierName} `,
    };

    itemArr.push(dropdownList);
  }

  ////console.log(items);

  const filterThroughDate = () => {
    // //console.log(start);
    // //console.log(end);
    setHelper(true);
    paymentFilterByDate(start, end);
  };

  const clearFilter = () => {
    setHelper(false);
  };
  //console.log(paymentByIDS);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Vendor Payment" pageTitle="Purchase" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">Add Payment</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add Payment
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="StoreList">
                    <Row className="mb-5">
                      {/* <Col md={3}>
                        <div className="mb-3">
                          <label>Supplier</label>
                          <Select
                            options={suppliersArr}
                            onChange={(choice) => setSupplierId(choice)}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                // text: "orangered",
                                // primary25: "hotpink",
                                // primary: "black",
                              },
                            })}
                          />
                        </div>
                      </Col> */}
                      <Col md={3}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Start Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStart(date)}
                            value={start}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            End Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEnd(date)}
                            value={end}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={filterThroughDate}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Filter By Date{" "}
                          </Button>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={clearFilter}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Clear filter{" "}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      
                    </div>

                    <TableContainer
                      columns={columns}
                      data={helper === true ? paymentByFilter : payments || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      // handleCustomerClick={handleCustomerClicks}
                      // isCustomerFilter={true}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Add New Payment</h5>
          <button
            type="button"
            className="btn-close"
            onClick={addModal}
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label>
                    Supplier name <span className="text-danger">*</span>
                  </label>
                  {/* <select
                    className="form-select "
                    id="itemtype"
                    onChange={(e) => setsupplierId(e.target.value)}
                    value={supplierId}
                    required
                  >
                    <option value="">
                      &hellip; Choose supplier name &hellip;
                    </option>
                    {items?.map((item, index) => {
                      return (
                        <option value={item._id} key={index}>
                          {" "}
                          {item.supplierName}{" "}
                        </option>
                      );
                    })}
                  </select> */}
                  <Select
                    options={itemArr}
                    onChange={(choice) => setsupplierId(choice)}
                    required
                    value={itemArr.filter(function (option) {
                      //console.log(option);
                      return option.value === supplierId?.value;
                    })}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        // text: "orangered",
                        // primary25: "hotpink",
                        // primary: "black",
                      },
                    })}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Vendor Balance </label>
                  <input
                    type="number"
                    id="CashAccount-field"
                    className="form-control"
                    placeholder="Balance"
                    disabled
                    value={paymentAmount?.supply_blance}
                    // defaultValue={paymentAmount?.supply_blance}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">
                    Payment Amount <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="CashAccount-field"
                    className="form-control"
                    placeholder="Enter Amount"
                    required
                    onChange={(e) => setamount(e.target.value)}
                    value={amount}
                    defaultValue={paymentByIDS.amount}
                  />
                </div>
              </Col>

              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">Reference No</label>
                  <input
                    type="text"
                    id="referenceno-field"
                    className="form-control"
                    placeholder="Enter Reference No"
                    onChange={(e) => setreference(e.target.value)}
                    value={reference}
                    defaultValue={paymentByIDS.referenceNo}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <Label htmlFor="PurchasesDate" className="form-label">
                    Payment Date <span className="text-danger">*</span>
                  </Label>
                  {/* <Input
                    type="date"
                    className="form-control"
                    id="PurchasesDate"
                    onChange={(e) => setDate(e.target.value)}
                    value={date}
                    required
                  /> */}
                  <Flatpickr
                    className="form-control"
                    required
                    placeholder="DD/MM/YYYY"
                    options={{
                      dateFormat: "d/m/Y",
                      defaultDate: "today",
                    }}
                    onChange={([date]) => setDate(date)}
                    value={date}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isEditing ? "update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => delStore()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Payment;
