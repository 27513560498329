import React, { useState, useEffect, useContext, useMemo } from "react";

import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import moment from "moment";
// import styles from "./StyledTable.module.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import DataTable from "react-data-table-component";
const MonthlyAttendanceReport = () => {
  const [Discription, setdescription] = useState("");
  const { openAnalogCardRegister } = useContext(LoginContex);

  //   const [customerId, setCustomer] = useState();
  const [employeeInfo, setEmployeInfo] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [deviceId, setDeviceId] = useState();
  const [service, setService] = useState("424");
  const [summary, setSummary] = useState("");
  const [type, setType] = useState();
  const [helper, setHelper] = useState(false);
  const [customerId, setcustomerId] = useState("");
  const [middleWare, setmiddleWare] = useState("");
  const [Line, setsetLine] = useState("all");

  document.title = "Monthly Attendance Report";
  // //console.log(service);
  const {
    cardList,

    fetchCustomers,
    customers,
    fetchDepartment,
    departments,
    fetchEmployeeDepartments,EmployeesByDepartments,
    getSingleEmployee,singleEmployee,
    custiptvRepot,
    fetchCustIPTVReport,
    isLoading,
    FetchRevenuReport,
    paymentReport, fetchDevices, devices, fetchAttendanceData,attendanceData, fetchAttendanceUpdates,fetchEmployees,getEmployee,
    getMonthlyAttendanceReport,monthylyAttendanceReport
  } = useContext(LoginContex);
  let  numberOfLate = 0;
  useEffect(() => {
    fetchDepartment()
    fetchCustomers();
    fetchDevices()
    fetchEmployees()
    let date = new Date();
    const start = moment(date).format("YYYY-MM-DD");
    const end = moment(date).format("YYYY-MM-DD");
    // //console.log("employeeInfo ",employeeInfo?.value)
    // if(employeeInfo?.value !=="all"){
    //   getSingleEmployee(employeeInfo?.value)
    // }
    // fetchCustIPTVReport(start, end,"APP");
    // FetchRevenuReport(start, end, "424");
  }, []);
 
 
  // //console.log(employeeInfo?.value);


const [EmployeeDepartment, setEmployeeDepartment] = useState("");
const [employeeId, setEmployeeId] = useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
     numberOfLate = monthylyAttendanceReport?.filter((item) => (item.status == "Late"));
    //  //console.log(numberOfLate?.length);
  let  filteredItems = monthylyAttendanceReport?.length > 0 && monthylyAttendanceReport?.filter((item) => (
    item.emp?.empName&&
    item.emp?.empName?.toLowerCase().includes(filterText?.toLowerCase()) && item.emp?.mobile ||
    item.emp?.empNo?.toString().toLowerCase().includes(filterText?.toLowerCase()) 
  ));
  // //console.log(filteredItems);

//  


  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
//   //console.log(Four24Count)
  const columns = useMemo(
    () => [
      {
        Header: "EMP-NO",
        accessor: "emp.empNo",
        filterable: true,
      },
      {
        Header: "Employee Name",
        accessor: "emp.empName",
        filterable: false,
      },
      {
        Header: "Phone Number",
        accessor: "emp.mobile",
        filterable: true,
      },
     
      {
        Header: "Shift",
        accessor: "shift.shiftName",
        filterable: true,
      },
  
      
      {
        Header: "Presents",
        accessor: "presents",
        filterable: true,
      },
      {
        Header: "Lates",
        accessor: "lates",
        filterable: true,
      },
      {
        Header: "Absents",
        accessor: "absents",
        filterable: true,
      },
      {
        Header: "Leaves",
        accessor: "leaves",
        filterable: true,
      },
      {
        Header: "Total Days",
        accessor: "totalDays",
        filterable: false
      },
      // {
      //   Header: "Time Out",
      //   accessor: "timeOut",
      //   filterable: false,
      // },
    
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   filterable: false,
      // },
      // {
      //   Header: "Timeout Status",
      //   accessor: "timeOutStatus",
      //   filterable: false,
      // },
  ])
  // //console.log(iptvCustomerRechargeData);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);


 


  // //console.log(filteredItems);
  
  const [filterText1, setFilterText1] = React.useState("");
  const [resetPaginationToggle1, setResetPaginationToggle1] =
    React.useState(false);
  const filteredItems1 = monthylyAttendanceReport?.filter((item) => (
  
    item.emp?.empName&&
    item.emp?.empName?.toLowerCase().includes(filterText1.toLowerCase()) && item.emp?.mobile ||
    item.emp?.empNo?.toString().toLowerCase().includes(filterText1?.toLowerCase()) 
  ))

  // //console.log('attendance data ---------------------214',monthylyAttendanceReport)

  const hormuudMoney = filteredItems1?.reduce((accu, curr)=>{
    // //console.log(curr?.hormuudMoney)
    if(curr?.hormuudMoney){
      return accu + Number(curr?.hormuudMoney)

    }
    return accu
  },0)

  // //console.log(hormuudMoney)


  // //console.log(iptvCustomerRechargeData);
  const subHeaderComponentMemo1 = React.useMemo(() => {
    const handleClear1 = () => {
      if (filterText1) {
        setResetPaginationToggle1(!resetPaginationToggle1);
        setFilterText1("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText1(e.target.value)}
          onClear={handleClear1}
          filterText={filterText1}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText1, resetPaginationToggle1]);
  const [filterText2, setFilterText2] = React.useState("");
  const [resetPaginationToggle2, setResetPaginationToggle2] =
    React.useState(false);
  const filteredItems2 = monthylyAttendanceReport?.filter((item) => (
    // for (let column of columns) {
    //   const value = column.selector(item);
    //   if (
    //     value &&
    //     value.toString().toLowerCase().includes(filterText.toLowerCase())
    //   ) {
    //     return true;
    //   }
    // }
    // return false;
    item.emp?.empName&&
    item.emp?.empName?.toLowerCase().includes(filterText1?.toLowerCase()) && item?.userId ||
    item?.userId?.toString().toLowerCase().includes(filterText1?.toLowerCase()) ||
    item.emp?.empNo?.toString().toLowerCase().includes(filterText1?.toLowerCase()) 
  ))
;

// const merchantTotal = filteredItems2.reduce((accu, curr)=>{

//   if(curr?.amount){
//     return accu + curr?.amount
//   }

//   return accu

// },0)

 
  
  // //console.log(filteredItems);
  // //console.log(iptvCustomerRechargeData);
  const subHeaderComponentMemo2 = React.useMemo(() => {
    const handleClear2 = () => {
      if (filterText2) {
        setResetPaginationToggle2(!resetPaginationToggle2);
        setFilterText2("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText2(e.target.value)}
          onClear={handleClear2}
          filterText={filterText2}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText2, resetPaginationToggle2]);

  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const filterThroughDate = async() => {
    let employees;
  
    // //console.log('employee ID ============================' ,employeeId?.value)
   let depts = EmployeeDepartment == "all"? 'all':EmployeeDepartment
    const employee = getEmployee?.filter(emp => emp._id === employeeId?.value);
    employees = employeeId?.value === "all"? 'all' : employeeId?.value
    // if(EmployeeDepartment?.value === "all" && employeeId?.value === "all"){
    //   employees = getEmployee
    // //  depts =   'all'
    // }else{
    //   employees = employeeId?.value === "all"? EmployeesByDepartments : employee
    //   // depts = EmployeeDepartment?.value
    // }
    setHelper(true);
    const startt = moment(start).format("YYYY-MM-DD");
    const enddd = moment(end).format("YYYY-MM-DD");
    // fetchCustIPTVReport(startt, enddd,type, Line);
    const deviceIp1 = deviceId?.value ? deviceId?.value : "All"
    let empId = employees
    // //console.log('departs ',depts)
    let data = {
      depts:depts,
      startDate :startt,
      endDate :enddd,
      deviceIp : deviceIp1,
      employees
      
    }
    // let empId = employeeId.value

    await getMonthlyAttendanceReport(data );
    // //console.log(attendanceData);
    // setmiddleWare(service);
  };

  const clearFilter = () => {
    setHelper(false);
    setStart("");
    setEnd("");
    setDeviceId("");
    setType("");
    setsetLine("");
  };

  const CustomersArr = [];

  for (let i = 0; i < customers.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: customers[i]._id,
      label: ` ${customers[i].customerName}---${customers[i].customerPhone}`,
    };

    CustomersArr.push(dropdownList);
  }

  const customerCards = [];

  const CvsData = [];
  for (let i = 0; i < filteredItems?.length; i++) {
    // //console.log(filteredItems[i]._id);

    if (filteredItems[i]?.customer_info?.customerName) {
      var dropdownList = {
        customerName:` ${filteredItems[i]?.customer_info?.customerName}`,
        customerPhone: ` ${filteredItems[i]?.customer_info?.customerPhone}`,
        cardNumber: ` ${filteredItems[i]?.customer_info?.cardNumber}`,
        paidBy: ` ${filteredItems[i]?.requestBody?.billInfo?.paidBy}`,
        paidAt: ` ${filteredItems[i]?.requestBody?.billInfo?.paidAt}`,
        paidDate: ` ${moment(filteredItems[i]?.requestBody?.billInfo?.paidDate).format("DD-MM-YYYY")}`,
          
           
        

        amount: ` ${filteredItems[i]?.transacionInfo?.amount}`,
        // total:Four24Count
     
      };
    }
  
    CvsData.push(dropdownList);
  }
  // //console.log(CvsData);
  // //console.log(ticketSupervisorReport)

  function convertArrayOfObjectsToCSV(array) {
    let result;
  
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData?.[0] || []);
  
    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    array.forEach((item, index) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
  
        result += item[key];
  
        ctr++;
      });
  
      // Add a column for the last total
      if (index === array.length - 1) {
        result += columnDelimiter;
        // result += `Total :${Four24Count}`;
      }
  
      result += lineDelimiter;
    });
  
    return result;
  }
  

  // ** Downloads CSV
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  const CvsData2 = [];
  for (let i = 0; i < filteredItems1?.length; i++) {
    // //console.log(filteredItems[i]._id);

    if (filteredItems1[i]?.emp?.empName) {
      var dropdownList = {
        EmployeeId:` ${filteredItems1[i]?.emp?.empNo}`,
        EmployeeName:` ${filteredItems1[i]?.emp?.empName}`,
        Phone: ` ${filteredItems1[i]?.emp?.mobile}`,
        Shift: ` ${filteredItems1[i]?.shift?.shiftName}`,
        TotalDays: ` ${filteredItems1[i]?.TotalDays}`,
        Presents: ` ${filteredItems1[i]?.presents}`,
        Lates: ` ${filteredItems1[i]?.lates }`,
        Absents: ` ${filteredItems1[i]?.absents}`
     
      };
    }
  
    CvsData2.push(dropdownList);
  }
  // //console.log(CvsData);
  // //console.log(ticketSupervisorReport)


  function convertArrayOfObjectsToCSV2(array) {
    let result;
  
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData2?.[0] || []);
  
    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    array.forEach((item, index) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
  
        result += item[key];
  
        ctr++;
      });
  
      // Add a column for the last total
      if (index === array.length - 1) {
        result += columnDelimiter;
       
      }
  
      result += lineDelimiter;
    });
  
    return result;
  }
  

  // ** Downloads CSV
  function downloadCSV2(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV2(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  const CvsData3 = [];
  for (let i = 0; i < filteredItems2?.length; i++) {
    // //console.log(filteredItems[i]._id);

    // if (filteredItems2[i]?.phoneNumber) {
      var dropdownList = {
        EmployeeId:` ${filteredItems1[i]?.emp?.empNo}`,
        EmployeeName:` ${filteredItems1[i]?.emp?.empName}`,
        Phone: ` ${filteredItems1[i]?.emp?.mobile}`,
        Shift: ` ${filteredItems1[i]?.shift?.shiftName}`,
        TotalDays: ` ${filteredItems1[i]?.TotalDays}`,
        Presents: ` ${filteredItems1[i]?.presents}`,
        Lates: ` ${filteredItems1[i]?.lates }`,
        Absents: ` ${filteredItems1[i]?.absents}`

     
      };
    // }
  
    CvsData3.push(dropdownList);
  }
  // //console.log(CvsData);
  // //console.log(ticketSupervisorReport)


  // function convertArrayOfObjectsToCSV3(array) {
  //   let result;
  
  //   const columnDelimiter = ",";
  //   const lineDelimiter = "\n";
  //   const keys = Object.keys(CvsData3?.[0] || []);
  
  //   result = "";
  //   result += keys.join(columnDelimiter);
  //   result += lineDelimiter;
  
  //   array.forEach((item, index) => {
  //     let ctr = 0;
  //     keys.forEach((key) => {
  //       if (ctr > 0) result += columnDelimiter;
  
  //       result += item[key];
  
  //       ctr++;
  //     });
  
  //     // Add a column for the last total
  //     if (index === array.length - 1) {
  //       result += columnDelimiter;
  //       result += `Total :${merchantTotal}`;
  //     }
  
  //     result += lineDelimiter;
  //   });
  
  //   return result;
  // }
  

  // ** Downloads CSV
  // function downloadCSV3(array) {
  //   const link = document.createElement("a");
  //   let csv = convertArrayOfObjectsToCSV3(array);
  //   if (csv === null) return;

  //   const filename = "export.csv";

  //   if (!csv.match(/^data:text\/csv/i)) {
  //     csv = `data:text/csv;charset=utf-8,${csv}`;
  //   }

  //   link.setAttribute("href", encodeURI(csv));
  //   link.setAttribute("download", filename);
  //   link.click();
  // }

  const Emp = [];
  Emp.push({ label: "all", value: "all" });
  // Emp.push({ label: "all", value: "all" });
  for (let i = 0; i < EmployeesByDepartments?.length; i++) {
    // //console.log(setEmployeeId[i]._id);

    var dropdownList = {
      value: EmployeesByDepartments[i]?.sqn,
      label: ` ${EmployeesByDepartments[i]?.empName}`,
    };

    Emp.push(dropdownList);
  }
  // //console.log(employeeId)
  const devicesList = [];

  for (let i = 0; i < devices?.length; i++) {
    // //console.log(setEmployeeId[i]._id);

    var list = {
      value: devices[i]?.ipAddress,
      label: ` ${devices[i]?.deviceName} -- ${devices[i]?.ipAddress}`,
    };

    devicesList.push(list);
  }

  const DepartmentsArr = [];
  DepartmentsArr.push({ label: "all", value: "all" });
  for (let i = 0; i < departments.length; i++) {
    // //console.log(departments[i]._id);

    var dropdownList2 = {
      value: departments[i]._id,
      label: ` ${departments[i].departmentName}`,
    };

    DepartmentsArr.push(dropdownList2);
  }
  const handleTypeSelect = (e) => {
    setEmployeeDepartment(e.value);
    fetchEmployeeDepartments(e.value);
  };

  const empArr = [];
  empArr.push({ label: "all", value: "all" });
  for (let i = 0; i < EmployeesByDepartments.length; i++) {
    // //console.log(EmployeesByDepartments[i]._id);

    var dropdownList = {
      value: EmployeesByDepartments[i]?._id,
      label: `${EmployeesByDepartments[i]?.empName}`,
      mobile: `${EmployeesByDepartments[i]?.mobile}`,
      titleId: `${EmployeesByDepartments[i]?.titleId}`,
      _id: `${EmployeesByDepartments[i]?._id}`,
    };
    //
    empArr.push(dropdownList);
  }

  // get attendance updates
  const getAttendanceUpdates = ()=>{
    fetchAttendanceUpdates()
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Collect Summary Attendance" pageTitle="Attendance" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-autod">
                      <div>
                        <ToastContainer />
                        <div className="">
                          <h4 className="card-title mb-0">Collect Summary Attendance</h4>
                          {/* <Button>Go Back</Button> */}

                          <div
                            className="form-label"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                         
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          
                          <Button
                            color="success"
                            className="add-btn me-1 mx-2"
                            onClick={() => downloadCSV2(CvsData2)}
                            id="create-btn"
                          >
                            <i
                              className="fa fa-print mx-2"
                              aria-hidden="true"
                            ></i>
                            Download Excell
                          </Button>
                        </div>
                      </div>
                    </Col>
                    {/* <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end"  style={{ display: "none" }}>
                        <div> <Button onClick={getAttendanceUpdates} className="add-btn mt-4 btn-info"><i className="ri-file-download-line align-bottom me-1 "></i>Dowmload {" "}Updates 
                         </Button > </div>
                      </div>
                    </Col> */}
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="mb-5">
                    <Col md={2}>
              <div className="mb-3">
                      <label className="form-label">
                        Device <span className="text-danger">*</span>
                      </label>
                        <Select
                          // className="bg-white"
                          options={devicesList}
                          onChange={(choice) => setDeviceId(choice)}
                          value={devicesList.filter(function (option) {
                            return option.value === deviceId?.value;
                          })}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                            },
                          })}
                        />
                    </div>
              </Col>
              <Col md={2}>
                <div className="mb-3">
                  <label>Select Department</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <Select
                    // className="bg-white"
                    options={DepartmentsArr}
                    onChange={handleTypeSelect}
                    value={DepartmentsArr.filter(function (option) {
                      return option.value === EmployeeDepartment;
                    })}
                    defaultValue={{
                      label: "Select Department",
                      value: EmployeeDepartment,
                    }}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        // text: "orangered",
                        // primary25: "hotpink",
                        // primary: "black",
                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={2}>
              <div className="mb-3">
                      <label className="form-label">
                        Select Employee <span className="text-danger">*</span>
                      </label>
                        <Select
                          // className="bg-white"
                          options={Emp}
                          onChange={(choice) => setEmployeeId(choice)}
                          value={Emp.filter(function (option) {
                            return option.value === employeeId?.value;
                          })}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                            },
                          })}
                        />
                    </div>
              </Col>
                      <Col md={2}>
                        <div style={{ display: "" }}>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            From Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStart(date)}
                            value={start}
                            
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div  style={{ display: "" }}>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            To Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEnd(date)}
                            value={end}
                          />
                        </div>
                      </Col>
                    

                      

                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4 btn-info"
                            onClick={() => filterThroughDate()}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Proceed
                          </Button>
                        </div>
                      </Col>
                    </Row>
              
            
                  <Row>
                  {isLoading ? (<SpinnerBorder/>) : (
                  <div id="customerList">
                    
                    <Row className="g-4 mb-3"></Row>
                   
                      <>
                    {monthylyAttendanceReport?.length > 0 && (
                      <TableContainer
                        columns={columns}
                        data={monthylyAttendanceReport || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                    
                        // handleCustomerClick={handleCustomerClicks}
                        // isCustomerFilter={true}
                      />
                    )}
                    </>
                    
                  </div>
                  ) }
                  </Row>
           

                   
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MonthlyAttendanceReport;
const SpinnerBorder = () => {
  return (
  <div style={{display:"flex", justifyContent:'center', alignItems:"center"}}>
   <Spinner className="my-2 text-center"  />;
  </div>
  )
};
