module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {

  //  API_URL: "http://localhost:5656/api/v1/",

   // API_URL: "https://beasalm24.asalmedia.net/api/v1/",

  //   correct one
 
  // API_URL: "https://beasalm24.asalmedia.net/api/v1/",
 

    API_URL: "https://asal24.tabaarak.net/api/v1/",

  },
};

// abdimalik5
// aBD@5510$$



