import React, { useState, useEffect, useContext } from "react";
import * as XLSX from "xlsx";
import text from "../../format.xlsx";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import Select from "react-select";
// import { Grid } from "gridjs";
import { Grid, _ } from "gridjs-react";

const SendSmsWithExpiredCards = () => {
  const [serviceList, setServiceList] = useState([
    {
      itemId: "",
      quantity: "",
      unitPrice: "",
      amount: "",
      description: "",
      QtyAvailable: "",
    },
  ]);

  const [itemData, setitemData] = useState();
  const [itemss, setItems] = useState([]);
  const [toggleTable, setToggleTable] = useState(false);

  ///local states

  const [type, setType] = useState("");
  const [date, setDate] = useState("");
  const [titles, setTitles] = useState("");
  const [customerInfo, setCustomerInfo] = useState();

  const [Qty, setQty] = useState("");
  const [modal_list, setmodal_list] = useState(false);
  const [yes, setYes] = useState(true);

  const [content, setcontent] = useState("");


  const {
  

    purchaseOrdersReceivers,
    purchaseOrdersRec,

    FetchSinglePurchaseReceiver,
    singlePurchaseOrderReceiver,
    registerReciever,
    uploadedErrors,
    isLoading,
    User,
    fetchEmpTitle,
    getAllTitles,
    fetchCorespondingEmp,
    corresponEmp,SendTheSms,getSingleEmployee,singleEmployee, fetchExpiredCustomerCards ,sendSmsWithExpiredCustomers, allExpredCustomerCards
  } = useContext(LoginContex);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  let yess = yes === "true";
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    fetchCorespondingEmp(titles?.value);
    fetchExpiredCustomerCards()
    fetchEmpTitle();
    if(customerInfo?.value !="all"){
      getSingleEmployee(customerInfo?.value)
    }
  }, [titles.value, customerInfo?.value]);

  //console.log(singlePurchaseOrderReceiver);

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;

  //console.log(purchaseOrdersRec);
  //console.log(titles.test);
  document.title = "Sms";

  // const amounts = serviceList[0].Qty;
  // //console.log(amounts);

  // //console.log(purchaseAmount);
  let userId = User?.data?.user?._id || User?.user?._id;

  // //console.log(userId);
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  // //console.log(
  //   Number(singlePurchaseOrderReceiver[0]?.quantity) -
  //     Number(singlePurchaseOrderReceiver[0]?.reciversUploaded)
  // );
  const handleSubmit = (e) => {
    e.preventDefault();
    // registerPurchaseOrder(data);

    if (!customerInfo.value || !date) {
      showToastMessage("Please provide required values");
      return;
    }
    if (!date) {
      showToastMessage("Please provide the date");
      return;
    }

    if (
      itemss.length >
      Number(singlePurchaseOrderReceiver[0]?.quantity) -
        Number(singlePurchaseOrderReceiver[0]?.reciversUploaded)
    ) {
      //console.log("erorrrrrrrrrrrrrrr");
      showToastMessage(
        `you recivers can not be above ${singlePurchaseOrderReceiver[0]?.quantity} `
      );
      return;
    }

    const data = {
      receiverDetails: itemss,
      purchaseId: titles.value,
      itemId: customerInfo.value,
      purchaseReceiveDate: date,
      titles: titles.test,
      userId,
    };
    registerReciever(data);
    //console.log(data);

    setDate("");
    setTitles("");
    setCustomerInfo("");
    setItems([]);
  };
  const suppliersArr = [];

  for (let i = 0; i < singlePurchaseOrderReceiver.length; i++) {
    //console.log(singlePurchaseOrderReceiver[i]._id);

    var dropdownList = {
      value: singlePurchaseOrderReceiver[i].itemId._id,
      label: singlePurchaseOrderReceiver[i].itemId.itemName,
    };

    suppliersArr.push(dropdownList);
  }
  // soloving dropdowns item info
  const itemArr = ["All","Single"];

  // soloving dropdowns item info
  const custArr = [];
  custArr.push({ label: "all", value: "all" });
  for (let i = 0; i < allExpredCustomerCards.length; i++) {
    // //console.log(allExpredCustomerCards[i]._id);

    var dropdownList = {
      value: allExpredCustomerCards[i]?._id,
      label: `${allExpredCustomerCards[i]?.customerName} -- ${allExpredCustomerCards[i]?.cardNumber}`,
      mobile: `${allExpredCustomerCards[i]?.customerPhone}`,
    //   titleId: `${allExpredCustomerCards[i]?.titleId}`,
      _id: `${allExpredCustomerCards[i]?._id}`,
    };
    //
    custArr.push(dropdownList);
  }

  // //console.log(custArr);

  const handleSubmites = ()=>{
    tog_delete()
  }
let singleeEmploye = [singleEmployee]
const fil = custArr.filter((item)=> item.value !=="all")
  const sendSms = ()=>{

    const data = {
      allCustomers:customerInfo?.label =="all" ? custArr.length > 0 ?fil :"all" :singleeEmploye,
      content:content,
      title:titles?.value ,
      custId:customerInfo?.value || "all",
      userId:userId
    }
    //console.log(data)
    // SendTheSms(data)
    setmodal_delete(false)
    // setmodal_list(false)

  }


  const filterArr = custArr?.find((item)=>{

    // //console.log(item)
    return item._id == customerInfo?.value
  })
  // //console.log(filterArr)
  // //console.log(customerInfo?.value)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Sms " pageTitle="Purchase" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">Sms</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div> <Button onClick={()=> tog_list(true)}>Send Sms</Button> </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchases Ordertable">
                    {/* <form>
                      <ModalBody>
                        <div
                          className="mb-3"
                          id="modal-id"
                          style={{ display: "none" }}
                        >
                          <label htmlFor="id-field" className="form-label">
                            ID
                          </label>
                          <input
                            type="text"
                            id="id-field"
                            className="form-control"
                            placeholder="ID"
                            readOnly
                          />
                        </div>
                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>
                                Titles
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                options={itemArr}
                                onChange={(choice) => setTitles(choice)}
                                value={itemArr.filter(function (option) {
                                  return option.value === titles?.value;
                                })}
                                required
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                  },
                                })}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>
                                Employees <span className="text-danger">*</span>
                              </label>
                              <Select
                                options={empArr}
                                onChange={(choice) => setCustomerInfo(choice)}
                                required
                                value={empArr.filter(function (option) {
                                  return option.value === customerInfo?.value;
                                })}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    // text: "orangered",
                                    // primary25: "hotpink",
                                    // primary: "black",
                                  },
                                })}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} className="input-control mt-5">
                            <div className="form-group">
                              <Input
                                type="textarea"
                                style={{
                                  backgroundColor: "#F0F0F0",
                                  color: "#333",
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                    </form> */}
                    <div className="table-card "></div>
                  </div>

                  {/* <div className="hstack gap-2 justify-content-end">
                    <button
                      type="button"
                      className="btn btn-success mt-5"
                      // id="add-btn"
                      onClick={handleSubmit}
                      disabled={isLoading}
                    >
                      {isLoading ? "please wait..." : "save"}
                    </button>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

    
  
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Send SMS To Customers With Expired Cards</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <form >
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              {/* <Col md={6}>
                <div className="mb-3">
                  <label>
                    Titles
                    <span className="text-danger">*</span>
                  </label>
                  <select
                         className="form-select "
                         id="itemtype"
                         onChange={(e)=>setType(e.target.value)}
                       >
                           <option value=''>Select Option</option>
                         <option value='All'>All Customers</option>
                         <option value='Single'>Single Customer</option>
                       
                       </select>
                </div>
              </Col> */}
              <Col md={6}>
                <div className="mb-3">
                  <label>
                    Customers <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={custArr}
                    onChange={(choice) => setCustomerInfo(choice)}
                    required
                    value={custArr.filter(function (option) {
                      return option.value === customerInfo?.value;
                    })}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        // text: "orangered",
                        // primary25: "hotpink",
                        // primary: "black",
                      },
                    })}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="input-control mt-5">
                <div className="form-group">
                  <Input
                    type="textarea"
                    style={{ backgroundColor: "#F0F0F0", color: "#333" }}
                    onChange={(e)=> setcontent(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>

              <button type="button" className="btn btn-success" id="add-btn" onClick={handleSubmites}>
               Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              {/* <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Send SMS to {titles?.label =="all" ?"all The Employes With Any titles" :`${titles?.label}`} {customerInfo =="all"?  "and all the employees":`${customerInfo?.label}`}   ?
              </p> */}
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={sendSms}
            >
              Yes, Send it!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default SendSmsWithExpiredCards;
