import React, { useState, useEffect, useContext, useMemo } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";

const Shifts = () => {


const[shiftName,setShiftName] =useState("")
const[fromTimeIn,setfromTimeIn] =useState("")
const[timeIn,setTimeIn] =useState()
const[fromLateTime,setfromLateTime] =useState("")
const[toTimeIn,settoTimeIn] =useState("")
const[fromTimeOut,setfromTimeOut] =useState("")
const[timeOut,seTtimeOut] =useState()
const[toTimeOut,settoTimeOut] =useState("")

  const [id, setId] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [yes, setYes] = useState("true");
 
  const [time, setTime] = useState("");
  const {

    isLoading,
    isAlert,

    User,registerShits,fetchShifts,shifts,deleteShifts,updateShifts
  } = useContext(LoginContex);
  //console.log(isAlert);
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  //console.log(yes);

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  //console.log(time);
//   //console.log(momentDate.format("HH:mm"));
  var momentDate = moment(time, "ddd MMM DD YYYY HH:mm:ss ZZ");
//console.log(momentDate.format("HH:mm"));
// //console.log(  )

  useEffect(() => {
    // readProducts();
    fetchShifts()
  }, [time]);

  document.title = "Shifts ";
  //console.log(shifts);

  //console.log(User?.data?.user?._id);
  let userId = User?.data?.user?._id || User?.user?._id;
  //console.log(userId);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "shiftName",
        filterable: false,
      },
      {
        Header: "From Time In",
        accessor: "fromTimeIn",
        filterable: false,
      },
      {
        Header: "Time In",
        accessor: "timeIn",
        filterable: false,
      },
      {
        Header: " From Late Time",
        accessor: "fromLateTime",
        filterable: false,
      },
      {
        Header: "To Time In ",
        accessor: "toTimeIn",
        filterable: false,
      },
      {
        Header: "From Time Out",
        accessor: "fromTimeOut",
        filterable: false,
      },
     
    
      {
        Header: "Time Out ",
        accessor: "timeOut",
        filterable: false,
      },
      {
        Header: "To Time Out ",
        accessor: "toTimeOut",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={(row) => {
                    const data = cellProps.row.original;
                    editPop(data);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={(row) => {
                    const data = cellProps.row.original;
                    deletPop(data);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ]
    // [handleCustomerClick]
  );

  let yess = yes === "true";
  // handle submit
  //console.log(yes);
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handlerSubmit = (e) => {
    e.preventDefault();
    if(isEditing ==false){

  
   
    let data = {
        shiftName:shiftName,
    fromTimeIn:moment(fromTimeIn).format("HH:mm"),
    timeIn:moment(timeIn).format("HH:mm"),
   
    fromLateTime: moment(fromLateTime).format("HH:mm"),
    toTimeIn: moment(toTimeIn).format("HH:mm"),
    fromTimeOut: moment(fromTimeOut).format("HH:mm"),
    timeOut: moment(timeOut).format("HH:mm"),
   
    toTimeOut: moment(toTimeOut).format("HH:mm")
    }
    //console.log(data)
   if(!shiftName || !fromTimeIn  ||!fromLateTime ||!toTimeIn ||!fromTimeOut  ||!toTimeOut) {
    showToastMessage("Please Provide all the required fields")
    return
   }

   registerShits(data)
   setShiftName("")
    e.target.reset()
   
    setmodal_list(false)
}else{
    
    let data = {
        shiftName:shiftName,
    fromTimeIn:moment(fromTimeIn).format("HH:mm") == "Invalid date" ? fromTimeIn:moment(fromTimeIn).format("HH:mm"),
    timeIn:moment(timeIn).format("HH:mm") == "Invalid date" ? timeIn:moment(timeIn).format("HH:mm"),
   
    fromLateTime: moment(fromLateTime).format("HH:mm") == "Invalid date" ? fromLateTime:moment(fromLateTime).format("HH:mm"),
    toTimeIn: moment(toTimeIn).format("HH:mm") == "Invalid date" ? toTimeIn:moment(toTimeIn).format("HH:mm"),
    fromTimeOut: moment(fromTimeOut).format("HH:mm") == "Invalid date" ? fromTimeOut:moment(fromTimeOut).format("HH:mm"),
    
    timeOut: moment(timeOut).format("HH:mm") == "Invalid date" ? timeOut:moment(timeOut).format("HH:mm"),
    toTimeOut: moment(toTimeOut).format("HH:mm") == "Invalid date" ? toTimeOut:moment(toTimeOut).format("HH:mm"),
    id:id
    }
    //console.log(timeOut)
   if(!shiftName || !fromTimeIn  || !fromLateTime || !toTimeIn || !fromTimeOut  || !toTimeOut) {
    showToastMessage("Please Provide all the required fields")
    return
   }
   //console.log(data)
   updateShifts(data)
   setShiftName("")
    e.target.reset()
    setmodal_list(false)
}



  };

  ///delete
  const deletPop = (data) => {
    setmodal_delete(true);
    setId(data._id);
    //console.log(data)
  };

  const deletProd = () => {
    deleteShifts(id);
    setmodal_delete(false);
  };

  const editPop = (row) => {
    //console.log(row);
    setmodal_list(true);
    setId(row._id);
    setShiftName(row.shiftName);
    setfromTimeIn(row.fromTimeIn);
    setTimeIn(row.timeIn);
    setfromLateTime(row.fromLateTime);
    setfromTimeOut(row.fromTimeOut);
    settoTimeIn(row.toTimeIn);
    seTtimeOut(row.timeOut);
    settoTimeOut(row.toTimeOut);
   
 
    setIsEditing(true);
 

  };

  const addModal = () => {
    tog_list();
    setIsEditing(false);
  setTimeIn("")
  setfromTimeIn("")
  setfromLateTime("")
  settoTimeIn("")
  settoTimeOut("")
  setShiftName("")
  setfromTimeOut("")
    setYes("true");
  };

  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      // use the selector function to resolve your field names by passing the sort comparitors
      const aField = selector(rowA);
      const bField = selector(rowB);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const load = () => {
    if (isLoading) {
      return (
        <>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ToastContainer />
                        <img
                          src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif"
                          style={{ backgroundColor: "white !", width: "700px" }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Shifts" pageTitle=" Registration" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h4 className="card-title mb-0">Add Item, Edit & Remove</h4> */}
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        {<ToastContainer />}
                        <div>
                          <subHeaderComponentMemo />
                          <h4 className="card-title mb-0">Add Shift</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  {/* {load()} */}
                  <div id="customerList">
                    <Row className="g-4 mb-3"></Row>
                    <div className="table-responsive table-card mt-3 mb-1"></div>
                  </div>
                  {/* <subHeaderComponentMemo /> */}
                  {/* {isLoading && (
                    <img
                      src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif"
                      style={{ backgroundColor: "white !", width: "700px" }}
                    />
                  )} */}
                  {shifts.length > 0 && (
                    <TableContainer
                      columns={columns}
                      data={shifts || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"

                      // handleCustomerClick={handleCustomerClicks}
                      // isCustomerFilter={true}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Shifts From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">
            {" "}
            {isEditing ? "update Shift" : "Add New Shift"}{" "}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={12}>
                <div className="mb-12">
                  <label htmlFor="itemType_id-field " className="form-label">
                   Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="itemName-field"
                    className="form-control"
                    placeholder="Enter shift Name"
                    
                    onChange={(e) => setShiftName(e.target.value)}
                    value={shiftName}
                  />
                </div>
              </Col>
              </Row>
              <Row>
              <Col md={3}>
              <div className="my-2">
                  <Label className="form-label mb-0">From Time In</Label>
                  <Flatpickr
                    placeholder="Select a time"
                    className="form-control"
                    options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i",
                        time_24hr: true,
                      
                      }}
                      onChange={([selectedDates]) => {
                        setfromTimeIn(selectedDates)
                        //console.log(selectedDates)
                      }}
                      value={fromTimeIn}
                  />
                </div>
              </Col>
              <Col md={3}>
              <div className="my-2">
                  <Label className="form-label mb-0">Time In</Label>
                  <Flatpickr
                    placeholder="Select a time"
                    className="form-control"
                    options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i",
                        time_24hr: true,
                      
                      }}
                      onChange={([selectedDates]) => {
                        setTimeIn(selectedDates)
                        //console.log(selectedDates)
                      }}
                      value={timeIn}
                  />
                </div>
              </Col>
              <Col md={3}>
              <div className="my-2">
                  <Label className="form-label mb-0">From late time</Label>
                  <Flatpickr
                    placeholder="Select a time"
                    className="form-control"
                    options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i",
                        time_24hr: true,
                      
                      }}
                      onChange={([selectedDates]) => {
                        setfromLateTime(selectedDates)
                        //console.log(selectedDates)
                      }}
                      value={fromLateTime}
                  />
                </div>
              </Col>
              <Col md={3}>
              <div className="my-2">
                  <Label className="form-label mb-0">To Time in</Label>
                  <Flatpickr
                    placeholder="Select a time"
                    className="form-control"
                    options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i",
                        time_24hr: true,
                      
                      }}
                      onChange={([selectedDates]) => {
                        settoTimeIn(selectedDates)
                        //console.log(selectedDates)
                      }}
                      value={toTimeIn}
                  />
                </div>
              </Col>
            </Row>
            <Row>
           
              <Col md={4}>
              <div className="my-2">
                  <Label className="form-label mb-0">From time out</Label>
                  <Flatpickr
                    placeholder="Select a time"
                    className="form-control"
                    options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i",
                        time_24hr: true,
                      
                      }}
                      onChange={([selectedDates]) => {
                        setfromTimeOut(selectedDates)
                        //console.log(selectedDates)
                      }}
                      value={fromTimeOut}
                  />
                </div>
              </Col>
              <Col md={4}>
              <div className="my-2">
                  <Label className="form-label mb-0">Time Out</Label>
                  <Flatpickr
                    placeholder="Select a time"
                    className="form-control"
                    options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i",
                        time_24hr: true,
                      
                      }}
                      onChange={([selectedDates]) => {
                        seTtimeOut(selectedDates)
                        //console.log(selectedDates)
                      }}
                      value={timeOut}
                  />
                </div>
              </Col>
            
              <Col md={4}>
              <div className="my-2">
                  <Label className="form-label mb-0">To time out</Label>
                  <Flatpickr
                   placeholder="Select a time"
                    className="form-control"
                    options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i",
                        time_24hr: true,
                      
                      }}
                      onChange={([selectedDates]) => {
                        settoTimeOut(selectedDates)
                        //console.log(selectedDates)
                      }}
                      value={toTimeOut}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
             
              </Col>
              <Col md={6}>
              {/* <div className="my-2">
                  <Label className="form-label mb-0">Time out</Label>
                  <Flatpickr
                    placeholder="Select a time"
                    className="form-control"
                    options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i",
                        time_24hr: true,
                      
                      }}
                      onChange={([selectedDates]) => {
                        settimeOut(selectedDates)
                        //console.log(selectedDates)
                      }}
                  />
                </div> */}
              </Col>
            </Row>
            <Row>
            {/* <Col md={12}>
              <div className="my-2">
                  <Label className="form-label mb-0">To time out</Label>
                  <Flatpickr
                   placeholder="Select a time"
                    className="form-control"
                    options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i",
                        time_24hr: true,
                      
                      }}
                      onChange={([selectedDates]) => {
                        settoTimeOut(selectedDates)
                        //console.log(selectedDates)
                      }}
                  />
                </div>
              </Col> */}
            </Row>
            <Row className="mt-3">
              
             

              
              {/* <Col lg={6}>
                <div>
                  <Label className="form-label mb-0">Timepicker</Label>
                  <Flatpickr
                  
                    className="form-control"
                    options={{
                        enableTime: true,
                        noCalendar: true,
                      
                      
                      }}
                      onChange={([selectedDates]) => {
                        settesting(selectedDates)
                        //console.log(selectedDates)
                      }}
                  />
                </div>
              </Col> */}
              {/* <Col lg={6}>
                <div>
                  <Label className="form-label mb-0">Timepicker</Label>
                  <Flatpickr
                    className="form-control"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      time_24hr: true,
                    }}
                    onChange={() => setTime(e.target.value)}
                  />
                </div>
              </Col> */}
              <Col lg={6}>
                {/* <div>
                  <Label className="form-label mb-0">Timepicker</Label>
                  <Input
                    type="time"
                    onChange={(e) => setTime(e.target.value)}
                    step="3600"
                    pattern="HH:mm"
                  />
                </div> */}
              </Col>
              {/* <Col lg={6}>
                <div>
                  <Label className="form-label mb-0">Timepicker</Label>
                  <Input
                    type="time"
                    onChange={(e) => setTime(e.target.value)}
                    step="3600"
                    pattern="HH:mm"
                  />
                </div>
              </Col> */}
              {/* <Col lg={6}>
                <div>
                  <label>Time:</label>
                  <input
                    type="time"
                    id="time"
                    name="time"
                    step="3600"
                    pattern="HH:mm"
                  />
                </div>
              </Col> */}
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isEditing ? "Update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={deletProd}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Shifts;
