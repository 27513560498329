import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LoginContex } from "../context/loginContext/LoginContext";

const Navdata = () => {
  const history = useHistory();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isBilling, setIsBilling] = useState(false);
  // const [isHR, setHR] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  const [isInventory, setIsInventory] = useState(false);
  const [isTicket, setIsTicket] = useState(false);
  const [isDhaweeye, setisDhaweeye] = useState(false);
  const [isIPTV, setisIPTV] = useState(false);
  const [isRedline, setisRedline] = useState(false);
  const [isHR, setIsHR] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);

  // Apps
  const [isEmail, setEmail] = useState(false);
  const [isSubEmail, setSubEmail] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isProjects, setIsProjects] = useState(false);
  const [isTasks, setIsTasks] = useState(false);
  const [isCRM, setIsCRM] = useState(false);
  const [isCrypto, setIsCrypto] = useState(false);
  const [isInvoices, setIsInvoices] = useState(false);
  const [isSupportTickets, setIsSupportTickets] = useState(false);
  const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);

  // Authentication
  const [isSignIn, setIsSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isPasswordCreate, setIsPasswordCreate] = useState(false);
  const [isLockScreen, setIsLockScreen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isError, setIsError] = useState(false);

  // Pages
  const [isProfile, setIsProfile] = useState(false);
  const [isLanding, setIsLanding] = useState(false);

  // Charts
  const [isApex, setIsApex] = useState(false);

  // Multi Level
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevel2, setIsLevel2] = useState(false);
  const {
    menus,
    fetchMenus,
    isLoading,
    User,
    sidebarMenus,
    fetchSidebarMenus,
  } = useContext(LoginContex);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  // //console.log(User?.user?._id);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  // //console.log(sidebarMenus);
  useEffect(() => {
    fetchSidebarMenus(User?.data?.user?._id);
  }, [User]);
  // //console.log(User?.data?.user?._id);
  // //console.log(User);
  useEffect(() => {
    fetchMenus();

    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
    if (iscurrentState !== "vendors") {
      setIsVendor(false);
    }
    if (iscurrentState !== "Billing") {
      setIsBilling(false);
    }
    if (iscurrentState !== "Ticket") {
      setIsTicket(false);
    }
    if (iscurrentState !== "olddhaweeye") {
      setisDhaweeye(false);
    }
    
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
    if (iscurrentState !== "Inventory") {
      setIsInventory(false);
    }
    if (iscurrentState !== "HR") {
      setIsHR(false);
    }
    if (iscurrentState !== "Setting") {
      setIsSetting(false);
    }
    if (iscurrentState !== "BaseUi") {
      setIsBaseUi(false);
    }
    if (iscurrentState !== "AdvanceUi") {
      setIsAdvanceUi(false);
    }
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    if (iscurrentState !== "Charts") {
      setIsCharts(false);
    }
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }
    if (iscurrentState !== "MuliLevel") {
      setIsMultiLevel(false);
    }
    if (iscurrentState === "Widgets") {
      history.push("/widgets");
      document.body.classList.add("twocolumn-panel");
    }
    if (iscurrentState !== "Landing") {
      setIsLanding(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isVendor,
    isBilling,
    isTicket,
    isHR,
    isSetting,
    isReport,
    isInventory,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
  ]);

  const demoMenus = [
    {
      id: "dashboard",
      label: "Dashboards",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
    },
    {
      id: "regestaration",
      label: "Dashboards",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
    },
    {
      id: "supplier",
      label: "suplier",
      icon: "ri-dashboard-2-line",
      link: "/registerSupplier",
    },
    {
      id: "upload customers",
      label: "Dashboards",
      icon: "ri-dashboard-2-line",
      link: "/uploadCustomers",
    },
    {
      id: "store",
      label: "store",
      icon: "ri-dashboard-2-line",
      link: "/registerStore",
    },
  ];

  const looop = menus.map((menu) => {
    return menu;
  });

  const arr = [];
  // for (let index = 0; index < menus.length; index++) {
  const ob = {
    id: "dashboard",
    label: "Dashboards",
    icon: "ri-dashboard-2-line",
    link: "/dashboard",
    stateVariables: isDashboard,
    click: function (e) {
      e.preventDefault();
      setIsDashboard(!isDashboard);
      setIscurrentState("Dashboard");
      updateIconSidebar(e);
    },
  };

  arr.push(ob);
  // }

  let apps1 = "apps";
  let apps2 = "Apps";
  let EmptyStatus = "";
  let Purchase1 = "prchs";
  let Purchase2 = "vendors";
  let billing1 = "billing";
  let billing2 = "Billing";
  let Inventory = "Inventory";
  let Ticket = "Ticket";
  let dhaweeye = "olddhaweeye";
  let HR = "HR";
  let IPTV = "IP TV"; 
  let REDLINETV = "REDLINE TV"; 
  let Setting1 = "Settings";
  let Setting2 = "Setting";
  let Reportcenter = "Report center";

  // let Inventory2 = "Inventory";
  const dashboard = {
    id: "dashboard",
    label: "Dashboards",
    icon: "ri-dashboard-fill",
    link: "/dashboard",
    stateVariables: isDashboard,
    click: function (e) {
      e.preventDefault();
      setIsDashboard(!isDashboard);
      setIscurrentState("Dashboard");
      updateIconSidebar(e);
    },
  };
  const reportCenter = {
    id: "Reports",
    label: "Reports",
    icon: "ri-file-chart-fill",
    link: "/report",
    stateVariables: isDashboard,
    click: function (e) {
      e.preventDefault();
      setIsDashboard(!isDashboard);
      setIscurrentState("Dashboard");
      updateIconSidebar(e);
    },
  };

  let y = [];

  // //console.log(menus);
  const serialNumber = 0;
  const ar = [];
  let side = sidebarMenus.sort(function (a, b) {
    return a - b;
  });
  const m =  side?.map((menu, index) =>{
    let VariablesState;
    const MainMenu = {
      id:
        menu?.title === "Registration"
          ? apps2
          : menu?.title === "Purchase"
          ? Purchase1
          : menu?.title === "Billing"
          ? billing1
          : menu?.title === "Inventory"
          ? Inventory
          : menu?.title === "Ticket"
          ? Ticket
          : menu?.title === "HR"
          ? HR
          : menu?.title === "Settings"
          ? Setting1
          : menu?.title === "Report center"
          ? Reportcenter
          : menu?.title === "Old Dhaweeye"
          ? dhaweeye
          : EmptyStatus,
      label: menu?.title,
      icon: menu?.icon,
      link: menu?.url,

      click: function (e) {
        if (menu?.title === "dashboard") {
          e.preventDefault();
          setIsDashboard(!isDashboard);
          setIscurrentState("Dashboard");
          updateIconSidebar(e);
        } 
      
        else {
          e.preventDefault();
          let currentState = "";
          if (menu?.title === "Registration") {
            setIsApps(!isApps);
            currentState = apps2;
            // VariablesState = isApps;
          }
          if (menu?.title === "Purchase") {
            setIsVendor(!isVendor);
            currentState = Purchase2;
            // VariablesState = isVendor;
          }

          if (menu?.title === "Billing") {
            setIsBilling(!isBilling);
            currentState = billing2;
            // VariablesState = isBilling;
          }

          if (menu?.title === "Inventory") {
            setIsInventory(!isInventory);
            currentState = Inventory;
            // VariablesState = isInventory;
          }

          if (menu?.title === "Ticket") {
            setIsTicket(!isTicket);
            currentState = Ticket;
            // VariablesState = isTicket;
          }
          if (menu?.title === "Old Dhaweeye") {
            setisDhaweeye(!isDhaweeye);
            currentState = dhaweeye;
            // VariablesState = isTicket;
          }
          if (menu?.title === "IP TV") {
            setisIPTV(!isIPTV);
            currentState = IPTV;
            // VariablesState = isTicket;
          }
          if (menu?.title === "REDLINE TV") {
            setisRedline(!isRedline);
            currentState = REDLINETV;
            // VariablesState = isTicket;
          }

          
          if (menu?.title === "HR") {
            setIsHR(!isHR);
            currentState = HR;
            // VariablesState = isHR;
          }

          if (menu?.title === "Settings") {
            setIsSetting(!isSetting);
            currentState = Setting2;
            // VariablesState = isHR;
          }

          if (menu?.title === "Report center") {
            setIsReport(!isReport);
            currentState = Reportcenter;
            // VariablesState = isHR;
          }

          setIscurrentState(currentState);
          updateIconSidebar(e);
        }
      },

      subItems: menu?.subItems,
      stateVariables:
        menu?.title === "Registration"
          ? isApps
          : menu?.title === "Purchase"
          ? isVendor
          : menu?.title === "dashboard"
          ? isDashboard
          : menu?.title === "Billing"
          ? isBilling
          : menu?.title === "Inventory"
          ? isInventory
          : menu?.title === "Ticket"
          ? isTicket
          : menu?.title === "HR"
          ? isHR
          : menu?.title === "Settings"
          ? isSetting
          : menu?.title === "Report center"
          ? isReport
          : menu?.title === "Old Dhaweeye"
          ? isDhaweeye

          : menu?.title === "IP TV"
          ? isIPTV 
          : menu?.title === "REDLINE TV"
          ? isRedline 
          : false,
          
    };

    if (index === 0) {
      ar.push(dashboard);
    }
    // if (index == 8) {
    //   ar.push(reportCenter);
    // }

    
    ar.push(MainMenu);
  });

  // //console.log("Ahmed Hussein: ", m);
  // //console.log(ar);

  const MainMenu = {
    id: apps1,
    label: menus[1]?.title,
    icon: menus[1]?.icon,
    link: menus[1]?.url,
    click: function (e) {
      e.preventDefault();
      setIsApps(!isApps);
      setIscurrentState(apps2);
      updateIconSidebar(e);
    },
    subItems: [
      {
        id: "items",
        label: "Items",
        link: "/registerItem",
        parentId: "dashboard",
      },
      {
        id: "Suppliers",
        label: "Suppliers",
        link: "/registerSupplier",
        parentId: "dashboard",
      },
      {
        id: "customers",
        label: "Customers",
        link: "/registerCustomer",
        parentId: "dashboard",
      },
      {
        id: "upload customers",
        label: "upload customers",
        link: "/uploadCustomers",
        parentId: "dashboard",
      },
      {
        id: "Sites",
        label: "Sites",
        link: "/registerSites",
        parentId: "dashboard",
      },
      {
        id: "stores",
        label: "Stores",
        link: "/registerStore",
        parentId: "dashboard",
      },
      {
        id: "Agents",
        label: "Sales Agent",
        link: "/Agents",
        parentId: "dashboard",
      },

      {
        id: "Channel Package",
        label: "Channel Package",
        link: "/ChannelPackage",
        parentId: "dashboard",
      },
    ],

    stateVariables: isApps,
  };
  // //console.log(MainMenu);
  // //console.log(arr[0]);
  // //console.log(isLoading);

  // if (isLoading) {
  //   return;
  // }

  const sub = [];
  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },

    {
      id: "dashboard",
      label: "Dashboards",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },

    // arr[0],

    MainMenu,
    // {
    //   id: "apps",
    //   label: "Registration",
    //   icon: "ri-apps-2-line",
    //   link: "/list",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsApps(!isApps);
    //     setIscurrentState("Apps");
    //     updateIconSidebar(e);
    //   },
    //   subItems: [
    //     {
    //       id: "items",
    //       label: "Items",
    //       link: "/registerItem",
    //       parentId: "dashboard",
    //     },
    //     {
    //       id: "Suppliers",
    //       label: "Suppliers",
    //       link: "/registerSupplier",
    //       parentId: "dashboard",
    //     },
    //     {
    //       id: "customers",
    //       label: "Customers",
    //       link: "/registerCustomer",
    //       parentId: "dashboard",
    //     },
    //     {
    //       id: "upload customers",
    //       label: "upload customers",
    //       link: "/uploadCustomers",
    //       parentId: "dashboard",
    //     },
    //     {
    //       id: "Sites",
    //       label: "Sites",
    //       link: "/registerSites",
    //       parentId: "dashboard",
    //     },
    //     {
    //       id: "stores",
    //       label: "Stores",
    //       link: "/registerStore",
    //       parentId: "dashboard",
    //     },
    //     {
    //       id: "Agents",
    //       label: "Sales Agent",
    //       link: "/Agents",
    //       parentId: "dashboard",
    //     },

    //     {
    //       id: "Channel Package",
    //       label: "Channel Package",
    //       link: "/ChannelPackage",
    //       parentId: "dashboard",
    //     },
    //   ],
    //   stateVariables: isApps,
    // },
    // {
    //   id: Purchase1,
    //   label: "Purchase",
    //   icon: "ri-stack-line",
    //   link: "/vendors",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsVendor(!isVendor);
    //     setIscurrentState(Purchase2);
    //     updateIconSidebar(e);
    //   },

    //   subItems: [
    //     {
    //       id: "Purchases Order",
    //       label: "Purchases Order",
    //       link: "/PurchasesOrder",
    //       parentId: "dashboard",
    //     },

    //     {
    //       id: "Manage Purchase Order",
    //       label: "Pending Orders",
    //       link: "/ManagePurchaseOrder",
    //       parentId: "dashboard",
    //     },

    //     // {
    //     //   id: "Purchase Recieve",
    //     //   label: "Purchase Recieve",
    //     //   link: "/purchaseReceive",
    //     //   parentId: "vendors",
    //     // },
    //     {
    //       id: "Upload Recivers",
    //       label: "Upload Recivers",
    //       link: "/UploadRecivers",
    //       parentId: "vendors",
    //     },

    //     // {
    //     //   id: "Add Receiver Detials",
    //     //   label: "Add Receiver Detials",
    //     //   link: "/AddReciver",
    //     //   parentId: "dashboard",
    //     // },

    //     {
    //       id: "Payment",
    //       label: "Vendor Payment",
    //       link: "/Payment",
    //       parentId: "dashboard",
    //     },

    //     {
    //       id: "Manage purchase",
    //       label: "Manage Purchase",
    //       link: "/ManagePurchase",
    //       parentId: "dashboard",
    //     },
    //     // {
    //     //   id: "Manage Purchase Order",
    //     //   label: "Manage Purchase Order",
    //     //   link: "/ManagePurchaseOrder",
    //     //   parentId: "dashboard",
    //     // },
    //   ],
    //   stateVariables: isVendor,
    // },
    {
      id: "billing",
      label: "Billing",
      icon: "ri-pencil-ruler-2-line",
      link: "/Billing",
      click: function (e) {
        e.preventDefault();
        setIsBilling(!isBilling);
        setIscurrentState("Billing");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "New Cable",
          label: "New Cable",
          link: "/NewCable",
          parentId: "dashboard",
        },
        {
          id: "Manage Cables",
          label: "Manage Cables",
          link: "/ManageCables",
          parentId: "dashboard",
        },
        {
          id: "IP-TV",
          label: "IP-TV",
          link: "/CreateIPTV",
          parentId: "dashboard",
        },
        {
          id: "Auto Charge",
          label: "Auto Charge",
          link: "/AutoCharge",
          parentId: "dashboard",
        },
        {
          id: "Item Sales",
          label: "Item Sales",
          link: "/ItemSales",
          parentId: "dashboard",
        },
        {
          id: "Open Closed Card ",
          label: "Open Card ",
          link: "/OpenClosedCard",
          parentId: "dashboard",
        },
        {
          id: " Close Opened Card ",
          label: "Close Card ",
          link: "/CloseOpenedCard",
          parentId: "dashboard",
        },
      ],
      stateVariables: isBilling,
    },
    {
      id: "Inventory",
      label: "Inventory",
      icon: "ri-pages-line",
      link: "/Inventories",
      click: function (e) {
        e.preventDefault();
        setIsInventory(!isInventory);
        setIscurrentState("Inventory");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "Item Distribution",
          label: "Item Transfer",
          link: "/ItemDistripution",
          parentId: "dashboard",
        },
        {
          id: "Receiver Distribution",
          label: "Receiver Distribution",
          link: "/ReceiverDistribution",
          parentId: "dashboard",
        },
        {
          id: "Manage Inventory",
          label: "Manage Inventory",
          link: "/ManageInventory",
          parentId: "dashboard",
        },
      ],
      stateVariables: isInventory,
    },
    {
      id: "Ticket",
      label: "Ticket",
      icon: " ri-pantone-line",
      link: "/Ticket",
      click: function (e) {
        e.preventDefault();
        setIsTicket(!isTicket);
        setIscurrentState("Ticket");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "Create Ticket",
          label: "Create Ticket",
          link: "/CreateTicket",
          parentId: "dashboard",
        },
        {
          id: "Resolve Ticket",
          label: "Resolve Ticket",
          link: "/ResolveTicket",
          parentId: "dashboard",
        },
        {
          id: "Manage Tickets",
          label: "Manage Tickets",
          link: "/ManageTickets",
          parentId: "dashboard",
        },
      ],
      stateVariables: isTicket,
    },
    {
      id: "HR",
      label: "HR",
      icon: "ri-user-2-line",
      link: "/top",
      click: function (e) {
        e.preventDefault();
        setIsHR(!isHR);
        setIscurrentState("HR");
        updateIconSidebar(e);
      },

      subItems: [
        {
          id: "Employee",
          label: "Employee",
          link: "/EmployeeRegistration",
          parentId: "HR",
        },
        {
          id: "Assign Groups",
          label: "Assign Groups",
          link: "/AssignGroups",
          parentId: "HR",
        },
        {
          id: "Advance",
          label: "Advance",
          link: "/Advance",
          parentId: "HR",
        },
        {
          id: "Deduction",
          label: "Deduction",
          link: "/Deduction",
          parentId: "HR",
        },

        {
          id: "Generate Payroll",
          label: "Generate Payroll",
          link: "/GeneratePayroll",
          parentId: "HR",
        },
        {
          id: "Manage Payroll",
          label: "Manage Payroll",
          link: "/ManagePayroll",
          parentId: "HR",
        },
        {
          id: "Master File",
          label: "Master File ",
          link: "/MasterFile",
          parentId: "HR",
        },
      ],
      stateVariables: isHR,
    },

    {
      id: "Settings",
      label: "Settings",
      icon: "ri-rocket-line",
      link: "/Manageusers",

      click: function (e) {
        e.preventDefault();
        setIsSetting(!isSetting);
        setIscurrentState("Setting");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "Create User",
          label: "Create User",
          link: "/CreateUsers",
          parentId: "setting",
        },
        {
          id: "Setup",
          label: "Setups",
          link: "/Promotion",
          parentId: "setting",
        },
        {
          id: "User Role",
          label: "User Roles",
          link: "/UserRole",
          parentId: "setting",
        },
      ],
      stateVariables: isSetting,
    },
    {
      id: "Report center",
      label: "Report center",
      icon: "ri-layout-grid-line",
      link: "/report",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsReport(!isReport);
        setIscurrentState("Report center");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "Inventories Report",
          label: "Inventories Report",
          link: "/InventriesReport",
          parentId: "report center",
        },
        {
          id: "Purchase Report",
          label: "Purchase Report",
          link: "/PurchaseReport",
          parentId: "report center",
        },
        {
          id: "Sales Report",
          label: "Sales Report",
          link: "/SalesReport",
          parentId: "report center",
        },
      ],
      stateVariables: isReport,
    },
  ];

  // //console.log(menuItems);
  return <React.Fragment>{ar}</React.Fragment>;
};
export default Navdata;
