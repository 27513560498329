import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { array } from "yup";
const Stores = () => {
  const [storeName, setstoreName] = useState("");
  const [storeLocation, srtstoreLocation] = useState("");
  const [storeDescription, setstoreDescription] = useState("");
  const [isPrimaryStore, setisPrimaryStore] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [yes, setYes] = useState(true);
  const [id, setId] = useState("");
  // const [no, setNo] = useState(false);
  const [test, setTest] = useState();
  const [dab, setDab] = useState();

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
    { value: "vani", label: "vani" },
    { value: "vash", label: "vash" },
  ];

  //console.log(test);
  const { registerStore, fetchStores, stores, deleteStores, upadteStore } =
    useContext(LoginContex);
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  // //console.log(yes, true);
  let yess = yes === "true";
  //console.log(yess);
  useEffect(() => {
    fetchStores();
  }, []);
  useEffect(() => {
    const attroptions = {
      valueNames: [
        "name",
        "born",
        {
          data: ["id"],
        },
        {
          attr: "src",
          name: "image",
        },
        {
          attr: "href",
          name: "link",
        },
        {
          attr: "data-timestamp",
          name: "timestamp",
        },
      ],
    };

    // pagination list

    new List("pagination-list", {
      valueNames: ["pagi-list"],
      page: 3,
      pagination: true,
    });
  });
  //console.log(stores);

  const newArray = [{ label: "Choose item", value: "" }];

  //   stores.forEach((st)=>{
  //   })

  for (let i = 0; i < stores.length; i++) {
    //console.log(stores[i]._id);

    var dropdownList = {
      value: stores[i]._id,
      label: stores[i].storeName,
    };

    newArray.push(dropdownList);

    // setDab(newArray);
  }

  //console.log(newArray);
  document.title = "Store Registration ";

  const handleSubmit = (e) => {
    //console.log("ddddddddddddd");
    e.preventDefault();
    if (!isEditing) {
      const data = {
        storeDescription,
        storeName,
        storeLocation,
        isPrimaryStore: yess,
      };
      if (!storeName || !storeLocation) {
        return;
      }
      registerStore(data);
      setmodal_list(false);
      setstoreName("");
      srtstoreLocation("");
      setstoreDescription("");
    }
    if (isEditing) {
      const data = {
        storeDescription,
        storeName,
        storeLocation,
        isPrimaryStore: yess,
        id: id,
      };
      if (!storeName || !storeLocation) {
        return;
      }
      upadteStore(data);
      setmodal_list(false);
      setstoreName("");
      srtstoreLocation("");
      setstoreDescription("");
    }
  };

  const deleteStore = (data) => {
    setmodal_delete(true);
    setId(data._id);
  };

  const delStore = () => {
    deleteStores(id);
    setmodal_delete(false);
  };

  //updateees
  // //console.log(isEditing);
  const updateForm = (data) => {
    setmodal_list(true);
    setstoreName(data.storeName);
    srtstoreLocation(data.storeLocation);
    setstoreDescription(data.storeDescription);
    setYes(yes);
    setEditing(true);
    setId(data._id);
  };
  const addModal = () => {
    tog_list();
    setEditing(false);
    setstoreName("");
    srtstoreLocation("");
    setstoreDescription("");
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Store Registration" pageTitle="Registration" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">Add Store</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="StoreList">
                    <Row className="g-4 mb-3"></Row>
                    <Select
                      options={newArray}
                      onChange={(choice) => setTest(choice)}
                    />

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="StoreTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "5px" }}></th>

                            <th className="sort" data-sort="store_name">
                              Store Name
                            </th>
                            <th className="sort" data-sort="location">
                              Location
                            </th>
                            <th className="sort" data-sort="description">
                              Description
                            </th>

                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {stores?.map((data, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row"></th>
                                <td className="id" style={{ display: "none" }}>
                                  <Link
                                    to="#"
                                    className="fw-medium link-primary"
                                  >
                                    #VZ2104
                                  </Link>
                                </td>
                                <td className="Store_name">
                                  {" "}
                                  {data.storeName}{" "}
                                </td>
                                <td className="location">
                                  {" "}
                                  {data.storeLocation}{" "}
                                </td>
                                <td className="description">
                                  {" "}
                                  {data.storeDescription}{" "}
                                </td>

                                <td>
                                  <div className="d-flex gap-2">
                                    <div className="edit">
                                      <button
                                        className="btn btn-sm btn-success edit-item-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showModal"
                                        onClick={() => updateForm(data)}
                                      >
                                        Edit
                                      </button>
                                    </div>
                                    <div className="remove">
                                      <button
                                        className="btn btn-sm btn-danger remove-item-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteRecordModal"
                                        onClick={() => deleteStore(data)}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#121331,secondary:#08a88a"
                            style={{ width: "75px", height: "75px" }}
                          ></lord-icon>
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">
                            We've searched more than 150+ Orders We did not find
                            any orders for you search.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Add New Store</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="store_name-field" className="form-label">
                    Store Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="store_name-field"
                    className="form-control"
                    placeholder="Enter Store Name"
                    required
                    onChange={(e) => setstoreName(e.target.value)}
                    value={storeName}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    Store Location <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="location-field"
                    className="form-control"
                    placeholder="Enter Location"
                    required
                    onChange={(e) => srtstoreLocation(e.target.value)}
                    value={storeLocation}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div>
                  <label className="form-label">Is Primary?</label>
                  <div className="mb-3">
                    <Label className="form-check-label" htmlFor="is_yes">
                      <Input
                        className="form-control-input"
                        type="radio"
                        name="prim"
                        id="is_yes"
                        value={true}
                        onChange={(e) => setYes(e.target.value)}
                      />
                      &nbsp;Yes
                    </Label>
                  </div>
                </div>
                <div className="mb-3">
                  <Label className="form-check-label" htmlFor="is_no">
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="prim"
                      defaultChecked
                      id="is_no"
                      value={false}
                      onChange={(e) => setYes(e.target.value)}
                    />
                    &nbsp; No
                  </Label>
                </div>
              </Col>
              <Col md={10}>
                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <textarea
                    className="form-control"
                    id="Description-field"
                    rows="1"
                    onChange={(e) => setstoreDescription(e.target.value)}
                    value={storeDescription}
                  ></textarea>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isEditing ? "update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => delStore()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Stores;
