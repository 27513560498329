import React, { useState, useEffect, useContext } from "react";
import * as XLSX from "xlsx";
import text from "../../format.xlsx";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import Select from "react-select";
// import { Grid } from "gridjs";
import { Grid, _ } from "gridjs-react";
import  axios  from 'axios';

const SendEmployeeToAttendence = () => {
  const [serviceList, setServiceList] = useState([
    {
      itemId: "",
      quantity: "",
      unitPrice: "",
      amount: "",
      description: "",
      QtyAvailable: "",
    },
  ]);

  const [itemData, setitemData] = useState();
  const [itemss, setItems] = useState([]);
  const [toggleTable, setToggleTable] = useState(false);

  ///local states
  const [EmployeeDepartment, setEmployeeDepartment] = useState("");
  const [todaydata, setTodayDate] = useState("");
  const [date, setDate] = useState("");
  const [titles, setTitles] = useState("");
  const [employeeInfo, setEmployeInfo] = useState();

  const [Qty, setQty] = useState("");
  const [modal_list, setmodal_list] = useState(false);
  const [yes, setYes] = useState(true);

  const [content, setcontent] = useState("");


  const {
    departments,
    purchaseOrdersRec,
    singlePurchaseOrderReceiver,
    registerReciever,
    fetchDepartment,
    isLoading,
    User,
    fetchEmpTitle,
    getAllTitles,
    fetchCorespondingEmp,
    corresponEmp,SendTheSms,getSingleEmployee,singleEmployee, getEmployee,
    fetchEmployees, enrollSingleEmployee,  fetchDevices,fetchEmployeeDepartments,EmployeesByDepartments,
    devices,
  } = useContext(LoginContex);
  const [employeeId, setEmployeeId] = useState([]);
  const [deviceId, setDeviceId] = useState("");
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  let yess = yes === "true";
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  //console.log(EmployeesByDepartments)
  useEffect(() => {
    fetchDevices()
    // fetchEmpTitle();
    fetchDepartment();
    if(employeeInfo?.value !="all"){
      getSingleEmployee(employeeInfo?.value)
    }
    fetchEmployees()
  }, [titles.value, employeeInfo?.value]);

  ////console.log(singlePurchaseOrderReceiver);

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;

  // //console.log(purchaseOrdersRec);
  // //console.log(titles.test);
  document.title = "Enroll Fingerprint";

  // const amounts = serviceList[0].Qty;
  // //console.log(amounts);

  // //console.log(purchaseAmount);
  let userId = User?.data?.user?._id || User?.user?._id;

  //console.log(userId);
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  // //console.log(
  //   Number(singlePurchaseOrderReceiver[0]?.quantity) -
  //     Number(singlePurchaseOrderReceiver[0]?.reciversUploaded)
  // );
  const handleSubmit = (e) => {
    e.preventDefault();
    // registerPurchaseOrder(data);

    if (!titles.value || !employeeInfo.value || !date) {
      showToastMessage("Please provide required values");
      return;
    }
    if (!date) {
      showToastMessage("Please provide the date");
      return;
    }

    if (
      itemss.length >
      Number(singlePurchaseOrderReceiver[0]?.quantity) -
        Number(singlePurchaseOrderReceiver[0]?.reciversUploaded)
    ) {
      //console.log("erorrrrrrrrrrrrrrr");
      showToastMessage(
        `you recivers can not be above ${singlePurchaseOrderReceiver[0]?.quantity} `
      );
      return;
    }

    const data = {
      receiverDetails: itemss,
      purchaseId: titles.value,
      itemId: employeeInfo.value,
      purchaseReceiveDate: date,
      titles: titles.test,
      userId,
    };
    registerReciever(data);
    //console.log(data);

    setDate("");
    setTitles("");
    setEmployeInfo("");
    setItems([]);
  };
  const suppliersArr = [];

  for (let i = 0; i < singlePurchaseOrderReceiver.length; i++) {
    //console.log(singlePurchaseOrderReceiver[i]._id);

    var dropdownList = {
      value: singlePurchaseOrderReceiver[i].itemId._id,
      label: singlePurchaseOrderReceiver[i].itemId.itemName,
    };

    suppliersArr.push(dropdownList);
  }
  // soloving dropdowns item info
  const itemArr = [];
  itemArr.push({ label: "all", value: "all" });
  for (let i = 0; i < getAllTitles.length; i++) {
    //console.log(getAllTitles[i]._id);

    var dropdownList = {
      value: getAllTitles[i]?._id,
      label: ` ${getAllTitles[i]?.title} `,
    };
    //
    itemArr.push(dropdownList);
  }
  // soloving dropdowns item info
  const empArr = [];
  empArr.push({ label: "all", value: "all" });
  for (let i = 0; i < EmployeesByDepartments.length; i++) {
    //console.log(EmployeesByDepartments[i]._id);

    var dropdownList = {
      value: EmployeesByDepartments[i]?._id,
      label: `${EmployeesByDepartments[i]?.empName}`,
      mobile: `${EmployeesByDepartments[i]?.mobile}`,
      titleId: `${EmployeesByDepartments[i]?.titleId}`,
      _id: `${EmployeesByDepartments[i]?._id}`,
    };
    //
    empArr.push(dropdownList);
  }

  const DepartmentsArr = [];
  DepartmentsArr.push({ label: "all", value: "all" });
  for (let i = 0; i < departments.length; i++) {
    // //console.log(departments[i]._id);

    var dropdownList = {
      value: departments[i]._id,
      label: ` ${departments[i].departmentName}`,
    };

    DepartmentsArr.push(dropdownList);
  }
  //console.log(empArr);
  const handleTypeSelect = (e) => {
    setEmployeeDepartment(e.value);
    fetchEmployeeDepartments(e.value);
  };
  const handleSubmites = ()=>{
    
 
    tog_delete()
  }
let singleeEmploye = [singleEmployee]
const fil = empArr.filter((item)=> item.value !=="all")
  const enrollEmployee = ()=>{
    let data;
    
    const employee = getEmployee?.filter(emp => emp._id == employeeId?.value);
    if(EmployeeDepartment?.value == "all" && employeeId?.value == "all"){
      data = {
        allEmployees: getEmployee,
        ipAddress:deviceId?.value
      }
    }else{
      data = {
        allEmployees: employeeId?.value == "all"? EmployeesByDepartments : employee,
        ipAddress:deviceId?.value
      }
    }
    // //console.log(data)
    // let info = employeeId?.value == "all"?  EmployeesByDepartments : employee;
    // //console.log(data)
    //  data = {
    //   allEmployees: employeeId?.value == "all"? getEmployee : employee,
    //   ipAddress:deviceId?.value
    // }

    enrollSingleEmployee(data)
    // // SendTheSms(data)
    setmodal_delete(false)
    setmodal_list(false)

  }


  const filterArr = empArr?.find((item)=>{

    //console.log(item)
    return item._id == employeeInfo?.value
  })
  //console.log(filterArr)
  //console.log(employeeInfo?.value)

  const Emp = [];
  Emp.push({ label: "all", value: "all" });
  for (let i = 0; i < EmployeesByDepartments?.length; i++) {
    // //console.log(setEmployeeId[i]._id);

    var dropdownList = {
      value: EmployeesByDepartments[i]?._id,
      label: ` ${EmployeesByDepartments[i]?.empName}`,
    };

    Emp.push(dropdownList);
  }
  const devicesList = [];

  for (let i = 0; i < devices?.length; i++) {
    // //console.log(setEmployeeId[i]._id);

    var list = {
      value: devices[i]?.ipAddress,
      label: ` ${devices[i]?.deviceName} -- ${devices[i]?.ipAddress}`,
    };

    devicesList.push(list);
  }


  const checkConnection = async()=>{
    try {
      const res = await axios.get(`/attendances/check/connection/${deviceId?.value}`)
      //console.log(res)
      if(res.status === 'fail'){
        toast.error(res.message)
      }
      if(res.status === 'success' ){
        toast.success(res.message)
      }
        
      
     
    } catch (error) {
      // //console.log(error.message)
      toast.error('error occurred')
    }
  }
// //console.log(deviceId.value)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Enroll Employee Fingerprint " pageTitle="Enroll fingerprint" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">Enroll Employee Fingerprint</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div> <Button onClick={()=> tog_list(true)}>Send</Button> </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchases Ordertable">
                    {/* <form>
                      <ModalBody>
                        <div
                          className="mb-3"
                          id="modal-id"
                          style={{ display: "none" }}
                        >
                          <label htmlFor="id-field" className="form-label">
                            ID
                          </label>
                          <input
                            type="text"
                            id="id-field"
                            className="form-control"
                            placeholder="ID"
                            readOnly
                          />
                        </div>
                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>
                                Titles
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                options={itemArr}
                                onChange={(choice) => setTitles(choice)}
                                value={itemArr.filter(function (option) {
                                  return option.value === titles?.value;
                                })}
                                required
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                  },
                                })}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>
                                Employees <span className="text-danger">*</span>
                              </label>
                              <Select
                                options={empArr}
                                onChange={(choice) => setEmployeInfo(choice)}
                                required
                                value={empArr.filter(function (option) {
                                  return option.value === employeeInfo?.value;
                                })}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    // text: "orangered",
                                    // primary25: "hotpink",
                                    // primary: "black",
                                  },
                                })}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} className="input-control mt-5">
                            <div className="form-group">
                              <Input
                                type="textarea"
                                style={{
                                  backgroundColor: "#F0F0F0",
                                  color: "#333",
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                    </form> */}
                    <div className="table-card "></div>
                  </div>

                  {/* <div className="hstack gap-2 justify-content-end">
                    <button
                      type="button"
                      className="btn btn-success mt-5"
                      // id="add-btn"
                      onClick={handleSubmit}
                      disabled={isLoading}
                    >
                      {isLoading ? "please wait..." : "save"}
                    </button>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

    
  
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Finger Ready For Registeration</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <form >
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
              <div className="mb-3">
                      <label className="form-label">
                        Device <span className="text-danger">*</span>
                      </label>
                        <Select
                          // className="bg-white"
                          options={devicesList}
                          onChange={(choice) => setDeviceId(choice)}
                          value={devicesList.filter(function (option) {
                            return option.value === deviceId?.value;
                          })}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                            },
                          })}
                        />
                    </div>
              </Col>
              <Col md={4}>
              <div className="mb-3">
              <label className="form-label"><span className="text-danger">*</span></label>
                      <div><button type="button" className="btn btn-primary" onClick={checkConnection}>CHECK CONNECTION</button></div>
                    </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label>Select Department</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <Select
                    // className="bg-white"
                    options={DepartmentsArr}
                    onChange={handleTypeSelect}
                    value={DepartmentsArr.filter(function (option) {
                      return option.value === EmployeeDepartment;
                    })}
                    defaultValue={{
                      label: "Select Department",
                      value: EmployeeDepartment,
                    }}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        // text: "orangered",
                        // primary25: "hotpink",
                        // primary: "black",
                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={4}>
              <div className="mb-3">
                      <label className="form-label">
                        Select Employee <span className="text-danger">*</span>
                      </label>
                        <Select
                          // className="bg-white"
                          options={Emp}
                          onChange={(choice) => setEmployeeId(choice)}
                          value={Emp.filter(function (option) {
                            return option.value === employeeId?.value;
                          })}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                            },
                          })}
                        />
                    </div>
              </Col>
             
              {/* <Col md={6}>
                <div className="mb-3">
                  <label>
                    Employees <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={empArr}
                    onChange={(choice) => setEmployeInfo(choice)}
                    required
                    value={empArr.filter(function (option) {
                      return option.value === employeeInfo?.value;
                    })}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        // text: "orangered",
                        // primary25: "hotpink",
                        // primary: "black",
                      },
                    })}
                  />
                </div>
              </Col> */}
            </Row>
            <Row>
              {/* <Col md={12} className="input-control mt-5">
                <div className="form-group">
                  <Input
                    type="textarea"
                    style={{ backgroundColor: "#F0F0F0", color: "#333" }}
                    onChange={(e)=> setcontent(e.target.value)}
                  />
                </div>
              </Col> */}
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>

              <button type="button" className="btn btn-success" id="add-btn" onClick={handleSubmites}>
               Apply
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Enroll this employee {employeeId?.label}
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={enrollEmployee}
            >
              Yes, Send it!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default SendEmployeeToAttendence;
