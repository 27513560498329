// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* StyledTable.module.css */\n.StyledTable_table__rRk3J {\n    width: 60%;\n    border-collapse: collapse;\n    padding: 8rem;\n  }\n  \n  .StyledTable_table__rRk3J th,\n  .StyledTable_table__rRk3J td {\n    padding: 8px;\n    text-align: left;\n    border-bottom: 1px solid #ddd;\n  }\n  \n  .StyledTable_table__rRk3J th {\n    background-color: #f2f2f2;\n  }\n  \n  .StyledTable_table__rRk3J tbody tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  ", "",{"version":3,"sources":["webpack://./src/pages/RevenueReport/StyledTable.module.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;IACI,UAAU;IACV,yBAAyB;IACzB,aAAa;EACf;;EAEA;;IAEE,YAAY;IACZ,gBAAgB;IAChB,6BAA6B;EAC/B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* StyledTable.module.css */\n.table {\n    width: 60%;\n    border-collapse: collapse;\n    padding: 8rem;\n  }\n  \n  .table th,\n  .table td {\n    padding: 8px;\n    text-align: left;\n    border-bottom: 1px solid #ddd;\n  }\n  \n  .table th {\n    background-color: #f2f2f2;\n  }\n  \n  .table tbody tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "StyledTable_table__rRk3J"
};
export default ___CSS_LOADER_EXPORT___;
