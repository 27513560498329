import React, { useState, useEffect, useContext, useMemo } from "react";

import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import moment from "moment";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { Autocomplete, TextField } from "@mui/material";
const FourTwoFourRePort = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardPackage, setCardPackage] = useState("");
  const [customer, setCustomer] = useState();
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [openDate, setOpenDate] = useState("");
  const [closeDate, setCloseDate] = useState("");
  const [usingDays, setUsingDays] = useState("");
  const [amount, setAmount] = useState(8);
  const [Discription, setdescription] = useState("");
  const { openAnalogCardRegister } = useContext(LoginContex);
  const [type, setTyp] = useState("text");
  const [customerInfo, setCustomerInfo] = useState();
  const [customerId, setCustomerId] = useState();
  const [Custom, setCustom] = useState("text");
  const [channelId, setChanell] = useState();
  const [cycle, setCycle] = useState("");
  const [numberOfDays, setNumberOfDays] = useState();
  const [numberOfMonths, setNumberOfMonths] = useState();
  const [billAmount, setBillAmount] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [helper, setHelper] = useState(false);
  const [userId, setUserId] = useState(false);
  const [CustometINFO, setCustometINFO] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [modal_delete, setmodal_delete] = useState(false);

  document.title = "424 Report";

  const {
    fetchOpenCards,
    OpenCards,
    cardList,
    channelPackage,
    fetchChanelPackages,
    closeAnalogCard,
    FetchOpenCardsReport,
    openCardsReport,
    FetchAllOpenCardsReport,
    allopenCardReport,
    FetchSummaryCableReport,
    summaryCableReport,
    FetchSummaryCloseReport,
    summaryCloseReport,
    fetchCustomers,
    customers,
    fetchCustomerListReport,
    customerListReport,
    allUsers,fetchBothCustomers,bothcustomers,isLoading,Fetchcustomer424Report,customer424Report
  } = useContext(LoginContex);
  const currentDate = new Date(); // Get current date
  const currentYear = currentDate.getFullYear(); // Get current year
  const currentMonth = currentDate.getMonth(); // Get current month
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1); // Create new Date object with the first day of current month
  // const startDate = firstDayOfMonth.toDateString(); // Convert the first day of current month to a string in the desired format
  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  //console.log(startDate)
  const formatedDate = moment(startDate).format(
    "YYYY-MM-DD"
  );  

  //console.log(formatedDate); // Output the start date of the current month in the console
  const maanta = new Date();
  const tariikhdamanta = moment(maanta[0]).format(
    "YYYY-MM-DD"
  );
//console.log(tariikhdamanta)
  useEffect(() => {
    // FetchAllOpenCardsReport();
    // FetchSummaryCableReport();
    fetchCustomers();
    FetchSummaryCloseReport();

    const startt = moment(start).format("YYYY-MM-DD");
    const enddd = moment(end).format("YYYY-MM-DD");
    fetchCustomerListReport(startt, enddd, customerId?.value);
    Fetchcustomer424Report(startDate,tariikhdamanta,'all')
  }, []);

  //console.log(customers);

  //console.log(openDate);
  //console.log(start);
  //console.log(end);
  //console.log(customerListReport);
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "customer_info.customerName",
        filterable: false,
      },
      {
        Header: "PhoneNumber",
        accessor: "customer_info.customerPhone",
        filterable: false,
      },
      {
        Header: "Address",
        accessor: "customer_info.customerAddress",
        filterable: false,
      },
      {
        Header: "Paid Number",
        accessor: "requestBody.billInfo.paidBy",
        filterable: false,
      },
      {
        Header: "cardNumber",
        accessor: "customer_info.cardNumber",
        filterable: false,
      },
      {
        Header: "Amount",
        accessor: "transacionInfo.amount",
        filterable: false,
      },

     
      {
        Header: "Reg Date",
        accessor: "createdAt",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
    ]
    // [handleCustomerClick]
  );
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handlerSubmitOpen = (e) => {
    //console.log(e);
    e.preventDefault();
    if (
      !customerInfo?.cardNumber ||
      !customerInfo?.receiverType ||
      !customerInfo?.value ||
      !customerInfo?.value ||
      !channelId ||
      !cycle ||
      !openDate ||
      !billAmount
    ) {
      showToastMessage("please provide required values");
      return;
    }
    //console.log(numberOfMonths);
    //console.log(closeDate);
    //console.log();
    //console.log("first");
    const data = {
      cardNumber: customerInfo?.cardNumber,
      receivertype: customerInfo?.receiverType,
      customerId: customerInfo?.value,
      noOfMonthDay: cycle == "month" ? numberOfMonths : numberOfDays,
      endDate:
        cycle === "month"
          ? addMonths(Number(numberOfMonths), dateee)
          : addDays(Number(numberOfDays)),
      startDate: openDate,

      openDate: openDate,
      billingCycle: cycle,
      billAmount: billAmount,
      channelPackageId: channelId,
    };
    e.target.reset();
    openAnalogCardRegister(data);
    setmodal_list(false);
    //console.log(data);

    // showToastMessage("succefully opened card");
  };

  const editPop = (data) => {
    //console.log(data);
    setmodal_list(true);
    setCardNumber(data.cardNumber);
    setBillingCycle(data.billingCycle);
    setCardType(data.receiverType);
    setOpenDate(data.open_date);
    // setCloseDate(data.closeDate);
    setCustomer(data.customerId.customerName);
    setCardPackage(data.channel_package);
  };

  const setbilling = (e) => {
    setBillingCycle(e);

    //console.log(e);
    let type = e;

    if (type === "Custom") {
      // document.getElementById("customfield").style.display = "block";
      // document.getElementById("billamount").setAttribute("md={4}");
    } else {
      // document.getElementById("customfield").style.display = "none";
      // document.getElementById("billamount").setAttribute("md={8}");
    }
  };

  let neeew = "11-11-2022";
  if (billingCycle === "monthly") {
    //console.log(openDate);
    const test = moment(openDate).utc().format("MM/DD/YYYY");
    //console.log(test);
    neeew = new Date(test);
    neeew.setDate(neeew.getDate() + 30);

    //console.log(neeew);
  }

  const customizeDate = (month = 0, dayy = 0) => {
    //console.log(month);
    const test = moment(openDate).utc().format("MM/DD/YYYY");
    const yep = new Date(test);
    const day = yep.getDate() + 1 + Number(dayy);
    const moonth = `${yep.setMonth(yep.getMonth() + Number(month))}`;
    const year = yep.getFullYear();

    //console.log(day, moonth, year);

    const foor = day + "-" + moonth + "-" + year;
    const f = moment(foor).format("D/MM/YYYY");

    setCloseDate(f);
    // //console.log(yep.getMonth());
  };

  //console.log(openDate);

  function dateWithMonthsDelay(months) {
    //console.log(months);
    const date = new Date(openDate);
    date.setMonth(date.getMonth() + months);
    // setCloseDate(date);

    return date;
  }

  const filterThroughDate = () => {
    setHelper(true);
    const startt = moment(start).format("YYYY-MM-DD");
    const enddd = moment(end).format("YYYY-MM-DD");
    Fetchcustomer424Report(startt, enddd, selectedOption?._id);
  };
//console.log(customer424Report)
//console.log( selectedOption?._id)
  const clearFilter = () => {
    setHelper(false);
    setStart("");
    setEnd("");
    setCustomerId("");
    setUserId("");
  };
  //console.log(closeDate);

  // //console.log();
  // //console.log(neeew);
  //console.log("type is" + typeof neeew);

  const chan = () => {
    setTyp("date");
  };

  //console.log(customer);
  const CustomersArr = [];

  for (let i = 0; i < customers.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: customers[i]._id,
      label: ` ${customers[i].customerName}`,
    };

    CustomersArr.push(dropdownList);
  }
  // const sortCard = [];
  // for (let i = 0; i < OpenCards?.length; i++) {
  //   //console.log(OpenCards[i]?._id);
  //   let openDate = OpenCards[i].openDate.split("T");
  //   const formattedDate = moment(openDate[0]).format("DD-MM-YYYY");
  //   let endDate = OpenCards[i].endDate.split("T");
  //   const formattedDate2 = moment(endDate[0]).format("DD-MM-YYYY");
  //   let singleArr = [
  //     i + 1,
  //     OpenCards?.[i]?.customerId?.customerName,
  //     OpenCards[i]?.customerId?.customerPhone,
  //     OpenCards[i]?.customerId?.customerAddress,

  //     OpenCards[i]?.cardNumber,
  //     OpenCards[i]?.receivertype,
  //     formattedDate,
  //     formattedDate2,
  //     OpenCards[i]?._id,
  //   ];

  //   sortCard.push(singleArr);
  // }

  // //console.log(sortCard);

  const customerCards = [];

  for (let i = 0; i < cardList.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: cardList?.[i]?.dataCustomers?._id,
      cardNumber: cardList?.[i]?.cardNumber,
      receiverType: cardList?.[i]?.datareceivers?.itemType,
      label: cardList?.[i]?.dataCustomers?.customerName,
    };

    customerCards.push(dropdownList);
  }

  //console.log(cardList);

  function addMonths(numOfMonths, date = new Date()) {
    //console.log(numOfMonths);
    date.setMonth(date.getMonth() + numOfMonths);
    setCloseDate(date);

    return date;
  }

  function addDays(days) {
    var result = new Date(openDate);
    result.setDate(result.getDate() + days);
    setCloseDate(result);
    return result;
  }

  // //console.log(closeDate);
  //console.log(closeDate);
  // 👇️ Add 2 months to current Date
  // const result = addMonths(2);

  // 👇️ Add months to another date
  const dateee = new Date(openDate);
  const someFn = (row) => {
    //console.log(row);
    setmodal_delete(true);
    const selectedRow = {
      name: row.customerName,
      address: row.customerAddress,
      cardNumber: row.cardNumber,
      receiverType: row.receiverType,
      _id: row._id,
      openDate: row.openDate,
      closeDate: row.endDate,
    };
    setInfo(selectedRow);
  };

  //console.log(info);
  const closeCard = () => {
    const data = {
      id: info._id,
      description: status,
    };
    //console.log(data);
    closeAnalogCard(data);
    setStatus("");
    setmodal_delete(false);
  };
  const Users = [];

  for (let i = 0; i < allUsers.length; i++) {
    // //console.log(setUserId[i]._id);

    var dropdownList = {
      value: allUsers[i]?._id,
      label: ` ${allUsers[i]?.username}`,
    };

    Users.push(dropdownList);
  }

  const getOptionSelected = (option, value) => {
    //console.log(option);
    //console.log(value);
    if (option && value) {
      return option.id === value.id;
    }
    return false;
  };
  //console.log(getOptionSelected());
  //console.log(selectedOption);
  const onInputChange2 = (event, value, reason) => {
    //console.log(value);
    //console.log(event);
    setCustometINFO(value);
    fetchBothCustomers(value);

    const matchingOption = makeItArray2.find(
      (option) =>
        option.customers.toLowerCase() ==
        value.substring(0, value.indexOf("-")).toLowerCase()
    );

    setSelectedOption(matchingOption);
  };

  const makeItArray2 = [];

  for (let i = 0; i < bothcustomers?.length; i++) {
    // //console.log("from: " + formattedDate);
    let singleArr = {
      customers: bothcustomers[i]?.customerName,
      customerPhone: bothcustomers[i]?.customerPhone,
      _id: bothcustomers[i]?._id,
      customerAddress: bothcustomers[i]?.customerAddress,
      districtName: bothcustomers[i]?.districtName,
    };

    makeItArray2.push(singleArr);
  }
  //console.log(makeItArray2);
  const CvsData = [];
  for (let i = 0; i < customer424Report?.length; i++) {
    // //console.log(customer424Report[i]._id);

 
      var dropdownList = {
        Customer: customer424Report[i]?.customer_info?.customerName,
        customerPhone: ` ${customer424Report[i]?.customer_info?.customerPhone}`,
        CustomerTell: ` ${customer424Report[i]?.customer_info.customerTell}`,
        cardNumber: ` ${customer424Report[i]?.customer_info.cardNumber}`,
        amount: ` ${customer424Report[i]?.transacionInfo.amount}`,
        Date: ` ${moment(customer424Report[i]?.createdAt).utc().format("DD-MM-YYYY")}`,
       
  
      }
   

    CvsData.push(dropdownList);
  }

  //console.log(CvsData)
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData?.[0] || []);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // ** Downloads CSV
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="424 Report" pageTitle="Report" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-autol">
                      <div>
                        <ToastContainer />
                        <div className="">
                          <h4 className="card-title mb-0">424 Report</h4>
                          <div className="form-label" style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end"}}>
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={()=>{
                              downloadCSV(CvsData)
                            }}
                            type="button"
                            //  id="create-btn"
                          > 
                            <i className="fa fa-print mx-2" aria-hidden="true"> </i>  
                            Download Excel
                          </Button>
                        </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="mb-5">
                      {/* <Col md={2}>
                        <div>
                          <label>
                            Customers <span className="text-danger">*</span>
                          </label>
                          <Select
                            options={CustomersArr}
                            // onChange={(choice) => setDistrictId(choice)}
                            required
                            onChange={(choice) => setCustomerId(choice)}
                            value={CustomersArr.filter(function (option) {
                              return option.value === customerId?.value;
                            })}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                // text: "orangered",
                                // primary25: "hotpink",
                                // primary: "black",
                              },
                            })}
                          />
                        </div>
                      </Col> */}
                      {/* <Col md={2}>
                        <div>
                          <label>
                            User <span className="text-danger">*</span>
                          </label>
                          <Select
                            // className="bg-white"
                            options={Users}
                            onChange={(choice) => {
                              setUserId(choice);
                              // setChecked(temenu);
                            }}
                            value={Users.filter(function (option) {
                              return option.value === userId?.value;
                            })}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                              },
                            })}
                          />
                        </div>{" "}
                      </Col> */}

                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Customer <span className="text-danger">*</span>
                          </Label>
                          <Autocomplete
                            // disabled={isLoading}
                            disablePortal
                            id="combo-box-demo"
                            size="small"
                            options={makeItArray2}
                            getOptionSelected={getOptionSelected}
                            getOptionLabel={(option) => {
                              return `${option.customers?.toString()}-${option.customerPhone?.toString()}`;
                            }}
                            value={selectedOption}
                            onInputChange={onInputChange2}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search Customer..."
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Start Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStart(date)}
                            value={start}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            End Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEnd(date)}
                            value={end}
                          />
                        </div>
                      </Col>

                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={filterThroughDate}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Filter By Date{" "}
                          </Button>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={clearFilter}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Clear filter{" "}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <TableContainer
                      columns={columns}
                      data={customer424Report}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      
                      // handleCustomerClick={handleCustomerClicks}
                      // isCustomerFilter={true}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Open Closed Card Distribution From */}

      {/* Remove Modal */}
    </React.Fragment>
  );
};

export default FourTwoFourRePort;
