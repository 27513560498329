import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";

const SalesForm = () => {
  const [salesDate, setSalesDate] = useState();
  const [salesType, setSalesType] = useState();
  const [phoneNumber, setsetPhoneNumber] = useState();
  const [rrr, setrr] = useState();
  const [description, setdescription] = useState("");
  const [typeId, setTypeId] = useState();
  const [customerId, setSupplierId] = useState();
  const [serviceList, setServiceList] = useState([
    {
      description: "",
      itemId: "",
      quantity: "",
      unitPrice: "",
      amount: "",
      cardNumberDe: "",
    },
  ]);

  ///local states

  const [todaydata, setTodayDate] = useState("");
  const [date, setDate] = useState("");

  const [amount, setAmount] = useState();
  const [additianalCost, setAdditianalCost] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [QtyAvailable, setQtyAvailable] = useState("");
  const [Qty, setQty] = useState("");
  const [modal_list, setmodal_list] = useState(false);
  const [yes, setYes] = useState(true);
  const [storeId, setStoreId] = useState("");
  const [siteId, setSiteId] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [description2, setdescription2] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [totalCards, setTotalCards] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [_id, set_ID] = useState("");
  const [channelPackagee, setChannelPackage] = useState("");
  const [remark, setRemark] = useState();
  const [receiverType, setRecieverType] = useState();
  const [cardNo, setCardNo] = useState("");

  const {
    suppliers,
    items,
    fetchItems,
    stores,
    fetchStores,
    sites,
    fetchSites,
    readProducts,
    products,
    registerPurchase,
    registerPurchaseReturn,
    registerCustomer,
    fetchCustomers,
    customers,
    deleteCustomer,
    updateCustomer,
    fetchAgents,
    agents,
    registerSales,
    channelPackage,
    fetchChanelPackages,
    getSingleCustomer,
    singleCustomer,
    getSiteCards,
    singleSiteCard,
  } = useContext(LoginContex);

  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  let yess = yes === "true";
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    fetchItems();
    fetchStores();
    fetchSites();
    readProducts();
    fetchAgents();
    fetchCustomers();
    fetchChanelPackages();
    setTodayDate(today);
    getSingleCustomer(customerId);
    getSiteCards(typeId);
  }, [typeId, customerId]);

  //console.log(singleSiteCard);
  //console.log(singleCustomer);
  //

  ///date format

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;

  //console.log(typeId);

  document.title = "Purchase  ";

  const amounts = serviceList[0].Qty;
  // //console.log(amounts);

  serviceList.forEach((ca) => {
    for (const item in ca) {
      //console.log("h");
    }
  });
  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,
      {
        itemId: "",
        quantity: "",
        unitPrice: "",
        amount: "",
        description: "",
        cardNumberDe: "",
      },
    ]);
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };
  const purchaseAmount = serviceList.reduce((accu, curr) => {
    return accu + curr["amount"];
  }, 0);
  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    list[index]["amount"] =
      serviceList[index]["quantity"] * serviceList[index]["unitPrice"];
    list[index]["purchaseAmount"] = purchaseAmount;
    setServiceList(list);
    // handleServiceAdd();
    if (e.target.name === "itemId") {
      handleServiceAdd();
    }
  };

  //console.log(serviceList);
  // setServiceList([...serviceList,])

  // //console.log(purchaseAmount);

  const totalAmount =
    purchaseAmount + Number(additianalCost) - Number(discount);

  //the big object

  const data = {
    saleDate: salesDate,
    customerId: customerId,
    // description: description,
    isCash: true,
    description: description,
    referenceNo: referenceNo,
    totalAmount: purchaseAmount,
    saleDetails: serviceList,
    cashAccount: 500,
    discount,
    saleAmount: purchaseAmount,
    salesType,
    typeId,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    registerSales(data);

    //console.log(data);
  };
  //console.log(isUpdating);
  const handlerSubmit = (e) => {
    e.preventDefault();

    if (!isUpdating) {
      // const data = { customerAddress, customerName, customerPhone, totalCards };

      // if (!customerAddress || !customerName || !customerPhone || !totalCards) {
      //   return;
      // }
      setmodal_list(false);
      //console.log(data);
    }
  };

  //delet customer

  const deleteCus = (data) => {
    setmodal_delete(true);
    set_ID(data._id);
  };
  const deleteCustomerSuccess = () => {
    deleteCustomer(_id);
    setmodal_delete(false);
  };

  // update form

  const updateForm = (data) => {
    setmodal_list(true);
    setCustomerAddress(data.customerAddress);
    setCustomerName(data.customerName);
    setCustomerPhone(data.customerPhone);
    setTotalCards(data.totalCards);
    setIsUpdating(true);
    set_ID(data._id);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Sales Form" pageTitle="Sales Form" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <h4 className="card-title mb-0">add new cable</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            // onClick={() => tog_list()}
                            //  id="create-btn"
                          >
                            <i className=" ri-pencil-line align-bottom me-1"></i>
                            <Link to="/Sales" className="text-white">
                              Manage Cables
                            </Link>
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <form onSubmit={handleSubmit}>
                    <ModalBody>
                      <div
                        className="mb-3"
                        id="modal-id"
                        style={{ display: "none" }}
                      >
                        <label htmlFor="id-field" className="form-label">
                          ID
                        </label>
                        <input
                          type="text"
                          id="id-field"
                          className="form-control"
                          placeholder="ID"
                          readOnly
                        />
                      </div>
                      {/* <Row> */}
                      <Row className="mb-3">
                        <Col lg={1}>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Register Date
                          </Label>
                        </Col>
                        <Col lg={3}>
                          <Input
                            type="date"
                            className="form-control"
                            id="PurchasesDate"
                            value={salesDate}
                            onChange={(e) => setSalesDate(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={1}>
                          <label>Customer</label>
                        </Col>
                        <Col lg={3}>
                          <select
                            className="form-select"
                            id="itemtype"
                            onChange={(e) => setSupplierId(e.target.value)}
                          >
                            {customers?.map((customer, index) => {
                              return (
                                <option key={index} value={customer._id}>
                                  {customer.customerName}
                                </option>
                              );
                            })}
                          </select>
                        </Col>
                        <Col>
                          <Button
                            size="sm"
                            color="success"
                            className="add-btn me-1"
                            onClick={() => tog_list()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                          </Button>
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={1}>
                          <label>Agent Type</label> <br />
                        </Col>
                        <Col md={3}>
                          <select
                            className="form-select "
                            id="itemtype"
                            onChange={(e) => setSalesType(e.target.value)}
                          >
                            <option>choose an option</option>
                            <option value="Store">Store</option>
                            <option value="Site">Site</option>
                            <option value="Agent">Agent</option>
                          </select>
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={1}>
                          <label>{salesType} </label>
                        </Col>
                        <Col md={3} className="">
                          <select
                            className="form-select "
                            id="itemtype"
                            onChange={(e) => setTypeId(e.target.value)}
                          >
                            <option>select an option</option>
                            {salesType == "Store" &&
                              stores?.map((store) => {
                                return (
                                  <option key={store._id} value={store._id}>
                                    {" "}
                                    {store.storeName}{" "}
                                  </option>
                                );
                              })}
                            {salesType == "Agent" &&
                              agents?.map((agent) => {
                                return (
                                  <option key={agent._id} value={agent._id}>
                                    {" "}
                                    {agent.salesAgentName}{" "}
                                  </option>
                                );
                              })}
                            {salesType == "Site" &&
                              sites?.map((site) => {
                                return (
                                  <option key={site._id} value={site._id}>
                                    {" "}
                                    {site.siteName}{" "}
                                  </option>
                                );
                              })}
                          </select>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={1}>
                          <label className="form-label">Card No</label>
                        </Col>
                        <Col lg={3}>
                          <select
                            className="form-select "
                            id="itemtype"
                            onChange={(e) => setCardNo(e.target.value)}
                          >
                            {singleSiteCard &&
                              singleSiteCard?.map((single, index) => {
                                return (
                                  <option key={index} value={single.cardNumber}>
                                    {" "}
                                    {single.cardNumber}{" "}
                                  </option>
                                );
                              })}
                          </select>
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={1}>
                          <label>Channel Package</label> <br />
                        </Col>
                        <Col md={3}>
                          <select
                            className="form-select "
                            id="itemtype"
                            onChange={(e) => setChannelPackage(e.target.value)}
                          >
                            {channelPackage.map((channel, index) => {
                              return (
                                <option key={index} value={channel._id}>
                                  {" "}
                                  {channel.channelPackageName}{" "}
                                </option>
                              );
                            })}
                          </select>
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={1}>
                          <label>Reciever Type</label> <br />
                        </Col>
                        <Col md={3}>
                          <select
                            className="form-select "
                            onChange={(e) => setRecieverType(e.target.value)}
                          >
                            <option>choose an option</option>
                            <option value="API TV">API TV</option>
                            <option value="Anolog">Anolog</option>
                            <option value="OTT">OTT</option>
                          </select>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={1}>
                          <label className="form-label">Remark</label>
                          <label className="form-label">Reference No</label>
                        </Col>
                        <Col lg={3}>
                          <input
                            type="number"
                            id="referenceno-field"
                            className="form-control"
                            placeholder="Enter Reference No"
                            required
                            onChange={(e) => setReferenceNo(e.target.value)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col lg={1}>
                          <label className="form-label">Description</label>
                        </Col>
                        <Col lg={3}>
                          <textarea
                            className="form-control"
                            id="Description-field"
                            rows="1"
                            required
                            onChange={(e) => setRemark(e.target.value)}
                            placeholder="enter description"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={1}>
                          <label className="form-label">phone number</label>
                        </Col>
                        <Col lg={3}>
                          <input
                            className="form-control"
                            id="Description-field"
                            rows="1"
                            type="number"
                            placeholder="enter phone nubmer"
                            required
                            onChange={(e) => setsetPhoneNumber(e.target.value)}
                            defaultValue={singleCustomer.customerPhone}
                            value={phoneNumber}
                            // onChange={(e) => setdescription(e.target.value)}
                          />
                        </Col>
                      </Row>

                      <Row></Row>
                      <table
                        className="table align-middle table-nowrap"
                        id="purchase"
                      >
                        <thead className="table-light">
                          <tr>
                            {/* <th scope="col" style={{ width: "50px" }}></th> */}
                            <th className="sort" data-sort="item">
                              Item Name
                            </th>
                            {/* <th className="sort" data-sort="qty">
                              Qty On Hand
                            </th> */}
                            <th className="sort" data-sort="phone">
                              Quantity Sold
                            </th>
                            <th className="sort" data-sort="Date">
                              Unit Price
                            </th>
                            <th className="sort" data-sort="amount">
                              Amount
                            </th>
                            <th className="sort" data-sort="amount">
                              Discription
                            </th>
                            <th className="sort" data-sort="action">
                              Remove
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {serviceList.map((service, index) => {
                            //console.log(service.Amount);
                            return (
                              <tr key={index} className="">
                                <td className="item">
                                  <div className="mb-3">
                                    <select
                                      className="form-select "
                                      id="itemtype"
                                      onChange={(e) =>
                                        handleServiceChange(e, index)
                                      }
                                      name="itemId"
                                      selected
                                    >
                                      {/* <option>Choose Item</option> */}
                                      {products.map((pr, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={pr._id}
                                            selected
                                          >
                                            {pr.itemName}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </td>
                                {/* <td className="qty">
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="disabledInput"
                                      defaultValue="5"
                                      disabled
                                      value={5}
                                    />
                                  </div>
                                </td> */}
                                <td className="phone">
                                  <div className="mb-3">
                                    <input
                                      type="number"
                                      id="CashAccount-field"
                                      className="form-control"
                                      placeholder="Enter quantity"
                                      required
                                      onChange={(e) =>
                                        handleServiceChange(e, index)
                                      }
                                      name="quantity"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="mb-3">
                                    <input
                                      type="number"
                                      id="CashAccount-field"
                                      className="form-control"
                                      placeholder="Enter Rate"
                                      required
                                      onChange={(e) =>
                                        handleServiceChange(e, index)
                                      }
                                      name="unitPrice"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="disabledInput"
                                      defaultValue={service.amount}
                                      value={service.amount}
                                      disabled
                                    />
                                  </div>
                                </td>
                                <td className="qty">
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        handleServiceChange(e, index)
                                      }
                                      name="description"
                                    />
                                  </div>
                                </td>
                                <td>
                                  {serviceList.length > 1 && (
                                    <button
                                      type="submit"
                                      className="btn btn-danger mb-3"
                                      id="add-btn"
                                      onClick={() => handleServiceRemove(index)}
                                    >
                                      Remove
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      <Row>
                        {/* <Col lg={2} md={1}>
                          <div>
                            <div className="form-check form-switch form-switch-lg mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="ISCash"
                              />
                              <div>
                                <Label
                                  className="form-check-label"
                                  for="flexSwitchCheckDefault"
                                >
                                  Is Cash
                                </Label>
                              </div>
                            </div>
                          </div>
                        </Col> */}

                        {/* <Col md={2}>
                          <div className="mb-3">
                            <label>Is Cash</label>
                            <div className="mb-3">
                              <Input
                                className="form-control-input"
                                type="radio"
                                name="prim"
                                id="is_yes"
                                value={true}
                                defaultChecked
                                onChange={(e) => setYes(e.target.value)}
                              />
                              <Label className="form-check-label">Yes</Label>
                            </div>
                          </div>
                          <div className="mb-3">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="prim"
                              id="is_no"
                              value={false}
                              onChange={(e) => setYes(e.target.value)}
                            />
                            <Label className="form-check-label">No</Label>
                          </div>
                        </Col> */}
                        <Col md={2}>
                          <div className="mb-3">
                            <label className="form-label">
                              Total Sale amount
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="disabledInput"
                              defaultValue={purchaseAmount}
                              value={purchaseAmount}
                            />
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <label className="form-label">Discount</label>
                            <input
                              type="number"
                              id="totalamount-field"
                              className="form-control"
                              placeholder="Enter Discount"
                              required
                              onChange={(e) => setDiscount(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <label className="form-label">Total Amount</label>
                            <input
                              type="number"
                              className="form-control"
                              id="disabledInput"
                              defaultValue={purchaseAmount}
                              value={purchaseAmount - discount}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-5"></Row>
                    </ModalBody>
                    <ModalFooter>
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-success"
                          id="add-btn"
                        >
                          Save
                        </button>
                      </div>
                    </ModalFooter>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Purchase Customer From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        <ModalHeader className="bg-light p-3">
          Add New Customer
          <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>

            <div className="mb-3">
              <label htmlFor="customer_name-field" className="form-label">
                Customer Name
              </label>
              <input
                type="text"
                id="customer_name-field"
                className="form-control"
                placeholder="Enter Customer Name"
                required
                onChange={(e) => setCustomerName(e.target.value)}
                value={customerName}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone-field" className="form-label">
                Phone Number
              </label>
              <input
                type="number"
                id="phone-field"
                className="form-control"
                placeholder="Enter phone Number"
                required
                onChange={(e) => setCustomerPhone(e.target.value)}
                value={customerPhone}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="address-field" className="form-label">
                Address
              </label>
              <input
                type="text"
                id="address-field"
                className="form-control"
                placeholder="Enter Address"
                required
                onChange={(e) => setCustomerAddress(e.target.value)}
                value={customerAddress}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="total-field" className="form-label">
                Total Card
              </label>
              <input
                type="Number"
                id="total-field"
                className="form-control "
                placeholder="Enter Total Card"
                required
                onChange={(e) => setTotalCards(e.target.value)}
                value={totalCards}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isUpdating ? "Update" : "save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default SalesForm;
