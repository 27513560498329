import React, { useState, useEffect, useContext, useMemo } from "react";

import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import moment from "moment";
// import styles from "./StyledTable.module.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import DataTable from "react-data-table-component";
const CollectDatlyAttendance = () => {
  const [Discription, setdescription] = useState("");
  const { openAnalogCardRegister } = useContext(LoginContex);

  //   const [customerId, setCustomer] = useState();
  const [EmployeeDepartment, setEmployeeDepartment] = useState("");
  const [employeeId, setEmployeeId] = useState([]);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [deviceId, setDeviceId] = useState();
  const [service, setService] = useState("424");
  const [summary, setSummary] = useState("");
  const [type, setType] = useState();
  const [helper, setHelper] = useState(false);
  const [customerId, setcustomerId] = useState("");
  const [middleWare, setmiddleWare] = useState("");
  const [Line, setsetLine] = useState("all");

  document.title = "Daily Attendance";
  // //console.log(service);
  const {
 fetchDevices, devices, fetchAttendanceData,attendanceData, fetchAttendanceUpdates,getDailyAttendance,dailyAttendanceReport,
 fetchDepartment,
 departments,
 fetchEmployeeDepartments,EmployeesByDepartments,fetchEmployees,getEmployee,isLoading
  } = useContext(LoginContex);

  useEffect(() => {
    fetchDevices()
    fetchDepartment()
    fetchEmployees()
  }, []);

// 

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  let  filteredItems = dailyAttendanceReport?.filter((item) => (
    
    item.emp?.empName&&
    item.emp?.empName?.toLowerCase().includes(filterText.toLowerCase()) && item.emp?.mobile ||
    item.emp?.mobile?.toString().toLowerCase().includes(filterText.toLowerCase()) ||
    item.status?.toString().toLowerCase().includes(filterText.toLowerCase()) ||
    item.emp.empNo?.toString().toLowerCase().includes(filterText.toLowerCase()) ||
    item.shift.shiftName?.toString().toLowerCase().includes(filterText.toLowerCase()) 
  ));
  // //console.log(filteredItems);



  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

const columns =[
   {
  name: <h6 style={{ fontWeight: "" ,  }}> EMMP-NO</h6>,
  selector: (row) => row?.emp?.empNo,
},

{
  name: <h6 style={{ fontWeight: "" }}> Employee Name</h6>,
  selector: (row) => row?.emp.empName,
  width: "300px",
  wrap: true
},
{
  name: <h6 style={{ fontWeight: "" }}> Phone Number</h6>,
  selector: (row) => row?.emp.mobile,
},
{
  name: <h6 style={{ fontWeight: "" }}> Department</h6>,
  selector: (row) => row?.depName,
},
{
  name: <h6 style={{ fontWeight: "" }}> Shift</h6>,
  selector: (row) => row?.shift.shiftName,
},
{
  name: <h6 style={{ fontWeight: "" }}> Date</h6>,
  selector: (row) => moment(row?.date).utc().format("DD-MM-YYYY"),
},
{
  name: <h6 style={{ fontWeight: "" }}>Time In</h6>,
  selector: (row) => row?.timeIn,
},
{
  name: <h6 style={{ fontWeight: "" }}>Time Out</h6>,
  selector: (row) => row?.timeOut,
},
// {
//   name: <h6 style={{ fontWeight: "" }}>Late Minutes</h6>,
//   selector: (row) => row?.minutesLate,
// },
{
  name: <h6 style={{ fontWeight: "" }}>Late Time</h6>,
  selector: (row) => row?.hoursLate +':'+ row?.minutesLate,
},

{
  name: <h6 style={{ fontWeight: "" }}>Status</h6>,
  selector: (row) => row?.status
},

]

  // //console.log(dailyAttendanceReport);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);




  // //console.log(iptvCustomerRechargeData);


  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const filterThroughDate = () => {
    setHelper(true);
    let employees;
  
    // //console.log('employee ID ============================' ,employeeId?.value)
   let depts = EmployeeDepartment == "all"? 'all':EmployeeDepartment
    const employee = getEmployee?.filter(emp => emp._id === employeeId?.value);
    employees = employeeId?.value === "all"? 'all' : employeeId?.value
  

    setHelper(true);
    const startt = moment(start).format("YYYY-MM-DD");
    const enddd = moment(end).format("YYYY-MM-DD");
    // fetchCustIPTVReport(startt, enddd,type, Line);
    const deviceIp1 = deviceId?.value ? deviceId?.value : "All"
    let empId = employees
    // //console.log('departs ',depts)
    let data = {
      depts:depts,
      employees
      
    }
 
    getDailyAttendance(data)

  };

  const clearFilter = () => {
    setHelper(false);
    setStart("");
    setEnd("");
    setDeviceId("");
    setType("");
    setsetLine("");
  };

  

  const CvsData = [];
  for (let i = 0; i < filteredItems?.length; i++) {
    // //console.log(filteredItems[i]);

    if (filteredItems[i]?.emp?.empName) {
      var dropdownList = {
        EmployeeId:` ${filteredItems[i]?.emp?.empNo}`,
        EmployeeName:` ${filteredItems[i]?.emp?.empName}`,
        Phone: ` ${filteredItems[i]?.emp?.mobile}`,
        Department: ` ${filteredItems[i]?.depName}`,
        Shift: ` ${filteredItems[i]?.shift?.shiftName}`,
        Date: ` ${filteredItems[i]?.date}`,
        TimeIn: ` ${filteredItems[i]?.timeIn}`,
        Timeout: ` ${filteredItems[i]?.timeOut}`,
        LateTime: ` ${filteredItems[i]?.hoursLate +':'+filteredItems[i]?.minutesLate }`,
        Status: ` ${filteredItems[i]?.status}`,

     
      };
    }
  
    CvsData.push(dropdownList);
  }
  // //console.log(CvsData)
  function convertArrayOfObjectsToCSV2(array) {
    let result;
  
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData?.[0] || []);
  
    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    array.forEach((item, index) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
  
        result += item[key];
  
        ctr++;
      });
  
      // Add a column for the last total
      if (index === array.length - 1) {
        result += columnDelimiter;
       
      }
  
      result += lineDelimiter;
    });
  
    return result;
  }
  

  // // ** Downloads CSV
  function downloadCSV2(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV2(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
 

  const devicesList = [];

  for (let i = 0; i < devices?.length; i++) {
    // //console.log(setEmployeeId[i]._id);

    var list = {
      value: devices[i]?.ipAddress,
      label: ` ${devices[i]?.deviceName} -- ${devices[i]?.ipAddress}`,
    };

    devicesList.push(list);
  }

  const DepartmentsArr = [];
  DepartmentsArr.push({ label: "all", value: "all" });
  for (let i = 0; i < departments.length; i++) {
    // //console.log(departments[i]._id);

    var dropdownList2 = {
      value: departments[i]._id,
      label: ` ${departments[i].departmentName}`,
    };

    DepartmentsArr.push(dropdownList2);
  }
  const handleTypeSelect = (e) => {
    setEmployeeDepartment(e.value);
    fetchEmployeeDepartments(e.value);
  };

  const empArr = [];
  empArr.push({ label: "all", value: "all" });
  for (let i = 0; i < EmployeesByDepartments.length; i++) {
    // //console.log(EmployeesByDepartments[i]._id);

    var dropdownList1 = {
      value: EmployeesByDepartments[i]?._id,
      label: `${EmployeesByDepartments[i]?.empName}`,
      mobile: `${EmployeesByDepartments[i]?.mobile}`,
      titleId: `${EmployeesByDepartments[i]?.titleId}`,
      _id: `${EmployeesByDepartments[i]?._id}`,
    };
    //
    empArr.push(dropdownList1);
  }
  const Emp = [];
  Emp.push({ label: "all", value: "all" });
  // Emp.push({ label: "all", value: "all" });
  for (let i = 0; i < EmployeesByDepartments?.length; i++) {
    // //console.log(setEmployeeId[i]._id);

    var dropdownList2 = {
      value: EmployeesByDepartments[i]?.sqn,
      label: ` ${EmployeesByDepartments[i]?.empName}`,
    };

    Emp.push(dropdownList2);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Collect Daily Attendance" pageTitle="Attendance" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-autod">
                      <div>
                        <ToastContainer />
                        <div className="">
                          <h4 className="card-title mb-0">Collect Daily Attendance</h4>
                          {/* <Button>Go Back</Button> */}

                          <div
                            className="form-label"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                         
                          </div>
                        </div>
                      </div>
                     
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                    
                          
                          <Button
                            color="success"
                            className="add-btn me-1 mx-2"
                            onClick={() => downloadCSV2(CvsData)}
                            id="create-btn"
                          >
                            <i
                              className="fa fa-print mx-2"
                              aria-hidden="true"
                            ></i>
                            Download Excell
                          </Button>
                    
                      </div>
                    </Col>
            
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="mb-5">
                    {/* <Col md={4}>
              <div className="mb-3">
                      <label className="form-label">
                        Device <span className="text-danger">*</span>
                      </label>
                        <Select
                          // className="bg-white"
                          options={devicesList}
                          onChange={(choice) => setDeviceId(choice)}
                          value={devicesList.filter(function (option) {
                            return option.value === deviceId?.value;
                          })}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                            },
                          })}
                        />
                    </div>
              </Col> */}
                      {/* <Col md={3}>
                        <div style={{ display: "none" }}>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            From Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStart(date)}
                            value={start}
                            
                          />
                        </div>
                      </Col> */}
                      {/* <Col md={3}>
                        <div  style={{ display: "none" }}>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            To Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEnd(date)}
                            value={end}
                          />
                        </div>
                      </Col> */}
                         <Col md={4}>
                <div className="mb-3">
                  <label>Select Department</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <Select
                    // className="bg-white"
                    options={DepartmentsArr}
                    onChange={handleTypeSelect}
                    value={DepartmentsArr.filter(function (option) {
                      return option.value === EmployeeDepartment;
                    })}
                    defaultValue={{
                      label: "Select Department",
                      value: EmployeeDepartment,
                    }}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        // text: "orangered",
                        // primary25: "hotpink",
                        // primary: "black",
                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={4}>
              <div className="mb-3">
                      <label className="form-label">
                        Select Employee <span className="text-danger">*</span>
                      </label>
                        <Select
                          // className="bg-white"
                          options={Emp}
                          onChange={(choice) => setEmployeeId(choice)}
                          value={Emp.filter(function (option) {
                            return option.value === employeeId?.value;
                          })}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                            },
                          })}
                        />
                    </div>
              </Col>
                      {/* <Col md={4}>
                      
                      <div className="">
                        <div> <Button onClick={getAttendanceUpdates} className="add-btn mt-4 btn-info"><i className="ri-file-download-line align-bottom me-1 "></i>Dowmload {" "}Updates 
                         </Button > </div>
                      </div>
                    </Col> */}

                      

                 <Col md={4} className="">
                        <div className="form-label ">
                          <Button
                            color="success"
                            className=" add-btn mt-4 btn-info"
                            onClick={filterThroughDate}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Proceed
                          </Button>
                        </div>
                      </Col>
                    </Row>
              
            
                  <Row>
                  <div id="customerList">
                    <Row className="g-4 mb-3"></Row>

                    {/* {dailyAttendanceReport?.length > 0 && (
                      <TableContainer
                        columns={columns}
                        data={dailyAttendanceReport || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        progressPending={isLoading  }
                        progressComponent={<SpinnerBorder />}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        // selectableRows
                        persistTableHead
                        // handleCustomerClick={handleCustomerClicks}
                        // isCustomerFilter={true}
                      />
                    )} */}
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        progressPending={isLoading  }
                        progressComponent={<SpinnerBorder />}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        // selectableRows
                        persistTableHead
                      // sortFunction={customSort}
                      />
                  </div>
                  </Row>
           

                   
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CollectDatlyAttendance;
const SpinnerBorder = () => {
  return <Spinner className="my-2 text-center" />;
};
