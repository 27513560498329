import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateCable = () => {
  // Flatpickr(".datepicker")

  const [salesDate, setSalesDate] = useState();
  const [field, setField] = useState([]);
  const [salesType, setSalesType] = useState();
  const [rrr, setrr] = useState();
  const [description, setdescription] = useState("");
  const [typeId, setTypeId] = useState();
  const [customerId, setCustomerId] = useState();
  const [cardNumber, setCardNumber] = useState("");
  const [receiverNumber, setReceiverNumber] = useState("");
  const [registerDate, setRegisterDate] = useState();
  const [receiverType, setReceiverType] = useState("");

  const [sortBy, setsortBy] = useState(null);
  const [selectMulti, setselectMulti] = useState(null);
  const [cardsList, setCardLists] = useState();

  const [newCableList, setNewCableList] = useState([
    {
      chanlPckgeId: "",
    },
  ]);

  ///local states

  const [todaydata, setTodayDate] = useState("");
  const [date, setDate] = useState("");

  const [modal_list, setmodal_list] = useState(false);
  const [yes, setYes] = useState(true);
  const [referenceNo, setReferenceNo] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [custPhone, setCustPhone] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [totalCards, setTotalCards] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [_id, set_ID] = useState("");
  const [lists, setLists] = useState([]);
  const [chanlPckge, setchanlPckge] = useState();
  const [chanlPckgeName, setchanlPckgeName] = useState();

  const {
    registerCustomer,
    fetchCustomers,
    customers,
    fetchRecievers,
    recievers,
    getByIdRecive,
    reciveById,
    registernewCable,
    fetchChanelPackages,
    channelPackage,
  } = useContext(LoginContex);

  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  let yess = yes === "true";
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    fetchCustomers();
    setTodayDate(today);
    fetchRecievers();
    fetchChanelPackages();
  }, []);

  // //console.log(yess);

  ///date format

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;

  // //console.log(date);

  document.title = "Purchase  ";

  const amounts = newCableList[0].Qty;
  // //console.log(amounts);

  newCableList.forEach((ca) => {
    for (const item in ca) {
      // //console.log("h");
    }
  });
  const handleServiceAdd = () => {
    setNewCableList([
      ...newCableList,
      {
        chanlPckgeId: "",
      },
    ]);
  };

  let getCustPhone = (id, mobile) => {
    setCustomerId(id);
    setCustPhone(mobile[mobile.selectedIndex].getAttribute("data-Moble"));
  };

  let getRecive = (id) => {
    setReceiverType(id);
    getByIdRecive(id);
  };

  let getCardNo = (id, cardNo) => {
    setReceiverNumber(id);
    setCardNumber(cardNo[cardNo.selectedIndex].getAttribute("data-cardNumber"));
  };

  let getPackageId = (id, Package) => {
    setchanlPckge(id);
    // setReceiverNumber(id)
    setchanlPckgeName(
      Package[Package.selectedIndex].getAttribute("data-PackageName")
    );
  };

  const handleServiceRemove = (index) => {
    const list = [...newCableList];
    list.splice(index, 1);
    setNewCableList(list);
  };

  const purchaseAmount = newCableList.reduce((accu, curr) => {
    return accu + curr["amount"];
  }, 0);

  
  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...newCableList];
    list[index][name] = value;
    list[index]["amount"] =
      newCableList[index]["quantity"] * newCableList[index]["unitPrice"];
    list[index]["purchaseAmount"] = purchaseAmount;
    setNewCableList(list);
    // handleServiceAdd();
    if (e.target.name === "itemId") {
      handleServiceAdd();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const Data = {
      registrationDate: "2022-10-25",
      customerId,
      salesAgentType: "Site",
      typeId: "6342ca87ded7524330e4df1a",
      referenceNo: null,
      description: null,
      billAmount: 16,
      discount: 3,
      balanceToPay: 14,
      isTrail: yes,
      startDate: "2022-10-15",
      closingDate: "2022-10-15",
      isCash: null,
      cashAccount: null,
      cardNumber,
      phoneNumber: custPhone,
      receiverNumber,
      receiverType,
      channelPackageId: [{ channelPackageId: "63456e399169f28208b626cf" }],
    };

    //  //console.log(lists);

    registernewCable(Data);

    setRegisterDate(Date());
    setCustomerId("0");
    setReceiverType("0");
    setReceiverNumber("0");
    setCardNumber("");
    setCustPhone("");
    // setYes()
    setchanlPckge("0");
  };
  // //console.log(isUpdating);
  const handlerSubmit = (e) => {
    e.preventDefault();
    const data = {
      customerName,
      customerPhone,
      customerAddress,
    };
    registerCustomer(data);
    setCustomerName("");
    setCustomerPhone("");
    setCustomerAddress("");
    setmodal_list(false);
  };

  // //console.log(yes);

  const addToTable = () => {
    setLists([...lists, chanlPckgeName]);
    //console.log(lists);
    // chanlPckge
  };

  const setdateReg =(e)=>{
    //console.log(e);
  }

  // //console.log(lists);
  const removeCardNumber = (name) => {
    // //console.log(cardsList);

    const filteredList = lists.filter((list, index) => list !== name);
    setLists(filteredList);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="New Cable" pageTitle="Billing" />
          <Row>
            <Card>
              <CardHeader>
                <Row className="g-4 mb-3">
                  <Col className="col-sm-auto">
                    <div>
                      <div>
                        <ToastContainer />
                        <h4 className="card-title mb-0">Add New Cable</h4>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-sm">
                    <div className="d-flex justify-content-sm-end">
                      <div>
                        <Link to="/ManageCables" className="text-white">
                          <Button
                            color="success"
                            className="add-btn me-1"
                            // onClick={() => tog_list()}
                            //  id="create-btn"
                          >
                            <i className=" ri-pencil-line align-bottom me-1"></i>
                            Manage Cables
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <form onSubmit={handleSubmit}>
                  <div
                    className="mb-3"
                    id="modal-id"
                    style={{ display: "none" }}
                  >
                    <label htmlFor="id-field" className="form-label">
                      ID
                    </label>
                    <input
                      type="text"
                      id="id-field"
                      className="form-control"
                      placeholder="ID"
                      readOnly
                    />
                  </div>
                  <Row className="mb-4">
                    <Col md={4}>
                      <Label htmlFor="PurchasesDate" className="form-label">
                        Register Date <span className="text-danger">*</span>
                      </Label>
                      <Flatpickr
                        className="form-control"
                        placeholder="DD/MM/YYYY"
                        options={{
                          dateFormat: "d/m/Y",
                        }}
                        // onChange={([date]) => {
                        //   this.setRegisterDate({ date });
                        // }}
                        onchange ={(e) => setdateReg(e.target.value)}
                      />
                    </Col>
                    <Col md={4}>
                      <div>
                        <Label className="form-label">
                          Customer <span className="text-danger">*</span>
                        </Label>
                        <div className="input-group">
                          <select
                            className="form-select"
                            id="inputGroupSelect04"
                            aria-label="Example select with button addon"
                            onChange={(e) =>
                              getCustPhone(e.target.value, e.target)
                            }
                            value={customerId}
                            // onClick={(e) => getCustPhone(e.target.value)}
                            // onClick={(e) => getCustPhone(e.target.value)}

                            required
                          >
                            <option value="0">Choose Customer</option>
                            {customers?.map((customer, index) => {
                              return (
                                <option
                                  key={index}
                                  value={customer._id}
                                  data-Moble={customer.customerPhone}
                                >
                                  {customer.customerName}
                                </option>
                              );
                            })}
                          </select>
                          <Button
                            size="sm"
                            color="success"
                            // className="add-btn me-1"
                            onClick={() => tog_list()}
                            id="create-btn"
                            className="mt-4d"
                            // style={{ marginTop: "2rem" }}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <label
                          htmlFor="ReOrderLevel-field"
                          className="form-label"
                        >
                          Receiver Type
                        </label>
                        <select
                          className="form-select "
                          id="itemtype"
                          onChange={(e) => getRecive(e.target.value)}
                          value={receiverType}
                          //  onChange {(e) => getRecive(e.target.value)}
                        >
                          <option value="0">Choose Type...</option>
                          {recievers.map((recive, index) => (
                            <option key={index} value={recive.itemId}>
                              {recive.itemName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-5">
                    <Col md={4}>
                      {/* <Form.Group as={Col} controlId="my_multiselect_field">
                        <Form.Label>My multiselect</Form.Label>
                        <Form.Control
                          as="select"
                          multiple
                          value={field}
                          onChange={(e) =>
                            setField(
                              [].slice
                                .call(e.target.selectedOptions)
                                .map((item) => item.value)
                            )
                          }
                        >
                          <option value="field1">Field 1</option>
                          <option value="field2">Field 2</option>
                          <option value="field3">Field 3</option>
                        </Form.Control>
                      </Form.Group> */}
                      <label className="form-label">Receiver Number</label>
                      <select
                        className="form-select "
                        id="itemtype"
                        onChange={(e) => getCardNo(e.target.value, e.target)}
                        value={receiverNumber}
                      >
                        <option value="0">Choose Receiver Number</option>

                        {reciveById.map((recive, index) => (
                          <option
                            key={index}
                            value={recive._id}
                            data-cardNumber={recive.cardNumber}
                          >
                            {recive.receiverSerialNumber}
                          </option>
                        ))}
                      </select>
                      {/* <Select
                        value={sortBy}
                        onChange={(sortBy) => {
                          setsortBy(sortBy);
                        }}
                        options={sortbyname._id}
                        id="choices-single-default"
                        className="js-example-basic-single mb-0 "
                        name="state"
                      /> */}
                    </Col>
                    <Col md={4}>
                      <label className="form-label">Card Number</label>
                      <input
                        type="number"
                        id="referenceno-field"
                        className="form-control"
                        placeholder="Card No"
                        disabled
                        onChange={(e) => setCardNumber(e.target.value)}
                        value={cardNumber}
                      />
                    </Col>
                    <Col md={4}>
                      <label className="form-label">Phone Number</label>
                      <input
                        type="number"
                        id="referenceno-field"
                        className="form-control"
                        placeholder="Explicit Phone Number"
                        required
                        onChange={(e) => setCustPhone(e.target.value)}
                        value={custPhone}
                        // onChange={(e) => setReferenceNo(e.target.value)}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={3}>
                      <div>
                        {/* <label className="form-label">Is Trial</label>
                        <select className="form-select " id="IsTRail">
                          <option value="Choose Type...">Choose Type...</option>
                          <option onChange={(e) => setYes(e.target.value)}>
                            Yes
                          </option>
                          <option onChange={(e) => setYes(e.target.value)}>
                            No
                          </option>
                        </select> */}
                        <div className="mb-3">
                          <label className="form-label">Is Trial</label>
                          <div>
                            <Input
                              className="form-control-input"
                              type="radio"
                              name="trial"
                              id="yes"
                              value={true}
                              defaultChecked
                              onChange={(e) => setYes(e.target.value)}
                            />
                            <Label className="form-check-label">Yes</Label>
                          </div>
                        </div>
                        <div className="mb-3">
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="trial"
                            id="no"
                            value={false}
                            onChange={(e) => setYes(e.target.value)}
                          />
                          <Label className="form-check-label">No</Label>
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div>
                        <label
                          htmlFor="ReOrderLevel-field"
                          className="form-label"
                        >
                          channel package
                        </label>

                        <select
                          className="form-select "
                          id="inputGroupSelect04"
                          onChange={(e) =>
                            getPackageId(e.target.value, e.target)
                          }
                          value={chanlPckge}
                        >
                          <option value="Choose Type...">Choose Type...</option>

                          {channelPackage.map((Package, index) => (
                            <option
                              key={index}
                              value={Package._id}
                              data-PackageName={Package.channelPackageName}
                            >
                              {Package.channelPackageName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col md={2} style={{ marginTop: "2%" }}>
                      <div className="d-flex gap-2">
                        <div className="remove">
                          <button
                            type="button"
                            className="btn btn-success"
                            id="add-btn"
                            onClick={addToTable}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </Col>

                    <Col md={12}>
                      {lists.length > 0 && (
                        <div className="table-responsivee mt-5">
                          <Table className="table-sm table-nowrap mb-0">
                            <thead>
                              <tr>
                                <th scope="col">No</th>
                                {/* <th scope="col">channel Ids</th> */}

                                {/* <th>ID</th> */}
                                <th>Channel Package</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists?.map((list, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="fw-medium">{index + 1} </td>

                                    {/* <td className="tdPackageID">{chanlPckge}  </td> */}

                                    <td>{list}</td>
                                    <td>
                                      <td>
                                        <div className="remove">
                                          <button
                                            className="btn btn-sm btn-danger remove-item-btn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#deleteRecordModal"
                                            onClick={() =>
                                              removeCardNumber(list)
                                            }
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </td>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Col md={5}></Col>

                  {/* <Row className="my-3">
                    <Col md={1}>
                      <label>Agent Type</label> <br />
                    </Col>
                    <Col md={3}>
                      <select
                        className="form-select "
                        id="itemtype"
                        onChange={(e) => setSalesType(e.target.value)}
                      >
                        <option>choose an option</option>
                        <option value="Store">Store</option>
                        <option value="Site">Site</option>
                        <option value="Agent">Agent</option>
                      </select>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col md={1}>
                      <label>Sites </label>
                    </Col>
                    <Col md={3} className="">
                      <select
                        className="form-select "
                        id="itemtype"
                        onChange={(e) => setTypeId(e.target.value)}
                      >
                        <option>select an option</option>
                        {salesType == "Store" &&
                          stores?.map((store) => {
                            return (
                              <option key={store._id} value={store._id}>
                                {" "}
                                {store.storeName}{" "}
                              </option>
                            );
                          })}
                        {salesType == "Agent" &&
                          agents?.map((agent) => {
                            return (
                              <option key={agent._id} value={agent._id}>
                                {" "}
                                {agent.salesAgentName}{" "}
                              </option>
                            );
                          })}
                        {salesType == "Site" &&
                          sites?.map((site) => {
                            return (
                              <option key={site._id} value={site._id}>
                                {" "}
                                {site.siteName}{" "}
                              </option>
                            );
                          })}
                      </select>
                    </Col>
                  </Row> */}
                  {/* <Row className="mb-3">
                    <Col lg={1}>
                      <label className="form-label">Card No</label>
                    </Col>
                    <Col lg={3}>
                      <input
                        type="number"
                        id="referenceno-field"
                        className="form-control"
                        placeholder="Enter Card No"
                        required
                        onChange={(e) => setReferenceNo(e.target.value)}
                      />
                    </Col>
                  </Row> */}
                  <Row className="mt-5"></Row>
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-success"
                      id="add-btn"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Purchase Customer From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Add New Customer</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>

            <div className="mb-3">
              <label htmlFor="customer_name-field" className="form-label">
                Customer Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="customer_name-field"
                className="form-control"
                placeholder="Enter Customer Name"
                required
                onChange={(e) => setCustomerName(e.target.value)}
                value={customerName}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone-field" className="form-label">
                Phone Number <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                id="phone-field"
                className="form-control"
                placeholder="Enter phone Number"
                required
                onChange={(e) => setCustomerPhone(e.target.value)}
                value={customerPhone}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="address-field" className="form-label">
                Address
              </label>
              <input
                type="text"
                id="address-field"
                className="form-control"
                placeholder="Enter Address"
                onChange={(e) => setCustomerAddress(e.target.value)}
                value={customerAddress}
              />
            </div>

            {/* <div className="mb-3">
              <label htmlFor="total-field" className="form-label">
                Total Card
              </label>
              <input
                type="Number"
                id="total-field"
                className="form-control "
                placeholder="Enter Total Card"
                required
                onChange={(e) => setTotalCards(e.target.value)}
                value={totalCards}
              />
            </div> */}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateCable;
