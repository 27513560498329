import React, { useState, useEffect, useContext, useMemo } from "react";

import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import moment from "moment";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import DataTable from "react-data-table-component";
const IptvCustomerRecharge = () => {
  
  const [Discription, setdescription] = useState("");
  const { openAnalogCardRegister } = useContext(LoginContex);
  
//   const [customerId, setCustomer] = useState();


  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [helper, setHelper] = useState(false);
  const [customerId, setcustomerId] = useState("");


  document.title = "Ticket Registration Report";

  const {

    cardList,

    fetchCustomers,
    customers,fetchTicketRegesterationReport,ticketRegReport , fetchEmployeeReport,employeeReport,
    fetchTicketTransferReport, ticketTransferReport,  FechIptvCustomerRechargeReport, iptvCustomerRechargeData
  } = useContext(LoginContex);

  useEffect(() => {

    fetchCustomers();
    let date= new Date()
    const start = moment(date).format("YYYY-MM-DD");
    const end = moment(date).format("YYYY-MM-DD");
    FechIptvCustomerRechargeReport(start, end);

  }, []);

  //console.log(ticketTransferReport);
  //console.log(customers);

//   //console.log(openDate);
//   //console.log(start);
//   //console.log(end);
//   //console.log(OpenCards);

 

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = iptvCustomerRechargeData?.filter(
    (item) =>
 
      (item?.customer_info.customerName && item?.customer_info.customerName?.toLowerCase().includes(filterText.toLowerCase()))
  );
  //console.log(filteredItems);
  // //console.log(iptvCustomerRechargeData);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> Customer Name</h6>,
      selector: (row) => row?.customer_info?.customerName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Phone</h6>,
      selector: (row) => row?.customer_info?.customerPhone,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Tellephone</h6>,
      selector: (row) => row?.customer_info?.customerTell,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Address</h6>,
      selector: (row) => row?.customer_info?.customerAddress,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Subscription</h6>,
      selector: (row) => row?.subscriptionId,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Amount</h6>,
      selector: (row) => row?.amount,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Start Date</h6>,
      selector: (row) => moment(row?.startDate).utc().format("DD-MM-YYYY"),
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>End Date</h6>,
      selector: (row) => moment(row?.endDate).utc().format("DD-MM-YYYY"),
    },

  ];
//console.log(iptvCustomerRechargeData)
//   const CvsData = [];
//   for (let i = 0; i < filteredItems?.length; i++) {
//     //console.log(filteredItems[i]._id);

//     if(filteredItems[i]?.customer_info?.customerName){
//       var dropdownList = {
//         Customer: filteredItems[i]?.customer_info?.customerName,
//         customerPhone: ` ${filteredItems[i]?.customer_info?.customerPhone}`,
//         Address: ` ${filteredItems[i]?.customer_info?.customerAddress}`,
//         Employee: ` ${filteredItems[i]?.employee_info?.empName}`,
//         EmployeePhone: ` ${filteredItems[i]?.employee_info?.mobile}`,
//         TciketTitle: ` ${filteredItems[i]?.title}`,
//         Status: ` ${filteredItems[i]?.status}`,
//         Date: ` ${moment(filteredItems[i]?.createdAt).utc().format("DD-MM-YYYY")}`,
  
//       };
//     }
   

//     CvsData.push(dropdownList);
//   }
// //console.log(CvsData)
// //console.log(filteredItems)

  // function convertArrayOfObjectsToCSV(array) {
  //   let result;

  //   const columnDelimiter = ",";
  //   const lineDelimiter = "\n";
  //   const keys = Object.keys(CvsData?.[0] || []);

  //   result = "";
  //   result += keys.join(columnDelimiter);
  //   result += lineDelimiter;

  //   array.forEach((item) => {
  //     let ctr = 0;
  //     keys.forEach((key) => {
  //       if (ctr > 0) result += columnDelimiter;

  //       result += item[key];

  //       ctr++;
  //     });
  //     result += lineDelimiter;
  //   });

  //   return result;
  // }

  // ** Downloads CSV
  // function downloadCSV(array) {
    // const link = document.createElement("a");
    // let csv = convertArrayOfObjectsToCSV(array);
    // if (csv === null) return;

    // const filename = "export.csv";

    // if (!csv.match(/^data:text\/csv/i)) {
    //   csv = `data:text/csv;charset=utf-8,${csv}`;
    // }

  //   link.setAttribute("href", encodeURI(csv));
  //   link.setAttribute("download", filename);
  //   link.click();
  // }

  
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
 

  const filterThroughDate = () => {
    setHelper(true);
    const startt = moment(start).format("YYYY-MM-DD");
    const enddd = moment(end).format("YYYY-MM-DD");
    FechIptvCustomerRechargeReport(startt, enddd);
  };
  //console.log(iptvCustomerRechargeData)

  const clearFilter = () => {
    setHelper(false);
    setStart("")
    setEnd("")
    setcustomerId("")
  };
 
  const CustomersArr = [];

  for (let i = 0; i < customers.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: customers[i]._id,
      label: ` ${customers[i].customerName}---${customers[i].customerPhone}`,
    };

    CustomersArr.push(dropdownList);
  }

  const customerCards = [];



  const CvsData = [];
  for (let i = 0; i < filteredItems?.length; i++) {
    // //console.log(filteredItems[i]._id);

    if(filteredItems[i]?.customer_info?.[0]?.customerName){
      var dropdownList = {
        Customer: filteredItems[i]?.customer_info?.customerName,
        customerPhone: ` ${filteredItems[i]?.customer_info?.customerPhone}`,
        Address: ` ${filteredItems[i]?.customer_info?.customerAddress}`,
        CustomerTell: ` ${filteredItems[i]?.customer_info?.customerTell}`,
        Subscription: ` ${filteredItems[i]?.subscriptionId}`,
        startDate: ` ${moment(filteredItems[i]?.startDate).utc().format("DD-MM-YYYY")}`,
        endDate: ` ${moment(filteredItems[i]?.endDate).utc().format("DD-MM-YYYY")}`,
       
  
      };
    }
   

    CvsData.push(dropdownList);
  }
//console.log(CvsData)
// //console.log(ticketSupervisorReport)

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData?.[0] || []);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // ** Downloads CSV
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Iptv Recharge Report" pageTitle="Report" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div className="">
                          <h4 className="card-title mb-0">Iptv Recharge Report</h4>
                          {/* <Button>Go Back</Button> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="mb-5">
               
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Start Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStart(date)}
                            value={start}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            End Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEnd(date)}
                            value={end}
                          />
                        </div>
                      </Col>
                      
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={filterThroughDate}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Filter By Date{" "}
                          </Button>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={clearFilter}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Clear filter{" "}
                          </Button>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={()=>{
                              downloadCSV(CvsData)
                            }}
                            type="button"
                            //  id="create-btn"
                          > 
                            <i className="fa fa-print mx-2" aria-hidden="true"> </i>  
                            Download Excel
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    {/* <TableContainer
                      columns={columns}
                      data={ticketRegReport}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      // handleCustomerClick={handleCustomerClicks}
                      // isCustomerFilter={true}
                    /> */}

{
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        // selectableRows
                        persistTableHead
                      // sortFunction={customSort}
                      />
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Open Closed Card Distribution From */}

      {/* Remove Modal */}
    </React.Fragment>
  );
};

export default IptvCustomerRecharge;
