import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReceiverDetails = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [inputList, setInputList] = useState([
    { receiverType: "", receiverSerialNumber: "", cardNumber: "" },
  ]);
  const [purchaseInfoId, setPurchasedInfoId] = useState();
  const [itemInfo, setItemInfo] = useState();

  const [modal_list, setmodal_list] = useState(false);
  const {
    readProducts,
    products,
    singlePurchaseId,
    fetchPurchasedId,
    getPurchasesInfo,
    purchases_with_associated_receivers,
    getPurchasesInfoById,
    purchases_with_associated_receiversBYids,
    addReceiverDetails,
  } = useContext(LoginContex);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const [sortBy, setsortBy] = useState(null);
  const [selectMulti, setselectMulti] = useState(null);

  useEffect(() => {
    readProducts();
    fetchPurchasedId();
    getPurchasesInfo();
    getPurchasesInfoById(purchaseInfoId?.value);
  }, [purchaseInfoId?.value]);

  // //console.log(purchases_with_associated_receivers);
  // //console.log(purchaseInfoId?.value);
  //console.log(purchases_with_associated_receiversBYids);

  const respond = purchases_with_associated_receiversBYids.filter(
    (each) => each._id == itemInfo?.value
  );

  //console.log(respond);
  //console.log(itemInfo);
  let itemsRemaining = respond[0]?.quantity - respond[0]?.reciversUploaded;

  document.title = "Recievers Details ";

  const DDL = [
    { name: "Analog", value: "Analog" },
    { name: "IP TV", value: "IP TV" },
    { name: "OTT", value: "OTT" },
  ];

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setSelectedItems([...selectedItems, value]);

    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);

    //console.log(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      { receiverType: "", receiverSerialNumber: "", cardNumber: "" },
    ]);
  };

  const submitReceiverDetails = (e) => {
    e.preventDefault();
    const data = {
      purchaseId: purchaseInfoId.value,
      itemId: itemInfo?.value,
      receiverDetails: inputList,
      itemsRemaining,
    };
    if (!purchaseInfoId || !itemInfo || !inputList) {
      return;
    }
    //console.log(data);
    addReceiverDetails(data);
    setInputList([
      { receiverType: "", receiverSerialNumber: "", cardNumber: "" },
    ]);
    setPurchasedInfoId("");
    setItemInfo("");
    itemsRemaining = 0;

    setTimeout(() => {
      window.location.reload();
    }, 4500);
  };

  // const alreadySelected =
  //console.log(inputList);

  // soloving dropdowns
  const purchaseArr = [];

  for (let i = 0; i < purchases_with_associated_receivers.length; i++) {
    //console.log(purchases_with_associated_receivers[i]._id);

    var dropdownList = {
      value: purchases_with_associated_receivers[i].purchase_id,
      label: ` ${purchases_with_associated_receivers[i].supplierName} -- ${purchases_with_associated_receivers[i].purchaseId}`,
    };

    purchaseArr.push(dropdownList);
  }
  // soloving dropdowns item info
  const itemArr = [];

  for (let i = 0; i < purchases_with_associated_receiversBYids.length; i++) {
    //console.log(purchases_with_associated_receiversBYids[i]._id);

    var dropdownList = {
      value: purchases_with_associated_receiversBYids[i]._id,
      label: ` ${purchases_with_associated_receiversBYids[i].itemName} `,
    };

    itemArr.push(dropdownList);
  }
  // //console.log(itemInfo);
  // //console.log(purchases_with_associated_receiversBYids);

  //console.log(selectedItems);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Recievers Details " pageTitle="Purchase" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <h4 className="card-title mb-0">Recievers Details</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <ToastContainer />
                          <Link to="/RegisterReciver" className="text-white">
                            {/* <Button
                              color="success"
                              className="add-btn me-1"
                              id="create-btn"
                            >
                              <i className="ri-pencil-line align-bottom me-1"></i>{" "}
                              Manage Recivers
                            </Button> */}
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="g-4 mb-3"></Row>
                    <form onSubmit={submitReceiverDetails}>
                      <Row>
                        {/* <Col md={3}>
                        <div className="mb-3">
                          <label>
                            Purchase Info <span className="text-danger">*</span>
                          </label>
                          <Select
                            value={sortBy}
                            onChange={(sortBy) => {
                              setsortBy(sortBy);
                            }}
                            options={sortbyname}
                            id="choices-single-default"
                            className="js-example-basic-single mb-0 "
                            name="state"
                            required
                          />
                        </div>
                      </Col> */}
                        <Col md={3}>
                          <div className="mb-3">
                            <label htmlFor="purchaseDDL">
                              Purchase Info{" "}
                              <span className="text-danger">*</span>
                            </label>
                            {/* <select
                              className="form-select "
                              id="purchaseDDL"
                              name="purchaseDDL"
                              required
                              onChange={(e) =>
                                setPurchasedInfoId(e.target.value)
                              }
                            >
                              <option value="">
                                &hellip; Choose Purchase &hellip;
                              </option>
                              {purchases_with_associated_receivers.map(
                                (purchase, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={purchase.purchase_id}
                                    >
                                      {" "}
                                      {purchase.supplierName} -{" "}
                                      {purchase.purchaseId}{" "}
                                    </option>
                                  );
                                }
                              )}
                            </select> */}

                            <Select
                              options={purchaseArr}
                              onChange={(choice) => setPurchasedInfoId(choice)}
                              required
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <label htmlFor="itemDDL">
                              Item Info <span className="text-danger">*</span>
                            </label>
                            {/* <select
                              className="form-select "
                              id="itemDDL"
                              name="itemDDL"
                              required
                              onChange={(e) => setItemInfo(e.target.value)}
                            >
                              <option value="">
                                &hellip; Choose Item &hellip;
                              </option>
                              {purchases_with_associated_receiversBYids.map(
                                (itemInfo, index) => {
                                  return (
                                    <option value={itemInfo.itemId} key={index}>
                                      {" "}
                                      {itemInfo.itemName}{" "}
                                    </option>
                                  );
                                }
                              )}
                            </select> */}

                            <Select
                              options={itemArr}
                              onChange={(choice) => setItemInfo(choice)}
                              required
                            />
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-2">
                            <label htmlFor="totalReceiversPurchased">
                              Total Recivers Purchased
                            </label>
                            <input
                              type="number"
                              id="totalReceiversPurchased"
                              className="form-control"
                              placeholder="Enter Total Recivers Purchased"
                              disabled
                              defaultValue={0}
                              value={respond[0]?.quantity}
                            />
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-2">
                            <label htmlFor="receiversUploaded">
                              Receivers Uploaded
                            </label>
                            <input
                              type="number"
                              id="receiversUploaded"
                              className="form-control"
                              placeholder="Receivers Uploaded"
                              disabled
                              required
                              defaultValue={0}
                              value={respond[0]?.reciversUploaded}
                            />
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-2">
                            <label htmlFor="itemsRemaining">
                              Items Remaining{" "}
                            </label>
                            <input
                              type="number"
                              id="itemsRemaining"
                              className="form-control"
                              placeholder="Recivers Remaining to Upload"
                              required
                              disabled
                              value={itemsRemaining}
                            />
                          </div>
                        </Col>
                      </Row>

                      <div className="table-responsive table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap"
                          id="cardTable"
                        >
                          <thead className="table-light">
                            <tr>
                              <th className="sort" data-sort="inventory_id">
                                Receiver Type
                              </th>

                              <th className="sort" data-sort="item_id">
                                Receiver Serial No
                              </th>
                              <th
                                className="sort"
                                data-sort="avaliable_quantity"
                              >
                                Card Number
                              </th>
                              <th className="sort" data-sort="action">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {inputList.map((data, index) => {
                              return (
                                <tr key={index} className="">
                                  <td className="item">
                                    <div className="mb-3">
                                      <select
                                        required
                                        className="form-select "
                                        id="reciever type"
                                        name="receiverType"
                                        onChange={(e) =>
                                          handleInputChange(e, index)
                                        }
                                      >
                                        <option value="">
                                          &hellip; Choose Receiver Type &hellip;
                                        </option>
                                        <option value="Analog">Analog</option>
                                        <option value="IP TV">IP TV </option>
                                        <option value="OTT">OTT</option>
                                      </select>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="mb-3">
                                      <Input
                                        required
                                        type="number"
                                        className="form-control"
                                        id="disabledInput"
                                        name="receiverSerialNumber"
                                        onChange={(e) =>
                                          handleInputChange(e, index)
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="mb-3">
                                      <Input
                                        required
                                        type="number"
                                        className="form-control"
                                        id="disabledInput"
                                        name="cardNumber"
                                        onChange={(e) =>
                                          handleInputChange(e, index)
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <div className="remove">
                                        {inputList.length !== 1 && (
                                          <button
                                            className="btn btn-sm btn-danger remove-item-btn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#deleteRecordModal"
                                            //  onClick={() => deletPop(data)}
                                            onClick={() =>
                                              handleRemoveClick(index)
                                            }
                                          >
                                            Remove
                                          </button>
                                        )}
                                      </div>
                                      <div className="edit">
                                        {inputList.length - 1 === index && (
                                          <button
                                            className="btn btn-sm btn-success edit-item-btn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#showModal"
                                            //onClick={() => editPop(data)}
                                            onClick={handleAddClick}
                                          >
                                            Add
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                        <div className="noresult" style={{ display: "none" }}>
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            <p className="text-muted mb-0">
                              We've searched more than 150+ Orders We did not
                              find any orders for you search.
                            </p>
                          </div>
                        </div>
                      </div>
                      <Row className="mt-3"></Row>
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-success"
                          id="add-btn"
                        >
                          Save All
                        </button>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReceiverDetails;
