import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
//import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
const ItemDistripution = () => {
  const [storeName, setstoreName] = useState("");
  const [storeLocation, srtstoreLocation] = useState("");
  const [storeDescription, setstoreDescription] = useState("");
  const [isPrimaryStore, setisPrimaryStore] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [yes, setYes] = useState(true);
  const [id, setId] = useState("");
  const [tempTable, setTempTable] = useState([]);
  const [itemName, setItemName] = useState("");
  const [qty, setQty] = useState("");
  const [desc, setDescription] = useState();
  const [avi, setAvi] = useState();

  ///-----------------------------//

  const [TransferType, setTransferType] = useState("");
  const [TransferDate, setTransferDate] = useState("");
  const [fromStoreToStoreee, setfromStoreToStoreee] = useState();
  const [transferDetails, settransferDetails] = useState("");
  const [referenceNumber, setreferenceNo] = useState(292386);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [clean, setClean] = useState(false);

  ///-------------------------------------//

  // local states
  const [serviceList, setServiceList] = useState([
    {
      itemId: "",
      quantity: "",
      description: "",
    },
  ]);

  // const [purchaseDate, setpurchaseDate] = useState("");
  const [supplierId, setSupplierId] = useState("");
  // const [storeId, setstoreId] = useState("");
  // const [siteId, setsiteId] = useState("");
  // const [purchaseAmount, setpurchaseAmount] = useState("");
  // const [additionalCost, setadditionalCost] = useState("");
  // const [discount, setdiscount] = useState();
  // const [totalAmount, settotalAmount] = useState();
  const {
    stores,
    sites,
    fetchSites,
    fetchStores,
    registerDisturbutionWithSerial,
    fetchItems,
    readProducts,
    items,
    products,
    agents,
    registerItemDisturbution,
    fetchAgents,
    transferItem,
    transferItems,
    fetchItemOtherSINGLEPlusAVA,
    fetchSinglelastsp,
    resetItemTransfer,
    isLoading,User
  } = useContext(LoginContex);
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  // const { fetchPurchase, purchases, removePurchase } = useContext(LoginContex);
  //console.log(yes);
  const dat = { fromStoreToStoreee, from };
  //console.log(dat);
  useEffect(() => {
    fetchStores();
    fetchItems();
    fetchSites();
    readProducts();
    fetchAgents();

    if (fromStoreToStoreee && from) {
      //console.log("kk");
      transferItems(dat);
    }
    if (itemName?.value) {
      //console.log("xogtaaan doono ");
      fetchItemOtherSINGLEPlusAVA(itemName?.value);
    }
  }, [fromStoreToStoreee, from, itemName?.value]);

  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  let tijaabo = fetchSinglelastsp?.[0]?.available;

  // //console.log(products);
  document.title = "Item Transfer";

  // //console.log(transferItem);
  // //console.log(fetchSinglelastsp);
  let userId = User?.data?.user?._id || User?.user?._id;
  //console.log(itemName?.value);
  const ty = tempTable.filter((t) => {
    return t.itemIId === itemName?.value;
  });
  const delCableInfo = (index) => {
    const list = [...tempTable];
    list.splice(index, 1);
    setTempTable(list);
  };
  const addServiceTable = (e) => {
    setItemName("");
    if (!qty || !itemName?.value) {
      showToastMessage("Please provide receiver details ");
      return;
    }
    if (fetchSinglelastsp?.[0]?.available == 0) {
      showToastMessage("The quantity available is zero");
      return;
    }
    if (qty > fetchSinglelastsp?.[0]?.available) {
      showToastMessage(
        "The quantity can not be greater than the quantity available"
      );
      return;
    }
    // setPreview(true);
    if (ty.length > 0) {
      showToastMessage("Duplicates are not allowed");
      return;
    }
    const data = {
      itemName: itemName?.label,
      itemIId: itemName?.value,
      itemId: fetchSinglelastsp?.[0]?._id,
      availableQty: fetchSinglelastsp?.[0]?.available,
      quantity: Number(qty),
      description: desc,
    };
    //console.log(data);
    setTempTable([...tempTable, data]);
    setQty("");
    setDescription("");
    setItemName("");

    // document.getElementById("total").value = "";
    document.getElementById("total").value = "";
    // handleReset();
    //console.log(tijaabo);
    setClean(true);
  };

  const handleSubmit = (e) => {
    //console.log("ddddddddddddd");
    e.preventDefault();

    if (
      !TransferDate ||
      !fromStoreToStoreee ||
      !from ||
      !to
      // !itemName?.value
    ) {
      return showToastMessage("Please provide required values");
    }

    const data = {
      to,
      from,
      details: tempTable,
      distributionType: fromStoreToStoreee,

      distributionDate: TransferDate,
      userId
    };

    registerItemDisturbution(data);
    //console.log(data);
    setmodal_list(false);
    document.forms["myForm"].reset();
    document.getElementById("total").value = "";
    setTempTable([]);
    setfromStoreToStoreee("");
    setFrom("");
    setTransferDate("");
    setTo("");
    resetItemTransfer();
  };

  const deletePuchase = (data) => {
    setmodal_delete(true);
    setId(data._id);
  };

  const delPurchase = () => {
    // removePurchase(id);
    setmodal_delete(false);
  };

  //updateees
  //console.log(isEditing);
  const updateForm = (data) => {
    setmodal_list(true);
    setstoreName(data.storeName);
    srtstoreLocation(data.storeLocation);
    setstoreDescription(data.storeDescription);
    setYes(yes);
    setEditing(true);
    setId(data._id);
  };

  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,
      {
        itemId: "",
        quantity: "",
        description: "",
      },
    ]);
  };

  //console.log(serviceList);
  const handleServiceChange = (e, index) => {
    // fetchQuantityAvailable(serviceList[index]["itemId"]);
    /// api

    // const balance = () => {
    //   const b = ;
    //   return b;
    // };
    // //console.log(balance);
    ///

    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    // fetchQuantityAvailable(serviceList[index]["itemId"]);

    if (e.target.name === "itemId") {
      handleServiceAdd();
      // fetchQuantityAvailable(serviceList[index]["itemId"]);
    }
  };

  const itemsss = [];

  for (let i = 0; i < transferItem.length; i++) {
    //console.log(transferItem[i]?._id);

    var ItemNames = {
      label: `${transferItem[i]?.itemName}`,
      value: `${transferItem[i]?._id}`,
    };

    itemsss.push(ItemNames);
  }
  //console.log(TransferType);

  // const handleReset = () => {
  //   Array.from(document.querySelectorAll("input")).forEach(
  //     (input) => (input.value = "")
  //   );
  //   this.setState({
  //     itemvalues: [{}],
  //   });
  // };

  //console.log(fetchSinglelastsp?.[0]?.available);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Item Transfer " pageTitle="Inventory" />
          <Row>
            <form onSubmit={handleSubmit} id="myForm">
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div>
                          <ToastContainer />
                          <div>
                            <h4 className="card-title mb-0">Item Transfer</h4>
                          </div>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div></div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <div id="Purchasestable">
                      <Row className="g-4 mb-3"></Row>
                      <Row>
                        <Col md={3}>
                          <div>
                            <Label
                              htmlFor="TransferDate"
                              className="form-label"
                            >
                              Transfer Date{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Flatpickr
                              className="form-control"
                              required
                              placeholder="DD/MM/YYYY"
                              options={{
                                dateFormat: "d/m/Y",
                              }}
                              onChange={([date]) => setTransferDate(date)}
                              value={TransferDate}
                            />
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-3">
                            <label>
                              Transfer Type{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-select "
                              id="itemtype"
                              onChange={(e) => {
                                setfromStoreToStoreee(e.target.value);
                                setTempTable([]);
                              }}
                              required
                            >
                              <option value="">Choose Tranfer &hellip;</option>
                              <option value="From Store To Store">
                                From Store To Store
                              </option>
                              <option value="From Site To Store">
                                From Site To Store
                              </option>
                              <option value="From Site To Site">
                                From Site To Site
                              </option>
                              <option value="From Store To Site">
                                {" "}
                                From Store To Site
                              </option>
                              <option value="From Store To Agent">
                                From Store To Agent
                              </option>
                              <option value="From Site to Agent">
                                From Site to Agent
                              </option>
                              <option value="From Agent To Site">
                                From Agent To Site
                              </option>
                              <option value="From Agent To Store">
                                From Agent To Store
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <label>From</label>{" "}
                            <span className="text-danger">*</span>
                            <select
                              className="form-select "
                              id="itemtype"
                              onChange={(e) => setFrom(e.target.value)}
                            >
                              <option value=""> From &hellip;</option>
                              {fromStoreToStoreee == "From Store To Store" &&
                                stores.map((store, index) => (
                                  <option key={index} value={store._id}>
                                    {" "}
                                    {store.storeName}{" "}
                                  </option>
                                ))}
                              {fromStoreToStoreee == "From Store To Site" &&
                                stores.map((store, index) => (
                                  <option key={index} value={store._id}>
                                    {" "}
                                    {store.storeName}{" "}
                                  </option>
                                ))}
                              {fromStoreToStoreee == "From Site To Site" &&
                                sites.map((site, index) => (
                                  <option key={index} value={site._id}>
                                    {" "}
                                    {site.siteName}{" "}
                                  </option>
                                ))}
                              {fromStoreToStoreee == "From Site To Store" &&
                                sites.map((site, index) => (
                                  <option key={index} value={site._id}>
                                    {" "}
                                    {site.siteName}{" "}
                                  </option>
                                ))}
                              {fromStoreToStoreee == "From Store To Agent" &&
                                stores.map((store, index) => (
                                  <option key={index} value={store._id}>
                                    {" "}
                                    {store.storeName}{" "}
                                  </option>
                                ))}
                              {fromStoreToStoreee == "From Site to Agent" &&
                                sites.map((site, index) => (
                                  <option key={index} value={site._id}>
                                    {" "}
                                    {site.siteName}{" "}
                                  </option>
                                ))}
                              {fromStoreToStoreee == "From Agent To Site" &&
                                agents.map((agent, index) => (
                                  <option key={index} value={agent._id}>
                                    {" "}
                                    {agent.salesAgentName}{" "}
                                  </option>
                                ))}
                              {fromStoreToStoreee == "From Agent To Store" &&
                                agents.map((agent, index) => (
                                  <option key={index} value={agent._id}>
                                    {" "}
                                    {agent.salesAgentName}{" "}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <label>To</label>{" "}
                            <span className="text-danger">*</span>
                            <select
                              className="form-select "
                              id="itemtype"
                              onChange={(e) => setTo(e.target.value)}
                            >
                              <option value="">To &hellip;</option>
                              {fromStoreToStoreee == "From Store To Store" &&
                                stores.map((store, index) => (
                                  <option key={index} value={store._id}>
                                    {" "}
                                    {store.storeName}{" "}
                                  </option>
                                ))}
                              {fromStoreToStoreee == "From Site To Store" &&
                                stores.map((store, index) => (
                                  <option key={index} value={store._id}>
                                    {" "}
                                    {store.storeName}{" "}
                                  </option>
                                ))}
                              {fromStoreToStoreee == "From Site To Site" &&
                                sites.map((site, index) => (
                                  <option key={index} value={site._id}>
                                    {" "}
                                    {site.siteName}{" "}
                                  </option>
                                ))}
                              {fromStoreToStoreee == "From Store To Site" &&
                                sites.map((site, index) => (
                                  <option key={index} value={site._id}>
                                    {" "}
                                    {site.siteName}{" "}
                                  </option>
                                ))}
                              {fromStoreToStoreee == "From Store To Agent" &&
                                agents.map((agent, index) => (
                                  <option key={index} value={agent._id}>
                                    {" "}
                                    {agent.salesAgentName}{" "}
                                  </option>
                                ))}
                              {fromStoreToStoreee == "From Site to Agent" &&
                                agents.map((agent, index) => (
                                  <option key={index} value={agent._id}>
                                    {" "}
                                    {agent.salesAgentName}{" "}
                                  </option>
                                ))}
                              {fromStoreToStoreee == "From Agent To Site" &&
                                sites.map((site, index) => (
                                  <option key={index} value={site._id}>
                                    {" "}
                                    {site.siteName}{" "}
                                  </option>
                                ))}
                              {fromStoreToStoreee == "From Agent To Store" &&
                                stores.map((store, index) => (
                                  <option key={index} value={store._id}>
                                    {" "}
                                    {store.storeName}{" "}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </Col>
                      </Row>

                      <div className="table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap"
                          id="cardTable"
                        >
                          <thead className="table-light">
                            <tr>
                              <th className="sort" data-sort="inventory_id">
                                items
                              </th>

                              <th className="sort" data-sort="item_id">
                                available quantity
                              </th>
                              <th className="sort" data-sort="item_id">
                                Quantity
                              </th>
                              <th
                                className="sort"
                                data-sort="avaliable_quantity"
                              >
                                description
                              </th>
                              <th className="sort" data-sort="action">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            <tr className="">
                              <td className="item">
                                <div className="mb-3">
                                  <Select
                                    // className="bg-white"
                                    options={fromStoreToStoreee && itemsss}
                                    // onChange={(choice) => handleChange(choice)}
                                    onChange={(choice) => {
                                      setItemName(choice);
                                      setClean(false);
                                      if (!from) {
                                        showToastMessage(
                                          "please provide the from field"
                                        );
                                      }
                                      if (!fromStoreToStoreee) {
                                        showToastMessage(
                                          "Please choose the disturbution type"
                                        );
                                      }
                                    }}
                                    // re={ref}
                                    // value={itemName?.label}
                                    value={itemsss.filter(function (option) {
                                      return option.value === itemName?.value;
                                    })}
                                    required
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        // text: "orangered",
                                        // primary25: "hotpink",
                                        // primary: "black",
                                      },
                                    })}
                                  />
                                </div>
                              </td>

                              <td className="qty">
                                <div className="mb-3">
                                  <input
                                    style={{ pointerEvents: "none" }}
                                    type="number"
                                    name="available"
                                    className="form-control"
                                    placeholder="quantity available"
                                    // fetchSinglelastsp?.[0]?.available || 0
                                    value={
                                      // fetchSinglelastsp?.[0]?.available || 0
                                      clean
                                        ? " "
                                        : fetchSinglelastsp?.[0]?.available || 0
                                    }
                                    id="total"
                                  />
                                </div>
                              </td>
                              <td className="qty">
                                <div className="mb-3">
                                  <input
                                    type="number"
                                    name="quantity"
                                    className="form-control"
                                    placeholder="Quantity to disturbute"
                                    onChange={(e) => setQty(e.target.value)}
                                    value={qty}
                                  />
                                </div>
                              </td>
                              <td className="qty">
                                <div className="mb-3">
                                  <input
                                    type="text"
                                    name="desc"
                                    className="form-control"
                                    placeholder="Enter description"
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                    value={desc}
                                  />
                                </div>
                              </td>

                              <td>
                                <button
                                  type="button"
                                  className="btn btn-success mb-3 mx-3"
                                  onClick={(e) => addServiceTable(e)}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                          {tempTable?.length > 0 && (
                            <tbody className="list form-check-all">
                              {tempTable.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="Store_name">
                                      {" "}
                                      {data.itemName}{" "}
                                    </td>
                                    <td className="location">
                                      {" "}
                                      {data.availableQty}{" "}
                                    </td>
                                    <td className="description">
                                      {" "}
                                      {data.quantity}{" "}
                                    </td>
                                    <td className="description">
                                      {" "}
                                      {data.description}{" "}
                                    </td>

                                    <td>
                                      <div className="d-flex gap-2">
                                        <div className="remove">
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-danger remove-item-btn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#deleteRecordModal"
                                            onClick={() => delCableInfo(index)}
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          )}
                        </table>
                        <div className="noresult" style={{ display: "none" }}>
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            <p className="text-muted mb-0">
                              We've searched more than 150+ Orders We did not
                              find any orders for you search.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end">
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-success"
                            id="add-btn"
                            disabled={isLoading}
                          >
                            {isLoading ? "please wait..." : "save"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </form>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        <ModalHeader className="bg-light p-3 pull right">
          Add New Store
          <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close float-right"
            aria-label="Close"
          ></Button>
        </ModalHeader>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => delPurchase()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ItemDistripution;
