import React, { useState, useEffect, useContext, useMemo } from "react";
import Select from "react-select";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import TableContainer from "../../Components/Common/TableContainer";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LeaveEmployee = () => {
  const [modal_Advance_list, setAdvance_list] = useState(false);
  // const [modal_Advance_list, setAdvance_list] = useState([
  //     {  },
  // ]);

  const tog_list = () => {
    // setloanPeriod("");
    setmodal_list(!modal_list);
  };

  // const tog_list = () => {
  //     setmodal_list(!modal_list);
  //   };

  const {
    advanceRegister,
    advanceUpdate,
    advanceDelete,
    fetchEmployees,
    getEmployee,
    getAdvance,
    fetchAdvance,User,
    fetchLeaveCategories,
    LeaveCategories,
    registerEmployeeLeaves,
    EmployeeLeaves,
    fetchEmployeeLeaves,
    updateEmployeeLeave,
    deleteEmployeeLeave
    
  } = useContext(LoginContex);

  // Employees
  const [empId, setEmployeeId] = useState();
  const [category, setCategory] = useState();
  const [sqn, setSqn] = useState();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [remarks, setRemarks] = useState();
  const [object_id, setObject] = useState();

  const [isUpdating, setIsUpdating] = useState(false);

  const [advanceData, setAdvanceData] = useState("");

  const [modal_list, setmodal_list] = useState(false);
  // const tog_list = () => {
  //     setmodal_list(!modal_list);
  // };

  const today = new Date().toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).split('/').reverse().join('/');
// This gets the current date and time
// let tomorrow = new Date(); // Create a new date object for manipulation
// tomorrow.setDate(today.getDate() + 1)
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    fetchEmployees();
    fetchEmployeeLeaves();
    fetchLeaveCategories();
  }, []);

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  //   //console.log(getAdvance)

  document.title = "Leave Employee  ";
  let userId = User?.data?.user?._id || User?.user?._id;
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "empId.empName",
        filterable: false,
      },
      {
        Header: "Category",
        accessor: "category.category",
        filterable: false,
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
      },
     
      {
        Header: "Start Date ",
        accessor: "startDate",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      {
        Header: "End Date ",
        accessor: "endDate",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={(row) => {
                    const empData = cellProps.row.original;
                    updateLeave(empData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={(row) => {
                    const empData = cellProps.row.original;
                    deletPop(empData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ]
    // [handleCustomerClick]
  );

  const handleServiceAdd = () => {
    setAdvance_list([...modal_Advance_list, {}]);
  };

  const handleServiceRemove = (index) => {
    const list = [...modal_Advance_list];
    list.splice(index, 1);
    setAdvance_list(list);
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...modal_Advance_list];
    list[index][name] = value;
    setAdvance_list(list);
    // handleServiceAdd();
  };

  const calculateLoanPeriod = () => {
    //console.log("Waaa Tijaabo From ahmed");
  };
  const test = (e, index) => {
    handleServiceChange(e, index);
    if (e.target.name === "item") {
      handleServiceAdd();
    }
  };

  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handlerSubmit = (e) => {
    let start =  moment(startDate).utc().add(1, 'days').format("YYYY-MM-DD")
    let end =  moment(endDate).utc().add(1, 'days').format("YYYY-MM-DD")
    if (!isUpdating) {
      e.preventDefault();

      if (!empId || !category || !startDate || !endDate) {
        showToastMessage("Please fill the required Fields");
        return;
      }

      const data = {
        empId,
        category,
        sqn,
        startDate:start,
        endDate:end,
        description: remarks,
        userId
      };
      // //console.log(data)
      registerEmployeeLeaves(data);
      setEmployeeId("");
      setCategory("");
   
      setStartDate("");
      setEndDate("");
      setRemarks("");
      setmodal_list(false);
    } else {
      e.preventDefault();
      const data = {
        category,
        
        startDate:start,
        endDate:end,
        description: remarks,
        userId,
        id: object_id,
      };

      if (isUpdating) {
        //console.log(data)
        updateEmployeeLeave(data);
        setEmployeeId("");

        setCategory("");
        setStartDate("");
        setEndDate("");
        setRemarks("");
        setIsUpdating(false);
        setmodal_list(false);
      }
    }
  };

  // const calc = (value, index) => {
  //   let lnAmount = 0;
  //   let lnPeriod = 0;

  //   if (index == 2) {
  //     if (!loanAmount) {
  //       lnAmount = lnAmount;
  //     } else {
  //       lnAmount = loanAmount / value;
  //     }
  //     //console.log(lnPeriod);
  //     setPerMonthDedection(lnAmount);
  //   }

  //   if (index == 1) {
  //     if (!loanPeriod) {
  //       lnPeriod = lnPeriod;
  //     } else {
  //       lnPeriod = loanAmount / loanPeriod;
  //     }
  //     setPerMonthDedection(lnPeriod.toFixed(3));
  //   }
  // };

  const deletPop = (data) => {
    setmodal_delete(true);
    setObject(data._id);
  };
  const deleteAdvance = () => {
    deleteEmployeeLeave(object_id);
    setmodal_delete(false);
    // alert('Employee Removed Successfully');
  };
  const addModal = () => {
    tog_list();
    setEmployeeId("");
  
    setStartDate("");
    setEndDate("");
    setRemarks("");
    setIsUpdating(false);
  };

  const Employees = [];

  for (let i = 0; i < getEmployee.length; i++) {
    // //console.log(getEmployee[i]._id);

    var dropdownList = {
      value: getEmployee[i]._id,
      label: ` ${getEmployee[i].empName}`,
      sqn: ` ${getEmployee[i].sqn}`,
    };

    Employees.push(dropdownList);
  }
  const CategoryArr = [];

  for (let i = 0; i < LeaveCategories?.length; i++) {
    // //console.log(LeaveCategories[i]._id);

    var dropdownList = {
      value: LeaveCategories[i]?._id,
      label: ` ${LeaveCategories[i]?.category}`,
    };

    CategoryArr.push(dropdownList);
  }

  const handleTypeSelect = (e) => {
    // const sqn = e.sqn;
    setSqn(e.sqn)
    setEmployeeId(e.value);
  };
  const handleCategorySelect = (e) => {
    setCategory(e.value);
  };

  const updateLeave = (data) => {
    //console.log(data)
    setIsUpdating(true);
    tog_list();
    setEmployeeId(data.empId?._id);
    setCategory(data.category?._id);
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    setRemarks(data.description);
    setObject(data._id);
  };

  // //console.log(getAdvance);
  const sortAdvance = [];
  for (let i = 0; i < getAdvance?.length; i++) {
    let singleArr = [
      getAdvance[i].empName,
      getAdvance[i].loanAmount,
      getAdvance[i].perMonthDedection,
      getAdvance[i].loanPeriod,
      moment(getAdvance[i].startDate).utc().format("YYYY-MM-DD"),
      moment(getAdvance[i].endDate).utc().format("YYYY-MM-DD"),
      getAdvance[i]._id,
      getAdvance[i].empId,
      getAdvance[i].description,
      getAdvance[i].year,
      getAdvance[i].month,
    ];

    sortAdvance.push(singleArr);
  }

  // //console.log(modal_Advance_list);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Employee Leave " pageTitle="Employee Leave" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h4 className="card-title mb-0">Add Item, Edit & Remove</h4> */}
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">Employee Leave</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            // onClick={() => tog_list()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row className="g-4 mb-3"></Row>
                 
                  <TableContainer
                    columns={columns}
                    data={EmployeeLeaves || []}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    customPageSize={10}
                    className="custom-header-css"
                    // handleCustomerClick={handleCustomerClicks}
                    // isCustomerFilter={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Advance Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        {/* <ModalHeader className="bg-light p-3">
                    Add Advance
                    <Button
                        type="button"
                        onClick={() => {
                            setmodal_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </ModalHeader> */}

        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">Employee Leave Registration</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => {
              setmodal_list(false);
          }}
          ></button>
        </div>

        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label>Employee Name</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <Select
                
                    options={Employees}
                    

                    onChange={handleTypeSelect}
                    value={Employees?.filter(function (option) {
                      
                      return option.value === empId;
                    })}
                    defaultValue={{ label: "Select Employee", value: empId }}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors
                  
                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>Category Name</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <Select
                    // className="bg-white"
                    options={CategoryArr}
                    // onChange={(choice) =>
                    //   setEmployeeId(choice.value)
                    // }

                    onChange={handleCategorySelect}
                    value={CategoryArr?.filter(function (option) {
                      return option.value === category;
                    })}
                    defaultValue={{ label: "Select Category", value: category }}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        // text: "orangered",
                        // primary25: "hotpink",
                        // primary: "black",
                      },
                    })}
                  />
                </div>
              </Col>
             
            </Row>
        
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label>Start Date</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  {/* 
                    <Flatpickr
                        className="form-control"
                        placeholder="DD/MM/YYYY"
                        options={{
                          dateFormat: "d/m/Y",
                        }}
                           onChange={(e) => setStartDate(e.target.value)}
                    value={startDate}
                      /> */}

                  <Flatpickr
                    className="form-control"
                    placeholder="DD/MM/YYYY"
                    
                    options={{
                      dateFormat: "d/m/Y",
                      defaultDate: "today",
                      minDate:'today',
                      "disable": [
                        function(date) {
                            // return true to disable
                            return (date.getDay() === 5);
                
                        }
                    ]
                    }}
                    onChange={([date]) => setStartDate(date)}
                    value={startDate}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>End Date</label>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <Flatpickr
                    className="form-control"
                    placeholder="DD/MM/YYYY"
                    
                  
                    // disabled="disabled"
                    options={{
                      dateFormat: "d/m/Y",
                      defaultDate: "today",
                      minDate:'today',
                      "disable": [
                        function(date) {
                            // return true to disable
                            return (date.getDay() === 5);
                
                        }
                    ]
                    }}
                    onChange={([date]) => setEndDate(date)}
                    value={endDate}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <label>Remarks</label>
                  <div className="mb-3">
                    <textarea
                      type="text"
                      id="Employee-field"
                      className="form-control"
                      placeholder="Enter Remarks"
                      onChange={(e) => setRemarks(e.target.value)}
                      value={remarks}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isUpdating ? "Update" : "save"}
                {/* Save */}
              </button>
            </div>
          </ModalFooter>
        </form>

        {/* =================================== */}
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={deleteAdvance}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default LeaveEmployee;
