export const REGISTER_USER = "REGISTER_USER";

export const REGISTER_LOGIN = "REGISTER_LOGIN";
export const REGISTER_USER_SUCCESSFUL = "REGISTER_USER_SUCCESSFUL";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const RESET_REGISTER_FLAG = "RESET_REGISTER_FLAG";
export const GET_ALL_USER = "GET_ALL_USER";

export const REGISTER_ITEM_SUCCESS = "REGISTER_ITEM_SUCCESS";
export const REGISTER_ITEM_ERROR = "REGISTER_ITEM_ERROR";

export const FETCH_ITEMS_SUCCESS = "FETCH_ITEMS_SUCCESS";
export const UPDATE_SUPPLIER_SUCCESS = "UPDATE_SUPPLIER_SUCCESS";
export const UPDATE_SUPPLIER_ERROR = "UPDATE_SUPPLIER_ERROR";

export const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS";

export const REGISTER_ITEM_TYPE_SUCCESS = "REGISTER_ITEM_TYPE_SUCCESS";

export const DELETE_ITEMTYPE_SUCCESS = "DELETE_ITEMTYPE_SUCCESS";

export const REGISTER_CUSTOMER_SUCCESS = "REGISTER_CUSTOMER_SUCCESS";
export const REGISTER_CUSTOMER_ERROR = "REGISTER_CUSTOMER_ERROR";

export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";

export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";

export const REGISTER_SITE_SUCCESS = "REGISTER_SITE_SUCCESS";

export const FETCH_SITES_SUCCESS = "FETCH_SITES_SUCCESS";

export const DELETE_SITE_SUCCESS = "DELETE_SITE_SUCCESS";

export const REGISTER_STORE_SUCCESS = "REGISTER_STORE_SUCCESS";

export const FETCH_STORE_SUCCESS = "FETCH_STORE_SUCCESS";

export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS";

export const REGISTER_ITEM__SUCCESS = "REGISTER_ITEM__SUCCESS";

export const DELETE_products_SUCCESS = "DELETE_products_SUCCESS";

export const REGISTER_PURCHASES__SUCCESS = "REGISTER_PURCHASES__SUCCESS";

export const REGISTER_PAYMENT__SUCCESS = "REGISTER_PAYMENT__SUCCESS";

export const REGISTER_STOCKS__SUCCESS = "REGISTER_STOCKS__SUCCESS";

export const FETCH_UPDATED_MANAGE_SUCCESS = "FETCH_UPDATED_MANAGE_SUCCESS";

export const FETCH_CHANELLS_SUCCESS = "FETCH_CHANELLS_SUCCESS";

export const FETCH_AGENTS_SUCCESS = "FETCH_AGENTS_SUCCESS";

export const REGISTER_EMPLOYEE_ERROR = "REGISTER_EMPLOYEE_ERROR";
export const REGISTER_ADVANCE_ERROR = "REGISTER_ADVANCE_ERROR";

export const REGISTER_EMPLOYEE_SUCCESS = "REGISTER_EMPLOYEE_SUCCESS";
export const FETCH_EMPLOYEE_SUCCESS = "FETCH_EMPLOYEE_SUCCESS";

export const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
export const FETCH_GROUPS_ERROR = "FETCH_GROUPS_ERROR";
export const FETCH_ZONES_SUCCESS = "FETCH_ZONES_SUCCESS";
export const FETCH_ZONES_ERROR = "FETCH_ZONES_ERROR";
export const FETCH_QUANTITY_AV_SUCCESS = "FETCH_QUANTITY_AV_SUCCESS";
export const FETCH_EMPTITLES_SUCCESS = "FETCH_EMPTITLES_SUCCESS";
export const FETCH_GETALLTITLE_AV_SUCCESS = "FETCH_GETALLTITLE_AV_SUCCESS";
export const FETCH_DEPARTMENT_AV_SUCCESS = "FETCH_DEPARTMENT_AV_SUCCESS";
export const GET_EMPLOYEE__SUCCESS = "GET_EMPLOYEE__SUCCESS";
export const GET_ADVANCE__SUCCESS = "GET_ADVANCE__SUCCESS";
export const REGISTER_ADVANCE_SUCCESS = "REGISTER_ADVANCE_SUCCESS";

export const FETCH_SINGLE_CUSTOMER = "FETCH_SINGLE_CUSTOMER";
export const FETCH_SINGLE_SITE_CARDS = "FETCH_SINGLE_SITE_CARDS";

export const FETCH__RECEIVERS = "FETCH__RECEIVERS";
export const REGISTER_DEPARTMENT_SUCCESS = "REGISTER_DEPARTMENT_SUCCESS";
export const FETCH_DEPARTMENT_SUCCESS = "FETCH_DEPARTMENT_SUCCESS";
export const REGISTER_DEPARTMENT_ERROR = "REGISTER_DEPARTMENT_ERROR";
export const REGISTER_DEDUCTION__SUCCESS = "REGISTER_DEDUCTION__SUCCESS";
export const REGISTER_DEDUCTION__ERROR = "REGISTER_DEDUCTION__ERROR";
export const FETCH_DEDUCTION_SUCCESS = "FETCH_DEDUCTION_SUCCESS";
export const REGISTER_TITLE_SUCCESS = "REGISTER_TITLE_SUCCESS";
export const REGISTER_TITLE_ERROR = "REGISTER_TITLE_ERROR";
export const REGISTER_ZONES_SUCCESS = "REGISTER_ZONES_SUCCESS";
export const REGISTER_ZONES_ERROR = "REGISTER_ZONES_ERROR";
export const REGISTER_GROUP__SUCCESS = "REGISTER_GROUP__SUCCESS";
export const REGISTER_GROUP__ERROR = "REGISTER_GROUP__ERROR";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const FETCH_GROUP_ZONES_SUCCESS = "FETCH_GROUP_ZONES_SUCCESS";
export const REGISTER_ASSIGNGROUPS_SUCCESS = "REGISTER_ASSIGNGROUPS_SUCCESS";
export const REGISTER_ASSIGNGROUPS_ERROR = "REGISTER_ASSIGNGROUPS_ERROR";
export const FETCH_ALL_ZONES_SUCCESS = "FETCH_ALL_ZONES_SUCCESS";
export const DELETE_DEDUCTION_SUCCESS = "DELETE_DEDUCTION_SUCCESS";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_TITLE_SUCCESS = "DELETE_TITLE_SUCCESS";
export const FETCH_CARDS_SUCCESS = "FETCH_CARDS_SUCCESS";
export const FETCH_GROUPSTOASSIGN_SUCCESS = "FETCH_GROUPSTOASSIGN_SUCCESS";
export const FETCH_ZONESTOASSIGN_SUCCESS = "FETCH_ZONESTOASSIGN_SUCCESS";
export const FETCH_EMPLOYEESTOASSIGN_SUCCESS =
  "FETCH_EMPLOYEESTOASSIGN_SUCCESS";
export const FETCH_GETBYIDZONES_SUCCESS = "FETCH_GETBYIDZONES_SUCCESS";

export const LOGUT_USER = "LOGUT_USER";

export const FETCH_SINGLE_PURCHASE_ID = "FETCH_SINGLE_PURCHASE_ID";
export const FETCH_ALL_IPTV_SUCCESS = "FETCH_ALL_IPTV_SUCCESS";

export const FETCH_SINGLE_CARD_NUMBER = "FETCH_SINGLE_CARD_NUMBER";
export const Fetch_associated_receivers = "Fetch_associated_receivers";
export const GET_BYID_CUSTOMERS_SUCCESS = "GET_BYID_CUSTOMERS_SUCCESS";
export const GET_BYID_RECIVE_SUCCESS = "GET_BYID_RECIVE_SUCCESS";
export const FETCH_GETZONES_SUCCESS = "FETCH_GETZONES_SUCCESS";
export const GET_BYID_GROUP_ALL_SUCCESS = "GET_BYID_GROUP_ALL_SUCCESS";

export const FETCH_OPEN_CARDS_SUCCESS = "FETCH_OPEN_CARDS_SUCCESS";
export const FETCH_CLOSE_CARDS_SUCCESS = "FETCH_CLOSE_CARDS_SUCCESS";
export const FETCH_CABLES_SUCCESS = "FETCH_CABLES_SUCCESS";
export const FETCH__ITEMRECEIVERS = "FETCH__ITEMRECEIVERS";
export const GET_BYID_GROUP_SUCCESS = "GET_BYID_GROUP_SUCCESS";
// export const FETCH_GETBYIDZONES_SUCCESS = "FETCH_GETBYIDZONES_SUCCESS";
export const FETCH_GETBYIDEMPLOYEE_SUCCESS = "FETCH_GETBYIDEMPLOYEE_SUCCESS";
export const GET_TICKETS__SUCCESS = "GET_TICKETS__SUCCESS";
export const FETCH_ASSIGNGROUPS_SUCCESS = "FETCH_ASSIGNGROUPS_SUCCESS";
export const FETCH_GETBYIDZONESASSIGN_SUCCESS =
  "FETCH_GETBYIDZONESASSIGN_SUCCESS";
export const FETCH_GET_SITEID_SUCCESS = "FETCH_GET_SITEID_SUCCESS";
export const REGISTER_PURCHASEORDERS__SUCCESS =
  "REGISTER_PURCHASEORDERS__SUCCESS";
export const GET_BYID_GROUP_CABLE_ALL_SUCCESS =
  "GET_BYID_GROUP_CABLE_ALL_SUCCESS";

export const FETCH_GETBYIDZONESCABLE_SUCCESS =
  "FETCH_GETBYIDZONESCABLE_SUCCESS";
export const FETCH_PURCHASED_ASSOCIATED_RECEIVERS_BY_ID =
  "FETCH_PURCHASED_ASSOCIATED_RECEIVERS_BY_ID";

export const REGISTER_PAYMENT__AMOUNT = "REGISTER_PAYMENT__AMOUNT";

export const ITEM_NOT_RECEIVER = "ITEM_NOT_RECEIVER";
export const FETCH_RECEIVER_ITEM = "FETCH_RECEIVER_ITEM";

export const FETCH_RECEIVER_CARD_NUMBER_AND_SERIALS =
  "FETCH_RECEIVER_CARD_NUMBER_AND_SERIALS";
export const FETCH_PURCHASE_ORDERS = "FETCH_PURCHASE_ORDERS";
export const FETCH_PURCHASE_ORDERS_BY_ID = "FETCH_PURCHASE_ORDERS_BY_ID ";

export const FETCH_ALL_PURCHASES = "FETCH_ALL_PURCHASES";
export const FETCH_PENDING_TICKETS = "FETCH_PENDING_TICKETS";
export const REGISTER_COMPLETETICKETS_ERROR = "REGISTER_COMPLETETICKETS_ERROR";
export const FETCH_IPTV_HORMUUD_ERROR = "FETCH_IPTV_HORMUUD_ERROR";
export const FETCH_APIHORMUUDIPTV_SUCCESS = "FETCH_APIHORMUUDIPTV_SUCCESS";

export const FECH_PURCHASE_RECEIVE_BY_ID = "FECH_PURCHASE_RECEIVE_BY_ID";
export const FETCH_IPTV_BY_ID = "FETCH_IPTV_BY_ID";
export const FECH_PURCHASE_ORDERS_RECEIVERS = "FECH_PURCHASE_ORDERS_RECEIVERS";

export const FETCH_PURCHASE_ORDERS_BY_ID_RECEIVER =
  "FETCH_PURCHASE_ORDERS_BY_ID_RECEIVER";

export const GET_BYID_CARD_NUMBER = "GET_BYID_CARD_NUMBER";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_ZONES_SUCCESS = "DELETE_ZONES_SUCCESS";
export const DELETE_ASSIGNGROUP_SUCCESS = "DELETE_ASSIGNGROUP_SUCCESS";

export const FETCH_INVENTORY_REPORTS = "FETCH_INVENTORY_REPORTS";
export const FETCH_PURCHASE_REPORTS = "FETCH_PURCHASE_REPORTS";
export const FETCH_SALES_REPORTS = "FETCH_SALES_REPORTS";
export const DELETE_BANK_SUCCESS = "DELETE_BANK_SUCCESS";
export const FETCH_BANK_SUCCESS = "FETCH_BANK_SUCCESS";
export const REGISTER_BANK_SUCCESS = "REGISTER_BANK_SUCCESS";
export const REGISTER_BANK_ERROR = "REGISTER_BANK_ERROR";
export const FETCH_ITEMBYID_SUCCESS = "FETCH_ITEMBYID_SUCCESS";
export const FETCH_CUSTOMERSBYID_SUCCESS = "FETCH_CUSTOMERSBYID_SUCCESS";

export const PURCHASE_REPORT_DATE = "PURCHASE_REPORT_DATE";
export const PURCHASE_SALES_DATE = "PURCHASE_SALES_DATE";

export const PAYMENT_BY_ID = "PAYMENT_BY_ID";

export const PAYMENT_FILTER_DATE = "PAYMENT_FILTER_DATE";
export const UPLOADED_ERRORS = "UPLOADED ERRORS";

export const MANAGE_PURCHASE_FILTER = "MANAGE_PURCHASE_FILTER";
export const FETCH_CABLE_BIGIN = "FETCH_CABLE_BIGIN";
export const FETCH_GROUPS_BIGIN = "FETCH_GROUPS_BIGIN";

export const FETCH_LAST_SERIAL = "FETCH_LAST_SERIAL";
export const FETCH_COMMISSION_SUCCESS = "FETCH_COMMISSION_SUCCESS";
export const REGISTER_PAYROLL_ERROR = "REGISTER_PAYROLL_ERROR";

export const FETCH_CABLE_INFO = "FETCH_CABLE_INFO";

export const ITEM_NOT_RECEIVER_by_id = "ITEM_NOT_RECEIVER_by_id";

export const FETCH_LAST_SERIAL_sp = "FETCH_LAST_SERIAL_sp";
export const FETCH_LAST_SERIAL_sp_SINGLE = "FETCH_LAST_SERIAL_sp_SINGLE";

export const FETCH_RECEIVER = "FETCH_RECEIVER";

export const TRANSFER_ITEM = "TRANSFER_ITEM";

export const RESET_ITEM_TRANSFER = "RESET_ITEM_TRANSFER";
export const RESET_RECEIVER_TRANSFER = "RESET_RECEIVER_TRANSFER";

export const RESET_BILLING_RECEIVER = "RESET_BILLING_RECEIVER";
export const RESET_BILLING_ITEM = "RESET_BILLING_ITEM";

export const RESET_ITEM_SALES_ITEM = "RESET_ITEM_SALES_ITEM";
export const RESET_ITEM_SALES_RECEIVER = "RESET_ITEM_SALES_RECEIVER";
export const FETCH_COMMISSIONMAIN_SUCCESS = "FETCH_COMMISSIONMAIN_SUCCESS";
export const RESET_THE_CARDNUMBER = "RESET_THE_CARDNUMBER";

export const RESET_QUANTITY_AVAILABLE = "RESET_QUANTITY_AVAILABLE";
export const FETCH_PAYROLLYEARMONTH_SUCCESS = "FETCH_PAYROLLYEARMONTH_SUCCESS";
export const FETCH_PENDINGPAYROLL_SUCCESS = "FETCH_PENDINGPAYROLL_SUCCESS";

export const GET_CUSTOMER_CARD_LIST = "GET_CUSTOMER_CARD_LIST";

export const GET_CLOSED_CARDS = "GET_CLOSED_CARDS";
export const GET_MENUS = "GET_MENUS";

export const LOADING2 = "LOADING2";
export const FETCH_MESSAGE_SUCCESS = "FETCH_MESSAGE_SUCCESS";
export const FETCH_SINGLE_EMPLOYEE = "FETCH_SINGLE_EMPLOYEE";

export const RECEIVER_DISTURBUTION_BEGIN = "RECEIVER_DISTURBUTION_BEGIN";
export const RECEIVER_DISTURBUTION_SUCCESS = "RECEIVER_DISTURBUTION_SUCCESS";

export const ITEM_DISTURBUTION_BEGIN = "ITEM_DISTURBUTION_BEGIN";
export const ITEM_DISTURBUTION_SUCCESS = "ITEM_DISTURBUTION_SUCCESS";

export const ITEM_REG_BEGIN = "ITEM_REG_BEGIN";

export const SUPPLIER_REG_BEGIN = "SUPPLIER_REG_BEGIN";

export const SITE_REG_BEGIN = "SITE_REG_BEGIN";

export const CUSTOMER_REG_BEGIN = "CUSTOMER_REG_BEGIN";

export const STORE_REG_BEGIN = "STORE_REG_BEGIN";

export const SALESAGENT_REG_BEGIN = "SALESAGENT_REG_BEGIN";

export const PACKAGE_REG_BEGIN = "PACKAGE_REG_BEGIN";

export const GET_SUPERVISORS = "GET_SUPERVISORS";

export const GET_SUPERVISORS_SPECIAL = "GET_SUPERVISORS_SPECIAL";
export const GET_SINGLE_MENU = "GET_SINGLE_MENU";

export const GET_SIDEBAR_MENU = "GET_SIDEBAR_MENU";

export const AUTORECEPTS = "AUTORECEPTS";

export const GET_DISTRICTS = "GET_DISTRICTS";
export const FETCH_HOR_IPT = "FETCH_HOR_IPT";
export const FETCH_OPEN_CARD_REPORT = "FETCH_OPEN_CARD_REPORT";
export const FETCH_ALL_OPEN_CARD_REPORT = "FETCH_ALL_OPEN_CARD_REPORT";
export const FETCH_CABLE_REPORT = "FETCH_CABLE_REPORT";
export const OPEN_CARD_REG_END = "OPEN_CARD_REG_END";
export const OPEN_CARD_REG_BEGIN = "OPEN_CARD_REG_BEGIN";
export const FETCH_CLOSE_CARDS_REPORT = "FETCH_CLOSE_CARDS_REPORT";
export const FETCH_PURCHASE_RECEIVE_REPORT = "FETCH_PURCHASE_RECEIVE_REPORT";
export const FETCH_PURCHASE_ORDER_REPORT = "FETCH_PURCHASE_ORDER_REPORT";
export const FETCH_CUSTOMER_LIST_REPORT = "FETCH_CUSTOMER_LIST_REPORT";
export const FETCH_CUSTOMER_STATEMENT_REPORT = "FETCH_CUSTOMER_STATEMENT_REPORT";
export const FETCH_TICKET_REG_REPORT = "FETCH_TICKET_REG_REPORT";
export const FETCH_TICKET_SUPERVISOR_REPORT = "FETCH_TICKET_SUPERVISOR_REPORT";
export const FETCH_ADVANCE_REPORT = "FETCH_ADVANCE_REPORT";
export const FETCH_ALL_PURCHASES_BEGIN = "FETCH_ALL_PURCHASES_BEGIN";
export const MANAGE_PURCHASE_FILTER_BEGIN = "MANAGE_PURCHASE_FILTER_BEGIN";
export const GET_OTP_NUM = "GET_OTP_NUM";
export const OTTP_BEGIN = "OTTP_BEGIN";
export const DEDUCTION_REPORT = "DEDUCTION_REPORT";
export const SUPPLER_REPORT = "SUPPLER_REPORT";
export const FETCH_PAYROLL_SUMMARY_REPORT = "FETCH_PAYROLL_SUMMARY_REPORT"
export const FETCH_EMPLOYEE_REPORT = "FETCH_EMPLOYEE_REPORT"
// export const TRANSFER_TICKET = "TRANSFER_TICKET"
export const GET_ALL_YEARS = "GET_ALL_YEARS"
export const FETCH_PAYROLL_DETAILS_REPORT = "FETCH_PAYROLL_DETAILS_REPORT"

export const FETCH_TICKET_TRANSFER_REPORT = "FETCH_TICKET_TRANSFER_REPORT"

export const  FETCH_TICKETS_SUMMARY_REPORT = " FETCH_TICKETS_SUMMARY_REPORT"
export const  FETCH_TICKET_SUPERVISOR_SUMMARY_REPORT = " FETCH_TICKET_SUPERVISOR_SUMMARY_REPORT"
export const  CUSTOMER_LIST = " CUSTOMER_LIST"
export const  EXPIRE_CARDS = " EXPIRE_CARDS"
export const  MANUAL_OPEN_CARD = " MANUAL_OPEN_CARD"
export const  PENDING_TRANSFER = " PENDING_TRANSFER"
export const  TODAYS_TICKETS = " TODAYS_TICKETS"
export const  TICKETS_SUPERVISOR_ADD_BEGIN = " TICKETS_SUPERVISOR_ADD_BEGIN"
export const  TICKETS_SUPERVISOR_ADD_SUCESS = " TICKETS_SUPERVISOR_ADD_SUCESS"
export const  TOP_TEN_USERS = " TOP_TEN_USERS"
export const  REG_SHITS = " REG_SHITS"
export const  IPTVS_ONLY = " IPTVS_ONLY"
export const  NASIYE_PAYMENTS = " NASIYE_PAYMENTS"
export const  HUBI_LINE_BEGIN = " HUBI_LINE_BEGIN"
export const  HUBI_LINE = " HUBI_LINE"
export const  OPEN_IPTV_BEGIN = " OPEN_IPTV_BEGIN"
export const  OPEN_IPTV = " OPEN_IPTV"
export const  REGISTER_IPTV_BEGIN = " REGISTER_IPTV_BEGIN"
export const  REGISTER_IPTV_SUCCESS = " REGISTER_IPTV_SUCCESS"
export const  REGISTER_IPTV_ERROR = " REGISTER_IPTV_ERROR"
export const  IPTV_CUSTOMER_RECHARGE = "IPTV_CUSTOMER_RECHARGE"
export const  OTT_CUSTOMER_RECHARGE = "OTT_CUSTOMER_RECHARGE"
export const  FETCH_CUSTOMERIPTV_REPORT = " FETCH_CUSTOMERIPTV_REPORT"
export const  EXPIRED_IPTVS_REPORT  = "EXPIRED_IPTVS_REPORT"
export const  EXPIRED_OTT_REPORT  = "EXPIRED_OTT_REPORT"
export const  GET_MAC_ADDRESS_AND_SERIAL_NUMBER  = "GET_MAC_ADDRESS_AND_SERIAL_NUMBER"
export const  FETCH_ONLY_CUSTOMERS_WITH_IPTVS  = "FETCH_ONLY_CUSTOMERS_WITH_IPTVS"
export const  TRASFER_IPTV_SUCCESS  = "TRASFER_IPTV_SUCCESS"
export const  TRANSFER_IPTV_BEGIN  = "TRANSFER_IPTV_BEGIN"
export const  SOLVED_REPORT  = "SOLVED_REPORT"
export const  SOLVED_CUSTOMERS_REPORT  = "SOLVED_CUSTOMERS_REPORT"
export const  UPDATED_CUSTOMERS  = "UPDATED_CUSTOMERS"
export const  UPLOAD_IPTV_BEGIN  = "UPLOAD_IPTV_BEGIN"
export const  UPLOAD_IPTV_CUSTOMERS  = "UPLOAD_IPTV_CUSTOMERS"
export const  REGISTER_NEW_CABLE_BEGIN  = "REGISTER_NEW_CABLE_BEGIN"
export const  REGISTER_NEW_CABLE_SUCCESS  = "REGISTER_NEW_CABLE_SUCCESS"
export const  REGISTER_NEW_CABLE_ERROR  = "REGISTER_NEW_CABLE_ERROR"
export const  REGISTER_NEW_ITEM_SALES_BEGIN  = "REGISTER_NEW_ITEM_SALES_BEGIN"
export const  REGISTER_NEW_ITEM_SALES_SUCCESS  = "REGISTER_NEW_ITEM_SALES_SUCCESS"
export const  REGISTER_NEW_ITEM_SALES_ERROR  = "REGISTER_NEW_ITEM_SALES_ERROR"
export const  IPTV_CUSTOMER  = "IPTV_CUSTOMER"
export const  THE_CUSTOMERS_BEGIN  = "THE_CUSTOMERS_BEGIN"
export const  THE_CUSTOMERS_SUCCESS  = "THE_CUSTOMERS_SUCCESS"
export const  THE_CUSTOMERS_ERROR  = "THE_CUSTOMERS_ERROR"
export const  REGISTER_IPT_ORGANIZATION__ERROR  = "REGISTER_IPT_ORGANIZATION__ERROR"
export const  REGISTER_IPT_ORGANIZATION__SUCCESS  = "REGISTER_IPT_ORGANIZATION__SUCCESS"
export const  REGISTER_IPT_ORGANIZATION_BEGIN  = "REGISTER_IPT_ORGANIZATION_BEGIN"
export const  CUSTOMER_424_REPORT  = "CUSTOMER_424_REPORT"
export const  CORESPONDING_EMP  = "CORESPONDING_EMP"
export const  SEND_SMS_SUCCESS  = "SEND_SMS_SUCCESS"
export const  SEND_SMS_BEGIN  = "SEND_SMS_BEGIN"
export const  SEND_SMS_FAIL  = "SEND_SMS_FAIL"
export const  FETCH_CABLES_BEGIN_BY_DATE  = "FETCH_CABLES_BEGIN_BY_DATE"
export const  FETCH_CABLES_SUCCESS_BY_DATE  = "FETCH_CABLES_SUCCESS_BY_DATE"
export const  FETCH_CABLES_ERROR_BY_DATE  = "FETCH_CABLES_ERROR_BY_DATE"
export const  AUTO_CHARGE_BEGIN  = "AUTO_CHARGE_BEGIN"
export const  AUTO_CHARGE_SUCCESS  = "AUTO_CHARGE_SUCCESS"
export const  AUTO_CHARGE_ERROR  = "AUTO_CHARGE_ERROR"
export const  GET_ALL_ANALOG_EXPIRED_BEGIN  = "GET_ALL_ANALOG_EXPIRED_BEGIN"
export const  GET_ALL_ANALOG_EXPIRED_SUCCESS  = "GET_ALL_ANALOG_EXPIRED_SUCCESS"
export const  GET_ALL_ANALOG_THREE_EXPIRED_BEGIN  = "GET_ALL_ANALOG_THREE_EXPIRED_BEGIN"
export const  GET_ALL_ANALOG_REMIAINGG_EXPIRED_SUCCESS  = "GET_ALL_ANALOG_REMIAINGG_EXPIRED_SUCCESS"
export const  GET_ALL_IPTV_THREE_EXPIRED_BEGIN  = "GET_ALL_IPTV_THREE_EXPIRED_BEGIN"
export const  GET_ALL_IPTV_REMIAINGG_EXPIRED_SUCCESS  = "GET_ALL_IPTV_REMIAINGG_EXPIRED_SUCCESS"
export const  GET_ALL_IPTV_THEE_EXPIRED_BEGIN  = "GET_ALL_IPTV_THEE_EXPIRED_BEGIN"
export const  GET_ALL_IPTV_THEEE_EXPIRED_SUCCESS  = "GET_ALL_IPTV_THEE_EXPIRED_SUCCESS"
export const  GET_USER_REPORT_ROLE  = "GET_USER_REPORT_ROLE"
export const  SEND_FINGERPRINT_SUCCESS  = "SEND_FINGERPRINT_SUCCESS"
export const  SEND_FINGERPRINT_BEGIN  = "SEND_FINGERPRINT_BEGIN"
export const  SEND_FINGERPRINT_FAIL  = "SEND_FINGERPRINT_FAIL"
export const  FETCH_DEVICES_SUCCESS  = "FETCH_DEVICES_SUCCESS"
export const  DEVICES_REG_BEGIN  = "DEVICES_REG_BEGIN"
export const  FETCH_ATTENDANCE_DATA  = "FETCH_ATTENDANCE_DATA"
export const  FETCH_ATTENDANCE_UPDATES  = "FETCH_ATTENDANCE_UPDATES"
export const  FETCH_AVAILABLE_CARDS  = "FETCH_AVAILABLE_CARDS"
export const  SEND_SMS_NOTIFICATION  = "SEND_SMS_NOTIFICATION"
export const  FETCH_RESOLVED_TICKETS  = "FETCH_RESOLVED_TICKETS"
export const  FETCH_SMS_MESSAGES  = "FETCH_SMS_MESSAGES"
export const  CANCEL_CARD_BEGIN  = "CANCEL_CARD_BEGIN"
export const  CANCEL_CARD_END  = "CANCEL_CARD_END"
export const  FETCH_SOLD_CARDS  = "FETCH_SOLD_CARDS"
export const  GET_TICKETS_COMPLETED_SUCCESS  = "GET_TICKETS_COMPLETED_SUCCESS"
export const  CUST_SMS_SUCCESS  = "CUST_SMS_SUCCESS"
export const  CUST_SMS_BEGIN  = "CUST_SMS_BEGIN"
export const  CUST_SMS_END  = "CUST_SMS_END"
export const  FETCH_EXPIRED_CARDS  = "FETCH_EXPIRED_CARDS"
export const  FETCH_DAILY_ATTENDANCE  = "FETCH_DAILY_ATTENDANCE"
export const  GET_ALL_IPTV_CUST  = "GET_ALL_IPTV_CUST"

