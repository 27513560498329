import React, { useState, useEffect, useContext, useMemo } from "react";

import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import moment from "moment";
// import styles from "./StyledTable.module.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import DataTable from "react-data-table-component";
const CollectAttendanceFromDevice = () => {
  const [Discription, setdescription] = useState("");
  const { openAnalogCardRegister } = useContext(LoginContex);

  //   const [customerId, setCustomer] = useState();

  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [deviceId, setDeviceId] = useState();

  const [helper, setHelper] = useState(false);
  

  document.title = "Download Attendance";

  const {
 fetchDevices, devices, fetchAttendanceUpdates,isLoading
  } = useContext(LoginContex);

  useEffect(() => {
    fetchDevices()

  }, []);




  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const clearFilter = () => {
    setHelper(false);
    setStart("");
    setEnd("");
    setDeviceId("");
  
  };

  


  const devicesList = [];

  for (let i = 0; i < devices?.length; i++) {
    // //console.log(setEmployeeId[i]._id);

    var list = {
      value: devices[i]?.ipAddress,
      label: ` ${devices[i]?.deviceName} -- ${devices[i]?.ipAddress}`,
    };

    devicesList.push(list);
  }


  // downloaad attendance updates
  const getAttendanceUpdates = ()=>{
    if(deviceId?.value == "" || deviceId?.value == null || deviceId?.value == undefined){
      toast.error("please choose a device", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else{
 
      let startDate = moment(start).format("YYYY-MM-DD")
      let endDate = moment(end).format("YYYY-MM-DD")
  
      fetchAttendanceUpdates(deviceId?.value,startDate,endDate)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Download Attendance" pageTitle="Attendance" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-autod">
                      <div>
                        <ToastContainer />
                        <div className="">
                          <h4 className="card-title mb-0">Download Attendance</h4>
                          {/* <Button>Go Back</Button> */}

                          <div
                            className="form-label"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                         
                          </div>
                        </div>
                      </div>
                     
                    </Col>
                    {/* <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                    
                          
                          <Button
                            color="success"
                            className="add-btn me-1 mx-2"
                            onClick={() => downloadCSV2(CvsData)}
                            id="create-btn"
                          >
                            <i
                              className="fa fa-print mx-2"
                              aria-hidden="true"
                            ></i>
                            Download Excell
                          </Button>
                    
                      </div>
                    </Col> */}
            
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="mb-5">
                    <Col md={3}>
              <div className="mb-3">
                      <label className="form-label">
                        Device <span className="text-danger">*</span>
                      </label>
                        <Select
                          // className="bg-white"
                          options={devicesList}
                          onChange={(choice) => setDeviceId(choice)}
                          value={devicesList.filter(function (option) {
                            return option.value === deviceId?.value;
                          })}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                            },
                          })}
                        />
                    </div>
              </Col>
                      <Col md={3}>
                        <div style={{ display: "" }}>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            From Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStart(date)}
                            value={start}
                            
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div  style={{ display: "" }}>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            To Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEnd(date)}
                            value={end}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                      
                      <div className="" >
                        <div> <Button onClick={getAttendanceUpdates} className="add-btn mt-4 btn-info"><i className="ri-file-download-line align-bottom me-1 "></i>Dowmload {" "}Updates 
                         </Button > </div>
                      </div>
                    </Col>

                      

             
                    </Row>
              
            
                {isLoading &&
                      <div className='d-flex justify-content-center align-content-center'>
                      <SpinnerBorder />
                      </div>
}
                  
           

                   
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CollectAttendanceFromDevice;
const SpinnerBorder = () => {
  return <Spinner className="my-2 text-center" />;
};
