import React, { useState, useEffect, useContext, useMemo } from "react";
import Select from "react-select";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import DataTable from "react-data-table-component";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";
import spinner from "../../../src/assets/images/spinner/load.gif";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { FETCH_ZONES_ERROR } from "../../context/loginContext/loginActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Grid, _ } from "gridjs-react";
import TableContainer from "../../Components/Common/TableContainer";

const RegisterSms = () => {
  const {

    getEmployee,
    
    getGroups,
 
    fetchByIdZonesCable,
    getByIdZonesCables,
    assignGroups,
   
    deleteAssignGroup,
    isLoadingGroups,User,
    regsiterSmsMessages,
        fetchSmsMessages,
        deleteSmsMessages,
        updateSmsMessages,smsMessages
  } = useContext(LoginContex);

  const [smsId, setSmsId] = useState();
  const [groupId, setGroup] = useState();
  const [zoneId, setZoneId] = useState();
  const [type, setType] = useState();
  const [contents, setContent] = useState();
  const [siteId, setSiteId] = useState();
  const [checked, setChecked] = useState(false);
  const [IsGroupLeader, setIsGroupLeader] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isGroupLeader, setisGroupLeader] = useState(false);
  const [object_id, setObject] = useState();
  const [id, set_ID] = useState("");

  const [serviceList, setServiceList] = useState([
    { item: "", QtyAvailable: "", Qty: "", rate: "", Amount: "" },
  ]);

  const [existingList, setexistingList] = useState([{ empId: "" }]);

  const [QtyAvailable, setQtyAvailable] = useState("");
  const [Qty, setQty] = useState("");
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setisGroupLeader(false);
    setChecked(false);
    setIsEditing(false);
    setmodal_list(!modal_list);
    clear();
  };
  let userId = User?.data?.user?._id || User?.user?._id;
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = smsMessages?.filter(
    (item) =>
      item.type &&
      item.type.toLowerCase().includes(filterText.toLowerCase())
  );
//   //console.log(filteredItems);
//   //console.log(filterText);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  // //console.log(smsMessages?);
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  const handleChange = () => {
    setChecked(!checked);
    setIsGroupLeader(!IsGroupLeader);
  };

  const handleGroupChange = () => {
    setChecked(!checked);
    setIsGroupLeader(!IsGroupLeader);
  };

  useEffect(() => {
    fetchSmsMessages()
    // fetchEmpTitle();
    // fetchDepartment();
    // fetchGroups();
    // fetchSites();
    // fetchsmsMessages?();
    // fetchByIdZonesCable();
    // fetchZones();
  }, []);

  document.title = "Register SMS  ";

  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> Type</h6>,
      selector: (row) => row.type,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Message</h6>,
      selector: (row) => row.message,
    },
    
    {
      name: <h6 style={{ fontWeight: "bold" }}> Actions</h6>,
      cell: (row) => (
        <>
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item edit" title="Edit">
              <Link
                to="#"
                className="text-primary d-inline-block edit-item-btn"
                onClick={() => editPop(row)}
              >
                <i className="ri-pencil-fill fs-16"></i>
              </Link>
            </li>
            <li className="list-inline-item" title="Remove">
              <Link
                to="#"
                className="text-danger d-inline-block remove-item-btn"
                onClick={() => deletPop(row)}
              >
                <i className="ri-delete-bin-5-fill fs-16"></i>
              </Link>
            </li>
          </ul>

        </>
      ),

      // ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
    },
    {
      /*end*/
    },
  ];

  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,
      { item: "", QtyAvailable: "", Qty: "", rate: "", Amount: "" },
    ]);
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
    // handleServiceAdd();
  };

  const test = (e, index) => {
    handleServiceChange(e, index);
    if (e.target.name === "item") {
      handleServiceAdd();
    }
  };

  // const [apiData, setApiData] = useState([]);

  // const fetchData = query => fetch(`/zoon/getAllZoons/${data.siteId}/${data.groupId}`)
  //     .then((response) => {
  //         return response.json();
  //     })
  //     .then(setApiData(data));

  const clear = () => {
    set_ID("");
    setType("");
    setContent("");
  };

  const editPop = (data) => {
    //console.log(data);
    setmodal_list(true);
    set_ID(data._id);
    
    setType(data.type);
    setContent(data.message);
    setIsEditing(true);
  
  };

  const deletPop = (data) => {
    setmodal_delete(true);
    set_ID(data._id);
  };

  const deleteGroups = () => {
    deleteSmsMessages(id);
    setmodal_delete(false);
  };

  const CustomersArr = [];

  for (let i = 0; i < getEmployee.length; i++) {
    var dropdownList = {
      value: getEmployee[i]._id,
      label: ` ${getEmployee[i].empName}`,
    };

    CustomersArr.push(dropdownList);
  }

  const handleTypeSelect = (e) => {
    // setEmployeeId(e.value);
  };

  const GroupsArr = [];
  for (let i = 0; i < getGroups.length; i++) {
    var dropdownList = {
      value: getGroups[i]._id,
      label: ` ${getGroups[i].groupName}`,
    };

    GroupsArr.push(dropdownList);
  }

  const handleTypeSelectGroup = (e) => {
    setGroup(e.value);
    fetchByIdZonesCable(e.value);
  };

  const ZonesArr = [];
  for (let i = 0; i < getByIdZonesCables.length; i++) {
    var dropdownList = {
      value: getByIdZonesCables[i]._id,
      label: ` ${getByIdZonesCables[i].zoneName}`,
    };

    ZonesArr.push(dropdownList);
  }

  const handleTypeSelectZones = (e) => {
    setZoneId(e.value);
    //console.log(e.value);
  };

  const getZonesbyGroup = (e) => {
    setSiteId(e.target[e.target.selectedIndex].getAttribute("data-siteId"));
    setGroup(e.target.value);
    fetchByIdZonesCable(e.target.value);
  };

  const handlerSubmit = (e) => {
    if (isEditing == false) {
      e.preventDefault();
      const data = {
        type,
        message:contents
      };
      //console.log(data);

      const showToastMessage = () => {
        toast.error("Please fill all the required value !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      };

      if (!type || !contents) {
        showToastMessage();
      } else {
        //console.log(data);
        setmodal_list(false);
        regsiterSmsMessages(data);
        clear();
      }
    } else {
      e.preventDefault();
      const data = {
        type,
        message:contents,
        id: id,
      };
      //console.log(data);
      const showToastMessage = () => {
        toast.error("Please fill all the required value !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      };

      if (!type || !contents) {
        showToastMessage();
      } else {
        //console.log(data);
        setmodal_list(false);
        updateSmsMessages(data);
        clear();
      }
    }
  };

//   let newList = [];
//   const sortsmsMessages? = [];
//   for (let i = 0; i < smsMessages??.length; i++) {
//     let singleArr = [
//       smsMessages?[i].empId?.empName,
//       assignGroups[i].empId?.mobile,
//       assignGroups[i].groupId?.groupName,
//       assignGroups[i].zoonId?.zoonName,
//       assignGroups[i].isLeader ? "Yes" : "No",
//       assignGroups[i]._id,
//       assignGroups[i].empId?._id,
//       assignGroups[i].groupId?._id,
//       assignGroups[i].zoonId?._id,
//       assignGroups[i].isLeader,
//     ];

//     var dropdownList = {
//       name: assignGroups[i].empId?.empName,
//       value: assignGroups[i].empId?._id,
//     };

//     newList.push(dropdownList);
//     //console.log("NEW LIST", newList);
//     // //console.log(singleArr[5])

//     sortAssignGroups.push(singleArr);

//     //console.log("Groups", sortAssignGroups);
//   }

  const handleTypeChange = (e)=>{
    setType(e.target.value)
    // //console.log(e.target.value)
    // getBothCustomersByType(e.target.value)
  }

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };
  const handleTagClick = (tag) => {
    setContent((prevContents) => prevContents + tag);
  };
  if (isLoadingGroups) {
    return (
      <>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Manage Cables" pageTitle="Billing" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={spinner}
                        style={{ backgroundColor: "white !" }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Register SMS " pageTitle="Register SMS" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">Register SMS</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={() => tog_list()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                    
                  <Row className="g-4 mb-3"></Row>
                  {smsMessages?.length > 0 && (
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      // selectableRows
                      persistTableHead
                    // sortFunction={customSort}
                    />
                  )}
                  
              
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Purchase Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        <ModalHeader className="bg-light p-3">
          Register SMS
          <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
            
              <Col md={6}>
                  
              <Label htmlFor="PurchasesDate" className="form-label">
                        Message Type <span className="text-danger">*</span>
                       </Label>

                       <select
                         className="form-select "
                         id="itemtype"
                         onChange={handleTypeChange}
                       >
                           <option value=''>Select Option</option>
                         <option value='New Analog Customers'>New Analog Customers</option>
                         <option value='New Iptv Customers'>New IPTV Customers</option>
                         <option value='Expired Analog Customers'>Expired Analog Customers</option>
                         <option value='Expired Iptv Customers'>Expired IPTV Customers</option>
                         <option value='Analog Payment Message'>Analog Payment Message</option>
                         <option value='Iptv Payment Message'>IPTV Payment Message</option>
                       
                       </select>
              </Col>
              <Col md="12">
              <div className="form-group">
                <label>Message</label>
                <Input
                // disabled={isSending}
                  type="textarea"
                  
                  style={{ backgroundColor: "white", color: "#333", height:"80px" }}
                  onChange={handleContentChange}
                  value={contents}
                />
              </div>
              <br/>
              <b>Available Tags:</b>
              <br/>
              {(type ==="Analog Payment Message" || type ==="New Analog Customers" || type ==="Expired Analog Customers") &&
              <> <li className="btn btn-light" onClick={() => handleTagClick('[cardNumber]')}>
              [cardNumber]
            </li>
         
            </>
            }
               
              <li className="btn btn-light m-2" onClick={() => handleTagClick('[fullname]')}>
                 [fullname]
               </li>
              
               <li className="btn btn-light" onClick={() => handleTagClick('[phoneNumber]')}>
                 [phoneNumber]
               </li>
          
                <li className="btn btn-light m-2" onClick={() => handleTagClick('[startDate]')}>
              [startDate]
            </li>
            <li className="btn btn-light" onClick={() => handleTagClick('[endDate]')}>
            [endDate]
          </li>
          
          
              </Col>
            </Row>
          
          </ModalBody> 
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {/* Assign */}
                {isEditing ? "Update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>

        {/* =================================== */}
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={deleteGroups}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default RegisterSms;
