import React, { useState, useEffect, useContext, useMemo } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";

import { Grid, _ } from "gridjs-react";
import DataTable from "react-data-table-component";

const ManageTicket = () => {
  const [storeName, setstoreName] = useState("");
  const [storeLocation, srtstoreLocation] = useState("");
  const [storeDescription, setstoreDescription] = useState("");
  const [isPrimaryStore, setisPrimaryStore] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [siteId, setSiteId] = useState("");
  const [zoneId, setZone] = useState("");
  const [groupId, setGroupId] = useState("");
  const [updateId, setUpdateId] = useState("");

  const [employeeId, setEmployeeId] = useState("");
  const [yes, setYes] = useState(true);
  const [id, setId] = useState("");
  // const [no, setNo] = useState(false);

  const [supplierId, setsupplierId] = useState("");
  const [amount, setamount] = useState(5);
  const [reference, setreference] = useState("");
  const [issueDate, setIsseDate] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [Datee, setDate] = useState();
  const [custAddress, setCustAddress] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [customerInfo, setCustomerInfo] = useState();

  const {
    // registerCustomer,
    fetchCustomers,
    customers,
    fetchSites,
    sites,
    fetchGroups,
    getByIdGroup,
    getByIdGroups,
    fetchByIdZones,
    getByIdZones,
    fetchByIEmployee,
    GetByIdEmployee,
    registertickets,
    fetchTickets,
    GetTickets,
    ticketsUpdate,
    getByIdZonesCables,
    fetchByIdZonesCable,
    EmployeesFromZone,
    getByIdGroupsCable,
    getSingleCustomer,
    singleCustomer,
    fetchEmployeesToAssign
  } = useContext(LoginContex);
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  //console.log(yes);
  useEffect(() => {
    fetchCustomers();
    fetchSites();
    fetchGroups();
    fetchTickets();
    fetchByIdZones(groupId);
    fetchByIEmployee(siteId, groupId, id);
  }, [supplierId]);

  let getGroup = (id) => {
    //console.log(id);
    setSiteId(id);

    getByIdGroup(id);
  };

  let getZones = (id) => {
    setGroupId(id);
    fetchByIdZonesCable(id);
    // //console.log(getZoneById)
  };


  let getEmployee = (id) => {
    setZone(id);
    let Data = [
      {
        siteId: siteId,
        groupId,
        zoneId: id,
      },
    ];

    fetchByIEmployee(siteId, groupId, id);
    // //console.log(GetByIdEmployee)
  };


  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = GetTickets.filter(
    (item) =>
      item.customerName &&
      item.customerName.toLowerCase().includes(filterText.toLowerCase()) && item.customerName ||
      item.customerPhone.toString().toLowerCase().includes(filterText.toLowerCase())
  );
  //console.log(filteredItems);
  //console.log(filterText);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  document.title = "Ticket ";

  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> Ticket ID</h6>,
      selector: (row) => row?.ticketId,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Date</h6>,
      selector: (row) => moment(row.createdAt).utc(true).format("YYYY-MM-DD HH:mm")
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Customer </h6>,
      selector: (row) => row.customerName,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Phone </h6>,
      selector: (row) => row.customerPhone,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Employee </h6>,
      selector: (row) => row.empName,
    },
    // {
    //   name: <h6 style={{ fontWeight: "bold" }}>Mobile </h6>,
    //   selector: (row) => row.customerName,
    // },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Title</h6>,
      selector: (row) => row.title,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Sent To</h6>,
      selector: (row) => row.siteName,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Is Resolved</h6>,
      selector: (row) => (row.isResolved ? "Yes" : "No"),
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Actions</h6>,
      cell: (row) => (
        <>
          {/* <button
            className="btn btn-primary  mx-3"
            onClick={() => updatTickets(row)}
          >
            {" "} */}
            
          {/* <i
            className="text-primary ri-pencil-fill fs-16 mx-3"
            onClick={() => updatTickets(row)}
          ></i> */}
          {/* </button> */}
          {"     "}
          {/* <button className="btn btn-danger" onClick={() => deleteStore(row)}> */}
          <i style={{marginLeft: "15px"}}
            className="ri-delete-bin-5-fill fs-16 text-danger"
            onClick={() => deleteStore(row)}
          ></i>

          
          {/* </button> */}
        </>
      ),
    },
    {
      /*end*/
    },
  ];
  const handleSubmit = (e) => {
    //console.log(isEditing);
    e.preventDefault();
    if (!isEditing) {
      const data = {
        issueDate,
        empId: employeeId,
        customerId,
        custAddress,
        siteId,
        groupId,
        zoneId,
        title,
        description,
      };

      registertickets(data);
      setEmployeeId("0");
      setCustomerId("0");
      setCustAddress("");
      setSiteId("0");
      setGroupId("0");
      setZone("0");
      setTitle("");
      setDescription("");
    }
    if (isEditing) {
      const data = {
        id: updateId,
        issueDate,
        empId: employeeId,
        customerId,
        custAddress,
        siteId,
        groupId,
        zoneId,
        title,
        description,
      };
      ticketsUpdate(data);

      setEmployeeId("0");
      setCustomerId("0");
      setCustAddress("");
      setSiteId("0");
      setGroupId("0");
      setZone("0");
      setTitle("");
      setDescription("");
    }
  };

  const deleteStore = (data) => {
    setmodal_delete(true);
    setId(data._id);
  };

  const delStore = () => {
    setmodal_delete(false);
  };

  let getCustAddress = (id) => {
    setCustomerInfo(id);

    //console.log("ID: ", id);
    setCustomerId(id);
    getSingleCustomer(id);
    setCustAddress(id.customerAddress);
    //console.log("Address", singleCustomer.customerAddress);

  };

  //updateees
  const updatTickets = (data) => {
    //console.log("data", data);

    setSiteId(data.siteId);
    getByIdGroup(data.siteId);

    fetchByIdZonesCable(data.groupId);
    setGroupId(data.groupId);

    setCustomerInfo(data);

    //console.log("custid", customerInfo?.customerId)
    setZone(data.zoneId);

    setmodal_list(true);
    setEmployeeId(data.empId);
    setCustomerId(customerInfo?.customerId);
    setCustAddress(customerInfo?.customerAddress);
    setSiteId(data.siteId);
    setGroupId(data.groupId);
    setZone(data.zoneId);
    setTitle(data.title);
    setDescription(data.description);
    setEditing(true);
    setUpdateId(data._id);
    setDate(data.issueDate);
  };

  const addModal = () => {
    tog_list();
    setEditing(false);
    setamount("");
    setreference("");
    setsupplierId("");
    setIsseDate("");
  };
  const sortedTicket = [];
  for (let i = 0; i < GetTickets?.length; i++) {
    let date = GetTickets[i].issueDate.split("T");
    const formattedDate = moment(date[0]).format("DD-MM-YYYY");
    let singleArr = [
      GetTickets[i].ticketId,
      formattedDate,
      // formattedDate,
      GetTickets[i].customerName,
      GetTickets[i].title,
      GetTickets[i].siteName,
      GetTickets[i].IS_Resolved ? "Yes" : "No",
    ];

    sortedTicket.push(singleArr);
  }


  const ZonesArr = [];
  for (let i = 0; i < getByIdZonesCables.length; i++) {
    var dropdownList = {
      value: getByIdZonesCables[i]._id,
      label: ` ${getByIdZonesCables[i].zoneName}`,
    };

    ZonesArr.push(dropdownList);
  }





  const GroupsArr = [];
  for (let i = 0; i < getByIdGroups.length; i++) {
    var dropdownList = {
      value: getByIdGroups[i]._id,
      label: ` ${getByIdGroups[i].groupName}`,
    };

    GroupsArr.push(dropdownList);
  }


  const CustomersArr = [];

  for (let i = 0; i < EmployeesFromZone.length; i++) {
    var dropdownList = {
      value: EmployeesFromZone[i].empId,
      label: ` ${EmployeesFromZone[i].empName}`,
    };

    CustomersArr.push(dropdownList);
  }


  //console.log("CustomersArr", EmployeesFromZone);




  const getEmployeeFromZone = e => {
    setZone(e.value);
    const data = {
      zoneId: e.value,
    };
    fetchEmployeesToAssign(data);
    //console.log(data);
  };


  const SitesArr = [];
  for (let i = 0; i < sites.length; i++) {
    //console.log(sites[i]._id);

    var dropdownList = {
      value: sites[i]._id,
      label: ` ${sites[i].siteName}`,
    };

    SitesArr.push(dropdownList);
  }


  const handleTypeSelectGroup = e => {
    getGroup(e.value);
  };


  const handleTypeSelectGroupZone = e => {
    setGroupId(e.value);
    fetchByIdZonesCable(e.value);
  };


  const handleTypeSelect = (e) => {
    setEmployeeId(e.value);
  };

  const CustomersArray = [];

  for (let i = 0; i < customers.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: customers[i]._id,
      label: ` ${customers[i].customerName}`,
      customername: ` ${customers[i].customerName}`,
      customerPhone: ` ${customers[i].customerPhone}`,
      customerAddress: ` ${customers[i].customerAddress}`,
    };

    CustomersArray.push(dropdownList);
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Tickets" pageTitle="Manage Tickets" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">Manage Tickets</h4>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="StoreList">
                    <Row className="g-4 mb-3"></Row>
                    {GetTickets.length > 0 && (
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        // selectableRows
                        persistTableHead
                      // sortFunction={customSort}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Create New Ticket</h5>
          <button
            type="button"
            className="btn-close"
            onClick={addModal}
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={4}>
                <div className="mb-3">
                  <label>
                    Ticket date <span className="text-danger">*</span>
                  </label>
                  <Flatpickr
                    className="form-control"
                    required
                    placeholder="DD/MM/YYYY"
                    options={{
                      dateFormat: "d/m/Y",
                      defaultDate: "today",
                    }}
                    onChange={(e) => setDate(e.target.value)}
                    value={Datee}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label>
                    Customer <span className="text-danger">*</span>
                  </label>
                  <Select
                    styles={{ width: "170px", height: "150px" }}
                    // className="bg-white"
                    options={CustomersArray}
                    onChange={(choice) =>
                      // getCustPhone(e.target.value, e.target)
                      getCustAddress(choice)
                    }
                    value={customerInfo}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                  />
                </div>
              </Col>

              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">Address</label>
                  <input
                    type="text"
                    id="address"
                    className="form-control"
                    placeholder="Enter Address"
                    onChange={(e) => setCustAddress(e.target.value, e.target)}
                    value={custAddress}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">Site Sent To</label>
                  <Select
                    // className="bg-white"
                    options={SitesArr}
                    onChange={handleTypeSelectGroup}
                    value={SitesArr.filter(function (option) {
                      return option.value === siteId;
                    })}

                    defaultValue={{ label: "Select Site", value: siteId }}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        // text: "orangered",
                        // primary25: "hotpink",
                        // primary: "black",
                      },
                    })}
                  />
                </div>
              </Col>

              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">
                    Group<span className="text-danger">*</span>
                  </label>

                  <Select
                    options={GroupsArr}
                    onChange={handleTypeSelectGroupZone}
                    value={GroupsArr.filter(function (option) {
                      return option.value === groupId;
                    })}

                    defaultValue={{ label: "Select Group", value: groupId }}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,

                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">Zone</label>
                  <Select
                    options={ZonesArr}
                    onChange={getEmployeeFromZone}
                    value={ZonesArr.filter(function (option) {
                      return option.value === zoneId;
                    })}
                    defaultValue={{ label: "Select Zone", value: zoneId }}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                  />

                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">Employee</label>
                  <Select
                    options={CustomersArr}
                    onChange={handleTypeSelect}
                    value={CustomersArr.filter(function (option) {
                      return option.value === employeeId;
                    })}
                    defaultValue={{ label: "Select Employee", value: employeeId }}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={8}>
                <div className="mb-3">
                  <label className="form-label">Ticket title</label>
                  <input
                    type="text"
                    id="address"
                    className="form-control"
                    placeholder="Enter Ticket Title"
                    onChange={(e) => setTitle(e.target.value, e.target)}
                    value={title}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <textarea
                    className="form-control"
                    id="Description-field"
                    rows="4"
                    onChange={(e) => setDescription(e.target.value, e.target)}
                    value={description}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isEditing ? "update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => delStore()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ManageTicket;
