import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
//import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SalesForm from "../SalesForm";

const Sales = () => {
  const [storeName, setstoreName] = useState("");
  const [storeLocation, srtstoreLocation] = useState("");
  const [storeDescription, setstoreDescription] = useState("");
  const [isPrimaryStore, setisPrimaryStore] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [yes, setYes] = useState(true);
  const [id, setId] = useState("");
  // const [no, setNo] = useState(false);

  // local states

  const [purchaseDate, setpurchaseDate] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [storeId, setstoreId] = useState("");
  const [siteId, setsiteId] = useState("");
  const [referenceNo, setreferenceNo] = useState("");
  const [description, setdescription] = useState("");
  const [purchaseAmount, setpurchaseAmount] = useState("");
  const [additionalCost, setadditionalCost] = useState("");
  const [discount, setdiscount] = useState();
  const [totalAmount, settotalAmount] = useState();
  const {
    registerStore,
    stores,
    deleteStores,
    upadteStore,
    fetchItems,
    fetchStores,
    fetchSites,
    sites,
    items,
  } = useContext(LoginContex);
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const { fetchPurchase, purchases, removePurchase } = useContext(LoginContex);
  //console.log(yes);
  useEffect(() => {
    fetchStores();
    fetchItems();
    fetchSites();
    fetchPurchase();
  }, []);

  useEffect(() => {
    const attroptions = {
      valueNames: [
        "name",
        "born",
        {
          data: ["id"],
        },
        {
          attr: "src",
          name: "image",
        },
        {
          attr: "href",
          name: "link",
        },
        {
          attr: "data-timestamp",
          name: "timestamp",
        },
      ],
    };

    // pagination list

    new List("pagination-list", {
      valueNames: ["pagi-list"],
      page: 3,
      pagination: true,
    });
  });

  document.title = "Manage Cables";
  const mockData = [
    {
      date: "10-02-2022",
      Customer: "Axmed Yuusuf Cali",
      Ref: "Analog",
      description: "12102025544",
      TotalAmount: "Yes",
      Type: "Site",
    },
  ];
  //console.log(purchases);

  const handleSubmit = (e) => {
    //console.log("ddddddddddddd");
    e.preventDefault();
    if (!isEditing) {
      const data = {
        storeDescription,
        storeName,
        storeLocation,
        isPrimaryStore: yes,
      };
      if (!storeName || !storeLocation || !storeDescription) {
        return;
      }
      registerStore(data);
      setmodal_list(false);
    }
    if (isEditing) {
      const data = {
        storeDescription,
        storeName,
        storeLocation,
        isPrimaryStore: yes,
        id: id,
      };
      if (!storeName || !storeLocation || !storeDescription) {
        return;
      }
      upadteStore(data);
    }
  };

  const deletePuchase = (data) => {
    setmodal_delete(true);
    setId(data._id);
  };

  const delPurchase = () => {
    removePurchase(id);
    setmodal_delete(false);
  };

  //updateees
  //console.log(isEditing);
  const updateForm = (data) => {
    // setmodal_list(true);
    setstoreName(data.storeName);
    srtstoreLocation(data.storeLocation);
    setstoreDescription(data.storeDescription);
    setYes(yes);
    setEditing(true);
    setId(data._id);
  };

  const dateFromDB = "2005-12-03T18:30:00.000Z";
  const formattedDate = moment(dateFromDB).utc().format("DD/MM/YYYY");

  //console.log("Date From DB:", dateFromDB);
  //=> 2005-12-03T18:30:00.000Z

  //console.log("Formatted Date:", formattedDate);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Manage Cables" pageTitle="Billing" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <h4 className="card-title mb-0">Manage Cables</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Link to="/NewCableFrom" className="text-white">
                            <Button
                              color="success"
                              className="add-btn me-1"
                              // onClick={() => tog_list()}
                              //  id="create-btn"
                            >
                              <i className=" ri-add-line align-bottom me-1"></i>{" "}
                              Add New Cable
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="StoreList">
                    <Row className="g-4 mb-3"></Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="StoreTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "5px" }}></th>

                            <th className="sort" data-sort="date">
                              Register Date
                            </th>
                            <th className="sort" data-sort="Customer">
                              Customer
                            </th>
                            <th className="sort" data-sort="Ref">
                              Receiver type
                            </th>
                            <th className="sort" data-sort="description">
                              Card number
                            </th>
                            <th className="sort" data-sort="TotalAmount">
                              Is trial?
                            </th>
                            <th className="sort" data-sort="Type">
                              Agent Type
                            </th>
                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {mockData.map((data, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row"></th>
                                <td className="id" style={{ display: "none" }}>
                                  <Link
                                    to="#"
                                    className="fw-medium link-primary"
                                  >
                                    #VZ2104
                                  </Link>
                                </td>
                                <td className="date"> {data.date} </td>
                                <td className="Customer">{data.Customer}</td>
                                <td className="Ref"> {data.Ref}</td>
                                <td className="description">
                                  {data.description}
                                </td>
                                <td className="TotalAmount">
                                  {data.TotalAmount}
                                </td>
                                <td className="Type">{data.Type}</td>

                                <td>
                                  <div className="d-flex gap-2">
                                    <div className="edit">
                                      <button
                                        className="btn btn-sm btn-success edit-item-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showModal"
                                        onClick={() => updateForm(data)}
                                      >
                                        <Link
                                          style={{ color: "white" }}
                                          to={`/managePurchase/edit/${data._id}`}
                                        >
                                          {" "}
                                          Edit{" "}
                                        </Link>
                                      </button>
                                    </div>
                                    <div className="remove">
                                      <button
                                        className="btn btn-sm btn-danger remove-item-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteRecordModal"
                                        onClick={() => deletePuchase(data)}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#121331,secondary:#08a88a"
                            style={{ width: "75px", height: "75px" }}
                          ></lord-icon>
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">
                            We've searched more than 150+ Orders We did not find
                            any orders for you search.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        <ModalHeader className="bg-light p-3 pull right">
          Add New Store
          <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close float-right"
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>

            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label>Supplier</label>
                  <select
                    className="form-select "
                    // id="itemtype"
                    onChange={(e) => setSupplierId(e.target.value)}
                    value={supplierId}
                  >
                    {items.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {item.supplierName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </Col>
              <Col md={6}>
                {/* <div className="mt-3">
                  <Label className="form-label mb-0">Purchases Date</Label>
                  <Flatpickr
                    className="form-control"
                    options={{
                      dateFormat: "D-M-Y",
                      placeholder: "Check-in Date",
                    }}
                  />
                </div> */}

                <div>
                  <Label htmlFor="PurchasesDate" className="form-label">
                    Purchase Date
                  </Label>
                  <Input
                    type="date"
                    className="form-control"
                    id="PurchasesDate"
                    //   value={date}
                    // onChange={(e) => setDate(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>Store</label>
                  <select
                    className="form-select "
                    id="itemtype"
                    // defaultValue="reciever"
                    // onChange={(e) => setStoreId(e.target.value)}
                  >
                    {stores?.map((store, index) => {
                      return (
                        <option key={index} name="service" value={store._id}>
                          {store.storeName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Site</label>
                  <select
                    className="form-select "
                    id="itemtype"
                    //  defaultValue="reciever"
                    // onChange={(e) => setSiteId(e.target.value)}
                  >
                    {/* {sites?.map((site, index) => {
                      return (
                        <option key={index} name="service" value={site._id}>
                          {site.siteName}
                        </option>
                      );
                    })} */}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Reference No</label>
                  <input
                    type="number"
                    id="referenceno-field"
                    className="form-control"
                    placeholder="Enter Reference No"
                    required
                    //  onChange={(e) => setReferenceNo(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <textarea
                    className="form-control"
                    id="Description-field"
                    rows="1"
                    required
                    //  onChange={(e) => setdescription(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">purchase Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    id="purchase_amount-field"
                    // defaultValue={purchaseAmount}
                    // value={purchaseAmount}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Is Cash</label>
                  <div className="mb-3">
                    <Input
                      className="form-control-input"
                      type="radio"
                      name="prim"
                      id="is_yes"
                      value={true}
                      defaultChecked
                      onChange={(e) => setYes(e.target.value)}
                    />
                    <Label className="form-check-label">Yes</Label>
                  </div>
                </div>
                <div className="mb-3">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="prim"
                    id="is_no"
                    value={false}
                    onChange={(e) => setYes(e.target.value)}
                  />
                  <Label className="form-check-label">No</Label>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Additional Cost</label>
                  <input
                    type="number"
                    id="AdditionalCostAcc-field"
                    className="form-control"
                    placeholder="Enter Additional Cost Acc"
                    required
                    //  onChange={(e) => setAdditianalCost(e.target.value)}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Discount</label>
                  <input
                    type="number"
                    id="totalamount-field"
                    className="form-control"
                    placeholder="Enter Discount"
                    required
                    //onChange={(e) => setDiscount(e.target.value)}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Total amount</label>
                  <input
                    type="number"
                    className="form-control"
                    id="disabledInput"
                    // defaultValue={totalAmount}
                    // value={totalAmount}
                    disabled
                  />
                </div>
              </Col>
            </Row>

            {/* <FormGroup tag="fieldset">
              Is Primary Location
              <FormGroup check></FormGroup>
              <FormGroup check onChange={(e) => setYes(e.target.value)}>
                <Label check>
                  <Input type="radio" name="radio1" /> Yes
                </Label>
              </FormGroup> */}
            {/* <FormGroup check  >
                <Label check>
                  <Input
                    type="radio"
                    name="radio1"
                  />{" "}
                  No
                </Label>
              </FormGroup> */}
            {/* </FormGroup> */}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isEditing ? "update" : "save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => delPurchase()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Sales;
