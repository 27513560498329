import React, { useState, useEffect, useContext } from "react";
import { Grid, _ } from "gridjs-react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import moment from "moment";
import spinner from "../../../src/assets/images/spinner/load.gif";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
//import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SalesForm from "../SalesForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { TextField } from "@mui/material";

const ManageCables = () => {
  const [storeName, setstoreName] = useState("");
  const [storeLocation, srtstoreLocation] = useState("");
  const [storeDescription, setstoreDescription] = useState("");
  const [isPrimaryStore, setisPrimaryStore] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [yes, setYes] = useState(true);
  const [id, setId] = useState("");
  const [test, setTest] = useState();
  // const [no, setNo] = useState(false);
  const [truee, setTOtotrue] = useState();
  const [clean, setClean] = useState(false);
  const [customerIdd, setCustomerIdd] = useState();
  // local states
  const [recNumber, setRecNum] = useState("");
  const [recTypes, setReceiverTypes] = useState();
  const [the_cardNumber, setthe_CardNumber] = useState();
  const [purchaseDate, setpurchaseDate] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [storeId, setstoreId] = useState("");
  // const [siteId, setsiteId] = useState("");
  const [referenceNo, setreferenceNo] = useState("");
  const [description, setdescription] = useState("");
  const [remark, setRemark] = useState("");
  const [purchaseAmount, setpurchaseAmount] = useState("");
  const [additionalCost, setadditionalCost] = useState("");
  const [discount, setdiscount] = useState();
  const [totalAmount, settotalAmount] = useState();
  const [EmployeeId, setEmployeeId] = useState();
  const [siteId, setSiteId] = useState();
  const [groupid, setgroupid] = useState();
  const [zoneid, setzoneid] = useState();
  const [Message, setMessage] = useState();
  const [issueDate, setissueDate] = useState();
  const [customerId, setcustomerId] = useState("");
  const [customerAddress, setcustomerAddress] = useState();
  const [title, setTitle] = useState();
  const [empPhone, setempPhone] = useState("");
  const [custPhone, setcustPhone] = useState("");
  const [customerInfo, setCustomerInfo] = useState();

  // const [Date, setDate] = useState();
  const [custAddress, setCustAddress] = useState("");
  const [supervisorInfo, setSupervisorInfo] = useState("");
  const [cNumber, setCnumber] = useState();
  const [pendingDate, setPendingDate] = useState();
  const [userId, setUserId] = useState();

  const {
    registerStore,
    stores,
    deleteStores,
    upadteStore,
    fetchItems,
    fetchStores,
    fetchSites,
    sites,
    items,
    getZonesById,
    getGroups,
    getEmployee,
    getZonesbyGroup,
    fetchEmployees,
    fetchGroups,
    fetchGroupsToAssign,
    fetchZonesToAssign,
    fetchEmployeesToAssign,
    getByIdGroupCable,
    getByIdGroupsCable,
    getByIdZonesCables,
    fetchByIdZones,
    getZoneById,
    Cables,
    fetchCables,
    AssignCablesRegister,
    fetchByIdZonesCable,
    fetchByIEmployee,
    GetByIdEmployee,
    registertickets,
    isLoading,
    EmployeesFromZone,
    getSingleEmployee,
    singleEmployee,
    getSingleCustomer,
    singleCustomer,
    supervisors,
    customers,
    fetchCustomers,
    getSupervisors,
    getByIdCustomers,
    ticketsSupervisorAdd,
    ticketsUpdate,
    reciveById,
    getByIdRecive,
    fetchReceiversType,
    itemGetAllReceiver,
    changeReceiverCardNumber,updateManageCable,fetchCablesByDate,cablesManage,isNewCableLoading
  } = useContext(LoginContex);
  const [modal_list, setmodal_list] = useState(false);
  const [modal_list2, setmodal_list2] = useState(false);
  const [modal_list3, setmodal_list3] = useState(false);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [type, setType] = useState();
  const [helper, setHelper] = useState(false);
  const [Line, setsetLine] = useState("all");
  const [date, setDate] = useState("all");
  const tog_list = () => {
    setmodal_list(!modal_list);
    clear();
  };
  const tog_list2 = () => {
    setmodal_list2(!modal_list2);
    clear();
  };
  const tog_list3 = () => {
    setmodal_list3(!modal_list3);
    clear();
  };
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const { fetchPurchase, purchases, removePurchase } = useContext(LoginContex);
  //console.log(yes);

  const currentDate = new Date(); // Get current date
  const currentYear = currentDate.getFullYear(); // Get current year
  const currentMonth = currentDate.getMonth(); // Get current month
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1); // Create new Date object with the first day of current month
  // const startDate = firstDayOfMonth.toDateString(); // Convert the first day of current month to a string in the desired format
  const startDate = moment().startOf("month").format("YYYY-MM-DD");
  //console.log(startDate);
  const formatedDate = moment(startDate).format("YYYY-MM-DD");

  //console.log(formatedDate); // Output the start date of the current month in the console
  const maanta = new Date();
  const tariikhdamanta = moment(maanta[0]).format("YYYY-MM-DD");
  //console.log(tariikhdamanta);
  useEffect(() => {
    fetchStores();
    fetchItems();
    fetchSites();
    fetchPurchase();
    fetchEmployees();
    fetchGroups();
    fetchGroupsToAssign();
    fetchZonesToAssign();
    fetchCustomers();
    getByIdCustomers(customerInfo?.value);
    getSupervisors();
    // fetchEmployeesToAssign();
    fetchCables();
    fetchReceiversType();
    fetchCablesByDate(startDate, tariikhdamanta)
  }, []);
//console.log(cablesManage)
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = cablesManage.filter((
    (item) =>
      (item?.cust?.customerName &&
        item?.cust?.customerName?.toLowerCase().includes(filterText.toLowerCase()) &&
        item.cust?.customerName?.toLowerCase().includes(filterText.toLowerCase()) &&
        item.cust?.customerPhone) ||
      item.cust?.customerPhone
        ?.toString()
        .toLowerCase()
        .includes(filterText.toLowerCase())
  ) )
  //console.log(filteredItems);
  //console.log(filterText);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  let getGroup = (id) => {
    // //console.log(id)
    setSiteId(id);
    getByIdGroupCable(id);
    setgroupid("");
    setzoneid("");
    setEmployeeId("");
  };

  let getZones = (id) => {
    setgroupid(id);
    fetchByIdZonesCable(id);
    // //console.log(getZoneById.result)
    //console.log(id);
    setzoneid("");
  };

  const clear = () => {
    setSiteId("");
    setgroupid("");
    setzoneid("");
    setEmployeeId("");
    setTitle("");
    setMessage("");
  };
  document.title = "Manage Cables";
  //console.log(Cables);
  let status = true
  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> No.</h6>,
      selector: (row,i) => i +1,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Name</h6>,
      selector: (row) => row.cust?.customerName,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Phone Number</h6>,
      selector: (row) => row.cust?.customerPhone,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Date</h6>,
      selector: (row) => moment(row.createdAt).utc().format("DD-MM-YYYY"),
    },
    // {
    //   name: <h6 style={{ fontWeight: "bold" }}> Total Recivers</h6>,
    //   selector: (row) => row.receiverDetails?.length,
    // },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Total Items</h6>,
      selector: (row) => row.totalAmount,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Is Trail</h6>,
      selector: (row) => (row.isAssignedToEmployee === false ? "No" : "yes"),
    },
     {
      name: <h6 style={{ fontWeight: "bold" }}>Is Assigned</h6>,
      selector: (row) => (row.isAssignedToEmployee === false ? "No" : "yes"),
    },
     {
      name:  <h6 style={{ fontWeight: "bold", width: "800" }}> actions</h6>,
      width: "400px"  ,
     
      cell: (row) => (

     <>

     {row?.cust?.isStatus ==true   &&
        <div className="d-flex align-items-center">
          <Button
            
            className="btn btn-sm  btn-success mx-1 bg-success"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            // onClick={Assignpop}
            onClick={() => Assignpop(row)}
          >
            Assign
          </Button>
        </div> 
     }

  {row?.cust?.isStatus ==false  && 
         <div className="d-flex align-items-center">
       <Button
            className="btn btn-sm  btn-success mx-1 bg-warning"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            // onClick={Assignpop}
            onClick={() => pendingPopUp(row)}
          >
            Pending
          </Button>
        </div> 
      }

{row?.cust?.isStatus ==true  &&
        <div className="d-flex align-items-center">
          <Button
            style={{ backgroundColor: "redd" }}
            className="btn btn-sm  btn-success mx-1 bg-info"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            // onClick={Assignpop}
        disabled={status == true ? false: true}
            onClick={() => Assignpop2(row)}
          >
            change
          </Button>
        </div>
    }
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
   
  ];

  const myData = (data) => {
    //console.log(data);
    let issueDate = "12-10-2022";
    // let customerId = customerId;
    let custAddress = data.customerAddress;
    // //console.log(issueDate);
    // //console.log(customerId);
    // //console.log(custAddress);
  };
  // //console.log(id);

  const Assignpop = (data) => {
    //console.log("data", data);
    // //console.log(row);
    ////console.log(row._cells[6].data);
    tog_list();
    setcustomerAddress(data.customerId.customerAddress);
    setcustomerId(data.customerId._id);
    getSingleCustomer(data.customerId._id);
    setcustPhone(data.customerId.customerPhone);
    setissueDate(data.createdAt);
    setEditing(true);
    setId(data._id);
    myData(data);
    setTest("muscaaaa");
  };
  const pendingPopUp = (data) => {
    //console.log("data", data);
    tog_list3();
    setPendingDate(data)
  
  };
  const Assignpop2 = (data) => {
    //console.log("data", data);
    // //console.log(row);
    ////console.log(row._cells[6].data);
    tog_list2();
    setCnumber(data?.receiverDetails?.receiverCardNumber);
    setCustomerIdd(data?.cust?._id);
    setRecNum(data?.receiverDetails?.[0]?.receiverNumber);

    // setEditing(true);
    // setId(data._id);
    // myData(data);
    // setTest("muscaaaa");
  };

  // //console.log(customerId);
  // //console.log(test);

  

  const showToastMessageError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const getEmployeePhone = (id) => {
    setEmployeeId(id);
    getSingleEmployee(id);
    //console.log(singleEmployee.mobile);
    setempPhone(singleEmployee.mobile);
  };

  async function sendMessage(content, phone) {
    if (description.trim().length < 10) {
      setTimeout(
        () => showToastMessageError("Error sending Message to Employee!"),
        2500
      );
    } else {
      //console.log(content, phone);
      //console.log(phone);
      let config = {
        "Content-Type": "text/plain",
        Accept: "*/*",
      };
      axios
        .get(
          `https://smsa.tabaarak.com/SendSMS.aspx?user=Asal&pass=TV@ccess2016&cont=${content}&rec=${phone}`,
          {
            headers: config,
          }
        )
        .then((response) => {
          //console.log(response.data);
          this.setState({ posts: response.data });
        })
        .catch((err) => {
          //console.log("API call error:", err.Message);
          setTimeout(
            () => showToastMessage("Message has been sent successfully"),
            2500
          );
        });
    }
  }

  //console.log("origin", empPhone);
  let FirstNum = String(empPhone)[0];
  //console.log("FirstNum", FirstNum);

  //console.log("customer", singleCustomer);

  // let Ename = singleEmployee.empName;
  // let Cname = singleCustomer.customerName;
  // let Cphone = singleCustomer.customerPhone;
  // let Caddress = singleCustomer.customerAddress;
  // let desc;

  // if (
  //   Ename == "" ||
  //   Ename == undefined ||
  //   Cname == "" ||
  //   Cname == undefined ||
  //   Caddress == "" ||
  //   Caddress == undefined
  // ) {
  //   desc = `${Ename} : ${description} -> Waxaa u Adeegtaa Macaaamilka ah '${Cname}' : Wata Telephone-Numberka -> ${Cphone} : Degaanka ${Caddress}`;
  // } else {
  //   desc = `${Ename.toUpperCase()} : ${description} -> Waxaa u Adeegtaa Macaaamilka ah '${Cname.toUpperCase()}' : Wata Telephone-Numberka -> ${Cphone} : Degaanka ${Caddress.toUpperCase()}`;
  // }

  // //console.log(desc);

  const handlersubmit = (e) => {
    e.preventDefault();

    const data = {
      issueDate: issueDate,
      customerId: customerId,
      custAddress: customerAddress,
      siteId: siteId,
      groupId: groupid,
      zoneId: zoneid,
      empId: EmployeeId,
      title: title,
      description: Message,
      cableId: id,
    };

    //console.log(data);
    if (
      !Message ||
      siteId == "0" ||
      groupid == "0" ||
      zoneid == "0" ||
      EmployeeId == "0"
    ) {
      alert("please Fill the required fields");

      let res = "please Fill the required fields";
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      //console.log(data);
      AssignCablesRegister(data);
      // registertickets(data)

      if (Message == "" || Message == undefined) {
        showToastMessageError(
          "Please make sure to fill description field to send a message"
        );
      } else {
        if (FirstNum != "0" || FirstNum != 0) {
          let number = "0" + empPhone;
          //console.log(number);

          var desc;
          if (
            singleEmployee.empName == "" ||
            singleEmployee.empName == undefined ||
            singleCustomer.customerName == "" ||
            singleCustomer.customerName == undefined ||
            singleCustomer.customerAddress == "" ||
            singleCustomer.customerAddress == undefined
          ) {
            desc = `${singleEmployee.empName} : ${Message} -> Waxaa u Adeegtaa Macaaamilka ah '${singleCustomer.customerName}' : Wata Telephone-Numberka -> ${singleCustomer.customerPhone} : Degaanka ${singleCustomer.customerAddress}`;
          } else {
            desc = `${singleEmployee.empName.toUpperCase()} : ${Message} -> Waxaa u Adeegtaa Macaaamilka ah '${singleCustomer.customerName.toUpperCase()}' : Wata Telephone-Numberka -> ${
              singleCustomer.customerPhone
            } : Degaanka ${singleCustomer.customerAddress.toUpperCase()}`;
          }
          //console.log(desc);
          sendMessage(desc, number);
        } else {
          //console.log(desc);
          sendMessage(desc, empPhone);
        }

        setTimeout(
          () => showToastMessage("Message has been sent successfully"),
          2500
        );
      }

      setmodal_list(false);
      clear();
    }
  };

  const handleSubmit = (e) => {
    //console.log(isEditing);
    e.preventDefault();

    var desc;
    if (supervisorInfo?.label == "" || supervisorInfo?.phoneNumber == "") {
      desc = `${supervisorInfo?.empname} : ${description} -> Waxaa u Adeegtaa Macaaamilka ah '${customerInfo?.customername}' : Wata telephone number '${customerInfo?.customerPhone}' : Degaanka ${customerInfo?.customerAddress}`;
    } else {
      desc = `${supervisorInfo?.empname} : ${description} -> Waxaa u Adeegtaa Macaaamilka ah '${customerInfo?.customername}' : Wata telephone number '${customerInfo?.customerPhone}' : Degaanka ${customerInfo?.customerAddress}`;
    }

    var PhoneNum = supervisorInfo?.phoneNumber;

    const data = {
      issueDate,
      empId: supervisorInfo?.value,
      customerId: customerInfo?.value,
      custAddress,
      siteId: null,
      groupId: null,
      zoneId: null,
      title,
      description,
      content: desc,
      phoneNumber: PhoneNum.trim(),
    };
    //console.log(data);

    if (description == "" || description == undefined) {
      showToastMessageError(
        "Please make sure to fill description field to send a message"
      );
    } else {
      ticketsSupervisorAdd(data);
      setmodal_list(false);
    }
    setSupervisorInfo("");
    setCustomerInfo("0");
    setCustAddress("");
    setSiteId("0");
    // setGroupId("0");
    // setZone("0");
    setTitle("");
    setdescription("");
  };

  const deletePuchase = (data) => {
    setmodal_delete(true);
    setId(data._id);
  };

  const delPurchase = () => {
    removePurchase(id);
    setmodal_delete(false);
  };

  //updateees
  //console.log(isEditing);






  let getEmployees = (id) => {
    setzoneid(id);
    let Data = [
      {
        siteId: siteId,
        groupid,
        zoneId: id,
      },
    ];

    fetchByIEmployee(Data);
    setEmployeeId("");
    //console.log("site", siteId, "group", groupid, "zone", id);
  };

  //console.log(GetByIdEmployee);


  const dateFromDB = "2005-12-03T18:30:00.000Z";
  const formattedDate = moment(dateFromDB).utc().format("DD/MM/YYYY");

  //console.log("Date From DB:", dateFromDB);
  //=> 2005-12-03T18:30:00.000Z

  //console.log("Formatted Date:", formattedDate);
  //console.log("Here: ", Cables);

  const sortedCables = [];
  for (let i = 0; i < Cables?.length; i++) {
    let date = Cables[i].createdAt.split("T");
    const formattedDate = moment(date[0]).utc().format("DD-MM-YYYY");
    //console.log(Cables[i].isAssignedToEmployee);
    let singleArr = [
      formattedDate,
      Cables[i].customerId?.customerName,
      Cables[i].cardDetails?.phoneNumber,
      Cables[i].receiverDetails?.length,
      Cables[i].saleDetails?.length,
      // Cables[i].type,
      Cables[i].isAssignedToEmployee === false ? "No" : "yes",
      Cables[i]?.customerId?._id,
    ];

    sortedCables.push(singleArr);
  }

  let getCustAddress = (id) => {
    setCustomerInfo(id);

    //console.log("ID: ", id);
    setcustomerId(id);
    getSingleCustomer(id);
    setCustAddress(id.customerAddress);

    //console.log("Address", singleCustomer.customerAddress);
  };

  const supervisorsArr = [];

  for (let i = 0; i < supervisors.length; i++) {
    // //console.log(supervisors[i]._id);

    var dropdownList = {
      value: supervisors[i]._id,
      label: ` ${supervisors[i].empName} -- ${supervisors[i].mobile}`,
      empname: ` ${supervisors[i].empName}`,
      phoneNumber: ` ${supervisors[i].mobile}`,
    };

    supervisorsArr.push(dropdownList);
  }

  //console.log("supervisorrrrrr: ", supervisorsArr);
  const CustomersArr = [];

  for (let i = 0; i < customers.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: customers[i]._id,
      label: ` ${customers[i].customerName} -- ${customers[i].customerPhone}`,
      customername: ` ${customers[i].customerName}`,
      customerPhone: ` ${customers[i].customerPhone}`,
      customerAddress: ` ${customers[i].customerAddress}`,
    };

    CustomersArr.push(dropdownList);
  }

  const close = () => {
    setDate("");
    setdescription("");
    setTitle("");
    setCustomerInfo("");
    setSupervisorInfo("");
    setCustAddress("");
    setmodal_list(false);
  };
  const close2 = () => {
    setDate("");
    setdescription("");
    setTitle("");
    setCustomerInfo("");
    setSupervisorInfo("");
    setCustAddress("");
    setmodal_list2(false);
  };
  const close3 = () => {
    setDate("");
    setdescription("");
    setTitle("");
    setCustomerInfo("");
    setSupervisorInfo("");
    setCustAddress("");
    setmodal_list3(false);
  };

  const filterThroughDate = () => {
    setHelper(true);
    const startt = moment(start).format("YYYY-MM-DD");
    const enddd = moment(end).format("YYYY-MM-DD");
    fetchCablesByDate(startt, enddd);
  };
 

  const clearFilter = () => {
    setHelper(false);
    setStart("")
    setEnd("")
    setcustomerId("")
    setType("")
    setsetLine("")
  };
 

  const makeItArray = [];

  for (let i = 0; i < reciveById.length; i++) {
    // //console.log("from: " + formattedDate);
    let singleArr = {
      cardNum: reciveById[i]?.cardNumber,
    };

    makeItArray.push(singleArr);
  }
  //console.log(makeItArray);
  const onInputChange = (event, value, reason) => {
    //console.log(value);
    setthe_CardNumber(value);

    getByIdRecive(recTypes?.value, value);

    //console.log(reciveById);
  };
  const suppliersArr = [];

  for (let i = 0; i < itemGetAllReceiver.length; i++) {
    // //console.log(itemGetAllReceiver[i]._id);

    var dropdownList = {
      value: itemGetAllReceiver[i]._id,
      label: ` ${itemGetAllReceiver[i].itemName}`,
    };

    suppliersArr.push(dropdownList);
  }

  const submitChangeCardNumber = (e) => {
    e.preventDefault();
    const data = {
      customerId: customerIdd,
      oldReceiverCardNumber: cNumber,
      receiverType: recTypes?.value,
      newReceiverCardNumber: the_cardNumber,
      description: remark,
      receiverNumber: recNumber,
    };

    if (
      !customerIdd ||
      !cNumber ||
      !recTypes?.value ||
      !the_cardNumber ||
      !remark ||
      !recNumber
    ) {
      toast.error("Please provide all the required values", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    //console.log(data);
    e.target.reset();
    setRemark("");
    setReceiverTypes("");
    setmodal_list2(false);
    changeReceiverCardNumber(data);
  };
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const submitPendingForm = (e)=>{
    e.preventDefault();
   
    const data = {
      customerId: pendingDate?.cust?._id,
      customerUser:userId,
      isStatus:true

    }

    if(!data.customerId ){
      showToastMessage("Please provide  cutomer id")
      return
      
    }
   
    if(!data.customerId ||!data.customerUser){
      showToastMessage("Please provide User Id")
      return
      
    }
   
    updateManageCable(data)
    //console.log("pending form" , data)
  }
 
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Manage Cables" pageTitle="Billing" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">Manage Cables</h4>
                        </div>
                      </div>
                    </Col>
                    <Row className="my-5">
                  
                      <Col md={2} >
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Start Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStart(date)}
                            value={start}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            End Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEnd(date)}
                            value={end}
                          />
                        </div>
                      </Col>
                      
                      <Col md={2}>
                        <div className="form-label">
                          {isLoading ==true ?
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={filterThroughDate}
                            type="button"
                            //  id="create-btn"
                            disabled
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Filter By Date{" "}
                          </Button>:<Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={filterThroughDate}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Filter By Date{" "}
                          </Button>
                          }
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={clearFilter}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Clear filter{" "}
                          </Button>
                        </div>
                      </Col>
                   
                    </Row>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="StoreList">
                    <Row className="g-4 mb-3"></Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                   
                     
                        <DataTable
                          columns={columns}
                          data={filteredItems}
                          pagination
                          progressPending={isNewCableLoading  }
                        progressComponent={<SpinnerBorder />}
                          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                          subHeader
                          subHeaderComponent={subHeaderComponentMemo}
                          // selectableRows
                          persistTableHead
                          // sortFunction={customSort}
                        />
                      
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">Create New Ticket</h5>

          <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>

            <Row>
              <Col md={4}>
                <div className="mb-3">
                  <label>
                    Ticket date <span className="text-danger">*</span>
                  </label>
                  <Flatpickr
                    className="form-control"
                    required
                    placeholder="DD/MM/YYYY"
                    options={{
                      dateFormat: "d/m/Y",
                      defaultDate: "today",
                    }}
                    onChange={([date]) => setDate(date)}
                    // value={Date}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <Label className="form-label">
                    Customer <span className="text-danger">*</span>
                  </Label>

                  <Select
                    // className="bg-white"
                    options={CustomersArr}
                    onChange={(choice) =>
                      // getCustPhone(e.target.value, e.target)
                      getCustAddress(choice)
                    }
                    value={customerInfo}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                  />
                </div>
              </Col>

              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">Address</label>
                  <input
                    type="text"
                    id="address"
                    className="form-control"
                    placeholder="Enter Address"
                    onChange={(e) => setCustAddress(e.target.value, e.target)}
                    value={custAddress}
                  />
                </div>
              </Col>
            </Row>
            <Row></Row>
            <Row>
              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">Supervisor</label>
                  <Select
                    // className="bg-white"
                    options={supervisorsArr}
                    onChange={(choice) =>
                      // getCustPhone(e.target.value, e.target)
                      setSupervisorInfo(choice)
                    }
                    value={supervisorInfo}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={8}>
                <div className="mb-33">
                  <label className="form-label">Ticket title</label>
                  <input
                    type="text"
                    id="address"
                    className="form-control"
                    placeholder="Enter Ticket Title"
                    onChange={(e) => setTitle(e.target.value, e.target)}
                    value={title}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <textarea
                    className="form-control"
                    id="Description-field"
                    rows="4"
                    onChange={(e) => setdescription(e.target.value, e.target)}
                    value={description}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-light" onClick={close}>
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                Save
              </button>
            </div>
          </ModalFooter>
        </form>

        {/* =================================== */}
      </Modal>
      <Modal
        isOpen={modal_list2}
        toggle={() => {
          tog_list2();
        }}
        centered
        size="lg"
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">change card number</h5>

          <Button
            type="button"
            onClick={() => {
              setmodal_list2(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>

        <form onSubmit={submitChangeCardNumber}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>

            <Row>
              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">
                    card number you want changed
                  </label>
                  <input
                    type="text"
                    id="address"
                    className="form-control"
                    placeholder="Enter Address"
                    // onChange={(e) => setCustAddress(e.target.value, e.target)}
                    value={cNumber}
                    disabled
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-4">
                  <Select
                    // className="bg-white"
                    options={suppliersArr}
                    onChange={(choice) => {
                      setReceiverTypes(choice);
                      setClean(false);
                      setTOtotrue(true);
                    }}
                    value={suppliersArr.filter(function (option) {
                      return option.value === recTypes?.value;
                    })}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-4">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    options={makeItArray}
                    getOptionLabel={(option) => option?.cardNum?.toString()}
                    onInputChange={onInputChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Card number" />
                    )}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <label className="form-label">Remark</label>
                  <textarea
                    className="form-control"
                    id="Description-field"
                    rows="4"
                    onChange={(e) => setRemark(e.target.value, e.target)}
                    value={remark}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-light" onClick={close2}>
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                Save
              </button>
            </div>
          </ModalFooter>
        </form>

        {/* =================================== */}
      </Modal>

      <>
      

      <Modal
        isOpen={modal_list3}
        toggle={() => {
          tog_list3();
        }}
        centered
        size="lg"
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">customer info</h5>

          <Button
            type="button"
            onClick={() => {
              setmodal_list3(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>

        <form onSubmit={submitPendingForm}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>

            <Row>
              <Col md={4}>
              <div className="mt-3">
                  <label className="form-label">
                    customer Name
                  </label>
                  <input
                    type="text"
                    id="address"
                    className="form-control"
                    placeholder="Enter Address"
                    // onChange={(e) => setCustAddress(e.target.value, e.target)}
                    value={pendingDate?.cust?.customerName}
                    disabled
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                <label className="form-label">
                    customer phone
                  </label>
                <input
                    type="text"
                    id="address"
                    className="form-control"
                    placeholder="Enter Address"
                    // onChange={(e) => setCustAddress(e.target.value, e.target)}
                    value={pendingDate?.cust?.customerPhone}
                    disabled
                  />
                </div>
              </Col>
              <Col md={4}>
              <div className="mt-3">
              <label className="form-label">
                 receiver card number
                  </label>
                <input
                    type="text"
                    id="address"
                    className="form-control"
                    placeholder="Enter Address"
                    // onChange={(e) => setCustAddress(e.target.value, e.target)}
                    value={pendingDate?.receiverDetails?.receiverCardNumber

                    }
                    disabled
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="mb-3 mt-4">
                  <label className="form-label">Enter user id</label>
                  <input
                    type="text"
                    id="address"
                    className="form-control"
                    placeholder="Enter user id"
                    onChange={(e) => setUserId(e.target.value, e.target)}
                  
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-light" onClick={close3}>
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                Save
              </button>
            </div>
          </ModalFooter>
        </form>

        {/* =================================== */}
      </Modal>
      </>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => delPurchase()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ManageCables;
const SpinnerBorder = () => {
  return <Spinner className="my-2 text-center" />;
};