import React, { useState, useEffect, useContext, useMemo , useRef } from "react";
import { Grid, _ } from "gridjs-react";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { BsPen } from "react-icons/bs";
import { BsFillEyeFill } from "react-icons/bs";
import DataTable from "react-data-table-component";
import logo from "./LogoTabaarak.png";

import ReactToPrint, { useReactToPrint } from 'react-to-print';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    DropdownItem,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
//import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrintComponent from "./PrintComponent";
import { Backdrop } from "@mui/material";


const GeneratePayroll = () => {

    const [isEditing, setEditing] = useState(false);

    const [additionalCost, setadditionalCost] = useState("");
    const [discount, setdiscount] = useState();
    const [totalAmount, settotalAmount] = useState();
    const [bonus, setbonus] = useState();
    const [commission, setcommision] = useState();
    const [year, setyear] = useState();
    const [month, setmonth] = useState();
    const [description, setdescription] = useState();
    const {
        registerStore,
        stores,
        deleteStores,
        upadteStore,
        fetchItems,
        fetchStores,
        fetchSites,
        sites,
        items,
        getEmployee,
        fetchEmployees,
        fetchPayroll,
        Commissions,
        IPTVHormuud,
        registerPayroll,
        fetchPayrollMain,
        PayrollMain,
        User
        
    } = useContext(LoginContex);
    let userId = User?.data?.user?._id || User?.user?._id;
    const [modal_list, setmodal_list] = useState(false);
    const tog_list = () => {
        setmodal_list(!modal_list);
    };
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
  
    const [modal_delete, setmodal_delete] = useState(false);
    const tog_delete = () => {
        setmodal_delete(!modal_delete);
    };

    useEffect(() => {
        fetchEmployees();
        fetchPayroll();
    }, []);

    // //console.log(Commissions);
    // //console.log(getEmployee);


    document.title = "Generate Payroll";


    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] =
        React.useState(false);
    const filteredItems = PayrollMain.filter(
        (item) =>
            item?.empName &&
            item?.empName.toLowerCase().includes(filterText.toLowerCase())
    );
    //console.log(filteredItems);
    //console.log(filterText);
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <div>
                <Input
                    onChange={(e) => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                    placeholder="search here"
                />
            </div>
        );
    }, [filterText, resetPaginationToggle]);

    let Rows = 0;
    for (let i = 0; i < PayrollMain?.length; i++) {
        Rows += 1;
    }
    // let userId = User?.data?.user?._id
    const columns = [
        // {
        //     name: <h6 style={{ fontWeight: "bold" }}> No</h6>,
        //     selector: (row) => Rows,
        // },
        

        {
            name: <h6 style={{ fontWeight: "bold" }}> Full Name</h6>,
            selector: (row) => row.empName,
        },
        {
            name: <h6 style={{ fontWeight: "bold" }}> Bank Name</h6>,
            selector: (row) => row.bankName,
        },
        {
            name: <h6 style={{ fontWeight: "bold" }}> Account</h6>,
            selector: (row) => row.bankAccount,
        },
        {
            name: <h6 style={{ fontWeight: "bold" }}> Salary</h6>,
            selector: (row) => row.baseSalary,
        },
        {
       
            name: <h6 style={{ fontWeight: "bold" }}> Advance</h6>,
            selector: (row) => row.AdvanceAmount == undefined ? 0 : row.AdvanceAmount,
        },
        {
            
            name: <h6 style={{ fontWeight: "bold" }}> Deduction</h6>,
            selector: (row) => row.deductionAmount == undefined ? 0 : row.deductionAmount ,
        },
        {
            name: <h6 style={{ fontWeight: "bold" }}> Net Payment</h6>,
            selector: (row) => (row.baseSalary) - ((row.AdvanceAmount == undefined ? 0 : row.AdvanceAmount) - (row.deductionAmount == undefined ? 0 : row.deductionAmount)),
        },

        {
            /*end*/
        },
    ];

    //console.log("coluuumns" , columns)

    const handleServiceChangeProduct = (e, index) => {
        const { name, value } = e.target;
        const list = [...EmployeeList];

        list[index][name] = value;
        //console.log(list[index][name]);
    };


    function showTableData() {
        let myTable = document.querySelector('#SetupTable');
        Object.values(getEmployee).forEach(entry => {
            var inputs = myTable.getElementsByTagName("input");
            var result = new Array(inputs.length);
            for (var i = 0; i < inputs.length; i++)
                //products[i].total.value = products[i].waarde.value * products[i].aantal.value; 
                result[i] = inputs[i].value;
            //console.log(result);
        });

    }

    const initialState = {
        customerData: {
            empID: '',
            Bonus: '',
            Commission: ''
        }
    }

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const d = new Date();

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let time = new Date().toLocaleTimeString('en-US');


    today = yyyy + "-" + mm + "-" + dd + " ";

    // //console.log("year", yyyy, "month", monthNames[d.getMonth()]);

    const [EmployeeList, setEmployeeList] = useState([
        {
            empId: "",
            baseSalary: "",
            AdvanceAmount: "",
            deductionAmount: ""
        },
    ]);


    const [List, setList] = useState([
        {
            empId: "",

        },
    ]);

    const [inputarr,
        setInputarr] = useState([])

    const showToastMessage = (message) => {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let EmpID = "";
        let Bonus = 0;
        let Commission = 0;


        var sumVal = 0;
        var table = document.getElementById("SetupTable");

        for (var i = 1; i < (table.rows.length - 1); i++) {
            sumVal = sumVal + parseInt(table.rows[i].cells[3].innerHTML);
        }

        // //console.log(sumVal);

    };


    const mockData = [
        {
            Name: "Hussein Jamac Liban",
            Salary: "600",
            Advance: "100",
            Deduction: "50",
            Account: "16245454",
            BankName: "Premiere Bank",
            Netpayment: "500",
        },
        {
            Name: "Aisha Mohamed Ahmed",
            Salary: "800",
            Advance: "50",
            Deduction: "30",
            Account: "455974",
            BankName: "Salaam Bank",
            Netpayment: "600",
        },
    ];


    if (year == undefined || year == null || month == undefined || month == null) {
        setyear(yyyy);
        setmonth(monthNames[d.getMonth()]);

        const data = {
            year: yyyy,
            month: monthNames[d.getMonth()]
        }
        fetchPayrollMain(data);
    }

    const SetYearPayroll = (e) => {
        setyear(e.target.value);

    }

    const SetMonthPayroll = (e) => {
        setmonth(e.target.value);

        const data = {
            year,
            month: e.target.value
        };
        fetchPayrollMain(data);

        //console.log(PayrollMain);

    }



    const sortPayrolls = [];
    for (let i = 0; i < PayrollMain?.length; i++) {
        let advance = PayrollMain[i].AdvanceAmount == undefined ? 0 : PayrollMain[i].AdvanceAmount;
        let deduction = PayrollMain[i].deductionAmount == undefined ? 0 : PayrollMain[i].deductionAmount;

        let singleArr = [
            i + 1,
            PayrollMain[i].empName,
            PayrollMain[i].bankName,
            PayrollMain[i].bankAccount,
            PayrollMain[i].baseSalary,
            PayrollMain[i].AdvanceAmount == undefined ? 0 : PayrollMain[i].AdvanceAmount,
            PayrollMain[i].deductionAmount == undefined ? 0 : PayrollMain[i].deductionAmount,
            PayrollMain[i].baseSalary - (advance + deduction),
            PayrollMain[i]._id,
        ];
        sortPayrolls.push(singleArr);
        // //console.log(PayrollMain[i].baseSalary - ((PayrollMain[i].AdvanceAmount == undefined ? 0 : PayrollMain[i].AdvanceAmount) + PayrollMain[i].deductionAmount == undefined ? 0 : PayrollMain[i].deductionAmount));
    }


    //console.log(sortPayrolls);

    const EmpLists = [];

    for (let i = 0; i < PayrollMain?.length; i++) {


        let singleArr = {
            empId: PayrollMain[i]._id,
            baseSalary: PayrollMain[i].baseSalary,
            AdvanceAmount: PayrollMain[i].AdvanceAmount == undefined ? 0 : PayrollMain[i].AdvanceAmount,
            deductionAmount: PayrollMain[i].deductionAmount == undefined ? 0 : PayrollMain[i].deductionAmount,
        };
        EmpLists.push(singleArr);
    }


    // //console.log("lists" , EmpLists)

    // //console.log(sortPayrolls);

    const Generate = () => {

        if (year == undefined || year == '') {
            showToastMessage('Please Select a year to generate')
        }
        if (month == undefined || month == '') {
            showToastMessage('Please Select a month to generate')
        } else {
            const data = {
                year: year,
                month: month,
                description: description,
                generatePayrollDetails: EmpLists,
                userId: userId
            }
            //console.log(data);
             registerPayroll(data);
       

        }
    };

    const printer = () => {
        let myStyle = '<link rel="stylesheet" href="./demo" />';
        let printContents = document.getElementById("divcontents").innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = myStyle + printContents;
        window.print();
        document.body.innerHTML = originalContents;
      };


      const data = [
        {employeeName:"muscab", account:50056, net:7854},
        {employeeName:"muscab", account:50056, net:7854},
        {employeeName:"muscab", account:50056, net:7854},
        {employeeName:"muscab", account:50056, net:7854},
        {employeeName:"muscab", account:50056, net:7854},
        {employeeName:"muscab", account:50056, net:7854},
        {employeeName:"muscab", account:50056, net:7854},
        {employeeName:"muscab", account:50056, net:7854},
        {employeeName:"muscab", account:50056, net:7854},
        {employeeName:"muscab", account:50056, net:7854},
        {employeeName:"muscab", account:50056, net:7854},
    
    ]

    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    //console.log(year)

    const printedData = [];
//   //console.log(PayrollMain);
  for (let i = 0; i < PayrollMain.length; i++) {
    //console.log(PayrollMain[i]._id);

    var dropdownList = {
      firstname: PayrollMain[i].empName,
      bankAccount: ` ${PayrollMain[i].bankAccount}`,
      baseSalary: ` ${PayrollMain[i].baseSalary}`,
    };

    printedData.push(dropdownList);
  }

  const generatePayrool = ()=>{

    // setTimeout(() => {
    // printer()
        
    // }, 1000);
  }
//   //console.log(printedData)
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Generate Payroll" pageTitle="Generate Payroll" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                            <div>
                                                <div>
                                                    <h4 className="card-title mb-0">Generate Payroll</h4>
                                                </div>
                                                <ToastContainer />
                                            </div>
                                        </Col>

                                    </Row>
                                </CardHeader>

                                <CardBody>
                                    <div id="StoreList">
                                    <Row style={{ marginLeft: "0.2%" }}>
                                                <Col md={3}>
                                                    <div>
                                                        <label>Select Year</label>

                                                        <select className="form-select " id="itemtype"
                                                            onChange={(e) => SetYearPayroll(e)}
                                                            value={year}
                                                        >
                                                            <option selected>Choose...</option>
                                                            <option value={previousYear}>{previousYear}</option>
                                                            <option value={currentYear}>{currentYear}</option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <label>Select Month</label>
                                                        <select className="form-select " id="itemtype"
                                                            onChange={(e) => SetMonthPayroll(e)}
                                                            value={month}
                                                        >
                                                            <option selected>Choose...</option>
                                                            <option value="January">January</option>
                                                            <option value="February">February</option>
                                                            <option value="March">March</option>
                                                            <option value="April">April</option>
                                                            <option value="May">May</option>
                                                            <option value="June">June</option>
                                                            <option value="July">July</option>
                                                            <option value="August">August</option>
                                                            <option value="September">September</option>
                                                            <option value="October">October</option>
                                                            <option value="November">November</option>
                                                            <option value="December">December</option>

                                                        </select>
                                                    </div>


                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <label>Description</label>
                                                        <input
                                                            type="text"
                                                            id="Purchasesid-field"
                                                            className="form-control"
                                                            placeholder="Enter Description"
                                                            // required
                                                            onChange={(e) => setdescription(e.target.value)}
                                                        />
                                                    </div>
                                                </Col>

                                            </Row>
                                            {PayrollMain.length > 0 && (
                                                <DataTable
                                                    columns={columns}
                                                    data={filteredItems}
                                                    pagination
                                                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                                    subHeader
                                                    subHeaderComponent={subHeaderComponentMemo}
                                                    // selectableRows
                                                    persistTableHead
                                                // sortFunction={customSort}
                                                />
                                            )}
                                            
                                            <div>

                                            </div>

                                        <button
                                            className="btn btn-sm btn-success edit-item-btn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#showModal"
                                            onClick={Generate}
                                            // onClick={()=>handlePrint()}
                                            // onClick={()=>handlePrint()}
                                            style={{ backgroundColor: "#405189", border: "none", float: "right", marginTop: "4%", width: "100px", height: "30px" }}
                                        >Generate
                                        </button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* end of container */}
                </Container>
            </div>

            {/* Add Modal */}
            {/* Store Registration From */}

            <Modal
                isOpen={modal_list}
                toggle={() => {
                    tog_list();
                }}
                centered
                size="lg"
            >
                {/* <ModalHeader className="bg-light p-3 pull right">
                    Commission & Bonus SetUp
                    <Button
                        type="button"
                        onClick={() => {
                            setmodal_list(false);
                        }}
                        className="btn-close float-right"
                        aria-label="Close"
                    ></Button>
                </ModalHeader> */}
                <div className="bg-light p-3 modal-header"><h5 className="modal-title">Commission & Bonus SetUp</h5>

                    <Button
                        type="button"
                        onClick={() => {
                            setmodal_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>


                </div>
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <div className="table-responsive table-card mt-3 mb-1">
                            <table
                                className="table align-middle table-nowrap"
                                id="SetupTable"
                            >
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col" style={{ width: "5px" }}></th>

                                        <th className="sort" data-sort="date">
                                            ID
                                        </th>
                                        <th className="sort" data-sort="date">
                                            Full Name
                                        </th>

                                        <th className="sort" data-sort="TotalAmount">
                                            Advance
                                        </th>
                                        <th className="sort" data-sort="Type">
                                            Deduction
                                        </th>


                                    </tr>
                                </thead>
                                <tbody className="list form-check-all">
                                    {getEmployee?.map((data, index) => {
                                        return (
                                            <tr key={index} id="somerow">
                                                <th scope="row"></th>

                                                <td className="id" style={{ display: "none" }}>
                                                    <Link
                                                        to="#"
                                                        className="fw-medium link-primary"
                                                    >
                                                        #VZ2104
                                                    </Link>
                                                </td>
                                                <td style={{ display: "none" }}>{''}{data._id}</td>
                                                <td>{''}{data.empId}</td>
                                                <td name="empID">{''}{data.empName}</td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        id="Purchasesid-field"
                                                        className="form-control"
                                                        placeholder="00.00"
                                                        // required
                                                        name="Bonus"
                                                        onChange={(e) =>
                                                            handleServiceChangeProduct(e, index)
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        id="Purchasesid-field"
                                                        className="form-control"
                                                        placeholder="00.00"
                                                        // required
                                                        name="Commission"

                                                        onChange={(e) =>
                                                            handleServiceChangeProduct(e, index)
                                                        }
                                                    />
                                                </td>



                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div className="noresult" style={{ display: "none" }}>
                                <div className="text-center">
                                    <lord-icon
                                        src="https://cdn.lordicon.com/msoeawqm.json"
                                        trigger="loop"
                                        colors="primary:#121331,secondary:#08a88a"
                                        style={{ width: "75px", height: "75px" }}
                                    ></lord-icon>
                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                    <p className="text-muted mb-0">
                                        We've searched more than 150+ Orders We did not find
                                        any orders for you search.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* <FormGroup tag="fieldset">
              Is Primary Location
              <FormGroup check></FormGroup>
              <FormGroup check onChange={(e) => setYes(e.target.value)}>
                <Label check>
                  <Input type="radio" name="radio1" /> Yes
                </Label>
              </FormGroup> */}
                        {/* <FormGroup check  >
                <Label check>
                  <Input
                    type="radio"
                    name="radio1"
                  />{" "}
                  No
                </Label>
              </FormGroup> */}
                        {/* </FormGroup> */}
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => setmodal_list(false)}
                            >
                                Close
                            </button>
                            <button type="submit" className="btn btn-success" id="add-btn">
                                {isEditing ? "update" : "save"}
                            </button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Remove Modal */}
            <Modal
                isOpen={modal_delete}
                toggle={() => {
                    tog_delete();
                }}
                className="modal"
                id="deleteRecordModal"
                centered
            >
                <div className="modal-header">
                    <Button
                        type="button"
                        onClick={() => setmodal_delete(false)}
                        className="btn-close"
                        aria-label="Close"
                    >
                        {" "}
                    </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon
                            src="https://cdn.lordicon.com/gsqxdxog.json"
                            trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548"
                            style={{ width: "100px", height: "100px" }}
                        ></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">
                                Are you Sure You want to Remove this Record ?
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            onClick={() => setmodal_delete(false)}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                        // onClick={() => delPurchase()}
                        >
                            Yes, Delete It!
                        </button>
                    </div>
                </ModalBody>
            </Modal>


{/* print code jsx */}
<Card ref={componentRef}>
 <div id="divcontents" style={{ marginLeft: "60px", display: "none" }} >
        <img
          src={logo}
          alt="Logo"
          style={{ marginLeft: "35%", size: "40px" }}
        />

        <div className="maincontainer">
          <div
            className="CustomerInfo"
            style={{ display: "inline-block", float: "right" }}
          >
            <div
              className="Custinfo2"
              style={{ display: "inline-block", float: "left" }}
            >
              <div style={{ float: "right" }}>
                <label className="salesDate">generated Date : </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                  {today}
                </span>
              </div>
              <br />

              <div style={{ float: "right" }}>
                <label className="Customer"> </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                  {/* {singleCustomer.customerName} */}
                </span>
              </div>
              <br />

              <div style={{ float: "right" }}>
                <label className="Customer"> </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                  {/* {singleCustomer.customerPhone} */}
                </span>
              </div>
              <br />

              {/* <div style={{ float: "right" }}>
                <label className="Customer">Address : </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                </span>
              </div> */}
            </div>
          </div>
          <br />
          <br />

          <div className="itemstable" style={{ marginTop: "50px" }}>
            <table
              id="items"
              className="table table-striped table-bordered table-hover"
              style={{
                width: "100%",
                maxWidth: "100%",
                fontSize: "15px",
                position: "relative",
              }}
            >
              <thead className="list form-check-all">
                <tr style={{ marginRight: "-100px" }}>
                  <th></th>
                  <th>Full Name</th>
                  <th>Bank Account</th>
                  <th> Net Income </th>

                 
                </tr>
              </thead>
              <tbody className="list form-check-all">
                {printedData?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td></td>

                      <td className="Customer">{data.firstname}</td>
                      <td className="Customer">{data?.bankAccount}</td>
                      <td className="Customer">{data?.baseSalary}</td>


                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
           
        </div>
        </div>
      </div>
      </Card>
 {/* Start of Table for printer */}

{/* <ReactToPrint
        trigger={() => <button>Print this out!</button>}
        content={() => componentRef.current}
      /> */}
{/* print code jsx end */}
        </React.Fragment>
    );
};

export default GeneratePayroll;
