import React, { useState, useEffect, useContext, useMemo } from "react";

import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import moment from "moment";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import DataTable from "react-data-table-component";
const IptvOrganizationReport = () => {
  
  const [Discription, setdescription] = useState("");
  const { openAnalogCardRegister } = useContext(LoginContex);
  
//   const [customerId, setCustomer] = useState();


  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [type, setType] = useState();
  const [helper, setHelper] = useState(false);
  const [customerId, setcustomerId] = useState("");
  const [Line, setsetLine] = useState("all");
  const [info, setsetInfo] = useState("");
  // const [Line, setsetLine] = useState("all");


  document.title = "iptv organizations Report";

  const {

    cardList,

    fetchCustomers,
    customers,fetchTicketRegesterationReport,ticketRegReport , fetchEmployeeReport,employeeReport,
    fetchTicketTransferReport, ticketTransferReport,  FechIptvCustomerRechargeReport, iptvCustomerRechargeData,
    FechOttCustomerRechargeReport, custiptvRepot, fetchCustIPTVReport,isLoading,fetchIptvOrganization,iptvOrganizationsReport

  } = useContext(LoginContex);

  useEffect(() => {

    fetchCustomers();
    let date= new Date()
    const start = moment(date).format("YYYY-MM-DD");
    const end = moment(date).format("YYYY-MM-DD");
    fetchCustIPTVReport(start, end,"APP");

  }, []);

  //console.log(custiptvRepot);
  //console.log(customers);


  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = iptvOrganizationsReport?.filter(
    (item) =>
      (item?.customerName && item?.customerName?.toLowerCase().includes(filterText.toLowerCase())) &&
      item.customerName?.toLowerCase().includes(filterText.toLowerCase()) && item.customerPhone ||
      item.customerPhone?.toString().toLowerCase().includes(filterText.toLowerCase())  && item.customerTell ||
      item.customerTell?.toString().toLowerCase().includes(filterText.toLowerCase())
  );
  //console.log(filteredItems);
  // //console.log(iptvCustomerRechargeData);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" , width:"400px" }}> Customer Name</h6>,
      selector: (row) => `${row?.customerName} ${row?.homes?.cName ? "--" + row?.homes?.cName:""}`,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Phone</h6>,
      selector: (row) => row?.customerPhone,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Telephone</h6>,
      selector: (row) => row?.homes?.line ?row?.homes?.line :  row?.customerTell,
    },
    
    {
      name: <h6 style={{ fontWeight: "bold" }}>Subscription</h6>,
      selector: (row) => row?.homes?.subscription ? row?.homes?.subscription :row?.subscriptionID,
    },
 
    {
      name: <h6 style={{ fontWeight: "bold" }}>Start Date</h6>,
      selector: (row) => moment(row?.startDate).utc().format("DD-MM-YYYY"),
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>End Date</h6>,
      selector: (row) => moment(row?.endDate).utc().format("DD-MM-YYYY"),
    },
    

  ];

  const columns1 = useMemo(
    () => [
      {
        Header: "Name",
        accessor: (row) => `${row?.customerName} ${row?.homes?.cName ? "--" + row?.homes?.cName:""}`,
        filterable: false,
      },
      {
        Header: "Phone Number",
        accessor: "customerPhone",
        filterable: false,
      },
     
      // {
      //   Header: "Address",
      //   accessor: "customer_info.customerAddress",
      //   filterable: false,
      // },
      {
        Header: "Tell",
        accessor:(row)=> `${row?.homes?.line ?row?.homes?.line :  row?.customerTell}`,
        filterable: false,
      },
      {
        Header: "subscription",
        accessor: (row)=> `${row?.homes?.subscription ? row?.homes?.subscription :row?.subscriptionID}`,
        filterable: false,
      },
      // {
      //   Header: " status",
      //   accessor: "lastCard.status",
      //   filterable: false,
      // },
      
      {
        Header: "Start Date",
        accessor: "startDate",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      {
        Header: "End Date",
        accessor: "endDate",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      

      
    ]
    // [handleCustomerClick]
  );
 
  
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
 

  const filterThroughDate = () => {
    setHelper(true);
    // const startt = moment(start).format("YYYY-MM-DD");
    // const enddd = moment(end).format("YYYY-MM-DD");
    let infoData = info? info : "61"
    fetchIptvOrganization(info);
  };
 

  const clearFilter = () => {
    setHelper(false);
    setStart("")
    setEnd("")
    setcustomerId("")
    setType("")
    setsetLine("")
  };
 
  const CustomersArr = [];

  for (let i = 0; i < customers.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: customers[i]._id,
      label: ` ${customers[i].customerName}---${customers[i].customerPhone}`,
    };

    CustomersArr.push(dropdownList);
  }

  const customerCards = [];



  const CvsData = [];
  for (let i = 0; i < filteredItems?.length; i++) {
    // //console.log(filteredItems[i]._id);

    if(filteredItems[i]?.customerName){
      var dropdownList = {
        CustomerName: `${filteredItems[i]?.customerName}  ${filteredItems[i]?.homes?.cName ?"--"+ filteredItems[i]?.homes?.cName :"" } `,
        PhoneNumber: ` ${filteredItems[i]?.customerPhone}`,
      
        Lindline: ` ${filteredItems[i]?.customerTell}`,
        // User: ` ${filteredItems[i]?.username}`,
       
        Subscription: `${filteredItems[i]?.homes?.subscription ? filteredItems[i]?.homes?.subscription :filteredItems[i]?.subscriptionID}`,
        StartDate: ` ${moment(filteredItems[i]?.startDate).utc().format("DD-MM-YYYY")}`,
        EndDate: ` ${moment(filteredItems[i]?.endDate).utc().format("DD-MM-YYYY")}`,
       
       
      };
    }
   

    CvsData.push(dropdownList);
  }
//console.log(CvsData)
// //console.log(ticketSupervisorReport)

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData?.[0] || []);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // ** Downloads CSV
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="IPTV Organizations REPORT" pageTitle="Report" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-autod">
                      <div>
                        <ToastContainer />
                        <div className="">
                          <h4 className="card-title mb-0">IPTV ORGANIZATIONS REPORT</h4>
                          {/* <Button>Go Back</Button> */}
                         
                        <div className="form-label" style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end"}}>
                          {filteredItems.length > 0 && 
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={()=>{
                              downloadCSV(CvsData)
                            }}
                            type="button"
                            //  id="create-btn"
                          > 
                            <i className="fa fa-print mx-2" aria-hidden="true"> </i>  
                            Download Excel
                          </Button>}
                        </div>
                     
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="mb-5">
                    {/* <Col md={2}>
                       
                       <Label htmlFor="PurchasesDate" className="form-label">
                         TYPE <span className="text-danger">*</span>
                       </Label>

                       <select
                         className="form-select "
                         id="itemtype"
                         onChange={(e) => setType(e.target.value)}
                       >
                           <option value='option'>Select Option</option>
                         <option value='APP'>APP</option>
                         <option value='OTT'>OTT</option>
                       </select>
                    
                     </Col> */}
                    {/* <Col md={2}>
                       
                       <Label htmlFor="PurchasesDate" className="form-label">
                         LINE (option) <span className="text-danger">*</span>
                       </Label>

                       <select
                         className="form-select "
                         id="itemtype"
                         onChange={(e) => setsetLine(e.target.value)}
                       >
                           <option value='option'>Select Option</option>
                         <option value='all'>ALL</option>
                         <option value='without'>WithOut-Line</option>
                         <option value='with'>With-Line</option>
                       </select>
                    
                     </Col>
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Start Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStart(date)}
                            value={start}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            End Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEnd(date)}
                            value={end}
                          />
                        </div>
                      </Col> */}
                      <Col md={3}>
                      <div className="mb-3">
                        <Label htmlFor="search" className="form-label">
                          Search by Customer Phone or Customer Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="info"
                          type="text"
                          placeholder="Enter phone number or customer name"
                          required
                          onChange={(e) => setsetInfo(e.target.value)}
                        />
                      </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          {isLoading ==true ?
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={filterThroughDate}
                            type="button"
                            //  id="create-btn"
                            disabled
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Fetch Data{" "}
                          </Button>:<Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={filterThroughDate}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Fetch Data{" "}
                          </Button>
                          }
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={clearFilter}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Clear filter{" "}
                          </Button>
                        </div>
                      </Col>
                   
                    </Row>

                    <div id="Purchasestable">
                    <Row className="g-4 mb-3"></Row>

                 

                  
                      <TableContainer
                        columns={columns1}
                        data={filteredItems || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        loading={true}
                        
                        
                        // handleCustomerClick={handleCustomerClicks}
                        // isCustomerFilter={true}
                      />
               
                  </div>

{/* {
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        progressPending={isLoading  }
                        progressComponent={<SpinnerBorder />}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        // selectableRows
                        persistTableHead
                      // sortFunction={customSort}
                      />
                    } */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Open Closed Card Distribution From */}

      {/* Remove Modal */}
    </React.Fragment>
  );
};

export default IptvOrganizationReport;
const SpinnerBorder = () => {
  return <Spinner className="my-2 text-center" />;
};