import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";

const CardDistribution = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [modal_list_serial, setmodal_list_serial] = useState(false);
  const {
    stores,
    sites,
    fetchSites,
    fetchStores,
    upadteStore,
    registerDisturbutionWithSerial,
  } = useContext(LoginContex);
  const [distributionDate, setdistributionDate] = useState("");
  const [distributionType, setdistributionType] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [quantity, setQuantity] = useState("");
  const [cardEndsTo, setCardEnd] = useState("");
  const [cardStartFrom, setCardStart] = useState("");
  const [remarks, setRemark] = useState("");
  const [fromStoreToStoreee, setfromStoreToStore] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [id, setId] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardsList, seList] = useState([]);
  const { registerDisturbutionWithOutSerial } = useContext(LoginContex);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const tog_serial = () => {
    setmodal_list_serial(!modal_list_serial);
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    fetchSites();
    fetchStores();
  }, []);
  //console.log(to);
  document.title = "Card Distribution ";

  const mockData = [
    {
      date: "10-04-2022",
      distribution_type: "From Store To Store",
      from_store: "baqaar",
      to_store: "baqaar one",
      quantity: "205",
      cars_start: "10-09-2022",
      card_end: "10-10-2022",
      remark: "hi",
      type: "Serial",
    },
  ];
  //console.log(fromStoreToStoreee);
  const fromStoreToStore = () => {
    setfromStoreToStore(true);
  };
  //console.log(fromStoreToStoreee);

  const handleSubmit = (e) => {
    //console.log("isEditing");
    e.preventDefault();
    if (!isEditing) {
      const data = {
        remarks,
        cardStartsFrom: cardStartFrom,
        cardEndsTo,
        quantity,
        to,
        from,
        distributionType,
        distributionDate,
        haveSerial: true,
        distributionType: fromStoreToStoreee,
      };
      if (
        !cardStartFrom ||
        !quantity ||
        !to ||
        !from ||
        !cardEndsTo ||
        !fromStoreToStoreee
      ) {
        return;
      }
      //console.log(data);
      registerDisturbutionWithSerial(data);
      setmodal_list(false);
    }
    if (isEditing) {
      const data = {
        remarks,
        cardStartFrom,
        cardEndsTo,
        quantity,
        to,
        from,
        distributionType,
        distributionDate,
      };
      if (
        !cardStartFrom ||
        !cardStartFrom ||
        !quantity ||
        !to ||
        !from ||
        !cardEndsTo ||
        !distributionType
      ) {
        return;
      }
      upadteStore(data);
    }
  };

  const handlerWithoutSerial = (e) => {
    //console.log("first");
    e.preventDefault();
    if (!isEditing) {
      const data = {
        remarks,
        to,
        from,
        distributionDate,
        haveSerial: false,
        distributionType: fromStoreToStoreee,
        cardLists: cardsList,
        quantity: 5,
      };
      if (!distributionDate || !cardsList || !to || !from) {
        return;
      }
      //console.log(data);
      registerDisturbutionWithOutSerial(data);
      setmodal_list(false);
    }
    if (isEditing) {
      const data = {
        remarks,
        cardStartFrom,
        cardEndsTo,
        quantity,
        to,
        from,
        distributionType,
        distributionDate,
      };
      if (
        !cardStartFrom ||
        !cardStartFrom ||
        !quantity ||
        !to ||
        !from ||
        !cardEndsTo ||
        !distributionType
      ) {
        return;
      }
      upadteStore(data);
    }
  };

  const addList = () => {
    seList([...cardsList, cardNumber]);
    setCardNumber("");
  };

  //console.log(cardsList);

  const removeCardNumber = (name) => {
    //console.log(cardsList);

    const filteredList = cardsList.filter((list, index) => list !== name);
    seList(filteredList);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Card Distribution " pageTitle="Inventory" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <h4 className="card-title mb-0">Card Distribution</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={() => tog_list()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Card Distribution With Serial
                          </Button>
                        </div>
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={() => tog_serial()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Card Distribution Without Serial
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="g-4 mb-3"></Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="cardTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "5px" }}></th>
                            <th className="sort" data-sort="date">
                              Distribution Date
                            </th>
                            <th className="sort" data-sort="distribution_type">
                              Distribution Type
                            </th>
                            <th className="sort" data-sort="from_store">
                              From
                            </th>
                            <th className="sort" data-sort="to_store">
                              To
                            </th>
                            <th className="sort" data-sort="quantity">
                              Quantity
                            </th>

                            <th className="sort" data-sort="remark">
                              Remark
                            </th>
                            <th className="sort" data-sort="remark">
                              Operation Type
                            </th>
                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {mockData.map((data, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row"></th>
                                <td className="id" style={{ display: "none" }}>
                                  <Link
                                    to="#"
                                    className="fw-medium link-primary"
                                  >
                                    #VZ2104
                                  </Link>
                                </td>
                                <td className="date">{data.date}</td>
                                <td className="distribution_type">
                                  {data.distribution_type}
                                </td>
                                <td className="from_store">
                                  {" "}
                                  {data.from_store}{" "}
                                </td>
                                <td className="to_store">{data.to_store}</td>
                                <td className="quantity"> {data.quantity} </td>

                                <td className="remark"> {data.remark} </td>
                                <td className="type"> {data.type} </td>
                                <td>
                                  <div className="d-flex gap-2">
                                    <div className="edit">
                                      <button
                                        className="btn btn-sm btn-success edit-item-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showModal"
                                        //  onClick={() => editPop(data)}
                                      >
                                        Edit
                                      </button>
                                    </div>
                                    <div className="remove">
                                      <button
                                        className="btn btn-sm btn-danger remove-item-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteRecordModal"
                                        //  onClick={() => deletPop(data)}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Card Distribution From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">
            {" "}
            Record Card Distributions With Serial no.
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">
                    Distribution Date <span className="text-danger">*</span>
                  </label>
                  <Flatpickr
                    className="form-control"
                    required
                    placeholder="DD/MM/YYYY"
                    options={{
                      dateFormat: "d/m/Y",
                      defaultDate: "today",
                    }}
                    onChange={(e) => setdistributionDate(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>
                    Distribution Type <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select "
                    id="distribution_type"
                    onChange={(e) => setfromStoreToStore(e.target.value)}
                    required
                  >
                    <option value="">&hellip; Choose Type &hellip;</option>
                    <option value="From Store To Store">
                      From Store To Store
                    </option>
                    <option value="From Store To Site">
                      From Store To Site
                    </option>
                    <option value="From Site To Site">From Site To Site</option>
                    <option value="From Site To Store">
                      From Site To Store
                    </option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label>
                    From <span className="text-danger">*</span>{" "}
                  </label>
                  <select
                    className="form-select "
                    id="from_store"
                    onChange={(e) => setFrom(e.target.value)}
                    required
                  >
                    <option value="">&hellip; From &hellip;</option>
                    {fromStoreToStoreee == "From Store To Store" &&
                      stores.map((store, index) => (
                        <option key={index} value={store._id}>
                          {" "}
                          {store.storeName}{" "}
                        </option>
                      ))}
                    {fromStoreToStoreee == "From Store To Site" &&
                      stores.map((store, index) => (
                        <option key={index} value={store._id}>
                          {" "}
                          {store.storeName}{" "}
                        </option>
                      ))}
                    {fromStoreToStoreee == "From Site To Site" &&
                      sites.map((site, index) => (
                        <option key={index} value={site._id}>
                          {" "}
                          {site.siteName}{" "}
                        </option>
                      ))}
                    {fromStoreToStoreee == "From Site To Store" &&
                      sites.map((site, index) => (
                        <option key={index} value={site._id}>
                          {" "}
                          {site.siteName}{" "}
                        </option>
                      ))}
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>
                    To <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select "
                    id="to_store"
                    onChange={(e) => setTo(e.target.value)}
                    required
                  >
                    <option value="">&hellip; To &hellip;</option>
                    {fromStoreToStoreee == "From Store To Store" &&
                      stores.map((store, index) => (
                        <option key={index} value={store._id}>
                          {" "}
                          {store.storeName}{" "}
                        </option>
                      ))}
                    {fromStoreToStoreee == "From Store To Site" &&
                      sites.map((site, index) => (
                        <option key={index} value={site._id}>
                          {" "}
                          {site.siteName}{" "}
                        </option>
                      ))}
                    {fromStoreToStoreee == "From Site To Site" &&
                      sites.map((site, index) => (
                        <option key={index} value={site._id}>
                          {" "}
                          {site.siteName}{" "}
                        </option>
                      ))}
                    {fromStoreToStoreee == "From Site To Store" &&
                      stores.map((store, index) => (
                        <option key={index} value={store._id}>
                          {" "}
                          {store.storeName}{" "}
                        </option>
                      ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">
                    Quantity<span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="quantity-field"
                    className="form-control"
                    placeholder="Enter  Quantity"
                    required
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">
                    Card Start<span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id=" card_end-field"
                    className="form-control"
                    placeholder="Enter Card Start"
                    required
                    onChange={(e) => setCardStart(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">
                    Card End <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id=" card_end-field"
                    className="form-control"
                    placeholder="Enter Card End"
                    required
                    onChange={(e) => setCardEnd(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label"> Remark</label>
                  <input
                    type="text"
                    id=" remark-field"
                    className="form-control"
                    placeholder="Enter Remark"
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isEditing ? "update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      {/* Record Card Distributions Without Serial no.*/}
      <Modal
        isOpen={modal_list_serial}
        toggle={() => {
          tog_serial();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">
            {" "}
            Record Card Distributions Without Serial no.{" "}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              tog_serial(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handlerWithoutSerial}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">
                    Distribution Date <span className="text-danger">*</span>
                  </label>
                  <Flatpickr
                    className="form-control"
                    required
                    placeholder="DD/MM/YYYY"
                    options={{
                      dateFormat: "d/m/Y",
                      defaultDate: "today",
                    }}
                    onChange={(e) => setdistributionDate(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label>
                    Distribution Type <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select "
                    id="distribution_type"
                    onChange={(e) => setfromStoreToStore(e.target.value)}
                    required
                  >
                    <option value="">&hellip; Choose Type &hellip;</option>
                    <option selected>Choose...</option>
                    <option value="From Store To Store">
                      From Store To Store
                    </option>
                    <option value="From Store To Site">
                      From Store To Site
                    </option>
                    <option value="From Site To Site">From Site To Site</option>
                    <option value="From Site To Store">
                      From Site To Store
                    </option>
                  </select>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label>
                    From <span className="text-danger">*</span>{" "}
                  </label>
                  <select
                    className="form-select "
                    id="from_store"
                    onChange={(e) => setFrom(e.target.value)}
                    required
                  >
                    <option value="">&hellip; From &hellip;</option>
                    {fromStoreToStoreee == "From Store To Store" &&
                      stores.map((store, index) => (
                        <option key={index} value={store._id}>
                          {" "}
                          {store.storeName}{" "}
                        </option>
                      ))}
                    {fromStoreToStoreee == "From Store To Site" &&
                      stores.map((store, index) => (
                        <option key={index} value={store._id}>
                          {" "}
                          {store.storeName}{" "}
                        </option>
                      ))}
                    {fromStoreToStoreee == "From Site To Site" &&
                      sites.map((site, index) => (
                        <option key={index} value={site._id}>
                          {" "}
                          {site.siteName}{" "}
                        </option>
                      ))}
                    {fromStoreToStoreee == "From Site To Store" &&
                      sites.map((site, index) => (
                        <option key={index} value={site._id}>
                          {" "}
                          {site.siteName}{" "}
                        </option>
                      ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="mb-3">
                  <label>
                    To <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select "
                    id="to_store"
                    onChange={(e) => setTo(e.target.value)}
                    required
                  >
                    <option value="">&hellip; To &hellip;</option>
                    {fromStoreToStoreee == "From Store To Store" &&
                      stores.map((store, index) => (
                        <option key={index} value={store._id}>
                          {" "}
                          {store.storeName}{" "}
                        </option>
                      ))}
                    {fromStoreToStoreee == "From Store To Site" &&
                      sites.map((site, index) => (
                        <option key={index} value={site._id}>
                          {" "}
                          {site.siteName}{" "}
                        </option>
                      ))}
                    {fromStoreToStoreee == "From Site To Site" &&
                      sites.map((site, index) => (
                        <option key={index} value={site._id}>
                          {" "}
                          {site.siteName}{" "}
                        </option>
                      ))}
                    {fromStoreToStoreee == "From Site To Store" &&
                      stores.map((store, index) => (
                        <option key={index} value={store._id}>
                          {" "}
                          {store.storeName}{" "}
                        </option>
                      ))}
                  </select>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label">
                    Card Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="quantity-field"
                    className="form-control"
                    placeholder="Enter  Quantity"
                    required
                    onChange={(e) => setCardNumber(e.target.value)}
                    value={cardNumber}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label className="form-label"> Remark</label>
                  <input
                    type="text"
                    id=" remark-field"
                    className="form-control"
                    placeholder="Enter Remark"
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="d-flex justify-content-sm mb-3">
                  <button
                    type="button"
                    size="sm"
                    className="btn btn-success"
                    id="add-btn"
                    onClick={addList}
                  >
                    <i className="ri-add-line align-bottom me-1"></i>
                    Add List
                  </button>
                </div>
              </Col>
            </Row>
            <div className="table-responsive">
              <Table className="table-sm table-nowrap mb-0">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Card Number</th>
                    <th></th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cardsList.map((list, index) => {
                    return (
                      <tr key={index}>
                        <td className="fw-medium">{index + 1} </td>
                        <td>{list}</td>
                        <td></td>
                        <td>
                          <td>
                            <div className="remove">
                              <button
                                className="btn btn-sm btn-danger remove-item-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteRecordModal"
                                //  onClick={() => deletPop(data)}
                                onClick={() => removeCardNumber(list)}
                              >
                                Remove
                              </button>
                            </div>
                          </td>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => tog_serial(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isEditing ? "update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CardDistribution;
