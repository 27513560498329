import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionItem,
  Breadcrumb,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Grid } from "gridjs-react";
import moment from "moment";
import classnames from "classnames";

const Report = () => {
  //LOCAL states
  const {
    inventoryReport,
    inventoryReports,
    purchReport,
    purchaseReport,
    salesReport,
    getsalesReport,
    fetchSingleRoleReport,
    repRoles,
    User,
  } = useContext(LoginContex);
  let userId = User?.data?.user?._id || User?.user?._id;

  useEffect(() => {
    inventoryReport();
    purchaseReport();
    getsalesReport();
    fetchSingleRoleReport(userId);
  }, []);

  //console.log(inventoryReports);
  //console.log(repRoles);

  document.title = "Report Center";

  const [col1, setcol1] = useState(true);

  const t_col1 = () => {
    setcol1(!col1);
  };

  const sortedInventories = [];
  for (let i = 0; i < inventoryReports?.length; i++) {
    let singleArr = [
      inventoryReports[i]?.itemId?.itemName,
      inventoryReports[i]?.availableQuantity,
      inventoryReports[i]?.storeId?.storeName,

      // inventoryReports[i]._id,
    ];

    sortedInventories.push(singleArr);
  }
  const sortedPurchases = [];
  for (let i = 0; i < purchReport?.length; i++) {
    //console.log(purchReport[i].date);
    let date = purchReport[i].date.split("T");
    const formattedDate = moment(date[0]).utc().format("DD-MM-YYYY");
    let singleArr = [
      purchReport[i]?.item?.itemId?.itemName,
      purchReport[i]?.item?.quantity,
      purchReport[i]?.item?.reciversUploaded,
      purchReport[i]?.supplier?.supplierName,
      formattedDate,

      // inventoryReports[i]._id,
    ];

    sortedPurchases.push(singleArr);
  }
  const sortedSales = [];
  for (let i = 0; i < salesReport?.length; i++) {
    //console.log(salesReport[i].date);
    let date = salesReport[i].date.split("T");
    const formattedDate = moment(date[0]).utc().format("DD-MM-YYYY");
    let singleArr = [
      salesReport[i].item?.itemId?.itemName || "not available",
      salesReport[i].item.quantity,
      salesReport[i].item.unitPrice,
      Number(salesReport[i].item.quantity) *
        Number(salesReport[i].item.unitPrice),
      formattedDate,

      // inventoryReports[i]._id,
    ];

    sortedSales.push(singleArr);
  }
  //console.log(salesReport);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Report" pageTitle="Report Center" />
          <Breadcrumb title="User Role" pageTitle="Settings" />
          <Row className="g-4 mb-3">
            <Accordion>
              <AccordionItem>
                <h1 className="accordion-header" id="headingOne">
                  <button
                    className={classnames("accordion-button", {
                      collapsed: !col1,
                    })}
                    type="button"
                    onClick={t_col1}
                    style={{ cursor: "pointer" }}
                  >
                    Reports
                  </button>
                </h1>
                <Collapse
                  isOpen={col1}
                  className="accordion-collapse"
                  id="collapseOne"
                >
                  {/* <Row className="mb-3 d-inline ">
                    <Col md={4}>
                      <div className="input-group ">
                        <input
                          type="text"
                          className="form-control"
                          aria-label="Recipient's username with two button addons"
                          placeholder="Search Report"
                        />
                        <button className="btn btn-primary" type="button">
                          Search
                        </button>
                      </div>
                    </Col>
                  </Row> */}
                </Collapse>
              </AccordionItem>
            </Accordion>
          </Row>
          <Row>
            {repRoles?.map((rep, i) => {
              return (
                <Col lg={6} key={i}>
                  <Card>
                    <CardHeader>
                      <Row className="g-4 mb-3">
                        <Col className="col-sm-auto">
                          <div>
                            <div>
                              <ToastContainer />
                              <h4 className="card-title mb-0">
                                {" "}
                                {rep?.title}{" "}
                              </h4>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardHeader>

                    <CardBody>
                      <div id="customerList">
                        <Row className="g-4 mb-3"></Row>

                        <div className="table-responsive mt-4 mt-xl-0">
                          <SimpleBar
                            autoHide={false}
                            className="simplebar-track-secondary"
                            style={{ maxHeight: "250px" }}
                          >
                            <Table className="table-hover table-striped align-middle table-nowrap mb-0 text-info">
                              <thead>
                                <tr>
                                  <th scope="col">No</th>
                                  <th scope="col">Report Name</th>
                                </tr>
                              </thead>
                              <tbody>
                                {rep?.subItems.map((sub , i) => {
                                  return (
                                    <tr key={i}>
                                      <td className="fw-medium text-info"> {i +1}</td>
                                      {/* <td className="text-info"></td> */}
                                      <td>
                                        <Link
                                          to={sub?.link}
                                          className="text-info"
                                        >
                                         {sub?.subReportName}
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </SimpleBar>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
            

           

           
           
          

           
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* customer Registration From */}

      {/* Remove Modal */}
    </React.Fragment>
  );
};

export default Report;
