import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
//import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SalesForm from "../SalesForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Grid, _ } from "gridjs-react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { Autocomplete, TextField } from "@mui/material";

const currDate = new Date().toLocaleDateString;
var dateVariable = new Date();

const CreateIPTV = () => {
  const [storeName, setstoreName] = useState("");
  const [storeLocation, srtstoreLocation] = useState("");
  const [storeDescription, setstoreDescription] = useState("");
  const [isPrimaryStore, setisPrimaryStore] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [yes, setYes] = useState(true);
  const [id, setId] = useState("");
  const [districtId, setDistrictId] = useState("");
  // const [no, setNo] = useState(false);

  // local states

  const [purchaseDate, setpurchaseDate] = useState("");
  const [customerTell, setCustomerTell] = useState("");

  const [supplierId, setSupplierId] = useState("");
  const [storeId, setstoreId] = useState("");
  // const [siteId, setsiteId] = useState("");
  const [referenceNo, setreferenceNo] = useState("");
  const [description, setdescription] = useState("");
  const [purchaseAmount, setpurchaseAmount] = useState("");
  const [additionalCost, setadditionalCost] = useState("");
  const [discount, setdiscount] = useState();
  const [totalAmount, settotalAmount] = useState();
  const [EmployeeId, setEmployeeId] = useState();
  const [siteId, setSiteId] = useState();
  const [groupid, setgroupid] = useState();
  const [zoneid, setzoneid] = useState();
  const [Message, setMessage] = useState();
  const [issueDate, setissueDate] = useState();
  const [customerId, setcustomerId] = useState();
  const [customerAddress, setcustomerAddress] = useState();
  const [title, setTitle] = useState();
  const [custPhone, setCustPhone] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");

  const [homeNumber, setHomeNumber] = useState("");
  const [custDistrict, setCustDistrict] = useState("");
  const [custDistrictId, setCustDistrictId] = useState("");
  // const [customerAddress, setCustomerAddress] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [object_id, setObject] = useState();
  const [Date, setDate] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [customerInfo, setCustomerInfo] = useState();

  const {
    registerStore,
    stores,
    deleteStores,
    upadteStore,
    fetchItems,
    fetchStores,
    fetchSites,
    sites,
    items,
    getZonesById,
    getGroups,
    getEmployee,
    getZonesbyGroup,
    fetchEmployees,
    fetchGroups,
    fetchGroupsToAssign,
    fetchZonesToAssign,
    fetchEmployeesToAssign,
    getByIdGroupCable,
    getByIdGroupsCable,
    getByIdZonesCables,
    fetchByIdZones,
    getZoneById,
    Cables,
    fetchCables,
    AssignCablesRegister,
    fetchByIdZonesCable,
    fetchByIEmployee,
    GetByIdEmployee,
    registertickets,
    customers,
    fetchCustomers,
    fetchAllIPTVS,
    IPTVS,
    registerIPTV,
    removeIPTV,
    UpdateIPTV,
    GetIPTVHormuud,
    IPTVHormuud,
    getSingleCustomer,
    singleCustomer,
    registerCustomer,
    fetchDistricts,
    districts,
    User,
    fetchCustomersWithIptvs,
    customerWithIptvs,
    updatedCustomers,
    fetchAutoIPTVCustomers,
    iptvCustomer,
    isLoading,
  } = useContext(LoginContex);
  const [modal_list, setmodal_list] = useState(false);
  const [modal_listCustomer, setmodal_listCustomer] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
    clear();
  };
  let userId = User?.data?.user?._id || User?.user?._id;
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = IPTVS.filter(
    (item) =>
      item.customerId?.customerName &&
      item.customerId?.customerName
        .toLowerCase()
        .includes(filterText.toLowerCase())
  );
  //console.log(filteredItems);
  //console.log(filterText);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const tog_listCustomer = () => {
    setmodal_listCustomer(!modal_listCustomer);
  };

  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleHomeNumberChange = (e) => {
    const limit = 6;

    // Here we are checking if the length is equal to 9
    if (e.target.value.length === 7) {
      showToastMessage("Home number should not exceed 6 digits!");
      setHomeNumber(e.target.value.slice(0, limit));
    }
    setHomeNumber(e.target.value.slice(0, limit));
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const { fetchPurchase, purchases, removePurchase } = useContext(LoginContex);
  //console.log(yes);
  useEffect(() => {
    fetchStores();
    fetchItems();
    fetchSites();
    fetchPurchase();
    fetchEmployees();
    fetchGroups();
    fetchGroupsToAssign();
    fetchZonesToAssign();
    fetchEmployeesToAssign();
    fetchCables();
    // fetchByIEmployee();
    fetchCustomers();
    fetchCustomersWithIptvs();
    fetchAllIPTVS();
    fetchDistricts();
  }, []);

  let getCustPhone = (id, mobile, Address) => {
    setcustomerId(id);
    setCustPhone(mobile[mobile.selectedIndex].getAttribute("data-Moble"));
    setcustomerAddress(
      Address[Address.selectedIndex].getAttribute("data-Address")
    );
    //console.log(customerAddress);
  };

  let getGroup = (id) => {
    // //console.log(id)
    setSiteId(id);

    getByIdGroupCable(id);
  };

  let getZones = (id) => {
    setgroupid(id);
    fetchByIdZonesCable(id);
    // //console.log(getZoneById.result)
    //console.log(id);
  };

  const clear = () => {
    setcustomerAddress("");
    setCustPhone("");
    setcustomerId("");
    setMessage("");
    // setMessage("");
  };
  document.title = "IP-TV";
  let districtArr = [];
  for (let i = 0; i < districts.length; i++) {
    // //console.log(items[i]._id);

    var dropdownList = {
      value: districts[i]._id,
      label: districts[i].districtName,
    };

    districtArr.push(dropdownList);
  }
  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> Name</h6>,
      selector: (row) => row.customerId?.customerName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Date</h6>,
      selector: (row) => moment(row.registerDate).utc().format("DD-MM-YYYY"),
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Phone Number </h6>,
      selector: (row) => row.customerId?.customerPhone,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Address</h6>,
      selector: (row) => row.customerId?.customerAddress,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Is Installed</h6>,
      selector: (row) => (row.isAssignedToEmployee ? "Yes" : "No"),
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Actions</h6>,
      cell: (row) => (
        <>
          <button
            className="btn btn-success"
            onClick={() => testing(row)}
            disabled={row?.status == null}
          >
            <Link
              className="text-white"
              to={`/NewIptv/${row?.customerId?._id || "NULL"}/${
                row?.customerId?.customerName || "NULL"
              }/${row?.customerId?.customerPhone || "NULL"}/${
                row.customerId?.customerAddress || "NULL"
              }/${row.customerId?.customerTell || "NULL"}/${
                row.customerId?.districtId
              }/true`}
            >
              {" "}
              {row?.status == null ? "Pending" : "Install"}
            </Link>
          </button>
          {"     "}
          {/* <button className="btn btn-danger" onClick={() => deleteStore(row)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </button> */}
        </>
      ),
    },
    {
      /*end*/
    },
  ];
  const testing = (row) => {
    //console.log(row);
  };
  var today = new window.Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = mm + "-" + dd + "-" + yyyy;

  //console.log(IPTVS);

  const updateForm = (data) => {
    setmodal_list(true);
    setcustomerId(data.customerId._id);
    setCustPhone(data.customerId.customerPhone);
    setMessage(data.description);
    setcustomerAddress(data.customerId.customerAddress);
    setYes(yes);
    setEditing(true);
    setId(data._id);
    setObject(data._id);
    //console.log(data);
  };

  const handlerSubmitCust = (e) => {
    e.preventDefault();
    const data = {
      customerName,
      customerPhone,
      customerTell,
      customerAddress,
      districtId: districtId.value,
    };
    registerCustomer(data);
    setCustomerName("");
    setCustomerPhone("");
    setcustomerAddress("");
    setDistrictId("");
    setmodal_listCustomer(false);
  };

  // const handleUser = (data) => {
  //     //console.log(data);
  //     fetch('https://ccxapi.hormuud.com/api/Csr/IPTVTIcketsSaveUpdate', {
  //         method: "POST" // default, so we can ignore
  //     })
  //         .then(res => res.json())
  //         .then(data => //console.log(data))
  // };

  // // Example POST method implementation:
  // async function postData(url = 'https://ccxapi.hormuud.com/api/Csr/IPTVTIcketsSaveUpdate', data = {
  //     AlarmID: "2",
  //     Tel: "",
  //     Callsub: custPhone,
  //     Center: "STH",
  //     District: "Howlwadaag",
  //     Address: "Baar Ubax",
  //     Description: Message,
  //     UserID: "TST",
  //     apikey: "bE0TKhvHvT23w7dXlrM8BvGPxh6HkjF#$"
  // }) {
  //     const response = await fetch(url, {
  //         method: 'POST',
  //         mode: 'no-cors',
  //         cache: 'no-cache',
  //         credentials: 'same-origin',
  //         headers: {
  //             'Content-Type': 'application/json'

  //         },
  //         redirect: 'follow',
  //         referrerPolicy: 'no-referrer',
  //         body: JSON.stringify(data)
  //     });
  //     return response.json();
  // }

  // postData('https://ccxapi.hormuud.com/api/Csr/IPTVTIcketsSaveUpdate', { answer: 42 })
  //     .then((data) => {
  //         //console.log(data);
  //     });

  //  useEffect(() => {
  //     // POST request using fetch inside useEffect React hook
  //     const requestOptions = {
  //         method: 'POST',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify({ title: 'React Hooks POST Request Example' })
  //     };
  //     fetch('https://ccxapi.hormuud.com/api/Csr/IPTVTIcketsSaveUpdate', requestOptions)
  //         .then(response => response.json())
  //         .then(data => setPostId(data.id));

  // // empty dependency array means this effect will only run once (like componentDidMount in classes)
  // }, []);

  const handlersubmit = (e) => {
    e.preventDefault();

    //console.log(customerId);
    if (!isEditing) {
      const data = {
        registerDate: today,
        customerId: selectedOption?._id,
        customerPhone: selectedOption?.customerPhone,
        customerTell: selectedOption?.customerTell,
        district: selectedOption?.districtName || "hodann",
        districtId: selectedOption?.districtId,
        customerAddress:selectedOption?.customerAddress,
        description: Message,
        userId,
      };

      //console.log("registerIPTV Data ===>", data);
      registerIPTV(data);
      setmodal_list(false);
      clear();

      // For Hormuud API Purpose

      const myapi = {
        AlarmID: "2",
        Tel: "0618292386",
        Callsub: custPhone,
        Center: "STH",
        District: "Howlwadaag",
        Address: "Baar Ubax",
        Description: Message,
        UserID: "TST",
        apikey: "bE0TKhvHvT23w7dXlrM8BvGPxh6HkjF#$",
      };

      // handleUser(myapi);
      //   GetIPTVHormuud(myapi);
      // postData();
      //console.log(myapi);
    } else {
      const data = {
        registerDate: today,
        customerId,
        customerPhone: custPhone,
        customerAddress,
        description: Message,
        id: object_id,
      };

      //console.log(data);
      UpdateIPTV(data);
      setmodal_list(false);
      clear();
    }
  };

  const deletePuchase = (data) => {
    setmodal_delete(true);
    setId(data._id);
  };

  const delIPTV = () => {
    removeIPTV(id);
    setmodal_delete(false);
  };

  //updateees
  // //console.log(isEditing);

  const getGroupForSite = (e) => {
    const data = {
      siteId: e.target.value,
    };
    fetchGroupsToAssign(data);
    //console.log(data);
  };

  let getRecive = (id) => {
    // setReceiverType(id);
    // getByIdRecive(id);
  };

  const getZoneforGroup = (e) => {
    const data = {
      groupId: e.target.value,
    };
    fetchZonesToAssign(data);
    //console.log(data);
  };

  const getEmployeeFromZone = (e) => {
    const data = {
      zoneId: e.target.value,
    };
    fetchEmployeesToAssign(data);
    //console.log(data);
  };

  let getEmployees = (id) => {
    setzoneid(id);
    let Data = [
      {
        siteId: siteId,
        groupid,
        zoneId: id,
      },
    ];

    fetchByIEmployee(siteId, groupid, id);
    // //console.log(GetByIdEmployee)
  };

  const dateFromDB = "2005-12-03T18:30:00.000Z";
  const formattedDate = moment(dateFromDB).utc().format("DD/MM/YYYY");

  // //console.log("Date From DB:", dateFromDB);
  // //=> 2005-12-03T18:30:00.000Z

  // //console.log("Formatted Date:", formattedDate);

  // const sortedIPVS = [];
  // for (let i = 0; i < IPTVS?.length; i++) {
  //   let date = IPTVS[i].registerDate.split("T");
  //   const formattedDate = moment(date[0]).format("DD-MM-YYYY");
  //   let singleArr = [
  //     formattedDate,
  //     IPTVS[i].customerId?.customerName,
  //     IPTVS[i].customerId?.customerPhone,
  //     IPTVS[i].customerId?.customerAddress,
  //     IPTVS[i].isAssignedToEmployee ? "Yes" : "No",
  //   ];

  //   sortedIPVS.push(singleArr);
  // }

  const CustomersArr = [];

  for (let i = 0; i < customerWithIptvs.length; i++) {
    // //console.log(customerWithIptvs[i]._id);

    var dropdownList = {
      value: customerWithIptvs[i]._id,
      label: `${customerWithIptvs[i].customerName}`,
      customername: `${customerWithIptvs[i].customerName}`,
      customerPhone: `${customerWithIptvs[i].customerPhone}`,
      customerAddress: `${customerWithIptvs[i].customerAddress}`,
      customerTell: `${customerWithIptvs[i].customerTell}`,
      district: `${customerWithIptvs[i].districtName}`,
      districtId: `${customerWithIptvs[i].districtId}`,
    };

    CustomersArr.push(dropdownList);
  }

  //console.log("customer: ", typeof Number(customerInfo?.customerPhone));

  let getCustAddress = (id) => {
    setCustomerInfo(id);

    //console.log("ID:------ ", id);
    setcustomerId(id);
    getSingleCustomer(id);
    setcustomerAddress(id.customerAddress);
    setCustPhone(Number(id.customerPhone));
    setHomeNumber(Number(id.customerTell));
    setCustDistrict(id.district);
    setCustDistrictId(id.districtId);
    //districtId
    //console.log("Address", singleCustomer.customerAddress);
  };
  const getOptionSelected = (option, value) => {
    //console.log(option);
    //console.log(value);
    if (option && value) {
      return option.id === value.id;
    }
    return false;
  };
  //console.log(getOptionSelected());
  //console.log(selectedOption);
  const onInputChange2 = (event, value, reason) => {
    //console.log(value);
    //console.log(event);
    fetchAutoIPTVCustomers(value);

    const matchingOption = makeItArray2.find(
      (option) =>
        option.customers.toLowerCase() ==
        value.substring(0, value.indexOf("-")).toLowerCase()
    );

    setSelectedOption(matchingOption);
  };
//console.log(selectedOption)
  const makeItArray2 = [];
  //console.log(iptvCustomer);
  for (let i = 0; i < iptvCustomer?.length; i++) {
    // //console.log("from: " + formattedDate);
    let singleArr = {
      customers: iptvCustomer[i]?.customerName,
      customerPhone: iptvCustomer[i]?.customerPhone,
      _id: iptvCustomer[i]?._id,
      customerAddress: iptvCustomer[i]?.customerAddress,
      customerTell: iptvCustomer[i]?.customerTell,
      districtName: iptvCustomer[i]?.districtName,
      districtId: iptvCustomer[i]?.districtId,
      customerAddress: iptvCustomer[i]?.customerAddress,
    };

    makeItArray2.push(singleArr);
  }
  //console.log(makeItArray2);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="IP-TV" pageTitle="Billing" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">IP-TV</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={() => tog_list()}
                            id="create-btn"
                          >
                            Survey
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="StoreList">
                    <Row className="g-4 mb-3"></Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      {IPTVS.length > 0 && (
                        <DataTable
                          columns={columns}
                          data={filteredItems}
                          pagination
                          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                          subHeader
                          subHeaderComponent={subHeaderComponentMemo}
                          // selectableRows
                          persistTableHead
                          // sortFunction={customSort}
                        />
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        {/* <ModalHeader className="bg-light p-3">
                    Assign To Employee
                    <Button
                        type="button"
                        onClick={() => {
                            setmodal_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </ModalHeader> */}

        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">Create IP-TV</h5>

          <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>

        <form onSubmit={handlersubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row style={{ marginTop: "-2%" }}>
              <Col md={6}>
                <div className="mb-3" style={{ display: "none" }}>
                  <label>
                    Register date <span className="text-danger">*</span>
                  </label>
                  <Flatpickr
                    className="form-control"
                    required
                    placeholder="DD/MM/YYYY"
                    options={{
                      dateFormat: "d/m/Y",
                      defaultDate: "today",
                    }}
                    onChange={(e) => setDate(e.target.value)}
                    // value={Date}
                  />
                </div>
              </Col>
              <Col md={12} className="w-756">
                <div className="mb-3">
                  {/* <Label className="form-label">
                    Customer <span className="text-danger">*</span>
                  </Label>
                  <div className="row">
                    <div className="col-sm-11">
                      <Select
                        styles={{ width: "170px", height: "150px" }}
                        // className="bg-white"
                        options={CustomersArr}
                        onChange={(choice) =>
                          // getCustPhone(e.target.value, e.target)
                          getCustAddress(choice)
                        }
                        value={customerInfo}
                        required
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                          },
                        })}
                      /> */}

                  <Label className="form-label">
                    Customer <span className="text-danger">*</span>
                  </Label>
                  <Autocomplete
                    disabled={isLoading}
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    options={makeItArray2}
                    getOptionSelected={getOptionSelected}
                    getOptionLabel={(option) => {
                      return `${option.customers?.toString()}-${option.customerPhone?.toString()}`;
                    }}
                    value={selectedOption}
                    onInputChange={onInputChange2}
                    renderInput={(params) => (
                      <TextField {...params} label="Search Customer..." />
                    )}
                  />

                  <div className="col-sm-1">
                    <Button
                      size="md"
                      color="success"
                      onClick={() => tog_listCustomer()}
                      id="create-btn"
                      className="mt-4d"
                      style={{ marginRight: "40%" }}
                    >
                      <i className="ri-add-line align-bottom me-1"></i>{" "}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: "2%" }}>
              <Col md={4}>
                <label className="form-label">Phone Number</label>{" "}
                <span className="text-danger">*</span>
                <input
                  type="number"
                  id="referenceno-field"
                  className="form-control"
                  placeholder="Explicit Phone Number"
                  required
                  onChange={(e) => setCustPhone(e.target.value)}
                  value={selectedOption?.customerPhone}
                  // onChange={(e) => setReferenceNo(e.target.value)}
                  disabled
                />
              </Col>

              <Col md={4}>
                <div className="mb-3">
                  <label htmlFor="homeNumber-field" className="form-label">
                    Home Number<span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="homeNumber-field"
                    className="form-control"
                    placeholder="Enter home number"
                    required
                    onChange={(e) => setHomeNumber(e.target.value)}
                    value={selectedOption?.customerTell}
                    disabled
                  />
                </div>

                <input type="hidden" id="district-field" value={custDistrict} />
                <input
                  type="hidden"
                  id="districtId-field"
                  value={custDistrictId}
                  disabled
                />
              </Col>

              <Col md={4}>
                <div className="mb-3">
                  <label>Address</label>

                  <input
                    type="text"
                    id="referenceno-field"
                    className="form-control"
                    placeholder="Enter Address"
                    required
                    onChange={(e) => setcustomerAddress(e.target.value)}
                    value={selectedOption?.customerAddress}
                    disabled
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <label>Description</label>
                <textarea
                  className="form-control"
                  id="Description-field"
                  rows="3"
                  required
                  placeholder="Enter Description"
                  onChange={(e) => setMessage(e.target.value)}
                  value={Message}
                ></textarea>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <ToastContainer />
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              {/* <button type="submit" className="btn btn-success" id="add-btn">
                                Assign
                            </button> */}
              <button
                type="submit"
                className="btn btn-success"
                id="add-btn"
                onClick={handlersubmit}
              >
                {isEditing ? "Update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>

        {/* =================================== */}
      </Modal>

      {/* Add Modal */}
      {/* Purchase Customer From */}

      <Modal
        isOpen={modal_listCustomer}
        toggle={() => {
          tog_listCustomer();
        }}
        centered
        size="lg"
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Add New Customer</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_listCustomer(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={handlerSubmitCust}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>

            <div className="mb-3">
              <label htmlFor="customer_name-field" className="form-label">
                Customer Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="customer_name-field"
                className="form-control"
                placeholder="Enter Customer Name"
                required
                onChange={(e) => setCustomerName(e.target.value)}
                value={customerName}
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    Phone Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="phone-field"
                    className="form-control"
                    placeholder="Enter phone Number"
                    required
                    onChange={(e) => setCustomerPhone(e.target.value)}
                    value={customerPhone}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="homeNumber-field" className="form-label">
                    Home Number<span className="text-small"> Line Line</span>
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="homeNumber-field"
                    className="form-control"
                    placeholder="Enter home number"
                    required
                    onChange={(e) => setCustomerTell(e.target.value)}
                    value={customerTell}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="address-field" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    id="address-field"
                    className="form-control"
                    placeholder="Enter Address"
                    onChange={(e) => setcustomerAddress(e.target.value)}
                    value={customerAddress}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <label>
                    District <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={districtArr}
                    onChange={(choice) => setDistrictId(choice)}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        // text: "orangered",
                        // primary25: "hotpink",
                        // primary: "black",
                      },
                    })}
                  />
                </div>
              </Col>
            </Row>

            {/* <div className="mb-3">
              <label htmlFor="total-field" className="form-label">
                Total Card
              </label>
              <input
                type="Number"
                id="total-field"
                className="form-control "
                placeholder="Enter Total Card"
                required
                onChange={(e) => setTotalCards(e.target.value)}
                value={totalCards}
              />
            </div> */}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_listCustomer(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => delIPTV()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CreateIPTV;
