import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";
import RegisterItem from "../pages/CreateItem";
import CustomerRegister from "../pages/customers";
import Sites from "../pages/SiteList";
import Stores from "../pages/Stores";
import TransferType from "../pages/TransferType";
import Cards from "../pages/Cards";
import BillGeneration from "../pages/BillGeneration";
import Purchase from "../pages/Purchases";

import Supplier from "../pages/supplier";
import ItemType from "../pages/ItemType";
import PurchaseDetails from "../pages/PurchaseDetails";
import PurchaseReturnDetails from "../pages/PurchaseReturnDetails";
import Inventories from "../pages/Inventories";
import Stocks from "../pages/Stocks";
import StockTransactions from "../pages/StockTransactions";
import SiteCards from "../pages/SiteCards";
import CardPackages from "../pages/CardPackages";
import Payment from "../pages/Payment";
import ManagePurchase from "../pages/ManagePurchase";
import PurchaseReturn from "../pages/PurchaseReturns";
import ItemDistripution from "../pages/ItemDistripution";
import CardDistribution from "../pages/CardDistribution";
import ChannelPackage from "../pages/ChannelPackages";
import OpenClosedCard from "../pages/OpenClosedCard";
import OpenClosedRedlineCard from "../pages/OpenClosedRedlineCard";
import CancelAndActivateRedlineCard from "../pages/CancelAndActivateRedlineCard";
import CloseOpenedCard from "../pages/CloseOpenedCard";
import Agents from "../pages/Agents";
import ManageInventory from "../pages/ManageInventory";
import UpdateManage from "../pages/UpdateManage";
import UpdateCable from "../pages/UpdateCable";
import Sales from "../pages/Sales";
import SalesForm from "../pages/SalesForm";
import MasterFile from "../pages/MasterFile";
import BaseExample, { BasicTable, ScrollVertical } from "../pages/test";
import EmployeeRegistration from "../pages/Employee";
import Advance from "../pages/Advance";
import GeneratePayroll from "../pages/GeneratePayroll";
import ManagePayroll from "../pages/ManagePayroll";
import Deduction from "../pages/Deduction";
import AssignGroups from "../pages/AssignGroups";
import NewCable from "../pages/NewCable";
import NewRedlineCable from "../pages/NewRedlineCable";
// import NewCables from "../pages/NewCables";
import ManageCables from "../pages/ManageCables";
import ReceiverDetails from "../pages/RecieverDetails";
//import AddReciever from "../pages/AddReciever";
import Sec from "../pages/Select2";
import Appb from "../pages/tests";
import AddReciever from "../pages/ItemSales";
import Ticket from "../pages/CreateTicket";
import ManageTickets from "../pages/ManageTickets";
import PurchasesOrder from "../pages/PurchaseOrders";
import ManagePurchaseOrder from "../pages/ManagePurchaseOrder";

import ResolveTicket from "../pages/ResolveTicket";
import ReceiverDistribution from "../pages/ReceiverDistribution";
import RecieveItem from "../pages/ReceiveItem";
import InvoiceDetails from "../pages/Invoice";
import PurchaseReceiveUpdate from "../pages/purchaseReceiveUpdate";
import UploadRecivers from "../pages/UploadRecivers";
import Test from "../pages/test";
import CreateUsers from "../pages/CreateUser/Index";
import CreateIPTV from "../pages/CreateIPTV";
import AutoCharge from "../pages/AutoCharge";
import PurchasesOrderUpdate from "../pages/purchaseOrderUpdate";
import Report from "../pages/Report";
import InventriesReport from "../pages/InventroyReport";
import PurchaseReport from "../pages/purchaseReport";
import SalesReport from "../pages/SalesReport";
import Promotion from "../pages/Promotion";
import UserRole from "../pages/UserRole/Index";
import UploadCustomers from "../pages/UploadCustomers";
import NewCables from "../pages/NewCables";
import ItemSalees from "../pages/itemsaleees";
import odd from "../pages/odd dhaweye/odd";
import oddReport from "../pages/odd dhaweye/odd";
import OddReport from "../pages/odd dhaweye/odd";
import OpenCardReport from "../pages/OpenCardReport";
import HorIptv from "../pages/Hor-aptv";
import CableReport from "../pages/CableReport";
import CloseReport from "../pages/CloseReport";
import IptvReport from "../pages/Iptv report";
import PendingOrdersReport from "../pages/PendingOrdersReport";
import PurchaseReciveReport from "../pages/PurchaseReciveReport";
import PurchaseOrderReport from "../pages/PurchaseOrderReport";
import CustomerReport from "../pages/CustomerReport/Index";
import CustomerStatementReport from "../pages/CustomerStatementReport";
import TicketSupervisorReport from "../pages/TicketSupervisorReport";
import TicketRegistrationReport from "../pages/TicketRegistrationReport";
import BasicTwosVerify from "../pages/OTP";
import DeductionReport from "../pages/DeductionReport";
import IPTVCustReport from "../pages/IPTVCustReport";
import AdvancedReport from "../pages/AdvanceReport";
import SupplierReport from "../pages/SupplierReport";
import GeneratePayrollSummaryReport from "../pages/generatePayrollSummaryReport";

import GeneratePayrollDetailReport from "../pages/GeneratePayrollDetailReport";

import EmployeeReport from "../pages/EmployeeReport";

import TicketTransferReport from '../pages/TicketTransferReport'

import TicketsSummary from "../pages/ticketsSummary";
import TicketsSupervisorsSummary from "../pages/ticketsSupervisorsSummary";
import CustomerList from "../pages/customerList";
import ExpireCards from "../pages/ExpireCards";
import ManualOpenCards from "../pages/ManualCardReport";
import NasiyeReport from "../pages/nasiyeReport";
import EnrollUser from "../pages/Teest";
import Shifts from "../pages/Shift";
import MultiShiftSetup from "../pages/MultiShiftSetup";
import NewIptv from "../pages/NewIptv";
import Bill from "../Components/Common/Bill";
import OpenIptv from "../pages/IPTVMANAGE";
import CloseIptv from "../pages/ManageClosedIptv";
import IptvCustomerRecharge from "../pages/iptvCustomerRecharge";
import OttCustomerRecharge from "../pages/ottCustomerRecharge";
import ExpiredNasiyeIptvs from "../pages/ExpiredIptvs";
import ActiveNasiyeCustomers from "../pages/ActiveNasiyeCustomers";
import TransferIptv from "../pages/TransferIptv";
import SolvedReports from "../pages/SolvedReports";
import Cilado from "../pages/Cilado";
import Testing from "../pages/Testing";
import UploadIptvCustomers from "../pages/UploadIptvCustomers";
import IptvOrganization from "../pages/IptvOrganization";
import FourTwoFourRePort from "../pages/424Report";
import Sms from "../pages/Sms";
import SendSmsToExpiredCustomers from "../pages/SendSmsExpiredCustomers";
import SendSmsToIptvCustomers from "../pages/smsiptv";
import SendSmsNotifications from "../pages/SendSmsNotifications";
import Cover404 from "../pages/NotFound";
import ReportRoles from "../pages/reportRoles";
import AddNewMenus from "../pages/AddAdminRole";
import CloseCardReport from "../pages/CloseCardReport";
import SendEmployeeToAttendence from "../pages/SendEmployee";
import RevenueReport from "../pages/RevenueReport";               
import ConnectDevice from "../pages/ConnectDevice/index";               
import CollectDailyAttendance from "../pages/CollectDailyAttendance/index";               
import CollectAttendanceData from "../pages/CollectAttendanceData/index";               
import CollectAttendanceFromDevice from "../pages/CollectAttendanceFromDevice/index";               
import MonthlyAttendanceReport from "../pages/CollectMonthlyAttendanceReport/index";               
import AvailableCardNumbers from "../pages/AvailableCardNumbers/index";                           
import ResolvedTickets from './../pages/ResolvedTickets/index';
import RegisterSms from './../pages/RegisterSmsTypes/index';
import CancelCards from './../pages/CancelCards/index';
import TransferReceiver from "../pages/TransferReceiver";
import CompleteTicket from "../pages/CompleteTicket";
import SendSmsWithExpiredCards from "../pages/SendSmsWithExpiredCards";
import IptvOrganizationReport from "../pages/IptvOrganizationReport";
import LeaveCategory from "../pages/LeaveCategory";
import LeaveEmployee from "../pages/LeaveEmployee";



//menu pages
const authProtectedRoutes = [
  { path: "/dashboard", component: DashboardEcommerce },
  { path: "/index", component: DashboardEcommerce },
  { path: "/registerItem", component: RegisterItem },
  { path: "/registerCustomer", component: CustomerRegister },
  { path: "/registerStore", component: Stores },
  { path: "/registerItemType", component: ItemType },
  { path: "/Cards", component: Cards },
  { path: "/BillGeneration", component: BillGeneration },
  { path: "/registerPurchase", component: Purchase },
  { path: "/registerSupplier", component: Supplier },
  { path: "/registerSites", component: Sites },
  { path: "/PurchaseDetails", component: PurchaseDetails },
  { path: "/PurchaseReturn", component: PurchaseReturn },
  { path: "/PurchaseReturnDetails", component: PurchaseReturnDetails },
  { path: "/Inventory", component: Inventories },
  { path: "/Stocks", component: Stocks },
  { path: "/StockTransactions", component: StockTransactions },
  { path: "/SiteCards", component: SiteCards },
  { path: "/CardPackages", component: CardPackages },
  { path: "/Payment", component: Payment },
  { path: "/ManagePurchase", component: ManagePurchase },
  { path: "/ItemDistripution", component: ItemDistripution },
  { path: "/CardDistribution", component: CardDistribution },
  { path: "/ChannelPackage", component: ChannelPackage },
  { path: "/OpenClosedCard", component: OpenClosedCard },
  { path: "/OpenClosedRedlineCard", component: OpenClosedRedlineCard },
  { path: "/CancelOrActivate", component: CancelAndActivateRedlineCard },
  { path: "/CloseOpenedCard", component: CloseOpenedCard },
  { path: "/MasterFile", component: MasterFile },
  { path: "/Agents", component: Agents },
  { path: "/ManageInventory", component: ManageInventory },
  { path: "/ManagePurchaseOrder", component: ManagePurchaseOrder },
  // { path: "/Sales", component: Sales },
  // { path: "/NewCables/:the_id", component: NewCables },
  { path: "/NewCable", component: NewCables },
  { path: "/NewRedline", component: NewRedlineCable },
  { path: "/CreateIPTV", component: CreateIPTV },
  { path: "/CreateHORIPTV", component: HorIptv },
  { path: "/ManageCables", component: ManageCables },
  { path: "/SalesForm", component: SalesForm },
  { path: "/EmployeeRegistration", component: EmployeeRegistration },
  { path: "/Advance", component: Advance },
  { path: "/Deduction", component: Deduction },
  { path: "/AssignGroups", component: AssignGroups },
  { path: "/NewCableFrom", component: NewCable },
  //enroll empolyee
  { path: "/SendFinger", component: SendEmployeeToAttendence },
  { path: "/ConnectDevice", component: ConnectDevice },
  { path: "/dailyAttendance", component: CollectDailyAttendance },
  { path: "/Attendance", component: CollectAttendanceData },
  { path: "/download", component: CollectAttendanceFromDevice },
  { path: "/monthly", component: MonthlyAttendanceReport },
  { path: "/AvailableCards", component: AvailableCardNumbers },
  { path: "/ResolvedTickets", component: ResolvedTickets },
  { path: "/RegisterSms", component: RegisterSms },
  { path: "/CancelCards", component: CancelCards },
  { path: "/TransferReceiver", component: TransferReceiver },

  // { path: "/RegisterReciver", component: Reciver },
  { path: "/AddReciver", component: ReceiverDetails },
  { path: "/GeneratePayroll", component: GeneratePayroll },
  { path: "/ManagePayroll", component: ManagePayroll },
  { path: "/ItemSales", component: ItemSalees },
  { path: "/CreateTicket", component: Ticket },
  { path: "/ResolveTicket", component: ResolveTicket },
  { path: "/ManageTickets", component: ManageTickets },
  { path: "/ReceiverDistribution", component: ReceiverDistribution },
  { path: "/Invoice", component: InvoiceDetails },
  { path: "/UploadRecivers", component: UploadRecivers },
  { path: "/CreateUsers", component: CreateUsers },
  { path: "/AutoCharge", component: AutoCharge },
  { path: "/Report", component: Report },
  { path: "/managePurchase/edit/:id", component: UpdateManage },
  { path: "/InventriesReport", component: InventriesReport },
  { path: "/PurchaseReport", component: PurchaseReport },
  { path: "/SalesReport", component: SalesReport },
  { path: "/Promotion", component: Promotion },
  { path: "/UserRole", component: UserRole },
  { path: "/TransferCardNo", component: OddReport },
  { path: "/OpenCardReport", component: OpenCardReport },
  { path: "/CableReport", component: CableReport },
  { path: "/CloseCardReport", component: CloseReport },
  { path: "/IptvReport", component: IptvReport },
  { path: "/PurchaseOderReport", component: PendingOrdersReport },
  { path: "/CustomerStatementReport", component: CustomerStatementReport },
  { path: "/TicketSupervisorReport", component: TicketSupervisorReport },
  { path: "/TicketRegistrationReport", component: TicketRegistrationReport },
  { path: "/TicketTransferReport", component: TicketTransferReport },
  { path: "/DeductionReport", component: DeductionReport },
  { path: "/IPTVCustReport", component: IPTVCustReport },
  { path: "/AdvancedReport", component: AdvancedReport },
  { path: "/SupplierReport", component: SupplierReport },
  { path: "/EmployeeReport", component: EmployeeReport },
  { path: "/category", component: LeaveCategory },
  { path: "/leave", component: LeaveEmployee },

  { path: "/TicketsSummary", component:TicketsSummary },
  { path: "/customerList", component:CustomerList },
  { path: "/ManualOpenCards", component:ManualOpenCards },
  { path: "/ExpireCards", component:ExpireCards },
  { path: "/TicketsSupervisorsSummary", component:TicketsSupervisorsSummary },
  { path: "/EnrollUser", component:EnrollUser },
  { path: "/Shifts", component:Shifts },
  { path: "/MultiShifts", component:MultiShiftSetup },
  { path: "/NewIptv/:id?/:name?/:phone?/:address?/:customerTelll?/:district?/:check?", component:NewIptv },
  { path: "/Bill", component:Bill },
  { path: "/NasiyeReport", component:NasiyeReport  },
  { path: "/IptvManage", component:OpenIptv  },
  { path: "/CloseIptv", component:CloseIptv  },
  { path: "/IptvCustomerRecharge", component:IptvCustomerRecharge  },
  { path: "/OttCustomerRecharge", component:OttCustomerRecharge  },
  { path: "/ExpiredNasiyeIptvs", component:ExpiredNasiyeIptvs  },
  { path: "/Active", component:ActiveNasiyeCustomers  },
  { path: "/TransferIptv", component:TransferIptv  },
  { path: "/Sms", component:Sms  },
  { path: "/SendSMSToCustomers", component:SendSmsToExpiredCustomers  },
  { path: "/SendSMSToIptvCustomers", component:SendSmsToIptvCustomers  },
  { path: "/SendSmsNotifications", component:SendSmsNotifications  },
  { path: "/NotFound", component: Cover404 },
  { path: "/CompleteTicket", component: CompleteTicket },
  { path: "/ExpiredAnalog", component: SendSmsWithExpiredCards },
  { path: "/OrganizationReport", component: IptvOrganizationReport },


  { path: "/PurchaseReciveReport", component: PurchaseReciveReport },
  { path: "/PurchaseOrderReport", component: PurchaseOrderReport },
  { path: "/PurchaseOrderReport", component: PurchaseOrderReport },
  { path: "/PurchasesOrder", component: PurchasesOrder },
  { path: "/CustomerReport", component: CustomerReport },
  { path: "/SolvedReports", component: SolvedReports },
  { path: "/Cilado", component: Cilado },
  { path: "/Testing", component: Testing },
  { path: "/UploadIptvCustomers", component: UploadIptvCustomers },
  { path: "/IptvOrganization", component: IptvOrganization },
  { path: "/FourTwoFourRePort", component: FourTwoFourRePort },
  { path: "/ReportRoles", component: ReportRoles },
  { path: "/AddNewMenus", component: AddNewMenus },
  { path: "/PaymentsReports", component: RevenueReport },

  { path: "/ManageCables/edit/:id", component: UpdateCable },
  // { path: "/RegisterRecievers", component: Reciver },

  //User Profile
  { path: "/profile", component: UserProfile },
  { path: "/test", component: Test },
  { path: "/uploadCustomers", component: UploadCustomers },
  { path: "/sec", component: Sec },
  { path: "/purchaseReceive/:the_id", component: RecieveItem },
  { path: "/view/purchaseOrder/:the_id", component: InvoiceDetails },
  {
    path: "/purchaseReceiveUpdate/edit/:the_id/:type",
    component: PurchaseReceiveUpdate,
  },
  {
    path: "/purchaseOrderUpdate/edit/:the_id/:type",
    component: PurchasesOrderUpdate,
  },
  {
    path: "/generatePayrollSummaryReport",
    component: GeneratePayrollSummaryReport,
  },
  {
    path: "/generatePayrollDetailReport",
    component: GeneratePayrollDetailReport,
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/register", component: Register },
  { path: "/OTP", component: BasicTwosVerify },
  { path: "/OTP", component: PurchasesOrder },
  // { path: "/NotFound", component: Cover404 },

];

export { authProtectedRoutes, publicRoutes };
