import React, { useRef } from 'react'
import logo from "./LogoTabaarak.png";
import { useReactToPrint } from 'react-to-print';
import { Card } from 'reactstrap';
const PrintComponent = ({printedData}) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });
  return (
    <Card ref={componentRef}>
 <div id="divcontents" style={{ marginLeft: "60px", display: "none" }} >
        <img
          src={logo}
          alt="Logo"
          style={{ marginLeft: "35%", size: "40px" }}
        />

        <div className="maincontainer">
          <div
            className="CustomerInfo"
            style={{ display: "inline-block", float: "right" }}
          >
            <div
              className="Custinfo2"
              style={{ display: "inline-block", float: "left" }}
            >
              <div style={{ float: "right" }}>
                <label className="salesDate">generated Date : </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                  {/* {today} */}
                </span>
              </div>
              <br />

              <div style={{ float: "right" }}>
                <label className="Customer"> </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                  {/* {singleCustomer.customerName} */}
                </span>
              </div>
              <br />

              <div style={{ float: "right" }}>
                <label className="Customer"> </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                  {/* {singleCustomer.customerPhone} */}
                </span>
              </div>
              <br />

              {/* <div style={{ float: "right" }}>
                <label className="Customer">Address : </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                </span>
              </div> */}
            </div>
          </div>
          <br />
          <br />

          <div className="itemstable" style={{ marginTop: "50px" }}>
            <table
              id="items"
              className="table table-striped table-bordered table-hover"
              style={{
                width: "100%",
                maxWidth: "100%",
                fontSize: "15px",
                position: "relative",
              }}
            >
              <thead className="list form-check-all">
                <tr style={{ marginRight: "-100px" }}>
                  <th></th>
                  <th>Full Name</th>
                  <th>Bank Account</th>
                  <th> Net Income </th>

                 
                </tr>
              </thead>
              <tbody className="list form-check-all">
                {printedData?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td></td>

                      <td className="Customer">{data.firstname}</td>
                      <td className="Customer">{data?.bankAccount}</td>
                      <td className="Customer">{data?.baseSalary}</td>


                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
           
        </div>
        </div>
      </div>
      </Card>

  )
}

export default PrintComponent