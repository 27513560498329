import React, { useState, useEffect, useContext, useMemo } from "react";
import Select from "react-select";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Alert,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";

const Deduction = () => {
  const [serviceList, setServiceList] = useState([
    { item: "", QtyAvailable: "", Qty: "", rate: "", Amount: "" },
  ]);


  const {
    departments,
    getAllTitles,
    EmployeeRegister,
    fetchEmpTitle,
    fetchDepartment,
    fetchEmployees,
    fetchSites,
    getEmployee,
    getDeduction,
    deleteEmployee,
    DeductionRegister,
    deleteDeduction,
    fetchDeductions,
    DeductionUpdate,
    fetchGroups,
    sites,
    getAdvance,User
  } =
    useContext(LoginContex);

  // Deduction
  const [EmployeeId, setEmployeeId] = useState();
  const [year, setyear] = useState();
  const [month, setmonth] = useState();
  const [description, setdescription] = useState();
  const [amount, setamount] = useState();
  const [empName, setempName] = useState();
  const [object_id, setObject] = useState();

  const [isUpdating, setIsUpdating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);


  const [modal_list, setmodal_list] = useState(false);


  const tog_list = () => {
    setmodal_list(!modal_list);
    clear();

    // setIsEditing(false);
    // // setIsUpdating(false);
  };


  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const closing = () => {
    // setIsEditing(false);
    setmodal_list(false);
    clear();
  }

  const addModal = () => {
    tog_list();
    setEmployeeId("");
    setamount("");
    setdescription("");
    setamount("");
    setyear("");
    setmonth("");
    setIsUpdating(false);
  };


  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };


  useEffect(() => {
    fetchEmployees();
    fetchEmpTitle();
    fetchDepartment();
    fetchGroups();
    fetchDeductions();
    getYear();
    fetchSites();

  }, []);

  // //console.log(getDeduction.result);
  document.title = "Deduction  ";


  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  let userId = User?.data?.user?._id || User?.user?._id;

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "empName",
        filterable: false,
      },
      {
        Header: "Amount",
        accessor: "amount",
        filterable: false,
      },
      {
        Header: "Reoson",
        accessor: "description",
        filterable: false,
      },
      
      // {
      //   Header: "user",
      //   accessor: "user_info.username",
      //   filterable: false,
      // },
      
      {
        Header: "Action Date ",
        accessor: "actionDate",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    editPop(customerData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    deletPop(customerData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ]
    // [handleCustomerClick]
  );

  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,
      { item: "", QtyAvailable: "", Qty: "", rate: "", Amount: "" },
    ]);
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
    // handleServiceAdd();
  };

  const test = (e, index) => {
    handleServiceChange(e, index);
    if (e.target.name === "item") {
      handleServiceAdd();
    }
  };

  const [date, setDate] = useState();

  const getYear = () => setDate(new Date().toLocaleString() + "")

  const clear = () => {
    setEmployeeId("");
    setyear("");
    setmonth("");
    setamount("");
    setdescription("");
  }

  const handlerSubmit = (e) => {
    e.preventDefault();
    if (!isUpdating) {
      const data = {
        empId: EmployeeId,
        amount: amount,
        description: description,
        userId: userId,
        year: year,
        month: month,

      };

      if (!EmployeeId || !year || !month || !amount) {
        showToastMessage('please Fill the required fields');
      } else {
        //console.log(data);
        DeductionRegister(data);
        setmodal_list(false);
        setEmployeeId("");
        setamount("");
        setdescription("");
        setamount("");
        setyear("");
        setmonth("");
      }
    }

    else {
      e.preventDefault();
      const data = {
        empId: EmployeeId,
        amount: amount,
        description: description,
        userId: userId,
        year: year,
        month: month,
        id: object_id,

      };
      if (isUpdating) {

        if (!EmployeeId || !year || !month || !amount) {
          showToastMessage('please Fill the required fields');
        } else {
          //console.log(data);
          DeductionUpdate(data);
          setmodal_list(false);
          setEmployeeId("");
          setamount("");
          setdescription("");
          setamount("");
          setyear("");
          setmonth("");
        }

      }

    }

    // }
  };



  const CustomersArr = [];

  for (let i = 0; i < getEmployee.length; i++) {
    //console.log(getEmployee[i]._id);

    var dropdownList = {
      value: getEmployee[i]._id,
      label: ` ${getEmployee[i].empName}`,
    };

    CustomersArr.push(dropdownList);
  }


  const handleTypeSelect = e => {
    setEmployeeId(e.value);
  };

  //console.log(getDeduction);

  const sortDeduction = [];
  for (let i = 0; i < getDeduction?.length; i++) {
    let singleArr = [
      getDeduction[i].empName,
      getDeduction[i].amount,
      getDeduction[i].description,
      moment(getDeduction[i].actionDate).utc()
        .format("YYYY-MM-DD"),
      getDeduction[i].year,
      getDeduction[i].month,
      getDeduction[i]._id,
      getDeduction[i].empId,


    ];

    sortDeduction.push(singleArr);
  }


  const editPop = (data) => {
    //console.log(data);
    setIsUpdating(true);
    tog_list();
    setEmployeeId(data.empId);
    setamount(data.amount);
    setdescription(data.description);
    setyear(data.year);
    setmonth(data.month);
    setObject(data._id);
    // //console.log(data._id);
    setIsEditing(true);
  };

  ///delete
  const deletPop = (data) => {
    // //console.log(setEmployeeId)
    setmodal_delete(true);
    setEmployeeId(data._id);
  };
  const deleteDed = () => {
    deleteDeduction(EmployeeId);
    setmodal_delete(false);
  };

  return (

    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Deduction "
            pageTitle="Deduction"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">Add Deduction</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="deductionstable">
                    <Row className="g-4 mb-3"></Row>
                    <TableContainer
                        columns={columns}
                        data={getDeduction || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                      // handleCustomerClick={handleCustomerClicks}
                      // isCustomerFilter={true}
                      />

                    <ModalFooter></ModalFooter>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Employee Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"


      >
        {/* <ModalHeader className="bg-light p-3">
          Add Deduction
          <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </ModalHeader> */}
        <div className="bg-light p-3 modal-header"><h5 className="modal-title">Add Deduction</h5>

          <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>


        </div>

        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label>Search Employee</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  {/* <VirtualizedSelect
                                          option={options}
                                          onChange={(value) => setItem(value)}
                                          value={item}
                                      /> */}

                  <Select
                    // className="bg-white"
                    options={CustomersArr}
                    // onChange={(choice) =>
                    //   setEmployeeId(choice.value)
                    // }


                    onChange={handleTypeSelect}
                    value={CustomersArr.filter(function (option) {
                      return option.value === EmployeeId;
                    })}

                    defaultValue={{ label: "Select Employee", value: EmployeeId }}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        // text: "orangered",
                        // primary25: "hotpink",
                        // primary: "black",
                      },
                    })}
                  />

                </div>
              </Col>
              <Col md={6}>
                <div>
                  <label>Select Year</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>

                  <select className="form-select " id="itemtype"
                    onChange={(e) => setyear(e.target.value)}
                    value={year}
                  >
                    <option selected>Choose...</option>
                    {/* <option value="2021">2021</option> */}
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label>Select Month</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  <select className="form-select " id="itemtype"
                    onChange={(e) => setmonth(e.target.value)}
                    value={month}
                  >
                    <option selected>Choose...</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>

                  </select>
                </div>
              </Col>
              <Col md={6}>
                <label>Amount</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>
                <div className="mb-3">
                  <input
                    type="number"
                    id="Employee-field"
                    className="form-control"
                    placeholder="Enter Amount"
                    required
                    onChange={(e) => setamount(e.target.value)}
                    value={amount}
                  ></input>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <label>Reason</label><span style={{ marginLeft: "5px", color: "red" }}>*</span>
                <div className="mb-6">
                  <textarea
                    className="form-control"
                    id="Description-field"
                    rows="3"
                    required
                    onChange={(e) => setdescription(e.target.value)}
                    // onChange={(e) => setDesc(e.target.value)}
                    value={description}
                  ></textarea>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => closing()}
              >
                Close
              </button>
              {/* <button type="submit" className="btn btn-success" id="add-btn"
                onClick={() => {
                  handlerSubmit();
                }}
              >
                {isEditing ? "Update" : "Add"}
              </button> */}
              <button type="submit" className="btn btn-success" id="add-btn">
                {isUpdating ? "Update" : "save"}
              </button>

            </div>
          </ModalFooter>
        </form>

        {/* =================================== */}
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={deleteDed}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Deduction;
