import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify"
const UserRoles = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEditor, setIsEditor] = useState(false);
  const [isContributor, setIsContributor] = useState(false);

  const handleAdminChange = () => {
    setIsAdmin(!isAdmin);
    setIsEditor(false);
    setIsContributor(false);
  };

  const handleEditorChange = () => {
    setIsEditor(!isEditor);
    setIsAdmin(false);
    setIsContributor(false);
  };

  const handleContributorChange = () => {
    setIsContributor(!isContributor);
    setIsAdmin(false);
    setIsEditor(false);
  };

 //how to get the stock data of Tesla in python?

  return (
   

<>
<div className="page-content">
  <Container fluid>
    <Row>
      <Col lg={12}>
        <Card>
          <ToastContainer />
          <CardBody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
           <div className="user-roles-container">
      <h2>User Role Management</h2>
      <div className="user-role">
        <input type="checkbox" id="admin" checked={isAdmin} onChange={handleAdminChange} />
        <label htmlFor="admin">Admin</label>
        {isAdmin && (
          <div className="role-details">
            <p>Admin can manage all content and users.</p>
          </div>
        )}
      </div>
      <div className="user-role">
        <input type="checkbox" id="editor" checked={isEditor} onChange={handleEditorChange} />
        <label htmlFor="editor">Editor</label>
        {isEditor && (
          <div className="role-details">
            <p>Editor can manage all content, but not users.</p>
          </div>
        )}
      </div>
      <div className="user-role">
        <input type="checkbox" id="contributor" checked={isContributor} onChange={handleContributorChange} />
        <label htmlFor="contributor">Contributor</label>
       
          <div className="role-details">
            <p>Contributor can only manage their own content.</p>
          </div>
       
      </div>
    </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
</div>
</>
  );
};

export default UserRoles;
