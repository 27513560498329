import React, { useState, useEffect, useContext, useMemo } from "react";
import DataTable from "react-data-table-component";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import Bill from "../../Components/Common/Bill";

const RegisterItem = () => {
  //local states
  const [itemName, setitemName] = useState();
  const [itemType, setItemType] = useState();
  const [desc, setDesc] = useState();
  const [Avaliable, setAvailbale] = useState();
  const [reOrder, setOrder] = useState();
  const [id, setId] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [yes, setYes] = useState("true");
  const [costPrice, setCostPrice] = useState("");
  const [sellingPrice, setsellingPrice] = useState("");
  const {
    registerItem,
    readProducts,
    products,
    deleteProduct,
    updateProduct,
    isLoading,
    isAlert,
    showAlert,getCurrentUserId ,User
  } = useContext(LoginContex);
  ////console.log(isAlert);
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  ////console.log(yes);

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    readProducts();
    // setYes(true);
  }, []);

  document.title = "Item Registration ";
  ////console.log(yes);

  ////console.log(User?.data?.user?._id);
  let userId = User?.data?.user?._id || User?.user?._id;
  ////console.log(userId)
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "itemName",
        filterable: false,
      },
      {
        Header: "Type",
        accessor: "itemType",
        filterable: false,
      },
      {
        Header: "Quantity Avaliable",
        accessor: "initialQuantity",
        filterable: false,
      },
      {
        Header: " Selling Price",
        accessor: "sellingPrice",
        filterable: false,
      },
      {
        Header: "Re-order Level ",
        accessor: "reorderLevel",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    editPop(customerData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    deletPop(customerData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ]
    // [handleCustomerClick]
  );

  let yess = yes === "true";
  // handle submit
  ////console.log(yes);
  const handlerSubmit = (e) => {
    e.preventDefault();
    if (!isEditing) {
      const data = {
        reorderLevel: reOrder,
        initialQuantity: Avaliable,
        itemDescription: desc,
        itemType,
        itemName,
        costPrice,
        sellingPrice,
        userId : userId,
        hasInitialQuantity: yess,
      };

      if (!itemName || !itemType) {
        return;
      }
      registerItem(data);
      setmodal_list(false);
      setsellingPrice("");
      setCostPrice("");
      setYes("");
      setOrder("");
      setAvailbale("");
      setItemType("");
      setitemName("");
      ////console.log(data);
    }

    if (isEditing) {
      const data = {
        reorderLevel: reOrder,
        initialQuantity: Avaliable,
        itemDescription: desc,
        itemType,
        itemName,
        id: id,
        costPrice,
        sellingPrice,
        hasInitialQuantity: yes,
      };

      if (!itemName || !itemType) {
        return;
      }
      updateProduct(data);
      setIsEditing(false);
      setmodal_list(false);
      setDesc("");
      setItemType("");
      setitemName("");
      setAvailbale("");
      setOrder("");
    }
  };

  ///delete
  const deletPop = (data) => {
    setmodal_delete(true);
    setId(data._id);
  };

  const deletProd = () => {
    deleteProduct(id);
    setmodal_delete(false);
  };

  const editPop = (row) => {
    ////console.log(row);
    setmodal_list(true);
    setId(row._id);
    setDesc(row.itemDescription);
    setItemType(row.itemType);
    setitemName(row.itemName);
    setCostPrice(row.costPrice);
    setsellingPrice(row.sellingPrice);
    setAvailbale(row.initialQuantity);
    setOrder(row.reorderLevel);
    setIsEditing(true);
    setYes(row.hasInitialQuantity);
    //  setYes(true);
  };

  const addModal = () => {
    tog_list();
    setIsEditing(false);
    setsellingPrice("");
    setCostPrice("");
    setYes("");
    setOrder("");
    setAvailbale("");
    setItemType("");
    setitemName("");
    setYes("true");
  };

  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      // use the selector function to resolve your field names by passing the sort comparitors
      const aField = selector(rowA);
      const bField = selector(rowB);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const load = () => {
    if (isLoading) {
      return (
        <>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ToastContainer />
                        <img
                          src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif"
                          style={{ backgroundColor: "white !", width: "700px" }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Item Registration" pageTitle=" Registration" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h4 className="card-title mb-0">Add Item, Edit & Remove</h4> */}
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        {<ToastContainer />}
                        <div>
                          <subHeaderComponentMemo />
                          <h4 className="card-title mb-0">Add Item</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  {/* {load()} */}
                  <div id="customerList">
                    <Row className="g-4 mb-3"></Row>
                    <div className="table-responsive table-card mt-3 mb-1"></div>
                  </div>
                  {/* <subHeaderComponentMemo /> */}
                  {/* {isLoading && (
                    <img
                      src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif"
                      style={{ backgroundColor: "white !", width: "700px" }}
                    />
                  )} */}
                  {products.length > 0 && (
                    <TableContainer
                      columns={columns}
                      data={products || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      
                      
                      // handleCustomerClick={handleCustomerClicks}
                      // isCustomerFilter={true}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* item Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">
            {" "}
            {isEditing ? "update item" : "Add New Item"}{" "}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-12">
                  <label htmlFor="itemType_id-field " className="form-label">
                    Item Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="itemName-field"
                    className="form-control"
                    placeholder="Enter Name"
                    required
                    onChange={(e) => setitemName(e.target.value)}
                    value={itemName}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-12">
                  <label htmlFor="itemType_id-field " className="form-label">
                    Item type <span className="text-danger">*</span>
                  </label>
                  <select
                    id="itemType-field"
                    className="form-control"
                    onChange={(e) => setItemType(e.target.value)}
                    value={itemType}
                    required
                  >
                    <option value="">&hellip; Choose Item Type &hellip;</option>
                    <option value="Receiver">Receiver</option>
                    <option value="Remote">Remote</option>
                    <option value="Antenna">Antenna</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <div className="mb-12">
                  <label htmlFor="itemType_id-field " className="form-label">
                    Cost Price <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="costPrice-field"
                    className="form-control"
                    placeholder="Cost price..."
                    required
                    onChange={(e) => setCostPrice(e.target.value)}
                    value={costPrice}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-12">
                  <label htmlFor="itemType_id-field " className="form-label">
                    Selling Price <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="sellingPrice-field"
                    className="form-control"
                    placeholder="Selling price..."
                    required
                    onChange={(e) => setsellingPrice(e.target.value)}
                    value={sellingPrice}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={3}>
                <div>
                  <label className="form-label">Has initail quantity?</label>
                  <div className="mb-3">
                    <Input
                      className="form-control-input"
                      type="radio"
                      name="prim"
                      id="is_yes"
                      value={true}
                      onChange={(e) => setYes(e.target.value)}
                    />
                    <Label className="form-check-label"> Yes</Label>
                  </div>
                </div>
                <div className="mb-3">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="prim"
                    id="is_no"
                    defaultChecked
                    value={false}
                    onChange={(e) => setYes(e.target.value)}
                  />
                  <Label className="form-check-label"> No</Label>
                </div>
              </Col>
              {yes === "true" && (
                <Col md={4}>
                  <div className="mb-3">
                    <label htmlFor="AvaliableQty-field" className="form-label">
                      Avaliable Quantity <span className="text-danger">*</span>
                    </label>
                    <input
                      type="Number"
                      min="0"
                      step="1"
                      id="AvaliableQty-field"
                      className="form-control "
                      placeholder="Enter Quantity"
                      required
                      onChange={(e) => setAvailbale(e.target.value)}
                      value={yes === "true" ? Avaliable : 0}
                      // value={Avaliable}
                    />
                  </div>
                </Col>
              )}

              <Col md={5}>
                <div className="mb-3">
                  <label htmlFor="ReOrderLevel-field" className="form-label">
                    Re-Order Level
                  </label>
                  <input
                    type="text"
                    id="ReOrderLevel-field"
                    className="form-control "
                    placeholder="Enter Re-Order"
                    onChange={(e) => setOrder(e.target.value)}
                    value={reOrder}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isEditing ? "Update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={deletProd}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default RegisterItem;
