import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
import { LoginContex } from "../../context/loginContext/LoginContext";

const ReceiverDistribution = () => {
  const [end, setEnd] = useState();
  const [tem, setTem] = useState();
  const [itemName, setItemName] = useState();
  const [preview, setPreview] = useState();
  const [tempTable, setTempTable] = useState([]);
  const [modal_list, setmodal_list] = useState(false);
  const [modal_list_serial, setmodal_list_serial] = useState(false);
  const [clean, setClean] = useState(false);
  const {
    stores,
    sites,
    fetchSites,
    fetchStores,
    upadteStore,
    registerDisturbutionWithSerial,
    fetchReceiversType,
    itemGetAllReceiver,
    FetchLastReceiverNumberAndLastCardNumber,
    fetchLastSerial,
    ReceiverDistribution,
    resetReceiverTransfer,
    isLoading,
  } = useContext(LoginContex);
  const [distributionDate, setdistributionDate] = useState("");
  const [distributionType, setdistributionType] = useState("");
  const [from, setFrom] = useState("");
  const [inputList, setInputList] = useState([
    { itemId: "", quantity: "", unitPrice: "", amount: "" },
  ]);
  const [genRows, setGenRows] = useState([
    { itemId: "", receiverType: "", receiverId: "", cardNumber: "" },
  ]);
  const [to, setTo] = useState("");
  const [quantity, setQuantity] = useState("");
  const [cardEndsTo, setCardEnd] = useState("");
  const [cardStartFrom, setCardStart] = useState("");
  const [remarks, setRemark] = useState("");
  const [fromStoreToStoreee, setfromStoreToStore] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [id, setId] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardsList, seList] = useState([]);
  const {
    registerDisturbutionWithOutSerial,
    cardAndSerials,
    FetchReciverCardNumberAndSerials,
    itemSalesRegisteration,
    agents,
    fetchAgents,
    itemsReceivers,User
  } = useContext(LoginContex);
  const args = {
    itemId: itemName?.value,
    type: fromStoreToStoreee,
    typeId: from,
  };
  useEffect(() => {
    fetchSites();
    fetchStores();
    fetchReceiversType();
    fetchAgents();
    if (itemName?.value && fromStoreToStoreee && from) {
      FetchLastReceiverNumberAndLastCardNumber(args);
    }
    //console.log("call---------------------");
  }, [itemName?.value, from, fromStoreToStoreee]);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const tog_serial = () => {
    setmodal_list_serial(!modal_list_serial);
  };

  let userId = User?.data?.user?._id || User?.user?._id;

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  //console.log(args);
  //console.log(from);

  //console.log(fetchLastSerial?.lastSerial?.[0]?.receiverSerialNumber);
  document.title = "Reciever Distribution ";

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    list[index]["amount"] =
      Number(list[index]["quantity"]) * Number(list[index]["unitPrice"]);
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleRemoveClick2 = (index) => {
    const list = [...genRows];
    list.splice(index, 1);
    setGenRows(list);
  };
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const ty = tempTable.filter((t) => {
    return t.itemId === itemName.value;
  });
  const addServiceTable = (e) => {
    if (!end) {
      showToastMessage("Please provide the quantity to disturbute ");
      return;
    }
    if (!fetchLastSerial?.lastSerial?.[0]?.receiverSerialNumber) {
      showToastMessage("There is start of the disturbution  ");
      return;
    }
    // setRecNumber()

    if (ty.length > 0) {
      showToastMessage("duplicate items are not allowed");
      return;
    }
    setPreview(true);
    const data = {
      receiverName: itemName.label,
      start: fetchLastSerial?.lastSerial?.[0]?.receiverSerialNumber,
      end: Number(end),
      itemId: itemName.value,
    };
    //console.log(data);
    setTempTable([...tempTable, data]);
    setItemName("");
    setClean(true);
    setEnd("");
  };
  const delCableInfo = (index) => {
    const list = [...tempTable];
    list.splice(index, 1);
    setTempTable(list);
  };
  const fromStoreToStore = () => {
    setfromStoreToStore(true);
  };
  //console.log(fromStoreToStoreee);

  const handleSubmit = (e) => {
    //console.log("isEditing");
    e.preventDefault();
    //console.log(tempTable);
  };

  const handlerWithoutSerial = (e) => {
    //console.log("first");
    e.preventDefault();
    if (!isEditing) {
      const data = {
        remarks,
        to,
        from,
        distributionDate,
        haveSerial: false,
        distributionType: fromStoreToStoreee,
        cardLists: cardsList,
        quantity: 5,
      };
      if (!distributionDate || !cardsList || !to || !from) {
        return;
      }
      //console.log(data);
      registerDisturbutionWithOutSerial(data);
      setmodal_list(false);
    }
    if (isEditing) {
      const data = {
        remarks,
        cardStartFrom,
        cardEndsTo,
        quantity,
        to,
        from,
        distributionType,
        distributionDate,
      };
      if (
        !cardStartFrom ||
        !cardStartFrom ||
        !quantity ||
        !to ||
        !from ||
        !cardEndsTo ||
        !distributionType
      ) {
        return;
      }
      upadteStore(data);
    }
  };

  const addList = () => {
    seList([...cardsList, cardNumber]);
    setCardNumber("");
  };

  //console.log(cardsList);

  const removeCardNumber = (name) => {
    //console.log(cardsList);

    const filteredList = cardsList.filter((list, index) => list !== name);
    seList(filteredList);
  };
  const sortedProducts = [];
  for (let i = 0; i < itemGetAllReceiver.length; i++) {
    // //console.log(itemGetAllReceiver[i]._id);

    var dropdownList = {
      value: itemGetAllReceiver[i]._id,
      label: itemGetAllReceiver[i].itemName,
      amount: itemGetAllReceiver[i].sellingPrice,
    };

    sortedProducts.push(dropdownList);
  }

  //console.log(itemGetAllReceiver);

  const handleForm = (e) => {
    e.preventDefault();
    const data = {
      distributionDate,
      distributionType: fromStoreToStoreee,

      from,
      to,
      details: tempTable,
      userId
    };
    if (tempTable.length < 1) {
      showToastMessage("please add the receiver");
      return;
    }

    if (!distributionDate) {
      showToastMessage("please provide date");
      return;
    }
    // if (!distributionType) {
    //   showToastMessage("please provide distribution type");
    //   return;
    // }
    //console.log(data);
    e.target.reset();
    setTempTable([]);
    ReceiverDistribution(data);
    setdistributionDate("");
    setdistributionType("");
    setfromStoreToStore("");
    setFrom("");
    setTo("");
    // setClean(true);
    resetReceiverTransfer();
  };

  // const handleChange = (choice) => {
  //   setItemName(choice);
  //   // if (choice !== null) {
  //   //   setItemName(itemName => [...itemName, value]);
  //   //
  //   // }
  //   ref.current.select.clearValue();
  // };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Reciever Distribution " pageTitle="Inventory" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">
                            Reciever Distribution
                          </h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end"></div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <form onSubmit={handleForm}>
                    <Row>
                      <Col md={3}>
                        <div className="mb-3">
                          <label className="form-label">
                            Distribution Date{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setdistributionDate(date)}
                            value={distributionDate}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <label>
                            Distribution Type{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select "
                            id="distribution_type"
                            onChange={(e) => {
                              setfromStoreToStore(e.target.value);
                              setFrom("");
                              setItemName("");
                              setTem("");
                            }}
                            required
                          >
                            <option value="">Choose Type &hellip;</option>
                            <option value="From Store To Store">
                              From Store To Store
                            </option>
                            <option value="From Store To Site">
                              From Store To Site
                            </option>
                            <option value="From Store To Agent">
                              From Store To Agent
                            </option>
                            <option value="From Site To Site">
                              From Site To Site
                            </option>
                            <option value="From Site To Store">
                              From Site To Store
                            </option>
                            <option value="From Site To Agent">
                              From Site To Agent
                            </option>
                            <option value="From Agent To Agent">
                              From Agent To Agent
                            </option>
                            <option value="From Agent To Site">
                              From Agent To Site
                            </option>
                            <option value="From Agent To Store">
                              From Agent To Store
                            </option>
                          </select>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <label>
                            From <span className="text-danger">*</span>{" "}
                          </label>
                          <select
                            className="form-select "
                            id="from_store"
                            onChange={(e) => setFrom(e.target.value)}
                            required
                          >
                            <option value="">From &hellip;</option>
                            {fromStoreToStoreee == "From Store To Store" &&
                              stores.map((store, index) => (
                                <option key={index} value={store._id}>
                                  {" "}
                                  {store.storeName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Store To Site" &&
                              stores.map((store, index) => (
                                <option key={index} value={store._id}>
                                  {" "}
                                  {store.storeName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Site To Site" &&
                              sites.map((site, index) => (
                                <option key={index} value={site._id}>
                                  {" "}
                                  {site.siteName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Site To Store" &&
                              sites.map((site, index) => (
                                <option key={index} value={site._id}>
                                  {" "}
                                  {site.siteName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Site To Agent" &&
                              sites.map((site, index) => (
                                <option key={index} value={site._id}>
                                  {" "}
                                  {site.siteName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Store To Agent" &&
                              stores.map((store, index) => (
                                <option key={index} value={store._id}>
                                  {" "}
                                  {store.storeName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Agent To Agent" &&
                              agents.map((agent, index) => (
                                <option key={index} value={agent._id}>
                                  {" "}
                                  {agent.salesAgentName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Agent To Site" &&
                              agents.map((agent, index) => (
                                <option key={index} value={agent._id}>
                                  {" "}
                                  {agent.salesAgentName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Agent To Store" &&
                              agents.map((agent, index) => (
                                <option key={index} value={agent._id}>
                                  {" "}
                                  {agent.salesAgentName}{" "}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <label>
                            To <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select "
                            id="to_store"
                            onChange={(e) => setTo(e.target.value)}
                            required
                          >
                            <option value="">To &hellip;</option>
                            {fromStoreToStoreee == "From Store To Store" &&
                              stores.map((store, index) => (
                                <option key={index} value={store._id}>
                                  {" "}
                                  {store.storeName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Store To Site" &&
                              sites.map((site, index) => (
                                <option key={index} value={site._id}>
                                  {" "}
                                  {site.siteName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Site To Site" &&
                              sites.map((site, index) => (
                                <option key={index} value={site._id}>
                                  {" "}
                                  {site.siteName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Site To Store" &&
                              stores.map((store, index) => (
                                <option key={index} value={store._id}>
                                  {" "}
                                  {store.storeName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Store To Agent" &&
                              agents.map((agent, index) => (
                                <option key={index} value={agent._id}>
                                  {" "}
                                  {agent.salesAgentName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Site To Agent" &&
                              agents.map((agent, index) => (
                                <option key={index} value={agent._id}>
                                  {" "}
                                  {agent.salesAgentName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Agent To Agent" &&
                              agents.map((agent, index) => (
                                <option key={index} value={agent._id}>
                                  {" "}
                                  {agent.salesAgentName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Agent To Store" &&
                              stores.map((store, index) => (
                                <option key={index} value={store._id}>
                                  {" "}
                                  {store.storeName}{" "}
                                </option>
                              ))}
                            {fromStoreToStoreee == "From Agent To Site" &&
                              sites.map((site, index) => (
                                <option key={index} value={site._id}>
                                  {" "}
                                  {site.siteName}{" "}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      {/* <Col md={4}>
                      <div>
                        <Label className="form-label">
                          Quantity <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="disabledInput"
                          name="quantity"
                          required
                          onChange={(e) => setQuantity(e.target.value)}
                        />
                      </div>
                    </Col> */}
                      {/* <Col md={4}>
                      <div className="mb-3">
                        <label className="form-label"> Remark</label>
                        <input
                          type="text"
                          id=" remark-field"
                          className="form-control"
                          placeholder="Enter Remark"
                          onChange={(e) => setRemark(e.target.value)}
                        />
                      </div>
                    </Col> */}
                    </Row>
                    <Row className="g-4 mb-3"></Row>
                    <div className=" table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="cardTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th className="sort" data-sort="inventory_id">
                              Receiver
                            </th>

                            <th className="sort" data-sort="item_id">
                              Start
                            </th>
                            <th className="sort" data-sort="avaliable_quantity">
                              Quantity
                            </th>
                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          <tr className="">
                            <td className="item">
                              <div className="mb-3">
                                <Select
                                  // className="bg-white"
                                  options={fromStoreToStoreee && sortedProducts}
                                  // onChange={(choice) => handleChange(choice)}
                                  onChange={(choice) => {
                                    setItemName(choice);
                                    setClean(false);
                                    if (!from) {
                                      showToastMessage(
                                        "please provide the from field"
                                      );
                                    }
                                    if (!fromStoreToStoreee) {
                                      showToastMessage(
                                        "Please choose the disturbution type"
                                      );
                                    }
                                  }}
                                  value={sortedProducts.filter(function (
                                    option
                                  ) {
                                    return option.value === itemName?.value;
                                  })}
                                  required
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      // text: "orangered",
                                      // primary25: "hotpink",
                                      // primary: "black",
                                    },
                                  })}
                                />
                              </div>
                            </td>

                            <td className="qty">
                              <div className="mb-3">
                                <input
                                  type="number"
                                  name="start"
                                  className="form-control"
                                  placeholder="enter start"
                                  disabled
                                  value={
                                    clean
                                      ? " "
                                      : fetchLastSerial?.lastSerial?.[0]
                                          ?.receiverSerialNumber
                                  }
                                />
                              </div>
                            </td>
                            <td className="qty">
                              <div className="mb-3">
                                <input
                                  type="number"
                                  name="end"
                                  className="form-control"
                                  placeholder="Enter end"
                                  onChange={(e) => setEnd(e.target.value)}
                                  value={end}
                                />
                              </div>
                            </td>

                            <td>
                              <button
                                type="button"
                                className="btn btn-success mb-3 mx-3"
                                onClick={(e) => addServiceTable(e)}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        {tempTable?.length > 0 && (
                          <tbody className="list form-check-all">
                            {tempTable.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td className="Store_name">
                                    {" "}
                                    {data.receiverName}{" "}
                                  </td>
                                  <td className="location"> {data.start} </td>
                                  <td className="description"> {data.end} </td>

                                  <td>
                                    <div className="d-flex gap-2">
                                      <div className="remove">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger remove-item-btn"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteRecordModal"
                                          onClick={() => delCableInfo(index)}
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                      </table>
                    </div>
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-success"
                        id="add-btn"
                        disabled={isLoading}
                      >
                        {isLoading ? "please wait..." : "save"}
                      </button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Card Distribution From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">
            {" "}
            Record Card Distributions With Serial no.
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>
      </Modal>
      {/* Record Card Distributions Without Serial no.*/}
      <Modal
        isOpen={modal_list_serial}
        toggle={() => {
          tog_serial();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">
            {" "}
            Record Card Distributions Without Serial no.{" "}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              tog_serial(false);
            }}
            aria-label="Close"
          ></button>
        </div>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ReceiverDistribution;
