import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";

const Inventories = () => {
  const [modal_list, setmodal_list] = useState(false);
  const { Fetchstocks, stocks, products, index } = useContext(LoginContex);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const [serviceList, setServiceList] = useState([
    {
      itemId: "",
      quantity: "",
      description: "",
    },
  ]);
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    Fetchstocks();
  }, []);
  useEffect(() => {
    const attroptions = {
      valueNames: [
        "name",
        "born",
        {
          data: ["id"],
        },
        {
          attr: "src",
          name: "image",
        },
        {
          attr: "href",
          name: "link",
        },
        {
          attr: "data-timestamp",
          name: "timestamp",
        },
      ],
    };

    // pagination list

    new List("pagination-list", {
      valueNames: ["pagi-list"],
      page: 3,
      pagination: true,
    });
  });
  //console.log(stocks);

  document.title = "Inventory ";
  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,
      {
        itemId: "",
        quantity: "",
        description: "",
      },
    ]);
  };
  const mockData = [
    {
      inventory_id: "104",
      store_id: "102",
      item_id: "1001",
      avaliable_quantity: "205",
      last_updated_on: "10-09-2022",
      last_activity_id: "100",
    },
  ];
  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;

    if (e.target.name === "itemId") {
      handleServiceAdd();
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Inventory " pageTitle="Inventory" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <h4 className="card-title mb-0">Inventory</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div></div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="g-4 mb-3"></Row>
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <label>Item</label>
                          <select
                            className="form-select "
                            id="itemtype"
                            onChange={(e) => handleServiceChange(e, index)}
                            name="itemId"
                            selected
                          >
                            {products
                              ?.filter((pr) => pr.itemName !== "Card")
                              .map((pr, index) => {
                                return (
                                  <option key={index} value={pr._id} selected>
                                    {pr.itemName}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <label>Type</label>
                          <select className="form-select " id="itemtype">
                            <option value="">
                              &hellip; Choose Type &hellip;
                            </option>
                            <option value="All Purchases">All Purchases</option>
                            <option value="Purchase Receive">
                              Purchase Receive
                            </option>
                            <option value="Purchase Return">
                              Purchase Return
                            </option>
                          </select>
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="cardTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "5px" }}></th>
                            <th className="sort" data-sort="inventory_id">
                              Inventory ID
                            </th>
                            {/* <th className="sort" data-sort="store_id">
                              Store ID
                            </th> */}
                            <th className="sort" data-sort="item_id">
                              Item ID
                            </th>
                            <th className="sort" data-sort="avaliable_quantity">
                              Avaliable Quantity
                            </th>
                            <th className="sort" data-sort="last_updated_on">
                              Last Updated On
                            </th>
                            <th className="sort" data-sort="last_activity_id">
                              Last Activity ID
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {stocks?.map((data, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row"></th>
                                <td className="id" style={{ display: "none" }}>
                                  <Link
                                    to="#"
                                    className="fw-medium link-primary"
                                  >
                                    #VZ2104
                                  </Link>
                                </td>
                                <td className="inventory_id">{data.stockId}</td>
                                {/* <td className="inventory_id">
                                  {" "}
                                  {data.inventory_id}{" "}
                                </td> */}
                                <td className="Iitem_idtemd">{data.itemId}</td>
                                <td className="avaliable_quantity">
                                  {" "}
                                  {data.availableQuantity}{" "}
                                </td>
                                <td className=" last_updated_on">
                                  {data.updatedAt}
                                </td>
                                <td className=" last_activity_id">
                                  {" "}
                                  {data.lastActivityId}{" "}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Inventory From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        <ModalHeader className="bg-light p-3">
          Add New Inventory
          <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <form>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Inventory ID</label>
              <input
                type="number"
                id="inventory_id-field"
                className="form-control"
                placeholder="Enter Inventory ID"
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Store Id</label>
              <input
                type="number"
                id="store_id-field"
                className="form-control"
                placeholder="Enter Store Id"
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Item ID</label>
              <input
                type="number"
                id="items_id-field"
                className="form-control"
                placeholder="Enter Item ID"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Avaliable Quantity</label>
              <input
                type="number"
                id="avaliable_quantity-field"
                className="form-control"
                placeholder="Enter avaliable Quantity"
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Last Updated On</label>
              <input
                type="date"
                id=" last_updated_on-field"
                className="form-control"
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label"> Last Activity ID</label>
              <input
                type="number"
                id=" last_activity_id-field"
                className="form-control"
                placeholder="Enter Last Activity ID"
                required
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                Add Inventory
              </button>
              <button type="button" className="btn btn-success" id="edit-btn">
                Update
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Inventories;
