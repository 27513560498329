import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
//import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SalesForm from "../SalesForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Grid, _ } from "gridjs-react";
import DataTable from "react-data-table-component";
import Select from "react-select";

const currDate = new Date().toLocaleDateString;
var dateVariable = new Date();

const TransferReceiver = () => {

  const [cardNumber, setCardNumber] = useState("");
  const [newCardNumber, setNewCardNumber] = useState("");

  const [customerInfo, setCustomerInfo] = useState();
  const [receiverId, setreceiverId] = useState();
  const {
 fetchSoldCards,  soldReceivers,transferReceivers
  } = useContext(LoginContex);
  const [modal_list, setmodal_list] = useState(false);

  const tog_list = () => {
    setmodal_list(!modal_list);
    clear();
  };
  
 useEffect(()=>{
    fetchSoldCards()
 },[])

  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };


  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  
 

  const clear = () => {
    setCardNumber("");
    setNewCardNumber("");

  };
  document.title = "TRANSFER RECEIVER";

  const handlersubmit = (e) => {
    e.preventDefault();
    if(!newCardNumber || !cardNumber){
      showToastMessage("please provide all the required values")
      return;
    }
    const data = {
      cardNumber,
      newCardNumber
    }
    //console.log("======================",data,"================")
    transferReceivers(data)
  };
  
//   const handleChecking = () => {
//     fetchMacAddressAndSerialNumber(subscriptionId)
    
//     setNewMacAddress(macAddressAndSerialNumberData[0]?.macAddress)
//     setNewSerialNumber(macAddressAndSerialNumberData[0]?.serialNumber)
//     //console.log("mac address and serial number is: ",macAddressAndSerialNumberData[0])
//     // if(!macAddressAndSerialNumberData[0]?.macAddress || !macAddressAndSerialNumberData[0]?.serialNumber){
//     //   showToastMessage("invalid subscription")
//     // }
//   }
  const cardNumbers = [];

  for (let i = 0; i < soldReceivers.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: soldReceivers?.[i]?._id,
      cardNumber: soldReceivers?.[i]?.cardNumber,
      label: soldReceivers?.[i]?.cardNumber,
    };

    cardNumbers.push(dropdownList);
  }
  const handlerSubmitOpen = (e) => {
    //console.log(e);
    e.preventDefault();
    if (
      !customerInfo?.cardNumber 
    ) {
      showToastMessage("please provide required values");
      return;
    }

   
    let  cardNumber1 = customerInfo?.cardNumber;
    
    e.target.reset();
    // cancelCards(cardNumber1);
    //console.log(cardNumber1);
    setmodal_list(false);

    // showToastMessage("succefully opened card");
  };
  let getCustcardNumber = (id) => {
    setCustomerInfo(id);
 

   setCardNumber(id.cardNumber);

   //console.log(id.cardNumber)

 };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="TRANSFER RECEIVER" pageTitle="Billing" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">TRANSFER RECEIVER</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={() => tog_list()}
                            id="create-btn"
                          >
                            TRANSFER
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

              
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        {/* <ModalHeader className="bg-light p-3">
                    Assign To Employee
                    <Button
                        type="button"
                        onClick={() => {
                            setmodal_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </ModalHeader> */}

        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">TRANSFER RECEIVER</h5>

          <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>

        <form onSubmit={handlersubmit}>
          <ModalBody>
           
          
            <Row style={{ marginTop: "2%" }}>
            <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Receivers</label>
                  <Select
                    // className="bg-white"
                    options={cardNumbers}

                    // onChange={(e) => getCustcardNumber(e.target)}
                    onChange={(choice) =>
                      // getCustPhone(e.target.value, e.target)
                      getCustcardNumber(choice)
                    }
                    value={receiverId}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="macAddress-field" className="form-label">
                    Old Receiver<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="macAddress-field"
                    className="form-control"
                    placeholder="Old Card Number"
                    disabled
                    readOnly
                    // onChange={(e) => setnewMac(e.target.value)}
                    value={cardNumber}
                  />
                </div>
              </Col>

            </Row>

            <Row>
            <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="macAddress-field" className="form-label">
                    New Card Number<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="macAddress-field"
                    className="form-control"
                    placeholder="New Card Number"
                    required
                    onChange={(e) => setNewCardNumber(e.target.value)}
                    value={newCardNumber}
                  />
                </div>
              </Col>

            
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <ToastContainer />
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
             
              <button
                type="submit"
                className="btn btn-success"
                id="add-btn"
                onClick={handlersubmit}
              >
                Transfer
              </button>
            </div>
          </ModalFooter>
        </form>

        {/* =================================== */}
      </Modal>

      {/* Add Modal */}
      {/* Purchase Customer From */}


      {/* Remove Modal */}
    
    </React.Fragment>
  );
};

export default TransferReceiver;
