import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import Select from "react-select";

const PurchasesOrder = () => {
  const [tempTable, setTempTable] = useState([]);
  const [recTypes, setReceiverTypes] = useState();
  const [recNumber, setRecNumber] = useState();
  const [amou, setAmou] = useState();
  const [quantity, setQuantity] = useState();
  const [rate, setRate] = useState();
  const [preview, setPreview] = useState(false);
  const [serviceList, setServiceList] = useState([
    {
      itemId: "",
      quantity: "",
      unitPrice: "",
      amount: "",
      description: "",
      QtyAvailable: "",
    },
  ]);

  const [itemData, setitemData] = useState();

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: "white",

        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",

        // backgroundColor: isDisabled ? "red" : null,
      };
    },
  };

  ///local states

  const [todaydata, setTodayDate] = useState("");
  const [date, setDate] = useState("");

  const [amount, setAmount] = useState();
  const [additianalCost, setAdditianalCost] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [QtyAvailable, setQtyAvailable] = useState("");
  const [Qty, setQty] = useState("");
  const [modal_list, setmodal_list] = useState(false);
  const [yes, setYes] = useState(true);
  const [supplierId, setSupplierId] = useState("");
  const [storeId, setStoreId] = useState("");
  const [siteId, setSiteId] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [description, setdescription] = useState("");
  const [bo, setBo] = useState();

  const {
    suppliers,
    items,
    fetchItems,
    stores,
    fetchStores,
    sites,
    fetchSites,
    readProducts,
    products,
    registerPurchaseOrder,
    fetchQuantityAvailable,
    quantityAvailable,User
  } = useContext(LoginContex);
  const [qt, setQt] = useState(quantityAvailable.item_blance);

  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  let yess = yes === "true";
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    fetchItems();
    fetchStores();
    fetchSites();
    readProducts();
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;
    setTodayDate(today);
    fetchQuantityAvailable(recNumber?.value);
  }, [recNumber?.value]);

  //console.log(date);

  //console.log(qt);
  ///---------
  // const showToastMessage = () => {
  //   toast.success("succefully registered !", {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  // };
  ///date format
  let userId = User?.data?.user?._id || User?.user?._id;

  //console.log(userId);
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;

  //console.log(qt);

  document.title = "Purchase  ";
  const ty = tempTable.filter((t) => {
    return t.itemId === recNumber?.value;
  });
  // const amounts = serviceList[0].Qty;
  // //console.log(amounts);
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const addServiceTable = (e) => {
    if (!quantity || !rate) {
      showToastMessage("Please provide receiver details ");
      return;
    }
    // setRecNumber()

    if (ty.length > 0) {
      showToastMessage("duplicate items are not allowed");
      return;
    }
    setPreview(true);
    const data = {
      itemId: recNumber?.value,
      itemNamee: recNumber?.label,

      quantity: quantity,
      amount: quantity * rate,
      purchaseAmount,
      unitPrice: rate,
      QtyAvailable: quantityAvailable.item_blance,
    };
    //console.log(data);
    setTempTable([...tempTable, data]);

    // e.target.reset();
    setQuantity("");
    setRate("");
    document.getElementById("available").value = "";
    setRecNumber("");
  };
  const delCableInfo = (index) => {
    const list = [...tempTable];
    list.splice(index, 1);
    setTempTable(list);
  };
  serviceList.forEach((ca) => {
    for (const item in ca) {
      //console.log("h");
    }
  });

  const purchaseAmount = tempTable.reduce((accu, curr) => {
    return accu + curr["amount"];
  }, 0);

  const handleServiceChange = (e, index) => {
    const list = [...serviceList];

    if (e.target.name === "itemId") {
      fetchQuantityAvailable(serviceList[index]["itemId"]);
      //console.log(e.target.name);
      //console.log(serviceList[index]);
    }

    fetchQuantityAvailable(serviceList[index]["itemId"]);

    const { name, value } = e.target;
    list[index][name] = value;
    fetchQuantityAvailable(serviceList[index]["itemId"]);
    list[index]["QtyAvailable"] = quantityAvailable?.item_blance;

    list[index]["amount"] =
      serviceList[index]["quantity"] * serviceList[index]["unitPrice"];
    list[index]["purchaseAmount"] = purchaseAmount;
    if (e.target.name === "itemId") {
      fetchQuantityAvailable(serviceList[index]["itemId"]);
    }
  };

  //console.log(serviceList);
  //console.log(quantityAvailable.item_blance);
  // setServiceList([...serviceList,])

  // //console.log(purchaseAmount);

  const itemsSelect = [];
  //console.log(products);
  for (let i = 0; i < products.length; i++) {
    //console.log(products[i]._id);

    var dropdownList = {
      value: products[i]._id,
      label: ` ${products[i].itemName}`,
    };

    itemsSelect.push(dropdownList);
  }

  const data = {
    purchaseOrderDate: date,
    supplierId: supplierId.value,
    referenceNo: referenceNo,
    description: description,
    purchaseOrderAmount: purchaseAmount,
    purchaseOrderDetails: tempTable,
    userId
  };

  //console.log(userId);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!supplierId || !date || tempTable.length < 1) {
      showToastMessage("Please fill the required values");
      return;
    }

    registerPurchaseOrder(data);
    setTempTable([]);
    setDate("");
    setSupplierId("...Choose supplier...");
    setReferenceNo("");
    setdescription("");
    // setServiceList("");

    // e.target.reset();
    setTimeout(() => {}, 2000);

    serviceList.quantity = "";
    serviceList.unitPrice = "";
    document.forms["myForm"].reset();
    document.getElementById("available").value = "";
  };
  // soloving dropdowns
  // const suppliersArr = [{ label: "Choose item", value: "choose item" }];
  const suppliersArr = [];

  for (let i = 0; i < items.length; i++) {
    //console.log(items[i]._id);

    var dropdownList = {
      value: items[i]._id,
      label: items[i].supplierName,
    };

    suppliersArr.push(dropdownList);
  }

  //console.log(tempTable);
  const dupTrue = tempTable.includes(recNumber?.value);

  //console.log(ty);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Purchases Order " pageTitle="Purchase" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">
                            Add Purchases Order
                          </h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Link
                            to="/ManagePurchaseOrder"
                            className="text-white"
                          >
                            <Button
                              color="success"
                              className="add-btn me-1"
                              // onClick={() => tog_list()}
                              //  id="create-btn"
                            >
                              <i className=" ri-pencil-line align-bottom me-1"></i>{" "}
                              Manage Pending Orders
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchases Ordertable">
                    <form onSubmit={handleSubmit} id="myForm">
                      <ModalBody>
                        <div
                          className="mb-3"
                          id="modal-id"
                          style={{ display: "none" }}
                        >
                          <label htmlFor="id-field" className="form-label">
                            ID
                          </label>
                          <input
                            type="text"
                            id="id-field"
                            className="form-control"
                            placeholder="ID"
                            readOnly
                          />
                        </div>
                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>
                                Supplier <span className="text-danger">*</span>
                              </label>
                              <Select
                                className="bg-white"
                                required
                                options={suppliersArr}
                                onChange={(choice) => setSupplierId(choice)}
                                value={suppliersArr.filter(function (option) {
                                  return option.value === supplierId?.value;
                                })}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    // text: "orangered",
                                    // primary25: "hotpink",
                                    // primary: "black",
                                  },
                                })}
                              />
                            </div>
                          </Col>
                          <Col md={2}>
                            <div>
                              <Label
                                htmlFor="Purchases OrderDate"
                                className="form-label"
                              >
                                Purchase Date{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Flatpickr
                                className="form-control"
                                placeholder="DD/MM/YYYY"
                                required
                                options={{
                                  dateFormat: "d/m/Y",
                                }}
                                onChange={([date]) => setDate(date)}
                                value={date}
                              />
                            </div>
                          </Col>

                          <Col md={2}>
                            <div className="mb-3">
                              <label className="form-label">Reference No</label>
                              <input
                                type="text"
                                id="referenceno-field"
                                className="form-control"
                                placeholder="Enter Reference No"
                                onChange={(e) => setReferenceNo(e.target.value)}
                                value={referenceNo}
                              />
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="mb-3">
                              <label className="form-label">Description</label>
                              <textarea
                                className="form-control"
                                id="Description-field"
                                rows="1"
                                onChange={(e) => setdescription(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Row></Row>
                          <table
                            className="table align-middle table-nowrap"
                            id="purchase"
                          >
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col" style={{ width: "50px" }}></th> */}
                                <th className="sort" data-sort="item">
                                  Item Name
                                </th>
                                <th className="sort" data-sort="qty">
                                  Qty Avaliable
                                </th>
                                <th className="sort" data-sort="phone">
                                  Quantity
                                </th>
                                <th className="sort" data-sort="Date">
                                  Rate
                                </th>
                                <th className="sort" data-sort="amount">
                                  Amount
                                </th>
                                {/* <th className="sort" data-sort="amount">
                                  Discription
                                </th> */}
                                <th className="sort" data-sort="action">
                                  Actions
                                </th>
                              </tr>
                            </thead>

                            <tbody className="list form-check-all">
                              {/* {serviceList.map((service, index) => {
                              return ( */}
                              <tr className="">
                                {/* <td className="item">
                                  <div className="mb-3">
                                    <Select
                                      // className="bg-white"
                                      options={suppliersArr}
                                      onChange={(choice) =>
                                        setReceiverTypes(choice)
                                      }
                                      required
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                          ...theme.colors,
                                          // text: "orangered",
                                          // primary25: "hotpink",
                                          // primary: "black",
                                        },
                                      })}
                                    />
                                  </div>
                                </td> */}
                                <td className="qty">
                                  <div className="mb-3">
                                    <Select
                                      // className="bg-white"
                                      options={itemsSelect}
                                      onChange={(choice) =>
                                        setRecNumber(choice)
                                      }
                                      value={itemsSelect.filter(function (
                                        option
                                      ) {
                                        return (
                                          option.value === recNumber?.value
                                        );
                                      })}
                                      required
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                          ...theme.colors,
                                          // text: "orangered",
                                          // primary25: "hotpink",
                                          // primary: "black",
                                        },
                                      })}
                                    />
                                  </div>
                                </td>
                                <td className="qty">
                                  <div className="mb-3">
                                    <input
                                      type="number"
                                      name="cardNumber"
                                      className="form-control"
                                      placeholder="Card No"
                                      readOnly
                                      // onChange={(e) =>
                                      //   handleServiceChange(e, index)
                                      // }
                                      value={quantityAvailable.item_blance || 0}
                                      id="available"
                                    />
                                  </div>
                                </td>

                                <td className="qty">
                                  <div className="mb-3">
                                    <Input
                                      name="amount"
                                      type="number"
                                      className="form-control"
                                      onChange={(e) =>
                                        setQuantity(e.target.value)
                                      }
                                      value={quantity}
                                    />
                                  </div>
                                </td>
                                <td className="qty">
                                  <div className="mb-3">
                                    <Input
                                      name="rate"
                                      type="number"
                                      className="form-control"
                                      onChange={(e) => setRate(e.target.value)}
                                      value={rate}
                                    />
                                  </div>
                                </td>
                                <td className="qty">
                                  <div className="mb-3">
                                    <Input
                                      name="amount"
                                      type="number"
                                      className="form-control"
                                      // onChange={(e) => setAmou(e.target.value)}
                                      disabled
                                      value={rate * quantity}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-success mb-3 mx-3"
                                    onClick={(e) => addServiceTable(e)}
                                  >
                                    <i className="fa fa-plus"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            {preview && tempTable.length > 0 && (
                              <tbody className="list form-check-all">
                                {tempTable.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="Store_name">
                                        {" "}
                                        {data.itemNamee}{" "}
                                      </td>
                                      <td className="description">
                                        {" "}
                                        {data.QtyAvailable}{" "}
                                      </td>
                                      <td className="location">
                                        {" "}
                                        {data.quantity}{" "}
                                      </td>

                                      <td className="description">
                                        {" "}
                                        {data.unitPrice}{" "}
                                      </td>
                                      <td className="description">
                                        {" "}
                                        {data.amount}{" "}
                                      </td>

                                      <td>
                                        <div className="d-flex gap-2">
                                          <div className="remove">
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-danger remove-item-btn"
                                              data-bs-toggle="modal"
                                              data-bs-target="#deleteRecordModal"
                                              onClick={() =>
                                                delCableInfo(index)
                                              }
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            )}
                          </table>
                          <Col md={8}></Col>
                          <Col md={2}>
                            <div className="mb-3 pull-right">
                              <label className="form-label">
                                Purchase Amount
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="disabledInput"
                                defaultValue={purchaseAmount}
                                // onChange={(e) =>
                                //   setpurchaseAmount(e.target.value)
                                // }
                                value={purchaseAmount}
                                disabled
                              />
                            </div>
                          </Col>

                          <Col md={3}></Col>
                          <Row className="mt-5"></Row>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-success"
                            id="add-btn"
                          >
                            Save
                          </button>
                        </div>
                      </ModalFooter>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Purchase Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        {/* =================================== */}
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default PurchasesOrder;
