// import React, { useEffect, useState } from 'react';
// import ZKLib from 'zklib';

// const EnrollUser = () => {

//     useEffect(()=>{
//         handleEnroll()
//     },[])
//   const [name, setName] = useState('');
//   const [id, setId] = useState('');
//   const [status, setStatus] = useState('');

//   const handleEnroll = async () => {
//     const zk = new ZKLib({
//       ip: '192.168.1.77',
//       port: 4370,
//       inport: 5200,
//     });
//     await zk.connect();
//     const result = await zk.createUser({
//       uid: id,
//       name: name,
//     });
//     if (result) {
//       setStatus('Successfully enrolled user');
//     } else {
//       setStatus('Failed to enroll user');
//     }
//   };

//   return (
//     <div>
//       <input
//         type="text"
//         placeholder="Enter user name"
//         value={name}
//         onChange={(e) => setName(e.target.value)}
//       />
//       <input
//         type="text"
//         placeholder="Enter user ID"
//         value={id}
//         onChange={(e) => setId(e.target.value)}
//       />
//       <button onClick={handleEnroll}>Enroll user</button>
//       <p>{status}</p>
//     </div>
//   );
// };

// export default EnrollUser;
