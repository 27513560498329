import React, { useState, useEffect, useContext, useMemo } from "react";
import DataTable from "react-data-table-component";
import { Grid, _ } from "gridjs-react";
import Select from "react-select";
import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";

import Supplier from "../supplier";
const CustomerRegister = () => {
  //LOCAL states

  const [customerName, setCustomerName] = useState("");
  const [phooneNumber, setPhooneNumber] = useState("");
  const [distriictName, setDistriictName] = useState("");
  const [Line, setLine] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [homeNumber, setHomeNumber] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [totalCards, setTotalCards] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [id, set_ID] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [type, setType] = useState("");
  const [checked, setChecked] = useState(false);
  const {
    registerCustomer,
    fetchCustomers,
    customers,
    deleteCustomer,
    updateCustomer,
    isLoading,
    fetchDistricts,
    districts,
    User,
    HubiLine,
    FetchsTheCustomersReport,
    the_customers,
    isNewCableLoading,
    isChecking,bothcustomers,fetchBothCustomers
  } = useContext(LoginContex);

  const [modal_list, setmodal_list] = useState(false);
  const [modal_list2, setmodal_list2] = useState(false);
  const [modal_list3, setmodal_list3] = useState(false);
  const [modal_list4, setmodal_list4] = useState(false);

  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [helper, setHelper] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const tog_list2 = () => {
    setmodal_list2(!modal_list2);
  };
  const tog_list3 = () => {
    setmodal_list3(!modal_list3);
  };
  const tog_list4 = () => {
    setmodal_list4(!modal_list4);
  };

  const [modal_delete, setmodal_delete] = useState(false);

  let userId = User?.data?.user?._id || User?.user?._id;

  //console.log(userId);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const currentDate = new Date(); // Get current date
  const currentYear = currentDate.getFullYear(); // Get current year
  const currentMonth = currentDate.getMonth(); // Get current month
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1); // Create new Date object with the first day of current month
  // const startDate = firstDayOfMonth.toDateString(); // Convert the first day of current month to a string in the desired format
  const startDate = moment().startOf("month").format("YYYY-MM-DD");
  //console.log(startDate);
  const formatedDate = moment(startDate).format("YYYY-MM-DD");

  //console.log(formatedDate); // Output the start date of the current month in the console
  const maanta = new Date();
  const tariikhdamanta = moment(maanta[0]).format("YYYY-MM-DD");
  //console.log(tariikhdamanta);

  useEffect(() => {
    fetchCustomers();
    fetchDistricts();
    FetchsTheCustomersReport(startDate, tariikhdamanta);
  }, []);

  //console.log("type===============", type);
  document.title = "Customer Registration ";
  //register customer
  //console.log(isUpdating);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = checked == true ? bothcustomers?.filter(
    (item) =>
      (item?.customerName &&
        item?.customerName?.toLowerCase().includes(filterText.toLowerCase()) &&
        item.customerName?.toLowerCase().includes(filterText.toLowerCase()) &&
        item.customerPhone) ||
      item.customerPhone
        ?.toString()
        .toLowerCase()
        .includes(filterText.toLowerCase())
  ) :the_customers?.filter(
    (item) =>
      (item?.customerName &&
        item?.customerName?.toLowerCase().includes(filterText.toLowerCase()) &&
        item.customerName?.toLowerCase().includes(filterText.toLowerCase()) &&
        item.customerPhone) ||
      item.customerPhone
        ?.toString()
        .toLowerCase()
        .includes(filterText.toLowerCase())
  );
  //console.log(filteredItems);
  // //console.log(iptvCustomerRechargeData);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}>No</h6>,
      selector: (row,index) => index +1,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Customer Name</h6>,
      selector: (row) => row?.customerName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Phone</h6>,
      selector: (row) => row?.customerPhone,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Address</h6>,
      selector: (row) => row?.customerAddress,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> User</h6>,
      selector: (row) => row?.username,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Type</h6>,
      selector: (row) => row?.type,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Actions</h6>,
      cell: (row) => (
        <>
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item edit" title="Edit">
              <Link
                to="#"
                className="text-primary d-inline-block edit-item-btn"
                onClick={() => {
                  const customerData = row;
                  updateForm(customerData);
                }}
              >
                <i className="ri-pencil-fill fs-16"></i>
              </Link>
            </li>
            <li className="list-inline-item" title="Remove">
              <Link
                to="#"
                className="text-danger d-inline-block remove-item-btn"
                onClick={() => {
                  const customerData = row;
                  deleteCus(customerData);
                }}
              >
                <i className="ri-delete-bin-5-fill fs-16"></i>
              </Link>
            </li>
          </ul>
        </>
      ),
    },
  ];

  const filterThroughDate = () => {
    setHelper(true);
    const startt = moment(start).format("YYYY-MM-DD");
    const enddd = moment(end).format("YYYY-MM-DD");
    FetchsTheCustomersReport(startt, enddd);
  };

  const clearFilter = () => {
    setHelper(false);

    setStart("");
    setEnd("");
  };
  const handlerSubmit = (e) => {
    //console.log(districtId);
    e.preventDefault();
    if (!districtId?.value) {
      showWarningMessage("please choose district.")
      return;
    }
    if (!isUpdating) {
      const data = {
        customerAddress,
        customerName,
        customerPhone,
        customerTell: homeNumber,
        totalCards: 0,
        districtId: districtId?.value,
        userId,
        type,
      };

     
      //console.log("data________", data);
      registerCustomer(data);
      setmodal_list(false);
      //console.log(data);
    }
    if (isUpdating) {
      //console.log("updd");
      const data = {
        customerAddress,
        customerName,
        customerPhone,
        customerTell: homeNumber,
        totalCards: 0,
        id: id,
        districtId: districtId?.value,
      };
      //console.log(data);
      if (!customerName || !customerPhone) {
        return;
      }
      updateCustomer(data);
      setIsUpdating(false);
      setmodal_list(false);
      setCustomerAddress("");
      setCustomerName("");
      setCustomerPhone("");
      setHomeNumber("");
      setTotalCards("");
    }
  };

  //delet customer

  const deleteCus = (data) => {
    setmodal_delete(true);
    //console.log(data)
    set_ID(data._id  );
  };
  const deleteCustomerSuccess = () => {
    deleteCustomer(id);
    setmodal_delete(false);
  };

  //console.log(districts);
  const districtArr = [];

  for (let i = 0; i < districts.length; i++) {
    // //console.log(items[i]._id);

    var dropdownList = {
      value: districts[i]._id,
      label: districts[i].districtName,
    };

    districtArr.push(dropdownList);
  }
  // update form

  const updateForm = (data) => {
    //console.log("DATA FROM DB :-,", data);
    setmodal_list(true);
    setCustomerAddress(data.customerAddress);
    setCustomerName(data.customerName);
    setCustomerPhone(data.customerPhone);
    setHomeNumber(data.customerTell);
    setType(data.type);
    setIsUpdating(true);
    set_ID(data._id);
    setIsUpdating(true);
  };

  const addModal = () => {
    setType("Analog");
    tog_list();
    setIsUpdating(false);
    setCustomerAddress("");
    setCustomerName("");
    setCustomerPhone("");
    setTotalCards("");
  };
  const addModal3 = () => {
    setType("Redline");
    tog_list4();
    setIsUpdating(false);
    setCustomerAddress("");
    setCustomerName("");
    setCustomerPhone("");
    setTotalCards("");
  };
  const addModal2 = () => {
    setType("IPTV");
    tog_list2();
    setIsUpdating(false);
    setCustomerAddress("");
    setCustomerName("");
    setCustomerPhone("");
    setTotalCards("");
  };

  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const showWarningMessage = (message) => {
    toast.warning(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handleChangePhone = (e) => {
    const limit = 10;

    // Here we are checking if the length is equal to 9
    if (e.target.value.length === 10) {
      showToastMessage("Phone number should not exceed 10 digits!");
      setCustomerPhone(e.target.value.slice(0, limit));
    }
    setCustomerPhone(e.target.value.slice(0, limit));
  };
  const handleHomeNumberChange = (e) => {
    const limit = 6;

    // Here we are checking if the length is equal to 9
    if (e.target.value.length === 7) {
      showToastMessage("Home number should not exceed 6 digits!");
      setHomeNumber(e.target.value.slice(0, limit));
    }
    setHomeNumber(e.target.value.slice(0, limit));
  };

  const sortCustomer = [];
  for (let i = 0; i < customers?.length; i++) {
    let singleArr = [
      customers[i].customerName,
      customers[i].customerPhone,
      customers[i].customerAddress,
      customers[i]._id,
    ];

    sortCustomer.push(singleArr);
  }

  const columnss = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "customerName",
        filterable: false,
      },
      {
        Header: "Phone Number",
        accessor: "customerPhone",
        filterable: false,
      },
      {
        Header: "Address",
        accessor: "customerAddress",
        filterable: false,
      },
      {
        Header: "user",
        accessor: "username",
        filterable: false,
      },
      {
        Header: "Customer Type",
        accessor: "type",
        filterable: false,
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    updateForm(customerData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    deleteCus(customerData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ]
    // [handleCustomerClick]
  );
  const handleChecking = () => {
    const data = {
      customerAddress,
      customerName,
      customerPhone,
      lineNumber: homeNumber,
      districtName: districtId?.label,
    };
    HubiLine(data);
    //console.log(data);
  };

  //console.log(the_customers);
  const handleChange = () => {
    setChecked(!checked);
  };

  const handleChangee = (e)=>{
    //console.log(e.target.value)

    fetchBothCustomers(e.target.value)

  }

  const load = () => {
    if (isNewCableLoading) {
      return (
        <>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif"
                          style={{ backgroundColor: "white !", width: "700px" }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Customer Registration" pageTitle="Registration" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">Add Customer</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add New Analog
                          </Button>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal3}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add New Redline
                          </Button>
                          {/* <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal2}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add New IPTV
                          </Button> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Row className="g-4 mb-3">
                    {checked == false && (
                      <>
                        <Col md={3}>
                          <div>
                            <Label
                              htmlFor="PurchasesDate"
                              className="form-label"
                            >
                              Start Date
                            </Label>
                            <Flatpickr
                              className="form-control"
                              required
                              placeholder="DD/MM/YYYY"
                              options={{
                                dateFormat: "d/m/Y",
                                defaultDate: "today",
                              }}
                              onChange={([date]) => setStart(date)}
                              value={start}
                            />
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <Label
                              htmlFor="PurchasesDate"
                              className="form-label"
                            >
                              End Date
                            </Label>
                            <Flatpickr
                              className="form-control"
                              required
                              placeholder="DD/MM/YYYY"
                              options={{
                                dateFormat: "d/m/Y",
                                defaultDate: "today",
                              }}
                              onChange={([date]) => setEnd(date)}
                              value={end}
                            />
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="form-label">
                            <Button
                              color="success"
                              className=" add-btn mt-4"
                              onClick={filterThroughDate}
                              type="button"
                              //  id="create-btn"
                            >
                              <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                              Filter By Date{" "}
                            </Button>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="form-label">
                            <Button
                              color="success"
                              className=" add-btn mt-4"
                              onClick={clearFilter}
                              type="button"
                              //  id="create-btn"
                            >
                              <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                              Clear filter{" "}
                            </Button>
                          </div>
                        </Col>
                      </>
                    )}
                    {checked == true && (
                      <Col md={6}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Search by customer or phone
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            onChange={handleChangee}
                          />
                        </div>
                      </Col>
                    )}
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end pr-3">
                        <div>
                          <span
                            className="form-check form-switch form-switch-lg mb-3"
                            style={{ float: "right" }}
                          >
                            <span style={{ marginTop: "7%", float: "right" }}>
                              {checked ? "Search by Name" : "Search by Date"}
                            </span>

                            <Input
                              style={{ marginTop: "9%" }}
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="ISCash"
                              onChange={handleChange}
                              value={checked}
                            />
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* {load()} */}
                  <div id="customerList">
                    {/* {the_customers.length > 0 && (
                      <TableContainer
                        columns={columns}
                        data={the_customers || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"

                        // handleCustomerClick={handleCustomerClicks}
                        // isCustomerFilter={true}
                      />
                    )} */}

                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      progressPending={isNewCableLoading  }
                      progressComponent={<SpinnerBorder />}
                      persistTableHead
                    
                    />
                    <div className="table-responsive table-card mt-3 mb-1"></div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* customer Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">
            {" "}
            {isUpdating ? "update customer" : "Add New Customer"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="customer_name-field" className="form-label">
                    Customer <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="customer_name-field"
                    className="form-control"
                    placeholder="Enter Customer Name"
                    required
                    onChange={(e) => setCustomerName(e.target.value)}
                    value={customerName}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    Phone Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="phone-field"
                    className="form-control"
                    placeholder="Enter phone Number"
                    required
                    onChange={handleChangePhone}
                    value={customerPhone}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="homeNumber-field" className="form-label">
                    Home Number<span className="text-small"> Line Line</span>
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="homeNumber-field"
                    className="form-control"
                    placeholder="Enter home number"
                    required
                    onChange={handleHomeNumberChange}
                    value={homeNumber}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="address-field" className="form-label">
                    Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="address-field"
                    className="form-control"
                    placeholder="Enter Address"
                    onChange={(e) => setCustomerAddress(e.target.value)}
                    value={customerAddress}
                    required
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>
                    District <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={districtArr}
                    onChange={(choice) => setDistrictId(choice)}
                    required
                    
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                  />
                </div>
              </Col>
              {!isUpdating && (
                <Col md={4}>
                  <div>
                    <Label htmlFor="PurchasesDate" className="form-label">
                      TYPE <span className="text-danger">*</span>
                    </Label>

                    <select
                      //  defaultValue={"Analog"}
                      className="form-select"
                      id="itemtype"
                      defaultValue={"Analog"}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option disabled value="Analog">
                        Analog
                      </option>
                    </select>
                  </div>
                </Col>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isUpdating ? "Update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <Modal
        isOpen={modal_list4}
        toggle={() => {
          tog_list4();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">
            {" "}
            {isUpdating ? "update customer" : "Add New Customer"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list4(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="customer_name-field" className="form-label">
                    Customer <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="customer_name-field"
                    className="form-control"
                    placeholder="Enter Customer Name"
                    required
                    onChange={(e) => setCustomerName(e.target.value)}
                    value={customerName}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    Phone Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="phone-field"
                    className="form-control"
                    placeholder="Enter phone Number"
                    required
                    onChange={handleChangePhone}
                    value={customerPhone}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="homeNumber-field" className="form-label">
                    Home Number<span className="text-small"> Line Line</span>
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="homeNumber-field"
                    className="form-control"
                    placeholder="Enter home number"
                    required
                    onChange={handleHomeNumberChange}
                    value={homeNumber}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="address-field" className="form-label">
                    Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="address-field"
                    className="form-control"
                    placeholder="Enter Address"
                    onChange={(e) => setCustomerAddress(e.target.value)}
                    value={customerAddress}
                    required
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>
                    District <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={districtArr}
                    onChange={(choice) => setDistrictId(choice)}
                    required
                    
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                  />
                </div>
              </Col>
              {!isUpdating && (
                <Col md={4}>
                  <div>
                    <Label htmlFor="PurchasesDate" className="form-label">
                      TYPE <span className="text-danger">*</span>
                    </Label>

                    <select
                      //  defaultValue={"Redline"}
                      className="form-select"
                      id="itemtype"
                      defaultValue={"Redline"}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option disabled value="Redline">
                        Redline
                      </option>
                    </select>
                  </div>
                </Col>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list4(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isUpdating ? "Update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <Modal
        isOpen={modal_list2}
        toggle={() => {
          tog_list2();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">
            {" "}
            {isUpdating ? "update customer" : "Add New Customer"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list2(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="homeNumber-field" className="form-label">
                    Home Number<span className="text-small"> Line Line</span>
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="homeNumber-field"
                    className="form-control"
                    placeholder="Enter home number"
                    required
                    onChange={handleHomeNumberChange}
                    value={homeNumber}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-4">
                  <label>
                    Hubi <span className="text-danger">*</span>
                  </label>

                  {isChecking == true ? (
                    <button
                      disabled
                      type="button"
                      className="btn btn-success btn-load w-100"
                    >
                      <span className="d-flex align-items-center">
                        <span
                          className="spinner-border flex-shrink-0"
                          role="status"
                        >
                          <span className="visually-hidden">Checking...</span>
                        </span>
                        <span className="flex-grow-1 ms-2 text-center">
                          Checking...
                        </span>
                      </span>
                    </button>
                  ) : (
                    <Button
                      type="button"
                      className="btn btn-warning"
                      style={{ width: "100%" }}
                      onClick={() => handleChecking()}
                    >
                      Hubbi
                    </Button>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="customer_name-field" className="form-label">
                    Customer <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="customer_name-field"
                    className="form-control"
                    placeholder="Enter Customer Name"
                    onChange={(e) => setCustomerName(e.target.value)}
                    value={customerName}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    Phone Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="phone-field"
                    className="form-control"
                    placeholder="Enter phone Number"
                    onChange={handleChangePhone}
                    value={customerPhone}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="address-field" className="form-label">
                    Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="address-field"
                    className="form-control"
                    placeholder="Enter Address"
                    onChange={(e) => setCustomerAddress(e.target.value)}
                    value={customerAddress}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>
                    District <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={districtArr}
                    onChange={(choice) => setDistrictId(choice)}
                    // value={districtArr.filter(function (option) {
                    //   return option.value === districtId;
                    // })}
                    theme={(theme) => ({
                      ...theme,
                     
                   
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        
                       
                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={4}>
                <Label htmlFor="PurchasesDate" className="form-label">
                  TYPE <span className="text-danger">*</span>
                </Label>

                <select
                  defaultValue={"IPTV"}
                  className="form-select "
                  id="itemtype"
                  onChange={(e) => setType(e.target.value)}
                >
                  <option disabled value="IPTV">
                    IPTV
                  </option>
                </select>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list2(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isUpdating ? "Update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <Modal
        isOpen={modal_list3}
        toggle={() => {
          tog_list3();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Hubi Ino Leyahay IPTV</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list3(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <form>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="customer_name-field" className="form-label">
                    Line Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="customer_name-field"
                    className="form-control"
                    placeholder="Enter Line Number"
                    required
                    onChange={(e) => setLine(e.target.value)}
                    value={Line}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="customer_name-field" className="form-label">
                    Phone Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="customer_name-field"
                    className="form-control"
                    placeholder="Enter Line Number"
                    required
                    onChange={(e) => setPhooneNumber(e.target.value)}
                    value={phooneNumber}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <label htmlFor="customer_name-field" className="form-label">
                    District Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="customer_name-field"
                    className="form-control"
                    placeholder="Enter Line Number"
                    required
                    onChange={(e) => setDistriictName(e.target.value)}
                    value={distriictName}
                  />
                </div>
              </Col>

              {/* <Col md={6}>
                <div className="mb-4" style={{display:"flex", justifyContent:"center" , alignItems:"center"}}>
                
                 <Button type="button" className="btn btn-warning" style={{width:'100%'}}>Hubbi</Button>
                </div>
              </Col> */}
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list3(false)}
              >
                Close
              </button>

              {isLoading == true ? (
                <button
                  disabled
                  type="button"
                  className="btn btn-success btn-load w-100"
                >
                  <span className="d-flex align-items-center">
                    <span
                      className="spinner-border flex-shrink-0"
                      role="status"
                    >
                      <span className="visually-hidden">Checking...</span>
                    </span>
                    <span className="flex-grow-1 ms-2 text-center">
                      Checking...
                    </span>
                  </span>
                </button>
              ) : (
                <button type="submit" className="btn btn-success" id="add-btn">
                  Hubi
                </button>
              )}
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={deleteCustomerSuccess}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CustomerRegister;

const SpinnerBorder = () => {
  return <Spinner className="my-2 text-center" />;
};
