import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import DataTable from "react-data-table-component";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// action
import { registerUser, apiError, resetRegisterFlag } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useHistory } from "react-router-dom";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";

import { Grid, _ } from "gridjs-react";

const CreateUsers = () => {
  const history = useHistory();
  const [userName, setUsername] = useState();
  const [password, setPassword] = useState();
  const [role, setRole] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [employeeId, setEmployeeId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [changePassword, setChangePassword] = useState("No");
  const [showPassword, setShowPassword] = useState(true);
  const [modal_list, setmodal_list] = useState(false);
  const [id, setId] = useState("");
  const [checked, setChecked] = useState(true);
  const [state, setState] = useState(true);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  const dispatch = useDispatch();
  const {
    registerUser,
    name,
    GetByIdEmployee,
    getAllUsers,
    allUsers,
    getEmployee,
    fetchEmployees,
    isLoading,
    UpdateUser,User,userLoading
  } = useContext(LoginContex);
  //console.log(changePassword);
  const { error, registrationError, success } = useSelector((state) => ({
    registrationError: state.Account.registrationError,
    success: state.Account.success,
    error: state.Account.error,
  }));
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
    let userId = User?.data?.user?._id || User?.user?._id;
  const filteredItems = allUsers.filter(
    (item) =>
      item?.username &&
      item?.username?.toLowerCase().includes(filterText.toLowerCase()) ||
      item?.phoneNumber &&
      item?.phoneNumber?.toLowerCase().includes(filterText?.toLowerCase())
  );
  //console.log(filteredItems);
  //console.log(filterText);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    getAllUsers();
    fetchEmployees();
  }, []);

  //console.log(getEmployee);
  //console.log(allUsers);

  useEffect(() => {
    if (success) {
      setTimeout(() => history.push("login"), 3000);
    }

    setTimeout(() => {
      dispatch(resetRegisterFlag());
    }, 3000);
  }, [dispatch, success, error, history]);
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handleSubmit = (e) => {
    if (isEditing == false) {
      e.preventDefault();
      if (!userName || !password || !phoneNumber || !role || !employeeId) {
        showToastMessage("Please provide the required values");
      }

      const data = {
        username: userName,
        password: password,
        isAdmin: role == "Yes" ? true : false,
        empId: employeeId?.value,
         userId,
        phoneNumber: phoneNumber,
        userStatus:state
      };
      registerUser(data);
      setEmployeeId("");
      setState("")
      e.target.reset();
    }
    if (isEditing == true) {
      e.preventDefault();
      if (!userName || !phoneNumber || !role || !employeeId) {
        showToastMessage("Please provide the required values");
      }
      // if (changePassword == "Yes" && password.length > 2) {
      //   showToastMessage("Please provide the password");
      // }
      const data = {
        username: userName,
        password: password,
        isAdmin: role == "Yes" ? true : false,
        empId: employeeId?.value,
        phoneNumber: phoneNumber,
        state:state,
        userStatus: changePassword == "Yes" ? true : false,
        id: id,
      };
      UpdateUser(data);
      setState("")
      // setEmployeeId("");
      e.target.reset();
      //console.log(data);
      setmodal_list(false);
    }
  };
  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> Name</h6>,
      selector: (row) =>  row?.emp?.empName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Username</h6>,
      selector: (row) => row.username,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Is Admin</h6>,
      selector: (row) => (row.isAdmin == true ? "Yes" : "No"),
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Phone </h6>,
      selector: (row) => row.phoneNumber,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Status</h6>,
      selector: (row) => row.userStatus 
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Actions</h6>,
      cell: (row) => (
        <>
          <i
            style={{ cursor: "pointer" }}
            className="text-primary ri-pencil-fill fs-16 mx-3 fx-3"
            onClick={() => editUser(row)}
          ></i>{" "}
         
        </>
      ),

     
    },
    {
      
    },
  ];

  const Emp = [];

  for (let i = 0; i < getEmployee.length; i++) {
    // //console.log(setEmployeeId[i]._id);

    var dropdownList = {
      value: getEmployee[i]._id,
      label: ` ${getEmployee[i].empName}`,
    };

    Emp.push(dropdownList);
  }

  //console.log(Emp);

  const sortedUsers = [];
  for (let i = 0; i < allUsers?.length; i++) {
    let singleArr = [
      allUsers[i]?.emp?.empName,
      allUsers[i]?.username,
      allUsers[i].isAdmin == true ? "Yes" : "No",
      allUsers[i].phoneNumber,
      allUsers[i]._id,
    ];

    sortedUsers.push(singleArr);
  }
  const addModal = () => {
    tog_list();
    setEmployeeId("");
    setUsername("");
    setRole("");
    setPhoneNumber("");
    setChecked(true);
  };
  const editUser = (row) => {
    setIsEditing(true);
    tog_list();
    //console.log(row);
    //console.log(row?.emp?.empName);
    setEmployeeId({ lable: row?.emp?.empName, value: row?.emp?._id });
    setUsername(row?.username);
    setRole(row?.isAdmin == true ? "Yes" : "No");
    setPhoneNumber(row?.phoneNumber);
    setId(row._id);
  };

  const closeModalMuscab = () => {
    setmodal_list(false);
    setChangePassword("No");
    setIsEditing(false);
    setChecked(true);
  };

  const handleChange = () => {
    setChecked(!checked);
    setChangePassword(checked ? "Yes" : "No");
  };

  const activeUsers = allUsers.filter((user) => {
    return user.userStatus == "Active";
  });
  const inActiveUsers = allUsers.filter((user) => {
    return user.userStatus == "InActive";
  });


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Create Users" pageTitle="Setting" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        {<ToastContainer />}
                        <div>
                          <subHeaderComponentMemo />
                          <h4 className="card-title mb-0">Add User</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <h5>Active users = <b> [{activeUsers?.length}]</b>, Non Active Users <b>[{inActiveUsers?.length}]</b> </h5>
                  <Row className="g-4 mb-3"></Row>

                  <div id="customerList">
                    {allUsers.length > 0 && (
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        // selectableRows
                        persistTableHead

                        progressPending={userLoading  }
                        progressComponent={<SpinnerBorder />}
                        // sortFunction={customSort}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}

          <Modal
            isOpen={modal_list}
            toggle={() => {
              tog_list();
            }}
            centered
            size="lg"
            backdrop={"static"}
          >
            <div className="bg-light p-3 modal-header">
              <h5 className="modal-title">
                {" "}
                {isEditing ? "update User" : "Create User"}{" "}
              </h5>

              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  closeModalMuscab();
                }}
                aria-label="Close"
              ></button>
            </div>
          
            <form onSubmit={handleSubmit}>
              <ModalBody>
           
                <div className="mb-3" id="modal-id" style={{ display: "none" }}>
                  <label htmlFor="id-field" className="form-label">
                    ID
                  </label>
                  <input
                    type="text"
                    id="id-field"
                    className="form-control"
                    placeholder="ID"
                    readOnly
                  />
                </div>

                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <label className="form-label">
                        Employee <span className="text-danger">*</span>
                      </label>

                      <div>
                        <Select
                          // className="bg-white"
                          options={Emp}
                          onChange={(choice) => setEmployeeId(choice)}
                          value={Emp.filter(function (option) {
                            return option.value === employeeId?.value;
                          })}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                            },
                          })}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="username" className="form-label">
                        Username <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="username"
                        type="text"
                        placeholder="Enter username"
                        required
                        onChange={(e) => setUsername(e.target.value)}
                        value={userName}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <label className="form-label">
                        Role <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select "
                        id="itemtype"
                        required
                        onChange={(e) => setRole(e.target.value)}
                        value={role}
                      >
                        <option value="">&hellip; Is Admin ? &hellip;</option>
                        <option value="Yes"> Yes</option>
                        <option value="No"> No</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="Phone number" className="form-label">
                        Phone number
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="phoneNumber"
                        type="number"
                        placeholder="Enter Phone number"
                        required
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        value={phoneNumber}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <label className="form-label">
                        State <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select "
                        id="itemtype"
                        required
                        onChange={(e) => setState(e.target.value)}
                        value={state}
                      >
                   <option value="">&hellip; Choose an option &hellip;</option>
                        <option value="Active"> Active</option>
                        <option value="InActive"> InActive</option>
                      </select>
                    </div>
                  </Col>
                  {isEditing == false && (
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="userpassword" className="form-label">
                          Password <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </Col>
                  )}

                  {modal_list == true && isEditing == true && (
                    // <Col md={4}>
                    //   <div className="mb-3">
                    //     <Label htmlFor="userpassword" className="form-label">
                    //       Change password <span className="text-danger">*</span>
                    //     </Label>
                    //     <select
                    //       className="form-select "
                    //       id="inputGroupSelect04"
                    //       onChange={(e) => {
                    //         setChangePassword(e.target.value);
                    //         // setShowPassword(true);
                    //       }}
                    //       // value={chanlPckge}
                    //     >
                    //       <option value="Yes">Yes</option>
                    //       <option value="No" selected>
                    //         No
                    //       </option>
                    //     </select>
                    //   </div>
                    // </Col>
                    <Col md={4}>
                      <div
                        className="form-check form-switch form-switch-lg mb-3 mt-3"
                        style={{
                          display: "flex",
                          // flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ marginTop: "7%", float: "right" }}>
                          change password
                        </div>

                        <Input
                          style={{ marginTop: "9%" }}
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="ISCash"
                          onChange={(e) => {
                            // setChangePassword(e.target.value);
                            // //console.log(e.target);
                            // setShowPassword(true);
                            handleChange();
                          }}
                        />
                      </div>
                    </Col>
                  )}

                  {changePassword == "Yes" && (
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="userpassword" className="form-label">
                          Password <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </Col>
                  )}
                  {/* <Col md={4}>
                          <div className="mb-2">
                            <Label
                              htmlFor="confirmPassword"
                              className="form-label"
                            >
                              Confirm Password{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="confirm_password"
                              type="password"
                              placeholder="Confirm Password"
                              required
                            />
                          </div>
                        </Col> */}
                </Row>
              </ModalBody>

              <div className="mb-4"></div>

              <ModalFooter>
                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => closeModalMuscab()}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    id="add-btn"
                  >
                    {isEditing ? "Update" : "Save"}
                  </button>
                </div>
              </ModalFooter>
            </form>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateUsers;
const SpinnerBorder = () => {
  return <Spinner className="my-2 text-center" />;
};
