import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { Helmet } from "react-helmet";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link, useParams } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";
import logo from "./LogoTabaarak.png";
// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NewIptv = () => {
  // Flatpickr(".datepicker")

  const { id, name, phone, address, customerTelll, district, check } =
    useParams();
  const [serialNumber, setSerialNumber] = useState();
  const [macAddress, setMacAddress] = useState();
  const [tempTable, setTempTable] = useState([]);
  const [recTypes, setReceiverTypes] = useState();
  const [recNumber, setRecNumber] = useState();
  const [amou, setAmou] = useState();
  const [preview, setPreview] = useState(false);
  const [validate, setValidate] = useState(false);
  const [itemName, setItemName] = useState();
  const [temp2, setTemp2] = useState([]);
  const [quantity, setQuantity] = useState();
  const [customerInfo, setCustomerInfo] = useState("");
  const [clearPhone, setClearPhone] = useState(false);
  const [eachMonthBalance, setEachMonthBalance] = useState(1);
  const [cycle, setCycle] = useState("");
  let { the_id, type } = useParams();
  const [salesDate, setSalesDate] = useState();
  const [field, setField] = useState([]);
  const [salesType, setSalesType] = useState();
  const [rrr, setrr] = useState();
  const [description, setdescription] = useState("");
  const [typeId, setTypeId] = useState();
  const [customerId, setCustomerId] = useState("");
  const [cardNum, setCardNumber] = useState("");
  const [receiverNumber, setReceiverNumber] = useState("");
  const [registerDate, setRegisterDate] = useState();
  const [receiverType, setReceiverType] = useState("");
  const [th_channel, setTheChannel] = useState();

  const [sortBy, setsortBy] = useState(null);
  const [selectMulti, setselectMulti] = useState(null);
  const [cardsList, setCardLists] = useState();
  const [totalamount, settotalamount] = useState();
  const [discount, setdiscount] = useState();
  const [paidbalance, setpaidbalance] = useState();

  const [newCableList, setNewCableList] = useState([
    {
      chanlPckgeId: "",
    },
  ]);

  ///local states

  const [todaydata, setTodayDate] = useState("");
  const [date, setDate] = useState("");

  const [modal_list, setmodal_list] = useState(false);
  const [yes, setYes] = useState(true);
  const [referenceNo, setReferenceNo] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerTell, setCustomerTell] = useState("");
  const [custPhone, setCustPhone] = useState();
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [totalCards, setTotalCards] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [_id, set_ID] = useState("");
  const [lists, setLists] = useState([]);
  const [chanlPckge, setchanlPckge] = useState();
  const [amount, setamount] = useState();
  const [chanlPckgeName, setchanlPckgeName] = useState();
  const [TotalPurAmount, setTotalPurAmount] = useState();
  const [recieveramount, setrecieveramount] = useState();
  const [printdata, setprintdata] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(true);
  const [names, setNames] = useState([]);
  const [selecteditem, setselecteditem] = useState([]);
  const [truee, setTOtotrue] = useState();
  const [month, setMonth] = useState(1);
  const [clean, setClean] = useState(false);
  const [clean2, setClean2] = useState(false);
  const [cardNumbers, setCardNumbers] = useState("");
  const [Text, setsetText] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [isFree, setIsFree] = useState("true");
  const [quuuantity, setquuuantity] = useState("false");
  const [selectedOption, setSelectedOption] = useState("");
  const [custLien, setCustLine] = useState("");
  const [cName, setcName] = useState("");
  const [selectedValue, setSelectedValue] = useState("off");
  const {
    registerCustomer,
    fetchCustomers,
    customers,
    fetchRecievers,
    recievers,
    getByIdRecive,
    reciveById,
    registernewCable,
    fetchChanelPackages,
    channelPackage,
    readProducts,
    products,
    fetchQuantityAvailable,
    getByIdCardNumber,
    getCardNumbers,
    fetchIPTVUpdateById,
    IPTVEdit,
    itemGetAllReceiver,
    fetchReceiversType,
    itemsNotReceivers,
    FetchItemsNotReceivers,
    fetchItemById,
    ItemsName,
    getSingleCustomer,
    singleCustomer,
    FetchLastReceiverNumberAndLastCardNumber,
    fetchLastSerial,
    getByIdCustomers,
    getCustomersId,
    fetchCableInformation,
    cableInformation,
    itemsNotReceiversById,
    FetchItemsNotReceiversById,
    resetBillingReceivers,
    resetBillingItems,
    reset_the_cardnumber,
    fetchDistricts,
    iptvsOnly,
    districts,
    User,
    getIptvReceiversOnly,
    registernewIPTV,
    HubiLine,
    fetchCustomersWithIptvs,
    customerWithIptvs,
    isLoading,
    updatedCustomers,
    fetchAutoIPTVCustomers,
    iptvCustomer,
    isNewCableLoading,isChecking
  } = useContext(LoginContex);
  //console.log(id, name, phone);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  let yess = yes === "true";
  const [modal_delete, setmodal_delete] = useState(false);
  const [the_cardNumber, setthe_CardNumber] = useState();
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchAutoIPTVCustomers();
    setTOtotrue(false);
    fetchCustomers();
    setTodayDate(today);
    fetchRecievers();
    fetchChanelPackages();
    readProducts();
    setdiscount(0);
    fetchDistricts();
    FetchItemsNotReceiversById(itemName?.value);
    fetchReceiversType();
    fetchCustomersWithIptvs();
    FetchItemsNotReceivers();
    getByIdCustomers(customerInfo?.value);

    if (recTypes?.value && truee) {
      fetchCableInformation(recTypes?.value);
      // getByIdRecive(recTypes?.value);
    }
  }, [recTypes?.value, recNumber?.value, itemName?.value, customerInfo?.value]);

  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  //console.log(getCardNumbers);
  //console.log(itemName?.value);

  //console.log(cableInformation);
  //console.log(itemsNotReceiversById);
  ///date format
  let userId = User?.data?.user?._id || User?.user?._id;
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;

  // //console.log(date);
  let getMonthlyPayment = month > 1 && (Number(month) - 1) * 6;

  document.title = "Add new iptv";

  const amounts = newCableList[0].Qty;
  // //console.log(amounts);

  newCableList.forEach((ca) => {
    for (const item in ca) {
      // //console.log("h");
    }
  });

  //console.log(reciveById);
  const [serviceList, setServiceList] = useState([
    {
      recievertype: "",
      recievernumber: "",
      cardNumber: "",
      amount: "",
    },
  ]);

  const [ListItemInput, setListItemInput] = useState([{ Itemname: "" }]);

  const [serviceListProduct, setserviceListProduct] = useState([
    {
      itemId: "",
      quantity: "",
      unitPrice: "",
      amt: "",
    },
  ]);

  const onInputChange = (event, value, reason) => {
    //console.log(value);
    setthe_CardNumber(value);

    getIptvReceiversOnly(recTypes?.value, value);

    //console.log(reciveById);
  };
  //console.log(iptvsOnly);
  const makeItArray = [];

  for (let i = 0; i < iptvsOnly.length; i++) {
    // //console.log("from: " + formattedDate);
    let singleArr = {
      cardNum: iptvsOnly[i]?.cardNumber,
    };

    makeItArray.push(singleArr);
  }
  //console.log(makeItArray);
  const handleChange = () => {
    setChecked(!checked);
  };
  const handleChange2 = () => {
    setChecked2(!checked2);
  };
  const handleChange3 = () => {
    setChecked3(true);
  };

  //console.log(itemsNotReceivers);

  let test1 = 100;
  const receiverTotalAmount = tempTable?.reduce((accu, curr) => {
    return accu + Number(curr["amount"]);
  }, 0);

  //console.log(receiverTotalAmount);

  //console.log(month);

  let getCustPhone = (id, mobile) => {
    setCustomerId(id);
    getSingleCustomer(id);
    setCustPhone(mobile[mobile.selectedIndex].getAttribute("data-Moble"));
  };

  let getRecive = (id) => {
    setReceiverType(id);
    // getByIdRecive(id);
    // getIptvReceiversOnly(id)
  };

  let getCardNo = (id, cardNo) => {
    setReceiverNumber(id);
    setCardNumber(cardNo[cardNo.selectedIndex].getAttribute("data-cardNumber"));
  };

  // let getPackageId = (id, Package) => {
  //   setchanlPckge(id);
  //   // setReceiverNumber(id)
  //   setchanlPckgeName(
  //     Package[Package.selectedIndex].getAttribute("data-PackageName")
  //   );
  // };

  //console.log(chanlPckge);

  const purchaseAmount = serviceListProduct.reduce((accu, curr) => {
    return accu + Number(curr["amt"]);
  }, 0);

  const purchaseAmountReciever = tempTable.reduce((accu, curr) => {
    return accu + Number(curr["amount"]);
  }, 0);

  //console.log(purchaseAmountReciever);

  // const totalAmount =
  // (Number(purchaseAmount) +  Number(purchaseAmountReciever));

  // //console.log(totalAmount);

  const gettotalAmount = () => {
    setTotalPurAmount(parseInt(purchaseAmount + purchaseAmountReciever));
  };
  const handleChangeAmount = (event) => {
    setrecieveramount(event.target.value);

    // const { name, value } = e.target;
    const list = [...serviceList];

    list["cardNumber"] = cardNum;
    list["amount"] = recieveramount;

    // //console.log('value is:', event.target.value);
  };

  //console.log(IPTVEdit);
  // //console.log(recieveramount);

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;

    // //console.log(e.target.name);
    // list[index]["amount"] = list[index]["amount"] + list[index]["amount"];

    // list[index]["purchaseAmountReciever"] = purchaseAmountReciever;

    if (e.target.name === "amount") {
      setamount(purchaseAmountReciever);
    }

    if (e.target.name === "recievertype") {
      getRecive(e.target.value);
    }

    if (e.target.name === "amount") {
      serviceList[index]["amount"] = parseInt(e.target.value);
    }

    if (e.target.name === "recievernumber") {
      getCardNo(e.target.value, e.target);
    }

    // list[index]["cardNumber"] =
    //   e.target[e.target.selectedIndex].getAttribute("data-cardNumber");

    // //console.log(serviceList);
  };
  // //console.log(purchaseAmountReciever);

  const handleServiceChangeProduct = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceListProduct];

    list[index][name] = value;
    list[index]["amt"] = list[index]["quantity"] * list[index]["unitPrice"];

    // list[index]["purchaseAmount"] = purchaseAmount;
    setNewCableList(list);

    var selectedOption = e.target.selectedOptions[0];
    //console.log(selectedOption.value); // 123
    //console.log(selectedOption.text); // TEXT

    setselecteditem(selectedOption.text);
    // setselectedCable(selectedOption.text);
    // setselectedRemote(selectedOption.text);

    if (e.target.name === "itemId") {
      fetchQuantityAvailable(serviceListProduct[index]["itemId"]);

      //console.log(list[index]["itemId"]);
      fetchItemById(list[index]["itemId"]);

      setListItemInput([...ListItemInput, selectedOption.text]);

      //console.log(ListItemInput);
    }

    if (e.target.name === "recievertype") {
      getRecive(e.target.value);
    }

    if (e.target.name === "recievernumber") {
      getCardNo(e.target.value, e.target);
    }

    gettotalAmount();
  };

  const handleServiceChangeBalance = (e, index) => {
    const { name, value } = e.target;

    // //console.log(e.target.name);
    // list[index]["amount"] = list[index]["amount"] + list[index]["amount"];

    // list[index]["purchaseAmountReciever"] = purchaseAmountReciever;

    if (e.target.name === "disc") {
      //console.log("hey");
      gettotalAmount();
      setdiscount(e.target.value);

      let paid = parseInt(paidbalance);
      //console.log(discount);
      //console.log(paid);
      // let totalbaln = totlpur - paid;
      settotalamount(
        Number(purchaseAmount) +
          Number(purchaseAmountReciever) -
          Number(paid) -
          Number(discount)
      );
    }

    if (e.target.name === "balancepaid") {
      gettotalAmount();
      setpaidbalance(e.target.value);
      let grandttl = TotalPurAmount;
      let dis = parseInt(discount);
      let totlpur = grandttl - dis;
      let paid = parseInt(e.target.value);
      let totalbaln = totlpur - paid;

      settotalamount(
        Number(purchaseAmount) +
          Number(purchaseAmountReciever) -
          Number(paid) -
          Number(discount)
      );
    }
  };

  const totalAmount =
    Number(purchaseAmount) +
    Number(purchaseAmountReciever) -
    Number(paidbalance) -
    Number(discount);

  const getbalance = (e) => {
    gettotalAmount();
    setpaidbalance(e);
    let grandttl = TotalPurAmount;
    let dis = parseInt(discount);
    let totlpur = grandttl - dis;
    let paid = parseInt(e);
    let totalbaln = totlpur - paid;

    //console.log(dis);
    if (dis == "" || dis == 0) {
      settotalamount(parseInt(purchaseAmount + purchaseAmountReciever) - paid);
    } else {
      settotalamount(
        parseInt(purchaseAmount + purchaseAmountReciever) - dis - paid
      );
    }
  };
  //console.log(checked2);

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (!date) {
    //   showToastMessage("Please provide register date");
    //   return;
    // }
    if (!selectedOption?.customers && !name) {
      showToastMessage("Please provide customer name");
      return;
    }
    if (!month) {
      showToastMessage("Please provide the duration");
      return;
    }
    // if (!check && !checked2) {
    //   showToastMessage("Customer has no internet");
    //   return;
    // }

    if (checked == false) {
      let totalbill = parseInt(purchaseAmount + purchaseAmountReciever);
      const Data = {
        registrationDate: date,
        customerId: selectedOption?._id || id,
        salesAgentType: "Site",
        typeId: "6342ca87ded7524330e4df1a",
        billAmount: totalbill,
        discount: discount,
        balanceToPay: Number(paidbalance),
        isTrail: yes,
        // duration: month,
        isCash: null,
        cashAccount: null,
        phoneNumber: selectedOption?.customerPhone || phone,
        channelPackageId: th_channel,
        userId,
        receiverDetails: tempTable,
        itemDetails: temp2,
        serialNumber: serialNumber,
        macAddress: macAddress,
        customerName: selectedOption?.customers || name,
        wardo: 1,
        address: selectedOption?.customerAddress,
        district: selectedOption?.districtName || "Hodan",
        districtId: selectedOption?.districtId || district,
        customerTell: selectedOption.customerTell || customerInfo?.customerTells,
        duration: cycle ? cycle : "27",
        isGroup:selectedValue == "on" ? true :false,
        cName:cName,
        line: selectedValue == "on" && custLien || ""

      };
     
      // setValidate(false);
      setChecked2(false);
      registernewIPTV(Data);
      setClean(true);
      e.target.reset();
      //console.log(Data);
      setTemp2([]);
      setTempTable([]);
      setCustomerId("");
      setCustPhone("");
      setchanlPckge("");
      setpaidbalance("");
      setdiscount("");
      setReceiverTypes("");
      setItemName("");
      setDate("");
      resetBillingReceivers();
      resetBillingItems();
      setMonth("1");
      setMacAddress("");
      setSerialNumber("");
      setSelectedOption("");
      setcName("")
      setCustLine("")
      setSelectedValue("off")


      // cancelCourse();
    } else {
      let totalbill = parseInt(purchaseAmount + purchaseAmountReciever);
      const Data = {
        registrationDate: date,
        customerId: customerInfo.value || id,
        salesAgentType: "Site",
        typeId: "6342ca87ded7524330e4df1a",

        billAmount: totalbill,
        discount: discount,
        balanceToPay: Number(paidbalance),
        isTrail: yes,
        // duration: month,
        isCash: null,
        userId,
        cashAccount: null,
        phoneNumber: getCustomersId?.customerPhone || phone,
        channelPackageId: th_channel,

        receiverDetails: tempTable,
        itemDetails: temp2,
        serialNumber: serialNumber,
        macAddress: macAddress,
        customerName: customerInfo?.label || name,
        wardo: 1,
        address: address,
        district: customerInfo?.districtName || "Hodan",
        districtId: customerInfo?.districtId || district,
        customerTell: customerTelll || customerInfo?.customerTells,
        duration: cycle ? cycle : "27",
      };

      // setValidate(false);
      registernewIPTV(Data);
      setClean(true);
      e.target.reset();
      //console.log(Data);
      setTemp2([]);
      setTempTable([]);
      setCustomerId("");
      setCustPhone("");
      setchanlPckge("");
      setpaidbalance("");
      setdiscount("");
      setReceiverTypes("");
      setItemName("");
      setDate("");
      resetBillingReceivers();
      resetBillingItems();
      setMonth("1");
      setMacAddress("");
      setSerialNumber("");

      setTimeout(() => printer(), 1000);
      // cancelCourse();
    }
  };

  const districtArr = [];

  for (let i = 0; i < districts.length; i++) {
    // //console.log(items[i]._id);

    var dropdownList = {
      value: districts[i]._id,
      label: districts[i].districtName,
    };

    districtArr.push(dropdownList);
  }
  // up

  const handlerSubmit = (e) => {
    e.preventDefault();
    if (!cycle) {
      showToastMessage("please provide required values");
      return;
    }
    const data = {
      customerName,
      customerPhone,
      customerAddress,
      districtId: districtId?.value,
      customerTell: customerTell,
    };
    registerCustomer(data);
    setCustomerName("");
    setCustomerPhone("");
    setCustomerAddress("");
    setDistrictId("");
    setCustomerTell("");
    setmodal_list(false);
  };

  // //console.log(yes);

  const totolOfTheItems = temp2.reduce((curr, acuu) => {
    return curr + acuu["amount"];
  }, 0);

  let grandTotal =
    Number(totolOfTheItems) + Number(receiverTotalAmount) + getMonthlyPayment;
  //console.log(totolOfTheItems);
  //console.log(grandTotal);
  const ty = tempTable.filter((t) => {
    return t.receiverCardNumber === the_cardNumber;
  });

  const addServiceTable = (e) => {
    if (
      !cableInformation?.sellingPrice?.[0]?.sellingPrice ||
      !cableInformation?.lastSerial?.[0]?.cardNumber ||
      !cableInformation?.lastSerial?.[0]?.receiverSerialNumber
    ) {
      showToastMessage("Please provide receiver details ");
      return;
    }
    setPreview(true);
    if (ty.length > 0) {
      showToastMessage("Duplicates are not allowed");
      return;
    }
    if (!recTypes.value || !recTypes?.label) {
      showToastMessage("provide the receiver name");
      return;
    }
    // if (!the_cardNumber) {
    //   showToastMessage("provide the card numbers");
    //   return;
    // }
    setReceiverTypes("");
    const data = {
      receiverType: recTypes?.value,
      receiverNumber: cableInformation?.lastSerial?.[0]?.receiverSerialNumber,
      amount: cableInformation?.sellingPrice?.[0]?.sellingPrice * 1,
      receiverCardNumber: the_cardNumber,
      recTyp: recTypes.label,
      recNumberLabel: cableInformation?.lastSerial?.[0]?.receiverSerialNumber,
    };
    //console.log(data);
    setTempTable([...tempTable, data]);
    setReceiverTypes("");
    setCardNumbers("");
    setquuuantity("");
    setClean(true);
    reset_the_cardnumber();
  };
  //console.log(itemsNotReceiversById);
  const ty2 = temp2.filter((t) => {
    return t.itemIdd === itemName?.value;
  });

  //console.log(temp2);
  //console.log(itemName);
  const addServiceTable2 = (e) => {
    if (ty2.length > 0) {
      showToastMessage("Duplicates are not allowed");
      return;
    }
    if (!quantity || !itemsNotReceiversById?.[0]?.sellingPrice) {
      showToastMessage("Please provide the item details");
      return;
    }
    if (itemsNotReceiversById?.[0]?.available < 1) {
      showToastMessage(
        "the available quantity can not be less than zero or equels to zero"
      );
      return;
    }

    if (quantity > itemsNotReceiversById?.[0]?.available) {
      showToastMessage("quantity can not be greater than the available items");
      return;
    }

    const data = {
      itemIdd: itemName.value,
      itemId: itemsNotReceiversById?.[0]?._id,
      itemLabel: itemName.label,
      quantity: Number(quantity),
      availableQuantity: itemsNotReceiversById?.[0]?.available,

      unitPrice: itemsNotReceiversById?.[0]?.sellingPrice,
      amount: quantity * itemsNotReceiversById?.[0]?.sellingPrice,
    };
    //console.log(data);
    setTemp2([...temp2, data]);
    setQuantity("");
    setClean2(true);
    setItemName("");
  };
  // //console.log(tempTable);

  const delCableInfo = (index) => {
    const list = [...tempTable];
    list.splice(index, 1);
    setTempTable(list);
  };
  const delCableInfo2 = (index) => {
    const list = [...temp2];
    list.splice(index, 1);
    setTemp2(list);
  };

  const togglePreview = () => {
    setPreview(!preview);
  };

  const suppliersArr = [];
  //console.log(itemGetAllReceiver);
  for (let i = 0; i < itemGetAllReceiver.length; i++) {
    // //console.log(itemGetAllReceiver[i]._id);
    if (itemGetAllReceiver?.[i]?.itemName == "IPTV Receiver") {
      var dropdownList = {
        value: itemGetAllReceiver[i]._id,
        label: ` ${itemGetAllReceiver[i].itemName}`,
      };

      suppliersArr.push(dropdownList);
    }
  }
  const receiverNumbers = [];

  const CustomersArr = [];

  for (let i = 0; i < customerWithIptvs.length; i++) {
    // //console.log(customerWithIptvs[i]._id);
    // if(customerWithIptvs[i].customerTell.length == 6){
    // //console.log(customerWithIptvs[i].customerTell.toString().length);
    // if(customerWithIptvs[i].customerTell.toString().length ==6){
    var dropdownList = {
      value: customerWithIptvs[i]._id,
      label: `${customerWithIptvs[i].customerName} -- ${customerWithIptvs[i].customerTell}`,
      customerTells: `${customerWithIptvs[i].customerTell}`,
      districtId: `${customerWithIptvs[i]?.districtId}`,
      districtName: `${customerWithIptvs[i]?.districtName}`,
    };

    CustomersArr.push(dropdownList);
    // }
  }
  // }
  // const receiverNumbers = [];
  //console.log(customerWithIptvs);
  const itemsss = [];

  for (let i = 0; i < ItemsName.length; i++) {
    //console.log(ItemsName[i]._id);

    var ItemNames = {
      label: ` ${ItemsName[i].itemName}`,
    };

    itemsss.push(ItemNames);
  }

  //console.log(ItemsName.itemName);
  //console.log(ItemsName);

  for (let i = 0; i < reciveById.length; i++) {
    // //console.log(reciveById[i]._id);

    var dropdownList = {
      value: reciveById[i]._id,
      label: reciveById[i].receiverSerialNumber,
      amount: reciveById[i].amount,
    };

    receiverNumbers.push(dropdownList);
  }

  //console.log(itemsNotReceivers);

  const sortedProducts = [];
  for (let i = 0; i < itemsNotReceivers.length; i++) {
    // //console.log(itemsNotReceivers[i]._id);

    var dropdownList = {
      value: itemsNotReceivers[i]._id,
      label: itemsNotReceivers[i].itemName,
      amount: itemsNotReceivers[i].sellingPrice,
    };

    sortedProducts.push(dropdownList);
  }
  const cardNumberss = [];
  for (let i = 0; i < reciveById.length; i++) {
    // //console.log(itemsNotReceivers[i]._id);

    var dropdownList = {
      value: reciveById[i]?._id,
      label: reciveById[i]?.cardNumber,
    };

    cardNumberss.push(dropdownList);
  }
  //console.log();
  const printer = () => {
    let myStyle = '<link rel="stylesheet" href="./demo" />';
    let printContents = document.getElementById("divcontents").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = myStyle + printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  // //console.log(eachMonthBalance);

  // //console.log(getMonthlyPayment);
  //console.log(check);
  //console.log(isFree);
  const getOptionSelected = (option, value) => {
    //console.log(option);
    //console.log(value);
    if (option && value) {
      return option.id === value.id;
    }
    return false;
  };
  //console.log(getOptionSelected());
  //console.log(selectedOption);
  const onInputChange2 = (event, value, reason) => {
    //console.log(value);
    //console.log(event);
    fetchAutoIPTVCustomers(value);

    const matchingOption = makeItArray2.find(
      (option) =>
        option.customers.toLowerCase() ==
        value.substring(0, value.indexOf("-")).toLowerCase()
    );

    setSelectedOption(matchingOption);
  };

  const makeItArray2 = [];
  //console.log(iptvCustomer);
  for (let i = 0; i < iptvCustomer?.length; i++) {
    // //console.log("from: " + formattedDate);
    let singleArr = {
      customers: iptvCustomer[i]?.customerName,
      customerPhone: iptvCustomer[i]?.customerPhone,
      _id: iptvCustomer[i]?._id,
      customerAddress: iptvCustomer[i]?.customerAddress,
      districtName: iptvCustomer[i]?.districtName,
      customerTell: iptvCustomer[i]?.customerTell,
      districtName: iptvCustomer[i]?.districtName,
      districtId: iptvCustomer[i]?.districtId,
      customerAddress: iptvCustomer[i]?.customerAddress,
    };

    makeItArray2.push(singleArr);
  }
  //console.log(makeItArray2);

  const handleChecking = ()=>{

    if(!selectedOption){
      return showToastMessage("Please fill the customer info First!!")
    }

    const data = {
      customerAddress :selectedOption.customerAddress,
      customerName:selectedOption.customerName,
      customerPhone :selectedOption.customerPhone,
      lineNumber: custLien, // * this is defferent **//
      districtName: selectedOption.districtName,
    }
    
    HubiLine(data)

  }
  
  const handleRadioChange = (event) => {
    //console.log(event.target.value);
    setSelectedValue(event.target.value);
    if(event.target.value =="on"){
      // toggle={() => {
      //     tog_list();
      //   }}
      if(!selectedOption) return showToastMessage("Select customer first")
      setmodal_list(true);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <script
            src="https://code.jquery.com/jquery-3.5.1.js"
            type="text/javascript"
          />
          <script
            src="https://cdn.datatables.net/1.12.1/js/jquery.dataTables.min.js"
            type="text/javascript"
          />
          <script
            src="https://cdn.datatables.net/buttons/2.2.3/js/dataTables.buttons.min.js"
            type="text/javascript"
          />
          <script
            src="https://cdn.datatables.net/buttons/2.2.3/js/buttons.print.min.js"
            type="text/javascript"
          />
          <script
            src="https://cdn.datatables.net/buttons/2.2.3/js/buttons.colVis.min.js"
            type="text/javascript"
          />
        </Helmet>
        <Container fluid>
          <BreadCrumb title="New IPTV" pageTitle="Billing" />
          <Row>
            <Card>
              <CardHeader>
                <Row className="g-4 mb-3">
                  <Col className="col-sm-auto">
                    <div>
                      <div>
                        <ToastContainer />
                        <h4 className="card-title mb-0">Add New IPTV Cables</h4>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-sm">
                    <div className="d-flex justify-content-sm-end">
                      <div>
                        <Link to="/ManageCables" className="text-white">
                          <Button
                            color="success"
                            className="add-btn me-1"
                            // onClick={() => tog_list()}
                            //  id="create-btn"
                          >
                            <i className=" ri-pencil-line align-bottom me-1"></i>
                            Manage Cables
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <iframe
                  id="ifmcontentstoprint"
                  style={{ height: "0px", width: "0px", position: "absolute" }}
                ></iframe>
                <form onSubmit={handleSubmit} id="FormControl">
                  <div
                    className="mb-3"
                    id="modal-id"
                    style={{ display: "none" }}
                  >
                    <label htmlFor="id-field" className="form-label">
                      ID
                    </label>
                    <input
                      type="text"
                      id="id-field"
                      className="form-control"
                      placeholder="ID"
                      readOnly
                    />
                  </div>
                  <card>
                    <CardHeader style={{ color: "grey", fontSize: "1rem" }}>
                      Customer Information
                      
                        <span>
                      
                      <div className="d-flex justify-content-sm-end">
                        <div className="live-preview mx-5">
                          <div className="hstack gap-2 flex-wrap">
                        
                            <Input
                              type="radio"
                              className="btn-check"
                              name="options-outlined"
                              id="success-outlined"
                              defaultChecked
                              value={"off"}
                              onChange={handleRadioChange}
                            />
                            <Label
                              className="btn btn-outline-success"
                              htmlFor="success-outlined"
                            >
                              Has Group : No
                            </Label>

                            <Input
                              type="radio"
                              className="btn-check"
                              name="options-outlined"
                              id="danger-outlined"
                              value={"on"}
                              
                              onChange={handleRadioChange}
                            />
                            <Label
                              className="btn btn-outline-danger"
                              htmlFor="danger-outlined"
                            >
                              Has Group : Yes
                            </Label>
                          </div>
                        </div>
                      </div>
                     
                  </span>
                     
                        
                      
                    
                    </CardHeader>

                    <CardBody>
                      <Row className="mb-4">
                        <Col md={3} className="d-none">
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Register Date <span className="text-danger">*</span>
                          </Label>
                          <Flatpickr
                            className="form-control"
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setDate(date)}
                            value={date}
                          />
                        </Col>

                        {!name && (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Customer <span className="text-danger">*</span>
                              </Label>
                              <Autocomplete
                                disabled={isLoading}
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                options={makeItArray2}
                                getOptionSelected={getOptionSelected}
                                getOptionLabel={(option) => {
                                  return `${option.customers?.toString()}-${option.customerPhone?.toString()}`;
                                }}
                                value={selectedOption}
                                onInputChange={onInputChange2}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Search Customer..."
                                  />
                                )}
                              />
                            </div>
                          </Col>
                        )}
                        {name && (
                          <Col md={3}>
                            <label className="form-label">Phone Number</label>{" "}
                            <span className="text-danger">*</span>
                            <input
                              type="text"
                              id="referenceno-field"
                              className="form-control"
                              placeholder="Explicit Phone Number"
                              onChange={(e) => setCustPhone(e.target.value)}
                              value={name}
                              // defaultValue={getCustomersId.customerPhone}
                              disabled
                            />
                          </Col>
                        )}

                        <Col md={3}>
                          <label className="form-label">Phone Number</label>{" "}
                          <span className="text-danger">*</span>
                          <input
                            type="text"
                            id="referenceno-field"
                            className="form-control"
                            placeholder="Explicit Phone Number"
                            onChange={(e) => setCustPhone(e.target.value)}
                            value={selectedOption?.customerPhone || phone}
                            // defaultValue={getCustomersId.customerPhone}
                            disabled
                          />
                        </Col>
                       

                        <Col md={3}>
                          <div>
                            <label
                              htmlFor="ReOrderLevel-field"
                              className="form-label"
                            >
                              Duration
                            </label>{" "}
                            <span className="text-danger">*</span>
                            <select
                              className="form-select "
                              id="inputGroupSelect04"
                              onChange={(e) => {
                                setCycle(e.target.value);
                              }}
                            >
                              <option value="27">1 month</option>
                              <option value="28">3 months</option>
                              <option value="29">6 months</option>
                              <option value="30">1 Year</option>
                            </select>
                          </div>
                        </Col>
                        {/* <Col md={3}>
                          <div>
                            <label
                              htmlFor="ReOrderLevel-field"
                              className="form-label"
                            >
                              channel package
                            </label>{" "}
                            <span className="text-danger">*</span>
                            <select
                              className="form-select "
                              id="inputGroupSelect04"
                              onChange={(e) => setTheChannel(e.target.value)}
                              // value={chanlPckge}
                            >
                              <option value="">--- Choose Receiver ---</option>

                              {channelPackage.map((Package, index) => (
                                <option
                                  key={index}
                                  value={Package._id}
                                  data-PackageName={Package.channelPackageName}
                                >
                                  {Package.channelPackageName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col> */}
                      </Row>
                      <Row className="my-4 mt-5">
                        <Col md={6}>
                          <label className="form-label">Mac address</label>{" "}
                          <span className="text-danger">*</span>
                          <input
                            type="text"
                            id="referenceno-field"
                            className="form-control"
                            placeholder="Enter Mac Address"
                            onChange={(e) => setMacAddress(e.target.value)}
                            value={macAddress}
                            // defaultValue={getCustomersId.customerPhone}
                          />
                        </Col>
                        <Col md={6}>
                          <label className="form-label">Serial Number</label>{" "}
                          <span className="text-danger">*</span>
                          <input
                            type="text"
                            id="referenceno-field"
                            className="form-control"
                            placeholder="Enter Serial Number"
                            onChange={(e) => setSerialNumber(e.target.value)}
                            value={serialNumber}
                            // defaultValue={getCustomersId.customerPhone}
                          />
                        </Col>
                        <Col md={6} className="mt-5">
                          <Label htmlFor="PurchasesDate" className="form-label">
                            is Free? <span className="text-danger">*</span>
                          </Label>

                          <select
                            className="form-select "
                            id="itemtype"
                            onChange={(e) => setIsFree(e.target.value)}
                          >
                            <option value="">
                              &hellip; Choose sales receiver &hellip;
                            </option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select>
                        </Col>
                      </Row>
                    </CardBody>
                  </card>

                  {isFree == "false" && (
                    <card>
                      <CardHeader>
                        <Row className="g-4">
                          <Col className="col-sm-auto">
                            <div>
                              <div style={{ color: "grey", fontSize: "1rem" }}>
                                Cable Information
                              </div>
                            </div>
                          </Col>
                          <Col className="col-sm">
                            <div className="d-flex justify-content-sm-end">
                              <div>
                                <Button
                                  color="success"
                                  type="button"
                                  className="add-btn me-1"
                                  onClick={() => togglePreview()}
                                >
                                  <i className="fa-solid fa-eye mx-2"></i>
                                  Preview{" "}
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row className="mb-5">
                          <form>
                            <table
                              className="table align-middle table-nowrap"
                              id="purchase"
                            >
                              <thead className="table-light">
                                <tr>
                                  <th className="sort" data-sort="item">
                                    Receiver Type
                                  </th>
                                  {/* <th className="sort" data-sort="qty">
                                  Receiver Number
                                </th> */}
                                  <th className="sort" data-sort="phone">
                                    Quantity Number
                                  </th>
                                  <th className="sort" data-sort="Date">
                                    Amount
                                  </th>

                                  <th className="sort" data-sort="action">
                                    Actions
                                  </th>
                                  <th className="sort" data-sort="action"></th>
                                </tr>
                              </thead>
                              <tbody className="list form-check-all">
                                <tr className="">
                                  <td className="item">
                                    <div className="mb-3">
                                      <Select
                                        options={suppliersArr}
                                        onChange={(choice) => {
                                          setReceiverTypes(choice);
                                          setClean(false);
                                          setTOtotrue(true);
                                        }}
                                        value={suppliersArr.filter(function (
                                          option
                                        ) {
                                          return (
                                            option.value === recTypes?.value
                                          );
                                        })}
                                        required
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 0,
                                          colors: {
                                            ...theme.colors,
                                          },
                                        })}
                                      />
                                    </div>
                                  </td>

                                  {/* <td className="qty">
                                  <div className="mb-3">
                                    <input
                                      type="number"
                                      name="receiver number"
                                      className="form-control"
                                      placeholder="receiver number"
                                      disabled
                                     
                                      value={
                                        clean
                                          ? "  "
                                          : cableInformation?.lastSerial?.[0]
                                              ?.receiverSerialNumber
                                      }
                                    />
                                  </div>
                                </td>
                                 */}
                                  <td className="qty">
                                    <div className="mb-3">
                                      <input
                                        type="number"
                                        name="quantity"
                                        className="form-control"
                                        placeholder="quantity number"
                                        onChange={(e) =>
                                          setquuuantity(e.target.value)
                                        }
                                        disabled
                                        value={1}
                                      />
                                    </div>
                                  </td>

                                  <td className="qty">
                                    <div className="mb-3">
                                      <Input
                                        name="amount"
                                        disabled
                                        type="number"
                                        className="form-control"
                                        onChange={(e) =>
                                          setAmou(e.target.value)
                                        }
                                        value={
                                          clean
                                            ? " "
                                            : cableInformation
                                                ?.sellingPrice?.[0]
                                                ?.sellingPrice * 1
                                        }
                                      />
                                    </div>
                                  </td>

                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-success mb-3 mx-3"
                                      onClick={(e) => addServiceTable(e)}
                                    >
                                      <i className="fa fa-plus"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>

                              {preview && tempTable.length > 0 && (
                                <tbody className="list form-check-all">
                                  {tempTable.map((data, index) => {
                                    return (
                                      <tr key={index}>
                                        <td className="Store_name">
                                          {" "}
                                          {data.recTyp}{" "}
                                        </td>
                                        <td className="location">
                                          {" "}
                                          {data.recNumberLabel}{" "}
                                        </td>
                                        {/* <td className="description">
                                        {" "}
                                        {data.receiverCardNumber}{" "}
                                      </td> */}
                                        <td className="description">
                                          {" "}
                                          {data.amount}{" "}
                                        </td>

                                        <td>
                                          <div className="d-flex gap-2">
                                            <div className="remove">
                                              <button
                                                type="button"
                                                className="btn btn-sm btn-danger remove-item-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#deleteRecordModal"
                                                onClick={() =>
                                                  delCableInfo(index)
                                                }
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              )}
                            </table>
                          </form>
                          <Col md={8} style={{ marginLeft: "6%" }}></Col>
                          <Col md={2}>
                            <div className="mb-3 pull-right">
                              <label className="form-label">Total Amount</label>
                              <input
                                type="number"
                                className="form-control"
                                id="disabledInput"
                                defaultValue={receiverTotalAmount}
                                value={receiverTotalAmount}
                                disabled
                              />
                            </div>
                          </Col>
                          <Row></Row>
                        </Row>
                      </CardBody>
                    </card>
                  )}

                  {isFree == "false" && (
                    <card>
                      <CardHeader
                        style={{
                          color: "grey",
                          fontSize: "1rem",
                          marginTop: "-3%",
                        }}
                      >
                        Reciept Details
                      </CardHeader>

                      <CardBody>
                        <Row className="mb-4">
                          <Col md={3}>
                            <Label
                              htmlFor="PurchasesDate"
                              className="form-label"
                            >
                              Grand Total
                            </Label>
                            <input
                              type="number"
                              id="disabledInput"
                              className="form-control"
                              placeholder=""
                              required
                              disabled
                              defaultValue={0}
                              onChange={(e) => handleServiceChangeBalance(e)}
                              value={grandTotal}
                            />
                          </Col>
                          <Col md={3} className="d-none">
                            <div className="mb-3">
                              <Label className="form-label">
                                Discount <span className="text-danger">*</span>
                              </Label>
                              <input
                                type="number"
                                id="referenceno-field"
                                className="form-control"
                                placeholder="Enter Discount"
                                name="disc"
                                // onChange={(e) => setdiscount(e.target.value)}
                                value={discount}
                                onChange={(e) => handleServiceChangeBalance(e)}

                                // onChange={(e) =>
                                //   handleServiceChangeBalance(e)
                                // }
                              />
                            </div>
                          </Col>

                          <Col md={3}>
                            <label className="form-label">Paid Balance</label>{" "}
                            <span className="text-danger">*</span>
                            <input
                              type="number"
                              id="referenceno-field"
                              className="form-control"
                              placeholder="Enter Paid Balance"
                              // required
                              name="balancepaid"
                              onChange={(e) => getbalance(e.target.value)}
                              value={paidbalance}
                            />
                          </Col>
                          <Col md={3}>
                            <Label
                              htmlFor="PurchasesDate"
                              className="form-label"
                            >
                              Balance
                            </Label>
                            <input
                              type="number"
                              id="disabledInput"
                              className="form-control"
                              placeholder=""
                              required
                              disabled
                              value={
                                Number(grandTotal) -
                                Number(discount) -
                                Number(paidbalance)
                              }
                              // onChange={(e) => setCustPhone(e.target.value)}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </card>
                  )}
                  <div className="hstack gap-2 justify-content-end">
                    {isNewCableLoading == true ? (
                      <div
                        className="hstack gap-2 justify-content-end"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <button
                          disabled
                          type="button"
                          className="btn btn-success btn-load"
                        >
                          <span className="d-flex align-items-center">
                            <span
                              className="spinner-border flex-shrink-0"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </span>
                            <span className="flex-grow-1 ms-2 text-center">
                              Loading...
                            </span>
                          </span>
                        </button>
                      </div>
                    ) : (
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-success"
                          id="add-btn"
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </CardBody>
            </Card>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Start of Table for printer */}
      <div id="divcontents" style={{ marginLeft: "60px", display: "none" }}>
        <img
          src={logo}
          alt="Logo"
          style={{ marginLeft: "35%", size: "40px" }}
        />

        <div className="maincontainer">
          <div
            className="CustomerInfo"
            style={{ display: "inline-block", float: "right" }}
          >
            <div
              className="Custinfo2"
              style={{ display: "inline-block", float: "left" }}
            >
              <div style={{ float: "right" }}>
                <label className="salesDate">Sales Date : </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                  {today}
                </span>
              </div>
              <br />

              <div style={{ float: "right" }}>
                <label className="Customer">Customer : </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                  {singleCustomer.customerName}
                </span>
              </div>
              <br />

              <div style={{ float: "right" }}>
                <label className="Customer">Phone : </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                  {singleCustomer.customerPhone}
                </span>
              </div>
              <br />

              <div style={{ float: "right" }}>
                <label className="Customer">Address : </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                  {singleCustomer.customerAddress}
                </span>
              </div>
            </div>
          </div>
          <br />
          <br />

          <div className="itemstable" style={{ marginTop: "50px" }}>
            <table
              id="items"
              className="table table-striped table-bordered table-hover"
              style={{
                width: "100%",
                maxWidth: "100%",
                fontSize: "15px",
                position: "relative",
              }}
            >
              <thead className="list form-check-all">
                <tr style={{ marginRight: "-100px" }}>
                  <th></th>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Amount</th>

                  <th></th>
                  <Col md={8} style={{ marginRight: "5%" }}></Col>
                  <th></th>
                </tr>
              </thead>
              <tbody className="list form-check-all">
                {serviceListProduct?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td></td>

                      <td className="Customer">{selecteditem}</td>
                      <td className="Customer">{data?.quantity}</td>
                      <td className="Customer">{data?.unitPrice}</td>

                      <td className="Customer">{data?.amt}</td>

                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Col md={8} style={{ marginTop: "10%" }}></Col>
            <Col md={2}>
              <div className="mb-3 pull-right">
                <label
                  className="form-label"
                  style={{
                    marginLeft: "70%",
                    marginTop: "10%",
                    fontWeight: "bold",
                  }}
                >
                  Total Items Amount
                </label>

                <input
                  type="number"
                  className="form-control"
                  id="disabledInput"
                  defaultValue={purchaseAmount}
                  value={purchaseAmount}
                  disabled
                  style={{
                    border: "none",
                    fontWeight: "bold",
                    size: "50px",
                    marginLeft: "80%",
                  }}
                />
              </div>
            </Col>
          </div>

          <div className="recievertable" style={{ marginTop: "50px" }}>
            <table
              id="recievers"
              className="table table-striped table-bordered table-hover"
              style={{ width: "100%", maxWidth: "100%", fontSize: "15px" }}
            >
              <thead className="list form-check-all">
                <tr>
                  {/* <th scope="col" style={{ width: "50px" }}></th> */}
                  <th className="sort" data-sort="item">
                    Receiver Type
                  </th>
                  <th className="sort" data-sort="qty">
                    Receiver Number
                  </th>
                  <th className="sort" data-sort="phone">
                    Card Number
                  </th>

                  <th className="sort" data-sort="phone">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody className="list form-check-all">
                {tempTable.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td className="Customer" id="mytable">
                        {data.recTyp}
                      </td>
                      <td className="Customer">{data.recNumberLabel}</td>
                      <td className="Customer">{data.receiverCardNumber}</td>
                      <td className="Customer">{data.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Col md={8} style={{ marginTop: "10%" }}></Col>
            <Col md={2}>
              <div className="mb-3 pull-right">
                <label
                  className="form-label"
                  style={{
                    marginLeft: "70%",
                    marginTop: "10%",
                    fontWeight: "bold",
                  }}
                >
                  Total Receiver Amount
                </label>

                <input
                  type="number"
                  className="form-control"
                  id="disabledInput"
                  defaultValue={receiverTotalAmount}
                  value={receiverTotalAmount}
                  disabled
                  style={{
                    border: "none",
                    fontWeight: "bold",
                    size: "50px",
                    marginLeft: "80%",
                  }}
                />
              </div>
            </Col>
          </div>

          <div className="grandcalculations">
            <h2 style={{ color: "lightgray" }}>Reciept Details</h2>
            <Col md={3}>
              <div className="mb-3">
                <label style={{ fontWeight: "bold" }}>Grand Total : </label>
                <input
                  type="number"
                  className="form-control"
                  id="disabledInput"
                  // defaultValue={receiverTotalAmount}
                  value={
                    Number(purchaseAmount) + Number(purchaseAmountReciever)
                  }
                  disabled
                  style={{ border: "none", fontWeight: "bold", size: "50px" }}
                />
              </div>
            </Col>
            <br />

            <Col md={3}>
              <div className="mb-3">
                <Label className="form-label">Discount:</Label>
                <input
                  type="number"
                  id="referenceno-field"
                  className="form-control"
                  required
                  name="disc"
                  disabled
                  style={{ border: "none", fontWeight: "bold", size: "50px" }}
                  value={discount}

                  // onChange={(e) =>
                  //   handleServiceChangeBalance(e)
                  // }
                />
              </div>
            </Col>
            <br />

            <Col md={3}>
              <div className="mb-3">
                <Label className="form-label" style={{ fontWeight: "bold" }}>
                  Paid Balance:
                </Label>
                <input
                  type="number"
                  id="referenceno-field"
                  className="form-control"
                  required
                  name="disc"
                  disabled
                  style={{ border: "none", fontWeight: "bold", size: "50px" }}
                  value={paidbalance}

                  // onChange={(e) =>
                  //   handleServiceChangeBalance(e)
                  // }
                />
              </div>
            </Col>
            <br />

            <Col md={3}>
              <div className="mb-3">
                <Label className="form-label">Balance Remain:</Label>
                <input
                  type="number"
                  id="referenceno-field"
                  className="form-control"
                  required
                  name="disc"
                  disabled
                  style={{ border: "none", fontWeight: "bold", size: "50px" }}
                  value={totalAmount}

                  // onChange={(e) =>
                  //   handleServiceChangeBalance(e)
                  // }
                />
              </div>
            </Col>
          </div>

          <div style={{ marginTop: "20%" }}>
            <span>
              <Label className="form-label" style={{ marginLeft: "23%" }}>
                Recieved By: ________________________
              </Label>
            </span>

            {/* <span>
              <Label className="form-label" style={{ marginLeft: "4%" }}>
                Sold to: ________________________
              </Label>
            </span> */}
          </div>
        </div>
      </div>

      {/* End of Table for printer */}

      {/* Add Modal */}
      {/* Purchase Customer From */}

   <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Hubi Line Number</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form >
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
              <Row>
              <Col md={10}>
                <div className="mb-3">
                  <label htmlFor="customer_name-field" className="form-label">
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="customer_name-field"
                    className="form-control"
                    placeholder="Enter Name"
                    required
                    onChange={(e) => setcName(e.target.value)}
                    value={cName}
                  />
                </div>
              </Col>
              <Col md={10}>
                <div className="mb-3">
                  <label htmlFor="customer_name-field" className="form-label">
                    Line <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="customer_name-field"
                    className="form-control"
                    placeholder="Enter Line"
                    required
                    onChange={(e) => setCustLine(e.target.value)}
                    value={custLien}
                  />
                </div>
              </Col>
              <Col md={2}>
                <div className="mt-4">
                  
                {isChecking == true ? (
                    <button
                      disabled
                      type="button"
                      className="btn btn-success btn-load w-100"
                    >
                      <span className="d-flex align-items-center">
                        <span
                          className="spinner-border flex-shrink-0"
                          role="status"
                        >
                          <span className="visually-hidden">Checking...</span>
                        </span>
                        <span className="flex-grow-1 ms-2 text-center">
                          Checking...
                        </span>
                      </span>
                    </button>
                  ) : (
                    <Button
                      type="button"
                      className="btn btn-warning"
                      style={{ width: "100%" }}
                      onClick={() => handleChecking()}
                    >
                      Hubbi
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </ModalBody>
          {/* <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isUpdating ? "Update" : "Save"}
              </button>
            </div>
          </ModalFooter> */}
        </form>
      </Modal> 
      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default NewIptv;
