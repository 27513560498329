import React, { useState, useEffect, useContext } from "react";
import { Grid, _ } from "gridjs-react";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { BsPen } from "react-icons/bs";
import { BsFillEyeFill } from "react-icons/bs";
import DataTable from "react-data-table-component";


import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    DropdownItem,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
//import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const ManagePayroll = () => {

    const [isEditing, setEditing] = useState(false);

    const [additionalCost, setadditionalCost] = useState("");
    const [generateID, setgenerateID] = useState("");
    const [discount, setdiscount] = useState();
    const [totalAmount, settotalAmount] = useState();
    const [bonus, setbonus] = useState();
    const [commission, setcommision] = useState();
    const [year, setyear] = useState();
    const [month, setmonth] = useState();
    const [description, setdescription] = useState();
    const {
        registerStore,
        stores,
        deleteStores,
        upadteStore,
        fetchItems,
        fetchStores,
        fetchSites,
        sites,
        items,
        getEmployee,
        fetchEmployees,
        fetchPayroll,
        Commissions,
        IPTVHormuud,
        registerPayroll,
        fetchPayrollMain,
        fetchPendingPayroll,
        PayrollMain,
        fetchPayrollYearandMonth,
        PayrollYearMonth,
        PendingPayroll,
        updatePayroll
    } = useContext(LoginContex);
    const [modal_list, setmodal_list] = useState(false);
    const tog_list = () => {
        setmodal_list(!modal_list);
    };
    const [modal_delete, setmodal_delete] = useState(false);
    const tog_delete = () => {
        setmodal_delete(!modal_delete);
    };

    useEffect(() => {
        fetchEmployees();
        fetchPayroll();
        fetchPayrollYearandMonth();
    }, []);

    // //console.log(Commissions);
    // //console.log(getEmployee);


    document.title = "Manage Payroll";

    useEffect(() => {
        fetchPayrollYearandMonth();
        fetchPayroll();
      }, []);
    
      useEffect(() => {
        // Ensure data is fetched before setting options
        if (PayrollYearMonth && PayrollYearMonth.length > 0) {
          populateYearAndMonthOptions();
        }
      }, [PayrollYearMonth]);
      const populateYearAndMonthOptions = () => {
        const uniqueYears = [...new Set(PayrollYearMonth.map((item) => item.year))];
        const uniqueMonths = [...new Set(PayrollYearMonth.map((item) => item.month))];
    
        const yearDropdown = document.getElementById("selectYear");
        uniqueYears.forEach((year) => {
          if (!Array.from(yearDropdown.options).some(option => option.value === String(year))) {
            yearDropdown[yearDropdown.length] = new Option(year, year);
          }
        });
    
        const monthDropdown = document.getElementById("selectMonth");
        uniqueMonths.forEach((month) => {
          if (!Array.from(monthDropdown.options).some(option => option.value === String(month))) {
            monthDropdown[monthDropdown.length] = new Option(month, month);
          }
        });
      };
    
      const SetYearPayroll = (e) => {
        setyear(e.target.value);
      };
    
      const SetMonthPayroll = (e) => {
        setmonth(e.target.value);
        fetchPendingPayroll({ year, month: e.target.value });
      };
    const handleServiceChangeProduct = (e, index) => {
        const { name, value } = e.target;
        const list = [...EmployeeList];

        list[index][name] = value;
        //console.log(list[index][name]);
    };


    function showTableData() {
        let myTable = document.querySelector('#SetupTable');
        Object.values(getEmployee).forEach(entry => {
            var inputs = myTable.getElementsByTagName("input");
            var result = new Array(inputs.length);
            for (var i = 0; i < inputs.length; i++)
                //products[i].total.value = products[i].waarde.value * products[i].aantal.value; 
                result[i] = inputs[i].value;
            //console.log(result);
        });

    }

    const initialState = {
        customerData: {
            empID: '',
            Bonus: '',
            Commission: ''
        }
    }

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const d = new Date();

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let time = new Date().toLocaleTimeString('en-US');


    today = yyyy + "-" + mm + "-" + dd + " ";

    // //console.log("year", yyyy, "month", monthNames[d.getMonth()]);

    const [EmployeeList, setEmployeeList] = useState([
        {
            empId: "",
            baseSalary: "",
            AdvanceAmount: "",
            deductionAmount: ""
        },
    ]);


    const [List, setList] = useState([
        {
            empId: "",

        },
    ]);

    const [inputarr,
        setInputarr] = useState([])

    const showToastMessage = (message) => {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let EmpID = "";
        let Bonus = 0;
        let Commission = 0;


        var sumVal = 0;
        var table = document.getElementById("SetupTable");

        for (var i = 1; i < (table.rows.length - 1); i++) {
            sumVal = sumVal + parseInt(table.rows[i].cells[3].innerHTML);
        }

        //console.log(sumVal);

    };

    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] =
        React.useState(false);
    const filteredItems = PendingPayroll.filter(
        (item) =>
            item?.Emp.empName &&
            item?.Emp.empName.toLowerCase().includes(filterText.toLowerCase())
    );
    //console.log(filteredItems);
    //console.log(filterText);
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <div>
                <Input
                    onChange={(e) => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                    placeholder="search here"
                />
            </div>
        );
    }, [filterText, resetPaginationToggle]);

    let Rows = 0;
    for (let i = 0; i < PendingPayroll?.length; i++) {
        Rows += 1;
    }

    let columns = [
        // {
        //     name: <h6 style={{ fontWeight: "bold" }}> No</h6>,
        //     selector: (row) => Rows,
        // },


        {
            name: <h6 style={{ fontWeight: "bold" }}> Full Name</h6>,
            selector: (row) => row.Emp.empName,
        },
        {
            name: <h6 style={{ fontWeight: "bold" }}> Bank Name</h6>,
            selector: (row) => row.Emp.bankName,
        },
        {
            name: <h6 style={{ fontWeight: "bold" }}> Account</h6>,
            selector: (row) => row.Emp.bankAccount,
        },
        {
            name: <h6 style={{ fontWeight: "bold" }}> Salary</h6>,
            selector: (row) => row.Emp.baseSalary,
        },
        {

            name: <h6 style={{ fontWeight: "bold" }}> Advance</h6>,
            selector: (row) => row.generatePayrollDetails.AdvanceAmount == undefined ? 0 : row.generatePayrollDetails.AdvanceAmount,
        },
        {

            name: <h6 style={{ fontWeight: "bold" }}> Deduction</h6>,
            selector: (row) => row.generatePayrollDetails.deductionAmount == undefined ? 0 : row.generatePayrollDetails.deductionAmount,
        },
        {
            name: <h6 style={{ fontWeight: "bold" }}> Net Payment</h6>,
            selector: (row) => (row.Emp.baseSalary) - ((row.generatePayrollDetails.AdvanceAmount == undefined ? 0 : row.generatePayrollDetails.AdvanceAmount) - (row.generatePayrollDetails.deductionAmount == undefined ? 0 : row.generatePayrollDetails.deductionAmount)),
        },

        {
            /*end*/
        },
    ];


    const mockData = [
        {
            Name: "Hussein Jamac Liban",
            Salary: "600",
            Advance: "100",
            Deduction: "50",
            Account: "16245454",
            BankName: "Premiere Bank",
            Netpayment: "500",
        },
        {
            Name: "Aisha Mohamed Ahmed",
            Salary: "800",
            Advance: "50",
            Deduction: "30",
            Account: "455974",
            BankName: "Salaam Bank",
            Netpayment: "600",
        },
    ];




    // const SetYearPayroll = (e) => {
    //     //console.log(e.target.value);
    //     setyear(e.target.value);

    // }

    // const SetMonthPayroll = (e) => {

    //     //console.log(e.target.value)
    //     setmonth(e.target.value);

    //     const data = {
    //         year,
    //         month: e.target.value
    //     };
    //     fetchPendingPayroll(data);

    //     //console.log(PayrollMain);

    // }


    // let GenID = "";
    const sortPayrolls = [];
    const GenID = [];
    for (let i = 0; i < PendingPayroll?.length; i++) {
        let advance = PendingPayroll[i].generatePayrollDetails[i].AdvanceAmount == undefined ? 0 : PendingPayroll[i].generatePayrollDetails[i].AdvanceAmount;
        let deduction = PendingPayroll[i].generatePayrollDetails[i].deductionAmount == undefined ? 0 : PendingPayroll[i].generatePayrollDetails[i].deductionAmount;

        let singleArr = [
            i + 1,
            PendingPayroll[i].Emp.empName,
            PendingPayroll[i].Emp.bankName,
            PendingPayroll[i].Emp.bankAccount,
            PendingPayroll[i].Emp.baseSalary,
            PendingPayroll[i].generatePayrollDetails[i].AdvanceAmount == undefined ? 0 : PendingPayroll[i].generatePayrollDetails[i].AdvanceAmount,
            PendingPayroll[i].generatePayrollDetails[i].deductionAmount == undefined ? 0 : PendingPayroll[i].generatePayrollDetails[i].deductionAmount,
            PendingPayroll[i].Emp.baseSalary - (advance + deduction),
            PendingPayroll[i]._id,
        ];

        let Sarray = {
            id: PendingPayroll[i]._id
        }
        GenID.push(Sarray);
        sortPayrolls.push(singleArr);

    }
    // setgenerateID(GenID);

    //console.log("GenID :::", GenID);

    const EmpLists = [];

    for (let i = 0; i < PayrollMain?.length; i++) {
        let singleArr = {
            empId: PayrollMain[i]._id,
            baseSalary: PayrollMain[i].baseSalary,
            AdvanceAmount: PayrollMain[i].AdvanceAmount == undefined ? 0 : PayrollMain[i].AdvanceAmount,
            deductionAmount: PayrollMain[i].deductionAmount == undefined ? 0 : PayrollMain[i].deductionAmount,
        };
        EmpLists.push(singleArr);

    }


    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    // Getting the year from the API 
    const YearPayroll = [];
    for (let i = 0; i < PayrollYearMonth?.length; i++) {
        YearPayroll.push(PayrollYearMonth[i]?.year);
    }

    // Getting the Month from the API 

    const MonthPayroll = [];
    for (let i = 0; i < PayrollYearMonth?.length; i++) {
        MonthPayroll.push(PayrollYearMonth[i]?.month)
    }


    // Setting or fetching only Distinct or Unique Year
    const unique = PayrollYearMonth
        .map(item => item?.year)
        .filter((value, index, self) => self.indexOf(value) === index)


    //console.log("unique", unique);


    // Loading only in the page load and using React Strict Mode to stop rerending
    useEffect(() => {
        fetchPayrollYearandMonth();
        fetchPayroll();
        // var dropdown = document.getElementById("selectYear");
        // for (var i = 0; i < unique.length; ++i) {
        //     dropdown[dropdown.length] = new Option(unique[i], unique[i]);
        // }
    }, []);


    // Setting or fetching only Distinct or Unique Month
    const uniqueMonth = PayrollYearMonth
        .map(item => item?.month)
        .filter((value, index, self) => self.indexOf(value) === index)

    //console.log("uniqueMon", uniqueMonth);

    // Loading only in the page load and using React Strict Mode to stop rerending
    useEffect(() => {
        fetchPayrollYearandMonth();
        fetchPayroll();
        // var dropdownMon = document.getElementById("selectMonth");
        // for (var i = 0; i < uniqueMonth.length; ++i) {
        //     dropdownMon[dropdownMon.length] = new Option(uniqueMonth[i], uniqueMonth[i]);
        // }
    }, []);




    const Proceed = () => {
        if (year == undefined || year == '') {
            showToastMessage('Please Select a year to generate')
        }
        if (month == undefined || month == '') {
            showToastMessage('Please Select a month to generate')
        } else {
            const data = {
                status: "true",
                id: Object.values(GenID[0]).join(' '), // Getting the first ID of the array and removing the brackets from it
            }
            //console.log(data);
            updatePayroll(data);
           
            populateYearAndMonthOptions()
            fetchPayrollYearandMonth();
            fetchPayroll();
        }
    };


    const Discard = () => {
        if (year == undefined || year == '') {
            showToastMessage('Please Select a year to generate')
        }
        if (month == undefined || month == '') {
            showToastMessage('Please Select a month to generate')
        } else {
            const data = {
                status: "false",
                id: Object.values(GenID[0]).join(' '),
            }
            //console.log(data);
            updatePayroll(data);
            populateYearAndMonthOptions()
            fetchPayrollYearandMonth();
            fetchPayroll();
        }
    };


    return (
        <React.StrictMode>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Manage Payroll" pageTitle="Manage Payroll" />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader>
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div>
                                                    <div>
                                                        <h4 className="card-title mb-0">Manage Payroll</h4>
                                                    </div>
                                                    <ToastContainer />
                                                </div>
                                            </Col>

                                        </Row>
                                    </CardHeader>

                                    <CardBody>
                                        <div id="StoreList">
                                            <Row style={{ marginLeft: "0.2%" }}>
                                                <Col md={3}>
                                                    <div>
                                                        <label>Select Year</label>
                                                        <select className="form-select " id="selectYear"
                                                            onChange={(e) => {
                                                                SetYearPayroll(e)
                                                            }}
                                                        >

                                                            <option value={"0"}>Select Year</option>

                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <label>Select Month</label>

                                                        <select className="form-select " id="selectMonth"
                                                            onChange={(e) => {
                                                                SetMonthPayroll(e)
                                                            }}
                                                        >

                                                            <option value={"0"}>Select Month</option>


                                                        </select>
                                                    </div>


                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">

                                                    </div>
                                                </Col>

                                            </Row>
                                            {PendingPayroll.length > 0 && (
                                                <DataTable
                                                    columns={columns}
                                                    data={filteredItems}
                                                    pagination
                                                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                                    subHeader
                                                    subHeaderComponent={subHeaderComponentMemo}
                                                    // selectableRows
                                                    persistTableHead
                                                // sortFunction={customSort}
                                                />
                                            )}
                                            

                                            <div>

                                            </div>
                                            <button
                                                className="btn btn-sm btn-success edit-item-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#showModal"
                                                onClick={Proceed}
                                                style={{ backgroundColor: "#405189", border: "none", float: "right", marginTop: "4%", width: "100px", height: "30px" }}
                                            >Proceed
                                            </button>
                                            <button
                                                className="btn btn-sm btn-success edit-item-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#showModal"
                                                onClick={Discard}
                                                style={{ backgroundColor: "#0ab39c", border: "none", marginRight: "1%", float: "right", marginTop: "4%", width: "100px", height: "30px" }}
                                            >Discard
                                            </button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {/* end of container */}
                    </Container>
                </div>

                {/* Add Modal */}
                <Modal
                    isOpen={modal_list}
                    toggle={() => {
                        tog_list();
                    }}
                    centered
                    size="lg"
                >

                    <div className="bg-light p-3 modal-header"><h5 className="modal-title">Commission & Bonus SetUp</h5>

                        <Button
                            type="button"
                            onClick={() => {
                                setmodal_list(false);
                            }}
                            className="btn-close"
                            aria-label="Close"
                        ></Button>

                    </div>
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="table-responsive table-card mt-3 mb-1">
                                <table
                                    className="table align-middle table-nowrap"
                                    id="SetupTable"
                                >
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col" style={{ width: "5px" }}></th>

                                            <th className="sort" data-sort="date">
                                                ID
                                            </th>
                                            <th className="sort" data-sort="date">
                                                Full Name
                                            </th>

                                            <th className="sort" data-sort="TotalAmount">
                                                Advance
                                            </th>
                                            <th className="sort" data-sort="Type">
                                                Deduction
                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                        {getEmployee?.map((data, index) => {
                                            return (
                                                <tr key={index} id="somerow">
                                                    <th scope="row"></th>

                                                    <td className="id" style={{ display: "none" }}>
                                                        <Link
                                                            to="#"
                                                            className="fw-medium link-primary"
                                                        >
                                                            #VZ2104
                                                        </Link>
                                                    </td>
                                                    <td style={{ display: "none" }}>{''}{data._id}</td>
                                                    <td>{''}{data.empId}</td>
                                                    <td name="empID">{''}{data.empName}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            id="Purchasesid-field"
                                                            className="form-control"
                                                            placeholder="00.00"
                                                            // required
                                                            name="Bonus"
                                                            onChange={(e) =>
                                                                handleServiceChangeProduct(e, index)
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            id="Purchasesid-field"
                                                            className="form-control"
                                                            placeholder="00.00"
                                                            // required
                                                            name="Commission"

                                                            onChange={(e) =>
                                                                handleServiceChangeProduct(e, index)
                                                            }
                                                        />
                                                    </td>



                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <div className="noresult" style={{ display: "none" }}>
                                    <div className="text-center">
                                        <lord-icon
                                            src="https://cdn.lordicon.com/msoeawqm.json"
                                            trigger="loop"
                                            colors="primary:#121331,secondary:#08a88a"
                                            style={{ width: "75px", height: "75px" }}
                                        ></lord-icon>
                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                        <p className="text-muted mb-0">
                                            We've searched more than 150+ Orders We did not find
                                            any orders for you search.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={() => setmodal_list(false)}
                                >
                                    Close
                                </button>
                                <button type="submit" className="btn btn-success" id="add-btn">
                                    {isEditing ? "update" : "save"}
                                </button>
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>

                {/* Remove Modal */}
                <Modal
                    isOpen={modal_delete}
                    toggle={() => {
                        tog_delete();
                    }}
                    className="modal"
                    id="deleteRecordModal"
                    centered
                >
                    <div className="modal-header">
                        <Button
                            type="button"
                            onClick={() => setmodal_delete(false)}
                            className="btn-close"
                            aria-label="Close"
                        >
                            {" "}
                        </Button>
                    </div>
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <lord-icon
                                src="https://cdn.lordicon.com/gsqxdxog.json"
                                trigger="loop"
                                colors="primary:#f7b84b,secondary:#f06548"
                                style={{ width: "100px", height: "100px" }}
                            ></lord-icon>
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Are you Sure ?</h4>
                                <p className="text-muted mx-4 mb-0">
                                    Are you Sure You want to Remove this Record ?
                                </p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <button
                                type="button"
                                className="btn w-sm btn-light"
                                onClick={() => setmodal_delete(false)}
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn w-sm btn-danger "
                                id="delete-record"
                            // onClick={() => delPurchase()}
                            >
                                Yes, Delete It!
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        </React.StrictMode>
    );
};

export default ManagePayroll;
