import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Container,
  Label,
  Row,
  Table,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Grid } from "gridjs-react";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import Select from "react-select";

const PurchaseReport = () => {
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [helper, setHelper] = useState(false);
  // const [pur, setPur] = useState(sortedPurchases);
  //LOCAL states
  const {
    inventoryReport,
    inventoryReports,
    purchReport,
    purchaseReport,
    salesReport,
    items,
    getsalesReport,
    purchaseReportByDate,
    purcReportByDate,
  } = useContext(LoginContex);

  useEffect(() => {
    inventoryReport();
    purchaseReport();
    getsalesReport();
  }, []);

  const [filterText, setFilterText] = React.useState("");
  //console.log(purcReportByDate);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = helper
    ? purcReportByDate.filter(
        (item) =>
          item.item?.itemId?.itemName &&
          item.item?.itemId?.itemName
            .toLowerCase()
            .includes(filterText.toLowerCase())
      )
    : purchReport.filter(
        (item) =>
          item.item?.itemId?.itemName &&
          item.item?.itemId?.itemName
            .toLowerCase()
            .includes(filterText.toLowerCase())
      );
  //console.log(filteredItems);
  //console.log(filterText);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  // //console.log(inventoryReports);
  //console.log(purchReport);
  //console.log(purcReportByDate);
  const [supplierId, setSupplierId] = useState("");

  document.title = "Purchase Report";
  //console.log(purchReport);
  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> Item</h6>,
      selector: (row) => row.item?.itemId?.itemName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Quantity</h6>,
      selector: (row) => row.item?.quantity,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Uploaded Quantity</h6>,
      selector: (row) => row.item?.reciversUploaded,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Supplier</h6>,
      selector: (row) => row.supplier?.supplierName,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Date</h6>,
      selector: (row) => moment(row.date).utc().format("DD-MM-YYYY"),
    },

    {
      /*end*/
    },
  ];

  const sortedInventories = [];
  for (let i = 0; i < inventoryReports?.length; i++) {
    let singleArr = [
      inventoryReports[i]?.itemId?.itemName,
      inventoryReports[i]?.availableQuantity,
      inventoryReports[i]?.storeId?.storeName,

      // inventoryReports[i]._id,
    ];

    sortedInventories.push(singleArr);
  }
  const sortedPurchases = [];
  for (let i = 0; i < purchReport?.length; i++) {
    //console.log(purchReport[i]?.date);
    let date = purchReport[i]?.date.split("T");
    const formattedDate = moment(date[0]).format("DD-MM-YYYY");
    let singleArr = [
      purchReport[i]?.item?.itemId?.itemName,
      purchReport[i]?.item?.quantity,
      purchReport[i]?.item?.reciversUploaded,
      purchReport[i]?.supplier?.supplierName,
      formattedDate,

      // inventoryReports[i]._id,
    ];

    sortedPurchases.push(singleArr);
  }

  //console.log(purcReportByDate);
  const sortedPurchasesDate = [];
  for (let i = 0; i < purcReportByDate?.length; i++) {
    //console.log(purcReportByDate[i]?.date);
    let date = purcReportByDate[i]?.date?.split("T");
    const formattedDate = moment(date[0]).format("DD-MM-YYYY");
    //console.log(formattedDate);
    let singleArr = [
      purcReportByDate[i].item?.itemId?.itemName,
      purcReportByDate[i]?.item?.quantity,
      purcReportByDate[i]?.item?.reciversUploaded,
      purcReportByDate[i]?.supplier?.supplierName,
      formattedDate,

      // inventoryReports[i]._id,
    ];

    sortedPurchasesDate.push(singleArr);
  }
  const sortedSales = [];
  for (let i = 0; i < salesReport?.length; i++) {
    //console.log(salesReport[i]?.date);
    let date = salesReport[i]?.date.split("T");
    const formattedDate = moment(date[0]).utc().format("DD-MM-YYYY");
    let singleArr = [
      salesReport[i].item?.itemId?.itemName || "not available",
      salesReport[i].item?.quantity,
      salesReport[i].item?.unitPrice,
      Number(salesReport[i]?.item?.quantity) *
        Number(salesReport[i]?.item?.unitPrice),
      formattedDate,

      // inventoryReports[i]._id,
    ];

    sortedSales.push(singleArr);
  }
  // //console.log(salesReport);
  // soloving dropdowns
  const suppliersArr = [];

  for (let i = 0; i < items.length; i++) {
    // //console.log(items[i]._id);

    var dropdownList = {
      value: items[i]._id,
      label: items[i].supplierName,
    };

    suppliersArr.push(dropdownList);
  }

  const filterThroughDate = () => {
    setHelper(true);
    purchaseReportByDate(start, end);
  };

  const clearFilter = () => {
    setHelper(false);
  };
  //console.log(purchReport);
  //console.log(start);
  //console.log(end);
  const CvsData = [];
  for (let i = 0; i < filteredItems?.length; i++) {
    // //console.log(filteredItems[i]._id);
  
   
      var dropdownList = {
        itemName: filteredItems?.[i]?.item?.itemId?.itemName,
        quantity: ` ${filteredItems?.[i]?.item?.quantity}`,
        uploadedQuantity: ` ${filteredItems?.[i]?.item?.reciversUploaded}`,
        supplier: ` ${filteredItems?.[i]?.supplier?.supplierName}`,
        Date: ` ${moment(filteredItems[i]?.date).utc().format("DD-MM-YYYY")}`,
       
       
  
      };
 
   
  
    CvsData.push(dropdownList);
  }
  function convertArrayOfObjectsToCSV(array) {
    let result;
  
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData?.[0] || []);
  
    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
  
        result += item[key];
  
        ctr++;
      });
      result += lineDelimiter;
    });
  
    return result;
  }
  
  // ** Downloads CSV
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;
  
    const filename = "export.csv";
  
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
  
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Purchase Report" pageTitle="Report Center" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-audto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">Purchase Report</h4>
                          <div className="form-label" style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end"}}>
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={()=>{
                              downloadCSV(CvsData)
                            }}
                            type="button"
                            //  id="create-btn"
                          > 
                            <i className="fa fa-print mx-2" aria-hidden="true"> </i>  
                            Download Excel
                          </Button>
                        </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end">
                    <div className="hstack gap-2 justify-content-end">
                      {/* <button type="button" className="btn btn-info">
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        Export
                      </button> */}
                    </div>{" "}
                  </div>
                </CardHeader>

                <CardBody>
                  <div id="customerList">
                    <Row className="mb-5">
                    
                      <Col md={3}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Start Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStart(date)}
                            value={start}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            End Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEnd(date)}
                            value={end}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={filterThroughDate}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Filter By Date{" "}
                          </Button>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={clearFilter}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Clear filter{" "}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        {/* {purchReport.length > 0 && ( */}
                          <DataTable
                            columns={columns}
                            data={filteredItems}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            // selectableRows
                            persistTableHead
                            // sortFunction={customSort}
                          />
                        {/* )} */}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* customer Registration From */}

      {/* Remove Modal */}
    </React.Fragment>
  );
};

export default PurchaseReport;
