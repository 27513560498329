import React, { useState, useEffect, useContext, useMemo } from "react";
import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
  Row,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
//import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";

const PendingOrdersReport = () => {
  const [yes, setYes] = useState(true);
  const [id, setId] = useState("");
  // const [no, setNo] = useState(false);

  // local states

  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const {
    fetchPurchase,
    purchases,
    fetchPurchaseOrder,

    purchaseOrders,
    removePurchase,
  } = useContext(LoginContex);
  useEffect(() => {
    fetchPurchase();
    fetchPurchaseOrder();
  }, []);
  //console.log(purchaseOrders[0]?.purchaseOrderDetails.length);

  document.title = " Pending Orders Report";
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  const columns = useMemo(
    () => [
      {
        Header: "supplierName",
        accessor: "supplierId.supplierName",
        filterable: false,
      },
      {
        Header: "referenceNo",
        accessor: "referenceNo",
        filterable: false,
      },
      {
        Header: "description",
        accessor: "description",
        filterable: false,
      },
      {
        Header: "total amount",
        accessor: "purchaseOrderAmount",
        filterable: false,
      },
      {
        Header: "total amount",
        accessor: "purchaseOrderDetails.length",
        filterable: false,
      },
      {
        Header: "date",
        accessor: "purchaseOrderDate",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      // {
      //   Header: "Type",
      //   // accessor: "purchase oder",
      //   default: "ll",
      //   filterable: false,
      // },
      
    ]
    // [handleCustomerClick]
  );

  const deletePuchase = (data) => {
    setmodal_delete(true);
    setId(data._id);
  };

  const delPurchase = () => {
    removePurchase(id);
    setmodal_delete(false);
  };

  //updateees

  const dateFromDB = "2005-12-03T18:30:00.000Z";
  const formattedDate = moment(dateFromDB).utc().format("DD/MM/YYYY");

  //console.log("Date From DB:", dateFromDB);
  //=> 2005-12-03T18:30:00.000Z

  //console.log("Formatted Date:", formattedDate);

  const sortedOrders = [];
  for (let i = 0; i < purchaseOrders?.length; i++) {
    let singleArr = [
      purchaseOrders[i].purchaseDate,
      purchaseOrders[i].supplierId?.supplierName,
      purchaseOrders[i].referenceNo,
      purchaseOrders[i].description,
      purchaseOrders[i].purchaseOrderAmount,
      purchaseOrders[i].purchaseOrderDetails.length,
      purchaseOrders[i].type,
    ];

    sortedOrders.push(singleArr);
  }

  // //console.log(data?.purchaseOrderDetails);

  //console.log(purchaseOrders);
  const CvsData = [];
  for (let i = 0; i < purchaseOrders?.length; i++) {
    // //console.log(purchaseOrders[i]._id);
  
    if(purchaseOrders?.[i]?.supplierId?.supplierName){
      var dropdownList = {
        itemName: purchaseOrders?.[i]?.supplierId?.supplierName,
        referenceNo: ` ${purchaseOrders?.[i]?.referenceNo}`,
        description: ` ${purchaseOrders?.[i]?.description}`,
        purchaseOrderAmount: ` ${purchaseOrders?.[i]?.purchaseOrderAmount}`,
        totalAMount: ` ${purchaseOrders?.[i]?.purchaseOrderDetails.length}`,
        Date: ` ${moment(purchaseOrders[i]?.purchaseOrderDate).utc().format("DD-MM-YYYY")}`,
       
       
  
      };
    }
   
  
    CvsData.push(dropdownList);
  }
  function convertArrayOfObjectsToCSV(array) {
    let result;
  
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData?.[0] || []);
  
    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
  
        result += item[key];
  
        ctr++;
      });
      result += lineDelimiter;
    });
  
    return result;
  }
  
  // ** Downloads CSV
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;
  
    const filename = "export.csv";
  
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
  
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Pending orders report" pageTitle="Purchase" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                   
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">
                            Pending orders report
                          </h4>
                          <div className="form-label" style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end"}}>
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={()=>{
                              downloadCSV(CvsData)
                            }}
                            type="button"
                            //  id="create-btn"
                          > 
                            <i className="fa fa-print mx-2" aria-hidden="true"> </i>  
                            Download Excel
                          </Button>
                        </div>
                        </div>
                      </div>
                   
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                         
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="StoreList">
                    <Row className="g-4 mb-3"></Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                     
                    </div>
                  </div>

                  {purchaseOrders.length > 0 && (
                    <TableContainer
                      columns={columns}
                      data={purchaseOrders || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      // handleCustomerClick={handleCustomerClicks}
                      // isCustomerFilter={true}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        <ModalHeader className="bg-light p-3 pull right">
          Add New Store
          <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close float-right"
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <form>
          <ModalBody></ModalBody>
          <ModalFooter></ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => delPurchase()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default PendingOrdersReport;
