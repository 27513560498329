import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import DataTable from "react-data-table-component";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// action
import { registerUser, apiError, resetRegisterFlag } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useHistory } from "react-router-dom";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";

import { Grid, _ } from "gridjs-react";

const MultiShiftSetup = () => {
  const history = useHistory();
  const [userName, setUsername] = useState();
  const [password, setPassword] = useState();
  const [role, setRole] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [employeeId, setEmployeeId] = useState("");
  const [shiftId, setShiftId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const [modal_list, setmodal_list] = useState(false);
  const [id, setId] = useState("");
  const [checked, setChecked] = useState(true);
  const [state, setState] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  const dispatch = useDispatch();
  const {

    isLoading,User,userLoading,MultiShifts,fetchShifts,shifts,
    fetchMultiShifts,registerMultiShift,updateMultiShift,deleteMultiShift,fetchEmployeesWithMultipleShifts,EmployeesWithMultipleShifts
  } = useContext(LoginContex);
  //console.log(changePassword);
  const { error, registrationError, success } = useSelector((state) => ({
    registrationError: state.Account.registrationError,
    success: state.Account.success,
    error: state.Account.error,
  }));
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
    let userId = User?.data?.user?._id || User?.user?._id;

    const filteredItems = MultiShifts?.filter(
      (item) =>
        item.employeeInfo?.empName &&
        item.employeeInfo?.empName.toLowerCase().includes(filterText.toLowerCase()) ||
        item?.shiftInfo?.shiftName.toLowerCase().includes(filterText.toLowerCase()) ||
        item?.employeeInfo?.mobile
    );
  //console.log(filteredItems);
  //console.log(filterText);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    fetchShifts()
    fetchMultiShifts()
    fetchEmployeesWithMultipleShifts()
  }, []);

  //console.log(EmployeesWithMultipleShifts);
  //console.log(MultiShifts);

  useEffect(() => {
    if (success) {
      setTimeout(() => history.push("login"), 3000);
    }

    setTimeout(() => {
      dispatch(resetRegisterFlag());
    }, 3000);
  }, [dispatch, success, error, history]);
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handleSubmit = (e) => {
    if (isEditing == false) {
      e.preventDefault();
      if (!shiftId || !startDate || !endDate || !employeeId) {
        return showToastMessage("Please provide the required values");
      }

      const data = {
        empId: employeeId?.value,
        shiftId: shiftId?.value,
        startDate: startDate,
        endDate: endDate
      
      };
      registerMultiShift(data);
      setEmployeeId("");
      setState("")
      e.target.reset();
    }
    if (isEditing == true) {
      e.preventDefault();
      if (!shiftId || !startDate || !endDate || !employeeId) {
       return showToastMessage("Please provide the required values");
      }
      // if (changePassword == "Yes" && password.length > 2) {
      //   showToastMessage("Please provide the password");
      // }
      const data = {
        empId: employeeId?.value,
        shiftId: shiftId?.value,
        startDate: startDate,
        endDate: endDate,
        id: id,
      };
      // console.log(data)
      updateMultiShift(data);
      setState("")
      // setEmployeeId("");
      e.target.reset();
      //console.log(data);
      setmodal_list(false);
    }
  };

    ///delete
    const deletPop = (data) => {
      setmodal_delete(true);
      setId(data._id);
      //console.log(data)
    };
  
    const deletProd = () => {
      deleteMultiShift(id);
      setmodal_delete(false);
    };
  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> ID</h6>,
      selector: (row) =>  row?.employeeInfo?.sqn,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Name</h6>,
      selector: (row) =>  row?.employeeInfo?.empName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Phone </h6>,
      selector: (row) => row.employeeInfo?.mobile,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Shift</h6>,
      selector: (row) => row.shiftInfo?.shiftName,
    },
  
    {
      name: <h6 style={{ fontWeight: "bold" }}> Start Date</h6>,
      selector: (row) => moment(row?.startDate).format("DD-MM-YYYY") 
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> End Date</h6>,
      selector: (row) => moment(row?.endDate).format("DD-MM-YYYY") 
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Actions</h6>,
      cell: (row) => (
        <>
          <i
            style={{ cursor: "pointer" }}
            className="text-primary ri-pencil-fill fs-16 mx-3 fx-3"
            onClick={() => editData(row)}
          ></i>{" "}
              <i style={{cursor: "pointer"}}
            className="ri-delete-bin-5-fill fs-16 text-danger"
            onClick={() => deletPop(row)}
          ></i>

         
        </>
      ),

     
    },
    {
      
    },
  ];

  const Emp = [];

  for (let i = 0; i < EmployeesWithMultipleShifts.length; i++) {
    // //console.log(setEmployeeId[i]._id);

    var dropdownList = {
      value: EmployeesWithMultipleShifts[i].sqn,
      label: ` ${EmployeesWithMultipleShifts[i].empName}`,
    };

    Emp.push(dropdownList);
  }

  const shiftArr = [];

  for (let i = 0; i < shifts?.length; i++) {
    // //console.log(shifts[i]?._id);

    var list = {
      value: shifts[i]._id,
      label: ` ${shifts[i].shiftName}`,
    };
    // //console.log(list)
    shiftArr.push(list);
  }
  const addModal = () => {
    tog_list();
    setEmployeeId("");
    setShiftId("");
    setStartDate("");
    setEndDate("");
    setChecked(true);
  };
  const editData = (row) => {
    setIsEditing(true);
    tog_list();
    // console.log(row);
    //console.log(row?.emp?.empName);
    setEmployeeId({ lable: row?.employeeInfo?.empName, value: row?.employeeInfo?.sqn });
    setShiftId({ lable: row?.shiftInfo.shiftName, value: row?.shiftInfo?._id });
    setStartDate(row?.startDate);
    setEndDate(row?.endDate);
    setId(row._id);
  };

  const closeModalMuscab = () => {
    setmodal_list(false);

    setIsEditing(false);
    setChecked(true);
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="MULTI SHIFT SETUP" pageTitle="Setting" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        {<ToastContainer />}
                        <div>
                          <subHeaderComponentMemo />
                          <h4 className="card-title mb-0">Multi Shift Setup</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
         

                  <div id="customerList">
                    {MultiShifts.length > 0 && (
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        // selectableRows
                        persistTableHead

                        progressPending={userLoading  }
                        progressComponent={<SpinnerBorder />}
                        // sortFunction={customSort}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}

          <Modal
            isOpen={modal_list}
            toggle={() => {
              tog_list();
            }}
            centered
            size="lg"
            backdrop={"static"}
          >
            <div className="bg-light p-3 modal-header">
              <h5 className="modal-title">
                {" "}
                {isEditing ? "update Multi Shift" : "Create Multi Shift"}{" "}
              </h5>

              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  closeModalMuscab();
                }}
                aria-label="Close"
              ></button>
            </div>
          
            <form onSubmit={handleSubmit}>
              <ModalBody>
           
                <div className="mb-3" id="modal-id" style={{ display: "none" }}>
                  <label htmlFor="id-field" className="form-label">
                    ID
                  </label>
                  <input
                    type="text"
                    id="id-field"
                    className="form-control"
                    placeholder="ID"
                    readOnly
                  />
                </div>

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <label className="form-label">
                        Employee <span className="text-danger">*</span>
                      </label>

                      <div>
                        <Select
                          // className="bg-white"
                          options={Emp}
                          onChange={(choice) => setEmployeeId(choice)}
                          value={Emp.filter(function (option) {
                            return option.value === employeeId?.value;
                          })}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                            },
                          })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <label className="form-label">
                        Shifts <span className="text-danger">*</span>
                      </label>

                      <div>
                        <Select
                          // className="bg-white"
                          options={shiftArr}
                          onChange={(choice) => setShiftId(choice)}
                          value={shiftArr?.filter(function (option) {
                            return option.value === shiftId?.value;
                          })}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                            },
                          })}
                        />
                      </div>
                    </div>
                  </Col>
                  </Row>
                      <Row>
                      <Col md={6}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Start Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStartDate(date)}
                            value={startDate}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            End Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEndDate(date)}
                            value={endDate}
                          />
                        </div>
                      </Col>
                        </Row>
             
              </ModalBody>

              <div className="mb-4"></div>

              <ModalFooter>
                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => closeModalMuscab()}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    id="add-btn"
                  >
                    {isEditing ? "Update" : "Save"}
                  </button>
                </div>
              </ModalFooter>
            </form>
          </Modal>
          <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={deletProd}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MultiShiftSetup;
const SpinnerBorder = () => {
  return <Spinner className="my-2 text-center" />;
};
