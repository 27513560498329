import React, { useState, useEffect, useContext, useMemo } from "react";
import Select from "react-select";
import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import DataTable from "react-data-table-component";

const SendSmsToIptvCustomers = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardPackage, setCardPackage] = useState("");
  const [customer, setCustomer] = useState();
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [openDate, setOpenDate] = useState("");
  const [closeDate, setCloseDate] = useState("");

  const [Discription, setdescription] = useState("");
  const { openAnalogCardRegister, customers } = useContext(LoginContex);
  const [type, setTyp] = useState("text");
  const [customerInfo, setCustomerInfo] = useState();

  const [channelId, setChanell] = useState();
  const [cycle, setCycle] = useState("");
  const [numberOfDays, setNumberOfDays] = useState();
  const [numberOfMonths, setNumberOfMonths] = useState();
  const [billAmount, setBillAmount] = useState();
  const [contents, setContent] = useState("");
  const [checked, setChecked] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [modal_delete, setmodal_delete] = useState(false);

  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const handleChange = () => {
    setChecked(!checked);
  };
  document.title = "Open Card ";
  //console.log(contents);
  const {
    closeAnalogCard,
    fetchIptvallExpiredCustomers,
    FetchExpireCards,
    expireCards,
    isNewCableLoading,
    User,
    sendSmsTocustomers,
    isSending,
    FetchCurrentExpireCards,
    currentExpiredCards,
    fetchAllAnalogExpired,
    analogAllExpired,
    fetchAllAnalogThreeDaysExpired,
    analogThreeRemaingDays,
    sendSmsTo3daysRemaingcustomers,
    iptvAllExpired,
    fetchIptva3DaysRemaingllExpiredCustomers,iptv3DaysExpired,sendSmsToIPTVcustomers,sendSmsToiptv3daysRemaingcustomers
  } = useContext(LoginContex);

  useEffect(() => {
    fetchIptvallExpiredCustomers();
    fetchIptva3DaysRemaingllExpiredCustomers();
    fetchAllAnalogExpired();
    fetchAllAnalogThreeDaysExpired();
  }, []);

  //console.log(iptv3DaysExpired);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems =
    checked == false
      ? iptvAllExpired?.filter(
          (item) =>
            (item?.customer_info?.customerName &&
              item?.customer_info?.customerName
                ?.toLowerCase()
                .includes(filterText.toLowerCase()) &&
              item.customer_info?.customerName
                ?.toLowerCase()
                .includes(filterText.toLowerCase()) &&
              item.customerPhone) ||
            item.customer_info?.customerPhone
              ?.toString()
              .toLowerCase()
              .includes(filterText.toLowerCase()) ||
            item.cardNumber
              ?.toString()
              .toLowerCase()
              .includes(filterText.toLowerCase())
        )
      : iptv3DaysExpired?.filter(
          (item) =>
          (item?.customer_info?.customerName &&
            item?.customer_info?.customerName
              ?.toLowerCase()
              .includes(filterText.toLowerCase()) &&
            item.customer_info?.customerName
              ?.toLowerCase()
              .includes(filterText.toLowerCase()) &&
            item.customerPhone) ||
          item.customer_info?.customerPhone
            ?.toString()
            .toLowerCase()
            .includes(filterText.toLowerCase()) ||
          item.cardNumber
            ?.toString()
            .toLowerCase()
            .includes(filterText.toLowerCase())
        );
  //console.log(filteredItems);
  // //console.log(iptvCustomerRechargeData);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);
  let userId = User?.data?.user?._id || User?.user?._id;
  const columns = [
    // {
    //   name: <h6 style={{ fontWeight: "bold" }}>No</h6>,
    //   selector: (row, index) => index + 1,
    // },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Customer Name</h6>,
      selector: (row) =>
        checked == false
          ? row?.customer_info?.customerName
          : row?.customer_info?.customerName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Phone</h6>,
      selector: (row) =>
        checked == false
          ? row?.customer_info?.customerPhone
          : row?.customer_info?.customerPhone,
    },

    // {
    //   name: <h6 style={{ fontWeight: "bold" }}> cardNumber</h6>,
    //   selector: (row) => row?.customer_info?.cardNumber,
    // },

    {
      name: <h6 style={{ fontWeight: "bold" }}> endDate</h6>,

      selector: (row) => moment(row.endDate).utc().format("DD-MM-YYYY"),
    },

    ,
  ];
  //console.log(expireCards);

  //console.log(openDate);

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  //   const columns = useMemo(
  //     () => [
  //       {
  //         Header: "Name",
  //         accessor: "customerName",
  //         filterable: false,
  //       },
  //       {
  //         Header: "Phone Number",
  //         accessor: "customerPhone",
  //         filterable: false,
  //       },

  //       {
  //         Header: " card Number",
  //         accessor: "cardNumber",
  //         filterable: false,
  //       },
  //       {
  //         Header: " status",
  //         accessor: "status",
  //         filterable: false,
  //       },

  //       {
  //         Header: "endDate",
  //         accessor: "endDate",
  //         filterable: false,
  //         Cell: (cell) => <>{handleValidDate(cell.value)}</>,
  //       },

  //     ]
  //     // [handleCustomerClick]
  //   );
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handlerSubmitOpen = (e) => {
    //console.log(e);
    e.preventDefault();
    if (
      !customerInfo?.cardNumber ||
      !customerInfo?.receiverType ||
      !customerInfo?.value ||
      !customerInfo?.value ||
      !channelId ||
      !cycle ||
      !openDate ||
      !billAmount
    ) {
      showToastMessage("please provide required values");
      return;
    }
    //console.log(numberOfMonths);
    //console.log(closeDate);
    //console.log();
    //console.log("first");
    const data = {
      cardNumber: customerInfo?.cardNumber,
      receivertype: customerInfo?.receiverType,
      customerId: customerInfo?.value,
      noOfMonthDay: cycle == "month" ? numberOfMonths : numberOfDays,
      endDate:
        cycle === "month"
          ? addMonths(Number(numberOfMonths), dateee)
          : addDays(Number(numberOfDays)),
      startDate: openDate,

      openDate: openDate,
      billingCycle: cycle,
      billAmount: billAmount,
      channelPackageId: channelId,
    };
    e.target.reset();
    openAnalogCardRegister(data);
    setmodal_list(false);
    //console.log(data);

    // showToastMessage("succefully opened card");
  };

  const editPop = (data) => {
    //console.log(data);
    setmodal_list(true);
    setCardNumber(data.cardNumber);
    setBillingCycle(data.billingCycle);
    setCardType(data.receiverType);
    setOpenDate(data.open_date);
    // setCloseDate(data.closeDate);
    setCustomer(data.customerId.customerName);
    setCardPackage(data.channel_package);
  };

  const CvsData = [];
  for (let i = 0; i < expireCards?.length; i++) {
    //console.log(expireCards[i]._id);

    var dropdownList = {
      customer: expireCards[i]?.customerName,
      phone: ` ${expireCards[i]?.customerPhone}`,

      cardNumber: `${expireCards[i]?.cardNumber}`,
      endDate: `${expireCards[i]?.endDate}`,
    };

    CvsData.push(dropdownList);
  }

  //   //console.log(CvsData[0]);
  //   //console.log(OpenCards)
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  //   // ** Downloads CSV
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  const setbilling = (e) => {
    setBillingCycle(e);

    //console.log(e);
    let type = e;

    if (type === "Custom") {
      // document.getElementById("customfield").style.display = "block";
      // document.getElementById("billamount").setAttribute("md={4}");
    } else {
      // document.getElementById("customfield").style.display = "none";
      // document.getElementById("billamount").setAttribute("md={8}");
    }
  };

  let neeew = "11-11-2022";
  if (billingCycle === "monthly") {
    //console.log(openDate);
    const test = moment(openDate).utc().format("MM/DD/YYYY");
    //console.log(test);
    neeew = new Date(test);
    neeew.setDate(neeew.getDate() + 30);
    // neeew.setMonth(neeew.getMonth() + 1);
    // setCloseDate(setDate(openDate.getDate() + 5));
    // //console.log(closeDate);
    //console.log(neeew);
    // neeew.toLocaleDateString("en-US");
    // setCloseDate(neeew);
    // //console.log(closeDate);
  }

  // const test = moment(openDate).utc().format("MM/DD/YYYY");
  // const yep = new Date(test);
  // const day = yep.getDate() + 1;
  // const moonth = yep.getMonth() + 1;
  // const year = yep.getFullYear();
  // //console.log(yep);
  // //console.log(day);
  // //console.log(moonth);
  // //console.log(year);

  const customizeDate = (month = 0, dayy = 0) => {
    //console.log(month);
    const test = moment(openDate).utc().format("MM/DD/YYYY");
    const yep = new Date(test);
    const day = yep.getDate() + 1 + Number(dayy);
    const moonth = `${yep.setMonth(yep.getMonth() + Number(month))}`;
    const year = yep.getFullYear();

    //console.log(day, moonth, year);

    const foor = day + "-" + moonth + "-" + year;
    const f = moment(foor).format("D/MM/YYYY");

    setCloseDate(f);
    // //console.log(yep.getMonth());
  };

  //console.log(openDate);

  function dateWithMonthsDelay(months) {
    //console.log(months);
    const date = new Date(openDate);
    date.setMonth(date.getMonth() + months);
    // setCloseDate(date);

    return date;
  }

  //console.log(closeDate);
  // //console.log();
  // //console.log(neeew);
  //console.log("type is" + typeof neeew);

  const chan = () => {
    setTyp("date");
  };

  //   const sortCard = [];
  //   for (let i = 0; i < OpenCards?.length; i++) {
  //     //console.log(OpenCards[i]?._id);
  //     let openDate = OpenCards[i].openDate.split("T");
  //     const formattedDate = moment(openDate[0]).format("DD-MM-YYYY");
  //     let endDate = OpenCards[i].endDate.split("T");
  //     const formattedDate2 = moment(endDate[0]).format("DD-MM-YYYY");
  //     let singleArr = [
  //       i + 1,
  //       OpenCards?.[i]?.customerId?.customerName,
  //       OpenCards[i]?.customerId?.customerPhone,
  //       OpenCards[i]?.customerId?.customerAddress,

  //       OpenCards[i]?.cardNumber,
  //       OpenCards[i]?.receivertype,
  //       formattedDate,
  //       formattedDate2,
  //       OpenCards[i]?._id,
  //     ];

  //     sortCard.push(singleArr);
  //   }

  //   //console.log(sortCard);

  //   const customerCards = [];

  //   for (let i = 0; i < cardList.length; i++) {
  //     // //console.log(customers[i]._id);

  //     var dropdownList = {
  //       value: cardList?.[i]?.dataCustomers?._id,
  //       cardNumber: cardList?.[i]?.cardNumber,
  //       receiverType: cardList?.[i]?.datareceivers?.itemType,
  //       label: `${cardList?.[i]?.dataCustomers?.customerName} -- ${cardList?.[i]?.dataCustomers?.cardNumber}`,
  //     };

  //     customerCards.push(dropdownList);
  //   }

  //   //console.log(cardList);

  function addMonths(numOfMonths, date = new Date()) {
    //console.log(numOfMonths);
    date.setMonth(date.getMonth() + numOfMonths);
    setCloseDate(date);

    return date;
  }

  function addDays(days) {
    var result = new Date(openDate);
    result.setDate(result.getDate() + days);
    setCloseDate(result);
    return result;
  }

  // //console.log(closeDate);
  //console.log(closeDate);
  // 👇️ Add 2 months to current Date
  // const result = addMonths(2);

  // 👇️ Add months to another date
  const dateee = new Date(openDate);
  const someFn = (row) => {
    //console.log(row);
    setmodal_delete(true);
    const selectedRow = {
      name: row.customerId?.customerName,
      address: row.customerId?.customerAddress,
      cardNumber: row.cardNumber,
      receiverType: row.receiverType,
      _id: row._id,
      openDate: row.openDate,
      closeDate: row.endDate,
    };
    setInfo(selectedRow);
  };

  //console.log(info);
  const closeCard = () => {
    const data = {
      id: info._id,
      description: status,
    };
    //console.log(data);
    closeAnalogCard(data);
    setStatus("");
    setmodal_delete(false);
  };

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };
  const handleSms = (e) => {
    e.preventDefault();

    if (checked == false) {
      const data = {
        message: contents,
        userId: userId,
        expiredIpvts: iptvAllExpired,
      };
      //console.log(data);
      sendSmsToIPTVcustomers(data);
      setContent("");
    }

    if (checked == true) {
      const data = {
        message: contents,
        userId: userId,
        expiringIptvCards: iptv3DaysExpired,
      };
      //console.log(data);
      sendSmsToiptv3daysRemaingcustomers(data);
      setContent("");
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Send SMS To IPTV Expired Customers "
            pageTitle="Send SMS To IPTV Expired Customers"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {/* <h4 className="card-title mb-0" style={{paddingRight:"24px"}}>
                            Send SMS To Expired Customers( <span className="text-warning">  {expireCards.length} ) </span>
                          </h4> */}
                          <div className="">
                            <div className="d-flex justify-content-sm-end pr-3">
                              <div>
                                <span
                                  className="form-check form-switch form-switch-lg mb-3"
                                  style={{ float: "right" }}
                                >
                                  <span
                                    style={{ marginTop: "7%", float: "right" }}
                                  >
                                    {checked
                                      ? "Udir Customers 3 Maalin u dhimantay"
                                      : "Udir Customers oo ka wada dhacsanyahay"}
                                  </span>

                                  <Input
                                    style={{ marginTop: "9%" }}
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="ISCash"
                                    onChange={handleChange}
                                    value={checked}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          {checked == false ? (
                            <Button
                              color="success"
                              className="add-btn me-1 mx-2"
                              id="create-btn"
                              onClick={() => tog_list()}
                            >
                              <i className="fas fa-sms fx-3 px-3"></i>
                              Send SMS To ALL Customers
                            </Button>
                          ) : (
                            <Button
                              color="success"
                              className="add-btn me-1 mx-2"
                              id="create-btn"
                              onClick={() => tog_list()}
                            >
                              <i className="fas fa-sms fx-3 px-3"></i>
                              Send SMS To 3Days Remaing Customers
                            </Button>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                {/* {load()} */}
                <CardBody>
                  <div id="Purchasestable">
                    <Row className="g-4 mb-3"></Row>

                    {/* <TableContainer
                        columns={columns}
                        data={expireCards || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        loading={true} */}

                    {/* // handleCustomerClick={handleCustomerClicks}
                        // isCustomerFilter={true}
                      /> */}

                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      progressPending={isNewCableLoading}
                      progressComponent={<SpinnerBorder />}
                      persistTableHead
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Open Closed Card Distribution From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">
            {" "}
            Send SMS To All Expired Customers :{" "}
            <span className="text-danger">
              (* You are sending SMS to{" "}
              <span className="text-primary"> {expireCards.length}</span>{" "}
              Customers
            </span>{" "}
            *)
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={handleSms}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
              />
            </div>
            <Row>
              <Col>
                <div className="form-group">
                  <label>Message</label>
                  <Input
                    disabled={isSending}
                    type="textarea"
                    style={{ backgroundColor: "white", color: "#333" }}
                    onChange={handleContentChange}
                    value={contents}
                  />
                </div>
              </Col>
              {/* <Col>
              <div className="form-group">
                <label>Message</label>
                
              </div>
              </Col> */}
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>

              {isSending == true ? (
                <div
                  className="hstack gap-2 justify-content-end"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <button
                    disabled
                    type="button"
                    className="btn btn-success btn-load"
                  >
                    <span className="d-flex align-items-center">
                      <span
                        className="spinner-border flex-shrink-0"
                        role="status"
                      >
                        <span className="visually-hidden">Sending...</span>
                      </span>
                      <span className="flex-grow-1 ms-2 text-center">
                        Sending...
                      </span>
                    </span>
                  </button>
                </div>
              ) : (
                <div className="hstack gap-2 justify-content-end">
                  <Button type="submit" className="btn btn-success">
                    Save
                  </Button>
                </div>
              )}
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
        style={{ width: "800px" }}
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className=" mx-4 mb-0">
                Are you Sure You want to Close{" "}
                {`  ${info?.name}'s card number ${info?.cardNumber}  who lives in ${info?.address}`}{" "}
                whose open date is
                <h6 className="text-primary">
                  {" "}
                  {handleValidDate(info?.openDate)}{" "}
                </h6>{" "}
                and close date is{" "}
                <h6 className="text-primary">
                  {" "}
                  {handleValidDate(info?.closeDate)} {}{" "}
                </h6>
                ?
              </p>
            </div>
            <Col id="billamount" className="mt-4">
              <div className="mb-3">
                <label className="form-label">
                  Description <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="amount-field"
                  className="form-control"
                  placeholder="Enter description"
                  required
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                ></input>
              </div>
            </Col>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              No
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => closeCard()}
            >
              Yes, Close it
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default SendSmsToIptvCustomers;
const SpinnerBorder = () => {
  return <Spinner className="my-2 text-center" />;
};
