import React, { useState, useEffect, useContext, useMemo } from "react";

import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import moment from "moment";
import styles from "./StyledTable.module.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import DataTable from "react-data-table-component";
const RevenueReport = () => {
  const [Discription, setdescription] = useState("");
  const { openAnalogCardRegister } = useContext(LoginContex);

  //   const [customerId, setCustomer] = useState();

  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [service, setService] = useState("424");
  const [summary, setSummary] = useState("");
  const [type, setType] = useState();
  const [helper, setHelper] = useState(false);
  const [customerId, setcustomerId] = useState("");
  const [middleWare, setmiddleWare] = useState("");
  const [Line, setsetLine] = useState("all");

  document.title = "Ticket Registration Report";
  //console.log(service);
  const {
    cardList,

    fetchCustomers,
    customers,

    custiptvRepot,
    fetchCustIPTVReport,
    isLoading,
    FetchRevenuReport,
    paymentReport,
  } = useContext(LoginContex);

  useEffect(() => {
    fetchCustomers();
    let date = new Date();
    const start = moment(date).format("YYYY-MM-DD");
    const end = moment(date).format("YYYY-MM-DD");
    // fetchCustIPTVReport(start, end,"APP");
    // FetchRevenuReport(start, end, "424");
  }, []);

  //console.log(custiptvRepot);
  //console.log(paymentReport);






  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  let  filteredItems = paymentReport?.filter((item) => (
    // for (let column of columns) {
    //   const value = column.selector(item);
    //   if (
    //     value &&
    //     value.toString().toLowerCase().includes(filterText.toLowerCase())
    //   ) {
    //     return true;
    //   }
    // }
    // return false;
    item.requestBody?.billInfo?.invoiceId&&
    item.requestBody?.billInfo?.invoiceId?.toLowerCase().includes(filterText.toLowerCase()) && item.requestBody?.billInfo?.paidBy ||
    item.requestBody?.billInfo?.paidBy?.toString().toLowerCase().includes(filterText.toLowerCase()) 
  ));
  //console.log(filteredItems);

let   Four24Count = filteredItems?.reduce((accu, curr)=>{
    return accu +Number(curr?.transacionInfo?.amount)
  },0)


  //console.log(Four24Count)
  // const columns = [
  //   {
  //     name: (
  //       <h6 style={{ fontWeight: "bold", width: "400px" }}> Customer Name</h6>
  //     ),
  //     selector: (row) => row?.customer_info?.customerName,
  //   },

  //   {
  //     name: <h6 style={{ fontWeight: "bold" }}> Phone</h6>,
  //     selector: (row) => row?.customer_info?.customerPhone,
  //   },
    
  //   {
  //     name: <h6 style={{ fontWeight: "bold" }}> Card Number</h6>,
  //     selector: (row) => row?.customer_info?.cardNumber,
  //   },
  //   {
  //     name: <h6 style={{ fontWeight: "bold" }}> Paid Number</h6>,
  //     selector: (row) => row?.requestBody?.billInfo?.paidBy,
  //   },
  //   {
  //     name: <h6 style={{ fontWeight: "bold" }}> Paid At</h6>,
  //     selector: (row) => row?.requestBody?.billInfo?.paidAt,
  //   },
  //   {
  //     name: <h6 style={{ fontWeight: "bold" }}> Paid Date</h6>,
  //     selector: (row) =>
  //       moment(row?.requestBody?.billInfo?.paidDate)
  //         .format("DD-MM-YYYY"),
  //   },

  //   {
  //     name: <h6 style={{ fontWeight: "bold" }}>Amount :{Number(Four24Count).toFixed(1) } </h6>,
  //     selector: (row) => row?.transacionInfo?.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
  //   },
  // ];
  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> Paid By</h6>,
      selector: (row) => row?.requestBody?.billInfo?.paidBy,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Card Number</h6>,
      selector: (row) => row?.requestBody?.billInfo?.invoiceId,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> paidAt</h6>,
      selector: (row) => row?.requestBody?.billInfo?.paidAt,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> paid Date</h6>,
      selector: (row) =>  moment(row.requestBody?.billInfo?.paidDate).utc().format("DD-MM-YYYY"),
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> transacionInfo</h6>,
      selector: (row) => Number(row.transacionInfo?.amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Currency</h6>,
      selector: (row) => row.transacionInfo?.currency,
    },
  ];
  // //console.log(iptvCustomerRechargeData);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);


 


  //console.log(filteredItems);
  
  const [filterText1, setFilterText1] = React.useState("");
  const [resetPaginationToggle1, setResetPaginationToggle1] =
    React.useState(false);
  const filteredItems1 = paymentReport?.filter((item) => (
    // for (let column of columns) {
    //   const value = column.selector(item);
    //   if (
    //     value &&
    //     value.toString().toLowerCase().includes(filterText.toLowerCase())
    //   ) {
    //     return true;
    //   }
    // }
    // return false;
    item.customer_info?.customerName&&
    item.customer_info?.customerName?.toLowerCase().includes(filterText1.toLowerCase()) && item.customer_info?.customerPhone ||
    item.customer_info?.customerPhone?.toString().toLowerCase().includes(filterText1.toLowerCase()) 
  ))


  const hormuudMoney = filteredItems1?.reduce((accu, curr)=>{
    //console.log(curr?.hormuudMoney)
    if(curr?.hormuudMoney){
      return accu + Number(curr?.hormuudMoney)

    }
    return accu
  },0)

  //console.log(hormuudMoney)
let mon = 18373773
  const columns1 = [
    {
      name: (
        <h6 style={{ fontWeight: "bold", width: "400px" }}> Customer Name</h6>
      ),
      selector: (row) => row?.customer_info?.customerName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Phone</h6>,
      selector: (row) => row?.customer_info?.customerPhone,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Lindline</h6>,
      selector: (row) =>
        row?.cusomerTell,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Address</h6>,
      selector: (row) => row?.customer_info?.customerAddress,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> description</h6>,
      selector: (row) => row?.description,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>hormuudMoney : {hormuudMoney.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h6>,
      selector: (row) => `${row?.hormuudMoney ?`${row?.hormuudMoney.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`:"No Money"}`,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}>subscriptionId</h6>,
      selector: (row) => row?.subscriptionId,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> paid Date</h6>,
      selector: (row) => moment(row?.createdAt
        ).format("DD-MM-YYYY"),
    },
  ];

  // //console.log(iptvCustomerRechargeData);
  const subHeaderComponentMemo1 = React.useMemo(() => {
    const handleClear1 = () => {
      if (filterText1) {
        setResetPaginationToggle1(!resetPaginationToggle1);
        setFilterText1("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText1(e.target.value)}
          onClear={handleClear1}
          filterText={filterText1}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText1, resetPaginationToggle1]);
  const [filterText2, setFilterText2] = React.useState("");
  const [resetPaginationToggle2, setResetPaginationToggle2] =
    React.useState(false);
  const filteredItems2 = paymentReport?.filter((item) => (
    // for (let column of columns) {
    //   const value = column.selector(item);
    //   if (
    //     value &&
    //     value.toString().toLowerCase().includes(filterText.toLowerCase())
    //   ) {
    //     return true;
    //   }
    // }
    // return false;
    item.phoneNumber&&
    item.phoneNumber?.toLowerCase().includes(filterText1.toLowerCase()) && item.subscriptionId ||
    item.subscriptionId?.toString().toLowerCase().includes(filterText1.toLowerCase()) 
  ))
;

const merchantTotal = filteredItems2.reduce((accu, curr)=>{

  if(curr?.amount){
    return accu + curr?.amount
  }

  return accu

},0)

 
  const columns2 = [
 

    {
      name: <h6 style={{ fontWeight: "bold" }}> Phone Number</h6>,
      selector: (row) => row?.phoneNumber ?row?.phoneNumber:"No Phone Number",
    },
    // {
    //   name: <h6 style={{ fontWeight: "bold" }}> serial Number</h6>,
    //   selector: (row) => row?.serialNumber ?row?.serialNumber:"No SerialNumber",
    // },
    // {
    //   name: <h6 style={{ fontWeight: "bold" }}> macAddress</h6>,
    //   selector: (row) => row?.macAddress ?row?.macAddress:"No MacAddress",
    // },
    {
      name: <h6 style={{ fontWeight: "bold" }}> subscription Id</h6>,
      selector: (row) => row?.transactionId ?row?.transactionId:"No Subscription Id",
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> substcription Type</h6>,
      selector: (row) => row?.substcriptionType ?row?.substcriptionType:"No substcriptionType",
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>amount : {merchantTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h6>,
      selector: (row) => `${row?.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> paid Date</h6>,
      selector: (row) => moment(row?.createdAt
        ).format("DD-MM-YYYY"),
    },
    // {
    //   name: <h6 style={{ fontWeight: "bold" }}> End Date</h6>,
    //   selector: (row) => moment(row?.endDate).format("DD-MM-YYYY"),
    // },
  ];
  
  //console.log(filteredItems);
  // //console.log(iptvCustomerRechargeData);
  const subHeaderComponentMemo2 = React.useMemo(() => {
    const handleClear2 = () => {
      if (filterText2) {
        setResetPaginationToggle2(!resetPaginationToggle2);
        setFilterText2("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText2(e.target.value)}
          onClear={handleClear2}
          filterText={filterText2}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText2, resetPaginationToggle2]);

  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const filterThroughDate = () => {
    setHelper(true);
    const startt = moment(start).format("YYYY-MM-DD");
    const enddd = moment(end).format("YYYY-MM-DD");
    // fetchCustIPTVReport(startt, enddd,type, Line);
    FetchRevenuReport(startt, enddd, service, summary);
    setmiddleWare(service);
  };

  const clearFilter = () => {
    setHelper(false);
    setStart("");
    setEnd("");
    setcustomerId("");
    setType("");
    setsetLine("");
  };

  const CustomersArr = [];

  for (let i = 0; i < customers.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: customers[i]._id,
      label: ` ${customers[i].customerName}---${customers[i].customerPhone}`,
    };

    CustomersArr.push(dropdownList);
  }

  const customerCards = [];
  if(summary == 'summary'){
    filteredItems = paymentReport
  }
  const CvsData = [];
  for (let i = 0; i < filteredItems?.length; i++) {
    // //console.log(filteredItems[i]._id);

    let  dropdownList;
    if(summary == "summary"){
      Four24Count = paymentReport?.[0]?.totalNetAmount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
       dropdownList = {
      //  Total:  paymentReport?.[0]?.totalNetAmount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        // total:Four24Count
        
     
      };
    }
    else{
       dropdownList = {
        // customerName:` ${filteredItems[i]?.customer_info?.customerName}`,
        // customerPhone: ` ${filteredItems[i]?.customer_info?.customerPhone}`,
        cardNumber: ` ${filteredItems[i]?.requestBody?.billInfo?.invoiceId}`,
        paidBy: ` ${filteredItems[i]?.requestBody?.billInfo?.paidBy}`,
        paidAt: ` ${filteredItems[i]?.requestBody?.billInfo?.paidAt}`,
        paidDate: ` ${moment(filteredItems[i]?.requestBody?.billInfo?.paidDate).format("DD-MM-YYYY")}`,
          
           
        

        amount: ` ${filteredItems[i]?.transacionInfo?.amount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`,
        // total:Four24Count
     
      };
    }
  
    CvsData.push(dropdownList);
  }
  //console.log(CvsData);
  // //console.log(ticketSupervisorReport)

  function convertArrayOfObjectsToCSV(array) {
    let result;
  
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData?.[0] || []);
  
    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    array.forEach((item, index) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
  
        result += item[key];
  
        ctr++;
      });
  
      // Add a column for the last total
      if (index === array.length - 1) {
        result += columnDelimiter;
        result += `Total :${Four24Count }`;
      }
  
      result += lineDelimiter;
    });
  
    return result;
  }
  

  // ** Downloads CSV
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  const CvsData2 = [];
  for (let i = 0; i < filteredItems1?.length; i++) {
    // //console.log(filteredItems[i]._id);

    if (filteredItems1[i]?.customer_info?.customerName) {
      var dropdownList = {
        customerName:` ${filteredItems1[i]?.customer_info?.customerName}`,
        customerPhone: ` ${filteredItems1[i]?.customer_info?.customerPhone}`,
        Lindline: ` ${filteredItems1[i]?.cusomerTell}`,
        address: ` ${filteredItems1[i]?.customer_info?.customerAddress}`,
        description: ` ${filteredItems1[i]?.description}`,
        HormuudMoney: ` ${filteredItems1[i]?.hormuudMoney ?filteredItems1[i]?.hormuudMoney :"No Money" }`,
        subscriptionId: ` ${filteredItems1[i]?.subscriptionId}`,
        paidDate: ` ${moment(filteredItems1[i]?.createdAt).utc().format("YYYY-MM-DD")}`
     
      };
    }
  
    CvsData2.push(dropdownList);
  }
  // //console.log(CvsData);
  // //console.log(ticketSupervisorReport)


  function convertArrayOfObjectsToCSV2(array) {
    let result;
  
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData2?.[0] || []);
  
    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    array.forEach((item, index) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
  
        result += item[key];
  
        ctr++;
      });
  
      // Add a column for the last total
      if (index === array.length - 1) {
        result += columnDelimiter;
        result += `Total :${hormuudMoney}`;
      }
  
      result += lineDelimiter;
    });
  
    return result;
  }
  

  // ** Downloads CSV
  function downloadCSV2(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV2(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  const CvsData3 = [];
  for (let i = 0; i < filteredItems2?.length; i++) {
    // //console.log(filteredItems[i]._id);
    
    // if (filteredItems2[i]?.phoneNumber) {
      var dropdownList = {
        // customerName:` ${filteredItems2[i]?.customer_info?.customerName}`,
        customerPhone: ` ${filteredItems2[i]?.phoneNumber ?filteredItems2[i]?.phoneNumber:"No CustomerPhone"}`,
        substcriptionType: ` ${filteredItems2[i]?.substcriptionType?filteredItems2[i]?.substcriptionType:"No Type"}`,
        // macAddress: ` ${filteredItems2[i]?.macAddress?filteredItems2[i]?.macAddress :"No Mac address" }`,
        // serialNumber: ` ${filteredItems2[i]?.serialNumber ?filteredItems2[i]?.serialNumber : "No Serial Number" }`,
        subscriptionId: ` ${filteredItems2[i]?.transactionId ?filteredItems2[i]?.transactionId:"No subscriptionId"}`,
        amount: ` ${filteredItems2[i]?.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`,
        paidDate:` ${moment(filteredItems2[i]?.createdAt).utc().format("YYYY-MM-DD")}`

     
      };
    // }
  
    CvsData3.push(dropdownList);
  }
  // //console.log(CvsData);
  // //console.log(ticketSupervisorReport)


  function convertArrayOfObjectsToCSV3(array) {
    let result;
  
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(CvsData3?.[0] || []);
  
    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    array.forEach((item, index) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
  
        result += item[key];
  
        ctr++;
      });
  
      // Add a column for the last total
      if (index === array.length - 1) {
        result += columnDelimiter;
        result += `Total :${merchantTotal}`;
      }
  
      result += lineDelimiter;
    });
  
    return result;
  }
  

  // ** Downloads CSV
  function downloadCSV3(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV3(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Payment Report" pageTitle="Report" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-autod">
                      <div>
                        <ToastContainer />
                        <div className="">
                          <h4 className="card-title mb-0">Payment Report</h4>
                          {/* <Button>Go Back</Button> */}

                          <div
                            className="form-label"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            {service == "merchant" && (
                              <Button
                                color="success"
                                className=" add-btn mt-4"
                                onClick={() => {
                                  downloadCSV3(CvsData3)
                                }}
                                type="button"
                                //  id="create-btn"
                              >
                                <i
                                  className="fa fa-print mx-2"
                                  aria-hidden="true"
                                >
                                  {" "}
                                </i>
                                Download Excel For Merchant
                              </Button>
                            )}

                            {
                              service =="909" &&
                              <Button
                                color="success"
                                className=" add-btn mt-4"
                                onClick={() => {
                                  downloadCSV2(CvsData2)
                                }}
                                type="button"
                                //  id="create-btn"
                                
                              >
                                <i
                                  className="fa fa-print mx-2"
                                  aria-hidden="true"
                                >
                                  {" "}
                                </i>
                                Download Excel For 909
                              </Button>
                            }

                       {  service =="424" &&    <Button
                              color="success"
                              className=" add-btn mt-4"
                              onClick={() => {
                                downloadCSV(CvsData);
                              }}
                              type="button"
                              //  id="create-btn"
                            >
                              <i
                                className="fa fa-print mx-2"
                                aria-hidden="true"
                              >
                                {" "}
                              </i>
                              Download Excel For 424
                            </Button>}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="mb-5">
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            From Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStart(date)}
                            value={start}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            To Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEnd(date)}
                            value={end}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <Label htmlFor="PurchasesDate" className="form-label">
                          Services <span className="text-danger">*</span>
                        </Label>

                        <select
                          className="form-select "
                          id="itemtype"
                          onChange={(e) => {
                            setService(e.target.value);
                          }}
                        >
                          <option value="option">Select Option</option>
                          <option value="424">424</option>
                          <option value="909">909</option>
                          <option value="merchant">Salaam Bank</option>
                        </select>
                      </Col>
                      <Col md={2}>
                        <Label htmlFor="PurchasesDate" className="form-label">
                          Report Type <span className="text-danger">*</span>
                        </Label>

                        <select
                          className="form-select "
                          id="itemtype"
                          onChange={(e) => {
                            setSummary(e.target.value);
                          }}
                        >
                          <option value="option">Select Option</option>
                          <option value="Detailed">Detailed</option>
                          <option value="summary"> Summary </option>
                        </select>
                      </Col>

                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4 btn-info"
                            onClick={() => filterThroughDate()}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Proceed
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {paymentReport?.length == 1 && summary == "summary" && (
                      <Row>
                        {/* <Col md={6}> */}
                        <table className={styles.table}>
                          <thead>
                            <tr>
                              <th>Total Documents</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td> {paymentReport?.[0]?.count} </td>
                              <td>{paymentReport?.[0]?.totalNetAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} </td>
                            </tr>
                          </tbody>
                        </table>
                        {/* </Col> */}
                      </Row>
                    )}

                    {middleWare == "424" && summary != "summary" && (
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        progressPending={isLoading}
                        progressComponent={<SpinnerBorder />}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        // selectableRows
                        persistTableHead
                        // sortFunction={customSort}
                      />
                    )}
                    {middleWare == "909" && summary != "summary" && (
                      <DataTable
                        columns={columns1}
                        data={filteredItems1}
                        progressPending={isLoading}
                        progressComponent={<SpinnerBorder />}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle1} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo1}
                        // selectableRows
                        persistTableHead
                        // sortFunction={customSort}
                      />
                    )}
                    {middleWare == "merchant" && summary != "summary" && (
                      <DataTable
                        columns={columns2}
                        data={filteredItems2}
                        progressPending={isLoading}
                        progressComponent={<SpinnerBorder />}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle2} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo2}
                        // selectableRows
                        persistTableHead
                        // sortFunction={customSort}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RevenueReport;
const SpinnerBorder = () => {
  return <Spinner className="my-2 text-center" />;
};
