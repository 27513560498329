import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  memo,
  isValidElement,
} from "react";
import DataTable from "react-data-table-component";
import { Grid, _ } from "gridjs-react";
import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroupItem,
  ListGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";

const Supplier = () => {
  const [modal_list, setmodal_list] = useState(false);
  const {
    itemRegisteration,
    fetchItems,
    items,
    updateItem,
    deleteSupplier,
    isLoading,User
    // su,
  } = useContext(LoginContex);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = items.filter(
    (item) =>
      item.supplierName &&
      item.supplierName.toLowerCase().includes(filterText.toLowerCase())
  );

  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [modal_delete, setmodal_delete] = useState(false);

  // states
  const [supplierId, setId] = useState();
  const [supplierName, setSuppliername] = useState("");
  const [supplierPhone, setSupplierPhone] = useState("");
  const [supplierAddress, setSupplierAddress] = useState("");
  const [supplierData, setSupplierData] = useState("");
  const [object_id, setObject] = useState();
  const [isUpdating, setIsUpdating] = useState(false);

  //context

  const suppliername = "";

  // //console.log(su);
  //performance
  // const [supItems, setsupItems] = useState(items);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  // const columns = [
  //   {
  //     name: <h6 style={{ fontWeight: "bold" }}> Name</h6>,
  //     selector: (row) => row.supplierName,
  //   },

  //   {
  //     name: <h6 style={{ fontWeight: "bold" }}> Phone Number</h6>,
  //     selector: (row) => row.supplierPhone,
  //   },
  //   {
  //     name: <h6 style={{ fontWeight: "bold" }}> Address</h6>,
  //     selector: (row) => row.supplierAddress,
  //   },

  //   {
  //     name: <h6 style={{ fontWeight: "bold" }}>Created At</h6>,

  //     selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
  //   },
  //   {
  //     name: <h6 style={{ fontWeight: "bold" }}> Actions</h6>,
  //     cell: (row) => (
  //       <>
  //         <button
  //           className="btn btn-primary mx-3"
  //           onClick={() => updateSupplier(row)}
  //         >
  //           {" "}
  //           <i className="fas fa-edit"></i>{" "}
  //         </button>
  //         {"     "}
  //         <button
  //           className="btn btn-danger"
  //           onClick={() => deleteSuppliers(row)}
  //         >
  //           <i className="fa fa-trash" aria-hidden="true"></i>
  //         </button>
  //       </>
  //     ),

  //     // ignoreRowClick: true,
  //     // allowOverflow: true,
  //     // button: true,
  //   },
  //   {
  //     /*end*/
  //   },
  // ];
  //console.log(isUpdating);
  useEffect(() => {
    fetchItems();
    //console.log("effect");
  }, []);

  // useEffect(() => {
  //   setsupItems(items);
  // }, []);

  // //console.log(supItems);
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  document.title = "Supplier Registration";
  let userId = User?.data?.user?._id || User?.user?._id;
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "supplierName",
        filterable: false,
      },
      {
        Header: "Phone Number",
        accessor: "supplierPhone",
        filterable: false,
      },
      {
        Header: "Address ",
        accessor: "supplierAddress",
        filterable: false,
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    updateSupplier(customerData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    deleteSuppliers(customerData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ]
    // [handleCustomerClick]
  );

  //console.log(userId);

  /// handling the submit form for creating supplier
  const handlerSubmit = (e) => {
    e.preventDefault();

    if (!isUpdating) {
      const data = {
        supplierName,
        supplierAddress,
        supplierId,
        supplierPhone,
        userId
      };
      //console.log(data);
      if (!supplierPhone || !supplierName) {
        return;
      }

      itemRegisteration(data);
      setmodal_list(false);
      setId("");
      setSupplierAddress("");
      setSuppliername("");
      setSupplierPhone("");
      setmodal_list(false);
    } else {
      const data = {
        supplierId,
        supplierName,
        supplierPhone,
        supplierAddress,
        id: supplierId,
      };
      if (!supplierId || !supplierName || !supplierPhone) {
        return;
      }
      if (isUpdating) {
        updateItem(data);
        setId("");
        setSupplierAddress("");
        setSuppliername("");
        setSupplierPhone("");
        setmodal_list(false);
      }
      setIsUpdating(false);
    }
  };
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handleChangePhone = (e) => {
    const limit = 10;

    // Here we are checking if the length is equal to 9
    if (e.target.value.length === 10) {
      showToastMessage("Phone number should not exceed 10 digits!");
      setSupplierPhone(e.target.value.slice(0, limit));
    }
    setSupplierPhone(e.target.value.slice(0, limit));
  };

  /// handler update form
  const updateSupplier = (data) => {
    //console.log(data);
    setmodal_list(true);
    setIsUpdating(true);
    setSupplierAddress(data.supplierAddress);
    setSuppliername(data.supplierName);
    setSupplierPhone(data.supplierPhone);
    setId(data._id);
    setObject(data._id);
  };

  const deleteSuppliers = (data) => {
    //console.log(data);
    setObject(data);
    tog_delete();
    // deleteSupplier(data._id);
  };

  const delSupplier = () => {
    deleteSupplier(object_id._id);
    setmodal_delete(false);
  };

  const addModal = () => {
    tog_list();
    setIsUpdating(false);
    setSupplierAddress("");
    setSuppliername("");
    setSupplierPhone("");
  };
  const sortSupplier = [];
  for (let i = 0; i < items?.length; i++) {
    let singleArr = [
      items[i].supplierName,
      items[i].supplierPhone,
      items[i].supplierAddress,
      items[i]._id,
    ];

    sortSupplier.push(singleArr);
  }

  const load = () => {
    if (isLoading) {
      return (
        <>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif"
                          style={{ backgroundColor: "white !", width: "700px" }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Supplier Registration" pageTitle="Registration" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />

                        <div>
                          <h4 className="card-title mb-0">Add Supplier</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  {load()}
                  <div id="customerList">
                    <Row className="g-4 mb-3"></Row>
                    <div className="table-responsive table-card mt-3 mb-1"></div>
                    {items.length > 0 && (
                      <TableContainer
                        columns={columns}
                        data={items || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        // handleCustomerClick={handleCustomerClicks}
                        // isCustomerFilter={true}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">
            {" "}
            {isUpdating ? "update supplier" : "Add New Supplier"}{" "}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>

            <div className="mb-3">
              <label htmlFor="email-field" className="form-label">
                Supplier <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="email-field"
                className="form-control"
                placeholder="Supplier name"
                required
                onChange={(e) => setSuppliername(e.target.value)}
                value={supplierName}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone-field" className="form-label">
                Phone Number <span className="text-danger">*</span>
              </label>
              <input
                onChange={handleChangePhone}
                type="number"
                id="phone-field"
                className="form-control"
                placeholder="Enter Phone no."
                required
                // onChange={(e) => setSupplierPhone(e.target.value)}

                value={supplierPhone}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone-field" className="form-label">
                Address
              </label>
              <input
                type="text"
                id="phone-field"
                className="form-control"
                placeholder="Supplier address."
                onChange={(e) => setSupplierAddress(e.target.value)}
                value={supplierAddress}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isUpdating ? "Update" : "Save"}
              </button>
              {/* <button
                type="button"
                className="btn btn-success"
                id="edit-btn"
                onClick={() => updatefn()}
              >
                Update
              </button> */}
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={delSupplier}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Supplier;
