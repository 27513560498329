import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Accordion,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";

// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
import classnames from "classnames";
import Select from "react-select";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Grid } from "gridjs-react";
import moment from "moment";

const ReportRoles = () => {
  //LOCAL states
  const {
    inventoryReport,
    inventoryReports,
    purchReport,
    purchaseReport,
    salesReport,
    getsalesReport,
    menus,
    getEmployee,
    getAllUsers,
    allUsers,
    fetchMenus,
    giveRoles,
    fetchSingleUserMenus,
    singleUserMenu,
    reset_menus,fetchSingleUserRoleReport,userRoleReport,giveReportRoles,reset_report
  } = useContext(LoginContex);
  const ref = useRef(null);
  const [userinfo, setUserInfo] = useState({
    languages: [],
  });
  const [employeeId, setEmployeeId] = useState("");
  const [checked, setChecked] = useState([]);

  const [testState, setTestState] = useState(singleUserMenu);
  const [gees, setGees] = useState([]);
  const incomingRoles =
    singleUserMenu?.[0]?.subItems?.filter((user) => user.status == true) || [];

  //console.log(incomingRoles);
  useEffect(() => {
    setTestState(singleUserMenu);
    // inventoryReport();
    // purchaseReport();
    getAllUsers();
    // getsalesReport();
    fetchMenus();
    fetchSingleUserRoleReport(employeeId?.value)
    fetchSingleUserMenus(employeeId?.value);
    //console.log("calling the state");
    setTestState(singleUserMenu);
  }, [employeeId?.value]);



  //console.log(checked);



  let meenus = [];
  for (let i = 0; i < userRoleReport?.length; i++) {

    userRoleReport[i].subItems.forEach((el) => {
      let singleArr = {
        subReportId: el.subReportId,
        subReportName: el.id,
        mainReportName: el.mainReportName,
        mainReportId: el.mainReportId,
        status: el.status,
        label: el.label,
        id: el.id,
        link: el.link,
        parentId: el.parentId,
        sqn: el.sqn,
      };
      meenus.push(singleArr);

      // //console.log(singleArr);
    });
  }
  //console.log(meenus);
  ////

  let temenu = [];
  for (let i = 0; i < userRoleReport?.length; i++) {
    //console.log(userRoleReport[i]._id);

    userRoleReport[i].subItems.forEach((el) => {
      //console.log(el);
      if (el.status == true) {
        let singleArr = {
            subReportId: el.subReportId,
            subReportName: el.id,
            mainReportName: el.mainReportName,
            mainReportId: el.mainReportId,
            status: el.status,
            label: el.label,
            id: el.id,
            link: el.link,
            parentId: el.parentId,
            sqn: el.sqn,
          };
        temenu.push(singleArr);
      }
    });
  }


  //console.log(temenu);

  //console.log(singleUserMenu);

  //console.log(incomingRoles);

  document.title = "Report Center";

  const [col1, setcol1] = useState(true);

  const t_col1 = () => {
    setcol1(!col1);
  };

  const Users = [];
//console.log(allUsers)
  for (let i = 0; i < allUsers.length; i++) {
    // //console.log(setEmployeeId[i]._id);
    if(allUsers[i]?.isAdmin == false){
      var dropdownList = {
        value: allUsers[i]?._id,
        label: ` ${allUsers[i]?.username}`,
      };
      Users.push(dropdownList);
  
    }
   
  }

  /// this is special code bro  //

  //console.log(Users);
  const main = menus?.subItems;
  //console.log(main);

  const o = [{ _id: "akram", status: true }];

  //console.log(meenus);
  //console.log(checked);
  //// the special code ends here //
  let tem = [];

  checked?.map((el) => {
    //console.log(el);
    tem.push(el);
  });

  //console.log(checked);
  const p = meenus.filter((val) => checked.includes(val));
  const the_menus = [...checked, ...meenus];
  // //console.log(tem);
  //console.log(the_menus);

  let listOfTags = [
      { id: 1, label: "Hello", color: "red", sorting: 0 },
      { id: 2, label: "World", color: "green", sorting: 1 },
      { id: 3, label: "Hello", color: "blue", sorting: 4 },
      { id: 4, label: "Sunshine", color: "yellow", sorting: 5 },
      { id: 5, label: "Hello", color: "red", sorting: 6 },
    ],
    keys = ["subReportId"],
    filtered = the_menus.filter(
      (
        (s) => (o) =>
          ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join("|"))
      )(new Set())
    );
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  //console.log(filtered)

 const submitForm = (e) => {
    e.preventDefault();
    const data = {
      userId: employeeId?.value,
      subReports: filtered,
    };

    if (!employeeId?.value) {
      showToastMessage("Please provide user");
      return;
    }

    giveReportRoles(data);
    //console.log(data);
    e.target.reset();
    setEmployeeId("");
    reset_report();
  };
  var good = []
  const handleCheck = (event) => {
    //console.log("current:--filtered--", filtered);
    let newMenu = {
        subReportId: event.target.value,
        mainReportId: event.target.getAttribute("idd"),
        mainReportName: event.target.getAttribute("mainReportName"),
        subReportName: event.target.getAttribute("subReportName"),
      label: event.target.getAttribute("labell"),
      link: event.target.getAttribute("link"),
      parentId: event.target.getAttribute("parentId"),
      id: event.target.getAttribute("ids"),
      status: true,
      sqn: Number(event.target.getAttribute("sqn")),
    };
    var CurrentSubMenu = filtered.filter(
      (m) => m.subReportId == event.target.getAttribute("subReportId")
    );

    //console.log(CurrentSubMenu);
    //console.log((CurrentSubMenu[0] = { ...newMenu }));
    if (!CurrentSubMenu[0]) CurrentSubMenu[0] = { ...newMenu };

    //console.log("majir", CurrentSubMenu[0]);
    CurrentSubMenu[0].status = event.target.checked;

    //console.log("Updated:---CurrentSubMenu-", CurrentSubMenu);
    let newTemenu = [
      ...filtered.filter(
        (m) => m.subReportId != event.target.getAttribute("subReportId")
      ),
    ];

    let updateTemenu = [...newTemenu];
    updateTemenu.push(...CurrentSubMenu);

    //  setChecked(updateTemenu);
    //console.log("Updated:----", updateTemenu);
    filtered = [...updateTemenu];
    //console.log("Updated:--filtered--", filtered);

  good = filtered;
    //console.log("goooooooood", good)

    // setGees(filtered)
    //console.log("Updated:--temenu--", temenu);
  };

//console.log(good)
  const result = the_menus.reduce((finalArr, current) => {
    let obj = finalArr?.find((item) => item.subReportId == current.subReportId);
    //console.log(obj);
    if (obj) return finalArr;
    return finalArr.concat([current]);
  }, []);


  // //console.log(temp_array);
  //console.log(incomingRoles);
  //console.log(checked);
  //console.log(filtered);
  //console.log(singleUserMenu);

  //console.log(filtered);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Report Role" pageTitle="Settings" />
          <Accordion className="border card-border-info">
            <AccordionItem>
              <h1 className="accordion-header" id="headingOne">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !col1,
                  })}
                  type="button"
                  onClick={t_col1}
                  style={{ cursor: "pointer" }}
                >
                  User Main Report
                </button>
              </h1>
              <Collapse
                isOpen={col1}
                className="accordion-collapse"
                id="collapseOne"
              >
                <div className="accordion-body">
                  <form onSubmit={submitForm}>
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <label className="form-label">Users </label>
                          <ToastContainer />
                          <div>
                            <Select
                              // className="bg-white"
                              options={Users}
                              onChange={(choice) => {
                                reset_report();
                                setEmployeeId(choice);
                                // setChecked(temenu);
                              }}
                              value={Users.filter(function (option) {
                                return option.value === employeeId?.value;
                              })}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                },
                              })}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {userRoleReport?.map((menu, i) => {
                        // //console.log(`${menu.title}  ---- ${menu._id}`);
                        // //console.log(menu);
                        return (
                          <Col lg={4} key={i}>
                            <Card className="border card-border-info">
                              <CardHeader className="card-secondary d-flex">
                                <Label
                                  className="form-check-label mx-2 text-white"
                                  htmlFor="activetableCheck01"
                                >
                                  <h5 className="text-white"> {menu.title} </h5>
                                </Label>
                              </CardHeader>

                              <CardBody>
                                <div className="table-responsive">
                                  <Table
                                    className="align-middle table-nowrap mb-0"
                                    id="myTable"
                                  >
                                    <thead>
                                      <tr className="card-info">
                                        <th
                                          scope="col"
                                          style={{ width: "40px" }}
                                        >
                                          <div className="form-check">
                                            {/* <Input
                                              className="form-check-input"
                                              type="checkbox"
                                              defaultValue=""
                                              id="activetableCheck"
                                            />
                                            <Label
                                              className="form-check-label"
                                              for="activetableCheck"
                                            ></Label> */}
                                          </div>
                                        </th>
                                        {/* <th scope="col">ID</th> */}
                                        <th scope="col">sub menus</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {menu?.subItems?.map((sub, i) => {
                                        // //console.log(sub);

                                        return (
                                          <tr key={i}>
                                            <th scope="row">
                                              <div className="form-check">
                                                <Input
                                                  ref={ref}
                                                  className="form-check-input mx-2"
                                                  type="checkbox"
                                                  role="switch"
                                                  defaultValue=""
                                                  id="activetableCheck01"
                                                  subReportId={sub.subReportId}
                                                  labell={sub.label}
                                                  link={sub.link}
                                                  parentId={sub.parentId}
                                                  value={sub.subReportId}
                                                  idd={sub.mainReportId}
                                                  ids={sub.label}
                                                  mainReportName={
                                                    sub.mainReportName
                                                  }
                                                  sqn={sub.sqn}
                                                  // defaultChecked={sub.status}
                                                  defaultChecked={sub.status}
                                                  subReportName={sub.subReportName}
                                                  onChange={handleCheck}
                                                />
                                                <Label className="form-check-label">
                                                  <span className="text-dangerr">
                                                    {" "}
                                                    {sub.label}
                                                  </span>
                                                </Label>
                                               
                                              </div>
                                            </th>
                                            {/* <td> {i + 1} </td>
                                          <td> {sub.label} </td> */}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      })}

                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-success"
                          id="add-btn"
                          // onClick={}
                        >
                          Grant/Revoke Role
                        </button>
                      </div>
                    </Row>
                  </form>
                </div>
              </Collapse>
            </AccordionItem>
          </Accordion>
          <Row></Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReportRoles;
