import axios from "axios";
import { createContext, useReducer, useRef, useMemo } from "react";
import jwtDecode from "jwt-decode";
import moment from "moment";

import { useReactToPrint, ReactToPrint } from "react-to-print";
import "react-toastify/dist/ReactToastify.css";
import {
  DELETE_CUSTOMER_SUCCESS,
  DELETE_ITEMTYPE_SUCCESS,
  DELETE_products_SUCCESS,
  DELETE_SITE_SUCCESS,
  DELETE_STORE_SUCCESS,
  DELETE_SUPPLIER_SUCCESS,
  FETCH_AGENTS_SUCCESS,
  FETCH_CHANELLS_SUCCESS,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_ITEMS_SUCCESS,
  FETCH_QUANTITY_AV_SUCCESS,
  FETCH_SITES_SUCCESS,
  DELETE_ASSIGNGROUP_SUCCESS,
  FETCH_STORE_SUCCESS,
  FETCH_UPDATED_MANAGE_SUCCESS,
  REGISTER_CUSTOMER_ERROR,
  REGISTER_CUSTOMER_SUCCESS,
  REGISTER_ITEM_ERROR,
  REGISTER_ITEM_SUCCESS,
  REGISTER_ITEM_TYPE_SUCCESS,
  REGISTER_ITEM__SUCCESS,
  REGISTER_PAYMENT__SUCCESS,
  REGISTER_PURCHASES__SUCCESS,
  REGISTER_SITE_SUCCESS,
  REGISTER_STOCKS__SUCCESS,
  REGISTER_STORE_SUCCESS,
  REGISTER_USER,
  FETCH_EMPLOYEE_SUCCESS,
  REGISTER_EMPLOYEE_ERROR,
  REGISTER_EMPLOYEE_SUCCESS,
  FETCH_APIHORMUUDIPTV_SUCCESS,
  DELETE_GROUP_SUCCESS,
  FETCH_GROUPS_SUCCESS,
  FETCH_EMPTITLES_SUCCESS,
  FETCH_GETALLTITLE_AV_SUCCESS,
  FETCH_DEPARTMENT_AV_SUCCESS,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_TITLE_SUCCESS,
  GET_EMPLOYEE__SUCCESS,
  GET_ADVANCE__SUCCESS,
  FETCH_ALL_IPTV_SUCCESS,
  REGISTER_ADVANCE_SUCCESS,
  REGISTER_ADVANCE_ERROR,
  FETCH_CARDS_SUCCESS,
  FETCH_OPEN_CARDS_SUCCESS,
  FETCH_CABLES_SUCCESS,
  REGISTER_DEDUCTION__SUCCESS,
  DELETE_DEDUCTION_SUCCESS,
  REGISTER_DEDUCTION__ERROR,
  FETCH_DEDUCTION_SUCCESS,
  REGISTER_DEPARTMENT_SUCCESS,
  REGISTER_DEPARTMENT_ERROR,
  REGISTER_TITLE_ERROR,
  REGISTER_TITLE_SUCCESS,
  FETCH_ZONES_SUCCESS,
  FETCH_ALL_ZONES_SUCCESS,
  FETCH_GROUP_ZONES_SUCCESS,
  REGISTER_ZONES_ERROR,
  // FETCH_GETBYIDZONES_SUCCESS,
  REGISTER_ZONES_SUCCESS,
  FETCH_GROUPSTOASSIGN_SUCCESS,
  FETCH_ZONESTOASSIGN_SUCCESS,
  FETCH_EMPLOYEESTOASSIGN_SUCCESS,
  REGISTER_GROUP__SUCCESS,
  REGISTER_GROUP__ERROR,
  DELETE_EMPLOYEE_SUCCESS,
  LOGUT_USER,
  FETCH_SINGLE_PURCHASE_ID,
  FETCH_SINGLE_CUSTOMER,
  FETCH__RECEIVERS,
  FETCH_SINGLE_SITE_CARDS,
  REGISTER_ASSIGNGROUPS_SUCCESS,
  REGISTER_ASSIGNGROUPS_ERROR,
  FETCH_SINGLE_CARD_NUMBER,
  Fetch_associated_receivers,
  FETCH_PURCHASED_ASSOCIATED_RECEIVERS_BY_ID,
  REGISTER_PAYMENT__AMOUNT,
  GET_BYID_CUSTOMERS_SUCCESS,
  GET_BYID_RECIVE_SUCCESS,
  ITEM_NOT_RECEIVER,
  FETCH_RECEIVER_ITEM,
  FETCH_RECEIVER_CARD_NUMBER_AND_SERIALS,
  FETCH_GETZONES_SUCCESS,
  GET_BYID_GROUP_ALL_SUCCESS,
  FETCH__ITEMRECEIVERS,
  GET_BYID_GROUP_SUCCESS,
  FETCH_GETBYIDZONES_SUCCESS,
  FETCH_GETBYIDEMPLOYEE_SUCCESS,
  GET_TICKETS__SUCCESS,
  FETCH_ASSIGNGROUPS_SUCCESS,
  FETCH_PURCHASE_ORDERS,
  FETCH_PURCHASE_ORDERS_BY_ID,
  DELETE_ZONES_SUCCESS,
  REGISTER_PURCHASEORDERS__SUCCESS,
  GET_BYID_GROUP_CABLE_ALL_SUCCESS,
  FETCH_GETBYIDZONESCABLE_SUCCESS,
  FETCH_ALL_PURCHASES,
  FECH_PURCHASE_RECEIVE_BY_ID,
  FECH_PURCHASE_ORDERS_RECEIVERS,
  FETCH_PURCHASE_ORDERS_BY_ID_RECEIVER,
  FETCH_PENDING_TICKETS,
  REGISTER_COMPLETETICKETS_ERROR,
  GET_BYID_CARD_NUMBER,
  REGISTER_IPTV_ERROR,
  FETCH_IPTV_BY_ID,
  FETCH_IPTV_HORMUUD_ERROR,
  FETCH_INVENTORY_REPORTS,
  FETCH_PURCHASE_REPORTS,
  FETCH_SALES_REPORTS,
  DELETE_BANK_SUCCESS,
  FETCH_BANK_SUCCESS,
  REGISTER_BANK_SUCCESS,
  REGISTER_BANK_ERROR,
  FETCH_ITEMBYID_SUCCESS,
  PURCHASE_REPORT_DATE,
  PURCHASE_SALES_DATE,
  PAYMENT_BY_ID,
  PAYMENT_FILTER_DATE,
  UPLOADED_ERRORS,
  MANAGE_PURCHASE_FILTER,
  FETCH_CABLE_BIGIN,
  FETCH_GROUPS_BIGIN,
  FETCH_LAST_SERIAL,
  FETCH_COMMISSION_SUCCESS,
  FETCH_PAYROLLYEARMONTH_SUCCESS,
  REGISTER_PAYROLL_ERROR,
  FETCH_CABLE_INFO,
  ITEM_NOT_RECEIVER_by_id,
  FETCH_LAST_SERIAL_sp,
  FETCH_LAST_SERIAL_sp_SINGLE,
  FETCH_RECEIVER,
  TRANSFER_ITEM,
  FETCH_PENDINGPAYROLL_SUCCESS,
  RESET_ITEM_TRANSFER,
  RESET_RECEIVER_TRANSFER,
  RESET_BILLING_RECEIVER,
  RESET_BILLING_ITEM,
  RESET_ITEM_SALES_ITEM,
  RESET_ITEM_SALES_RECEIVER,
  FETCH_COMMISSIONMAIN_SUCCESS,
  RESET_QUANTITY_AVAILABLE,
  GET_ALL_USER,
  GET_CUSTOMER_CARD_LIST,
  FETCH_SINGLE_EMPLOYEE,
  GET_CLOSED_CARDS,
  GET_MENUS,
  LOADING2,
  RECEIVER_DISTURBUTION_BEGIN,
  RECEIVER_DISTURBUTION_SUCCESS,
  ITEM_DISTURBUTION_BEGIN,
  ITEM_DISTURBUTION_SUCCESS,
  ITEM_REG_BEGIN,
  ITEM_REG_SUCCESS,
  SUPPLIER_REG_BEGIN,
  SUPPLIER_REG_SUCCESS,
  CUSTOMER_REG_BEGIN,
  SITE_REG_BEGIN,
  STORE_REG_BEGIN,
  SALESAGENT_REG_BEGIN,
  PACKAGE_REG_BEGIN,
  GET_SUPERVISORS,
  GET_SUPERVISORS_SPECIAL,
  RESET_THE_CARDNUMBER,
  GET_SINGLE_MENU,
  GET_SIDEBAR_MENU,
  AUTORECEPTS,
  GET_DISTRICTS,
  FETCH_HOR_IPT,
  FETCH_OPEN_CARD_REPORT,
  FETCH_ALL_OPEN_CARD_REPORT,
  FETCH_CABLE_REPORT,
  OPEN_CARD_REG_BEGIN,
  OPEN_CARD_REG_END,
  FETCH_CLOSE_CARDS_SUCCESS,
  FETCH_CLOSE_CARDS_REPORT,
  FETCH_PURCHASE_RECEIVE_REPORT,
  FETCH_PURCHASE_ORDER_REPORT,
  FETCH_CUSTOMER_LIST_REPORT,
  FETCH_CUSTOMER_STATEMENT_REPORT,
  FETCH_TICKET_REG_REPORT,
  FETCH_TICKET_SUPERVISOR_REPORT,
  FETCH_ADVANCE_REPORT,
  FETCH_ALL_PURCHASES_BEGIN,
  MANAGE_PURCHASE_FILTER_BEGIN,
  FETCH_PAYROLL_SUMMARY_REPORT,
  OTTP_BEGIN,
  DEDUCTION_REPORT,
  SUPPLER_REPORT,
  FETCH_EMPLOYEE_REPORT,
  FETCH_PAYROLL_DETAILS_REPORT,
  FETCH_TICKET_TRANSFER_REPORT,
  GET_ALL_YEARS,
  FETCH_TICKETS_SUMMARY_REPORT,
  FETCH_TICKET_SUPERVISOR_SUMMARY_REPORT,
  CUSTOMER_LIST,
  EXPIRE_CARDS,
  MANUAL_OPEN_CARD,
  PENDING_TRANSFER,
  TODAYS_TICKETS,
  TICKETS_SUPERVISOR_ADD_SUCESS,
  TICKETS_SUPERVISOR_ADD_BEGIN,
  TOP_TEN_USERS,
  REG_SHITS,
  IPTVS_ONLY,
  NASIYE_PAYMENTS,
  HUBI_LINE,
  HUBI_LINE_BEGIN,
  OPEN_IPTV_BEGIN,
  OPEN_IPTV,
  REGISTER_IPTV_SUCCESS,
  REGISTER_IPTV_BEGIN,
  IPTV_CUSTOMER_RECHARGE,
  FETCH_CUSTOMERIPTV_REPORT,
  OTT_CUSTOMER_RECHARGE,
  EXPIRED_IPTVS_REPORT,
  EXPIRED_OTT_REPORT,
  GET_MAC_ADDRESS_AND_SERIAL_NUMBER,
  FETCH_ONLY_CUSTOMERS_WITH_IPTVS,
  TRASFER_IPTV_SUCCESS,
  TRANSFER_IPTV_ERROR,
  TRANSFER_IPTV_BEGIN,
  SOLVED_REPORT,
  SOLVED_CUSTOMERS_REPORT,
  UPDATED_CUSTOMERS,
  UPLOAD_IPTV_CUSTOMERS,
  UPLOAD_IPTV_BEGIN,
  REGISTER_NEW_CABLE_BEGIN,
  REGISTER_NEW_CABLE_SUCCESS,
  REGISTER_NEW_CABLE_ERROR,
  REGISTER_NEW_ITEM_SALES_BEGIN,
  REGISTER_NEW_ITEM_SALES_SUCCESS,
  REGISTER_NEW_ITEM_SALES_ERROR,
  IPTV_CUSTOMER,
  THE_CUSTOMERS,
  THE_CUSTOMERS_SUCCESS,
  THE_CUSTOMERS_BEGIN,
  THE_CUSTOMERS_ERROR,
  REGISTER_IPT_ORGANIZATION_BEGIN,
  REGISTER_IPT_ORGANIZATION__SUCCESS,
  REGISTER_IPT_ORGANIZATION__ERROR,
  CUSTOMER_424_REPORT,
  CORESPONDING_EMP,
  SEND_SMS_SUCCESS,
  SEND_SMS_BEGIN,
  SEND_FINGERPRINT_BEGIN,
  SEND_FINGERPRINT_SUCCESS,
  SEND_FINGERPRINT_FAIL,
  SEND_SMS_FAIL,
  FETCH_CABLES_BEGIN_BY_DATE,
  FETCH_CABLES_SUCCESS_BY_DATE,
  FETCH_CABLES_ERROR_BY_DATE,
  AUTO_CHARGE_SUCCESS,
  AUTO_CHARGE_BEGIN,
  AUTO_CHARGE_ERROR,
  GET_ALL_ANALOG_EXPIRED_SUCCESS,
  GET_ALL_ANALOG_THREE_EXPIRED_BEGIN,
  GET_ALL_ANALOG_REMIAINGG_EXPIRED_SUCCESS,
  GET_ALL_IPTV_THREE_EXPIRED_BEGIN,
  GET_ALL_IPTV_REMIAINGG_EXPIRED_SUCCESS,
  GET_ALL_IPTV_THEE_EXPIRED_BEGIN,
  GET_ALL_IPTV_THEEE_EXPIRED_SUCCESS,
  GET_USER_REPORT_ROLE,
  FETCH_DEVICES_SUCCESS,
  DEVICES_REG_BEGIN,
  FETCH_ATTENDANCE_DATA,
  FETCH_ATTENDANCE_UPDATES,
  FETCH_AVAILABLE_CARDS,
  SEND_SMS_NOTIFICATION,
  FETCH_RESOLVED_TICKETS,
  FETCH_SMS_MESSAGES,
  FETCH_SOLD_CARDS,
  GET_TICKETS_COMPLETED_SUCCESS,
  CUST_SMS_SUCCESS,
  CUST_SMS_END,
  CUST_SMS_BEGIN,
  FETCH_EXPIRED_CARDS,
  FETCH_DAILY_ATTENDANCE,
  GET_ALL_IPTV_CUST
} from "./loginActions";
import reducer from "./loginReducer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

export const LoginContex = createContext();
// const addToLocalStorage = (user) => {
//   localStorage.setItem("user", JSON.stringify(user));
//   localStorage.setItem("token", JSON.stringify(user.accessToken));
//   // localStorage.setItem("OTP", JSON.stringify(user.OTP));
// };
const addOTPToLocalStorage = (otp) => {
  localStorage.setItem("OTP", JSON.stringify(otp));
};
const getIDlocalStroge = (id, status, useeer) => {
  localStorage.setItem("_id", JSON.stringify(id));
  localStorage.setItem("status", JSON.stringify(status));
  localStorage.setItem("useeer", JSON.stringify(useeer));
};

const removeIDFromLocalStorage = () => {
  localStorage.setItem("_id", "");
  localStorage.setItem("useeer", "");
};
// addOTPToLocalStorage()
// const removeFromLocalStorage = () => {
//   localStorage.setItem("user", "");
//   localStorage.setItem("OTP", "");
// };

const addToLocalStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};
const removeFromLocalStorage = () => {
  localStorage.setItem("user", "");
};
const user = localStorage.getItem("user");
const useeer = localStorage.getItem("useeer");
const ottp = localStorage.getItem("OTP");
////console.log(user);
////console.log(ottp);

const initialState = {
  User: user ? JSON.parse(user) : null,
  Otp: ottp ? JSON.parse(ottp) : null,
  OTPNumber: "",
  allUsers: [],
  showAlert: false,
  showAlertText: "",
  items: [],
  getByIdGroups: [],
  theUser: user && user != "undefined" ? JSON.parse(user) : null,

  purchaseOrders: [],
  GetByIdEmployee: [],
  GetTickets: [],
  getByIdZones: [],
  getZoneById: [],
  itemTypes: [],
  customers: [],
  IPTVS: [],
  getByIdGroups: [],
  getZoneById: [],
  GetPendingTickets: [],
  GetByIdEmployee: [],
  getByIdGroupsCable: [],
  GetTickets: [],
  isLoading: false,
  isLoadingGroups: false,
  sites: [],
  IPTVEdit: [],
  stores: [],
  products: [],
  purchases: [],
  empsGroups: [],
  getGroups: [],
  payments: [],
  stocks: [],
  manageSingle_id: "",
  channelPackage: [],
  reciveById: [],
  agents: [],
  Employees: [],
  getAllTitles: [],
  departments: [],
  getEmployee: [],
  getAdvance: [],
  getCustomersId: [],
  getDeduction: [],
  EmpTitle: [],
  getZonesById: [],
  ZoneList: [],
  GroupsForSite: [],
  ZonesForGroup: [],
  EmployeesFromZone: [],
  // AssignGroups : [],
  getGroupZones: [],
  quantityAvailable: "",
  singleCustomer: "",
  singleSiteCard: "",
  recievers: [],
  AssignGroups: [],
  itemGetAllReceiver: [],
  getByIdZonesCables: [],
  temp: "",
  singlePurchaseId: "",
  singleCardNumber: "",
  purchases_with_associated_receivers: [],
  purchases_with_associated_receiversBYids: [],
  ItemInfo: [],
  paymetAmount: "",
  itemsNotReceivers: [],
  itemsReceivers: [],
  cardAndSerials: [],
  OpenCards: [],
  Cables: [],
  purchaseOrders: [],
  singlePurchaseOrder: [],
  allPurchases: [],
  purchaseReceiveEdit: [],
  purchaseOrdersRec: [],
  IPTVHormuud: [],
  singlePurchaseOrderReceiver: [],
  getCardNumbers: [],
  inventoryReports: [],
  purchReport: [],
  salesReport: [],
  assignGroups: [],
  Banks: [],
  ItemsName: [],
  CustomerById: [],
  purcReportByDate: [],
  salesRepoDate: [],
  paymentByIDS: [],
  paymentByFilter: [],
  uploadedErrors: [],
  manageFilterByDate: [],
  fetchLastSerial: [],
  Commissions: [],
  cableInformation: "",
  itemsNotReceiversById: "",
  fetchlastsp: [],
  fetchSinglelastsp: [],
  getReceiver: [],
  transferItem: [],
  PayrollMain: [],
  PayrollYearMonth: [],
  PendingPayroll: [],
  cardList: [],
  closedCards: [],
  menus: [],
  isAlert: false,
  showAlert: false,
  alertText: "",
  supervisors: [],
  specialSupervisors: [],
  singleUserMenu: [],
  sidebarMenus: [],
  autorecepts: [],
  nasiyeReport: [],
  districts: [],
  horIptvs: [],
  openCardsReport: [],
  allopenCardReport: [],
  summaryCableReport: [],
  summaryCloseReport: [],
  purchaseRecReport: [],
  purchaseOrederReport: [],
  customerListReport: [],
  customerStatement: [],
  ticketRegReport: [],
  ticketSupervisorReport: [],
  deductionReport: [],
  supplierReport: [],

  employeeReport: [],
  custiptvRepot: [],

  advancesReport: [],
  payrollSummaryReport: [],
  payrollDetailsReport: [],
  allYears: [],
  titcketsSummary: [],
  titcketsSupervisorSummaryReport: [],
  customerList: [],
  expireCards: [],
  manualOpenCard: [],
  todaysTikcets: [],
  topTenUsers: [],
  shifts: [],
  iptvsOnly: [],
  openIptv: [],
  iptvCustomerRechargeData: [],
  ottCustomerRecharges: [],
  expirecdIptvsReport: [],
  expireNasiyeCustomersReport: [],
  activeNasiyeIptvs: [],
  activeNasiyeCustomers: [],
  macAddressAndSerialNumberData: [],
  customerWithIptvs: [],
  solvedReport: [],
  solvedCustomersReport: [],
  updatedCustomers: [],
  iptvCustomers: [],
  isNewCableLoading: false,
  iptvCustomer: [],
  the_customers: [],
  isOrgLoading: false,
  isReceiverUploadLoading: false, 
  bothcustomers: [],
  isChecking: false,
  customer424Report: [],
  corresponEmp: [],
  isSendingSMS: false,
  cablesManage: [],
  auto: [],
  userLoading: false,
  isSending: false,
  currentExpiredCards: [],
  analogAllExpired: [],
  analogThreeRemaingDays: [],
  iptvAllExpired: [],
  iptv3DaysExpired: [],
  userRoleReport: [],
  repRoles: [],
  paymentReport: [],
  isEnrolling:false,
  devices:[],
  attendanceData:[],
  attendanceUpdates:[],
  availableCards:[],
  customersNotification:[],
  resolvedTickets:[],
  smsMessages:[],
  soldReceivers:[],
  completedTickets:[],
  allExpredCustomerCards:[],
  dailyAttendanceReport:[],
  allIPtvCustomers:[],
  monthylyAttendanceReport:[],
  iptvOrganizationsReport:[],
  EmployeesByDepartments:[],
  EmployeeLeaves:[],
  LeaveCategories:[],
  RedlineCustomers:[],
  RedlineCardList:[],
  RedlineOpenCards:[],
  RedlineClosedCards:[],
  RedlineCardsReport:[],
  MultiShifts:[],
  EmployeesWithMultipleShifts:[],
  RedlineReciverById: [],
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const componentRef = useRef();

  // registering users
  const registerUser = async (user) => {
    ////console.log(user);
    try {
      const res = await axios.post("/register", user);
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({ type: REGISTER_USER });
      getAllUsers();
    } catch (error) {
      //console.log(error);
    }
  };

  //*send sms to expired analog customers
  const sendSmsTocustomers = async (data) => {
    ////console.log(data);
    try {
      dispatch({ type: "SMS_BEGIN" });
      const res = await axios.post(
        "/billings/analog/customers/sms/expired",
        data
      );
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({ type: "SMS_END" });
      getAllUsers();
    } catch (error) {
      //console.log(error);
    }
  };
  const sendSmsTo3daysRemaingcustomers = async (data) => {
    ////console.log(data);
    try {
      dispatch({ type: "days3_BEGIN" });
      const res = await axios.post(
        "/billings/analog/customers/sms/lessthan/three/expired",
        data
      );
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({ type: "days3_SUCCESS" });
      getAllUsers();
    } catch (error) {
      //console.log(error);
    }
  };
  //*send sms to expired iptv customers
  const sendSmsToIPTVcustomers = async (data) => {
    ////console.log(data);
    try {
      dispatch({ type: "SMS_IPTV_BEGIN" });
      const res = await axios.post(
        "/billings/iptv/customers/sms/expired",
        data
      );
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({ type: "SMS_IPTV_END" });
      getAllUsers();
    } catch (error) {
      //console.log(error);
    }
  };
  const sendSmsToiptv3daysRemaingcustomers = async (data) => {
    ////console.log(data);
    try {
      dispatch({ type: "SMS_IPTV_3DAYS_BEGIN" });
      const res = await axios.post(
        "/billings/iptv/customers/sms/lessthan/three/expired",
        data
      );
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({ type: "days3_REMAINIG_SUCCESS" });
      getAllUsers();
    } catch (error) {
      //console.log(error);
    }
  };

  const UpdateUser = async (user) => {
    ////console.log(user);
    try {
      const res = await axios.patch(`/register/update/${user.id}`, user);
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({ type: REGISTER_USER });
      getAllUsers();
    } catch (error) {
      //console.log(error);
    }
  };
  const getCurrentUserId = () => {
    const { _id } = localStorage.getItem("userData");
    //  let user= jwtDecode(jwt);
    return _id;
  };

  const cleaState = () => {
    dispatch({ type: "CLEAR_THE_STATE" });
  };
  // const getCurrentUserId=()=>{
  //    const jwt = localStorage.getItem("token");
  //    let user= jwtDecode(jwt);
  //    return user.id;
  // }
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  // const loginUser = async (user) => {
  //   ////console.log(user);
  //   try {
  //     dispatch({ type: "REGISTER_LOGIN|_FIRST" });
  //     const res = await axios.post("/login", user);
  //     ////console.log(res.message);
  //     ////console.log(res);
  //     // addToLocalStorage(res);
  //     getIDlocalStroge(res?.data?.user?._id, res.data.status, res);

  //     dispatch({ type: "REGISTER_LOGIN", payload: { res } });
  //     if (res.status == "fail") {
  //       dispatch({ type: "REGISTER_LOGIN_Fail", payload: { res } });
  //       ////console.log("wronggggggggggggggggggggggggggggggggggggggg");
  //       toast.error(res.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       ////console.log("ERRORRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR");
  //       return;
  //     } else {
  //       ////console.log("wronggssssssssssssssssssssssssssssss");
  //       ////console.log(res.message);

  //       toast.success("User logged in succefully", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       ////console.log("sucesssssssssssssssssssssssssssssssssss");
  //     }
  //   } catch (error) {
  //     // //console.log(error);
  //     showToastMessage("Invalid credentials");
  //     dispatch({ type: "REGISTER_LOGIN_Fail" });
  //   }
  // };

  const loginUser = async (user) => {
    ////console.log(user);
    dispatch({ type: "REGISTER_LOGIN_BEGIN" });
    try {
      const res = await axios.post("/login", user);
      ////console.log(res.message);
      ////console.log(res);
      if (res?.data?.status == "success") {
        addToLocalStorage(res);
      }

      dispatch({ type: "REGISTER_LOGIN", payload: { res } });
      if (res.status == "fail") {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        ////console.log("ERRORRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR");
        return;
      } else {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        ////console.log("sucesssssssssssssssssssssssssssssssssss");
      }
    } catch (error) {
      //console.log(error);
    }
  };
  // const confirmOTPP = async (OTTP) => {

  //   ////console.log(OTTP);
  //   try {
  //     dispatch({ type: "OTTP_BEGIN_KOOW" });
  //     const res = await axios.post("/checkOtp", OTTP);
  //     // addToLocalStorage(res);

  //     dispatch({ type: OTTP_BEGIN, payload: { res } });
  //     // dispatch({ type: "OTTP_BEGIN" });
  //     ////console.log(res);
  //     ////console.log(res?.data?.user?.OTP);

  //     if (res.data.status === "success") {
  //       const data = {
  //         name: "OTP",
  //         otpNumber: res?.data?.user?.OTP,
  //       };
  //       addToLocalStorage(res);

  //       addOTPToLocalStorage(data);
  //       toast.success(res.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });

  //       if (res?.data?.user?.OTP == OTTP.OTP) {
  //         setTimeout(() => {
  //           history.push("/dashboard");
  //         }, 500);
  //       }
  //     } else {
  //       toast.error(res.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
  //   } catch (error) {
  //     dispatch({ type: "OTTP_BEGIN_ERROR" });
  //     toast.error("invalid credentials", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };

  const resetOTPnum = () => {
    dispatch({ type: "RESET_OTP_NUM" });
  };
  // const = (number)=>{
  //   ////console.log(number);
  //   try {
  //     dispatch({ type: "GET_OTP_NUM" , payload:{number} });
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // }

  const getAllUsers = async () => {
    dispatch({ type: "REGISTER_USER_BEGINN" });
    try {
      const data = await axios.get("/users");
      dispatch({ type: GET_ALL_USER, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  const openIptvs = async (status) => {
    dispatch({ type: OPEN_IPTV_BEGIN });
    try {
      const data = await axios.get(`/nasiye/iptvs/report`);
      dispatch({ type: OPEN_IPTV, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const logoutUser = () => {
    dispatch({ type: LOGUT_USER });
    removeFromLocalStorage();
    removeIDFromLocalStorage();
    // removeCartItems()
  };

  //customer list

  const FetchExpireCards = async () => {
    try {
      dispatch({ type: "EXPIRE_CARDS_BEGIN" });
      const data = await axios.get("/billings/report/expiredCustomerReport");
      dispatch({ type: EXPIRE_CARDS, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //* curresnt month
  const FetchCurrentExpireCards = async () => {
    try {
      dispatch({ type: "EXPIRE_CURRENT_CARDS_BEGIN" });
      const data = await axios.get("/billings/cards/expired/report/month/all");
      dispatch({ type: "EXPIRE_CURRENT_CARDS_END", payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  //customer list

  const fetchcustomerList = async () => {
    try {
      const data = await axios.get("/customerList");
      dispatch({ type: CUSTOMER_LIST, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // ----------------------------------- begginig of supplier register       -------------- //

  //   Ticket Registeration

  const getByIdGroup = async (siteId) => {
    try {
      const data = await axios.get(`/employeGroups/getAllGroups/${siteId}`);
      dispatch({ type: GET_BYID_GROUP_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchByIdZones = async (groupId) => {
    try {
      ////console.log(data);
      const data = await axios.get(`/zoon/getByIdZoon/${groupId}`);
      dispatch({ type: FETCH_GETBYIDZONES_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchByIEmployee = async (siteId, groupId, zoneId) => {
    ////console.log(groupId);
    try {
      const data = await axios.get(
        `/employeGroups/getAllGroups/${siteId}/${groupId}/${zoneId}`
      );
      dispatch({ type: FETCH_GETBYIDEMPLOYEE_SUCCESS, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const registertickets = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/tickets/add", data);
      fetchPendingTickets();
      getSupervisorsSpecials();
      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_ADVANCE_ERROR });
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  const fetchTickets = async () => {
    try {
      const data = await axios.get("/tickets");
      dispatch({ type: GET_TICKETS__SUCCESS, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // fetch completed
  const fetchCompletedTickets = async () => {
    try {
      const data = await axios.get("/tickets/complated/all");
      dispatch({ type: GET_TICKETS_COMPLETED_SUCCESS, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  //*** GET ALL EXPIRED ANALOG  */
  const fetchAllAnalogExpired = async () => {
    try {
      dispatch({ type: GET_ALL_ANALOG_EXPIRED_SUCCESS });
      const data = await axios.get("/billings/analog/customers/expired/all");
      dispatch({ type: GET_ALL_ANALOG_EXPIRED_SUCCESS, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  //*** GET ALL 3 days remaing EXPIRED ANALOG  */
  const fetchAllAnalogThreeDaysExpired = async () => {
    try {
      dispatch({ type: GET_ALL_ANALOG_THREE_EXPIRED_BEGIN });
      const data = await axios.get(
        "/billings/analog/customers/lessthan/three/expired/all "
      );
      dispatch({
        type: GET_ALL_ANALOG_REMIAINGG_EXPIRED_SUCCESS,
        payload: { data },
      });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  const fetchIptvallExpiredCustomers = async () => {
    try {
      dispatch({ type: GET_ALL_IPTV_THREE_EXPIRED_BEGIN });
      const data = await axios.get("/billings/iptv/customers/expired/all ");
      dispatch({
        type: GET_ALL_IPTV_REMIAINGG_EXPIRED_SUCCESS,
        payload: { data },
      });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  const fetchIptva3DaysRemaingllExpiredCustomers = async () => {
    try {
      dispatch({ type: GET_ALL_IPTV_THEE_EXPIRED_BEGIN });
      const data = await axios.get(
        "/billings/iptv/customers/lessthan/three/expired/all"
      );
      dispatch({
        type: GET_ALL_IPTV_THEEE_EXPIRED_SUCCESS,
        payload: { data },
      });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchDistricts = async () => {
    try {
      const data = await axios.get("/regions/639d7812881712747d232b01");
      dispatch({ type: GET_DISTRICTS, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const ticketsUpdate = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.patch(`/tickets/update/${data.id}`, data);
      fetchTickets();
      fetchCompletedTickets()
      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // tickets/add

  // supplier registeration

  const itemRegisteration = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("supliers/add", data);
      fetchItems();
      ////console.log(res);
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({ type: REGISTER_ITEM_SUCCESS });
    } catch (error) {
      dispatch({ type: REGISTER_ITEM_ERROR });
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  // supplier fetching

  const fetchItems = async () => {
    dispatch({ type: SUPPLIER_REG_BEGIN });
    try {
      const items = await axios.get("/supliers");
      dispatch({ type: FETCH_ITEMS_SUCCESS, payload: { items } });
      // ////console.log(items);
    } catch (error) {
      //console.log(error);
    }
  };
  //** solved reports */
  // const FetchsolvedReport = async () => {
  //   try {
  //     const items = await axios.get("/tickets/solved/report");
  //     dispatch({ type: SOLVED_REPORT, payload: { items } });
  //     // ////console.log(items);
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  const FetchsolvedReport = async (start = "all", end = "all") => {
    try {
      const items = await axios.get(`/tickets/solved/report/${start}/${end}`);
      dispatch({ type: SOLVED_REPORT, payload: { items } });
      // registerItemType();
      ////console.log(items);
    } catch (error) {
      //console.log(error);
    }
  };
  const FetchsTheCustomersReport = async (start, end) => {
    try {
      dispatch({ type: THE_CUSTOMERS_BEGIN });
      const items = await axios.get(`/customers/${start}/${end}`);
      dispatch({ type: THE_CUSTOMERS_SUCCESS, payload: { items } });
      ////console.log(items);
    } catch (error) {
      dispatch({ type: THE_CUSTOMERS_ERROR });
      //console.log(error);
    }
  };

  const FetchsolvedCustomersReport = async (start = "all", end = "all") => {
    try {
      const items = await axios.get(
        `/tickets/solved/customers/report/${start}/${end}`
      );
      dispatch({ type: SOLVED_CUSTOMERS_REPORT, payload: { items } });
      // registerItemType();
      ////console.log(items);
    } catch (error) {
      //console.log(error);
    }
  };

  // const su = useMemo(() => {
  //   fetchItems();
  // });
  const fetchReceiversType = async () => {
    try {
      const data = await axios.get("/items/receivers");
      dispatch({ type: FETCH__ITEMRECEIVERS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //updating SUPPLIER

  const updateItem = async (data) => {
    ////console.log(data);
    try {
      const upadtedSupplier = await axios.patch(
        `/supliers/update/${data.id}`,
        data
      );
      fetchItems();
      toast.success(upadtedSupplier.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      ////console.log(upadtedSupplier);
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //* closing IPTV CLOSE

  const closeIptv = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/nasiye/iptvs/closeAndOpen", data);

      ////console.log(res);
      if (data.status == true) {
        openIptvs(true);
      }
      if (data.status == false) {
        openIptvs(false);
      }

      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  /// DELETING SUPPLIER

  const deleteSupplier = async (id) => {
    try {
      const res = await axios.delete(`supliers/delete/${id}`);
      ////console.log(res);
      fetchItems();

      // dispatch({ type: DELETE_SUPPLIER_SUCCESS });

      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // end of supplier

  // ------------------------------------------------------------------------------------------------//

  // BIGGINING OF RECEIVE
  // ** IPT RECEIVERS ONLY **//
  const getIptvReceiversOnly = async (id, search) => {
    ////console.log(id);
    try {
      const data = await axios.get(`/receivers/all/iptvs/${id}/${search}`);
      dispatch({ type: IPTVS_ONLY, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  const getByIdRecive = async (id, search) => {
    ////console.log(id);
    try {
      const data = await axios.get(
        `/receivers/serialsAndCardNumbers/${id}/${search}`
      );
      dispatch({ type: GET_BYID_RECIVE_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  
  // get redline receivers by mac address or serial number
  const getRedlineByMacAddressOrSerial = async (id, search) => {
    ////console.log(id);
    try {
      const data = await axios.get(
        `/receivers/redline/serialAndMacAddress/${id}/${search}`
      );
      dispatch({ type: "GET_REDLINE_RECIVER__BYID_SUCCESS", payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  const cleanTheReceiversAfterSave = () => {
    dispatch({ type: "CLEAN_THE_RECEIVERS" });
  };
  const getByIdCardNumber = async (id) => {
    ////console.log(id);
    try {
      const { data } = await axios.get(`/receivers/${id}`);
      dispatch({ type: GET_BYID_CARD_NUMBER, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // const getByIdGroup = async (siteId) => {
  //   try {
  //     const data = await axios.get(`/employeGroups/getAllGroups/${siteId}`);
  //     dispatch({ type: GET_BYID_GROUP_ALL_SUCCESS, payload: { data } });
  //     // registerItemType();
  //     ////console.log(data);
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  // const getByIdGroup = async (siteId) => {
  //   try {
  //     const data = await axios.get(`/siteGroups/${siteId}`);
  //     dispatch({ type: GET_BYID_GROUP_ALL_SUCCESS, payload: { data } });
  //     // registerItemType();
  //     ////console.log(data);
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  //

  // BIGGINING OF REGISTER ADVANCE /////////////////////////////////////////////

  //update fetchAdvance
  const fetchAdvance = async () => {
    try {
      const data = await axios.get("/advance");
      dispatch({ type: GET_ADVANCE__SUCCESS, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //update REGISTER ADVANCE
  const advanceRegister = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/advance/add", data);
      fetchAdvance();
      dispatch({ type: REGISTER_ADVANCE_SUCCESS });
      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_ADVANCE_ERROR });
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  //UPDATE ADVANCE
  const advanceUpdate = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.patch(`/advance/update/${data.id}`, data);
      fetchAdvance();

      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //DELETE ADVANCE
  const advanceDelete = async (id) => {
    ////console.log("Advance id: ", id);
    try {
      const res = await axios.delete(`/advance/delete/${id}`, id);
      fetchAdvance();

      if (res.status == "success") {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // const deleteAdvance = async (id) => {
  //   try {
  //     axios.delete(`advance/delete/${id}`);
  //     dispatch({ type: DELETE_ITEMTYPE_SUCCESS });
  //     fetchItemType();
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  // END OF REGISTER ADVANCE /////////////////////////////////////////////

  // BIGGINING OF Department Employee /////////////////////////////////////////////

  const DepartmentRegister = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/department/add", data);
      fetchDepartment();
      dispatch({ type: REGISTER_DEPARTMENT_SUCCESS });
      if (res.success == true) {
        toast.success(res.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_DEPARTMENT_ERROR });
      toast.error(error.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  // Fetch Department
  const fetchDepartment = async () => {
    try {
      const data = await axios.get("/department");
      dispatch({ type: FETCH_DEPARTMENT_AV_SUCCESS, payload: { data } });
    } catch (error) {
      //console.log(error);
    }
  };

  // Update Department

  const DepartmentUpdate = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.patch(`/department/update/${data.id}`, data);
      fetchDepartment();

      toast.success(res.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      dispatch({ type: REGISTER_DEPARTMENT_ERROR });
      toast.error(error.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  // Delete Department

  const deleteDepartment = async (id) => {
    try {
      const res = await axios.delete(`/department/delete/${id}`);
      dispatch({ type: DELETE_DEPARTMENT_SUCCESS });
      fetchDepartment();
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // END OF Department  /////////////////////////////////////////////

  // BIGGINING OF BANKS /////////////////////////////////////////////

  const BankRegister = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/banks/add", data);
      fetchBank();
      dispatch({ type: REGISTER_BANK_SUCCESS });
      if (res.success == true) {
        toast.success(res.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_BANK_ERROR });
      toast.error(error.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  // Fetch BANK
  const fetchBank = async () => {
    try {
      const data = await axios.get("/banks");
      dispatch({ type: FETCH_BANK_SUCCESS, payload: { data } });
    } catch (error) {
      //console.log(error);
    }
  };

  // Update BANK

  const BankUpdate = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.patch(`/banks/update/${data.id}`, data);
      fetchBank();

      toast.success(res.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      dispatch({ type: REGISTER_BANK_ERROR });
      toast.error(error.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  // Delete BANK

  const deleteBank = async (id) => {
    try {
      const res = await axios.delete(`/banks/delete/${id}`);
      dispatch({ type: DELETE_BANK_SUCCESS });
      fetchBank();
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // END OF BANK  /////////////////////////////////////////////

  // BIGGINING OF Employee Titles /////////////////////////////////////////////

 // Fetching Titles
  const fetchEmpTitle = async () => {
    try {
      const data = await axios.get("/title");
      dispatch({ type: FETCH_GETALLTITLE_AV_SUCCESS, payload: { data } });
      ////console.log("Title Regsiter ", data);
    } catch (error) {
      //console.log(error);
    }
  };
  // employee by department
  const fetchEmployeeDepartments = async (id='all') => {
    try {
      const data = await axios.get(`/employees/departments/${id}`);
      dispatch({ type: 'EMP_DEPARTMENT_SUCCESS', payload: { data } });
      ////console.log("EMP_DEPARTMENT ", data);
    } catch (error) {
      //console.log(error);
    }
  };
  
  // Fetching Titles
  const fetchCorespondingEmp = async (id) => {
    try {
      const data = await axios.get(`/employees/titles/${id}`);
      dispatch({ type: CORESPONDING_EMP, payload: { data } });
      ////console.log("Title Regsiter ", data);
    } catch (error) {
      //console.log(error);
    }
  };

  const TitleRegsiter = async (data) => {
    // ////console.log(data);
    try {
      const res = await axios.post("/title/add", data);
      if (res.success == true) {
        toast.success(res.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        ////console.log(res);
        // fetchEmpTitle();
      } else {
        toast.error(res.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_TITLE_ERROR });
      //console.log(error);
      toast.error(error.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const SendTheSms = async (data) => {
    try {
      dispatch({ type: SEND_SMS_BEGIN });
      const res = await axios.post("/employees/sms", data);
      dispatch({ type: SEND_SMS_SUCCESS });
      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        ////console.log(res);
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: SEND_SMS_FAIL });
      //console.log(error);
      toast.error(error.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const autoCharge = async (data) => {
    try {
      dispatch({ type: AUTO_CHARGE_BEGIN });
      const res = await axios.get("/billings/report/autocharge/day");
      dispatch({ type: AUTO_CHARGE_SUCCESS, payload: { res } });
      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        ////console.log(res);
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: AUTO_CHARGE_ERROR });
      //console.log(error);
      toast.error(error.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // Update Title

  const EmpTitleUpdate = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.patch(`/title/update/${data.id}`, data);
      fetchEmpTitle();

      if (res.success == true) {
        toast.success(res.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      //console.log(error);
      toast.error(error.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // Delete Title

  const deleteTitle = async (id) => {
    try {
      const res = await axios.delete(`/title/delete/${id}`);
      dispatch({ type: DELETE_TITLE_SUCCESS });
      fetchEmpTitle();
      toast.error(res.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  // END OF Employee Title  /////////////////////////////////////////////

  // BIGGINING OF REGISTER Employee /////////////////////////////////////////////

  const EmployeeRegister = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/employee/add", data);
      fetchEmployees();
      dispatch({ type: REGISTER_EMPLOYEE_SUCCESS });
      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_EMPLOYEE_ERROR });
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // Employee fetching
  const fetchEmployees = async () => {
    try {
      const data = await axios.get("/employee");
      dispatch({ type: GET_EMPLOYEE__SUCCESS, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  ///delete employee
  const deleteEmployee = async (id) => {
    try {
      const res = await axios.delete(`employee/delete/${id}`);
      dispatch({ type: DELETE_EMPLOYEE_SUCCESS });
      fetchEmployees();
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // Update Employee
  const updateEmployee = async (data) => {
    ////console.log(data);
    try {
      const updatedEmp = await axios.patch(`/employee/update/${data.id}`, data);
      toast.success(updatedEmp.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchEmployees();
      ////console.log(updatedEmp);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  // END OF REGISTER Employee  /////////////////////////////////////////////

  const fetchBranch = async () => {
    try {
      const data = await axios.get("/department/getAlldepartments");
      dispatch({ type: FETCH_DEPARTMENT_AV_SUCCESS, payload: { data } });
    } catch (error) {
      //console.log(error);
    }
  };

  // Employees Assign Groups BEGIN

  const AssignGroupsRegister = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/assignGroup/add", data);
      ////console.log(res);
      // fetchEmployees();
      fetchAssignGroups();
      dispatch({ type: REGISTER_ASSIGNGROUPS_SUCCESS });
      if (res.success == true) {
        toast.success(res.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_ASSIGNGROUPS_ERROR });
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchAssignGroups = async () => {
    try {
      // const data = await axios.get("deduction/getAlldeductions");
      // dispatch({ type: FETCH_GROUPS_BIGIN });
      // setTimeout(() => dispatch({ type: FETCH_GROUPS_BIGIN }), 100);
      const data = await axios.get("/assignGroup");
      dispatch({ type: FETCH_ASSIGNGROUPS_SUCCESS, payload: { data } });
      // ////console.log(items);
    } catch (error) {
      //console.log(error);
    }
  };

  const AssignGroupUpdate = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.patch(`/assignGroup/update/${data.id}`, data);
      fetchAssignGroups();

      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const deleteAssignGroup = async (id) => {
    try {
      const res = await axios.delete(`/assignGroup/delete/${id}`);
      dispatch({ type: DELETE_ASSIGNGROUP_SUCCESS });
      fetchAssignGroups();
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // end of Employees ASSIGN GROUPS

  const AssignCablesRegister = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/tickets/assign_new_cable_ticket", data);
      fetchCables();
      // dispatch({ type: REGISTER_ASSIGNCABLES_SUCCESS });
      if (res.success == true) {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      // dispatch({ type: REGISTER_ASSIGNCABLES_ERROR });
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  // BIGGINING OF Payroll /////////////////////////////////////////////

  const fetchPayrollMain = async (data) => {
    try {
      ////console.log(data);
      const res = await axios.get(
        `/GeneratePayroll/${data.year}/${data.month}`
      );
      ////console.log(res);

      dispatch({ type: FETCH_COMMISSIONMAIN_SUCCESS, payload: { res } });
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchPayroll = async () => {
    try {
      const data = await axios.get("/GeneratePayroll/2022/November");
      dispatch({ type: FETCH_COMMISSION_SUCCESS, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchPayrollYearandMonth = async () => {
    try {
      const res = await axios.get("/YearMonthGeneratePayroll");
      ////console.log(res);

      dispatch({ type: FETCH_PAYROLLYEARMONTH_SUCCESS, payload: { res } });
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchPendingPayroll = async (data) => {
    try {
      // ////console.log(data);
      const res = await axios.get(
        `/PendingGeneratePayroll/${data.year}/${data.month}`
      );
      ////console.log(res);

      dispatch({ type: FETCH_PENDINGPAYROLL_SUCCESS, payload: { res } });
    } catch (error) {
      //console.log(error);
    }
  };

  const registerPayroll = async (data) => {
    // const res = await axios.post("/GeneratePayroll/add", data);
    ////console.log(res);

    try {
      // ////console.log(data);
      const res = await axios.post("/GeneratePayroll/add", data);
      //console.log(res)
      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }else if(res.status == 400 || res.success == "false"){
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {

        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_PAYROLL_ERROR });
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  const updatePayroll = async (data) => {
    ////console.log(data);
    ////console.log(data.id);

    try {
      const res = await axios.patch(`/GeneratePayroll/${data.id}`, data);

      if(res.status == 200 || res.success == "true"){
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }else if(res.status == 400 || res.success == "false"){
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }else{
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
    }
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  // END OF Payroll /////////////////////////////////////////////

  // BIGGINING OF Deduction /////////////////////////////////////////////

  const fetchDeductions = async () => {
    try {
      // const data = await axios.get("deduction/getAlldeductions");
      const data = await axios.get("/deduction/employeeDeduction");
      dispatch({ type: FETCH_DEDUCTION_SUCCESS, payload: { data } });
      // ////console.log(items);
    } catch (error) {
      //console.log(error);
    }
  };

  const DeductionRegister = async (data) => {
    // ////console.log(data);
    try {
      const res = await axios.post("/deduction/add", data);
      fetchDeductions();
      dispatch({ type: REGISTER_DEDUCTION__SUCCESS });
      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_DEDUCTION__ERROR });
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  const deleteDeduction = async (id) => {
    try {
      const res = await axios.delete(`/deduction/delete/${id}`);
      dispatch({ type: DELETE_DEDUCTION_SUCCESS });
      fetchDeductions();
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //Deduction update
  const DeductionUpdate = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.patch(`/deduction/update/${data.id}`, data);
      fetchDeductions();

      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // end of Deduction
  // ------------------------------------------------------------------------------------------------//

  // BIGGINING OF Groups /////////////////////////////////////////////

  const fetchGroups = async () => {
    try {
      const data = await axios.get("/employeGroups/getAllGroups");
      dispatch({ type: FETCH_GROUPS_SUCCESS, payload: { data } });
    } catch (error) {
      //console.log(error);
    }
  };

  const GroupRegsiter = async (data) => {
    // ////console.log(data);

    try {
      const res = await axios.post("/employeGroups/add", data);
      fetchGroups();
      toast.success(res.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      dispatch({ type: REGISTER_GROUP__ERROR });
      //console.log(error);
      toast.error(error.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const GroupUpdateAPI = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.patch(`/siteGroups/update/${data.id}`, data);
      fetchGroups();

      if (res.success == true) {
        toast.success(res.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_GROUP__ERROR });
      toast.error(error.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  const deleteGroup = async (id) => {
    try {
      const res = await axios.delete(`/siteGroups/delete/${id}`);
      dispatch({ type: DELETE_GROUP_SUCCESS });
      fetchGroups();
      toast.error(res.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchZones = async (data) => {
    try {
      const fetchZones = await axios.get(
        `/zoon/getAllZoons/${data.siteId}/${data.groupId}`
      );
      dispatch({ type: FETCH_GETZONES_SUCCESS, payload: { data } });
      ////console.log(fetchZones);
      // ////console.log(data.siteId);
      // ////console.log(data.groupId);
    } catch (error) {
      //console.log(error);
    }
  };

  // const fetchByIdZones = async (data) => {
  //   try {
  //     const fetchZones = await axios.get(
  //       `/zoon/getByIdZoon/${data}`
  //     );
  //     dispatch({ type: FETCH_GETBYIDZONES_SUCCESS, payload: { fetchZones } });
  //     //  ////console.log(fetchZones);
  //     // ////console.log(data.siteId);
  //     // ////console.log(data.groupId);
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  const fetchAllZones = async () => {
    try {
      const data = await axios.get("/zoon/getZoonList");
      dispatch({ type: FETCH_ALL_ZONES_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const registerZones = async (data) => {
    ////console.log(data);

    try {
      const res = await axios.post("/zoon/add", data);
      // dispatch({ type: REGISTER_ITEM_TYPE_SUCCESS });
      toast.success(res.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchAllZones();
    } catch (error) {
      // dispatch({ type: REGISTER_ZONES_ERROR });
      //console.log(error);
      toast.error(error.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const ZonesUpdate = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.patch(`/zoon/update/${data.id}`, data);
      fetchAllZones();

      if (res.status == "success") {
        toast.success(res.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_ZONES_ERROR });
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  // Delete Zones

  const deleteZoneAPI = async (id) => {
    try {
      const res = await axios.delete(`/zoon/delete/${id}`);
      dispatch({ type: DELETE_ZONES_SUCCESS });
      fetchAllZones();
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // const fetchByIdZones = async (groupId) => {
  //   try {
  //     const data = await axios.get(`/zoon/getByIdZoon/${groupId}`);
  //     dispatch({ type: FETCH_GETBYIDZONES_SUCCESS, payload: { data } });
  //     // registerItemType();
  //     ////console.log(data);
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  // const fetchByIdZones = async (groupId) => {
  //   try {
  //     const data = await axios.get(`/zoneGroups/${groupId}`);
  //     dispatch({ type: FETCH_GETBYIDZONES_SUCCESS, payload: { data } });
  //     // registerItemType();
  //     ////console.log(data);
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  const fetchGroupsToAssign = async (data) => {
    try {
      const fetchgroup = await axios.get(`/siteGroups/${data.siteId}`, data);
      dispatch({ type: FETCH_GROUPSTOASSIGN_SUCCESS, payload: { fetchgroup } });
      ////console.log(fetchgroup);
      // ////console.log(data.siteId);
      // ////console.log(data.groupId);
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchZonesToAssign = async (data) => {
    try {
      const fetchZone = await axios.get(`/zoneGroups/${data.groupId}`, data);
      dispatch({ type: FETCH_ZONESTOASSIGN_SUCCESS, payload: { fetchZone } });
      ////console.log(fetchZone);
      // ////console.log(data.siteId);
      // ////console.log(data.groupId);
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchEmployeesToAssign = async (data) => {
    try {
      const fetchEmp = await axios.get(`/zoneEmployees/${data.zoneId}`, data);
      dispatch({
        type: FETCH_EMPLOYEESTOASSIGN_SUCCESS,
        payload: { fetchEmp },
      });
      ////console.log(fetchEmp);
      // ////console.log(data.siteId);
      // ////console.log(data.groupId);
    } catch (error) {
      //console.log(error);
    }
  };

  // BIGGINING OF REGISTER ADVANCE /////////////////////////////////////////////

  // const fetchAdvance = async () => {
  //   try {
  //     const data = await axios.get("/advance");
  //     dispatch({ type: GET_ADVANCE__SUCCESS, payload: { data } });
  //     ////console.log(data);
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  // const AdvanceRegister = async (data) => {
  //   ////console.log(data);
  //   try {
  //     await axios.post("/advance/add", data);
  //     fetchAdvance();
  //     dispatch({ type: REGISTER_ADVANCE_SUCCESS });
  //   } catch (error) {
  //     dispatch({ type: REGISTER_ADVANCE_ERROR });
  //     //console.log(error);
  //   }
  // };

  // // END OF REGISTER ADVANCE /////////////////////////////////////////////

  // // BIGGINING OF REGISTER Employee /////////////////////////////////////////////

  // const EmployeeRegister = async (data) => {
  //   ////console.log(data);
  //   try {
  //     await axios.post("/employee/add", data);
  //     fetchEmployees();
  //     dispatch({ type: REGISTER_EMPLOYEE_SUCCESS });
  //   } catch (error) {
  //     dispatch({ type: REGISTER_EMPLOYEE_ERROR });
  //     //console.log(error);
  //   }
  // };

  // const fetchEmployees = async () => {
  //   try {
  //     const data = await axios.get("/employee");
  //     dispatch({ type: GET_EMPLOYEE__SUCCESS, payload: { data } });
  //     ////console.log(data);
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  // // Employee fetching

  // const fetchEmpTitle = async () => {
  //   try {
  //     const data = await axios.get("/title/getAllTitles");
  //     dispatch({ type: FETCH_GETALLTITLE_AV_SUCCESS, payload: { data } });
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  // ///delete employee
  // const deleteEmployee = async (id) => {
  //   try {
  //     await axios.delete(`employee/delete/${id}`);
  //     dispatch({ type: DELETE_products_SUCCESS });
  //     readProducts();
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  // const fetchDepartment = async () => {
  //   try {
  //     const data = await axios.get("/department/getAlldepartments");
  //     dispatch({ type: FETCH_DEPARTMENT_AV_SUCCESS, payload: { data } });
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  // const fetchBranch = async () => {
  //   try {
  //     const data = await axios.get("/department/getAlldepartments");
  //     dispatch({ type: FETCH_DEPARTMENT_AV_SUCCESS, payload: { data } });
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  // end of Employees

  // ------------------------------------------------------------------------------------------------//

  // BIGGINING OF Groups /////////////////////////////////////////////

  // const fetchGroups = async () => {
  //   try {
  //     const Groups = await axios.get("/employeeGroups/getAllGroups");
  //     dispatch({ type: FETCH_GROUPS_SUCCESS, payload: { Groups } });
  //     // ////console.log(items);
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  // const fetchGroups = async () => {
  //   try {
  //     const Groups = await axios.get("http://localhost:5626/api/v1/employeeGroups/getAllGroups");
  //     dispatch({ type: FETCH_GROUPS_SUCCESS, payload: { Groups } });
  //     ////console.log(Groups);
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  // end of Groups

  // ------------------------------------------------------------------------------------------------//

  // BIGGINING OF CREATE IPTV /////////////////////////////////////////////

  const fetchAllIPTVS = async () => {
    try {
      const data = await axios.get("/ipTvs");
      dispatch({ type: FETCH_ALL_IPTV_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  const fetchAHorIPTVS = async () => {
    try {
      const data = await axios.get("/ipTvsHormuud");
      dispatch({ type: FETCH_HOR_IPT, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const registerIPTV = async (data) => {
    ////console.log(data);
    try {
      ////console.log("await axios.post(, data)", data);
      const res = await axios.post("/ipTvs/add", data);
      ////console.log("Response From Db:====>", res);
      // dispatch({ type: REGISTER_ITEM_TYPE_SUCCESS });
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchAllIPTVS();
    } catch (error) {
      dispatch({ type: REGISTER_IPTV_ERROR });
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const removeIPTV = async (id) => {
    try {
      const res = await axios.delete(`/ipTvs/delete/${id}`);
      // dispatch({ type: DELETE_products_SUCCESS });
      fetchAllIPTVS();
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const UpdateIPTV = async (data) => {
    ////console.log(data);
    try {
      const updateIP = await axios.patch(`/ipTvs/update/${data.id}`, data);
      fetchAllIPTVS();
      toast.success(updateIP.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      ////console.log(updateIP);
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchIPTVUpdateById = async (id) => {
    try {
      const data = await axios.get(`/ipTvs/${id}`);
      ////console.log(data);
      dispatch({
        type: FETCH_IPTV_BY_ID,
        payload: { data },
      });
      // registerItemType();
    } catch (error) {
      ////console.log(`----------------------${error}`);
    }
  };

  const GetIPTVHormuud = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post(
        "https://ccxapi.hormuud.com/api/Csr/IPTVTIcketsSaveUpdate",
        data
      );
      // dispatch({ type: REGISTER_ITEM_TYPE_SUCCESS });

      // const res = await axios.post(
      //   `https://ccxapi.hormuud.com/api/Csr/IPTVTIcketsSaveUpdate?AlarmID=2&Tel=&Callsub=4777475&Center=STH&District=Howlwadaag&Address=Baar%20Ubax&Description=tesssssssssss&UserID=TST&apikey=bE0TKhvHvT23w7dXlrM8BvGPxh6HkjF`
      //   // `https://ccxapi.hormuud.com/api/Csr/IPTVTIcketsSaveUpdate?AlarmID=${data.AlarmID}&Tel=${data.Tel}&Callsub=${data.Callsub}&Center=${data.Center}&District=${data.District}&Address=${data.Address}&Description=${data.Description}&UserID=${data.UserID}&apikey=${data.apikey}$`
      // );
      dispatch({ type: FETCH_APIHORMUUDIPTV_SUCCESS, payload: { res } });
      ////console.log(res);
    } catch (error) {
      dispatch({ type: FETCH_IPTV_HORMUUD_ERROR });
      //console.log(error);
    }
  };

  // ------------------------------------------------------------------------------------------------//

  // BIGGINING OF REGISTER ITEM TYPE /////////////////////////////////////////////

  //register itemType
  const registerItemType = async (data) => {
    ////console.log(data);
    ////console.log(data);
    try {
      const res = await axios.post("itemTypes/add", data);
      // dispatch({ type: REGISTER_ITEM_TYPE_SUCCESS });
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchItemType();
    } catch (error) {
      dispatch({ type: REGISTER_ITEM_ERROR });
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  /// fetch itemtype

  const fetchItemType = async () => {
    try {
      const data = await axios.get("/itemTypes");
      dispatch({ type: REGISTER_ITEM_TYPE_SUCCESS, payload: { data } });

      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //delete item type

  const deleteItemType = async (id) => {
    try {
      axios.delete(`itemTypes/delete/${id}`);
      dispatch({ type: DELETE_ITEMTYPE_SUCCESS });
      fetchItemType();
    } catch (error) {
      //console.log(error);
    }
  };

  //update itemType

  const updateItemType = async (data) => {
    ////console.log(data);
    try {
      const updateItemType = await axios.patch(
        `/itemTypes/update/${data.id}`,
        data
      );
      toast.success(updateItemType.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      ////console.log(updateItemType);
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // --------------------------------------------//

  // customers api begin ////////////

  // register customer
  //* sp code
  const currentDate = new Date(); // Get current date
  const currentYear = currentDate.getFullYear(); // Get current year
  const currentMonth = currentDate.getMonth(); // Get current month
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1); // Create new Date object with the first day of current month
  // const startDate = firstDayOfMonth.toDateString(); // Convert the first day of current month to a string in the desired format
  const startDate = moment().startOf("month").format("YYYY-MM-DD");
  ////console.log(startDate);
  const formatedDate = moment(startDate).format("YYYY-MM-DD");

  ////console.log(formatedDate); // Output the start date of the current month in the //console
  const maanta = new Date();
  const tariikhdamanta = moment(maanta[0]).format("YYYY-MM-DD");
  //** ednd */
  const registerCustomer = async (data) => {
    ////console.log(data);
    try {
      ////console.log("data________", data);
      const res = await axios.post("customers/add", data);
      ////console.log("Response:--", res);
      dispatch({ type: REGISTER_CUSTOMER_SUCCESS });
      // fetchCustomers();
      FetchsTheCustomersReport(startDate, tariikhdamanta);

      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      if (res.status == "fail") {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // return
      }
    } catch (error) {
      dispatch({ type: REGISTER_CUSTOMER_ERROR });
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };
  const registerShits = async (data) => {
    ////console.log(data);
    try {
      ////console.log("data________", data);
      const res = await axios.post("shifts/add", data);
      ////console.log("Response:--", res);
      fetchShifts();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // //console.log(error);
    }
  };

  // read shifts
  const fetchShifts = async () => {
    try {
      const data = await axios.get("/shifts");
      dispatch({ type: REG_SHITS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //delete shifts
  const deleteShifts = async (id) => {
    try {
      const res = await axios.delete(`shifts/${id}`);
      fetchShifts();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  // update shifts

  const updateShifts = async (data) => {
    ////console.log(data);
    try {
      const updatedShift = await axios.patch(`shifts/${data.id}`, data);
      fetchShifts();
      toast.success(updatedShift.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      ////console.log(updatedShift);
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //reading customers

  const fetchTopTenLastLoginUsers = async () => {
    try {
      const data = await axios.get("users/report/lastTenLoggedUser");
      dispatch({ type: TOP_TEN_USERS, payload: { data } });

      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  const fetchCustomers = async () => {
    dispatch({ type: CUSTOMER_REG_BEGIN });
    try {
      const data = await axios.get("/customers");
      dispatch({ type: FETCH_CUSTOMER_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  const fetchAutoCustomers = async (search = "a") => {
    try {
      const data = await axios.get(`/customers/filter/info/${search}`);
      dispatch({ type: UPDATED_CUSTOMERS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  const fetchAutoIPTVCustomers = async (search = "a") => {
    try {
      const data = await axios.get(`/customers/iptvs/all/filter/${search}`);
      dispatch({ type: IPTV_CUSTOMER, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  const fetchBothCustomers = async (search = "a") => {
    try {
      const data = await axios.get(`/customers/both/filter/info/${search}`);
      dispatch({ type: "FETCH_BOTH", payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // redline customers
  const fetchRedlineCustomers = async (search = "a") => {
    try {
      const data = await axios.get(`/customers/redline/filter/info/${search}`);
      dispatch({ type: "FETCH_REDLINE", payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //delete Customer
  const deleteCustomer = async (id) => {
    try {
      const res = await axios.delete(`customers/delete/${id}`);
      dispatch({ type: DELETE_CUSTOMER_SUCCESS });
      FetchsTheCustomersReport(startDate, tariikhdamanta);
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //Get By ID
  const getByIdCustomers = async (id) => {
    ////console.log(id);
    try {
      const data = await axios.get(`/customers/${id}`);
      dispatch({ type: GET_BYID_CUSTOMERS_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //upadating

  const updateCustomer = async (data) => {
    ////console.log(data);
    try {
      const updateCustomer = await axios.patch(
        `/customers/update/${data.id}`,
        data
      );
      FetchsTheCustomersReport(startDate, tariikhdamanta);
      ////console.log(updateCustomer);
      toast.success(updateCustomer.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //sites

  /// register sites

  const registerSite = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/sites/add", data);
      dispatch({ type: REGISTER_SITE_SUCCESS });
      fetchSites();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //fetching sites

  const fetchSites = async () => {
    dispatch({ type: SITE_REG_BEGIN });
    try {
      const data = await axios.get("/sites");
      dispatch({ type: FETCH_SITES_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // delete

  const deleteSite = async (id) => {
    try {
      const res = await axios.delete(`sites/delete/${id}`);
      dispatch({ type: DELETE_SITE_SUCCESS });
      fetchSites();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  /// upadating sites
  const upadteSite = async (data) => {
    ////console.log(data);
    try {
      const updateSite = await axios.patch(`/sites/update/${data.id}`, data);
      fetchSites();
      toast.success(updateSite.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      ////console.log(updateSite);
    } catch (error) {
      toast.success(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  ///register store

  const registerStore = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/stores/add", data);
      dispatch({ type: REGISTER_STORE_SUCCESS });
      fetchStores();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //reading stores
  const fetchStores = async () => {
    dispatch({ type: STORE_REG_BEGIN });
    try {
      const data = await axios.get("/stores");
      dispatch({ type: FETCH_STORE_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //delete store
  const deleteStores = async (id) => {
    try {
      const res = await axios.delete(`stores/delete/${id}`);
      dispatch({ type: DELETE_STORE_SUCCESS });
      fetchStores();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //update store
  const upadteStore = async (data) => {
    ////console.log(data);
    try {
      const updateStore = await axios.patch(`/stores/update/${data.id}`, data);
      fetchStores();
      toast.success(updateStore.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      ////console.log(updateStore);
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  //------------------------------//

  // create item

  const registerItem = async (data) => {
    ////console.log(data);

    try {
      const res = await axios.post("/items/add", data);
      // dispatch({ type: REGISTER_ITEM__SUCCESS });
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      readProducts();
      // displayAlert(res.message);
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      ////console.log("gg" + error.message);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  /////

  // const displayAlert = (msg) => {
  //   dispatch({ type: "DISPLAY_ALERT", payload: { msg } });
  //   toast.success(msg, {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  //   clearAlert();
  // };

  // const clearAlert = () => {
  //   setTimeout(() => {
  //     dispatch({ type: "CLEAR_ALERT" });
  //   }, 3000);
  // };
  //fetch items

  const readProducts = async () => {
    dispatch({ type: ITEM_REG_BEGIN });
    try {
      const data = await axios.get("/items");
      dispatch({ type: REGISTER_ITEM__SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  ///delete product
  const deleteProduct = async (id) => {
    try {
      const res = await axios.delete(`items/delete/${id}`);
      ////console.log(res);
      dispatch({ type: DELETE_products_SUCCESS });
      readProducts();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const updateProduct = async (data) => {
    ////console.log(data);
    try {
      const updatedItem = await axios.patch(`/items/update/${data.id}`, data);
      readProducts();
      ////console.log(updatedItem);
      toast.success(updatedItem.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  /// promotion
  const promotion = async (data) => {
    ////console.log(data);
    try {
      const updatedItem = await axios.patch(
        `/items/promotions/${data.id}`,
        data
      );
      readProducts();
      ////console.log(updatedItem);
      toast.success(updatedItem.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // purchase Order

  const registerPurchaseOrder = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/purchaseOrders/add", data);
      // dispatch({ type: REGISTER_ITEM__SUCCESS });
      // readProducts();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      ////console.log("gg" + error.message);
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchPurchaseOrder = async () => {
    try {
      const data = await axios.get("/purchaseOrders");
      dispatch({ type: REGISTER_PURCHASEORDERS__SUCCESS, payload: { data } });
      //  ////console.log(data)
    } catch (error) {
      //console.log(error);
    }
  };

  // manage purchase

  const registerPurchase = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/purchases/add", data);
      // dispatch({ type: REGISTER_ITEM__SUCCESS });
      // readProducts();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      ////console.log("gg" + error.message);
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchPurchase = async () => {
    try {
      const data = await axios.get("/purchases");
      dispatch({ type: REGISTER_PURCHASES__SUCCESS, payload: { data } });
      const notify = () => toast("Wow so easy!");
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //remove

  const removePurchase = async (id) => {
    try {
      const res = await axios.delete(`purchases/delete/${id}`);
      // dispatch({ type: DELETE_products_SUCCESS });
      fetchPurchase();
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // update purchases

  const updatePurchases = async (data) => {
    ////console.log(data);
    try {
      const updatedPurchases = await axios.patch(
        `/purchases/update/${data.id}`,
        data
      );
      fetchPurchase();
      ////console.log(updatedPurchases);
    } catch (error) {
      //console.log(error);
    }
  };
  // =-------------------------------------------------------//
  // payments
  const registerPayment = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/payments/add", data);
      // dispatch({ type: REGISTER_ITEM__SUCCESS });
      fetchPayments();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      ////console.log("gg" + error.message);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //read payments

  const fetchPayments = async () => {
    try {
      const data = await axios.get("/payments");
      dispatch({ type: REGISTER_PAYMENT__SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //read payments

  const fetchPaymentsById = async (id) => {
    try {
      const data = await axios.get(`/payments/${id}`);
      dispatch({ type: PAYMENT_BY_ID, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //delete payment

  const deletePayment = async (id) => {
    try {
      const res = await axios.delete(`payments/delete/${id}`);
      // dispatch({ type: DELETE_products_SUCCESS });
      fetchPayments();
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //update payments
  const updatePayment = async (data) => {
    ////console.log(data);
    try {
      const updatedPayments = await axios.patch(
        `/payments/update/${data.id}`,
        data
      );
      fetchPayments();
      ////console.log(updatedPayments);
      toast.success(updatedPayments.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  /// fetch customer payment amount

  const fetchCustomerBalance = async (id) => {
    try {
      const data = await axios.get(`/supliers/balance/${id}`);
      dispatch({ type: REGISTER_PAYMENT__AMOUNT, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // purchase return save
  const registerPurchaseReturn = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/purchaseReturns/add", data);
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      ////console.log("gg" + error.message);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //update purchase return save

  //------------------------//

  const Fetchstocks = async () => {
    try {
      const data = await axios.get("/stocks");
      dispatch({ type: REGISTER_STOCKS__SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //---------------------//

  // FETCH SINGLE UPDATE
  //manageSingle
  const fetchId = async (id) => {
    try {
      const data = await axios.get(`/purchases/${id}`);
      dispatch({ type: FETCH_UPDATED_MANAGE_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //---------------------//

  const registerPackage = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/channelPackages/add", data);
      fetchChanelPackages();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // ////console.log("gg" + error.message);
    }
  };

  // fetch channel packages

  const fetchChanelPackages = async () => {
    dispatch({ type: PACKAGE_REG_BEGIN });

    try {
      const data = await axios.get("/channelPackages");
      dispatch({ type: FETCH_CHANELLS_SUCCESS, payload: { data } });
      // registerItemType();
      // ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //delete package
  const deletePackage = async (id) => {
    try {
      const res = await axios.delete(`channelPackages/delete/${id}`);
      // dispatch({ type: DELETE_products_SUCCESS });
      fetchChanelPackages();
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  ///update package

  const updateChannelPackage = async (data) => {
    ////console.log(data);
    try {
      const updatedChannelPackage = await axios.patch(
        `/channelPackages/update/${data.id}`,
        data
      );
      fetchChanelPackages();
      toast.success(updatedChannelPackage.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      ////console.log(updatedChannelPackage);
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //--------------------- agents-----------------------//

  const registerAgent = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/salesAgents/add", data);
      fetchAgents();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      fetchAgents();
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      ////console.log("gg" + error.message);
    }
  };

  // read agensts

  const fetchAgents = async () => {
    dispatch({ type: SALESAGENT_REG_BEGIN });
    try {
      const data = await axios.get("/salesAgents");
      dispatch({ type: FETCH_AGENTS_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  /// delete agents

  const deleteAgents = async (id) => {
    try {
      const res = await axios.delete(`salesAgents/delete/${id}`);
      fetchAgents();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // dispatch({ type: DELETE_products_SUCCESS });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // update agents

  const updateAgents = async (data) => {
    ////console.log(data);
    try {
      const updatedAgent = await axios.patch(
        `/salesAgents/update/${data.id}`,
        data
      );
      fetchAgents();
      ////console.log(updatedAgent);
      fetchAgents();
      toast.success(updatedAgent.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // api for fetching quantity available

  const fetchQuantityAvailable = async (id) => {
    ////console.log(id);
    try {
      const data = await axios.get(`/items/balance/${id}`);
      dispatch({ type: FETCH_QUANTITY_AV_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchItemById = async (id) => {
    ////console.log(id);
    try {
      const data = await axios.get(`/items/${id}`);
      dispatch({ type: FETCH_ITEMBYID_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  //nasiye payments
  const fetchNasiyeReport = async () => {
    try {
      const data = await axios.get("/payment/report/nasiyePayments");
      dispatch({ type: NASIYE_PAYMENTS, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // card disturbution

  const registerDisturbutionWithSerial = async (data) => {
    ////console.log(data);
    try {
      await axios.post("/cardDistributions/add", data);
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      ////console.log("gg" + error.message);
    }
  };

  // Record Card Distributions Without Serial no.

  const registerDisturbutionWithOutSerial = async (data) => {
    ////console.log(data);
    try {
      await axios.post("/cardDistributions/add", data);
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      ////console.log("gg" + error.message);
    }
  };

  // register transfer type

  const registerItemDisturbution = async (data) => {
    dispatch({ type: ITEM_DISTURBUTION_BEGIN });
    ////console.log(data);
    try {
      const res = await axios.post("/inventorytransfers/add", data);
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({ type: ITEM_DISTURBUTION_SUCCESS });
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      ////console.log("gg" + error.message);
    }
  };
  const HubiLine = async (data) => {
    dispatch({ type: HUBI_LINE_BEGIN });
    ////console.log(data);
    try {
      const res = await axios.post(
        "/sales/newCable/iptv/subscriber/message",
        data
      );
      ////console.log(res);
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({ type: HUBI_LINE, payload: { res } });
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      ////console.log("gg" + error.message);
    }
  };

  // new cable ///////////////

  // new analog cable
  const registernewCable = async (data) => {
    ////console.log(data);

    try {
      dispatch({ type: REGISTER_NEW_CABLE_BEGIN });
      const res = await axios.post("/sales/newCable/add", data);

      dispatch({ type: REGISTER_NEW_CABLE_SUCCESS });
      if (res.success == true) {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_NEW_CABLE_ERROR });
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };
  // new redline cable
  const registerNewRedlineCable = async (data) => {
    ////console.log(data);

    try {
      dispatch({ type: "REGISTER_NEW_REDLINE_ABLE_BEGIN" });
      const res = await axios.post("/sales/newRedlineCable/add", data);

      dispatch({ type: "REGISTER_NEW_REDLINE_CABLE_SUCCESS" });
      if (res.success == true) {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: "REGISTER_NEW_REDLINE_CABLE_ERROR" });
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  // new iptv
  const registernewIPTV = async (data) => {
    ////console.log(data);
    dispatch({ type: REGISTER_IPTV_BEGIN });
    try {
      const res = await axios.post("/sales/newCable/iptv/add", data);
      dispatch({ type: REGISTER_IPTV_SUCCESS });
      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_IPTV_ERROR });
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };
  const registerOrganizationIPTV = async (data) => {
    ////console.log(data);
    dispatch({ type: REGISTER_IPT_ORGANIZATION_BEGIN });
    try {
      const res = await axios.post(
        "/sales/newCable/iptv/organizations/add",
        data
      );
      dispatch({ type: REGISTER_IPT_ORGANIZATION__SUCCESS });
      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_IPT_ORGANIZATION__ERROR });
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  const registerSales = async (data) => {
    ////console.log(data);
    try {
      await axios.post("/sales/add", data);
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      ////console.log("gg" + error.message);
    }
  };

  ///----------------------//

  const fetchCards = async () => {
    // ////console.log(id);
    try {
      const data = await axios.get(`/openCards`);
      dispatch({ type: FETCH_CARDS_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchCables = async () => {
    // ////console.log(id);
    // dispatch({ type: FETCH_CABLE_BIGIN });
    try {
      const data = await axios.get(`/newCables`);
      dispatch({ type: FETCH_CABLES_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //* by date
  const fetchCablesByDate = async (startDate, endDate) => {
    dispatch({ type: FETCH_CABLES_BEGIN_BY_DATE });
    try {
      const data = await axios.get(`/newCables/${startDate}/${endDate}`);
      dispatch({ type: FETCH_CABLES_SUCCESS_BY_DATE, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      dispatch({ type: FETCH_CABLES_ERROR_BY_DATE });
      //console.log(error);
    }
  };

  const getByIdGroupCable = async (siteId) => {
    try {
      const data = await axios.get(`/siteGroups/${siteId}`);
      dispatch({ type: GET_BYID_GROUP_CABLE_ALL_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // analog
  const fetchOpenCards = async () => {
    // ////console.log(id);
    try {
      dispatch({ type: "FETCH_OPEN_CARDS_BEGIN" });
      const data = await axios.get(`/analogOpenCards`);
      dispatch({ type: FETCH_OPEN_CARDS_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // Redline Open cards
  const fetchRedlineOpenCards = async () => {
    // ////console.log(id);
    try {
      dispatch({ type: "FETCH_REDLINE_OPEN_CARDS_BEGIN" });
      const data = await axios.get(`/redlineOpenCards`);
      dispatch({ type: "FETCH_REDLINE_OPEN_CARDS_SUCCESS", payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // analog closed cards
  const fetchClosedCards = async () => {
    // ////console.log(id);
    try {
      const data = (await axios.get(`/analogCloseCards`)) || [];
      dispatch({ type: GET_CLOSED_CARDS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // fetch redline closed cards
  const fetchRedlineClosedCards = async () => {
    // ////console.log(id);
    try {
      const data = (await axios.get(`/closeCards`)) || [];
      dispatch({ type: GET_CLOSED_CARDS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //close Analog card
  const closeAnalogCard = async (data) => {
    ////console.log(data);
    try {
      const closedCard = await axios.patch(
        `/analogOpenCards/update/${data.id}`,
        data
      );
      fetchOpenCards();
      ////console.log(closedCard);
      toast.success(closedCard.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  //close Redline card
  const closeRedlineCard = async (data) => {
    ////console.log(data);
    try {
      const closedCard = await axios.patch(
        `/redlineOpenCards/update/${data.id}`,
        data
      );
      fetchRedlineOpenCards();
      ////console.log(closedCard);
      toast.success(closedCard.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  //Disable and Activate Redline card
  const CancelAndActivateRedlineCard = async (id) => {
    ////console.log(data);
    try {
      const res = await axios.patch(
        `/cancelAndActivateRedlineCards/${id}`);
      fetchRedlineOpenCards();
      ////console.log(res);
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  // this manage cables ---
  const updateManageCable = async (data) => {
    ////console.log(data);
    try {
      const updatedManaged = await axios.patch(
        `newCables/updateCustomerUser`,
        data
      );
      fetchCables();
      ////console.log(updatedManaged);
      toast.success(updatedManaged.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // Analog open card 
  const openAnalogCardRegister = async (data) => {
    ////console.log(data);
    dispatch({ type: OPEN_CARD_REG_BEGIN });
    try {
      const res = await axios.post("/openAnalogCards/add", data);
      fetchOpenCards();
      dispatch({ type: OPEN_CARD_REG_END });
      res.status == false
        ? toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        : toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      //console.log("Error: " + error.message);
    }
  };
  // Redline open card 
  const openRedlineCardRegister = async (data) => {
    ////console.log(data);
    dispatch({ type: "OPEN_REDLINE_CARD_REG_BEGIN" });
    try {
      const res = await axios.post("/openRedlineCards/add", data);
      fetchRedlineOpenCards();
      dispatch({ type: "OPEN_REDLINE_CARD_REG_END" });
      res.status == false
        ? toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        : toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      //console.log("Error: " + error.message);
    }
  };

  // --------------------------//

  // get phoneNumber customer by id

  const fetchtodaysTickets = async () => {
    try {
      const data = await axios.get(`/tickets/today`);
      dispatch({ type: TODAYS_TICKETS, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  const getSingleCustomer = async (id) => {
    try {
      const data = await axios.get(`/customers/${id}`);
      dispatch({ type: FETCH_SINGLE_CUSTOMER, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const getSingleEmployee = async (id) => {
    try {
      const data = await axios.get(`/employee/${id}`);
      dispatch({ type: FETCH_SINGLE_EMPLOYEE, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  ///----------------

  const getSiteCards = async (id) => {
    ////console.log("fn is called");
    try {
      const data = await axios.get(`/siteCards/specific/${id}`);
      dispatch({ type: FETCH_SINGLE_SITE_CARDS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //----------------//

  const registerReciever = async (data) => {
    ////console.log(data);
    dispatch({ type: "RECEIVER_ADD_BEGIN" });
    try {
      // const response = await axios.post("/receivers/add", data);
      const response = await axios({
        method: "post",
        url: "/receivers/add",
        data: data,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        maxContentLength: "infinity",
        maxBodyLength: "infinity",
      });
      // console.log("--------------------------------------------",response)
   
     
      if(response.status == 200 || response.status == "success"){
        toast.success(response.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

      }else{
        dispatch({ type: UPLOADED_ERRORS, payload: response.skipped });
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
         dispatch({ type: "RECEIVER_ADD_SUCCESS" ,payload: response});
    } catch (error) {
      ////console.log("gg" + error.message);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const customersUpload = async (data) => {
    ////console.log(data);
    dispatch({ type: "Customers_ADD_BEGIN" });
    try {
      const response = await axios.post("/customers/upload", data);
      // dispatch({ type: REGISTER_ITEM_TYPE_SUCCESS });

      toast.success(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({ type: "customers_upload_success", payload: response });
    } catch (error) {
      ////console.log("gg" + error.message);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const uploadIptvCustomers = async (data) => {
    ////console.log(data);
    dispatch({ type: UPLOAD_IPTV_BEGIN });
    try {
      const response = await axios.post("/customers/iptv/upload", data);

      toast.success(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({ type: UPLOAD_IPTV_CUSTOMERS, payload: response });
    } catch (error) {
      ////console.log("gg" + error.message);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  /// fetch reciever

  const fetchRecievers = async () => {
    try {
      const data = await axios.get("/receivers");
      dispatch({ type: FETCH__RECEIVERS, payload: { data } });
      // registerItemType();
      // ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  /// delete reciever
  const deleteRecievers = async (id) => {
    ////console.log(id);
    try {
      const res = await axios.delete(`receivers/delete/${id}`);
      fetchRecievers();
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // dispatch({ type: DELETE_products_SUCCESS });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const updateReciever = async (data) => {
    ////console.log(data);
    try {
      const updatedReciever = await axios.patch(
        `/receivers/update/${data.id}`,
        data
      );
      fetchRecievers();
      ////console.log(updatedReciever);
      toast.success(updatedReciever.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // purchased id --- not used yet
  const fetchPurchasedId = async (id) => {
    try {
      const data = await axios.get(`/purchases/${id}`);
      dispatch({ type: FETCH_SINGLE_PURCHASE_ID, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //fetch receiver number -- not used yet

  const fetchRecievedNumberCard = async (id) => {
    try {
      const data = await axios.get(`/receiver/${id}`);
      dispatch({ type: FETCH_SINGLE_CARD_NUMBER, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //get_purchases_with_associated_receivers
  const getPurchasesInfo = async () => {
    try {
      const data = await axios.get(`/receiverTemps`);
      dispatch({ type: Fetch_associated_receivers, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  const getPurchasesInfoById = async (id) => {
    try {
      const data = await axios.get(`/receiverTemps/${id}`);
      dispatch({
        type: FETCH_PURCHASED_ASSOCIATED_RECEIVERS_BY_ID,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  /// add receiver details
  const addReceiverDetails = async (data) => {
    try {
      const response = await axios.post("/receivers/add", data);
      // dispatch({ type: REGISTER_ITEM_TYPE_SUCCESS });

      toast.success(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      ////console.log("gg" + error);
      // const itemDont = 'item'
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchByIdZonesCable = async (groupId) => {
    try {
      const data = await axios.get(`/zoneGroups/${groupId}`);
      dispatch({ type: FETCH_GETBYIDZONESCABLE_SUCCESS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const FetchItemsNotReceivers = async () => {
    try {
      const data = await axios.get("/items/others/forNewCables");
      dispatch({ type: ITEM_NOT_RECEIVER, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  const FetchItemsNotReceiversById = async (id) => {
    try {
      const data = await axios.get(`/items/others/${id}`);
      dispatch({ type: ITEM_NOT_RECEIVER_by_id, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // fetch receiver items
  const FetchItemsReceivers = async () => {
    try {
      const data = await axios.get("/items/receivers");
      dispatch({ type: FETCH_RECEIVER_ITEM, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //FetchReciverCardNumberAndSerials
  const FetchReciverCardNumberAndSerials = async (id) => {
    ////console.log(id);
    try {
      const data = await axios.get(`/receivers/serialsAndCardNumbers/${id}`);
      dispatch({
        type: FETCH_RECEIVER_CARD_NUMBER_AND_SERIALS,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  //fetch card number and receiver for single item
  // const fetchSingleCardforReciever = async (id) => {
  //   ////console.log(id);
  //   try {
  //     const data = await axios.get(`/receivers/serialsAndCardNumbers/${id}`);
  //     dispatch({
  //       type: FETCH_RECEIVER_CARD_NUMBER_AND_SERIALS,
  //       payload: { data },
  //     });
  //     // registerItemType();
  //     ////console.log(data);
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  const fetchPendingTickets = async () => {
    // ////console.log(id);
    try {
      const data = await axios.get(`/tickets/pending`);
      dispatch({ type: FETCH_PENDING_TICKETS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const transferTickets = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.patch(`/tickets/ticketTransfer/${data.id}`, data);
      if (res.success == true) {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchPendingTickets();
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };
  const CompleteTickets = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.patch(
        `/tickets/complete/${data.id}/${data.userId}/${data.comment}/${data.content}/${data.phoneNumber}`,
        data
      );
      if (res.success == true) {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchPendingTickets();
        fetchCompletedTickets()
        fetchTickets()
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_COMPLETETICKETS_ERROR });
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };

  //  reopen tickets
  const ReopenTickets = async (data) => {
    // ////console.log(data);
    try {
      const res = await axios.patch(
        `/tickets/reopen/${data.id}`,data);
      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchCompletedTickets();
        fetchPendingTickets();
  
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: "FETCH_REOPEN_ERROR" });
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };
  // complete ticket successfully
  const completeTicket = async (data) => {
    // ////console.log(data);
    try {
      const res = await axios.patch(
        `/tickets/completed/last/${data.id}`,data);
      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchCompletedTickets();
        fetchPendingTickets();
        fetchTickets()
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: "FETCH_COMPLETE_ERROR" });
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };
  // function that togles the pending from onhold to unhold and vice versa

  const RegPendingTrans = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.patch(`tickets/check/${data.id}`, data);
      ////console.log(res);
      dispatch({ type: PENDING_TRANSFER });
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchPendingTickets();
    } catch (error) {
      ////console.log("gg" + error.message);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // ** this function is to toggle unreachavble
  const isUnreachable = async (id) => {
    ////console.log(id);
    try {
      const res = await axios.patch(`tickets/check/isUnreachable/${id}`);
      ////console.log(res);
      // dispatch({ type: PENDING_TRANSFER });
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchPendingTickets();
    } catch (error) {
      ////console.log("gg" + error.message);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  // ** this function is to toggle to no device
  const noDevice = async (id) => {
    ////console.log(id);
    try {
      const res = await axios.patch(`tickets/check/isAdeviceIssue/${id}`);
      ////console.log(res);
      // dispatch({ type: PENDING_TRANSFER });
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchPendingTickets();
    } catch (error) {
      ////console.log("gg" + error.message);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const itemSalesRegisteration = async (data) => {
    ////console.log(data);
    try {
      dispatch({ type: REGISTER_NEW_ITEM_SALES_BEGIN });
      const res = await axios.post("/sales/add", data);
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({ type: REGISTER_NEW_ITEM_SALES_SUCCESS });
    } catch (error) {
      dispatch({ type: REGISTER_NEW_ITEM_SALES_ERROR });
      ////console.log("gg" + error.message);
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  /// purchase orders

  const fetchPurchaseOrders = async () => {
    try {
      const data = await axios.get(`/purchaseOrders`);
      dispatch({
        type: FETCH_PURCHASE_ORDERS,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // fetch single purchase order
  const FetchSinglePurchase = async (id) => {
    try {
      const data = await axios.get(`/purchaseOrders/${id}`);
      ////console.log(data);
      dispatch({
        type: FETCH_PURCHASE_ORDERS_BY_ID,
        payload: { data },
      });
      // registerItemType();
    } catch (error) {
      ////console.log(`----------------------${error}`);
    }
  };

  /// purchases reciver add

  const purchaseReceiveAdd = async (data) => {
    ////console.log(data);
    try {
      const res = await axios.post("/purchasesReceives/add", data);
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      ////console.log("gg" + error.message);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  /// fetch all purchase
  const fetchAllPurchases = async () => {
    try {
      dispatch({
        type: FETCH_ALL_PURCHASES_BEGIN,
      });
      const data = await axios.get(`/allPurchases`);
      dispatch({
        type: FETCH_ALL_PURCHASES,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // fetch purchase receive update by single id
  const fetchPurchaseReceiveUpdateById = async (id) => {
    try {
      const data = await axios.get(`/allPurchases/${id}`);
      ////console.log(data);
      dispatch({
        type: FECH_PURCHASE_RECEIVE_BY_ID,
        payload: { data },
      });
      // registerItemType();
    } catch (error) {
      ////console.log(`----------------------${error}`);
    }
  };

  /// update purchase recive
  const updatePurchaseReceive = async (data) => {
    ////console.log(data);
    try {
      const updatedReciever = await axios.patch(
        `/allPurchases/update/${data.id}/${data.type}`,
        data
      );
      // fetchRecievers();
      ////console.log(updatedReciever);
      toast.success(updatedReciever.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // delete purchase

  const deletePurchase = async (data) => {
    ////console.log(data);
    try {
      // ${data.purchaseType}
      const res = await axios.delete(
        `allPurchases/delete/${data.id}/${data.type}`
      );

      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // dispatch({ type: DELETE_products_SUCCESS });
      fetchAllPurchases();
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // purchase ordersReciever
  const purchaseOrdersReceivers = async () => {
    try {
      const data = await axios.get(`/purchaseOrders/receivers`);
      dispatch({
        type: FECH_PURCHASE_ORDERS_RECEIVERS,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // FETCH ORDERRS RECIVE BY ID
  // fetch single purchase order
  const FetchSinglePurchaseReceiver = async (id) => {
    try {
      const data = await axios.get(`/purchaseOrders/receivers/${id}`);
      ////console.log(data);
      dispatch({
        type: FETCH_PURCHASE_ORDERS_BY_ID_RECEIVER,
        payload: { data },
      });
      // registerItemType();
    } catch (error) {
      ////console.log(`----------------------${error}`);
    }
  };

  //  deduction  report
  const deductionReport = new Date();
  const formattedDatedeductionReport = moment(deductionReport[0]).format(
    "YYYY-MM-DD"
  );
  let year = deductionReport.getFullYear();
  const fetchDeductionReport = async (month = "all", year = "2022") => {
    try {
      const data = await axios.get(`/deduction/report/${year}/${month}`);

      dispatch({
        type: DEDUCTION_REPORT,
        payload: { data },
      });

      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // advance report
  const advanceReport = new Date();
  const formattedDateAdvamceReport = moment(advanceReport[0]).format(
    "YYYY-MM-DD"
  );
  const fetchAdvanceReport = async (
    start = formattedDateAdvamceReport,
    end = formattedDateAdvamceReport,
    id = "ALL"
  ) => {
    try {
      const data = await axios.get(`/advance/report/${start}/${end}/${id}`);

      dispatch({
        type: FETCH_ADVANCE_REPORT,
        payload: { data },
      });

      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //----------------------------------------------//

  //  ticket supervisor report
  const supplierReport = new Date();
  const formattedDateSupplierReport = moment(supplierReport[0]).format(
    "YYYY-MM-DD"
  );

  const fetchSupplierReport = async (
    start = formattedDateSupplierReport,
    end = formattedDateSupplierReport,
    supplier = "all"
  ) => {
    try {
      const data = await axios.get(
        `/supliers/report/${start}/${end}/${supplier}`
      );

      dispatch({
        type: SUPPLER_REPORT,
        payload: { data },
      });

      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //*manual open card report

  //  ticket supervisor report
  const manualOpenCardRep = new Date();
  const formattedDatemanualOpenCardRep = moment(manualOpenCardRep[0]).format(
    "YYYY-MM-DD"
  );

  const fetchManualOpenCardRep = async (
    start = formattedDatemanualOpenCardRep,
    end = formattedDatemanualOpenCardRep,
    paymentMode = "ALL"
  ) => {
    try {
      const data = await axios.get(
        `/cards/report/manuallyOPenedCards/${start}/${end}/${paymentMode}`
      );

      dispatch({
        type: MANUAL_OPEN_CARD,
        payload: { data },
      });

      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //*closed

  //  ticketsSupervisorsSummaryr report
  const ticketsSupervisorsSummary = new Date();
  const formattedDateticketsSupervisorsSummaryReport = moment(
    ticketsSupervisorsSummary[0]
  ).format("YYYY-MM-DD");

  const fetchticketsSupervisorsSummaryReport = async (
    start = formattedDateticketsSupervisorsSummaryReport,
    end = formattedDateticketsSupervisorsSummaryReport,
    supervisor = "all"
  ) => {
    try {
      const data = await axios.get(
        `/ticketsSupervisorsSummary/report/${start}/${end}/${supervisor}`
      );

      dispatch({
        type: FETCH_TICKET_SUPERVISOR_SUMMARY_REPORT,
        payload: { data },
      });

      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // ------------------------------------------//
  //  ticket supervisor report
  const ticketSupervisorReport = new Date();
  const formattedDateSupervisorRegReport = moment(
    ticketSupervisorReport[0]
  ).format("YYYY-MM-DD");

  const fetchTicketSupervisorReport = async (
    start = formattedDateSupervisorRegReport,
    end = formattedDateSupervisorRegReport,
    supervisor = "all"
  ) => {
    try {
      const data = await axios.get(
        `/ticketsSupervisors/report/${start}/${end}/${supervisor}`
      );

      dispatch({
        type: FETCH_TICKET_SUPERVISOR_REPORT,
        payload: { data },
      });

      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // Employee  report
  const employeRep = new Date();
  const formattedDateEmployeeReport = moment(employeRep[0]).format(
    "YYYY-MM-DD"
  );

  const fetchEmployeeReport = async (
    start = formattedDateEmployeeReport,
    end = formattedDateEmployeeReport,
    employeeId = "ALL"
  ) => {
    try {
      const data = await axios.get(
        `/employee/report/${start}/${end}/${employeeId}`
      );

      dispatch({
        type: FETCH_EMPLOYEE_REPORT,
        payload: { data },
      });

      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchCustIPTVReport = async (
    start = formattedDateEmployeeReport,
    end = formattedDateEmployeeReport,
    type = "APP",
    Line = "all"
  ) => {
    try {
      dispatch({
        type: "FETCH_CUSTOMERIPTV_REPORT_BEGIN",
      });
      const data = await axios.get(
        `/nasiye/iptvCustomers/report/${start}/${end}/${type}/${Line}`
      );

      dispatch({
        type: FETCH_CUSTOMERIPTV_REPORT,
        payload: { data },
      });

      ////console.log(data);
    } catch (error) {
      //console.log(error);
      dispatch({
        type: "FETCH_CUSTOMERIPTV_REPORT_ERROR",
      });
    }
  };

  const RevDate = new Date();
  const formattedRevDate = moment(RevDate[0]).format("YYYY-MM-DD");
  const FetchRevenuReport = async (
    start = formattedRevDate,
    end = formattedRevDate,
    type = "424",
    summary="detailed"
  ) => {
    try {
      dispatch({
        type: "FETCH_REVANUE_REPORT_BEGIN",
      });
      const data = await axios.get(
        `/payments/report/all/${start}/${end}/${type}/${summary}`
      );

      dispatch({
        type: "FETCH_REVANUE_REPORT_SUCCESS",
        payload: { data },
      });

      ////console.log(data);
    } catch (error) {
      //console.log(error);
      dispatch({
        type: "FETCH_CUSTOMERIPTV_REPORT_ERROR",
      });
    }
  };

  // ticket registeration report
  const ticketSummary = new Date();
  const formattedDateTicketSummaryReport = moment(ticketSummary[0]).format(
    "YYYY-MM-DD"
  );

  const fetchTicketSummaryReport = async (
    start = formattedDateTicketSummaryReport,
    end = formattedDateTicketSummaryReport,
    employeeId = "all"
  ) => {
    try {
      const data = await axios.get(
        `/ticketsSummary/report/${start}/${end}/${employeeId}`
      );

      dispatch({
        type: FETCH_TICKETS_SUMMARY_REPORT,
        payload: { data },
      });

      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // ticket registeration report
  const ticketReport = new Date();
  const formattedDateTicketRegReport = moment(ticketReport[0]).format(
    "YYYY-MM-DD"
  );

  const fetchTicketRegesterationReport = async (
    start = formattedDateTicketRegReport,
    end = formattedDateTicketRegReport,
    employeeId = "all"
  ) => {
    try {
      const data = await axios.get(
        `/tickets/report/${start}/${end}/${employeeId}`
      );

      dispatch({
        type: FETCH_TICKET_REG_REPORT,
        payload: { data },
      });

      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // customer statement  report
  // const customerStatementtReport = new Date();
  // const formattedDateCustomerStatementReport = moment(customerStatementtReport[0]).format("YYYY-MM-DD");

  const fetchCustomerStatementReport = async (supplierId) => {
    try {
      const { billIfor } = await axios.get(
        `/reports/customerStatement/${supplierId}`
      );

      dispatch({
        type: FETCH_CUSTOMER_STATEMENT_REPORT,
        payload: { billIfor },
      });

      ////console.log(billIfor);
    } catch (error) {
      //console.log(error);
    }
  };
  // customer list  report
  const customerListReport = new Date();
  const formattedDateCustomerListReport = moment(customerListReport[0]).format(
    "YYYY-MM-DD"
  );

  const fetchCustomerListReport = async (
    start = formattedDateCustomerListReport,
    end = formattedDateCustomerListReport,
    customerId = "ALL",
    userId = "ALL"
  ) => {
    try {
      const { customersList } = await axios.get(
        `/customers/report/${start}/${end}/${customerId}/${userId}`
      );

      dispatch({
        type: FETCH_CUSTOMER_LIST_REPORT,
        payload: { customersList },
      });

      ////console.log(customersList);
    } catch (error) {
      //console.log(error);
    }
  };

  //**424 Report
  const Fetchcustomer424Report = async (start, end, customerId = "all") => {
    try {
      const data = await axios.get(
        `/billings/report/paymentNotification/${start}/${end}/${customerId}`
      );

      dispatch({
        type: CUSTOMER_424_REPORT,
        payload: { data },
      });

      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // purchase receicer report
  const purchaseRecReport = new Date();
  const formattedDatePurchaseRecReport = moment(purchaseRecReport[0]).format(
    "YYYY-MM-DD"
  );

  const fetchPurchaseReceiveReport = async (
    start = formattedDatePurchaseRecReport,
    end = formattedDatePurchaseRecReport,
    supplierId = "all"
  ) => {
    try {
      const data = await axios.get(
        `/reports/purchasesReceives/${start}/${end}/${supplierId}`
      );

      dispatch({
        type: FETCH_PURCHASE_RECEIVE_REPORT,
        payload: { data },
      });

      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // purchase receicer report
  const purchaseOrderReceive = new Date();
  const formattedDatepurchaseOrderReport = moment(
    purchaseOrderReceive[0]
  ).format("YYYY-MM-DD");
  const fetchPurchaseOrderReport = async (
    start = formattedDatepurchaseOrderReport,
    end = formattedDatePurchaseRecReport,
    supplierId = "ALL"
  ) => {
    try {
      const { purchaseOrderData } = await axios.get(
        `/reports/purchases/purchaseOrder/${start}/${end}/${supplierId}`
      );

      dispatch({
        type: FETCH_PURCHASE_ORDER_REPORT,
        payload: { purchaseOrderData },
      });

      ////console.log(purchaseOrderData);
    } catch (error) {
      //console.log(error);
    }
  };
  // purchase ordersReciever
  const inventoryReport = async () => {
    try {
      const data = await axios.get(`/stocks`);
      dispatch({
        type: FETCH_INVENTORY_REPORTS,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // purchase ordersReciever
  const purchaseReport = async () => {
    try {
      const data = await axios.get(`/reports/purchases`);
      dispatch({
        type: FETCH_PURCHASE_REPORTS,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // fetch Analog open card report
  const theDate = new Date();
  const formattedDate = moment(theDate[0]).format("YYYY-MM-DD");
  const FetchOpenCardsReport = async (
    start = formattedDate,
    end = formattedDate
  ) => {
    ////console.log(formattedDate);
    try {
      const data = await axios.get(`/reports/analogOpenCardsDate/${start}/${end}`);
      dispatch({
        type: FETCH_OPEN_CARD_REPORT,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

    // fetch Analog open card report
    const theDate1 = new Date();
    const formattedDate1 = moment(theDate1[0]).format("YYYY-MM-DD");
    const FetchRedlineOpenCardsReport = async (
      start = formattedDate1,
      end = formattedDate1
    ) => {
      ////console.log(formattedDate);
      try {
        const data = await axios.get(`/reports/redlineOpenCardsDate/${start}/${end}`);
        dispatch({
          type: "FETCH_REDLINE_OPEN_CARD_REPORT",
          payload: { data },
        });
        // registerItemType();
        ////console.log(data);
      } catch (error) {
        //console.log(error);
      }
    };
  /// fetch cable report by date
  const cableReportDate = new Date();
  const formattedcableReportDate = moment(cableReportDate[0]).format(
    "YYYY-MM-DD"
  );
  const FetchSummaryCableReport = async (
    start = formattedcableReportDate,
    end = formattedcableReportDate
  ) => {
    ////console.log(formattedcableReportDate);
    try {
      const data = await axios.get(`/reports/cables/${start}/${end}`);
      dispatch({
        type: FETCH_CABLE_REPORT,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  /// fetch close report by date
  const CloseReportDate = new Date();
  const formattedCloseReportDate = moment(CloseReportDate[0]).format(
    "YYYY-MM-DD"
  );
  const FetchSummaryCloseReport = async (
    start = formattedCloseReportDate,
    end = formattedCloseReportDate
  ) => {
    ////console.log();
    try {
      const data = await axios.get(`/reports/closeCardsDate/${start}/${end}`);
      dispatch({
        type: FETCH_CLOSE_CARDS_REPORT,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // fetch all open card report
  const FetchAllOpenCardsReport = async () => {
    try {
      const data = await axios.get(`reports/openCards`);
      dispatch({
        type: FETCH_ALL_OPEN_CARD_REPORT,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // sales ordersReciever
  const getsalesReport = async () => {
    try {
      const data = await axios.get(`/reports/sales`);
      dispatch({
        type: FETCH_SALES_REPORTS,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // sales ordersReciever
  const getAutoRecepts = async (start , end ) => {
    try {
      const data = await axios.get(`/autoReceipts/${start}/${end}`);
      dispatch({
        type: AUTORECEPTS,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // purchase ordersReciever by date
  const purchaseReportByDate = async (start, end) => {
    try {
      const data = await axios.get(`/reports/purchases/${start}/${end}`);
      dispatch({
        type: PURCHASE_REPORT_DATE,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // purchase ordersReciever by date
  const purchaseSalesByDate = async (start, end) => {
    try {
      const data = await axios.get(`/reports/sales/${start}/${end}`);
      dispatch({
        type: PURCHASE_SALES_DATE,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // iptv customer recharge
  const iptvCustomerRechargeDate = new Date();
  const formattediptvCustomerRechargeDate = moment(
    iptvCustomerRechargeDate[0]
  ).format("YYYY-MM-DD");
  const FechIptvCustomerRechargeReport = async (
    start = formattediptvCustomerRechargeDate,
    end = formattediptvCustomerRechargeDate
  ) => {
    // ////console.log(formattedcableReportDate);
    try {
      const data = await axios.get(
        `/nasiye/iptvCustomerRecharges/report/${start}/${end}`
      );
      dispatch({
        type: IPTV_CUSTOMER_RECHARGE,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // ott customer recharge
  const ottCustomerRechargeDate = new Date();
  const formattedottCustomerRechargeDate = moment(
    ottCustomerRechargeDate[0]
  ).format("YYYY-MM-DD");
  const FechOttCustomerRechargeReport = async (
    start = formattedottCustomerRechargeDate,
    end = formattedottCustomerRechargeDate
  ) => {
    // ////console.log(formattedcableReportDate);
    try {
      const data = await axios.get(
        `/ottp/hormuud/customers/report/${start}/${end}`
      );
      dispatch({
        type: OTT_CUSTOMER_RECHARGE,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // // expired iptv customer
  const FetchExpiredIptvs = async () => {
    try {
      const data = await axios.get("/nasiye/iptvs/expired/report");
      dispatch({ type: EXPIRED_IPTVS_REPORT, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
   const FetchExpireNasiyeCustomers = async () => {
    try {
      const data = await axios.get("/nasiye/expired/all");
      dispatch({ type: "EXPIRED_NASIYE_CUSTOMERS", payload: { data } });
      // ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  //expired ott customer
  const FetchExpiredOtt = async () => {
    try {
      const data = await axios.get("/hormuud/ott/expired/report");
      dispatch({ type: EXPIRED_OTT_REPORT, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // get active nasiye customers
  const fetchActiveNasiyeIptvCustomers = async () => {
    try {
      const data = await axios.get("/nasiye/iptvs/active/report");
      dispatch({ type: "ACTIVE_NASIYE_CUSTOMERS", payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  // active nasiye customers from online nasiye API
    const fetchActiveNasiyeCustomers = async () => {
    try {
      const data = await axios.get("/nasiye/active/all");
      dispatch({ type: EXPIRED_OTT_REPORT, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  /// paymetn filter date
  const paymentFilterByDate = async (start, end) => {
    try {
      const data = await axios.get(`/payments/${start}/${end}`);
      dispatch({
        type: PAYMENT_FILTER_DATE,
        payload: { data },
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // fetch mac address and serial number by passing subscrionId
  const fetchMacAddressAndSerialNumber = async (subscriptionId) => {
    // ////console.log(subscriptionId);
    try {
      const data = await axios.get(
        `/iptv/nasiye/getMacAddressAndSerialNumber/${subscriptionId}`
      );
      dispatch({
        type: GET_MAC_ADDRESS_AND_SERIAL_NUMBER,
        payload: { data },
      });
      // registerItemType();
    } catch (error) {
      ////console.log(`----------------------${error}`);
    }
  };

  // GET CUSTOMERS WITH IPVS
  const fetchCustomersWithIptvs = async () => {
    // dispatch({ type: CUSTOMER_REG_BEGIN });
    try {
      const data = await axios.get("/customers/iptvs/all");
      dispatch({ type: FETCH_ONLY_CUSTOMERS_WITH_IPTVS, payload: { data } });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // REGISTER TRANSFER IPTV
  const transferIPTV = async (data) => {
    ////console.log(data);
    dispatch({ type: TRANSFER_IPTV_BEGIN });
    try {
      const res = await axios.post("/iptv/nasiye/transfer", data);
      dispatch({ type: TRASFER_IPTV_SUCCESS });
      if (res.status == "success") {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_ADVANCE_ERROR });
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(error);
    }
  };
  /// managePurchase filter date
  const managePurchaseFilterByDate = async (start, end) => {
    try {
      // dispatch({
      //   type:  dispatch({
      //     type: MANAGE_PURCHASE_FILTER_BEGIN,

      //   })

      // });
      const data = await axios.get(`/allPurchases/${start}/${end}`);
      dispatch({
        type: MANAGE_PURCHASE_FILTER,
        payload: data,
      });
      // registerItemType();
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  // fetch last receiver number , last card number

  const FetchLastReceiverNumberAndLastCardNumber = async (dataa) => {
    try {
      const data = await axios.get(
        `/fetch/serial/${dataa.itemId}/${dataa.type}/${dataa.typeId}`
      );
      ////console.log(data);
      if (data.message !== "found") {
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      dispatch({
        type: FETCH_LAST_SERIAL,
        payload: { data },
      });
      // registerItemType();
    } catch (error) {
      ////console.log(`----------------------${error}`);
      // toast.error(error.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  };

  // fetch receiver dont delete this fn
  const fetchReciever = async (dataa) => {
    try {
      const data = await axios.get(
        `/fetch/receiver/${dataa.receiverType}/${dataa.salesType}/${dataa.typeId}`
      );
      ////console.log(data);
      // if (data.message !== "found") {
      //   toast.error(data.message, {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // }

      dispatch({
        type: FETCH_RECEIVER,
        payload: { data },
      });
      // registerItemType();
    } catch (error) {
      ////console.log(`----------------------${error}`);
      // toast.error(error.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  };
  const fetchItemOtherPlusAVA = async (dataa) => {
    try {
      const data = await axios.get(
        `/items/others/${dataa.salesType}/${dataa.typeId}`
      );
      ////console.log(data);
      if (data.message !== "found") {
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      dispatch({
        type: FETCH_LAST_SERIAL_sp,
        payload: { data },
      });
      // registerItemType();
    } catch (error) {
      ////console.log(`----------------------${error}`);
    }
  };

  // item transfering
  const transferItems = async (dataa) => {
    try {
      const data = await axios.get(
        `/items/distribution/${dataa.fromStoreToStoreee}/${dataa.from}`
      );
      ////console.log(data);
      if (data.message !== "found") {
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      dispatch({
        type: TRANSFER_ITEM,
        payload: { data },
      });
      // registerItemType();
    } catch (error) {
      ////console.log(`----------------------${error}`);
    }
  };

  /// fetch the items that fetch the item with ites details
  const fetchItemOtherSINGLEPlusAVA = async (id) => {
    ////console.log(id);
    try {
      const data = await axios.get(`/items/others/${id}`);
      ////console.log(data);
      if (data.message !== "found") {
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      dispatch({
        type: FETCH_LAST_SERIAL_sp_SINGLE,
        payload: { data },
      });
      // registerItemType();
    } catch (error) {
      ////console.log(`----------------------${error}`);
    }
  };

  // fetch cable information

  const fetchCableInformation = async (id) => {
    ////console.log(id);
    try {
      const data = await axios.get(`/fetch/serial/${id}`);
      ////console.log(data);
      if (data.message !== "found") {
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      dispatch({
        type: FETCH_CABLE_INFO,
        payload: { data },
      });
      // registerItemType();
    } catch (error) {
      ////console.log(`----------------------${error}`);
    }
  };

  const addNewAdmin = async (user) => {
    ////console.log(user);
    try {
      const res = await axios.post("/menus/submenu/add", user);
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchSingleRoleReport = async (id) => {
    ////console.log(id);
    try {
      const data = await axios.get(`/reports/isAdminOrUser/${id}`);
      ////console.log(data);

      dispatch({
        type: "SINGLE_REPORT_ROLE",
        payload: { data },
      });
    } catch (error) {
      //console.log(error);
    }
  };

  //// receiver disturbution
  const ReceiverDistribution = async (data) => {
    dispatch({ type: RECEIVER_DISTURBUTION_BEGIN });

    try {
      const response = await axios.post("/fetch/distributions", data);
      // dispatch({ type: REGISTER_ITEM_TYPE_SUCCESS });

      toast.success(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({ type: RECEIVER_DISTURBUTION_SUCCESS });
    } catch (error) {
      ////console.log("gg" + error);
      // const itemDont = 'item'
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const getAnalogCustomerCardList = async () => {
    try {
      const response = await axios.get("/analogCustomerCardList");
      dispatch({ type: GET_CUSTOMER_CARD_LIST, payload: { response } });

      // toast.success(response.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } catch (error) {
      ////console.log("gg" + error);
      // const itemDont = 'item'
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // Redline customers
  const getRedlineCustomerList = async () => {
    try {
      const response = await axios.get("/redlineCustomerCardList");
      dispatch({ type: "GET_REDLINE_CUSTOMER_CARD_LIST", payload: { response } });

      // toast.success(response.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } catch (error) {
      ////console.log("gg" + error);
      // const itemDont = 'item'
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const getSupervisors = async () => {
    try {
      const response = await axios.get("/employee/Supervisor");
      dispatch({ type: GET_SUPERVISORS, payload: { response } });
      ////console.log(response);
      // toast.success(response.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } catch (error) {
      ////console.log("gg" + error);
      // const itemDont = 'item'
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const ticketsSupervisorAdd = async (data) => {
    try {
      dispatch({ type: TICKETS_SUPERVISOR_ADD_BEGIN });
      const response = await axios.post("/ticketsSupervisor/add", data);
      // dispatch({ type: REGISTER_ITEM_TYPE_SUCCESS });

      toast.success(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({ type: TICKETS_SUPERVISOR_ADD_SUCESS });
    } catch (error) {
      ////console.log("gg" + error);
      // const itemDont = 'item'
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const giveRoles = async (data) => {
    try {
      const response = await axios.post("/systemRoles/add", data);
      // dispatch({ type: REGISTER_ITEM_TYPE_SUCCESS });

      toast.success(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      ////console.log("gg" + error);

      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //** report roles */
  const giveReportRoles = async (data) => {
    try {
      const response = await axios.post("/reports/reportRoles/add", data);

      toast.success(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      ////console.log("gg" + error);

      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getSupervisorsSpecials = async () => {
    try {
      const response = await axios.get("/ticketsSupervisor");
      dispatch({ type: GET_SUPERVISORS_SPECIAL, payload: { response } });
      ////console.log(response);
      // toast.success(response.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } catch (error) {
      ////console.log("gg" + error);
      // const itemDont = 'item'
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };



  // these functions are not calling api they are just related to UI componenets //
  const resetItemTransfer = () => {
    dispatch({
      type: RESET_ITEM_TRANSFER,
    });
  };
  const resetReceiverTransfer = () => {
    dispatch({
      type: RESET_RECEIVER_TRANSFER,
    });
  };
  const resetBillingReceivers = () => {
    dispatch({
      type: RESET_BILLING_RECEIVER,
    });
  };
  const resetBillingItems = () => {
    dispatch({
      type: RESET_BILLING_ITEM,
    });
  };
  const resetItemSalesItems = () => {
    dispatch({
      type: RESET_ITEM_SALES_ITEM,
    });
  };
  const resetItemSalesReceivers = () => {
    dispatch({
      type: RESET_ITEM_SALES_RECEIVER,
    });
  };
  const resetQuantityAvailable = () => {
    dispatch({
      type: RESET_QUANTITY_AVAILABLE,
    });
  };
  const reset_the_cardnumber = () => {
    dispatch({
      type: RESET_THE_CARDNUMBER,
    });
  };
  const reset_menus = () => {
    dispatch({
      type: "RESET_THE_MENUS",
    });
  };
  const reset_report = () => {
    dispatch({
      type: "RESET_THE_REPORT",
    });
  };

  // menuuus

  const fetchMenus = async () => {
    dispatch({ type: LOADING2 });
    try {
      const response = await axios.get("/menusRole");
      dispatch({ type: GET_MENUS, payload: { response } });

      // toast.success(response.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } catch (error) {
      ////console.log("gg" + error);
      // const itemDont = 'item'
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchSidebarMenus = async (id) => {
    const k = " ";
    // dispatch({ type: LOADING2 });
    try {
      const response = await axios.get(`/menus/${id}`);
      dispatch({ type: GET_SIDEBAR_MENU, payload: { response } });

      // toast.success(response.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } catch (error) {
      ////console.log("gg" + error);
      // const itemDont = 'item'
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // fetch single user menus
  const fetchSingleUserMenus = async (userId) => {
    dispatch({ type: LOADING2 });
    try {
      const response = await axios.get(`/getMenus/${userId}`);
      dispatch({ type: GET_SINGLE_MENU, payload: { response } });
    } catch (error) {
      ////console.log("gg" + error);
      // const itemDont = 'item'
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  //** */ fetch single user role report
  const fetchSingleUserRoleReport = async (userId) => {
    try {
      const response = await axios.get(`/getReportRoles/${userId}`);
      dispatch({ type: GET_USER_REPORT_ROLE, payload: { response } });
    } catch (error) {
      ////console.log("gg" + error);

      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // change card number
  const changeReceiverCardNumber = async (data) => {
    try {
      const response = await axios.patch("/sales/updateCable/update", data);

      toast.success(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      ////console.log("Error" + error);

      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // payroll summary report
  // fetch payroll summary by year
  const payrollSummary = new Date();
  const formattedDateSummaryReport = moment(payrollSummary[0]).format(
    "YYYY-MM-DD"
  );
  const fetchPayrollSummaryReport = async (year = "") => {
    try {
      const data = await axios.get(`/GeneratePayrollReport/summary/${year}`);
      dispatch({
        type: FETCH_PAYROLL_SUMMARY_REPORT,
        payload: { data },
      });
    } catch (error) {
      ////console.log(`----------------------${error}`);
    }
  };

  // payroll detail report
  const payrollDetails = new Date();
  const formattedDateDetailsReport = moment(payrollDetails[0]).format(
    "YYYY-MM-DD"
  );
  const fetchPayrollDetailsReport = async (year, month, id = "ALL") => {
    try {
      const data = await axios.get(
        `/GeneratePayrollReport/detail/${year}/${month}/${id}`
      );
      dispatch({
        type: FETCH_PAYROLL_DETAILS_REPORT,
        payload: { data },
      });
    } catch (error) {
      ////console.log(`----------------------${error}`);
    }
  };

  // payroll detail report
  const ticketTransfer = new Date();
  const formattedTicketTransferReport = moment(ticketTransfer[0]).format(
    "YYYY-MM-DD"
  );
  const fetchTicketTransferReport = async (
    start = formattedTicketTransferReport,
    end = formattedTicketTransferReport,
    id = "ALL"
  ) => {
    try {
      const data = await axios.get(
        `/tickets/ticketTransfer/report/${start}/${end}/${id}`
      );
      dispatch({
        type: FETCH_TICKET_TRANSFER_REPORT,
        payload: { data },
      });
    } catch (error) {
      ////console.log(`----------------------${error}`);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getAllYears = async () => {
    try {
      const data = await axios.get("/GeneratePayrollReport/getAllYears");
      dispatch({ type: GET_ALL_YEARS, payload: { data } });
      ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

// enroll employee
  const enrollSingleEmployee = async (data) => {
    try {
      dispatch({ type: SEND_FINGERPRINT_BEGIN });
      // const res = await axios.post("/attendance/enroll", data);
      const res = await axios.post("/attendances/employees/enroll", data);
      dispatch({ type: SEND_FINGERPRINT_SUCCESS });
      if (res.status == "success" ) {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        ////console.log(res);
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      dispatch({ type: SEND_FINGERPRINT_FAIL });
      //console.log(error);
      toast.error(error.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // register devices
  const registerDevice = async (data) => {
    // ////console.log(data);
    try {
      const res = await axios.post("/devices/add", data);
      fetchDevices();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      // dispatch({ type: REGISTER_CUSTOMER_ERROR });
      fetchDevices();
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // ////console.log("gg" + error.message);
    }
  };

  // fetch devices 
  const fetchDevices = async () => {
    dispatch({ type: DEVICES_REG_BEGIN });
    try {
      const data = await axios.get("/devices");
      dispatch({ type: FETCH_DEVICES_SUCCESS, payload: { data } });
      // registerItemType();
      // ////console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  //update devices
  const updateDevices = async (data) => {
    // ////console.log(data);
    try {
      const updatedAgent = await axios.patch(
        `/devices/update/${data.id}`,
        data
      );
      fetchDevices();
      // ////console.log(updatedAgent);
      fetchDevices();
      toast.success(updatedAgent.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // delete devics
  const deleteDevices= async (id) => {
    try {
      const res = await axios.delete(`/devices/delete/${id}`);
      fetchDevices();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // dispatch({ type: DELETE_products_SUCCESS });
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // get attendance date range
  const AttendanceData = new Date();
  const formattedDateAttendanceData = moment(AttendanceData[0]).format(
    "YYYY-MM-DD"
  );

  const fetchAttendanceData = async ( data1 ) => {
    try {
      dispatch({
        type: "FETCH_ATTENDANCE_DATA_BEGIN",
      });
      const data = await axios.get(
        `/attendances/range/date/${data1.startDate}/${data1.endDate}/${data1.deviceIp}/${data1.employees}/${data1.depts}`
      );
      // ////console.log('response data ==================================',res)
      dispatch({
        type: "FETCH_ATTENDANCE_DATA",
        payload: { data },
      });

      // ////console.log(data);
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  // get monthly attendance report 
  const getMonthlyAttendanceReport = async (
    data1

  ) => {
    try {
      dispatch({
        type: "FETCH_MONTHLY_ATTENDANCE_DATA_BEGIN",
      });
      const data = await axios.get(
        `/attendances/report/monthly/${data1.startDate}/${data1.endDate}/${data1.deviceIp}/${data1.employees}/${data1.depts}`
      );

      dispatch({
        type: "FETCH_MONTHLY_ATTENDANCE_DATA",
        payload: { data },
      });

      // ////console.log(data);
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const updateDate = new Date();
  const formattedDateAttendance = moment(
    updateDate[0]
  ).format("YYYY-MM-DD");
  // fetch attendance updates
  const fetchAttendanceUpdates = async (deviceIp,
    startDate=formattedDateAttendance,
    endDate= formattedDateAttendance
    ) => {
    try {
      dispatch({ type: "FETCH_ATTENDANCE_BEGIN" });
      const data = await axios.get(
        `/attendances/data/all/${deviceIp}/${startDate}/${endDate}`
      );

      dispatch({
        type: FETCH_ATTENDANCE_UPDATES,
        payload: { data },
      });
      if (data.status == "success") {
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      if (data.status == "fail" || data.status == 400) {
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      dispatch({ type: "FETCH_ATTENDANCE_END" });
      // ////console.log(data);
    } catch (error) {
      //console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
//  available card numbers
  const fetchAvailableCardNumbers = async () => {
    try {
      const data = await axios.get("/recievers/avaialable/cards");
      dispatch({ type: FETCH_AVAILABLE_CARDS, payload: { data } });
      //  ////console.log(data)
    } catch (error) {
      //console.log(error);
    }
  };

   // fetch mac address and serial number by passing subscrionId
   const getBothCustomersByType = async (type) => {
    // ////console.log(type);
    try {
      const data = await axios.get(
        `/customers/both/analog/iptv/${type}`
      );
      dispatch({
        type: SEND_SMS_NOTIFICATION,
        payload: { data },
      });
      // registerItemType();
    } catch (error) {
      ////console.log(`----------------------${error}`);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
 //*send sms to analog and iptv customers
 const sendSmsNotificantionCustomers = async (data) => {
  // ////console.log(data);
  try {
    dispatch({ type: "SMS_BEGIN" });
    const res = await axios.post(
      "/customers/sms/notification",
      data
    );
    toast.success(res.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    dispatch({ type: "SMS_END" });
    
  } catch (error) {
    ////console.log(`----------------------${error}`);
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
 //*Re-send sms to analog and iptv customers
 const resendSmsToCustomers = async (data) => {
  // ////console.log(data);
  try {
    dispatch({ type: "RESEND_SMS_BEGIN" });
    const res = await axios.post(
      "/customers/sms/resend",
      data
    );
    toast.success(res.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    dispatch({ type: "RESEND_SMS_END" });
    
  } catch (error) {
    ////console.log(`----------------------${error}`);
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

// fetch tickets resolved by suppervisors
const ticketsDate = new Date();
const formattedDateTicketSupervisorRegReport = moment(
  ticketsDate[0]
).format("YYYY-MM-DD");

const fetchTicketsByResolved = async (
  start = formattedDateTicketSupervisorRegReport,
  end = formattedDateTicketSupervisorRegReport,
  supervisor = "all"
) => {
  try {
    const data = await axios.get(
      `/tickets/supervisors/single/report/${start}/${end}/${supervisor}`
    );

    dispatch({
      type: FETCH_RESOLVED_TICKETS,
      payload: { data },
    });

    ////console.log(data);
  } catch (error) {
    //console.log(error);
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

// register sms messages
const regsiterSmsMessages = async (data) => {
  ////console.log(data);
  try {
    ////console.log("data________", data);
    const res = await axios.post("sms/add", data);
    ////console.log("Response:--", res);
    fetchSmsMessages();
    toast.success(res.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    // //console.log(error);
  }
};

// read shifts
const fetchSmsMessages = async () => {
  try {
    const data = await axios.get("/sms/all");
    dispatch({ type: FETCH_SMS_MESSAGES, payload: { data } });
    // registerItemType();
    ////console.log(data);
  } catch (error) {
    //console.log(error);
  }
};

//delete shifts
const deleteSmsMessages = async (id) => {
  try {
    const res = await axios.delete(`/sms/delete/${id}`);
    fetchSmsMessages();
    toast.success(res.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
    //console.log(error);
  }
};

// update shifts

const updateSmsMessages = async (data) => {
  ////console.log(data);
  try {
    const updatedShift = await axios.patch(`sms/update/${data.id}`, data);
    fetchSmsMessages();
    toast.success(updatedShift.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    ////console.log(updatedShift);
  } catch (error) {
    //console.log(error);
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

// fetch sold cards
const fetchSoldCards = async () => {
  // ////console.log(id);
  try {
    const data = (await axios.get(`/sales/receivers/sold`)) || [];
    dispatch({ type: FETCH_SOLD_CARDS, payload: { data } });
    // registerItemType();
    // ////console.log(data);
  } catch (error) {
    //console.log(error);
  }
};


// cancel cards 
const cancelCards = async (cardNumber) => {
  // ////console.log(data);
  try {
    const updated = await axios.patch(
      `/sales/receivers/cancel/${cardNumber}`,
      cardNumber
    );
    fetchSoldCards();
    ////console.log(updated);
    toast.success(updated.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    //console.log(error);
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
const sendSmsWithExpiredCustomers = async (data) => {
  try {
    dispatch({ type: CUST_SMS_BEGIN });
    const res = await axios.post("/customers/expired/sms/send", data);
    dispatch({ type: CUST_SMS_SUCCESS });
    if (res.status == "success") {
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      ////console.log(res);
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    dispatch({ type: CUST_SMS_END });
    //console.log(error);
    toast.error(error.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
// fetch expired customer cards
const fetchExpiredCustomerCards = async () => {
  // ////console.log(id);
  try {
    const data = (await axios.get(`/customers/expired/cards/all`)) || [];
    dispatch({ type: FETCH_EXPIRED_CARDS, payload: { data } });
    // registerItemType();
    // ////console.log(data);
  } catch (error) {
    //console.log(error);
  }
};
// transfer receivers
const transferReceivers = async (data) => {
  // ////console.log(data);
  try {
    dispatch({ type: "TRANSFER_BEGIN" });
    const res = await axios.post(
      "/sales/receivers/transfer",
      data
    );
    fetchSoldCards()
    if(res.status === "success"){
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    dispatch({ type: "TRANSFER_END" });
    }
    if(res.status === "fail"){
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    } 
  } catch (error) {
    ////console.log(`----------------------${error}`);
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

// upload active nasiye customers
const uploadActiveNasiyeCustomers = async (data) => {
  // ////console.log(data);
  try {
    ////console.log("data________", data);
    const res = await axios.post("/nasiye/customers/upload", data);
    // ////console.log("Response:--", res);
    // fetchSmsMessages();
    if(res.status == "success"){
    toast.success(res.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    // //console.log(error);
  }
};
// upload expired nasiye customers
const uploadExpiredNasiyeCustomers = async (data) => {
  // ////console.log(data);
  try {
    // ////console.log("data________", data);
    const res = await axios.post("/nasiye/expired/upload", data);
    // ////console.log("Response:--", res);
    // fetchSmsMessages();
    if(res.status == "success"){
    toast.success(res.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    // //console.log(error);
  }
};

const fetchSpecificCustomers = async () => {
  try {

    const data = (await axios.get("/customers/iptvs/all/data")) || [];
    dispatch({
      type: GET_ALL_IPTV_CUST,
      payload: { data },
    });

    // ////console.log(data);
  } catch (error) {
    //console.error(error);
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
const fetchIptvOrganization = async (search="61") => {
  try {
    const data = await axios.get(`/customers/iptvs/type/organization/${search}`);
    dispatch({ type: "IPTV_ORGANIZATION_REPORT", payload: { data } });
    // registerItemType();
    ////console.log(data);
  } catch (error) {
    //console.log(error);
  }
};

const getDailyAttendance = async (data1) => {
  try {
    dispatch({
      type: "FETCH_DAILY_ATTENDANCE_BEGIN",
    });
    const data = await axios.get(`/attendances/daily/report/${data1.employees}/${data1.depts}`);

    dispatch({
      type: FETCH_DAILY_ATTENDANCE,
      payload: { data },
    });

    // ////console.log(data);
  } catch (error) {
    //console.error(error);
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
// fetch leave categories
const fetchLeaveCategories = async () => {
  try {
    ////console.log(data);
    const data = await axios.get(`/category`);
    dispatch({ type: "FECH_LEAVE_CATEGORIES", payload: { data } });
    // registerItemType();
    ////console.log(data);
  } catch (error) {
    //console.log(error);
  }
};
// register leave categories
const registerLeaveCategory = async (data) => {
  ////console.log(data);
  try {
    const res = await axios.post("/category/add", data);
    fetchLeaveCategories();
  
    if (res.status == "success") {
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    dispatch({ type: "LEAVE_CATEGORY_ERROR" });
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    console.error(error);
  }
};
// delete leave categories
const deleteLeaveCategory = async (id) => {
  ////console.log(id);
  try {
    const res = await axios.delete(`/category/delete/${id}`);
    fetchLeaveCategories()
    if(res.status == "success" ){
      toast.success(res.message, {
         position: toast.POSITION.TOP_RIGHT,
    }); 
    }
    else{
      toast.error(res.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
    // dispatch({ type: DELETE_products_SUCCESS });
  } catch (error) {
    //console.log(error);
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
// update Leave categories
const updateLeaveCategories = async (data) => {

  try {
    
    const res = await axios.patch(`/category/update/${data.id}`, data);
  
    fetchLeaveCategories();
    if(res.status == "success"){
    toast.success(res.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    // //console.log(error);
  }
};
// fetch Employee Leaves
const fetchEmployeeLeaves = async () => {
  try {
    ////console.log(data);
    const data = await axios.get(`/leave`);
    dispatch({ type: "FETCH_EMPLOYEE_LEAVES", payload: { data } });
    // registerItemType();
    ////console.log(data);
  } catch (error) {
    //console.log(error);
  }
};
// register Employee leave 
const registerEmployeeLeaves = async (data) => {
  ////console.log(data);
  try {
    const res = await axios.post("/leave/add", data);
    fetchEmployeeLeaves();
  
    if (res.status == "success") {
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    dispatch({ type: "EMP_LEAVE_ERROR" });
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    console.error(error);
  }
};
// delete Employee leaves
const deleteEmployeeLeave = async (id) => {
  ////console.log(id);
  try {
    const res = await axios.delete(`/leave/delete/${id}`);
    fetchEmployeeLeaves();
    if(res.status == "success" ){
      toast.success(res.message, {
         position: toast.POSITION.TOP_RIGHT,
    }); 
    }
    else{
      toast.error(res.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
    // dispatch({ type: DELETE_products_SUCCESS });
  } catch (error) {
    //console.log(error);
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
// update Employee Leave 
const updateEmployeeLeave = async (data) => {
  // ////console.log(data);
  try {
    ////console.log("data________", data);
    const res = await axios.patch(`/leave/update/${data.id}`, data);
    // ////console.log("Response:--", res);
    fetchEmployeeLeaves();
    if(res.status == "success"){
    toast.success(res.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    // //console.log(error);
  }
};

// fetch multi shift employes
const fetchMultiShifts = async () => {
  try {
    ////console.log(data);
    const data = await axios.get(`/multiShifts`);
    dispatch({ type: "FECH_MULTI_SHIFTS", payload: { data } });
    // registerItemType();
    ////console.log(data);
  } catch (error) {
    //console.log(error);
  }
};
// register multi shift employees
const registerMultiShift = async (data) => {
  ////console.log(data);
  try {
    const res = await axios.post("/multiShifts/add", data);
    fetchMultiShifts();
  
    if (res.status == "success") {
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    dispatch({ type: "MULTI_SHIFT_ERROR" });
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    console.error(error);
  }
};
// delete multi shift employees
const deleteMultiShift = async (id) => {
  ////console.log(id);
  try {
    const res = await axios.delete(`/multiShifts/${id}`);
    fetchMultiShifts()
    if(res.status == "success" || res.status == true ){
      toast.success(res.message, {
         position: toast.POSITION.TOP_RIGHT,
    }); 
    }
    else{
      toast.error(res.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
    // dispatch({ type: DELETE_products_SUCCESS });
  } catch (error) {
    //console.log(error);
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
// update multi shift employees
const updateMultiShift = async (data) => {

  try {
    
    const res = await axios.patch(`/multiShifts/${data.id}`, data);
  
    fetchMultiShifts();
    if(res.status == "success"){
    toast.success(res.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    // //console.log(error);
  }
};
const fetchEmployeesWithMultipleShifts = async () => {
  try {
    ////console.log(data);
    const data = await axios.get(`/multiShifts/multiple`);
    dispatch({ type: "FECH_EMP_MULTI_SHIFTS", payload: { data } });
    // registerItemType();
    ////console.log(data);
  } catch (error) {
    //console.log(error);
  }
};
  return (
    <LoginContex.Provider
      value={{
        ...state,
        registerUser,
        handlePrint,
        giveRoles,
        getAutoRecepts,
        fetchSidebarMenus,
        changeReceiverCardNumber,
        getSupervisors,
        fetchSingleUserMenus,
        reset_the_cardnumber,
        ticketsSupervisorAdd,
        fetchMenus,
        resetReceiverTransfer,
        resetBillingReceivers,
        resetBillingItems,
        resetQuantityAvailable,
        reset_menus,
        resetItemSalesItems,
        fetchItemOtherPlusAVA,
        resetItemTransfer,
        resetItemSalesReceivers,
        getAnalogCustomerCardList,
        transferItems,
        fetchReciever,
        fetchItemOtherSINGLEPlusAVA,
        promotion,
        ReceiverDistribution,
        fetchCableInformation,
        managePurchaseFilterByDate,
        FetchLastReceiverNumberAndLastCardNumber,
        getSupervisorsSpecials,
        paymentFilterByDate,
        purchaseReportByDate,
        getByIdCardNumber,
        getsalesReport,
        purchaseReport,
        purchaseSalesByDate,
        fetchAllPurchases,
        inventoryReport,
        FetchSinglePurchaseReceiver,
        deleteZoneAPI,
        ZonesUpdate,
        fetchPurchaseReceiveUpdateById,
        purchaseOrdersReceivers,
        updatePurchaseReceive,
        purchaseReceiveAdd,
        itemSalesRegisteration,
        deletePurchase,
        fetchPurchaseOrders,
        FetchItemsNotReceivers,
        FetchSinglePurchase,
        FetchReciverCardNumberAndSerials,
        FetchItemsReceivers,
        addReceiverDetails,
        getPurchasesInfoById,
        fetchPaymentsById,
        getPurchasesInfo,
        fetchRecievedNumberCard,
        fetchPurchasedId,
        logoutUser,
        updateReciever,
        deleteRecievers,
        fetchRecievers,
        registerReciever,
        getSiteCards,
        getSingleCustomer,
        getSingleEmployee,
        registerSales,
        fetchQuantityAvailable,
        fetchItemById,
        openAnalogCardRegister,
        fetchOpenCards,
        FetchItemsNotReceiversById,
        fetchAllIPTVS,
        registerIPTV,
        removeIPTV,
        UpdateIPTV,
        fetchCables,
        registerItemDisturbution,
        registerDisturbutionWithOutSerial,
        updateAgents,
        deleteAgents,
        getAllUsers,
        UpdateUser,
        fetchAgents,
        registerPackage,
        updateChannelPackage,
        registerAgent,
        deletePackage,
        fetchId,
        updatePayment,
        registerPurchaseReturn,
        deletePayment,
        registerPayment,
        fetchPayments,
        registerPurchase,
        updatePurchases,
        fetchIPTVUpdateById,
        GetIPTVHormuud,
        removePurchase,
        registerStore,
        fetchPurchase,
        deleteProduct,
        itemRegisteration,
        updateProduct,
        readProducts,
        deleteStores,
        upadteStore,
        fetchItems,
        fetchStores,
        updateItem,
        deleteSupplier,
        registerItemType,
        fetchItemType,
        deleteItemType,
        updateItemType,
        fetchZones,
        fetchAllZones,
        getByIdGroupCable,
        fetchGroupsToAssign,
        fetchByIdZones,
        fetchZonesToAssign,
        fetchEmployeesToAssign,
        fetchCustomers,
        upadteSite,
        registerCustomer,
        deleteCustomer,
        updateCustomer,
        registerSite,
        AssignCablesRegister,
        fetchSites,
        deleteSite,
        registerItem,
        Fetchstocks,
        fetchChanelPackages,
        fetchEmpTitle,
        fetchAssignGroups,
        AssignGroupUpdate,
        EmpTitleUpdate,
        deleteTitle,
        fetchDepartment,
        fetchBranch,
        EmployeeRegister,
        fetchEmployees,
        fetchAdvance,
        advanceRegister,
        deleteEmployee,
        updateEmployee,
        fetchGroups,
        fetchTopTenLastLoginUsers,
        advanceUpdate,
        advanceDelete,
        DeductionRegister,
        deleteDeduction,
        DeductionUpdate,
        fetchDeductions,
        DepartmentRegister,
        DepartmentUpdate,
        deleteDepartment,
        TitleRegsiter,
        registerZones,
        GroupRegsiter,
        GroupUpdateAPI,
        fetchCustomerBalance,
        AssignGroupsRegister,
        loginUser,
        getCurrentUserId,
        getByIdCustomers,
        getByIdRecive,
        registernewCable,
        getByIdGroup,
        fetchReceiversType,
        fetchByIEmployee,
        registertickets,
        fetchTickets,
        ticketsUpdate,
        registerPurchaseOrder,
        fetchPurchaseOrder,
        fetchByIdZonesCable,
        deleteAssignGroup,
        fetchPendingTickets,
        CompleteTickets,
        deleteGroup,
        deleteBank,
        BankUpdate,
        fetchBank,
        BankRegister,
        fetchPayroll,
        registerPayroll,
        updatePayroll,
        fetchPayrollYearandMonth,
        fetchPayrollMain,
        fetchPendingPayroll,
        closeAnalogCard,
        fetchClosedCards,
        customersUpload,
        fetchDistricts,
        fetchAHorIPTVS,
        FetchOpenCardsReport,
        FetchAllOpenCardsReport,
        FetchSummaryCableReport,
        // confirmOTPP,
        FetchSummaryCloseReport,
        updateManageCable,
        fetchPurchaseReceiveReport,
        fetchPurchaseOrderReport,
        fetchCustomerListReport,
        fetchCustomerStatementReport,
        fetchTicketRegesterationReport,
        fetchTicketSupervisorReport,
        removeIDFromLocalStorage,
        removeFromLocalStorage,
        cleaState,
        fetchDeductionReport,
        fetchSupplierReport,
        fetchAdvanceReport,
        fetchPayrollSummaryReport,
        resetOTPnum,
        fetchEmployeeReport,
        transferTickets,

        getAllYears,
        fetchPayrollDetailsReport,
        fetchTicketTransferReport,
        fetchCustIPTVReport,
        fetchTicketSummaryReport,
        fetchticketsSupervisorsSummaryReport,
        fetchcustomerList,
        FetchExpireCards,
        fetchManualOpenCardRep,
        RegPendingTrans,
        fetchtodaysTickets,
        registerShits,
        fetchShifts,
        deleteShifts,
        updateShifts,
        getIptvReceiversOnly,
        fetchNasiyeReport,
        registernewIPTV,
        componentRef,
        HubiLine,
        openIptvs,
        closeIptv,
        FechIptvCustomerRechargeReport,
        FechOttCustomerRechargeReport,
        FetchExpiredIptvs,
        FetchExpiredOtt,
        fetchMacAddressAndSerialNumber,
        fetchCustomersWithIptvs,
        FetchsolvedReport,
        FetchsolvedCustomersReport,
        isUnreachable,
        noDevice,
        fetchAutoCustomers,
        transferIPTV,
        registerOrganizationIPTV,
        uploadIptvCustomers,
        fetchAutoIPTVCustomers,
        FetchsTheCustomersReport,
        fetchBothCustomers,
        Fetchcustomer424Report,
        fetchCorespondingEmp,
        SendTheSms,
        fetchCablesByDate,
        autoCharge,
        cleanTheReceiversAfterSave,
        sendSmsTocustomers,
        FetchCurrentExpireCards,
        fetchAllAnalogExpired,
        fetchAllAnalogThreeDaysExpired,
        sendSmsTo3daysRemaingcustomers,
        fetchIptvallExpiredCustomers,
        fetchIptva3DaysRemaingllExpiredCustomers,
        sendSmsToIPTVcustomers,
        sendSmsToiptv3daysRemaingcustomers,
        fetchSingleUserRoleReport,
        giveReportRoles,
        reset_report,
        fetchSingleRoleReport,
        addNewAdmin,
        FetchRevenuReport,
        enrollSingleEmployee,
        fetchDevices,
        registerDevice,
        updateDevices,
        deleteDevices,
        fetchAttendanceData,
        fetchAttendanceUpdates,
        fetchAvailableCardNumbers,
        getBothCustomersByType,
        sendSmsNotificantionCustomers,
        fetchTicketsByResolved,
        regsiterSmsMessages,
        fetchSmsMessages,
        deleteSmsMessages,
        updateSmsMessages,
        cancelCards,
        fetchSoldCards,
        transferReceivers,
        fetchCompletedTickets,
        ReopenTickets,
        completeTicket,
        fetchExpiredCustomerCards,
        sendSmsWithExpiredCustomers,
        fetchActiveNasiyeCustomers,
        FetchExpireNasiyeCustomers,
        uploadActiveNasiyeCustomers,
        uploadExpiredNasiyeCustomers,
        fetchActiveNasiyeIptvCustomers,
        resendSmsToCustomers,
        getDailyAttendance,
        fetchSpecificCustomers,
        getMonthlyAttendanceReport,
        fetchIptvOrganization,
        fetchEmployeeDepartments,
        registerLeaveCategory,
        fetchLeaveCategories,
        updateLeaveCategories,
        deleteLeaveCategory,
        fetchEmployeeLeaves,
        registerEmployeeLeaves,
        updateEmployeeLeave,
        deleteEmployeeLeave,
        registerNewRedlineCable,
        fetchRedlineCustomers,
        fetchRedlineOpenCards,
        getRedlineCustomerList,
        openRedlineCardRegister,
        closeRedlineCard,
        CancelAndActivateRedlineCard,
        fetchMultiShifts,
        registerMultiShift,
        deleteMultiShift,
        updateMultiShift,
        fetchEmployeesWithMultipleShifts,
        getRedlineByMacAddressOrSerial
      }}
    >
      {children}
    </LoginContex.Provider>
  );
};

export default AppProvider;
