import React, { useState, useEffect, useContext } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Grid, _ } from "gridjs-react";
import DataTable from "react-data-table-component";

const Promotion = () => {
  //local states
  const [itemName, setitemName] = useState();
  const [itemType, setItemType] = useState();
  const [desc, setDesc] = useState();
  const [Avaliable, setAvailbale] = useState();
  const [reOrder, setOrder] = useState();
  const [id, setId] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [yes, setYes] = useState("true");
  const [costPrice, setCostPrice] = useState("");
  const [sellingPrice, setsellingPrice] = useState("");
  const [discount, setdiscount] = useState("");
  const [PromotionPrice, setPromotionPrice] = useState("");
  const [hasPromotion, sethasPromotion] = useState();
  const {
    registerItem,
    readProducts,
    products,
    deleteProduct,
    updateProduct,
    promotion,
  } = useContext(LoginContex);

  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  //console.log(id);
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    readProducts();
  }, []);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = products.filter(
    (item) =>
      item.itemName &&
      item.itemName.toLowerCase().includes(filterText.toLowerCase())
  );
  //console.log(filteredItems);
  //console.log(filterText);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  document.title = "Item Promotion ";
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  document.title = "Setups";

  let yess = yes === "true";
  let haspro = hasPromotion == "Yes";
  // handle submit
  //console.log(hasPromotion);
  const handlerSubmit = (e) => {
    e.preventDefault();
    if (!isEditing) {
      const data = {
        reorderLevel: reOrder,
        initialQuantity: Avaliable,
        itemDescription: desc,
        itemType,
        itemName,
        costPrice,
        sellingPrice,
        discount,
        PromotionPrice,

        hasInitialQuantity: yess,
      };

      if (!itemName || !itemType) {
        return;
      }
      registerItem(data);
      setmodal_list(false);
      setsellingPrice("");
      setdiscount("");
      setPromotionPrice("");
      setCostPrice("");
      setYes("");
      setOrder("");
      setAvailbale("");
      setItemType("");
      setitemName("");
      //console.log(data);
    }

    if (isEditing) {
      const data = {
        hasPromotions: haspro,
        discount,
        id: id,
      };

      if (!discount || !hasPromotion) {
        showToastMessage("Please provide required values");
        return;
      }
      //console.log(data);
      promotion(data);
      setIsEditing(false);
      setmodal_list(false);
      setDesc("");
      setItemType("");
      setitemName("");
      setAvailbale("");
      setOrder("");
    }
  };

  ///delete
  const deletPop = (data) => {
    setmodal_delete(true);
    setId(data._id);
  };

  const deletProd = () => {
    deleteProduct(id);
    setmodal_delete(false);
  };

  const editPop = (data) => {
    //console.log(data);
    setmodal_list(true);
    setId(data._id);
    setdiscount("");

    setIsEditing(true);
  };

  const addModal = () => {
    tog_list();
    setIsEditing(false);
    setPromotionPrice("");
    setCostPrice("");
    setYes("");
    setOrder("");
    setAvailbale("");
    setItemType("");
    setitemName("");
    setYes("true");
    setsellingPrice("");
    setdiscount("");
  };

  const columns = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> Name</h6>,
      selector: (row) => row.itemName,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Type</h6>,
      selector: (row) => row.itemType,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Selling Price</h6>,
      selector: (row) => row.sellingPrice,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Has Promotion</h6>,
      selector: (row) => (row.hasPromotions ? "Yes" : "No"),
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Amount</h6>,
      selector: (row) => row.discount,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Actions</h6>,
      cell: (row) => (
        <>
          <i
            className="text-primary ri-pencil-fill fs-16 mx-3"
            onClick={() => editPop(row)}
          ></i>{" "}
          <i
            className="ri-delete-bin-5-fill fs-16 text-danger"
            onClick={() => deletPop(row)}
          ></i>
        </>
      ),

      // ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
    },
    {
      /*end*/
    },
  ];

  //data sorting into table....
  const sortedItems = [];
  for (let i = 0; i < products?.length; i++) {
    let singleArr = [
      products[i].itemName,
      products[i].itemType,
      products[i].sellingPrice,
      products[i].hasPromotions ? "Yes" : "No",
      products[i].discount,
      products[i]._id,
    ];

    sortedItems.push(singleArr);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Item Setups" pageTitle=" Settings" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h4 className="card-title mb-0">Add Item, Edit & Remove</h4> */}
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">Item Setups</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        {/* <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3"></Row>

                    {products.length > 0 && (
                      // <Grid
                      //   data={sortedItems}
                      //   columns={[
                      //     "Name",
                      //     "Type",
                      //     "Selling Price",
                      //     "Has Promotion",
                      //     "Amount",
                      //     {
                      //       name: "Actions",
                      //       width: "100px",
                      //       formatter: (cell, row) => {
                      //         return _(
                      //           <>
                      //             <UncontrolledDropdown className="dropdown d-inline-block">
                      //               <DropdownToggle
                      //                 className="btn btn-soft-secondary btn-sm"
                      //                 tag="button"
                      //               >
                      //                 <i className="ri-more-fill align-middle"></i>
                      //               </DropdownToggle>
                      //               <DropdownMenu className="dropdown-menu-end">
                      //                 <Row>
                      //                   <Col md={3}>
                      //                     <DropdownItem
                      //                       className="edit-item-btn"
                      //                       onClick={() => editPop(cell, row)}
                      //                     >
                      //                       <i className="ri-edit-2-line link-success fs-15"></i>
                      //                     </DropdownItem>
                      //                   </Col>
                      //                   <Col md={3}>
                      //                     <DropdownItem
                      //                       onClick={() => deletPop(cell)}
                      //                     >
                      //                       {" "}
                      //                       <i className="ri-delete-bin-line link-danger fs-15"></i>
                      //                     </DropdownItem>
                      //                   </Col>
                      //                 </Row>
                      //               </DropdownMenu>
                      //             </UncontrolledDropdown>
                      //           </>
                      //         );
                      //       },
                      //     },
                      //   ]}
                      //   search={true}
                      //   pagination={{ enabled: true, limit: 10 }}
                      // />
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        // selectableRows
                        persistTableHead
                        // sortFunction={customSort}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* item Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Add New Item</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <Row>
              <Col md={6}>
                <div className="mb-12">
                  <label htmlFor="itemType_id-field " className="form-label">
                    Has Promotion ?<span className="text-danger">*</span>
                  </label>
                  <select
                    id="itemType-field"
                    className="form-control"
                    required
                    onChange={(e) => sethasPromotion(e.target.value)}
                  >
                    <option value="">&hellip; Choose &hellip;</option>
                    <option value="Yes">Yes</option>
                    <option value="No">NO</option>
                  </select>
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-12">
                  <label htmlFor="itemType_id-field " className="form-label">
                    Discount <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="discount-field"
                    className="form-control"
                    placeholder="Enter Discount Price"
                    required
                    onChange={(e) => setdiscount(e.target.value)}
                    value={discount}
                  />
                </div>
              </Col>
            </Row>
            {/* <div className="mb-3 d-none">
              <label htmlFor="phone-field" className="form-label">
                Description:
              </label>
              <textarea
                className="form-control"
                id="Description-field"
                rows="1"
                onChange={(e) => setDesc(e.target.value)}
                value={desc}
              ></textarea>
            </div> */}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isEditing ? "Update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={deletProd}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Promotion;
