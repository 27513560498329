import React, { useState, useEffect, useContext, useRef } from "react";
import Select from "react-select";
import logo from "./LogoTabaarak.png";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const AddReciever = () => {
  const myRef = useRef(null);
  const [itemName, setItemName] = useState();
  const [truee, setTOtotrue] = useState(false);
  const [quuuantity, setquuuantity] = useState();
  const [tempTable, setTempTable] = useState([]);
  const [recTypes, setReceiverTypes] = useState();
  const [recNumber, setRecNumber] = useState();
  const [amou, setAmou] = useState();
  const [preview, setPreview] = useState(false);
  const [, difCardNumber, setDifCardNumber] = useState();
  const [clean1, setClean1] = useState(false);
  const [clean2, setClean2] = useState(false);
  const [inputList, setInputList] = useState([
    { itemId: "", quantity: "", unitPrice: "", amount: "" },
  ]);

  const [inputListPrint, setinputListPrint] = useState([
    { itemname: "", quantity: "", unitPrice: "", amount: "", TotalAmount: "" },
  ]);

  const [genRows, setGenRows] = useState([
    { itemId: "", receiverType: "", receiverId: "", cardNumber: "" },
  ]);

  const [salesType, setSalesType] = useState();
  const [salesReceiver, setSalesReceiver] = useState("Yes");
  const [quantity, setQuantity] = useState();
  const [temp, setTemp] = useState();
  const [salesDate, setSalesDate] = useState();
  const [customerId, setSupplierId] = useState();
  const [typeId, setTypeId] = useState();
  const [unitPrice, setUnitPrice] = useState();
  const [amount, setAmount] = useState();
  const [temp2, setTemp2] = useState([]);

  const [modal_list, setmodal_list] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [totalCards, setTotalCards] = useState("");

  const [itemSales, setItemSales] = useState("Yes");

  const [discount, setdiscount] = useState("");
  const [paidbalance, setpaidbalance] = useState("");
  const [checked, setChecked] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);
  const [_id, set_ID] = useState("");
  const [numberOfRows, setNumberOfRows] = useState("");
  const [rows, setRows] = useState([]);
  const {
    readProducts,
    products,
    singlePurchaseId,
    fetchPurchasedId,
    customers,
    fetchCustomers,
    registerCustomer,
    fetchSites,
    fetchAgents,
    sites,
    agents,
    itemsNotReceivers,
    FetchItemsNotReceivers,
    FetchItemsReceivers,
    itemsReceivers,
    cardAndSerials,
    FetchReciverCardNumberAndSerials,
    itemSalesRegisteration,
    itemGetAllReceiver,
    fetchReceiversType,
    reciveById,
    getByIdRecive,
    getByIdCardNumber,
    getCardNumbers,
    ItemsName,
    singleCustomer,
    getSingleCustomer,
    fetchCableInformation,
    cableInformation,
    itemsNotReceiversById,
    FetchItemsNotReceiversById,
    fetchItemOtherPlusAVA,
    fetchlastsp,
    fetchItemOtherSINGLEPlusAVA,
    fetchSinglelastsp,
    getReceiver,
    fetchReciever,
    resetItemSalesItems,
    resetItemSalesReceivers,
  } = useContext(LoginContex);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const [sortBy, setsortBy] = useState(null);
  const [selectMulti, setselectMulti] = useState(null);
  const [todaydata, setTodayDate] = useState("");
  const dataaa = { salesType, typeId };
  const the_data = { salesType, typeId, receiverType: recTypes?.value };

  useEffect(() => {
    setTOtotrue(false);
    // setSalesReceiver("Yes");
    // setItemSales("Yes");
    readProducts();
    fetchPurchasedId();
    fetchCustomers();
    fetchSites();
    fetchAgents();
    FetchItemsNotReceivers();
    FetchItemsReceivers();
    fetchReceiversType();
    if (salesType && typeId && recTypes?.value) {
      fetchReciever(the_data);
    }
    // if (recTypes?.value && truee) {
    //   fetchCableInformation(recTypes?.value);
    // }
    // FetchItemsNotReceiversById(itemName?.value);
    getByIdRecive(recTypes?.value);
    getByIdCardNumber(recNumber?.value);
    // FetchReciverCardNumberAndSerials();
    if (salesType && typeId && truee) {
      fetchItemOtherPlusAVA(dataaa);
    }

    if (itemName?.value) {
      fetchItemOtherSINGLEPlusAVA(itemName?.value);
    }
  }, [recTypes?.value, recNumber?.value, itemName?.value, salesType, typeId]);
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const Amount = quantity * unitPrice;
  //console.log(getReceiver);
  document.title = "Item Sales";

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;

  let getCustPhone = (id, mobile) => {
    setSupplierId(id);
    getSingleCustomer(id);
  };

  const totolReceiverAmount = temp2?.reduce((accu, curr) => {
    return accu + curr["amount"];
  }, 0);

  //console.log(temp2);
  const handleChange = () => {
    setChecked(!checked);
  };

  //console.log(itemsNotReceivers);
  //console.log(fetchlastsp);

  const purchaseAmount = inputList.reduce((accu, curr) => {
    return accu + Number(curr["amount"]);
  }, 0);

  const purchaseAmountReciever = tempTable?.reduce((accu, curr) => {
    return accu + Number(curr["amount"]);
  }, 0);

  let totalAmount;

  let paidBaln = paidbalance;

  const showToastMessageCheck = () => {
    toast.error(
      "Please Make sure that paid balance is less than or Equal to Grand Total !",
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );
  };

  if (paidBaln < 0) {
    showToastMessageCheck();
  } else {
    totalAmount =
      Number(purchaseAmount) +
      Number(purchaseAmountReciever) -
      Number(paidbalance) -
      Number(discount);
  }
  const ty5 = tempTable?.filter((t) => {
    return t.receiverNumber === recTypes?.value;
  });
  const addServiceTable = () => {
    if (ty5.length > 0) {
      showToastMessage("Duplicates are not allowed");
      return;
    }
    if (!getReceiver?.lastSerial?.[0]?.cardNumber) {
      showToastMessage("receiver details empty");
      return;
    }
    setPreview(true);
    const data = {
      receiverType: recTypes?.value,
      itemId: recTypes?.value,
      amount: getReceiver?.sellingPrice?.[0]?.sellingPrice,
      receiverCardNumber: getReceiver?.lastSerial?.[0]?.cardNumber,
      recTyp: recTypes?.label,
      recNumberLabel: getReceiver?.lastSerial?.[0]?.receiverSerialNumber,
      receiverNumber: getReceiver?.lastSerial?.[0]?.receiverSerialNumber,
    };
    //console.log(data);
    setTempTable([...tempTable, data]);

    setReceiverTypes("");

    resetItemSalesReceivers();
  };
  //console.log(tempTable);

  const delCableInfo = (index) => {
    const list = [...tempTable];
    list.splice(index, 1);
    setTempTable(list);
  };
  const ty2 = temp2?.filter((t) => {
    return t.itemValue === itemName?.value;
  });
  const addServiceTable2 = (e) => {
    if (ty2.length > 0) {
      showToastMessage("Duplicates are not allowed");
      return;
    }
    if (!quuuantity || !fetchSinglelastsp?.[0]?.sellingPrice) {
      showToastMessage("Please provide the item details");
      return;
    }

    if (quuuantity > itemsNotReceiversById?.[1]?.available) {
      showToastMessage("quantity can not be greater than the available items");
      return;
    }
    const data = {
      itemIdDD: itemName.value,
      itemId: fetchSinglelastsp?.[0]?._id,
      itemLabel: itemName.label,
      quantity: Number(quuuantity),
      availableQuantity: fetchSinglelastsp?.[0]?.available,

      unitPrice: fetchSinglelastsp?.[0]?.sellingPrice,
      amount: Number(quuuantity) * Number(fetchSinglelastsp?.[0]?.sellingPrice),
    };
    //console.log(data);
    setTemp2([...temp2, data]);
    setquuuantity("");
    resetItemSalesItems();
    setItemName("");
  };

  const delCableInfo2 = (index) => {
    const list = [...temp2];
    list.splice(index, 1);
    setTemp2(list);
  };

  const togglePreview = () => {
    setPreview(!preview);
  };

  const handlerSubmit = (e) => {
    e.preventDefault();

    if (!isUpdating) {
      // const data = { customerAddress, customerName, customerPhone, totalCards };

      // if (!customerAddress || !customerName || !customerPhone || !totalCards) {
      //   return;
      // }
      setmodal_list(false);
      ////console.log(data);
    }
  };

  //console.log(itemGetAllReceiver);

  const suppliersArr = [];

  for (let i = 0; i < itemGetAllReceiver.length; i++) {
    //console.log(itemGetAllReceiver[i]._id);

    var dropdownList = {
      value: itemGetAllReceiver[i]._id,
      label: ` ${itemGetAllReceiver[i].itemName}`,
    };

    suppliersArr.push(dropdownList);
  }

  const receiverNumbers = [];
  //console.log(reciveById);

  for (let i = 0; i < reciveById.length; i++) {
    //console.log(reciveById[i]._id);

    var dropdownList = {
      value: reciveById[i]._id,
      label: reciveById[i].receiverSerialNumber,
      amount: reciveById[i].amount,
    };

    receiverNumbers.push(dropdownList);
  }
  // handler submit customer
  const handlerSubmitCustomer = (e) => {
    e.preventDefault();
    if (!customerName || !customerPhone) {
      return;
    }
    const data = {
      customerAddress,
      customerPhone,
      customerName,
    };
    registerCustomer(data);
    setCustomerName("");
    setCustomerAddress("");
    setCustomerPhone("");
    setmodal_list(false);
  };

  //console.log(inputList);
  // handle click event of the Add button
  const handleAddClick = (index) => {
    if (!inputList[index].itemId) {
      showToastMessage("Please provide item name");
      return;
    }

    if (!inputList[index].unitPrice) {
      showToastMessage("Please provide item unit price");
      return;
    }
    if (!inputList[index].quantity) {
      showToastMessage("Please provide item quantity");
      return;
    }
    setInputList([
      ...inputList,
      { itemId: "", quantity: "", unitPrice: "", amount: "" },
    ]);
  };
  const handleAddClick2 = () => {
    setGenRows([
      ...genRows,
      { itemId: "", receiverType: "", receiverId: "", cardNumber: "" },
    ]);
  };

  const updateForm = (data) => {
    setmodal_list(true);
    setCustomerAddress(data.customerAddress);
    setCustomerName(data.customerName);
    setCustomerPhone(data.customerPhone);
    setTotalCards(data.totalCards);
    setIsUpdating(true);
    set_ID(data._id);
  };
  //console.log(genRows);

  const t = salesReceiver === "Yes";

  //console.log(inputList);
  //console.log(inputListPrint);

  const cancelCourse = () => {
    document.getElementById("FormControl").reset();
  };

  //console.log(customerId);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!salesDate) {
      showToastMessage("Please provide the sales date");
      return;
    }
    if (!salesType) {
      showToastMessage("Please provide the sales type");
      return;
    }
    if (!typeId) {
      showToastMessage("Please provide the sales type info");
      return;
    }
    if (itemSales == "Yes" && temp2.length < 1) {
      showToastMessage("Please provide the item details");
      return;
    }
    if (tempTable.length < 1) {
      showToastMessage("Please provide the receiverDetails");
      return;
    }

    if (checked == false) {
      const data = {
        customerId: customerId?.value,
        saleDate: salesDate,
        salesType,
        typeId,
        saleDetails: temp2,
        saleAmount: 50,
        totalAmount: 100,
        isSalesReceiver: t,
        discount: discount || 0,
        isItemSales: itemSales,
        receiverDetails: {
          quantity,
          amount: Amount,
          unitPrice,
          details: tempTable,
        },
      };
      //console.log(data);

      itemSalesRegisteration(data);

      //console.log(data);
      e.target.reset();
      setTempTable([]);
      setTemp2([]);
      cancelCourse();
      setpaidbalance("");
      setSupplierId("");
      setdiscount("");
      setSalesDate("");
    } else {
      const data = {
        customerId: customerId?.value,
        saleDate: salesDate,
        salesType,
        typeId,
        saleDetails: temp2,
        saleAmount: 50,
        totalAmount: 100,
        isSalesReceiver: t,
        isItemSales: itemSales,
        discount: discount || 0,
        receiverDetails: {
          quantity,
          amount: Amount,
          unitPrice,
          details: tempTable,
        },
      };
      //console.log(data);

      itemSalesRegisteration(data);

      //console.log(data);
      e.target.reset();
      setTempTable([]);
      setTemp2([]);
      cancelCourse();
      setpaidbalance("");
      setSupplierId("");
      setdiscount("");
      setSalesDate("");

      cancelCourse();

      setTimeout(() => printer(), 1000);
    }
  };
  //console.log(fetchlastsp);
  const sortedProducts = [];
  for (let i = 0; i < fetchlastsp?.length; i++) {
    // //console.log(fetchlastsp[i]._id);

    var dropdownList = {
      value: fetchlastsp?.[i]?._id,
      label: fetchlastsp?.[i]?.itemName,
      amount: fetchlastsp?.[i]?.sellingPrice,
    };

    sortedProducts.push(dropdownList);
  }

  const recAmount = tempTable.reduce((accum, curr) => {
    return accum + curr["amount"];
  }, 0);

  const grandTotal = recAmount + totolReceiverAmount;

  //console.log(recAmount);
  const printer = () => {
    var content = document.getElementById("divcontents");
    document.getElementById("mytable").style.marginLeft = "30%";
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
    $("#items").push(
      '<thead><tr><th style="width:120px;">Account No.</th> <th>Account Name</th> <th>Account ID</th><th>Description</th><th>Debit</th> <th>Credit</th> </tr> <thead>'
    );
    $("#meta").push(
      "<tr><td class='meta-head'>Cash</td> <td align='right'><div class='due'>$" +
        10 +
        "</div></td> </tr>"
    );
  };
  const CustomersArr = [];

  for (let i = 0; i < customers.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: customers[i]._id,
      label: ` ${customers[i].customerName}`,
    };

    CustomersArr.push(dropdownList);
  }

  //console.log(getCardNumbers);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Item Sales" pageTitle="Billing" />
          <Row>
            <form onSubmit={handleSubmit} id="FormControl">
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div>
                          <ToastContainer />
                          <div>
                            <h4 className="card-title mb-0">Add Item Sales</h4>
                          </div>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div>
                            {/* <Link to="/RegisterReciver" className="text-white">
                            <Button
                              color="success"
                              className="add-btn me-1"
                              id="create-btn"
                            >
                              <i className="ri-pencil-line align-bottom me-1"></i>{" "}
                              Manage Recivers
                            </Button>
                          </Link> */}
                            <span
                              className="form-check form-switch form-switch-lg mb-3"
                              style={{ float: "right" }}
                            >
                              <span style={{ marginTop: "7%", float: "right" }}>
                                Check to Print
                              </span>

                              <Input
                                style={{ marginTop: "9%" }}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="ISCash"
                                onChange={handleChange}
                                value={checked}
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <div id="Purchasestable">
                      <iframe
                        id="ifmcontentstoprint"
                        style={{
                          height: "0px",
                          width: "0px",
                          position: "absolute",
                        }}
                      ></iframe>
                      <Row className="g-4 mb-3"></Row>
                      <Row>
                        <Col md={3}>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Sales Date <span className="text-danger">*</span>
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            // onChange={(e) => setSalesDate(e.target.value)}
                            onChange={([date]) => setSalesDate(date)}
                            value={salesDate}
                          />
                        </Col>
                        <Col md={3}>
                          <div>
                            <Label className="form-label">
                              Customer <span className="text-danger">*</span>
                            </Label>
                            <div className="input-group">
                              <div style={{ width: "300px" }}>
                                <Select
                                  // className="bg-white"
                                  options={CustomersArr}
                                  onChange={(choice) => setSupplierId(choice)}
                                  // value={customerId}
                                  value={CustomersArr.filter(function (option) {
                                    return option.value === customerId?.value;
                                  })}
                                  required
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                    },
                                  })}
                                />
                              </div>
                              <Button
                                size="sm"
                                color="success"
                                // className="add-btn me-1"
                                onClick={() => tog_list()}
                                id="create-btn"
                                className="mt-4d"
                                // style={{ marginTop: "2rem" }}
                              >
                                <i className="ri-add-line align-bottom me-1"></i>{" "}
                              </Button>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <Label className="form-label">
                              Sales Type <span className="text-danger">*</span>
                            </Label>

                            <select
                              className="form-select "
                              id="itemtype"
                              onChange={(e) => {
                                setSalesType(e.target.value);

                                setTempTable([]);
                                setTemp2([]);
                              }}
                            >
                              <option value="">
                                &hellip; Choose type &hellip;
                              </option>
                              <option value="Site">Site</option>
                              <option value="Agent">Agent</option>
                            </select>
                          </div>
                        </Col>
                        {salesType && (
                          <Col md={3}>
                            <div>
                              <Label className="form-label">
                                {salesType}{" "}
                                <span className="text-danger">*</span>
                              </Label>

                              <select
                                className="form-select "
                                id="itemtype"
                                onChange={(e) => {
                                  setTypeId(e.target.value);
                                  setTOtotrue(true);
                                }}
                              >
                                <option value="">
                                  &hellip; Choose Item &hellip;
                                </option>
                                {salesType === "Site" &&
                                  sites?.map((site, index) => {
                                    return (
                                      <option key={index} value={site._id}>
                                        {" "}
                                        {site.siteName}{" "}
                                      </option>
                                    );
                                  })}
                                {salesType === "Agent" &&
                                  agents?.map((agent, index) => {
                                    return (
                                      <option key={index} value={agent._id}>
                                        {" "}
                                        {agent.salesAgentName}{" "}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row className="mb-4 mt-5">
                        <Col md={3}>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            item sales? <span className="text-danger">*</span>
                          </Label>

                          <select
                            className="form-select "
                            id="itemtype"
                            onChange={(e) => setItemSales(e.target.value)}
                          >
                            <option value="">
                              &hellip; Choose sales receiver &hellip;
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </Col>
                      </Row>

                      {itemSales === "Yes" && (
                        <div className=" table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap"
                            id="purchase"
                          >
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col" style={{ width: "50px" }}></th> */}
                                <th className="sort" data-sort="item">
                                  Item Name
                                </th>

                                <th className="sort" data-sort="phone">
                                  Quantity Avaliable
                                </th>
                                <th className="sort" data-sort="phone">
                                  Quantity
                                </th>
                                <th className="sort" data-sort="Date">
                                  Rate
                                </th>
                                <th className="sort" data-sort="amount">
                                  Amount
                                </th>

                                <th className="sort" data-sort="action">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              <tr className="">
                                <td className="item">
                                  <div
                                    className="mb-3"
                                    // style={{ width: "200px" }}
                                  >
                                    <Select
                                      // className="bg-white"
                                      options={sortedProducts}
                                      onChange={(choice) => {
                                        setItemName(choice);
                                        setClean1(false);
                                      }}
                                      value={sortedProducts.filter(function (
                                        option
                                      ) {
                                        return option.value === itemName?.value;
                                      })}
                                      required
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                          ...theme.colors,
                                        },
                                      })}
                                    />
                                  </div>
                                </td>

                                <td className="qty">
                                  <div className="mb-3">
                                    <input
                                      type="number"
                                      name="quantity available"
                                      className="form-control"
                                      placeholder="quantity available"
                                      disabled
                                      // onChange={(e) =>
                                      //   handleServiceChange(e, index)
                                      // }
                                      value={
                                        fetchSinglelastsp?.[0]?.available || 0
                                      }
                                      id="akram-1"
                                      ref={myRef}
                                    />
                                  </div>
                                </td>

                                <td className="qty">
                                  <div className="mb-3">
                                    <input
                                      type="number"
                                      name="quantity"
                                      className="form-control"
                                      placeholder="quantity number"
                                      onChange={(e) =>
                                        setquuuantity(e.target.value)
                                      }
                                      value={quuuantity}
                                    />
                                  </div>
                                </td>
                                <td className="qty">
                                  <div className="mb-3">
                                    <input
                                      type="number"
                                      name="rate"
                                      className="form-control"
                                      placeholder="rate"
                                      disabled
                                      // onChange={(e) =>
                                      //   handleServiceChange(e, index)
                                      // }
                                      value={
                                        fetchSinglelastsp?.[0]?.sellingPrice ||
                                        0
                                      }
                                      id="akram-2"
                                    />
                                  </div>
                                </td>

                                <td className="qty">
                                  <div className="mb-3">
                                    <Input
                                      name="amount"
                                      disabled
                                      type="number"
                                      className="form-control"
                                      placeholder="amount"
                                      // onChange={(e) => setAmou(e.target.value)}
                                      value={
                                        quuuantity *
                                        fetchSinglelastsp?.[0]?.sellingPrice
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-success mb-3 mx-3"
                                    onClick={(e) => addServiceTable2(e)}
                                  >
                                    <i className="fa fa-plus"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>

                            {temp2?.length > 0 && (
                              <tbody className="list form-check-all">
                                {temp2?.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="Store_name">
                                        {" "}
                                        {data.itemLabel}{" "}
                                      </td>
                                      <td className="location">
                                        {" "}
                                        {data.availableQuantity}{" "}
                                      </td>
                                      <td className="location">
                                        {" "}
                                        {data.quantity}{" "}
                                      </td>
                                      <td className="description">
                                        {" "}
                                        {data.unitPrice}{" "}
                                      </td>
                                      <td className="description">
                                        {" "}
                                        {data.amount}{" "}
                                      </td>

                                      <td>
                                        <div className="d-flex gap-2">
                                          <div className="remove">
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-danger remove-item-btn"
                                              data-bs-toggle="modal"
                                              data-bs-target="#deleteRecordModal"
                                              onClick={() =>
                                                delCableInfo2(index)
                                              }
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            )}
                          </table>
                        </div>
                      )}

                      <Row className="g-4 mb-5 "></Row>
                      <Row>
                        <Col md={3}>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Sales Receiver?{" "}
                            <span className="text-danger">*</span>
                          </Label>

                          <select
                            className="form-select "
                            id="itemtype"
                            onChange={(e) => setSalesReceiver(e.target.value)}
                          >
                            <option value="">
                              &hellip; Choose sales receiver &hellip;
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </Col>
                        {itemSales === "Yes" && (
                          <Col md={3}>
                            <div>
                              <Label className="form-label">
                                Amount <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="disabledInput"
                                name="amount"
                                required
                                defaultValue={totolReceiverAmount}
                                value={totolReceiverAmount}
                                disabled
                              />
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row className="mb-5"></Row>
                      {salesReceiver === "Yes" && (
                        <table
                          className="table align-middle table-nowrap"
                          id="purchase"
                        >
                          <thead className="table-light">
                            <tr>
                              {/* <th scope="col" style={{ width: "50px" }}></th> */}
                              <th className="sort" data-sort="item">
                                Receiver Type
                              </th>
                              <th className="sort" data-sort="qty">
                                Receiver Number
                              </th>
                              <th className="sort" data-sort="phone">
                                Card Number
                              </th>
                              <th className="sort" data-sort="Date">
                                Amount
                              </th>

                              <th className="sort" data-sort="action">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {/* {serviceList.map((service, index) => {
                              return ( */}
                            <tr className="">
                              <td className="item">
                                <div className="mb-3">
                                  <Select
                                    // className="bg-white"
                                    options={suppliersArr}
                                    onChange={(choice) => {
                                      setReceiverTypes(choice);
                                      if (!salesType || !typeId) {
                                        showToastMessage(
                                          "please first select the sales type and the type"
                                        );
                                      }
                                    }}
                                    able
                                    value={suppliersArr.filter(function (
                                      option
                                    ) {
                                      return option.value === recTypes?.value;
                                    })}
                                    required
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        // text: "orangered",
                                        // primary25: "hotpink",
                                        // primary: "black",
                                      },
                                    })}
                                  />
                                </div>
                              </td>
                              <td className="qty">
                                <div className="mb-3">
                                  <Input
                                    name="reciver number"
                                    type="number"
                                    className="form-control"
                                    placeholder="receiver number"
                                    value={
                                      getReceiver?.lastSerial?.[0]
                                        ?.receiverSerialNumber || 0
                                    }
                                    required
                                    disabled
                                  />
                                </div>
                              </td>
                              <td className="qty">
                                <div className="mb-3">
                                  <input
                                    type="text"
                                    name="cardNumber"
                                    className="form-control"
                                    placeholder="Card No"
                                    disabled
                                    value={
                                      getReceiver?.lastSerial?.[0]
                                        ?.cardNumber || 0
                                    }
                                  />
                                </div>
                              </td>

                              <td className="qty">
                                <div className="mb-3">
                                  <Input
                                    name="amount"
                                    type="number"
                                    className="form-control"
                                    value={
                                      getReceiver?.sellingPrice?.[0]
                                        ?.sellingPrice || 0
                                    }
                                    required
                                    disabled
                                  />
                                </div>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-success mb-3 mx-3"
                                  onClick={() => addServiceTable()}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>

                          <tbody className="list form-check-all">
                            {tempTable.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td className="Store_name">
                                    {" "}
                                    {data.recTyp}{" "}
                                  </td>
                                  <td className="location">
                                    {" "}
                                    {data.recNumberLabel}{" "}
                                  </td>
                                  <td className="description">
                                    {" "}
                                    {data.receiverCardNumber}{" "}
                                  </td>
                                  <td className="description">
                                    {" "}
                                    {data.amount}{" "}
                                  </td>

                                  <td>
                                    <div className="d-flex gap-2">
                                      <div className="remove">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger remove-item-btn"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteRecordModal"
                                          onClick={() => delCableInfo(index)}
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                      <card>
                        <CardHeader
                          style={{
                            color: "grey",
                            fontSize: "1rem",
                            marginTop: "3%",
                          }}
                        >
                          Reciept Details
                        </CardHeader>

                        <CardBody>
                          <Row className="mb-4">
                            <Col md={3}>
                              <Label
                                htmlFor="PurchasesDate"
                                className="form-label"
                              >
                                Grand Total
                              </Label>
                              <input
                                type="number"
                                id="disabledInput"
                                className="form-control"
                                placeholder=""
                                required
                                disabled
                                defaultValue={0}
                                // onChange={(e) => handleServiceChangeBalance(e)}
                                value={grandTotal}
                              />
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Discount{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <input
                                  type="number"
                                  id="referenceno-field"
                                  className="form-control"
                                  placeholder="Enter Discount"
                                  name="disc"
                                  onChange={(e) => setdiscount(e.target.value)}
                                  value={discount}
                                  // onChange={(e) => handleServiceChangeBalance(e)}

                                  // onChange={(e) =>
                                  //   handleServiceChangeBalance(e)
                                  // }
                                />
                              </div>
                            </Col>

                            <Col md={3}>
                              <label className="form-label">Paid Balance</label>{" "}
                              <span className="text-danger">*</span>
                              <input
                                type="number"
                                id="referenceno-field"
                                className="form-control"
                                placeholder="Enter Paid Balance"
                                name="balancepaid"
                                onChange={(e) => setpaidbalance(e.target.value)}
                                value={paidbalance}

                                // onChange={(e) =>
                                //   handleServiceChangeBalance(e)
                                // }
                              />
                            </Col>
                            <Col md={3}>
                              <Label
                                htmlFor="PurchasesDate"
                                className="form-label"
                              >
                                Balance
                              </Label>
                              <input
                                type="number"
                                id="disabledInput"
                                className="form-control"
                                placeholder=""
                                required
                                disabled
                                value={
                                  grandTotal -
                                  Number(discount) -
                                  Number(paidbalance)
                                }
                                // onChange={(e) => setCustPhone(e.target.value)}
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </card>
                      <Row className="mt-3"></Row>
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-success"
                          id="add-btn"
                        >
                          Save All
                        </button>
                      </div>
                      <Row className="mt-3"></Row>
                      <div className="d-flex justify-content-end">
                        <div className="pagination-wrap hstack gap-2">
                          <Link
                            className="page-item pagination-prev disabled"
                            to="#"
                          >
                            Previous
                          </Link>
                          <ul className="pagination listjs-pagination mb-0"></ul>
                          <Link className="page-item pagination-next" to="#">
                            Next
                          </Link>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </form>
          </Row>

          {/* end of container */}
        </Container>

        {/* Start of Table for printer */}
        <div id="divcontents" style={{ marginLeft: "60px", display: "none" }}>
          <img
            src={logo}
            alt="Logo"
            style={{ marginLeft: "35%", size: "40px" }}
          />

          <div className="maincontainer">
            <div
              className="CustomerInfo"
              style={{ display: "inline-block", float: "right" }}
            >
              <div
                className="Custinfo2"
                style={{ display: "inline-block", float: "left" }}
              >
                <div style={{ float: "right" }}>
                  <label className="salesDate">Sales Date : </label>
                  <span style={{ marginLeft: "15px" }}>
                    {""}
                    {today}
                  </span>
                </div>
                <br />
                <div style={{ float: "right" }}>
                  <label className="Customer">Customer : </label>
                  <span style={{ marginLeft: "15px" }}>
                    {""}
                    {singleCustomer.customerName}
                  </span>
                </div>
                <br />

                <div style={{ float: "right" }}>
                  <label className="Customer">Phone : </label>
                  <span style={{ marginLeft: "15px" }}>
                    {""}
                    {singleCustomer.customerPhone}
                  </span>
                </div>
                <br />

                <div style={{ float: "right" }}>
                  <label className="Customer">Address : </label>
                  <span style={{ marginLeft: "15px" }}>
                    {""}
                    {singleCustomer.customerAddress}
                  </span>
                </div>
              </div>
            </div>
            <br />
            <br />

            <div className="itemstable" style={{ marginTop: "50px" }}>
              <table
                id="items"
                className="table table-striped table-bordered table-hover"
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  fontSize: "15px",
                  position: "relative",
                }}
              >
                <thead className="list form-check-all">
                  <tr style={{ marginRight: "-100px" }}>
                    <th></th>
                    <th>Item Name</th>
                    <th>Quantity</th>
                    <th>Price</th>

                    <th></th>
                    <Col md={8} style={{ marginRight: "5%" }}></Col>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="list form-check-all">
                  {inputList?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td></td>
                        <td className="Customer">{ItemsName?.itemName}</td>
                        <td className="Customer">{data?.quantity}</td>
                        <td className="Customer">{data?.unitPrice}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Col md={8} style={{ marginTop: "10%" }}></Col>
              <Col md={2}>
                <div className="mb-3 pull-right">
                  <label
                    className="form-label"
                    style={{
                      marginLeft: "70%",
                      marginTop: "10%",
                      fontWeight: "bold",
                    }}
                  >
                    Total Items Amount
                  </label>

                  <input
                    type="number"
                    className="form-control"
                    id="disabledInput"
                    defaultValue={purchaseAmount}
                    value={purchaseAmount}
                    disabled
                    style={{
                      border: "none",
                      fontWeight: "bold",
                      size: "50px",
                      marginLeft: "80%",
                    }}
                  />
                </div>
              </Col>
            </div>

            <div className="recievertable" style={{ marginTop: "50px" }}>
              <table
                id="recievers"
                className="table table-striped table-bordered table-hover"
                style={{ width: "100%", maxWidth: "100%", fontSize: "15px" }}
              >
                <thead className="list form-check-all">
                  <tr>
                    {/* <th scope="col" style={{ width: "50px" }}></th> */}
                    <th className="sort" data-sort="item">
                      Receiver Type
                    </th>
                    <th className="sort" data-sort="qty">
                      Receiver Number
                    </th>
                    <th className="sort" data-sort="phone">
                      Card Number
                    </th>
                  </tr>
                </thead>
                <tbody className="list form-check-all">
                  {tempTable.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td className="Customer" id="mytable">
                          {data.recTyp}
                        </td>
                        <td className="Customer">{data.recNumberLabel}</td>
                        <td className="Customer">{data.receiverCardNumber}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Col md={8} style={{ marginTop: "10%" }}></Col>
              <Col md={2}>
                <div className="mb-3 pull-right">
                  <label
                    className="form-label"
                    style={{
                      marginLeft: "70%",
                      marginTop: "10%",
                      fontWeight: "bold",
                    }}
                  >
                    Total Receiver Amount
                  </label>

                  <input
                    type="number"
                    className="form-control"
                    id="disabledInput"
                    defaultValue={purchaseAmountReciever}
                    value={purchaseAmountReciever}
                    disabled
                    style={{
                      border: "none",
                      fontWeight: "bold",
                      size: "50px",
                      marginLeft: "80%",
                    }}
                  />
                </div>
              </Col>
            </div>

            <div className="grandcalculations">
              <h2 style={{ color: "lightgray" }}>Reciept Details</h2>
              <Col md={3}>
                <div className="mb-3">
                  <label style={{ fontWeight: "bold" }}>Grand Total : </label>
                  <input
                    type="number"
                    className="form-control"
                    id="disabledInput"
                    // defaultValue={receiverTotalAmount}
                    value={
                      Number(purchaseAmount) + Number(purchaseAmountReciever)
                    }
                    disabled
                    style={{ border: "none", fontWeight: "bold", size: "50px" }}
                  />
                </div>
              </Col>

              <Col md={3}>
                <div className="mb-3">
                  <Label className="form-label">Discount:</Label>
                  <input
                    type="number"
                    id="referenceno-field"
                    className="form-control"
                    required
                    name="disc"
                    disabled
                    style={{ border: "none", fontWeight: "bold", size: "50px" }}
                    value={discount}

                    // onChange={(e) =>
                    //   handleServiceChangeBalance(e)
                    // }
                  />
                </div>
              </Col>

              <Col md={3}>
                <div className="mb-3">
                  <Label className="form-label" style={{ fontWeight: "bold" }}>
                    Paid Balance:
                  </Label>
                  <input
                    type="number"
                    id="referenceno-field"
                    className="form-control"
                    required
                    name="disc"
                    disabled
                    style={{ border: "none", fontWeight: "bold", size: "50px" }}
                    value={paidbalance}

                    // onChange={(e) =>
                    //   handleServiceChangeBalance(e)
                    // }
                  />
                </div>
              </Col>

              <Col md={3}>
                <div className="mb-3">
                  <Label className="form-label">Balance Remain:</Label>
                  <input
                    type="number"
                    id="referenceno-field"
                    className="form-control"
                    required
                    name="disc"
                    disabled
                    style={{ border: "none", fontWeight: "bold", size: "50px" }}
                    value={totalAmount}

                    // onChange={(e) =>
                    //   handleServiceChangeBalance(e)
                    // }
                  />
                </div>
              </Col>
            </div>

            <div style={{ marginTop: "20%" }}>
              <span>
                <Label className="form-label" style={{ marginLeft: "23%" }}>
                  Recieved By: ________________________
                </Label>
              </span>

              {/* <span>
              <Label className="form-label" style={{ marginLeft: "4%" }}>
                Sold to: ________________________
              </Label>
            </span> */}
            </div>
          </div>
        </div>

        {/* End of Table for printer */}
      </div>

      {/* Add Modal */}
      {/* Inventory From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Add New Customer</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={handlerSubmitCustomer}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>

            <div className="mb-3">
              <label htmlFor="customer_name-field" className="form-label">
                Customer Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="customer_name-field"
                className="form-control"
                placeholder="Enter Customer Name"
                required
                onChange={(e) => setCustomerName(e.target.value)}
                value={customerName}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone-field" className="form-label">
                Phone Number <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                id="phone-field"
                className="form-control"
                placeholder="Enter phone Number"
                required
                onChange={(e) => setCustomerPhone(e.target.value)}
                value={customerPhone}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="address-field" className="form-label">
                Address
              </label>
              <input
                type="text"
                id="address-field"
                className="form-control"
                placeholder="Enter Address"
                onChange={(e) => setCustomerAddress(e.target.value)}
                value={customerAddress}
              />
            </div>

            {/* <div className="mb-3">
              <label htmlFor="total-field" className="form-label">
                Total Card
              </label>
              <input
                type="Number"
                id="total-field"
                className="form-control "
                placeholder="Enter Total Card"
                required
                onChange={(e) => setTotalCards(e.target.value)}
                value={totalCards}
              />
            </div> */}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isUpdating ? "Update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddReciever;
