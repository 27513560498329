import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import {
  AiOutlineCheckCircle,
  AiOutlineBook,
  AiOutlineTransaction,
  AiOutlineFullscreen,
  AiOutlineRetweet,
  AiOutlineAliyun,
  AiOutlineSlack,
  AiOutlineColumnWidth,
  AiOutlineStop,
  AiTwotoneApi,
} from "react-icons/ai";

import Select from "react-select";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";

//Import Flatepicker
import Flatpickr from "react-flatpickr";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";

const CompleteTicket = () => {
  
  const [isEditing, setEditing] = useState(false);
  const [siteId, setSiteId] = useState("");
  const [yes, setYes] = useState(true);
  const [id, setId] = useState("");
  // const [no, setNo] = useState(false);
  const [selectOb, setSelectedOb] = useState("");

  const [supplierId, setsupplierId] = useState("");
  const [amount, setamount] = useState(5);
  const [reference, setreference] = useState("");
  const [date, setDate] = useState("");
  const [Message, setMessage] = useState("");
  const [ticketId, setticketId] = useState("");
  const [EmpName, setEmpName] = useState("");
  const [Ephone, setEphone] = useState("");
  const [CustName, setCustName] = useState("");
  const [CustPhone, setCustPhone] = useState("");

  const [EmployeeId, setEmployeeId] = useState();
  // const [siteId, setSiteId] = useState();
  const [groupid, setgroupid] = useState();
  const [zoneid, setzoneid] = useState();
  // const [Message, setMessage] = useState();

  const [empPhone, setempPhone] = useState("");
  const [title, setTitle] = useState();
  const [custPhone, setcustPhone] = useState("");
  const [customerId, setcustomerId] = useState("");
  const [customerAddress, setcustomerAddress] = useState();
  const [issueDate, setissueDate] = useState();
  const [EmployeeInfo, setEmployeeInfo] = useState();
  const [employeeIdd, setEmployeeIdd] = useState();
  const [transferInfo, settransferInfo] = useState();
  const [transferPhone, settransferPhone] = useState();
  const [transferId, settransferId] = useState();
  const [transferContent, settransferContent] = useState();

  const {
    fetchItems,
    items,
    registerPayment,
    fetchPayments,
    sites,
    EmployeesFromZone,
    deletePayment,
    updatePayment,
    fetchCustomerBalance,
    paymentAmount,
    fetchTickets,
    fetchPendingTickets,
    GetTickets,
    GetPendingTickets,
    CompleteTickets,
    getSingleEmployee,
    singleEmployee,
    getSingleCustomer,
    singleCustomer,
    fetchGroupsToAssign,
    fetchZonesToAssign,
    fetchEmployeesToAssign,
    getByIdGroupsCable,
    getByIdZonesCables,
    getGroups,
    getByIdGroupCable,
    fetchByIdZonesCable,
    fetchSites,
    fetchGroups,
    fetchEmployees,
    AssignCablesRegister,
    ticketsUpdate,
    getSupervisorsSpecials,
    specialSupervisors,
    registertickets,
    User,
    getEmployee,
    transferTickets,
    RegPendingTrans,
    isUnreachable,
    noDevice,
    fetchCompletedTickets, completedTickets,
    ReopenTickets,
    completeTicket
  } = useContext(LoginContex);
  const [modal_list, setmodal_list] = useState(false);
  const [modal_list1, setmodal_list1] = useState(false);
  const [modal_list3, setmodal_list3] = useState(false);
  const [modal_list_update, setmodal_list_update] = useState(false);
  const [modal_listEmp, setmodal_listEmp] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const tog_list1 = () => {
    setmodal_list1(!modal_list1);
  };
  const tog_list3 = () => {
    setmodal_list3(!modal_list3);
  };
  const tog_list_update = () => {
    setmodal_list_update(!modal_list_update);
  };

  const tog_listEmp = () => {
    setEmployeeInfo("");
    setmodal_listEmp(!modal_listEmp);
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const [modal_delete2, setmodal_delete2] = useState(false);
  const [modal_delete3, setmodal_delete3] = useState(false);
  const [confirmInfo, setconfirmInfo] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const tog_delete2 = () => {
    setmodal_delete2(!modal_delete);
  };
  const tog_delete3 = () => {
    setmodal_delete3(!modal_delete);
  };
  //console.log(yes);
  useEffect(() => {
    // fetchCustomerBalance(supplierId);
    fetchTickets();
    fetchPendingTickets();
    fetchCompletedTickets();
    fetchSites();
    fetchEmployees();
    
    getSupervisorsSpecials();
    fetchSites();
    fetchEmployeesToAssign();
    // fetchEmployeesToAssign();
  }, []);

  //console.log(paymentAmount?.supply_blance);

  //console.log(specialSupervisors);
  document.title = "Ticket ";
  const mockData = [
    {
      Ticket_Id: "1",
      Date: "23/10/2022",
      Customer_Name: "Axmed Xuseen Xanshi",
      Ticket_Title: "ABCD",
      Site_Sent_To: "Bakaaro Site",
      IS_Resolved: "No",
    },
  ];
  let userId = User?.data?.user?._id || User?.user?._id;
  let userIdd = User?.user?._id;
  // //console.log("user iddddddddddddddddd", userId);
  //console.log("user iddddddddddddddddd", userId);
  let getGroup = (id) => {
    // //console.log(id)
    setSiteId(id);
    getByIdGroupCable(id);
    setgroupid("");
    setzoneid("");
    setEmployeeId("");
  };

  let getZones = (id) => {
    setgroupid(id);
    fetchByIdZonesCable(id);
    // //console.log(getZoneById.result)
    //console.log(id);
    setzoneid("");
    setEmployeeId("");
  };

  // const getEmployeeFromZone = (id) => {
  //   setzoneid(id);
  //   const data = {
  //     zoneId: id,
  //   };
  //   fetchEmployeesToAssign(data);
  //   //console.log(data);
  // };

  const getEmployeeFromZone = (e) => {
    setzoneid(e.value);
    const data = {
      zoneId: e.value,
    };
    fetchEmployeesToAssign(data);
    //console.log(data);
  };
  // reopen ticket
  const reopenTicket = (mydata) => {
    setmodal_list1(true);
    // //console.log(mydata);
    setticketId(mydata._id);
    setEditing(true);
    //  getSingleEmployee();
    setEmpName(mydata.empId.empName);
    setEphone(mydata.empId.mobile);
    setCustName(mydata.customerId.customerName);
    setCustPhone(mydata.customerId.customerPhone);
    setcustomerAddress(mydata.customerId.customerAddress);
    //  //console.log(mydata.customerId);
  };
  const CompletedTicketFunc = (mydata) => {
    setmodal_list3(true);
    //console.log(mydata);
    setticketId(mydata._id);
    setEditing(true);
    
    //  //console.log(mydata.customerId);
  };
  // complete ticket
  const updateForm = (mydata) => {
    setmodal_list(true);
    //console.log(mydata);
    setticketId(mydata._id);
    setEditing(true);
    // getSingleEmployee();
    setEmpName(mydata.empId.empName);
    setEphone(mydata.empId.mobile);
    setCustName(mydata.customerId.customerName);
    setCustPhone(mydata.customerId.customerPhone);
    //  //console.log(mydata.customerId);
  };
  // transfer ticket
  const transferTicket = (mydata) => {
    setmodal_list_update(true);
    //console.log(mydata);
    settransferInfo(mydata);
    settransferPhone(mydata?.empId?.mobile);
    settransferId(mydata?._id);
    //desc = `${EmployeeInfo?.EmployeeName} : ${Message} -> Waxaa u Adeegtaa Macaaamilka ah '${selectOb.customerName}' : Wata telephone number '${selectOb.customerPhone}' : Degaanka ${selectOb.customerAddress}`;
    settransferContent(
      ` -> Waxaa u Adeegtaa Macaaamilka ah ${mydata?.customerId?.customerName} Wata telephone number ${mydata?.customerId?.customerPhone}  Degaanka ${mydata?.customerId?.customerAddress} `
    );
  };

  const [filterText, setFilterText] = React.useState("");
  const [filterText2, setFilterText2] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems =
    GetPendingTickets?.length > 0 &&
    GetPendingTickets?.filter(
      (item) =>
        (item.customerId?.customerName &&
          item.customerId?.customerName
            .toLowerCase()
            .includes(filterText.toLowerCase()) &&
          item.customerId?.customerName) ||
        item.customerId?.customerPhone
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase())
    );

  const filteredItems3 =
    completedTickets?.length > 0 &&
    completedTickets?.filter(
      (item) =>
        (item.customerId?.customerName &&
          item.customerId?.customerName
            .toLowerCase()
            .includes(filterText.toLowerCase()) &&
          item.customerId?.customerName) ||
        item.customerId?.customerPhone
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase())
    );
  //console.log(filteredItems);
  //console.log(filterText);

  //console.log(GetPendingTickets);
  //console.log(specialSupervisors);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);
  const filteredItems2 = specialSupervisors?.filter(
    (item) =>
      (item.customerName &&
        item.customerName.toLowerCase().includes(filterText.toLowerCase()) &&
        item.customerName) ||
      item.customerPhone
        .toString()
        .toLowerCase()
        .includes(filterText.toLowerCase())
  );
  //console.log(filteredItems2);
  //console.log(filterText);
  const subHeaderComponentMemo2 = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText2("");
      }
    };

    return (
      <div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText2}
          placeholder="search here"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };


    
  const columns3 = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> Ticket ID</h6>,
      selector: (row) => row.ticketId,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Date</h6>,
      selector: (row) => moment(row.createdAt).utc(true).format("YYYY-MM-DD HH:mm")
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Customer</h6>,
      selector: (row) => row.customerId?.customerName,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> phone</h6>,
      selector: (row) => row.customerId?.customerPhone,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Ticket Title</h6>,
      selector: (row) => row.title,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Employee</h6>,
      selector: (row) => row.empId?.empName,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> phone</h6>,
      selector: (row) => row.empId?.mobile,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>is Resolved</h6>,
      selector: (row) => (row.isResolved ? "Yes" : "No"),
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>on Hold</h6>,
      selector: (row) => (row.onHold == true ? "Yes" : "No"),
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Actions</h6>,
      cell: (row) => (
        <>
       
         
        
          <button
            className="btn btn-sm btn-success mx-1"
            onClick={() => CompletedTicketFunc(row)}
            title="Complete Ticket"
          >
            {" "}
            <AiOutlineCheckCircle size={15} />
          </button>{" "}
          <button
            className="btn btn-sm btn-info mx-1"
            onClick={() => reopenTicket(row)}
            title="Re-open Ticket"
          >
            {" "}
            <AiOutlineRetweet size={15} />
          </button>
  
         
        </>
      ),
    },
  ];
  const columns2 = [
    {
      name: <h6 style={{ fontWeight: "bold" }}> Ticket ID</h6>,
      selector: (row) => row.ticketId,
    },

    {
      name: <h6 style={{ fontWeight: "bold" }}> Date</h6>,
      selector: (row) => moment(row.createdAt).utc(true).format("YYYY-MM-DD HH:mm")
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Customer</h6>,
      selector: (row) => row?.customerName,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> phone</h6>,
      selector: (row) => row?.customerPhone,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}>Ticket Title</h6>,
      selector: (row) => row.title,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Supervisor</h6>,
      selector: (row) => row.empName,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Supervisor</h6>,
      selector: (row) => row.phone,
    },
    {
      name: <h6 style={{ fontWeight: "bold" }}> Actions</h6>,
      cell: (row) => (
        <>
          <button
            className="btn btn-sm btn-primary  mx-3"
            onClick={() => Assignpop(row)}
            title="Assign"
          >
            {" "}
            <MdOutlineAssignmentTurnedIn size={15} />
          </button>
        </>
      ),
    },
    {
      /*end*/
    },
  ];

  // //console.log("emp" , EmpName);
  // //console.log("phone" , Ephone);
  // //console.log("cust" , CustName);
  // //console.log("phone" , CustPhone);

  const Assignpop = (data) => {
    //console.log("data", data);
    // //console.log(row);
    ////console.log(row._cells[6].data);
    tog_listEmp();
    setcustomerAddress(data.customerId.customerAddress);
    setcustomerId(data.customerId);
    getSingleCustomer(data.customerId._id);
    setcustPhone(data.customerId.customerPhone);
    setTitle(data.title);
    setMessage(data.description);
    setissueDate(data.createdAt);
    setEditing(true);
    setId(data._id);
    setSelectedOb({
      customerName: data.customerName,
      customerAddress: data.customerAddress,
      customerPhone: data.customerPhone,
    });
  };

  const getEmployeePhone = (id) => {
    setEmployeeId(id);
    getSingleEmployee(id);
    //console.log("Phone", singleEmployee.mobile);
    setempPhone(singleEmployee.mobile);
  };

  async function sendMessage(content, phone) {
    //console.log("Decs messages", content, phone);

    if (Message.trim().length < 10) {
      setTimeout(
        () => showToastMessageError("Error sending Message to Employee!"),
        2500
      );
    } else {
      //console.log(content, phone);
      //console.log(phone);
      let config = {
        "Content-Type": "text/plain",
        Accept: "*/*",
      };
      axios
        .get(
          `https://smsa.tabaarak.com/SendSMS.aspx?user=Asal&pass=TV@ccess2016&cont=${content}&rec=${phone}`,
          {
            headers: config,
          }
        )
        .then((response) => {
          //console.log(response.data);
          this.setState({ posts: response.data });
        })
        .catch((err) => {
          //console.log("API call error:", err.Message);
          setTimeout(
            () => showToastMessage("Message has been sent successfully"),
            2500
          );
        });
    }
  }

  const showToastMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastMessageError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  let FirstNum = String(CustPhone)[0];
  let FirstEmp = String(Ephone)[0];

  let desc = `Macaamil '${CustName.toUpperCase()}' : Shaqadaadii waa lagu soo dhameeyay , Mahadsanid.`;
  // handle last complete
  const handleComplete = ()=>{
    const data ={
      id: ticketId
    }
    completeTicket(data)
    fetchCompletedTickets();
    fetchPendingTickets();
    setmodal_list3(false);
  }
  // handle re-open
  const handleReopen = (e)=>{
    e.preventDefault();
   let desc1 = `${EmpName} : ${Message} -> Waxaa u Adeegtaa Macaaamilka ah '${CustName}' : Wata telephone number '${CustPhone}' : Degaanka ${customerAddress}`;
    const data = {
      id: ticketId,

      // comment: Message,
      content: desc1,
      phoneNumber: Ephone,
    };
    if (Message === "" || Message === undefined) {
      showToastMessageError("Please make sure to fill description field");
    } else {
      // //console.log(data)
      //console.log(desc1)
      ReopenTickets(data);
      fetchCompletedTickets();
      fetchPendingTickets();

      setmodal_list1(false);
    }
  }
  // handle complete
  const handlersubmit = (e) => {
    e.preventDefault();

    const data = {
      id: ticketId,
      userId,
      comment: Message,
      content: desc,
      phoneNumber: CustPhone,
    };

    if (Message == "" || Message == undefined) {
      showToastMessageError("Please make sure to fill description field");
    } else {
      CompleteTickets(data);
      setmodal_list(false);
    }
  };

  //console.log("EmployeeInfoEmpPhone", EmployeeInfo?.EmpPhone);

  var Myphone = EmployeeInfo?.EmpPhone;
  //console.log("selectOb", selectOb.customerPhone);

  const handlersubmitEmp = (e) => {
    e.preventDefault();
    var desc;

    var customerMessage = `Macaamiil : ${selectOb.customerName}. Dhawaan ayaa laguu adeegi doni.Waxaa kuu shaqeen doona '${EmployeeInfo?.EmployeeName}' oo wata mobile numberka '${EmployeeInfo?.EmpPhone}'.Mahadsanid`;

    if (EmployeeInfo?.label == "" || EmployeeInfo?.EmpPhone == "") {
      desc = `${EmployeeInfo?.EmployeeName} : ${Message} -> Waxaa u Adeegtaa Macaaamilka ah '${selectOb.customerName}' : Wata telephone number '${selectOb.customerPhone}' : Degaanka ${selectOb.customerAddress}`;
    } else {
      desc = `${EmployeeInfo?.EmployeeName} : ${Message} -> Waxaa u Adeegtaa Macaaamilka ah '${selectOb.customerName}' : Wata telephone number '${selectOb.customerPhone}' : Degaanka ${selectOb.customerAddress}`;
    }

    const data = {
      id,
      issueDate: issueDate,
      customerId: customerId,
      custAddress: customerAddress,
      siteId: siteId,
      groupId: groupid,
      zoneId: zoneid,
      empId: EmployeeInfo.value,
      title: title,
      description: Message,
      content: desc,
      phoneNumber: Myphone.trim(),
      customerContent: customerMessage,
      CustomerPhone: selectOb.customerPhone,
      userId: userId,
    };

    //console.log("zone", zoneid);
    //console.log("My info: ", EmployeeInfo);
    //console.log("data", data);
    if (
      !Message ||
      siteId == "0" ||
      groupid == "0" ||
      zoneid == "0" ||
      EmployeeId == "0"
    ) {
      alert("please Fill the required fields");

      let res = "please Fill the required fields";
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      // ticketsUpdate(data);

      if (
        EmployeeInfo?.EmpPhone == undefined ||
        EmployeeInfo?.EmpPhone == "" ||
        selectOb.customerPhone == undefined ||
        selectOb.customerPhone == ""
      ) {
        setTimeout(() => showToastMessageError("Error sending Ticket!"), 1500);
      } else {
        //console.log(data);
        registertickets(data);
      }

      // if (desc.trim().length < 10) {
      //   setTimeout(
      //     () => showToastMessageError("Error sending Ticket!"),
      //     2500
      //   );
      // } else {
      //   if (EmployeeInfo?.EmpPhone == undefined || EmployeeInfo?.EmpPhone == ""){
      //     setTimeout(
      //       () => showToastMessageError("Error sending Ticket!"),
      //       1500
      //     );
      //   }else{
      //     //console.log(data);
      //     registertickets(data);
      //   }

      // }

      setmodal_listEmp(false);
      clear();
    }
  };

  const CustomersArr = [];

  for (let i = 0; i < EmployeesFromZone.length; i++) {
    var dropdownList = {
      value: EmployeesFromZone[i].empId,
      label: ` ${EmployeesFromZone[i].empName} -- ${EmployeesFromZone[i].empPhoneNumber}`,
      EmpPhone: ` ${EmployeesFromZone[i].empPhoneNumber}`,
      EmployeeName: ` ${EmployeesFromZone[i].empName}`,
    };

    CustomersArr.push(dropdownList);
  }

  const handleTypeSelect = (e) => {
    setEmployeeId(e.value);
  };

  const clear = () => {
    setSiteId("");
    setgroupid("");
    setzoneid("");
    setEmployeeId("");
    setTitle("");
    setMessage("");
  };

  const close = () => {
    setSiteId("");
    setgroupid("");
    setzoneid("");
    setEmployeeId("");
    setmodal_listEmp(false);
  };

  //updateees
  //console.log(isEditing);

  const addModal = () => {
    tog_list();
    setEditing(false);
    setamount("");
    setreference("");
    setsupplierId("");
    setDate("");
  };

  const ZonesArr = [];
  for (let i = 0; i < getByIdZonesCables.length; i++) {
    var dropdownList = {
      value: getByIdZonesCables[i]._id,
      label: ` ${getByIdZonesCables[i].zoneName}`,
    };

    ZonesArr.push(dropdownList);
  }

  const SitesArr = [];
  for (let i = 0; i < sites.length; i++) {
    //console.log(sites[i]._id);

    var dropdownList = {
      value: sites[i]._id,
      label: ` ${sites[i].siteName}`,
    };

    SitesArr.push(dropdownList);
  }

  const GroupsArr = [];
  for (let i = 0; i < getByIdGroupsCable.length; i++) {
    var dropdownList = {
      value: getByIdGroupsCable[i]._id,
      label: ` ${getByIdGroupsCable[i].groupName}`,
    };

    GroupsArr.push(dropdownList);
  }

  const handleTypeSelectGroup = (e) => {
    getGroup(e.value);
  };

  const handleTypeSelectGroupZone = (e) => {
    setgroupid(e.value);
    fetchByIdZonesCable(e.value);

    setzoneid("");
    setEmployeeId("");
  };
  const Emp = [];

  for (let i = 0; i < getEmployee.length; i++) {
    // //console.log(setEmployeeId[i]._id);

    var dropdownList = {
      value: getEmployee[i]._id,
      label: ` ${getEmployee[i].empName}`,
      phone: ` ${getEmployee[i].mobile}`,
    };

    Emp.push(dropdownList);
  }

  const handleTransfer = () => {
    // settransferContent( `${employeeIdd?.label} -> Waxaa u Adeegtaa Macaaamilka ah ${transferInfo?.customerId?.customerName} Wata telephone number ${transferInfo?.customerId?.customerPhone}  Degaanka ${transferInfo?.customerId?.customerAddress}`)
    const data = {
      content: employeeIdd?.label + transferContent,
      id: transferId,
      // phoneNumber: transferPhone,
      phoneNumber: employeeIdd?.phone * 1,
      empId: employeeIdd?.value,
      userId,
    };
    if (!employeeIdd?.value) {
      showToastMessageError("Please select an employee");
      return;
    }
    //console.log(data);
    if (employeeIdd?.value == transferInfo?.empId?._id) {
      showToastMessageError("You can not transfer to the same employee");
      return;
    }
    transferTickets(data);
    setmodal_list_update(false);
    settransferContent("");
  };

  const confirmHolding = (row) => {
    //console.log(row);
    setmodal_delete(true);
    setconfirmInfo(row);
  };
  const confirmUnreachable = (row) => {
    //console.log(row);
    setmodal_delete2(true);
    setconfirmInfo(row);
  };
  const confirmNodevice = (row) => {
    //console.log(row);
    setmodal_delete3(true);
    setconfirmInfo(row);
  };

  const RemoveOrAddPending = () => {
    let content = ` ${confirmInfo.empId?.empName} -> Waxaa u Adeegtaa Macaaamilka ah ${confirmInfo?.customerId?.customerName} Wata telephone number ${confirmInfo?.customerId?.customerPhone}  Degaanka ${confirmInfo?.customerId?.customerAddress} `;
    let id = confirmInfo._id;
    let phone = confirmInfo.empId?.mobile;
    let onHold = confirmInfo.onHold;
    let data = {
      content: content,
      id: id,
      phoneNumber: phone,
      onHold: String(onHold),
    };
    RegPendingTrans(data);
    setmodal_delete(false);
    setconfirmInfo("");
    //console.log(content, id, phone, onHold);
  };

  const RemoveFromUnreachable = () => {
    isUnreachable(confirmInfo._id);
    setmodal_delete2(false);
  };
  const RemoveFromNodevice = () => {
    noDevice(confirmInfo._id);
    setmodal_delete3(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Resolve Ticket" pageTitle="Ticket" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">Resolve Ticket</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          {/* <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={() => tog_list()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            Create Ticket
                          </Button> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
             
               

                    
                      <div id="StoreList">
                        <Row className="g-4 mb-3"></Row>
                        {completedTickets.length > 0 && (
                          <DataTable
                            columns={columns3}
                            data={filteredItems3}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            // selectableRows
                            persistTableHead
                            // sortFunction={customSort}
                          />
                        )}
                      </div>
         
           
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}

 
   
      
      <Modal
        isOpen={modal_list1}
        toggle={() => {
          tog_list1();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Resolve Ticket</h5>
          {/* <button
            type="button"
            className="btn-close"
            onClick={addModal}
            aria-label="Close"
          ></button> */}
        </div>
        <ModalBody className="text-center p-5">
          <lord-icon
            src="https://cdn.lordicon.com/tdrtiskw.json"
            trigger="loop"
            colors="primary:#f7b84b,secondary:#405189"
            style={{ width: "130px", height: "130px" }}
          ></lord-icon>
          <div>
            <h4>Are You Sure You Want To Re-open This Ticket ?</h4>
          </div>
          <form onSubmit={handleReopen}>
            <div className="mb-3">
              <label htmlFor="message-text" className="col-form-label">
                Message:
              </label>
              {/* <span style={{ marginLeft: "5px", color: "red" }}>*</span> */}
              <textarea
                className="form-control"
                id="message-text"
                rows="2"
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => setmodal_list1(false)}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-success"
              id="add-btn"
              onClick={handleReopen}
            >
              Yes, Re-open It
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modal_list3}
        toggle={() => {
          tog_list3();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Resolve Ticket</h5>
          {/* <button
            type="button"
            className="btn-close"
            onClick={addModal}
            aria-label="Close"
          ></button> */}
        </div>
        <ModalBody className="text-center p-5">
          <lord-icon
            src="https://cdn.lordicon.com/tdrtiskw.json"
            trigger="loop"
            colors="primary:#f7b84b,secondary:#405189"
            style={{ width: "130px", height: "130px" }}
          ></lord-icon>
          <div>
            <h4>Are You Sure You Want To Complete This Ticket ?</h4>
          </div>
      
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => setmodal_list3(false)}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-success"
              id="add-btn"
              onClick={handleComplete}
            >
              Yes, Complete It
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default CompleteTicket;
