import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
//import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SalesForm from "../SalesForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Grid, _ } from "gridjs-react";
import DataTable from "react-data-table-component";
import Select from "react-select";

const currDate = new Date().toLocaleDateString;
var dateVariable = new Date();

const TransferIptv = () => {
  const [storeName, setstoreName] = useState("");
  const [storeLocation, srtstoreLocation] = useState("");
  const [storeDescription, setstoreDescription] = useState("");
  const [isPrimaryStore, setisPrimaryStore] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [yes, setYes] = useState(true);
  const [id, setId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [newSerial, setnewSerial] = useState("");
  const [newMac, setnewMac] = useState("");
  // const [no, setNo] = useState(false);

  // local states

  const [purchaseDate, setpurchaseDate] = useState("");
  const [customerTell, setCustomerTell] = useState("");

  const [supplierId, setSupplierId] = useState("");
  const [storeId, setstoreId] = useState("");
  // const [siteId, setsiteId] = useState("");
  const [referenceNo, setreferenceNo] = useState("");
  const [description, setdescription] = useState("");
  const [purchaseAmount, setpurchaseAmount] = useState("");
  const [additionalCost, setadditionalCost] = useState("");
  const [discount, setdiscount] = useState();
  const [totalAmount, settotalAmount] = useState();
  const [EmployeeId, setEmployeeId] = useState();
  const [siteId, setSiteId] = useState();
  const [groupid, setgroupid] = useState();
  const [zoneid, setzoneid] = useState();
  const [Message, setMessage] = useState();
  const [issueDate, setissueDate] = useState();
  const [customerId, setcustomerId] = useState();
  const [customerAddress, setcustomerAddress] = useState();
  const [title, setTitle] = useState();
  const [custPhone, setCustPhone] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");

  const [subscriptionId, setSubscriptionId] = useState("");
  const [OldMacAddress, setOldMacAddress] = useState("");
  const [OldSerialNumber, setOldSerialNumber] = useState("");
  const [NewMacAddress, setNewMacAddress] = useState("");
  const [newSerialNumber, setNewSerialNumber] = useState("");
  // const [customerAddress, setCustomerAddress] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [object_id, setObject] = useState();
  const [Date, setDate] = useState();

  const [customerInfo, setCustomerInfo] = useState();

  const {
    registerStore,
    stores,
    deleteStores,
    upadteStore,
    fetchItems,
    fetchStores,
    fetchSites,
    sites,
    items,
    getZonesById,
    getGroups,
    getEmployee,
    getZonesbyGroup,
    fetchEmployees,
    fetchGroups,
    fetchGroupsToAssign,
    fetchZonesToAssign,
    fetchEmployeesToAssign,
    getByIdGroupCable,
    getByIdGroupsCable,
    getByIdZonesCables,
    fetchByIdZones,
    getZoneById,
    Cables,
    fetchCables,
    AssignCablesRegister,
    fetchByIdZonesCable,
    fetchByIEmployee,
    GetByIdEmployee,
    registertickets,
    customers,
    fetchCustomers,
    fetchAllIPTVS,
    IPTVS,
    registerIPTV,
    removeIPTV,
    UpdateIPTV,
    GetIPTVHormuud,
    IPTVHormuud,
    getSingleCustomer,
    singleCustomer,
    registerCustomer,
    fetchDistricts,
    districts,
    User,
    fetchMacAddressAndSerialNumber, macAddressAndSerialNumberData,transferIPTV
  } = useContext(LoginContex);
  const [modal_list, setmodal_list] = useState(false);
  const [modal_listCustomer, setmodal_listCustomer] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
    clear();
  };
  
 

  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };


  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  
 

  const clear = () => {
    // setSunsc("");
    setSubscriptionId("");
    setNewMacAddress("");
    setNewSerialNumber("");
    setOldSerialNumber("");
    setOldMacAddress("");
    // setMessage("");
  };
  document.title = "TRANSFER IP-TV";

  const handlersubmit = (e) => {
    e.preventDefault();
    // if(!subscriptionId || !newSerialNumber || !NewMacAddress){
    //   showToastMessage("please provide all the required values")
    //   return;
    // }
    const data = {
      subscriptionId:subscriptionId,
      serialNumber:newSerial,
      macAddress:newMac
    }
    //console.log("======================",data,"================")
    transferIPTV(data)
  };
  
  const handleChecking = () => {
    fetchMacAddressAndSerialNumber(subscriptionId)
    
    setNewMacAddress(macAddressAndSerialNumberData[0]?.macAddress)
    setNewSerialNumber(macAddressAndSerialNumberData[0]?.serialNumber)
    //console.log("mac address and serial number is: ",macAddressAndSerialNumberData[0])
    // if(!macAddressAndSerialNumberData[0]?.macAddress || !macAddressAndSerialNumberData[0]?.serialNumber){
    //   showToastMessage("invalid subscription")
    // }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="TRANSFER IP-TV" pageTitle="Billing" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">TRANSFER IPTV</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={() => tog_list()}
                            id="create-btn"
                          >
                            TRANSFER
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

              
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Store Registration From */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        {/* <ModalHeader className="bg-light p-3">
                    Assign To Employee
                    <Button
                        type="button"
                        onClick={() => {
                            setmodal_list(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </ModalHeader> */}

        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title">TRANSFER IP-TV</h5>

          <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>

        <form onSubmit={handlersubmit}>
          <ModalBody>
           
          
            <Row style={{ marginTop: "2%" }}>
              <Col md={3}>
                <label className="form-label">Subscription ID</label>{" "}
                <span className="text-danger">*</span>
                <input
                  type="Number"
                  id="referenceno-field"
                  className="form-control"
                  placeholder="Enter The subscription ID"
                  required
                  onChange={(e) => setSubscriptionId(e.target.value)}
                  value={subscriptionId}
                  // onChange={(e) => setReferenceNo(e.target.value)}
                />
              </Col>

              <Col md={3}>
                <div className="mb-3">
                  <label htmlFor="macAddress-field" className="form-label">
                    Old Mac Address<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="macAddress-field"
                    className="form-control"
                    placeholder="Old MacAddress"
                    required
                    disabled
                    onChange={(e) => setOldMacAddress(e.target.value, e.target)}
                    value={subscriptionId?macAddressAndSerialNumberData[0]?.macAddress:''}
                  />
                </div>
              </Col>

              <Col md={3}>
                <div className="mb-3">
                  <label>Old Serial Number</label>

                  <input
                    type="text"
                    id="oldSerialNumber-field"
                    className="form-control"
                    placeholder="Old Serial Number"
                    required
                
                    disabled
                    onChange={(e) => setOldSerialNumber(e.target.value, e.target)}
                    value={subscriptionId?macAddressAndSerialNumberData[0]?.serialNumber:''}
                    // onChange={(e) => setCustPhone(e.target.value)}
                    // value={custPhone}
                    // onChange={(e) => setReferenceNo(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={3}>
              <div className="mb-3">
              <label>.</label>
              <Button
                    type="button"
                    className="btn btn-warning"
                    style={{ width: "100%" }}
                    onClick={()=> handleChecking()}
                  >
                    Fetch subscription ID
                  </Button>
                  </div>
              </Col>

            </Row>

            <Row>
            <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="macAddress-field" className="form-label">
                    New Mac Address<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="macAddress-field"
                    className="form-control"
                    placeholder="New Mac Address"
                    required
                    onChange={(e) => setnewMac(e.target.value)}
                    value={newMac}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <label>New Serial Number</label>

                  <input
                    type="text"
                    id="newSerialNumber-field"
                    className="form-control"
                    placeholder="New Serial Number"
                    required
                    onChange={(e) => setnewSerial(e.target.value)}
                    // value={newSerial}
                 
                    // onChange={(e) => setCustPhone(e.target.value)}
                    // value={custPhone}
                    // onChange={(e) => setReferenceNo(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <ToastContainer />
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              {/* <button type="submit" className="btn btn-success" id="add-btn">
                                Assign
                            </button> */}
              <button
                type="submit"
                className="btn btn-success"
                id="add-btn"
                onClick={handlersubmit}
              >
                {isEditing ? "Update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>

        {/* =================================== */}
      </Modal>

      {/* Add Modal */}
      {/* Purchase Customer From */}


      {/* Remove Modal */}
    
    </React.Fragment>
  );
};

export default TransferIptv;
