import React, { useState, useEffect, useContext, useMemo } from "react";
import { Grid, _ } from "gridjs-react";
import DataTable from "react-data-table-component";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Input,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";

const ConnectDevice = () => {
  //LOCAL states

  const [deviceName, setDeviceName] = useState("");
  const [deviceCode, setDeviceCode] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [port, setPort] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [_id, set_ID] = useState("");
  const {
    fetchCustomers,
    registerDevice,
    fetchDevices,
    devices,
    deleteDevices,
    updateDevices,
    isLoading,User
  } = useContext(LoginContex);

  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  useEffect(() => {
    fetchCustomers();
    fetchDevices();
  }, []);

  document.title = "Connect Device ";
  let userId = User?.data?.user?._id || User?.user?._id;
  const columns = useMemo(
    () => [
      {
        Header: "Device Name",
        accessor: "deviceName",
        filterable: false,
      },
      {
        Header: "Device Code",
        accessor: "deviceCode",
        filterable: false,
      },
      {
        Header: "Device Ip Address",
        accessor: "ipAddress",
        filterable: false,
      },
      {
        Header: "Device Port",
        accessor: "port",
        filterable: false,
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        filterable: false,
      },
      {
        Header: "Address",
        accessor: "address",
        filterable: false,
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    updateForm(customerData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={(row) => {
                    const customerData = cellProps.row.original;
                    deleteCus(customerData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ]
    // [handleCustomerClick]
  );
  //register customer
  //console.log(isUpdating);
  const handlerSubmit = (e) => {
    e.preventDefault();

    if (!isUpdating) {
      const data = {
        ipAddress,
        deviceCode,
        deviceName,
        port,
        phoneNumber,
        address,
        userId
      };

      if (!deviceName || !deviceCode) {
        return;
      }
      registerDevice(data);
      setmodal_list(false);
      setDeviceName("");
      setDeviceCode("");
      setIpAddress("");
      setPort("");
      setAddress("");
      setPhoneNumber("");
      //console.log(data);
    }
    if (isUpdating) {
      //console.log("updd");
      const data = {
        ipAddress,
        deviceCode,
        deviceName,
        port,
        phoneNumber,
        address,
        id: _id,
      };

      if (!deviceName || !deviceCode) {
        return;
      }
      // updateCustomer(data);
      updateDevices(data);
      setmodal_list(false);
      setDeviceName("");
      setDeviceCode("");
      setIpAddress("");
      setPort("");
      setAddress("");
      setPhoneNumber("");
    }
  };

  //delet customer

  const deleteCus = (data) => {
    setmodal_delete(true);
    set_ID(data._id);
  };
  const deleteAgentss = () => {
    deleteDevices(_id);
    setmodal_delete(false);
  };

  // update form

  const updateForm = (data) => {
    setmodal_list(true);
    setDeviceName(data.deviceName);
    setDeviceCode(data.deviceCode);
    setIpAddress(data.ipAddress);
    setPort(data.port);
    setPhoneNumber(data.phoneNumber);
    setAddress(data.address);
    setIsUpdating(true);
    set_ID(data._id);
  };

  const addModal = () => {
    tog_list();
    setIsUpdating(false);
    setDeviceName("");
    setDeviceCode("");
    setIpAddress("");
    setPort("");
    setAddress("");
    setPhoneNumber("");
  };
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handleChangePhone = (e) => {
    const limit = 10;

    // Here we are checking if the length is equal to 9
    if (e.target.value.length === 10) {
      showToastMessage("Phone number should not exceed 10 digits!");
      setPhoneNumber(e.target.value.slice(0, limit));
    }
    setPhoneNumber(e.target.value.slice(0, limit));
  };
  const sortAgents = [];
  for (let i = 0; i < devices?.length; i++) {
    let singleArr = [
      devices[i].deviceName,
      devices[i].deviceCode,
      devices[i].ipAddress,
      devices[i].port,
      devices[i].phoneNumber,
      devices[i].address,
      devices[i]._id,
    ];

    sortAgents.push(singleArr);
  }
  const load = () => {
    if (isLoading) {
      return (
        <>
          <div className="page-content">
            <Container fluid>
              <BreadCrumb title="Manage Cables" pageTitle="Billing" />
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif"
                          style={{ backgroundColor: "white !", width: "700px" }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="New Device" pageTitle="Registration" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <div>
                          <ToastContainer />
                          <h4 className="card-title mb-0">Add New Device</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={addModal}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  {load()}
                  <div id="customerList">
                    <Row className="g-4 mb-3"></Row>

                    {devices.length > 0 && (
                      <TableContainer
                        columns={columns}
                        data={devices || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        // handleCustomerClick={handleCustomerClicks}
                        // isCustomerFilter={true}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* customer Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Add New Device</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <Row>
            <Col md={12}>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            </Col>
            <Col md={6}>
            <div className="mb-3">
              <label htmlFor="device-name-field" className="form-label">
                Device <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="device-name-field"
                className="form-control"
                placeholder="Enter Device Name"
                required
                onChange={(e) => setDeviceName(e.target.value)}
                value={deviceName}
              />
            </div>
            </Col>
            <Col md={6}>
            <div className="mb-3">
              <label htmlFor="device-code-field" className="form-label">
                Device <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="device-code-field"
                className="form-control"
                placeholder="Enter Device Code"
                required
                onChange={(e) => setDeviceCode(e.target.value)}
                value={deviceCode}
              />
            </div>
            </Col>
            <Col md={6}>
            <div className="mb-3">
              <label htmlFor="device-ipaddress-field" className="form-label">
                Device Ip Address<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="device-ipaddress-field"
                className="form-control"
                placeholder="Enter Device Address"
                required
                onChange={(e) => setIpAddress(e.target.value)}
                value={ipAddress}
              />
            </div>
            </Col>
            <Col md={6}>
            <div className="mb-3">
              <label htmlFor="device-port-field" className="form-label">
                Device Port <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="device-port-field"
                className="form-control"
                placeholder="Enter Device Port"
                required
                onChange={(e) => setPort(e.target.value)}
                value={port}
              />
            </div>
            </Col>
            <Col md={6}>
            <div className="mb-3">
              <label htmlFor="phone-field" className="form-label">
                Phone Number <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                id="phone-field"
                className="form-control"
                placeholder="Enter Phone Number "
                required
                onChange={handleChangePhone}
                value={phoneNumber}
              />
            </div>
            </Col>
            <Col md={6}>
            <div className="mb-3">
              <label htmlFor="address-field" className="form-label">
                Address
              </label>
              <input
                type="text"
                id="address-field"
                className="form-control"
                placeholder="Enter Address"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
              />
            </div>
            </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isUpdating ? "Update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={deleteAgentss}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ConnectDevice;
