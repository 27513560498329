import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link, useParams, useHistory } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";

const RecieveItem = () => {
  let { the_id } = useParams();
  let [serviceList, setServiceList] = useState([
    {
      itemId: "",
      itemName: "",
      receiveQuantity: "",
      orederedQuantity: "",
      amount: "",
      remaining: "",
      Rate: "",
      uploadedQuantity: "",
    },
  ]);
  const history = useHistory();
  const [itemData, setitemData] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [toastTrue, settoastTrue] = useState();
  ///local states

  const [todaydata, setTodayDate] = useState("");
  const [date, setDate] = useState(todaydata);

  const [amount, setAmount] = useState();
  const [additianalCost, setAdditianalCost] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [QtyAvailable, setQtyAvailable] = useState("");
  const [Qty, setQty] = useState("");
  const [modal_list, setmodal_list] = useState(false);
  const [yes, setYes] = useState(true);
  const [supplierId, setSupplierId] = useState("");
  const [storeId, setStoreId] = useState("");
  const [siteId, setSiteId] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [description, setdescription] = useState("");
  const [description2, setdescription2] = useState("");
  const [switchh, setSwitch] = useState("");
  const [duplicate, setDuplicate] = useState(false);
  const [trythis, setTrythis] = useState(false);
  const {
    suppliers,
    items,
    fetchItems,
    stores,
    fetchStores,
    sites,
    fetchSites,
    readProducts,
    products,
    registerPurchase,
    fetchQuantityAvailable,
    quantityAvailable,
    purchaseOrders,
    fetchPurchaseOrders,
    singlePurchaseOrder,
    FetchSinglePurchase,
    purchaseReceiveAdd,
  } = useContext(LoginContex);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  let yess = yes === "true";
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  const id = supplierId?.value;
  //console.log(the_id);
  //console.log(singlePurchaseOrder);
  //console.log(yes);

  useEffect(() => {
    fetchItems();
    fetchStores();
    fetchSites();
    readProducts();

    setTodayDate(today);
    fetchPurchaseOrders();

    FetchSinglePurchase(the_id);
    setServiceList(singlePurchaseOrder[0]?.purchaseOrderDetails);

    setYes(true);
    setReferenceNo(singlePurchaseOrder?.[0]?.referenceNo);
    setdescription(singlePurchaseOrder?.[0]?.description);
    setDate(singlePurchaseOrder?.[0]?.purchaseOrderDate);
  }, [the_id]);
  useEffect(() => {
    //console.log(serviceList);
  }, [serviceList]);
  //console.log(singlePurchaseOrder[0]);
  //console.log(serviceList);
  // //console.log(singlePurchaseOrder[0]);

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;

  document.title = "Purchase  receive";

  const purchaseAmount = singlePurchaseOrder[0]?.purchaseOrderDetails.reduce(
    (accu, curr) => {
      return accu + curr["amount"];
    },
    0
  );

  // const purchaseAmount = 500;

  const handleServiceChange = (e, index) => {
    fetchQuantityAvailable(
      singlePurchaseOrder[0].purchaseOrderDetails[index]["itemId"]
    );
    // //// //console.log(e);
    const { name, value } = e.target;
    const list = [...singlePurchaseOrder[0].purchaseOrderDetails];

    list[index][name] = value;
    list[index]["QtyAvailable"] = quantityAvailable.item_blance;

    const gr =
      Number(list[index]["quantity"]) -
      Number(list[index]["reciversUploaded"]) -
      Number(list[index]["receiveQuantity"]);

    list[index]["remaining"] =
      Number(list[index]["quantity"]) -
      Number(list[index]["reciversUploaded"]) -
      Number(list[index]["receiveQuantity"]);

    //console.log(toastTrue);

    fetchQuantityAvailable(
      singlePurchaseOrder[0].purchaseOrderDetails[index]["itemId"]
    );
    list[index]["amount"] =
      Number(
        singlePurchaseOrder[0].purchaseOrderDetails[index]["receiveQuantity"]
      ) *
      Number(singlePurchaseOrder[0].purchaseOrderDetails[index]["unitPrice"]);
    list[index]["purchaseAmount"] = purchaseAmount;
  };

  const totalAmount =
    Number(purchaseAmount) + (Number(additianalCost) - Number(discount));

  const data = {
    purchaseDate: date,
    supplierId: supplierId.value,
    description: description,
    referenceNo: referenceNo,
    siteId: siteId,
    purchaseAmount: purchaseAmount,
    totalAmount: totalAmount,
    isCash: yess,
    additionalCostAcc: additianalCost,
    discount: discount,
    purchaseDetails: serviceList,
    storeId: storeId.value,
  };
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!date || !storeId.value || !singlePurchaseOrder?.[0]?.supplierId._id) {
      showToastMessage("Please provide required values");
      return;
    } else {
      setTrythis(false);

      const data = {
        purchaseOrderId: the_id,
        purchaseReceiveDate: date,
        storeId: storeId.value,
        referenceNo: referenceNo,
        description: description,
        purchaseReceiveAmount: purchaseAmount,
        additionalCost: additianalCost,
        discount: discount,
        totalAmount: totalAmount,
        purchaseReceiveDetails: purchaseReceiveDetails,
        isCash: yess,
        supplierId: singlePurchaseOrder[0]?.supplierId._id,
      };
      //console.log(data);
      purchaseReceiveAdd(data);

      setServiceList([
        {
          itemId: "",
          quantity: "",
          unitPrice: "",
          amount: "",
          description: "",
          QtyAvailable: "",
        },
      ]);
      setDate("");
      setAmount("");
      setReferenceNo("");
      setStoreId("");
      setAdditianalCost("");
      setYes("");
      setDiscount("");
      setSupplierId("");
      setdescription("");
      setStoreId("...Select...");
      // storeId.current.select.clearValue();
      setdescription2("");
      // setTimeout(() => {}, 4000);
      setAdditianalCost("");
      setDiscount("");

      //// //console.log(data);
      setTimeout(() => {
        // history.push("/ManagePurchaseOrder", { replace: true });
        // window.location.replace("/ManagePurchaseOrder");
        history.push("/ManagePurchaseOrder");
      }, 3000);
    }
    e.target.reset();
  };
  // soloving dropdowns
  const suppliersArr = [];

  for (let i = 0; i < purchaseOrders.length; i++) {
    //// //console.log(purchaseOrders[i]._id);

    var dropdownList = {
      value: purchaseOrders[i]?._id,
      label: `${purchaseOrders[i]?.supplierId?.supplierName} -- ${purchaseOrders[i]?.purchaseOrderId}`,
    };

    suppliersArr.push(dropdownList);
  }

  /// stores dropdowns
  const storesArr = [];

  for (let i = 0; i < stores.length; i++) {
    //// //console.log(stores[i]._id);

    var dropdownList = {
      value: stores[i]._id,
      label: stores[i].storeName,
    };

    storesArr.push(dropdownList);
  }

  //// //console.log(serviceList);

  const purchaseReceiveDetails = [];

  for (
    let i = 0;
    i < singlePurchaseOrder[0]?.purchaseOrderDetails?.length;
    i++
  ) {
    const newob = {
      itemId: singlePurchaseOrder[0]?.purchaseOrderDetails[i].itemId._id,
      quantity: Number(
        singlePurchaseOrder[0]?.purchaseOrderDetails[i].receiveQuantity
      ),
      unitPrice: singlePurchaseOrder[0]?.purchaseOrderDetails[i].unitPrice,
      amount: singlePurchaseOrder[0]?.purchaseOrderDetails[i].amount,
      receiversUploaded:
        singlePurchaseOrder[0]?.purchaseOrderDetails[i].reciversUploaded,
    };

    purchaseReceiveDetails.push(newob);
  }

  //console.log(purchaseReceiveDetails);

  // const showToastMessage = () => {
  //   toast.error("Please provide required value !", {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  // };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title=" Purchase Recieve" pageTitle="Purchase" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          {/* {trythis && showToastMessage()} */}
                          <h4 className="card-title mb-0">Purchase Receive</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Link to="/ManagePurchase" className="text-white">
                            <Button
                              color="success"
                              className="add-btn me-1"
                              // onClick={() => tog_list()}
                              //  id="create-btn"
                            >
                              <i className=" ri-add-line align-bottom me-1"></i>{" "}
                              Manage Purchase{" "}
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <form onSubmit={handleSubmit}>
                      <ModalBody>
                        <div
                          className="mb-3"
                          id="modal-id"
                          style={{ display: "none" }}
                        >
                          <label htmlFor="id-field" className="form-label">
                            ID
                          </label>
                          <input
                            type="text"
                            id="id-field"
                            className="form-control"
                            placeholder="ID"
                            readOnly
                          />
                        </div>
                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>
                                Supplier <span className="text-danger">*</span>
                              </label>

                              {/* <Select
                                required
                                options={suppliersArr}
                                onChange={(choice) => setSupplierId(choice)}
                              /> */}
                              <input
                                type="text"
                                id="id-field"
                                className="form-control d-none"
                                placeholder="ID"
                                value={the_id}
                              />
                              <input
                                type="text"
                                id="id-field"
                                className="form-control"
                                readOnly
                                value={
                                  singlePurchaseOrder[0]?.supplierId
                                    ?.supplierName
                                }
                                required
                              />
                            </div>
                          </Col>
                          <Col md={2}>
                            <div>
                              <Label
                                htmlFor="PurchasesDate"
                                className="form-label"
                              >
                                Purchase Date
                                <span className="text-danger">*</span>
                              </Label>
                              <Flatpickr
                                className="form-control"
                                required
                                placeholder="DD/MM/YYYY"
                                options={{
                                  dateFormat: "d/m/Y",
                                }}
                                onChange={([date]) => setDate(date)}
                                value={date}
                              />
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="mb-3">
                              <label>
                                Store <span className="text-danger">*</span>
                              </label>

                              <Select
                                options={storesArr}
                                onChange={(choice) => setStoreId(choice)}
                                required
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    // text: "orangered",
                                    // primary25: "hotpink",
                                    // primary: "black",
                                  },
                                })}
                              />
                            </div>
                          </Col>

                          <Col md={2}>
                            <div className="mb-3">
                              <label className="form-label">Reference No</label>
                              <input
                                type="text"
                                id="referenceno-field"
                                className="form-control"
                                placeholder="Enter Reference No"
                                onChange={(e) => setReferenceNo(e.target.value)}
                                value={referenceNo}
                                required
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label className="form-label">Description</label>
                              <textarea
                                className="form-control"
                                id="Description-field"
                                rows="1"
                                onChange={(e) => setdescription(e.target.value)}
                                value={description}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Row></Row>
                          <table
                            className="table align-middle table-nowrap"
                            id="purchase"
                          >
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col" style={{ width: "50px" }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div>
                                </th> */}

                                <th className="sort" data-sort="item">
                                  Item Name
                                </th>
                                <th className="sort" data-sort="qty">
                                  Ordered Quantity
                                </th>
                                <th className="sort" data-sort="qty">
                                  Uploaded Quantity
                                </th>
                                <th className="sort" data-sort="phone">
                                  Receive Quantity
                                </th>
                                <th className="sort" data-sort="phone">
                                  Remaining
                                </th>
                                <th className="sort" data-sort="Date">
                                  Rate
                                </th>
                                <th className="sort" data-sort="amount">
                                  Amount
                                </th>
                                {/* <th className="sort" data-sort="amount">
                                  Discription
                                </th> */}
                                {/* <th className="sort" data-sort="action">
                                  Actions
                                </th> */}
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {singlePurchaseOrder[0]?.purchaseOrderDetails?.map(
                                (service, index) => {
                                  ////console.log(service.Amount);
                                  return (
                                    <tr key={index} className="">
                                      {/* <th scope="row">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="checkAll"
                                            value="option4"
                                          />
                                        </div>
                                      </th>
                                      <td
                                        className="id"
                                        style={{ display: "none" }}
                                      >
                                        <Link
                                          to="#"
                                          className="fw-medium link-primary"
                                        >
                                          #VZ2104
                                        </Link>
                                      </td> */}
                                      <td className="item">
                                        <div className="mb-3">
                                          {/* <select
                                          className="form-select "
                                          id="itemtype"
                                          onChange={(e) =>
                                            handleServiceChange(e, index)
                                          }
                                          name="itemId"
                                          required
                                        >
                                          <option value="choose item">
                                            Choose item
                                          </option>
                                          {products.map((pr, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={pr._id}
                                              >
                                                {pr.itemName}
                                              </option>
                                            );
                                          })}
                                        </select> */}

                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="disabledInput"
                                            disabled
                                            value={service.itemId.itemName}
                                          />
                                          <Input
                                            type="text"
                                            className="form-control d-none"
                                            id="disabledInput"
                                            disabled
                                            defaultValue={service._id}
                                            value={service.itemId}
                                            name="itemId"
                                          />
                                        </div>
                                      </td>
                                      <td className="qty">
                                        <div className="mb-3">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="disabledInput"
                                            // defaultValue={service.QtyAvailable}
                                            disabled
                                            value={service.quantity}
                                            name="orederedQuantity"
                                          />
                                        </div>
                                      </td>
                                      <td className="qty">
                                        <div className="mb-3">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="disabledInput"
                                            value={service.reciversUploaded}
                                            disabled
                                            name="uploadedQuantity"
                                          />
                                        </div>
                                      </td>
                                      <td className="phone">
                                        <div className="mb-3">
                                          <input
                                            type="number"
                                            id="CashAccount-field"
                                            className="form-control"
                                            placeholder="Enter quantity"
                                            required
                                            onChange={(e) =>
                                              handleServiceChange(e, index)
                                            }
                                            name="receiveQuantity"
                                            max={
                                              service.quantity -
                                              service.reciversUploaded
                                            }
                                            min={-1}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="mb-3">
                                          <input
                                            type="number"
                                            id="CashAccount-field"
                                            className="form-control"
                                            placeholder="Enter Remaining"
                                            required
                                            onChange={(e) =>
                                              handleServiceChange(e, index)
                                            }
                                            name="remaining"
                                            disabled
                                            value={service.remaining}
                                            min={1}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="mb-3">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="disabledInput"
                                            defaultValue={service.unitPrice}
                                            // value={service.unitPrice}
                                            name="unitPrice"
                                            onChange={(e) =>
                                              handleServiceChange(e, index)
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="qty">
                                        <div className="mb-3">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            onChange={(e) =>
                                              handleServiceChange(e, index)
                                            }
                                            name="amount"
                                            value={service.amount}
                                            disabled
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        {/* {serviceList.length > 1 && ( */}
                                        {/* {serviceList.length !== 1 && (
                                        <button
                                          type="submit"
                                          className="btn btn-danger mb-3"
                                          id="add-btn"
                                          onClick={() =>
                                            handleServiceRemove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      )} */}
                                        {/* )} */}

                                        {/* {serviceList.length > 1 && ( */}
                                        {/* {serviceList.length - 1 === index && (
                                        <button
                                          type="submit"
                                          className="btn btn-success mb-3 mx-3"
                                          id="add-btn"
                                          onClick={handleServiceAdd}
                                        >
                                          Add
                                        </button>
                                      )} */}
                                        {/* )} */}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>

                          {/* <Col> */}

                          {/* <div className="mb-3">
                              <Label
                                className="form-check-label"
                                for="Yes_is_Cash"
                              >
                                Is Cash
                              </Label>
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="Yes_is_Cash"
                                  id="Yes_is_Cash"
                                />
                              </div>
                            </div>

                          {/* <div>
                              <Col lg={3} md={1}>
                                <div>
                                  <div className="form-check form-switch form-switch-lg mb-3">
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id="ISCash"
                                      onChange={(e)=> setSwitch(e.target.value)}
                                    />
                                    <div>
                                      <Label
                                        className="form-check-label"
                                        for="flexSwitchCheckDefault"
                                      >
                                        Is Cash
                                      </Label>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </div> */}
                          {/* </Col> */}

                          <Col md={2}>
                            <div className="mb-3">
                              <label className="form-label">
                                Purchase Amount
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="disabledInput"
                                defaultValue={purchaseAmount}
                                value={purchaseAmount}
                                disabled
                              />
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="mb-3">
                              <label className="form-label">
                                Additional Cost
                              </label>
                              <input
                                type="number"
                                id="AdditionalCostAcc-field"
                                className="form-control"
                                placeholder="Enter Additional Cost Acc"
                                defaultValue={0}
                                onChange={(e) =>
                                  setAdditianalCost(e.target.value)
                                }
                                required
                              />
                            </div>
                          </Col>

                          <Col md={2}>
                            <div className="mb-3">
                              <label className="form-label">Discount</label>
                              <input
                                type="number"
                                id="totalamount-field"
                                className="form-control"
                                placeholder="Enter Discount"
                                defaultValue={0}
                                onChange={(e) => setDiscount(e.target.value)}
                                required
                              />
                            </div>
                          </Col>

                          <Col md={3}>
                            <div className="mb-3">
                              <label className="form-label">Total amount</label>
                              <input
                                type="number"
                                className="form-control"
                                id="disabledInput"
                                // defaultValue={totalAmount}
                                value={totalAmount}
                                // defaultValue={purchaseAmount}
                                // value={purchaseAmount}

                                disabled
                              />
                            </div>
                          </Col>
                          <Row className="mt-5"></Row>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-success"
                            id="add-btn"
                          >
                            Save
                          </button>
                        </div>
                      </ModalFooter>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Purchase Registration From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        {/* =================================== */}
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default RecieveItem;
